import { CLEAR_VERIFICATION_DATA } from '../constants/verificationConstants';

const initialState = {
    isRegistrationInit: false,
    isVerificationModalOpen: false,
    userData: null,
};

const verification = (state = initialState, action) => {
    switch (action.type) {
        case 'REGISTER_INIT':
            return {
                ...state,
                isRegistrationInit: true,
            };
        case 'REGISTER_DONE':
            return {
                ...state,
                isRegistrationInit: action.payload,
            };
        case 'REGISTER_SUCCESS':
            return {
                ...state,
                userData: action.payload,
            };
        case 'TOGGLE_VERIFICATION_MODAL':
            return { ...state, isVerificationModalOpen: !state.isVerificationModalOpen };

        case CLEAR_VERIFICATION_DATA:
            return {
                ...state,
                isRegistrationInit: false,
                userData: {}
            };
        default:
            return state;
    }
};

export default verification;
