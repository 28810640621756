export const medias = [1230, 1280, 1536, 1600, 1920];

export const mediasNew = {
    minSm: '997px',
    minMd: '1050px',
    minLg: '1230px',
    minLgg: '1280px',
    minXl: '1536px',
    minXxl: '1600px',
    minMax: '1900px',
};

export const colors = {
    bluePrimary: '#CC66FF',
    primary: '#00C4B1',
    greenPrimary: '#16D4C2',
    blackPrimary: '#10151B',
    greyPrimary: '#A4A4A4',
    greyDDD: '#DDDDDD',
};

export const familyFonts = {
    Poppins: "'Poppins', sans-serif",
    GlossAndBloom: 'Gloss_And_Bloom',
};

export const headerBtnSizes = [200, 222, 244, 256, 278];

export const defaultFontSizes = [14, 16, 18, 19, 21];

export const headerXLFontSizes = [40, 42, 50, 52, 63];

export const headerLFontSizes = [32, 33, 40, 41, 50];

export const headerMFontSizes = [32, 33, 40, 41, 50];

export const fontSizes18 = [18, 19, 23, 23, 29];

export const fontSizes16 = [16, 17, 21, 21, 26];

export const fontSizes13 = [13, 14, 17, 17, 21];

export const inputHeights = [48, 50, 60, 62, 75];

export const footerDistances = [100, 86, 38, 37, 52];

export const buttonDistances = [60, 55, 42, 41, 44];

export const dateTimeWidths = [372, 395, 472, 495, 596];

export const dateTimeHeights = [300, 319, 381, 399, 340];

export const defaultBtnSize = 200;
