import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { useHistory } from 'react-router';
import footerBg from "../../assets/footer-bg.webp";

export const GetStartedHero = ({ text, button, customStyle, }) => {
    const [moveBg, setMoveBg] = useState('');
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            setMoveBg('move-bg')
        }, 200)
    }, [])

    return (
        <GetStartedHeroStyles className={`${moveBg}`}>
            <div className="bg" />
            <section className={`hero ${customStyle}`}>
                <div className="container-fluid">
                    <div className="title">Let’s get started</div>
                    <AnimatedOnScroll animationIn="fadeInUp" animationInDelay={2000} animateOnMount={true}>
                        <p className="hero__text">{text}</p>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInUp" animationInDelay={2500} animateOnMount={true}>
                        <Link to={`/get-started`} className="home__link"><span className="home__btn">{button}</span></Link>
                    </AnimatedOnScroll>
                    <div className="buttons-wrapper">
                        <div className="buttons-container">
                            <div className="buttons-container__elem">
                                <div className="buttons-container__title">Finders</div>
                                <div className="buttons-container__text">Easily search top talent, fast</div>
                                <div
                                  className="buttons-container__button buttons-container__button_finders"
                                  onClick={() => history.push('/register-business')}
                                >Get started</div>
                            </div>
                            <div className="buttons-container__elem">
                                <div className="buttons-container__title">Speakers</div>
                                <div className="buttons-container__text">Power your speaker business</div>
                                <div
                                    className="buttons-container__button buttons-container__button_speakers"
                                    onClick={() => history.push('/register')}
                                >Get started</div>
                            </div>
                        </div>

                        <div className="bottom-text">Already have an account?<span>Log in</span></div>
                    </div>
                </div>
            </section>
        </GetStartedHeroStyles>
    )
}

export const GetStartedHeroStyles = styled.div`
  position: relative;
  overflow: hidden;

  .buttons-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buttons-container {
    width: 1110px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px) {
        flex-direction: column;
        width: 100%;
        height: 494px;
    }

    &__elem {
        width: 540px;
        height: 372px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #374658;
        border-radius: 5px;
        transition: transform 0.2s ease-in-out;
        margin-bottom: 24px;

        @media (max-width: 800px) {
            width: 328px;
            height: 242px;
            margin-bottom: 10px;
        }

        &:hover {
            transform: scale(1.05);
            border: 2px solid #E8388A;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 48px;
        line-height: 60px;
        color: #ffffff;
        margin-bottom: 20px;
        text-align: center;

        @media (max-width: 800px) {
            margin-bottom: 40px;
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 12px;
        }
    }

    &__text {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 24px;
        width: 333px;

        @media (max-width: 800px) {
            font-size: 16px;
            line-height: 22px;
            width: 253px;
            margin-bottom: 24px;
        }
    }

    &__button {
        width: 310px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        border-radius: 40px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        text-transform: uppercase;

        @media (max-width: 800px) {
            width: 154px;
            height: 48px;
            font-size: 12px;
            line-height: 16px;
        }

        &_finders {
            background-color: #E8388A;
        }

        &_speakers {
            background-color: #20D5C4;
        }
    }
  }

  .bottom-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    margin-top: 24px;
    margin-bottom: 50px;
    color: #ffffff;

    @media (max-width 800px) {
        font-size: 16px;
        line-height: 22px;
    }

    span {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #20D5C4;
        margin-left: 8px;
        cursor: pointer;

        @media (max-width 800px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
  }

    .bg {
        background: #161E27 url(${footerBg}) no-repeat center;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        transform: scaleX(1.5);
        transition: transform 2.5s linear 0.2s;
      
        @media (max-width: 768px) {
          background: #161E27 url(${footerBg}) center bottom no-repeat;
          background-size: cover;
        }
    }

    .hero.about__hero {
      padding: 160px 0 73px;
    }

    &.move-bg {
      .bg {
          transform: scaleX(1);
      }; 
    }

    .home__link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hero {
        padding: 120px 0  0 0;
        text-align: center;
        position: relative;
        overflow: hidden;
        
       
        @media (max-width: 1360px) {
           padding: 184px 0 136px;
        }
        
        @media (max-width: 1024px) {
           padding: 110px 0 80px;
           
           &.about__hero {
              padding: 90px 0 42px;
            }
        }
        
        .title {
            font-weight: 600;
            font-size: 48px;
            line-height: 60px;
            color: #ffffff;
            margin-bottom: 40px;

            @media (max-width: 800px) {
            font-weight: 600;
            font-size: 36px;
            line-height: 42px;
            }
        }

        .home__btn {
            font-weight: 600;
            font-size: 14px;
            
             @media (max-width: 1024px) {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                height: 38px;
                width: 200px;
                white-space: nowrap;
                padding: 6px 0;
                height: 48px;
                width: 222px;
                display: flex;
                justify-content: center;
                align-items: center;
             }
        }
    
        .hero__text {
            font-weight: 300;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #fff;
            margin-bottom: 25px;
          
            @media (max-width: 1024px) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 20px;
              padding: 0 15px;
            }
        }
        
        .container-fluid {
          @media (max-width: 768px) {
            padding: 0 16px;
            max-width: 360px;
            margin: 0 auto;
          }
        }
    }
`;
