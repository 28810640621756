import React from 'react';
import {ReactComponent as Add} from '../../../../../assets/add.svg';
import styles from "../../../BookingForm.module.scss";
import BookingFormAdditionalNotes from "../../../BookingFormAdditionalNotes/BookingFormAdditionalNotes";
import BookingFormFinderAdditionalNotes
  from "../../../BookingFormFinder/BookingFormFinderAdditionalNotes/BookingFormFinderAdditionalNotes";

export default function NotesBody(
  {
    view,
    handleOpen,
    notes,
    deleteNotesHandler,
    editNotesHandler,
    disabled
  }) {
  if (disabled) {
    if (notes.length) {
      return (
        <BookingFormAdditionalNotes
          deleteNotesHandler={deleteNotesHandler}
          notes={notes}
          editNotesHandler={editNotesHandler}
          disabled={disabled}
        />
      );
    }

    return null;
  }

  return (
    <div>
      {
        view === 'speaker'
          ?
          <div>
            {
              !notes.length
                ?
                <div
                  onClick={handleOpen}
                  style={{
                    marginTop: '20px',
                    marginBottom: '30px'
                  }}
                >
                  <Add
                    className={styles.budget_add_imgPlus}
                    style={{
                      paddingRight: '5px',
                      width: '27px',
                      height: '27px',
                      cursor: 'pointer'
                    }}
                  />
                  <span style={{
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '-0.02em',
                    color: '#20D5C4',
                    cursor: 'pointer',
                  }}>
                    Add additional notes to the brief
                  </span>
                </div>
                :
                <BookingFormAdditionalNotes
                  deleteNotesHandler={deleteNotesHandler}
                  notes={notes}
                  editNotesHandler={editNotesHandler}
                />
            }
          </div>
          :
          <BookingFormFinderAdditionalNotes notes={notes}/>
      }
    </div>
  );
}