import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { sliderData } from './sliderData';
import { MarketSliderStyled } from './marketplace-styles';
import { SliderCardStyled } from './slider-card-styled';
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import greenFill from '../../../assets/greenfill.png';

export const MarketPlaceSlider = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const ref = useRef();

    const sliderOptions = {
        infinite: true,
        speed: 1500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        swipe: false,
        arrows: false,
        beforeChange: (old, next) => {
            setActiveSlide(next);
        },
    };

    useEffect(() => {
        let arr = document.querySelectorAll('.first-slide .slide-card-green-fill');
        let arr2 = document.querySelectorAll('.first-slide .slide-card-green-fill-title');

        if (arr) arr.forEach(elem => elem.classList.add('slide-card-green-fill__active'));
        if (arr2) arr2.forEach(elem => elem.classList.add('slide-card-green-fill-title__active'));

        return () => {
            arr.forEach(elem => elem.classList.remove('slide-card-green-fill__active'));
            arr2.forEach(elem => elem.classList.remove('slide-card-green-fill-title__active'));
        };
    }, [activeSlide]);

    const getSlideClassName = (index) => {
        if (activeSlide === 6 && index === 0) return 'third-slide';
        if (activeSlide === 7 && index === 0) return 'second-slide';
        if (activeSlide === 7 && index === 1) return 'third-slide';
        if (activeSlide === 8 && index === 0) return 'first-slide';
        if (activeSlide === 8 && index === 1) return 'second-slide';
        if (activeSlide === 8 && index === 2) return 'third-slide';
        if (activeSlide === 9 && index === 9) return 'third-slide';
        if (activeSlide === 9 && index === 0) return 'second-slide';
        if (activeSlide === 9 && index === 1) return 'first-slide';
        if (activeSlide === 9 && index === 2) return 'second-slide';
        if (activeSlide === 9 && index === 3) return 'third-slide';

        if (index === activeSlide || index === activeSlide + 4) {
            return 'third-slide';
        } else if (index === activeSlide + 1 || index === activeSlide + 3) {
            return 'second-slide';
        } else if (index === activeSlide + 2) {
            return 'first-slide';
        } else return 'fourth-slide';
    };

    const getCardClassName = (index) => {
        if (activeSlide === 6 && index === 0) return 'slide-card-right';
        if (activeSlide === 6 && index === 1) return 'slide-card-right';

        if (activeSlide === 7 && index === 0) return 'slide-card-right';
        if (activeSlide === 7 && index === 1) return 'slide-card-right';
        if (activeSlide === 7 && index === 2) return 'slide-card-right';

        if (activeSlide === 8 && index === 1) return 'slide-card-right';
        if (activeSlide === 8 && index === 2) return 'slide-card-right';
        if (activeSlide === 8 && index === 3) return 'slide-card-right';

        if (activeSlide === 9 && index === 0) return 'slide-card-left';
        if (activeSlide === 9 && index === 2) return 'slide-card-right';
        if (activeSlide === 9 && index === 3) return 'slide-card-right';
        if (activeSlide === 9 && index === 4) return 'slide-card-right';

        if (index === activeSlide || index <= activeSlide + 2) {
            return 'slide-card-left';
        } else {
            return 'slide-card-right';
        };
    };

    return (
        <MarketSliderStyled>
            <div className="disable-click-wrapper"></div>
            <div className="wrapper">
                {/*<>
                    <div
                        className="slider-point slider-point__red"
                        style={{ width: '8px', height: '8px', top: '165px', left: '175px' }}
                    ></div>
                    <div
                        className="slider-point slider-point__red"
                        style={{ width: '33px', height: '33px', top: '135px', left: '205px' }}
                    ></div>
                    <div
                        className="slider-point slider-point__red"
                        style={{ width: '16px', height: '16px', top: '207px', left: '368px' }}
                    ></div>
                    <div
                        className="slider-point slider-point__green"
                        style={{ width: '21px', height: '21px', top: '175px', right: '175px' }}
                    ></div>
                    <div
                        className="slider-point slider-point__green"
                        style={{ width: '50px', height: '50px', top: '185px', right: '120px', }}
                    ></div>
                </>*/}
                <Slider {...sliderOptions}>
                    {sliderData.map((elem, index) => {
                        return (
                            <SliderCardStyled key={elem.id} type={elem.type}>
                                <div key={elem.id} style={{ alignItems: 'center' }} ref={ref}>
                                    <div className="card-wrapper">
                                        <div className={`card-container ${getSlideClassName(index)}`}>
                                            <div className={`slide-card ${getCardClassName(index)}`}>
                                                <div className="green-fill-container">
                                                    <img
                                                        className="slide-card-green-fill-title"
                                                        src={greenFill}
                                                    />
                                                    <div className={`slide-card__text slide-card__title`}>{elem.title}</div>
                                                </div>
                                                <div className="green-fill-container">
                                                    <img
                                                        className="slide-card-green-fill"
                                                        src={greenFill}
                                                    />
                                                    <div className={`slide-card__text slide-card__desc`}>{elem.text}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SliderCardStyled>
                        )
                    })}
                </Slider>
            </div>
        </MarketSliderStyled >
    )
}