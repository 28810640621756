import React from 'react';
import styled from "styled-components";
import { ReactComponent as Cross } from '../assets/icons/cross.svg';
import { FilterList } from "./FilterList";

export const PopUpFilter = ({ onPortalClose, visible, onChangeFilter, queryOptions }) => {
  return (
    <PopUpFilterStyles className={`${visible ? 'visible' : ''}`}>
      <div className={`body ${visible ? 'visible' : ''}`}>
        <button onClick={() => onPortalClose('filterPortal')} className="btn-close"><Cross /></button>
          <FilterList onChangeFilter={onChangeFilter} queryOptions={queryOptions} />
      </div>
    </PopUpFilterStyles>
  )
}

const PopUpFilterStyles = styled.section`
width: 100%;
height: 100vh;
background: rgba(0,0,0,0);
position:fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 1000;
transition: background-color 0.4s ease-in-out;

  &.visible {
  background: rgba(0,0,0,0.4);
  }
  
  .btn-remove,
  .btn-close {
    width: 30px;
    height: 30px;
    border: none;
    background: transparent;
    padding: 0;
    
    &:focus {
      outline: none;
    }
    
     &:hover {
      path {
        fill: #20D5C4;
      }
    }
  }
  
  .btn-close {
    position:absolute;
    right: 45px;
    top: 45px;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
  }
  
  .btn-remove{
    width: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 30px;
    color: #797979;
    
    svg {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
    
    path {
      fill: #293749;
    }
  }
  
  @media (max-width: 1360px) {
      .body {
          width: 625px;
          padding: 94px 85px 40px 94px;
      }
      .btn-close {
          right: 88px;
          top: 38px;
      }
  }
  
  @media (max-width: 768px) {
      border-radius: 0;
      min-height: 100vh;
      
     .body.visible {
        padding: 0;
        width: 100%;
     }
     .btn-close {
        top: 32px;
        right: 18px;
     }
  }
`