
const initialState = {
    shortListPortal: false,
    filterPortal: false,
    agreeModal: false,
};
const portalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PORTAL_STATUS':
            return {
                ...state,
                [action.payload.portalType]: action.payload.portalStatus,
            };
            case 'RESET_PORTAL_STATUS':
            return initialState;
        default:
            return state;
    }
};

export default portalReducer;
