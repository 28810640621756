import {
  SET_USER_DATA,
  SET_TOKEN,
  SET_STRIPE_CONNECTED_STATUS,
  SET_EMAIL,
  SET_ACCOUNT_DETAILS
} from '../constants/profileConstants';

const initialState = {
  id: null,
  email: null,
  avatar: null,
  isVerified: false,
  role: null,
  firstName: null,
  lastName: null,
  token: null,
  sendbirdId: null,
  sendbirdToken: null,
  isConnectedToStripe: false,
  businessName: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        id: action.payload.id,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        role: action.payload.role,
        sendbirdId: action.payload.sendbirdId,
        sendbirdToken: action.payload.sendbirdToken,
        isConnectedToStripe: action.payload.isConnectedToStripe,
        businessName: action.payload.businessName
      };

    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };

    case SET_EMAIL:
      return {
        ...state,
        email: action.payload
      };

      case SET_ACCOUNT_DETAILS: {
        const { firstName, lastName, businessName } = action.payload;
        return {
          ...state,
          firstName,
          lastName,
          businessName
        };
      }

    case 'SET_IS_LOGIN':
      return {
        ...state,
        isVerified: action.payload
      };

    case 'CLEAR_USER':
      return {
        ...initialState
      };

    case 'SET_AVATAR':
      return {
        ...state,
        avatar: action.payload
      };

    case SET_STRIPE_CONNECTED_STATUS:
      return {
        ...state,
        isConnectedToStripe: action.payload
      };

    default:
      return state;
  }
};

export default user;
