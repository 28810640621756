import {Loader as GoogleMapsLoader} from '@googlemaps/js-api-loader';

const promise =  new GoogleMapsLoader({
  version: "weekly",
  libraries: ["places"]
}).load().then(google => {
  if (!google) {
    throw new Error('failed to fetch location from google');
  }

  return new google.maps.places.PlacesService(document.createElement('div'));
}).catch(e => console.error(e));

export default placeId => promise.then(service => new Promise((resolve, reject) => {
  service.getDetails({ placeId }, (place, status) => {
    if (status !== 'OK') {
      reject('Error while getting location details');
      return;
    }

    const names = ['', ''];
    const fullName = [];
    place.address_components.forEach(item => {
      if (item.types.indexOf('administrative_area_level_1') > -1) names[0] = item.short_name
      if (item.types.indexOf('country') > -1) names[1] = item.long_name
    });
    if (names[0]) fullName.push(names[0]);
    if (names[1]) fullName.push(names[1]);

    resolve(fullName.join(', '));
  });
}));
