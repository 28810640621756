import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { BackSideCard } from './SpeakerCard/SpeakerCardBackSide';
import { SpeakerCard } from './SpeakerCard/SpeakerCard';
import {
    addUsersToShortlist,
    removeUsersFromShortlist
} from '../store/actions/findSpeakersActions';
import ConfirmationUserModal from '../_components/modals/ConfirmationUserModal';
import {toggleLoginModal} from '../store/actions';


export const SpeakersList = ({ data }) => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const shortList = useSelector(state => state.speakers.shortList.data);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const toggleShortlist = (id) => {
        if (!isAuthenticated) {
            setModalOpen(true);

            return;
        }

        if (shortList.some(speaker => speaker.id === id)) {
            dispatch(removeUsersFromShortlist([id]));
        } else {
            if (shortList.length < 50) {
                dispatch(addUsersToShortlist([id]));
            }
        }
    };

    return (
      <>
          <ConfirmationUserModal
            isOpen={modalOpen}
            text="Log in or sign up to shortlist speakers"
            onConfirm={() => {
                setModalOpen(false);

                window.scrollTo({ top: 0 });

                dispatch(toggleLoginModal());
            }}
            onCancel={() => {
                setModalOpen(false);

                window.scrollTo({ top: 0 });

                history.push('/register-business');
            }}
            onClose={() => setModalOpen(false)}
            cancelButtonText="sign up"
            confirmButtonText = "log in"
          />
            <SpeakersListStyles>
                {
                    data.map((elem, index) => {
                        return (
                            <SpeakerCard
                                key={index}
                                elem={elem}
                                backComponent={<BackSideCard {...elem} toggleShortlist={toggleShortlist} />}
                                toggleShortlist={toggleShortlist}
                            />
                        )
                    })
                }
            </SpeakersListStyles>
      </>
    )
}

const SpeakersListStyles = styled.section`
    display: flex;
    flex-wrap: wrap;
    width: 1002px;;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .react-card-flipper {
      margin-bottom: 30px;
  }
  
  .container {
      width: 1310px;    
      max-width: 1310px;
  }
`;