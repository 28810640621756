import React from 'react';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>The benefits of booking a business speaker</h2>

      <p className={s.paragraph}>
        At <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a>, we're proud
        to represent some of the inspirational business speakers from Australia and internationally.
        Access from some of the most recognizable and highly regarded leaders of brands and
        organizations who can share their knowledge and best-in-class strategies at your next
        event. If you are looking for the  best business keynote speakers to inspire your
        team, get in touch today.
      </p>


      <p className={s.paragraph}>
        Booking a business public speaker for your next event can bring a wide range of
        benefits. From sharing industry expertise and insight to inspiring and motivating
        your team to help your organisation succeed. Here are some key benefits of booking
        a leading business speaker
        with <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a>
      </p>

      <h3 className={s.greenHeadingList}>Expertise and Insight</h3>
      <p className={s.paragraph}>
        Professional business conference speakers are recognised authorities and experts
        in their fields and bring a wealth of knowledge and insight to your event. They
        provide valuable industry insights, share best practices, and offer actionable
        strategies that attendees can apply to their work to gain a competitive advantage.
      </p>

      <h3 className={s.greenHeadingList}>Inspiration and Motivation</h3>
      <p className={s.paragraph}>
        A great business speaker has the ability to inspire and motivate teams and audiences,
        to achieve their full potential. Sharing their personal stories of success, actionable
        tips to overcoming obstacles, and a fresh perspective on common challenges empowering
        your audience to do the same.
      </p>

      <h3 className={s.greenHeadingList}>Networking Opportunities</h3>
      <p className={s.paragraph}>
        Booking best business keynote speakers can also create networking opportunities
        for attendees. Business speakers are well-connected in their industries and can
        introduce attendees to other professionals or industry resources. Additionally,
        the shared experience of attending a business conference speaker’s presentation
        can help create a community among attendees.
      </p>

      <h3 className={s.greenHeadingList}>Customized Content</h3>
      <p className={s.paragraph}>
        When you book a business speaker
        through <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a>, you
        will get direct access to experts who will customise content for your event, ensuring
        that they get the most out of the experience and ensuring that the speaker’s message
        aligns with your organisation’s values and goals.
      </p>

      <h3 className={s.greenHeadingList}>Improved ROI</h3>
      <p className={s.paragraph}>
        By booking the best motivational speakers for business, you can improve the ROI from
        your event. The right business speaker can attract more attendees, increase engagement
        and participation, and create a truly memorable experience. This can lead to increased
        productivity and a more motivated and inspired team, which can translate into tangible
        results for your organisation.
      </p>
    </>
  );
};

const TopicPageBusiness = () => {
  return (
    <TopicPageBase
      topicAlias="business"
      headerSubtitle={<>
        Book leading business speakers.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a Business speaker?"
      trendingSpeakersHeading="Trending Business Speakers"
      why={<Why />}
      speakerTopicText="business"
    ></TopicPageBase>
  )
};

export default TopicPageBusiness;
