import React from 'react';
import MoneyFormat from '../components/MoneyFormat';
import styles from '../BookingForm.module.scss';

export default function BookingFormInstalmentsFirst({ speakerFee, platformFee, incrementals, taxVisible, gstVat, total, role }) {
  return (
    <div className={styles.instalmentFirst}>
      <div className={styles.instalmentFirst_column}>
        <span>Speaker Fee</span>
        <span className={styles.instalmentFirst_paid}><MoneyFormat value={speakerFee} /></span>
      </div>
      {
        (taxVisible || gstVat > 0) && (
          <div className={styles.instalmentFirst_column}>
            <span>GST/VAT</span>
            <span className={styles.instalmentFirst_paid}><MoneyFormat value={gstVat} /></span>
          </div>
        )
      }
      {
        !isNaN(platformFee) && (
          <div className={styles.instalmentFirst_column}>
            <span>Platform fee</span>
            <span className={styles.instalmentFirst_paid}>-<MoneyFormat value={Math.abs(platformFee)} /></span>
          </div>
        )
      }

      {
        !!incrementals && (
          <div className={styles.instalmentFirst_column}>
            <span>Incrementals</span>
            <span className={styles.instalmentFirst_paid}><MoneyFormat value={incrementals} /></span>
          </div>
        )
      }

      <div className={styles.instalmentFirst_column}>
        <span
          className={styles.instalmentFirst_paid}
          style={{fontSize: '13px'}}
        >
          {
            role === 'speaker' ? (
              'Paid to you'
            ) : role === 'business' ? (
              'Paid to speaker'
            ): null
          }

        </span>
        <span className={styles.instalmentFirst_paid}><MoneyFormat value={total} /></span>
      </div>
    </div>
  );
}