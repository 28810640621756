import React, {useState} from 'react';
import styles from './BookingForm.module.scss';
import BookingFormModalNotes from './BookingFormModalNotes/BookingFormModalNotes';
import NumberFormat from 'react-number-format';
import ReadMoreBooking from './ReadMore/ReadMoreBooking';
import BookingFormModal from "./components/BookingFormModal/BookingFormModal";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import BudgetFormatted from '../BudgetFormatted';

export default function BookingFormHeader(
  { eventName,
    setEventName,
    brief,
    changeBrief,
    budget,
    view,
    additionalNote,
    setAdditionalNote,
    bookingStatus,
    isEditable
  }) {
  const [value, setValue] = useState('');

  const deleteNotesHandler = () => {
    setAdditionalNote('');
  }

  const changeNotesHandler = (value) => {
    if (value) setAdditionalNote(value);
  }

  const changeEventNameHandler = (value) => {
    if (value) setEventName(value);
  }

  const changeBriefHandler = (value) => {
    if (value) changeBrief(value);
  }

  const changeValueHandler = (e) => {
    setValue(e.target.value);
  }

  const submitModalHandler = (value) => {
    changeNotesHandler(value);
    setValue('');
  }

  const submitEventNameModalHandler = (value) => {
    changeEventNameHandler(value);
    setValue('');
  }

  const submitBriefModalHandler = (value) => {
    changeBriefHandler(value);
    setValue('');
  }

  return (
    <div className={styles.event_name}>
      <div className={styles.event_name_logs}>
        <h5 className={styles.subheading}>Event name</h5>
        <h5 className={styles.event_name_text}>
          <BookingFormModal
            eventName={eventName}
            value={value}
            submitEventNameModalHandler={submitEventNameModalHandler}
            changeValueHandler={changeValueHandler}
            mode={'eventName'}
            disabled={!isEditable}
          />
        </h5>
      </div>
      <div className={styles.brief}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap'
        }}>
          <h5 className={styles.subheading}>The brief</h5>
          <div style={{
            maxWidth: '600px'
          }}>
            <ReadMoreBooking>
              <BookingFormModal
                mode={"brief"}
                brief={brief}
                submitBriefModalHandler={submitBriefModalHandler}
                changeValueHandler={changeValueHandler}
                value={value}
                disabled={!isEditable}
              />
            </ReadMoreBooking>
          </div>
        </div>
        <div className={styles.budget}>
          <h5 className={styles.subheading}>Proposed budget (AUD)</h5>
          <h5 className={styles.budget_money}>
            <BudgetFormatted
              from={budget?.from}
              to={budget?.to}
            />
          </h5>
        </div>
        <div>
          <BookingFormModal
            className={styles.budget_add}
            notes={additionalNote}
            changeNotesHandler={changeNotesHandler}
            changeValueHandler={changeValueHandler}
            deleteNotesHandler={deleteNotesHandler}
            value={value}
            submitModalHandler={submitModalHandler}
            setAdditionalNote={setAdditionalNote}
            view={view}
            mode={"notes"}
            disabled={!isEditable}
          />
        </div>
      </div>
    </div>
  );
}
