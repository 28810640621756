import {
    SPIDER,
    GET_FISH_REQUEST,
    GET_FISH_SUCCESS,
    GET_FISH_FAILURE,
} from '../actions';

const initialState = {};

const test = (state = initialState, action) => {
    switch (action.type) {
        case SPIDER:
            return { ...state, message: "OH BOY NOW I'VE GOT A POWER" };
        case GET_FISH_REQUEST:
            return { ...state, message: 'LOADING' };
        case GET_FISH_FAILURE:
            return { ...state, message: 'THIS IS SPOILED!!!!' };
        case GET_FISH_SUCCESS:
            return { ...state, message: 'SOME SOY SOUCE, PLEASE' };
        default:
            return state;
    }
};

export default test;
