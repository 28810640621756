import React from 'react'
import styled from 'styled-components'
import constants from '../constants';
import img from '../assets/ComingSoon/payments.png'

export default (props) => {

    return <SCCStyled>
        <div className="scc-content">
            <div className="scc-text">
                <h2 className="scc-text__label">Once you confirm your first booking</h2>
                <p className="scc-text__sublabel">Access automated payments.
                <br className="desktop-line-break" /> Get paid on time every time</p>
                <p className="scc-text__desc">Finders Speakers offers an easy and secure way to get paid, with scheduled and automated payments made direct to your bank account.</p>
                <p className="scc-text__list-title">How it works</p>
                <ul className="scc-text__list">
                    <li className="scc-text__list-item">Payments received straight to your account.</li>
                    <li className="scc-text__list-item">No more messy money conversations and time spent chasing clients.</li>
                    <li className="scc-text__list-item">Auto-generated invoices sent directly to your client and stored on your dashboard so your records are always up to date.</li>
                </ul>
            </div>
            <div className="scc-image">
                <img src={img} className="scc-image__big"/>
            </div>
        </div>

        <div className="scc-footer">
            All Rights Reserved     {constants.copyright}
        </div>
    </SCCStyled>
}

const SCCStyled = styled.div`
    background: #19212B;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 160px;
    padding-bottom: 100px;

    .desktop-line-break {
        display: inline;
    }

    .mobile-line-break {
        display: none;
    }

    @media (max-width: 1024px) {
        padding-top: 100px;
        padding-bottom: 80px;

        .mobile-line-break {
            display: inline;
        }

        .desktop-line-break {
            display: none;
        }
    }
    
    .scc {

        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-image {
            position: relative;
            margin: 0 15px;

            &__big {
                width: 100%;
                max-width: 595px;
            }
        }

        &-text {
            max-width: 400px;
            margin: 0 15px;

            &__label {
                color: #ffffff;
                font-size: 28px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__sublabel {
                color: #20D5C4;
                font-size: 28px;
                line-height: 32px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__desc {
                color: #ffffff;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 30px;
            }

            &__list {
                list-style: none;
                padding-left: 20px;

                &-title {
                    color: #20D5C4;
                    font-size: 15px;
                    font-weight: 300;
                    margin-bottom: 10px;
                }

                &-item {
                    color: #ffffff;
                    margin-bottom: 10px; 

                    &:before {
                        content: "\\2022";
                        color: #20D5C4;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        &-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: 300;
            color: #ffffff;
            text-align: center;
        }
    }
`