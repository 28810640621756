import constants from './constants';

export default {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: ' Finders Speakers',
  description: 'Easily book leading keynote and celebrity speakers. Deliver a WOW event! All topics and budgets. Chat with our team today!',
  url: process.env.REACT_APP_SITE_URL,
  logo: `${process.env.REACT_APP_SITE_URL}/images/logo.png`,
  contactPoint: [
    {
      '@type': 'ContactPoint',
      email: 'hello@findersspeakersco.com',
      telephone: constants.phone,
      availableLanguage: 'English'
    }
  ]
};
