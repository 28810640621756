import React from 'react';
import useBlockCrawling from '../helpers/useBlockCrawling';
import Background from '../assets/coming-soon-background.png';
import CentralImage from '../assets/coming-soon.png';
import './styles.scss';

export const ComingSoonPage = () => {
    useBlockCrawling();

    return (
        <>
            <div
                className="coming-soon-background"
                style={{
                    backgroundImage: `url(${Background})`,
                    backgroundSize: 'cover'
                }}
            >
                <img className="coming-soon-central-image" src={CentralImage} alt="coming soon" />
            </div>
        </>
    )
}