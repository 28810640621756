import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ApiDataProvider from '../api/apiClient';
import { setChatEmpty } from '../store/actions';
import { setUserData, setToken } from '../store/actions/profileActions';
import { SET_AUTHENTICATED } from '../store/constants/profileConstants';
import MessageBoxUserModal from '../_components/modals/MessageBoxUserModal';
import Spinner from '../_components/Spinner';


export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams();

  const [userRole, setUserRole] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    ApiDataProvider.verifyNewEmail(token)
    .then(result => {
      setIsSuccess(true);
      dispatch(setToken(result.token));

      dispatch({
        type: SET_AUTHENTICATED,
        payload: true
      });

      dispatch({
        type: 'SET_IS_LOGIN',
        payload: true
      });

      dispatch(setChatEmpty());

      const { user } = result;

      setUserRole(user.role);

      dispatch(setUserData({
        id: user.id,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        role: user.role,
        sendbirdId: user.sendbird_id,
        sendbirdToken: user.sendbird_token,
        isConnected: user.is_connected_to_stripe
      }));
    }).catch(e => {
      console.error(e);
      setErrorMessage(e.message);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return <MessageBoxUserModal
    isOpen={true}
    title={isSuccess ? 'Your new email is confirmed' : 'Failed to confirm your email'}
    message={errorMessage}
    onOk={() => {
      if (isSuccess) {
        if (userRole.toLowerCase() === 'speaker') {
          history.push('/builder-step1');
        } else {
          history.push('/find-speakers');
        }
      } else {
        history.push('/');
      }
    }}
  />;
};
