import React, {useCallback, useEffect, useState} from 'react';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import useWindowDimensions from "../helpers/useWindowDimentsions";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataGet } from "../api/helperFunctions";
import { AutoComplete, Input } from 'antd';
import styled from "styled-components";
import styles from './SearchFormSmall.module.scss';
import ApiDataProvider from '../api/apiClient';


export const SearchFormSmall = ({ value, onSearch, onChange }) => {
    const [dataSource, setDataSource] = useState([]);

    const debounced = useCallback(throttle(value => {
        if (!value.trim()) {
            setDataSource([]);
            onSearch('');
        } else {
            const keywords = value.trim().split(/\s/).map(s => s.trim());
            ApiDataProvider.getSpeakers({ keywords }).then(result => {
                setDataSource(result.data.map(s => `${s.first_name} ${s.last_name}`));
            }).catch(() => {});
        }
    }, 1000), []);

    const onChangeInput = (value) => {
        onChange(value);
        debounced(value);
    };

    const onPressEnter = e => {
        e.preventDefault();

        onSearch(value);
    };

    return (
        <FormStyles onSubmit={onPressEnter}>
            <AutoComplete
                className="s-home__search-label"
                value={value}
                showSearch
                placeholder='Keyword search'
                optionFilterProp="children"
                onChange={onChangeInput}
                onSelect={value => onSearch(value)}
                // dataSource={dataSource}
                autoComplete='off'
            >
                <Input className="s-home__search-input a b c" />
            </AutoComplete>
        </FormStyles>
    );
}

const FormStyles = styled.form`
    width: 308px;
    height: 40px;

    @media(max-width: 800px) {
        height: 40px;
        width: 320px;
        margin-bottom: 0;
    }

    .ant-select-selection:hover,
    .ant-select-focused .ant-select-selection,
    .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
        border: none;
        box-shadow: none;
    }

    .ant-empty {
        display: none;
    }

    .ant-select-arrow .ant-select-arrow-icon svg {
        display: none;
    }

    .ant-select-selection__rendered {
        height: 100%;

        .ant-select-selection-selected-value {
            padding-top: 10px;
            padding-left: 3px;
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            color: #293749;
        }
    }
    
    .s-home__search-label {
        width: 100%;
        height: 100%;
        box-shadow: 0 15px 50px rgba(167, 171, 172, 0.1);
        border-radius: 5px;
        border: 1px solid #293749;
    }

    .s-home__search-input.a.b.c.ant-input {
        width: 100%;
        height: 100%;
        padding: 6px 16px;
        border-radius: 5px;
        border-width: 0;
        box-shadow: unset;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        
        &:focus, &:hover {
            border-width: 0;
            border-right-width: 0 !important;
        }
    }
    
    .ant-select-selection__placeholder {
        color: #293749;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        padding-left: 4px;
    }
`;
