import React from 'react';
import BaseInput from '../../../_components/BaseInput';

export default props => {
  return (
    <BaseInput
      {...props}
      InputProps={{
        style: {
          fontSize: '12px'
        }
      }}
    />
  );
};
