import {
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL
} from '../constants/authenticationConstants';
import { signUp as signUpApi } from '../../api/helperFunctions';
import ApiDataProvider from '../../api/apiClient';
import sendbird from '../../sendbird';
import { setUserData, setToken, initSendbird } from './profileActions';
import { setChatEmpty } from '../actions';
import {SET_AUTHENTICATED} from '../constants/profileConstants';
import getFileUrl from '../../helpers/getFileUrl';

export const signUp = (token, signUpData, hideModal, history) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SIGNUP_START
      })
      const result = await signUpApi(token, signUpData);

      hideModal();
      dispatch({
        type: SIGNUP_SUCCESS
      });

      dispatch({
        type: 'REGISTER_SUCCESS',
        payload: result.data.user
      });

      history.push('/sign-up-email');
      
    } catch (e) {
      let payload = null;
      if (e.response) {
        if (e.response.status === 422) {
          payload = e.response.data.errors;
        } else if (e.response.data.message) {
          payload = { generalError: e.response.data.message };
        } else {
          payload = { generalError: 'Error' };
        }
      } else {
        payload = { generalError: 'Error' };
      }
      dispatch({
        type: SIGNUP_FAIL,
        payload
      })
    }
  }
};

export const login = (email, password, callback) => {
  return async dispatch => {
    try {
      const { user, token } = await ApiDataProvider.login(email, password);
      dispatch(setUserData(user));
      dispatch(setToken(token));
      dispatch({
        type: SET_AUTHENTICATED,
        payload: true
      });

      if (user.sendbird_id && user.sendbird_token) {
        dispatch(initSendbird(user.sendbird_id, user.sendbird_token));
      } else {
        dispatch(setChatEmpty());
      }

      if (callback) {
        callback();
      }



      dispatch({
        type: 'RESET_ERRORS'
      });

      dispatch({
        type: 'SET_IS_LOGIN',
        payload: true
      });

      return user;
    } catch (e) {
      dispatch({
        type: 'LOGIN_FAILURE',
        payload: {
          message: e.message,
          errors: e.errors || undefined
        }
      });

      dispatch({
        type: SET_AUTHENTICATED,
        payload: false
      });

      return { error: true };
    }
  };
};

export const loginUser = (email, password, history, callback) => {
  return async dispatch => {
    const result = await dispatch(login(email, password, callback));

    if (result?.error) {
      return;
    }

    const accountDetails = await ApiDataProvider.getAccountDetails();

    dispatch({
      type: 'SET_AVATAR',
      payload: `${getFileUrl(accountDetails.data.photo)}`,
    });

    if (result.role === 'Speaker') {
      history.push('/preview-profile');
    }
    if (result.role === 'Business') {
      history.push('/');
    }
  };
};

export const logout = callback => {
  return async dispatch => {
    try {
      await sendbird.drop();
    } catch (e) {
      console.error(e);
    }

    try {
      await ApiDataProvider.logout();
    } catch (e) {
      console.error(e);
    }

    dispatch({
      type: 'CLEAR_USER'
    });

    dispatch({
      type: 'SET_IS_LOGIN',
      payload: false
    });

    dispatch({
      type: SET_AUTHENTICATED,
      payload: false
    });

    if (callback) {
      callback();
    }
  };
};