import s from './ReadMore.module.scss';
import React, { useEffect, useState, useRef } from 'react';

const ReadMore = ({ children, maxHeight = 200 }) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [isButtonVisible, setIsButtonVisible ] = useState(false); 
  const readMoreTextElement = useRef(null);
  const readMoreTextContentElement = useRef(null);

  useEffect(() => {
    if (readMoreTextContentElement.current.scrollHeight > maxHeight) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
      setIsOpen(true);
    }
  });

  return <>
    <div className={s.readMoreText} ref={readMoreTextElement}>
      <div className={s.readMoreTextContent} style={{ maxHeight: isOpen ? 'none' : (maxHeight + 'px') }} ref={readMoreTextContentElement}>
        {children}
      </div>
    </div>

    { isButtonVisible &&
    <div>
      <span className={s.readMoreButton} onClick={() => setIsOpen(!isOpen)}>
        { isOpen ? 'read less' : 'read more' }
      </span>
    </div>
    }
  </>;
};

export default ReadMore;
