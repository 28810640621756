import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import constants from '../constants';
import img from '../assets/ComingSoon/chat.png'

const texts = {
    speaker: {
        label: 'Once you receive your first enquiry',
        sublabel: 'You can access real time chat with event organisers and enjoy direct conversations',
        desc: 'Finders Speakers offers an easy and secure way to chat direct with event organisers. Easily track all your conversations all in the one place, via desktop and mobile.',
        howItWorks: [
          'Receive direct enquiries from event planners, straight to your inbox'
        ]
    },
    finder: {
        label: 'Once you receive your first email response',
        sublabel: 'You can chat direct with speakers',
        desc: 'Finders Speakers offers an easy and secure way to chat direct with speakers. Easily track all your conversations all in the one place, via desktop and mobile.',
        howItWorks: [
            'Receive direct enquiries from speakers, straight to your inbox'
        ]
    },
};

export default () => {
    const role = useSelector(state => state.user.role);
    const text = role === 'speaker' ? texts.speaker : texts.finder;

    return <SCPStyled>
        <div className="scp-content">
            <div className="scp-text">
                <h2 className="scp-text__label">{text.label}</h2>
                <p className="scp-text__sublabel">{text.sublabel}</p>
                <p className="scp-text__desc">{text.desc}</p>
                <p className="scp-text__list-title">How it works</p>
                <ul className="scp-text__list">
                    <li className="scp-text__list-item">{text.howItWorks[0]}</li>
                    <li className="scp-text__list-item">Negotiate terms, upload documents, manage contracts and payments online</li>
                    <li className="scp-text__list-item">Drive better collaborations and longer lasting relationships.</li>
                </ul>
            </div>
            <div className="scp-image">
                <img src={img} className="scp-image__big"/>
            </div>
        </div>

        <div className="scp-footer">
            All Rights Reserved     {constants.copyright}
        </div>
    </SCPStyled>
}

const SCPStyled = styled.div`
    background: #19212B;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 160px;
    padding-bottom: 100px;

    @media (max-width: 1024px) {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    .scp {

        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-image {
            position: relative;
            margin: 0 15px;

            &__big {
                width: 100%;
                max-width: 595px;
            }
        }

        &-text {
            max-width: 400px;
            margin: 0 15px;

            &__label {
                color: #ffffff;
                font-size: 28px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__sublabel {
                color: #20D5C4;
                font-size: 28px;
                line-height: 32px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__desc {
                color: #ffffff;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 30px;
            }

            &__list {
                list-style: none;
                padding-left: 20px;

                &-title {
                    color: #20D5C4;
                    font-size: 15px;
                    font-weight: 300;
                    margin-bottom: 10px;
                }

                &-item {
                    color: #ffffff;
                    margin-bottom: 10px; 

                    &:before {
                        content: "\\2022";
                        color: #20D5C4;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        &-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: 300;
            color: #ffffff;
            text-align: center;
        }
    }
`