import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as Mark } from '../../../../assets/question_mark.svg';
import styles from '../../BookingForm.module.scss';
import MoneyInput from '../../components/MoneyInput';
import MoneyFormat from '../../components/MoneyFormat';

export default function BookingFormOfferSpeakerList(
  {
    view,
    speakerFee,
    changeSpeakerFee,
    speakerFeeInvalid,
    taxVisible,
    gstVat,
    disabledSpeakerFee,
    platformFee,
    netSpeakerFee
  }) {
  return (
    <div>
      <div className={styles.offer_list_roll_first}>
        <div className={styles.offer_list_roll_elems}>
          <div
            className={styles.offer_list_roll_elems_head}
          >
            Speaker fee
            <span className={styles.aud}>(AUD)</span>
            <Tooltip title={'exclusive of gst'}>
              <Mark className={styles.markIcon} />
            </Tooltip>
          </div>
        </div>
        <div className={styles.speakerFeeInput}>
          {
            view === 'speaker' ? (
              <MoneyInput
                value={speakerFee}
                onChange={changeSpeakerFee}
                disabled={disabledSpeakerFee}
                error={speakerFeeInvalid && 'Required field'}
              />
            ) : (
              <span className={styles.offer_list_roll_sum}><MoneyFormat value={speakerFee || 0} /></span>
            )
          }

        </div>
      </div>
      <div className={styles.offer_list_roll_group}>
        {
          (taxVisible || gstVat > 0) && (
            <div className={styles.offer_list_roll_third}>
              <span>GST/VAT</span>
              <span className={styles.offer_list_roll_sum}><MoneyFormat value={gstVat} /></span>
            </div>
          )
        }
        {
          !isNaN(platformFee) && (
            <div className={styles.offer_list_roll_third}>
              <span>Platform fee*</span>
              <span className={styles.offer_list_roll_sum}>-<MoneyFormat value={platformFee} /></span>
            </div>
          )
        }

        <div className={styles.stroke} />

        <div className={styles.offer_list_roll_fourth}>
          <span>Net speaker fee</span>
          <span className={styles.offer_list_roll_sum}><MoneyFormat value={netSpeakerFee} /></span>
        </div>
      </div>
    </div>
  );
}