import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

function Paper({ title, children, size = 'medium' }) {
  return (
    <div className={classNames(styles.container, styles[size])}>
      <h1 className={styles.heading}>{title}</h1>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

Paper.propTypes = {
  size: PropTypes.oneOf(['medium', 'large'])
};

export default Paper;
