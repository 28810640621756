import React from 'react';
import { Link } from 'react-router-dom';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>Why book a Mental Health & Resilience Speaker</h2>

      <h3 className={s.greenHeading}>Mental health speakers</h3>
      <p className={s.paragraph}>
        Mental health guest speakers are experts who bring their knowledge, personal experiences,
        and insights to engage audiences on the topic of mental health. They create a safe space for
        open discussions, reduce stigma, and promote understanding and empathy. These speakers have
        a deep understanding of various mental health conditions, including anxiety, depression, stress, and
        burnout, and they offer practical strategies for improving mental well-being.
      </p>

      <h3 className={s.greenHeading}>Resilience Speakers</h3>
      <p className={s.paragraph}>
        Resilience speakers are individuals who have overcome significant challenges in their lives
        and possess the ability to inspire others. They share their personal stories of resilience,
        emphasizing the importance of perseverance, adaptability, and positive mindset. Resilience
        speakers empower their audience to face adversity head-on and develop the necessary skills
        to bounce back stronger.
      </p>

      <h3 className={s.greenHeadingList}>Education and Awareness</h3>
      <p className={s.paragraph}>
        Mental health and resilience speakers deliver powerful presentations that educate audiences about the
        importance of mental well-being. They increase awareness of mental health issues, help individuals
        recognize the signs and symptoms, and encourage seeking support when needed.
      </p>

      <h3 className={s.greenHeadingList}>Destigmatization and Empathy</h3>
      <p className={s.paragraph}>
        By sharing personal stories and experiences, mental health speakers reduce the stigma surrounding
        mental health. They create a sense of empathy and understanding, fostering a more inclusive and
        supportive environment for individuals struggling with their mental well-being.
      </p>

      <h3 className={s.greenHeadingList}>Skills and Coping Strategies</h3>
      <p className={s.paragraph}>
        Mental health and resilience speakers provide practical tools and coping strategies to manage
        stress, improve emotional well-being, and build resilience. These skills can be applied
        both in personal and professional settings, leading to increased productivity, job
        satisfaction, and overall happiness.
      </p>

      <h3 className={s.greenHeadingList}>Inspiration and Motivation</h3>
      <p className={s.paragraph}>
        Speakers who have triumphed over adversity inspire and motivate audiences to face
        their own challenges with courage and determination. Their stories ignite hope and
        instill a belief that individuals can overcome obstacles and thrive in the face of adversity.
      </p>

      <h3 className={s.greenHeadingList}>Workplace Well-being</h3>
      <p className={s.paragraph}>
        Mental health and resilience speakers are particularly valuable in the workplace.
        They address the specific challenges faced by employees, such as workplace stress,
        burnout, and maintaining a healthy work-life balance. By promoting mental well-being,
        they contribute to a positive work environment, increased productivity, and reduced absenteeism.
      </p>

      <h3 className={s.greenHeadingList}>Community Impact</h3>
      <p className={s.paragraph}>
        Mental health and resilience speakers have a profound impact on communities. Their presentations
        not only benefit individuals but also influence societal attitudes towards mental health.
        By fostering dialogue and raising awareness, they contribute to creating supportive communities
        that prioritize mental well-being.
      </p>

      <h3 className={s.greenHeadingList}>Personal Growth and Development</h3>
      <p className={s.paragraph}>
        Engaging with mental health and resilience speakers can be a transformative experience
        on a personal level. Their insights and guidance inspire self-reflection, personal
        growth, and the development of resilience skills that can be applied to various aspects of life.
      </p>

      <p className={s.paragraph}>
        At <Link className={s.link}>Finders Speakers Co</Link>., we connect you with exceptional
        mental health and resilience speakers
        who have the expertise and passion to make a lasting impact. By inviting these speakers to
        your workplace or event, you can create an environment that values mental health, supports
        resilience, and promotes positive change.
      </p>
    </>
  );
};

const TopicPageMentalHealth = () => {
  return (
    <TopicPageBase
      topicAlias="mental-health-and-resilience"
      headerSubtitle={<>
        Book leading mental health & resilience speakers.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a Mental Health & Resilience speaker?"
      trendingSpeakersHeading="Trending Mental Health Speakers"
      why={<Why />}
      speakerTopicText="mental health and resilience"
    ></TopicPageBase>
  )
};

export default TopicPageMentalHealth;
