import { SIGNUP_START, SIGNUP_FAIL, SIGNUP_SUCCESS } from '../constants/authenticationConstants';
import { SET_FETCH_USER_STATUS, SET_AUTHENTICATED } from '../constants/profileConstants';

const auth = {
  wrongData: {},
  loading: false,
  status: null,
  isAuthenticated: false
};

const authenticationReducer = (state = auth, action) => {
  switch (action.type) {
    case SIGNUP_START:
      return {
        ...state,
        loading: true
      }

    case SIGNUP_SUCCESS:
      return {
        ...state,
        wrongData: {},
        loading: false
      }

    case SIGNUP_FAIL:
      return {
        ...state,
        wrongData: action.payload,
        loading: false
      }

    case SET_FETCH_USER_STATUS: {
      return {
        ...state,
        status: action.payload
      };
    }

    case SET_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload
      };
    }

    default:
      return state;
  }
};

export default authenticationReducer;
