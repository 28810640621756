import React, { useCallback, useEffect, useState } from 'react';
import styled from "styled-components";
import bg from '../assets/get-start-page/get_start_bg.png';
import { ReactComponent as Chevron } from "../assets/icons/chevron.svg";
import { Link } from "react-router-dom";
import { GreenLine } from '../HomePage/GreenLine';
import SignUpFormCode from './SignUpFormCode'
import SignUpEmail  from './SignUpEmail'
import SignUpForm from './SignUpForm'
import { fetchResendConfirmation } from '../api/helperFunctions';
import useWindowDimensions from "../helpers/useWindowDimentsions";
import logo from "../assets/logo_light_new.svg";
import footerBg from "../assets/footer-bg.webp";

export const GetStart = ({ userData, errors, postRegisterRequest, step, toggleAuthSteps }) => {
  const [resentBtnText, setResentBtnText] = useState('Resend email');

  const windowSize = useWindowDimensions();
  useEffect(() => {
    toggleAuthSteps(0);
  }, [])

  const resendConfirmation = async (email) => {
    const res = await fetchResendConfirmation('confirmation/re-send', email);
    (res.ok || !!res.message) && setResentBtnText('Re-sent successfully');
    setTimeout(() => {
      setResentBtnText('Resend email');
    }, 3000)
    toggleAuthSteps(2);
  };

  const handleSubmitCode = async (code) => {
    const data = {
      ...userData,
      code,
    }
    await postRegisterRequest(data);
  };

  const handleSubmitSuccess = useCallback(() => async () => {
    const data = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: 'speaker',
      number: { code: '', phone: '' },
      agree: false,
      captcha: false,
      code: '000000',
      receive_emails: false,
    }
    await postRegisterRequest(data);
  }, [step === 2]);


  return (
    step !== 2
      ? (
        <SignUpFormStyles>
          <section className="get-start__info get-start-block" style={{ display: 'flex' }}>
            <div className="section-wrapper">
              <Link to="/" className="logo">
                <img
                  src={logo}
                  alt="logo"
                />
              </Link>
              {
                windowSize.width > 1024
                  ? <h1 className="get-start__title">
                    {`Apply to join our `}
                    <GreenLine
                      bgImg={() => require('../assets/get-start-page/green_line_get_start.svg')}
                      target={'community'}
                      scrollPosition={0}
                      moveBottom={'-2px'}
                      lineHeight={'20px'}
                    /> of<br />Finders and Speakers
                    </h1>
                  : <h1 className="get-start__title">
                    {`Apply to join our `}
                    <GreenLine
                      bgImg={() => require('../assets/get-start-page/get-started-mobile.svg')}
                      target={'community'}
                      scrollPosition={600}
                      moveBottom={'-2px'}
                      lineHeight={'20px'}
                    /> of
                    <br />
                    Finders and
                    <br/>
                    Speakers
                  </h1>
              }

              <p className="get-start__text">
                Power a successful speaking business with the all-in-one marketing,
                booking and collaboration hub
              </p>

              <div className="get-start__list-title">
                In order for a profile submission to be considered we require the following:
              </div>

              <p className="get-start__text">
                <ul className="get-start__list">
                  <li className="get-start__item">Your story and the value you deliver</li>
                  <li className="get-start__item">Professional headshot</li>
                  <li className="get-start__item">Speaker showreel/video</li>
                  <li className="get-start__item">Speaker photos (minimum of 4)</li>
                  <li className="get-start__item">Recent speaking engagements (minimum of 4)</li>
                  <li className="get-start__item">Client testimonials (minimum of 4 to 6)</li>
                  <li className="get-start__item">Your presentation topics</li>
                  <li className="get-start__item">In the media – press, published books, podcasts</li>
                </ul>
              </p>
            </div>
          </section>
          {step === 0
            ? <SignUpForm data={userData} errorsData={errors} />
            : <SignUpFormCode data={userData} submitCode={handleSubmitCode} errorsData={errors} />
          }
        </SignUpFormStyles>
      )
      : <SignUpEmail
        email={userData.email}
        resendConfirmation={() => resendConfirmation(userData.email)}
        resetReduxState={handleSubmitSuccess}
        resentBtnText={resentBtnText}
      />
  );
}

export const SignUpFormStyles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .get-start-block {
      justify-content: center;

      @media (max-width: 769px) {
        justify-content: center;
        padding-bottom: 50px;
      }
    }

    .section-wrapper {
        width: 530px;

        @media (max-width: 1260px) {
          width: 420px;
        }
    }

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }

    .aqua {
        color: #20D5C4;
    }

    .logo {
        display: block;
        margin-top: 40px;

        img{
          max-height: 52px;
        }

        @media (max-width: 1024px) {
          display: none;
        }
    }

    .get-start{
        &__info {
          position: fixed;
          top: 0;
          left: 0;
          width: calc((100% / 2) - 14px);
          background-image: url(${footerBg});
          background-repeat: no-repeat;
          background-size: cover;
          background-color: #161E27;
          padding: 40px 109px 120px 120px;
          min-height: 100vh;
          height: 100%;
          padding: 0;

          @media (max-width: 1280px) {
              width: 560px;
              min-width: 560px;
          }

          @media (max-width: 1260px) {
              width: 42%;
              min-width: 42%;
              flex-basis: 42%;
          }

          @media (max-width: 1024px) {
              position: static;
              min-width: 100%;
              padding: 40px 16px;
              width: 100%;
              order: 2;
          }

          @media (max-width: 600px) {
              padding-top: 30px;
          }
      }

        &__title {
            margin-top: 60px;
            font-weight: 400;
            font-size: 44px;
            line-height: 48px;
            color: #FFFFFF;
            margin-bottom: 22px;

            @media (max-width: 768px) {
                width: 100%;
            }

            @media (max-width: 600px) {
              width: 100%;
              margin-top: 0;
              font-size: 36px;
              line-height: 42px;
              margin-bottom: 12px;
              font-weight: 600;
          }
        }

        &__text {
            font-size: 18px;
            line-height: 24px;
            color: #ABB0BE;
            margin-bottom: 28px;
            max-width: 520px;

            @media (max-width: 768px) {
              width: 100%;
              margin-bottom: 10px;
            }
        }

        &__list {
            padding: 0;
            margin: 0 0 20px 0;
            list-style-type: none;
            width: 717px;

            li:before {
              content: "\\2022";
              color: #20D5C4;
              padding-right: 10px;
            }

            @media (max-width: 1600px) {
              width: 520px;
            }

            @media (max-width: 1280px) {
              width: 390px;
            }

            @media (max-width: 768px) {
              width: 100%;
            }

            @media (max-width: 768px) {
              margin-bottom: 24px;
            }
        }

        &__list-title {
          color: #FFFFFF;
          font-weight: 700;
          margin-bottom: 10px;
          font-size: 20px;
        }

        &__item {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #FFFFFF;
            width: 717px;

            @media (max-width: 768px) {
              width: 100%;
            }

            @media (max-width: 768px) {
              margin-bottom: 12px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &__link {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-transform: uppercase;
            color: #E8388A;
            cursor: pointer;
            width: 717px;

            @media (max-width: 1600px) {
                width: 520px;
            }

            @media (max-width: 1280px) {
                width: 390px;
            }

            @media (max-width: 1280px) {
              font-size: 14px;
              line-height: 20px;
            }

            svg {
                display:inline-block;
                margin-left: 17px;
                fill: #E8388A;
                width: 6px;

                path {
                  fill: #E8388A;
                }
            }
        }
    }
`;

