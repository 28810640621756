import React from 'react';
import BaseInput from '../../../BaseInput';

const TextInput = ({ value, onChange, disabled = false, placeholder, error }) => {

  const changeValue = event => {
    onChange(event.target.value);
  };

  return (
    <BaseInput
      value={value}
      onChange={changeValue}
      disabled={disabled}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default TextInput;
