import { useEffect } from 'react';

const useOgMetaTag = (property, content) => {
  useEffect(() => {
    if (!content) {
      return;
    }

    const tag = document.querySelector(`meta[property="og:${property}"]`);

    const previousContent = tag.content;
  
    tag.content = content;
  
    return () => {
      tag.content = previousContent;
    };
  }, [content]);
};

const useTwitterMetaTag = (name, content) => {
  useEffect(() => {
    if (!content) {
      return;
    }

    const tag = document.querySelector(`meta[name="twitter:${name}"]`);

    const previousContent = tag.content;
  
    tag.content = content;
  
    return () => {
      tag.content = previousContent;
    };
  }, [content]);
};

const useSocialMeta = options => {
  const { title, description, image, url, type } = options;

  useOgMetaTag('title', title);
  useOgMetaTag('description', description);
  useOgMetaTag('image', image);
  useOgMetaTag('url', url);
  useOgMetaTag('type', type);

  useTwitterMetaTag('title', title);
  useTwitterMetaTag('description', description);
  useTwitterMetaTag('image', image);
};

export default useSocialMeta;
