import React, { useEffect, useState } from "react";
import { FAQQuestion } from './FAQQuestion'
import styled from "styled-components";
import { Link } from 'react-router-dom'
import { Text } from '../../PrivacyPolicyPage/PrivacyPolicy'

const FAQTabForSpeaker = [
  {
    id: 1,
    question: 'What is Finders Speakers?',
    answer: <>
      <Text>
        Finders Speakers is a next generation booking, marketing and collaboration speakers marketplace helping leading voices and the freshest talent get promoted, booked and paid.
      </Text>
      <Text>
        Created by event experts for the real needs of speakers and event planners. Finders Speakers helps you power a speaking business and brand that will thrive in the new world of speaking and events.
      </Text>
      <Text>
      Our mission is to redefine and enhance the way event professionals and speakers connect by creating a new direct speaking economy which creates opportunities and drives positive outcomes on all sides.
      </Text>

      { /*
        <Text>
          Finders Speakers showcases the most diverse range of curated speaking talent. No matter what type of speaker you are, renowned, established or a rising star, we welcome all speaking talent, any topic, type or budget, across all formats; live virtual or hybrid.
        </Text>
        */
      }

      <Text>
        No upfront subscriptions. Apply to join today.
      </Text>
      <Text><Link to={'/about'}>Learn more</Link></Text>
    </>,
  },
  {
    id: 2,
    question: 'Why should I choose Finders Speakers?',
    answer: <>
      <ul>
        <li>
          <Text><strong>Get actively marketed to some of the biggest brands and event professionals, for free</strong> - save money and hours on marketing, so you can focus on what you do best. The perfect complement to your current marketing strategy. </Text>
        </li>
        <li>
          <Text><strong>Receive direct and unfiltered access to event organisers</strong> - own your relationships, drive better collaborations, enjoy more control and negotiate best terms.</Text>
        </li>
        <li>
          <Text><strong>The most comprehensive, engaging and dynamic professional profiles</strong> - custom built by event organisers for speakers and designed to get you noticed. </Text>
        </li>
        <li>
          <Text><strong>Market your full-service offering</strong> - expertise, showreels, videos, presentations, programs, courses, collaboration opportunities, media, books, social media, testimonials and more. </Text>
        </li>
        <li>
          <Text><strong>Your success is our success</strong> - unlike other platforms, there are no subscriptions, upfront fees or tiered memberships. We only get paid if you get paid, so we are 100% motivated to get you booked.</Text>
        </li>
        <li>
          <Text><strong>Much more than just a booking platform</strong> – free up your time, reduce admin and focus on business growth with all conversations, bookings, contracts, testimonials seamlessly and efficiently managed online. Think of us as a digital extension of your marketing team.</Text>
        </li>
        <li>
          <Text><strong>Get paid on time, every time</strong> – with automated and secure payments and invoicing. No more messy money conversations</Text>
        </li>
      </ul>
    </>,
  },
  {
    id: 3,
    question: 'How does it work?',
    answer: <>
      <Text><strong>Get Promoted</strong></Text>
      <ul>
        <li><Text>Apply to join and create an expert profile custom designed to best promote you and bring your story to life. Share your story, the value you deliver, your expertise, past events, and full service offering.</Text></li>
        <li><Text>Get actively marketed to the biggest brands and event professionals, we bring clients to you. Our intuitive search function helps planners easily access your full profile in just minutes.</Text></li>
        <li><Text>Let event organisers see you in action doing what you do best. Upload showreels, videos, presentations, programs, courses, collaboration opportunities, media, books, social following. </Text></li>
        <li><Text>Use the platform in a way that works best for you, either as your main marketing tool or a complementary extension to your existing marketing, saving you money on marketing spend.</Text></li>
      </ul>
      <Text><strong>Get Booked</strong></Text>
      <ul>
        <li><Text>Receive direct enquiries from event decision makers in minutes. Own from the outset who you want to deal with and how. Take control and negotiate the rate and terms you deserve.</Text></li>
        <li><Text>Engage direct, enjoy more meaningful collaborations, develop stronger, longer lasting client relationships.</Text></li>
        <li><Text>Behind the scenes support: access a full suite of free value-add business solutions, to help you power your speaking business, all in the one place online. </Text></li>
        <li><Text>Organised conversations: real time chat function allows you to receive enquiries and messages direct to your inbox or mobile, so you’ll never miss an opportunity. </Text></li>
        <li><Text>Secure your bookings, send contracts direct to clients, with our easy-to-use online booking contracts, or, upload and store your own if you prefer. </Text></li>
        <li><Text>Easily manage all bookings via your custom dashboard. All past and future bookings information at your fingertips - conversations, contracts, documents, invoices and payments, testimonials and more.</Text></li>
      </ul>
      <Text><strong>Get paid</strong></Text>
      <ul>
        <li><Text>Get paid on time every time with automated and scheduled payments direct to your nominated account.</Text></li>
        <li><Text>Say goodbye to messy money conversations and time spent chasing clients. </Text></li>
        <li><Text>Auto-generated invoices sent direct to your client and stored on your dashboard so your records stay up to date. </Text></li>
      </ul>
      <Text><strong>Get rated</strong></Text>
      <ul>
        <li><Text>Receive testimonials after every speaking engagement, sourced for you by us.  </Text></li>
        <li><Text>Choose the reviews you want to publish direct to your profile page.</Text></li>
        <li><Text>Maximise your exposure and increase potential future booking opportunities.</Text></li>
      </ul>
    </>,
  },
  {
    id: 4,
    question: 'Who should apply to join?',
    answer: <>
      <Text>
        At Finders Speakers we are passionate about growing a global community
        of the most exciting and diverse curated speaking talent. We seek to partner
        with speakers who are subject matter experts in their chosen field.
        To apply to join we will require you to be able to provide the below information:
      </Text>

      <Text>
        <ul>
          <li>Your story and the value you deliver</li>
          <li>Professional headshot</li>
          <li>Speaker showreel/video</li>
          <li>Speaker photos (minimum of 4)</li>
          <li>Recent speaking engagements (minimum of 4)</li>
          <li>Client testimonials (minimum of 4 to 6)</li>
          <li>Your presentation topics</li>
          <li>In the media – press, published books, podcasts</li>
        </ul>
      </Text>

      <Text><Link to="/register">Get started today</Link></Text>
    </>,
  },
  {
    id: 6,
    question: 'What are the costs?',
    answer: <>
      <Text>Yes, it’s 100% free to join and publish a full profile and get marketed to event organisers. We don't think subscription services or other upfront fee offerings have your best interests at heart. That’s why we offer you access to our full suite of services for free.</Text>
      <Text>At Finders Speakers we believe in being aligned with our customers, your success is our success. If you don't get booked, we don't think it’s fair that we get paid. </Text>
      <Text>Only once you are successfully booked via the platform, you will be charged a 17% platform fee based on the agreed speaking fee only. </Text>
      <Text>The Platform Fee is used for ongoing marketing to event organisers and to deliver enhanced platform features, ensuring you continue to get found and booked in the future.</Text>
    </>,
  },
  {
    id: 7,
    question: 'Who does my profile get marketed to?',
    answer: <>
      <Text>Anyone can use the Finders Speakers platform to search for speaking talent, however Finders Speakers actively targets high quality, high use, event organisers, experts and decision makers.</Text>
      <Text>We offer you access to a wide range of previously untapped customers and opportunities and the potential to build strong relationships and generate repeat business.  </Text>
    </>,
  }
];

const FAQTabForFinders = [
  {
    id: 7,
    question: 'What is Finders Speakers?',
    answer: <>
      <Text>Finders Speakers is a next generation speaker booking and collaboration marketplace. Created by event experts for the real needs of event planners and speakers. </Text>
      <Text>Connect and collaborate with top speaking talent for more choice, diversity, accessibility and control when sourcing talent. </Text>
      <Text>Easily find, book and manage the most exciting and diverse range of talent to deliver best in class events that drive conversations, new ideas, thought leadership and opportunity. </Text>
      <Text>Whether you are looking for a leading voice,  a rising star, a Keynote, MC, Panellist, Entertainer, Coach or Trainer, we’ve got you covered, any topic, type, format formats; live, virtual and hybrid.</Text>
      <Text>And it is 100% free to start searching. No catches, no subscriptions, no hidden fees.</Text>
    </>,
  },
  {
    id: 8,
    question: 'How does it work?',
    answer: <>
      <Text><strong>1.Find</strong></Text>
      <ul>
        <li><Text>Get direct access to talent, any topic, type, budget, format - live, virtual, hybrid.</Text></li>
        <li><Text>View profiles that bring speaker stories to life; value delivered, expertise and full service offerings. </Text></li>
        <li><Text>See speakers in action; showreels, videos, presentations, programs, courses, collaboration opportunities, media, books, social following and more.</Text></li>
        <li><Text>Search and shortlist talent. Send an enquiry to multiple speakers in just one one send.</Text></li>
      </ul>
      <Text><strong>2. Book</strong></Text>
      <ul>
        <li><Text>Send enquiries in just minutes, decide who you want to deal with and how for more meaningful collaborations, stronger relationships.</Text></li>
        <li><Text>Behind the scenes support; access a full suite of value-add business solutions at no cost, all in the one place online. </Text></li>
        <li><Text>Organised conversations; real time chat function allows you to make enquiries and receive messages direct to your inbox or mobile. </Text></li>
        <li><Text>Secure bookings, with our online speaker booking contracts, or, upload and store your own if you prefer. </Text></li>
        <li><Text>Easily manage all bookings via your custom dashboard. All past and future bookings at your fingertips - conversations, contracts, documents, invoices, payments and more.</Text></li>
      </ul>
      <Text><strong>3. Deliver</strong></Text>
      <ul>
        <li><Text>Make your event dollar stretch further, do more for less, negotiate best terms, no inflated fees.</Text></li>
        <li><Text>Nail the brief, work faster and smarter, deliver more for your business, drive growth.</Text></li>
        <li><Text>Deliver best in class events that drive conversions, new ideas, thought leadership and opportunities.</Text></li>
      </ul>
    </>
  },
  {
    id: 9,
    question: 'Who should join?',
    answer: <>
      <Text>Everyone is welcome to use the platform; event professionals, corporates, brands, agencies, professional and industry associations, academics, charities and individuals. </Text>
      <Text>Whatever the brief or budget, we have a speaker for you. </Text>
    </>
  },
  {
    id: 10,
    question: 'Is it easy to get started?',
    answer: <>
      <Text>Yes, it is easy, fast, and free to start searching and connecting with top talent.</Text>
      <Text>Simply sign up and verify your details for instant and full access to all the benefits of the platform.</Text>
      <Text><Link to={'/contact-us'}>Send us your brief</Link></Text>
    </>
  },
  {
    id: 11,
    question: 'Is Finders Speakers free to use?',
    answer: <>
      <Text>Yes, it is 100% free to use the Finders Speakers platform. </Text>
      <Text>We offer event organisers our full suite of services. No catches, no subscriptions, no hidden fees.</Text>
    </>
  }
];

export const FAQItem = ({ currentTab = {}, tabsData }) => {
  const [currentData, setCurrentData] = useState(tabsData || FAQTabForSpeaker);

  useEffect(() => {
    
    let data;

    switch (1) {
      case 0: {
        data = FAQTabForSpeaker;
        break;
      }
      case 1: {
        data = FAQTabForFinders;
        break;
      }
      default: {
        data = tabsData || FAQTabForSpeaker;
      }
    }

    setCurrentData(data);
  }, [currentTab])

  return (
    <FQAListStyle>
      {
        currentData.map((el) => (
          <FAQQuestion key={el.id} question={el.question} answer={el.answer} marginBottom={el.marginBottom} />
        ))
      }
    </FQAListStyle>
  )
}

export const FQAListStyle = styled.ul`
   margin: 0;
   padding: 20px 0 0 0;
   list-style-type: none;

       @media (max-width: 1280px) {
           padding: 10px 0 0 0;
       }
       @media (max-width: 768px) {
           padding: 4px 0 0 0;
       }
`;