import { combineReducers } from 'redux';
import topics from "./topics.reducer";
import user from './user.reducer';
import admin from './admin.reducer';
import test from './test.reducer';
import enquiry from './enquiry.reducer';
import errors from './errors.reducer';
import modal from './modal.reducer';
import verification from './verification.reducer';
import steps from './steps.reducer';
import sendEnquiryData from './createEnquiryReducer';
import utils from './faqReducer';
import authStep from './authSteps.reducer';
import speakers from './findSpeakers.reducer';
import portalReducer from './portal.reducer';
import authenticationReducer from './authentication.reducer';
import chatReducer from './chat.reducer';

// our store
const rootReducer = combineReducers({
    user,
    admin,
    test,
    enquiry,
    errors,
    modal,
    verification,
    authStep,
    steps,
    sendEnquiryData,
    topics,
    utils,
    speakers,
    portal: portalReducer,
    auth: authenticationReducer,
    chat: chatReducer
});

export default rootReducer;
