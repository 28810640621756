import React from "react";
import styled from "styled-components";

export const FAQNav = ({ currentTab, setCurrentTab }) => {

  const FAQNavigationTitles = [
    // 'General questions',
    // 'For speakers',
    'For finders',
    // 'my account',
  ];

  const handleClick = (idx) => {
    setCurrentTab(idx);
  }

  return (
    <FQANavigationStyle>
      {
        FAQNavigationTitles.map((el, idx) => (
          <li
            key={idx}
            className={`nav__item ${(idx === (currentTab.idx || 0)) ? 'active' : ''}`}
            // className={'nav__item active'}
          >
            <button
              typeof="button"
              className="nav__button"
              onClick={() => handleClick(idx)}
            >
              {el}
            </button>
          </li>
        ))
      }
    </FQANavigationStyle>
  )
}

export const FQANavigationStyle = styled.ul`
   margin: 0;
   padding: 0;
   list-style-type: none;
   display: flex;
   border-bottom: 1px solid #DCDFE0;
   
   @media (max-width: 768px) {
    flex-wrap: wrap;
   }

   .active {
        .nav__button{
             color: #20D5C4;
             border-bottom: 2px solid #20D5C4;   
             
             @media (max-width: 768px) {
              border: none;
             }
        }
    }

   .nav__button{
        background: transparent;
        border: none;
        padding: 9px 0;
        margin-right: 60px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #293749;
        transition: color 0.3s ease-in-out;
        
        &:active,
        &:focus {
            outline: none;
        }
        
        &:hover {
          color: #20D5C4;
        }
        
        @media (max-width: 1280px) {
            font-size: 12px;
            line-height: 18px;
        }
        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 16px;
          margin-right: 30px;
          margin-bottom: 20px;
          padding: 0;
        }
   }
`;