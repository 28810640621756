import React, { useState } from 'react';
import styled from "styled-components";
import ChevronDown from "react-dates/esm/components/ChevronDown";
import avatar from '../../assets/founder-avatar.jpg'
import { ReactComponent as Bell } from '../../assets/admin/icons/bell.svg'
import { NavLink } from 'react-router-dom';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
} from 'reactstrap';
import Dropdown from 'reactstrap/es/Dropdown';
import { fetchData } from "../../api/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';


export const AdminHeader = () => {
    const [headerDropdownIsOpen, setHeaderDropdownIsOpen] = useState(false);
    const history = useHistory();

    const toggle = () => {
        setHeaderDropdownIsOpen(!headerDropdownIsOpen);
    }
    const token = useSelector(store => store.user.token);
    const dispatch = useDispatch();

    const handlerLogout = () => {
        history.push('/');
        fetchData('logout', 'post', token, token);
        dispatch({
            type: 'LOGIN_SUCCESS',
            payload: {
                user: { first_name: 'user', last_name: 'noname' },
                token: '',
            },
        });
        dispatch({ type: 'SET_IS_LOGIN', payload: false });
        dispatch({ type: 'SET_AVATAR', payload: '' });
    };

    if (
        avatar === `${process.env.REACT_APP_DO_SPACES_URL}/null` ||
        avatar === `${process.env.REACT_APP_DO_SPACES_URL}/`
    ) {
        avatar = '';
    }

    return (
        <HeaderStyle className="header">
            <Nav className="ml-auto" navbar>
                <NavLink to="#" className="notification-btn"><Bell /></NavLink>
                <div className="header-dropdown__wrapper">
                    <Dropdown isOpen={headerDropdownIsOpen} toggle={toggle} className="header-dropdown">
                        <DropdownToggle nav>
                            <div>
                                <span style={{ whiteSpace: 'nowrap' }}>Sophie</span>
                                <ChevronDown />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu right style={{ right: 'auto', marginRight: '50px' }}>
                            <DropdownItem><i className="fa fa-shield" />Lock Account</DropdownItem>
                            <DropdownItem onClick={handlerLogout}><i className="fa fa-lock" />Logout</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="img-avatar__holder">
                    <img
                        src={avatar}
                        className="avatar"
                        alt="admin" />
                </div>
            </Nav>
        </HeaderStyle>
    );
}

const HeaderStyle = styled.header`
    display: flex;
    justify-content: flex-end;
    position: relative;
    box-shadow: 0 2px 6px #0000000A;
    padding-top: 10px;
    padding-bottom: 10px;

    button:active,
    button:focus {
      outline: none;
    }

    .dropdown-item .fa {
        display:inline-block;
        color: #ccc;
        margin-right: 12px;
    }

    .dropdown-menu {
        position: absolute !important;
        top: 100%;
    }
    .ml-auto {
      display: flex;
      flex-direction: row;
      padding-right: 20px;

      .notification-btn {
          padding: 10px;
          position:relative;

          &.active::before {
              content: '';
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: #ffc107;
              position:absolute;
              border: 2px solid #fff;
              top: 8px;
              right: 8px;
          }
          svg {
             fill: #ccc;
             width: 22px;
             height: 22px;
            }
        }
    }
     .app-header__list-icons{
        list-style-type: none;
        padding: 0;
     }
     .header-dropdown__wrapper {
         min-width: 160px;
         height: 42px;
         border-right: 1px solid #ccc;
         border-left: 1px solid #ccc;
         padding: 10px;

         .nav-link {
            line-height: 22px;
         }
     }
    .header-dropdown {
         .nav-link {
            color: #4D4F5C;
         }


        svg {
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-left: 12px;
        }
    }
    .img-avatar__holder {
        border-radius: 50%;
        width: 38px;
        height: 38px;
        overflow: hidden;
        margin: 0 10px;

        .avatar {
            height: 38px;
            width: auto;
            object-fit: cover;
        }
    }

    .user-menu {
       display: flex;
       width: 236px;
       padding: 16px 20px;
       align-items: center;
       justify-content: space-between;
    }

   .user-notification {
      flex-basis: 15px;
   }
`
