import React, { useState } from 'react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import BaseModal from '../../BaseModal';
import styles from './styles.module.scss';

const ConfirmationUserModal = (props) => {
  const {
    isOpen,
    title,
    text,
    errorMessage,
    subtext,
    onConfirm,
    onCancel,
    cancelButtonText = 'no',
    confirmButtonText = 'yes',
    onClose,
    children
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const onPressConfirm = () => {
    const result = onConfirm();

    if (result instanceof Promise) {
      setIsLoading(true);
      result.catch(e => {
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const onCloseModal = () => {
    if (!isLoading) {
      if (onClose) {
        onClose();
      } else {
        onCancel();
      }
    }
  };

  const onPressCancel = () => {
    if (!isLoading) {
      onCancel();
    }
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <div className={styles.modalContent}>
        <div className={styles.closeIcon} onClick={onCloseModal}>+</div>
        <div className={styles.modalHeader}>{title}</div>

        <div className={styles.modalBody}>
          <p className={styles.text}>{text}</p>
          <p className={styles.subtext}>{subtext}</p>
          {children}
          <p className={styles.errorMessage}>{errorMessage}</p>
        </div>

        <div className={styles.buttons}>
          <button disabled={isLoading} className={styles.cancel} onClick={onPressCancel}>{cancelButtonText}</button>
          <button
            disabled={isLoading}
            className={classNames(styles.confirm, { [styles.loading]: isLoading })}
            onClick={onPressConfirm}
          >
            {isLoading ? <CircularProgress size="1.5em" style={{ color: '#16d4c2' }} /> : confirmButtonText}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmationUserModal;
