import React from 'react';
import Load from '../../../assets/reload.svg';
import s from './LoadMoreButton.module.scss';

const LoadMoreButton = ({ isLoading, onClick }) => <div className={s.loadMoreContainer}>
  <button className={s.loadMoreButton} onClick={onClick}>
    <span className={s.loadMoreText}>{ isLoading ? 'Loading...' : 'Load more' }</span>
    <img className={isLoading ? s.loadMoreImageAnimated : s.loadMoreImage} src={Load} />
  </button>
</div>;

export default LoadMoreButton;
