import React from 'react'
import styled from 'styled-components'
import img from '../assets/ComingSoon/feedback.png'
import constants from '../constants';

export default (props) => {

    return <SCTStyled>
        <div className="sct-content">
            <div className="sct-text">
                <h2 className="sct-text__label">Once you complete your first speaking event</h2>
                <p className="sct-text__sublabel">Start to receive testimonials after every engagement</p>
                <p className="sct-text__desc">We are all about getting speakers found and booked. What better and more trusted way than through client endorsements. That’s why we provide you testimonials after every engagement to power your business and brand. </p>
                <p className="sct-text__list-title">How it works</p>
                <ul className="sct-text__list">
                    <li className="sct-text__list-item">Let us do the heavy lifting and source testimonials for you after every engagement. </li>
                    <li className="sct-text__list-item">Choose the reviews you want to publish, direct to your profile page. </li>
                    <li className="sct-text__list-item">Maximise your exposure and increase potential future booking opportunities.</li>
                </ul>
            </div>
            <div className="sct-image">
                <img src={img} className="sct-image__big"/>
            </div>
        </div>

        <div className="sct-footer">
            All Rights Reserved     {constants.copyright}
        </div>
    </SCTStyled>
}

const SCTStyled = styled.div`
    background: #19212B;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 160px;
    padding-bottom: 100px;

    @media (max-width: 1024px) {
        padding-top: 50px;
        padding-bottom: 80px;
    }

    .sct {

        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-image {
            position: relative;
            margin: 0 15px;

            &__big {
                width: 100%;
                max-width: 595px;
            }
        }

        &-text {
            max-width: 400px;
            margin: 0 15px;

            &__label {
                color: #ffffff;
                font-size: 28px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__sublabel {
                color: #20D5C4;
                font-size: 28px;
                line-height: 32px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__desc {
                color: #ffffff;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 30px;
            }

            &__list {
                list-style: none;
                padding-left: 20px;

                &-title {
                    color: #20D5C4;
                    font-size: 15px;
                    font-weight: 300;
                    margin-bottom: 10px;
                }

                &-item {
                    color: #ffffff;
                    margin-bottom: 10px; 

                    &:before {
                        content: "\\2022";
                        color: #20D5C4;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        &-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: 300;
            color: #ffffff;
            text-align: center;
        }
    }
`