import EventAbstract from './EventAbstract';

class EnquiryModel extends EventAbstract {
  constructor(apiDataItem) {
    const { date_posted, dates, budget_from, budget_to } = apiDataItem;
    super(date_posted, dates, budget_from, budget_to);
    this.data = apiDataItem;
  }
}

export default EnquiryModel;
