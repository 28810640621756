import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import BaseInput from '../../../BaseInput';

const MAX_VALUE = 10 ** 6;

const limitValue = ({ floatValue }) => isNaN(floatValue) || floatValue <= MAX_VALUE;


const MoneyBaseInput = ({ ...props }) => {
  return <BaseInput
    {...props}
    placeholder={'0.00'}
    InputProps={{
      startAdornment: <InputAdornment position="start">$</InputAdornment>
    }}
  />;
};

const MoneyInput = ({ onChange, ...other }) => {
  return (
    <NumberFormat
      {...other}
      customInput={MoneyBaseInput}
      onValueChange={value => onChange(value.floatValue)}
      thousandSeparator={true}
      decimalSeparator={'.'}
      decimalScale={2}
      allowNegative={false}
      isAllowed={limitValue}
    />
  );
};

export default MoneyInput;
