import React from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';

export const AgreeModal = props => {
    const dispatch = useDispatch();

    const close = () => {
        props.clearErrors();
        dispatch({
            type: 'SET_PORTAL_STATUS',
            payload: {portalType: 'agreeModal', portalStatus: false},
        });
    };

    const onSubmit = () => {
        const a = props.isAgree();
        a.then(res => {
            res && close();
        })
    }

    return (
        <StyledModal className={props.className}>
            <div className="modal-agree">
                <div className="del-btn" onClick={() => close()}>
                    +
                </div>
                <div className="content">
                    {props.children}
                    <div className="buttons">
                        <button
                            className="cancel-btn"
                            onClick={() => close()}
                        >
                            { props.cancelButtonText || 'CANCEL' }
                        </button>
                        <button
                            className="confirm-btn"
                            onClick={onSubmit}
                        >
                            { props.agreeButtonText || 'CONFIRM' }
                        </button>
                    </div>
                </div>
            </div>
        </StyledModal>
    );
};

const StyledModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    .modal-agree {
        .content {
            padding: 30px 50px;
            font-size: 12px;
        }
        .buttons {
            display: flex;
            justify-content: space-between;
        }
        width: 400px;
        border-radius: 10px;
        background-color: #fff;
        margin: 150px auto;
        position: relative;
        .text {
            font-size: 20px;
            color: #10151b;
            margin-bottom: 35px;
        }
        .cancel-btn {
            height: 40px;
            line-height: 35px;
            width: 130px;
            border: 2px solid #ff00ff;
            border-radius: 20px;
        }
        .confirm-btn {
            height: 40px;
            line-height: 35px;
            width: 130px;
            border: 2px solid #16d4c2;
            border-radius: 20px;
        }
        .del-btn {
            position: absolute;
            color: #10151b;
            right: 15px;
            top: 3px;
            transform: rotate(45deg);
            font-size: 32px;
            cursor: pointer;
        }
    }
    
    button:focus,
    button:active {
      outline: none;
    }
    
    &.status__confirmation {
      .modal-agree {
          width: 600px;
        .content {
          min-height: initial;
          border-radius: 10px;
          background: #fff;
        }
      }
      .message {
        display:inline-block;
        width: 100%;
        height: 140px;
        border: 1px solid #ccc;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 4px;
        
        &:placeholder-shown {
          text-align: center;
          padding-top: 40px;
        }
        
        &:focus {
          outline: none;
        }
      }
      .title {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #4D4F5C;
      }
      .text {
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        color: #4D4F5C;
        opacity: 1;
      }
      .confirm-btn {
        width: 205px;
        border-radius: 4px;
        background: #3b86ff;
        color: #fff;
        border: none;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        transition: box-shadow 0.3s ease-in-out;
        &:hover {
         box-shadow:  0 2px 3px 0 rgba(0,0,0,0.3);
        }
      }
      .cancel-btn {
        border: 1px solid #3B86FF;
        background: transparent;
        width: 205px;
        border-radius: 4px;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        transition: box-shadow 0.3s ease-in-out;
        &:hover {
          box-shadow:  0 2px 3px 0 rgba(0,0,0,0.3);
        }
      }
      .error-text {
        color: red;
        font-size: 10px;
        display: block;
        text-align: center;
      }
    }
    
    @media (max-width: 1024px) {
        .modal-agree {
          width: 322px;
        }
    }
`;

export default AgreeModal;
