import { SET_CURRENT_FAQ_TAB, SET_IS_ERROR_ON_EDIT_PROFILE } from '../constants/faq';
import {combineReducers} from "redux";

const initialState = 0;

const faqCurrentTab = (state = initialState, action) => {
    if (action.type === SET_CURRENT_FAQ_TAB) {
        return { ...state, ...action.payload };
    } else return state;
};

const isErrorOnEditProfileInitial = false;

const isErrorOnEditProfile = (state = isErrorOnEditProfileInitial, action) => {
    if (action.type === SET_IS_ERROR_ON_EDIT_PROFILE) {
        return action.payload;
    } else return state;
};

export default combineReducers({
    faqCurrentTab,
    isErrorOnEditProfile,
});