import React from 'react';
import styled from "styled-components";
// import chevron from '../assets/icons/arrow-light.svg';
import { isNil } from "ramda";
import useWindowDimensions from "../helpers/useWindowDimentsions";
import arrowRight from '../assets/icons/arrow-right.png';
import defaultAvatar from '../assets/default-avatar.jfif';

export const MyShortList = ({ data, onClick }) => {
    const { width } = useWindowDimensions();

    return (
        <MyShortListStyles data={data} onClick={onClick}>
            <h3 className="title" >
                My shortlist
                <span> ({data.length})</span>
                {/* <span className="title__view-btn">VIEW</span> */}
            </h3>

            {data.length !== 0 && width > 768 &&
                <div className="position-relative" role={'button'} onClick={onClick}>
                    <button className={`add-aqua-btn`}>
                        <img src={arrowRight} />
                    </button>
                    <ul className="list">
                        {
                            data.map((el, idx) => {
                                if (idx < 3) {
                                    return (
                                        <li key={el.id} className="item">
                                            {isNil(el.photo)
                                                ? <div className="no-img"></div>
                                                : <img
                                                    src={`${process.env.REACT_APP_DO_SPACES_URL}/${el.photo}`}
                                                    alt="speaker"
                                                    className={'avatar'}
                                                    onError={event => {event.target.src=defaultAvatar }}
                                                />
                                            }
                                        </li>
                                    )
                                }
                                return;
                            }).reverse()
                        }
                    </ul>
                </div>
            }
        </MyShortListStyles>
    )
}

const MyShortListStyles = styled.section`
  background: #fff;
  border-radius: 5px 0 0 5px;
  padding: 8px 0px 11px 19px;
  margin-bottom: 24px;
  box-shadow: 0px 15px 50px rgba(167, 171, 172, 0.1);
  height: 85px;
  position: fixed;
  right: 0;
  top: 210px;
  z-index: 2;
  background: #20D5C4;
  width: 153px;
  height: 75px;
  display: ${props => props.data.length > 0 ? 'block' : 'none'};

    @media(max-width: 768px) {
        height: 37px;
        background: #20D5C4;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        top: 190px;
        display: flex;
        justify-content: end;
        align-items: center;
        width: 120px;
        padding: 0 10px;
        border-radius: 5px 0 0 5px;

        .empty-list {
            display: none;
        }
    }

  .no-img {
      width: 40px;
      height: 40px;
      border: 3px solid #fff;
      background: #ccc;
      border-radius: 50%;
  }

  .add-aqua-btn {
    border: 3px solid #01BDB2;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
    background: #01BDB2;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        outline: none;
    }

    img {
        width: 9px;
        height: 15px;
    }
  }

  .list {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: 35px;
    margin-bottom: 0;
  }

  .item {
    list-style-type: none;
    margin-right: -18px;
  }

  .title {
    font-weight: 600;
    color: #293749;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 0;

    @media(max-width: 768px) {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
        font-style: normal;
        font-weight: bold;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    span {
        margin-left: 3px;
    }

    &__view-btn {
        color: #20D5C4;
        cursor: pointer;
    }
  }

  .avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border: 3px solid #fff;
    background: #fff;
  }

   @media (max-width: 1360px) {
      .add-aqua-btn {
          width: 40px;
          height: 40px;
          line-height: 34px;
          font-size: 18px;

          &--text {
            font-size: 14px;
          }
      }

      .no-img,
      .avatar {
          width: 40px;
          height: 40px;
      }

      .list {
          padding-left: 24px;
      }

   }
`