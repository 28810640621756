import { useState, useEffect } from 'react';
import { medias } from '../styles/styledVariables';

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const useCustomMedia = (value, def) => {
    const { width } = useWindowDimensions();

    if (width < medias[1]) {
        return value[0];
    }
    if (width < medias[2]) {
        return value[1];
    }
    if (width < medias[3]) {
        return value[2];
    }
    if (width < medias[4]) {
        return value[3];
    }
    if (width >= medias[4]) {
        return value[4];
    }

    return def;
};
