import React from 'react';
import { Link } from 'react-router-dom';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>The benefits of booking a leadership speaker</h2>

      <p className={s.paragraph}>
        Leadership speakers possess a unique ability to captivate audiences with their charisma,
        knowledge, and compelling stories. By leveraging their expertise, these speakers inspire
        individuals at all levels of an organization to develop their leadership skills and
        drive positive change. Whether you're seeking guidance on effective communication,
        strategic decision-making, or team building, our platform connects you with exceptional
        and best leadership speakers who can address your specific needs.
      </p>

      <h3 className={s.greenHeadingList}>Expertise and Knowledge</h3>
      <p className={s.paragraph}>
        <a className={s.link} href="/about" target="_blank">Leadership speakers</a> are
        typically experienced professionals who have achieved success
        in their respective fields. They bring a wealth of knowledge and expertise, providing
        valuable insights and practical strategies that can benefit individuals and teams.
        Their expertise can cover a wide range of leadership topics, such as effective
        communication, team building, decision-making, and strategic thinking.
      </p>

      <h3 className={s.greenHeadingList}>Inspiration and Motivation</h3>
      <p className={s.paragraph}>
        Leadership speakers often have compelling stories of their own leadership journeys,
        including overcoming challenges and achieving success. Their personal narratives
        can inspire and motivate individuals to push their limits, adopt new approaches,
        and strive for excellence. They can share valuable lessons and help cultivate a
        positive mindset among the audience.
      </p>

      <h3 className={s.greenHeadingList}>Skill Development</h3>
      <p className={s.paragraph}>
        Female speakers on Leadership can deliver practical tools, techniques, and frameworks
        that individuals can apply in their own leadership roles. They can provide guidance
        on specific areas, such as improving communication skills, enhancing emotional
        intelligence, fostering a positive work culture, or managing change
        effectively. Attendees can gain actionable insights and develop
        their leadership capabilities.
      </p>

      <h3 className={s.greenHeadingList}>Different Perspectives</h3>
      <p className={s.paragraph}>
        Leadership speakers bring diverse backgrounds and experiences to the table, offering fresh
        perspectives on leadership challenges. This can be particularly valuable in today's
        interconnected and rapidly changing world, where leaders need to adapt and innovate.
        Hearing from speakers with different viewpoints can expand one's thinking and enable
        leaders to approach problems from new angles.
      </p>

      <h3 className={s.greenHeadingList}>Networking Opportunities</h3>
      <p className={s.paragraph}>
        Events featuring inspirational leadership speakers often attract a diverse audience of
        professionals, including executives, managers, and aspiring leaders. Attending such
        events provides opportunities for networking and building connections with like-minded
        individuals. Interacting with other attendees and the speaker can foster collaboration,
        idea-sharing, and potential partnerships.
      </p>

      <h3 className={s.greenHeadingList}>Employee Engagement and Development</h3>
      <p className={s.paragraph}>
        Organizations that invest in leadership speakers demonstrate their commitment to employee
        growth and development. Bringing in experts to share insights and inspire employees can
        boost morale, engagement, and motivation. It also signals that the organization values
        its employees' professional development, which can contribute to employee retention and loyalty.
      </p>

      <h3 className={s.greenHeadingList}>Thought Leadership and Industry Knowledge</h3>
      <p className={s.paragraph}>
        Leadership speakers are often at the forefront of their fields, staying updated on the latest
        trends, research, and best practices. By booking a leadership speaker, organizations
        can tap into this knowledge base and expose their employees to cutting-edge ideas
        and strategies. This can help organizations stay competitive and drive innovation
        within their industry.
      </p>
    </>
  );
};

const TopicPageLeadership = () => {
  return (
    <TopicPageBase
      topicAlias="leadership"
      headerSubtitle={<>
        Book leadership speakers for your next event.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a Leadership speaker?"
      trendingSpeakersHeading="Trending Leadership Speakers"
      why={<Why />}
      speakerTopicText="leadership"
    ></TopicPageBase>
  )
};

export default TopicPageLeadership;
