import { useEffect } from 'react';

const useTitle = (title, replace) => {
  useEffect(() => {
    if (!title) {
      return;
    }

    const previousTitle = document.title;
    
    if (replace) {
      document.title = title;
    } else {
      document.title = `${title} | Finders Speakers`;
    }

    return () => {
      document.title = previousTitle;
    };
  }, [title]);
};

export default useTitle;
