import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default () => {
  return (
    <div style={{
      textAlign: 'center',
      paddingTop: '30vh'
    }}>
      <CircularProgress style={{ color: '#20D5C4' }} />
    </div>
  );
};
