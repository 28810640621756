import React, { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';
import { FormStyles, FormWrapperStyle, ButtonMagenta } from "../SignUpForm/SignUpForm";
import 'react-phone-input-2/lib/style.css'
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

export const SignUpFormCode = ({ data, submitCode, errorsData, postRegisterRequest }) => {
    const [code, setCode] = useState('');
    const [resentBtnText, setResentBtnText] = useState('Resend code');
    const isMobile = useMediaQuery({
        query: '(max-device-width: 600px)'
    })

    const [error, setError] = useState(null);

    useEffect(() => {
        let currentError = null;

        if (typeof errorsData.errorRegister === "string") {
            currentError = errorsData.errorRegister;
        }

        if (errorsData && errorsData.errorRegister && errorsData.errorRegister.hasOwnProperty('code')) {
            currentError = errorsData.errorRegister.code;
        }
        setError(currentError)
    }, [errorsData])

    const submitForm = async () => {
        await postRegisterRequest(data, process.env.REACT_APP_PHONE_VERIFICATION == '1' ? '?verify=1' : '');
        !error && setResentBtnText('Re-sent successfully');
        setTimeout(() => {
            setResentBtnText('Resend code');
        }, 3000)
        setCode('');
    };

    const handleChangeCode = (value) => {
        const reg = /^-?\d+\.?\d*$/;
        if (reg.test(value) || value === '') {
            setCode(value)
        }
        setResentBtnText('Resend code')
    }

    return (
        <FormCodeStyles>
            <div className="form__wrapper">
                <h3 className="form__subtitle">Now let’s verify some details</h3>
                <FormWrapperStyle >
                    <Form.Group
                        controlId="signUpFormLastName"
                        className={error ? 'red-border' : ''}
                    >
                        <Form.Control
                            required
                            type="text"
                            name="lastName"
                            autoComplete="off"
                            onChange={e => handleChangeCode(e.target.value)}
                            value={code}
                            placeholder=""
                        />
                        {error && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg'}
                            >
                                {error}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </FormWrapperStyle>

                <p className="text">We sent a text to the phone number below.</p>
                <p className="phone-number">+{data && data.number.phone}</p>
                <span className="resend-btn" onClick={submitForm}>{resentBtnText}</span>

                {isMobile && <ButtonNext type="button" onClick={() => submitCode(code)}>
                    next
                </ButtonNext>}

                <div className="text-problem">Problems receiving the code?
                     <Link
                        target="_blank"
                        to={`/contact-us`}>
                        <span className="resend-btn">Support Center</span>
                    </Link>
                </div>

                {!isMobile && <ButtonNext type="button" onClick={() => submitCode(code)}>
                    next
                </ButtonNext>}
            </div>
        </FormCodeStyles>
    );
}


export const ButtonNext = styled(ButtonMagenta)`
   width: 259px;
   height: 52px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 14px;
   white-space: nowrap;
   
   @media (max-width: 1024px) {
      max-width: 111px;
      height: 48px;
      text-align: center;
      padding: 0 40px;
      font-size: 12px;
      line-height: 16px;
      margin-top: 50px;
      margin-bottom: 42px;
   }

   @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 40px;
 }
`;

export const FormCodeStyles = styled(FormStyles)`
      padding-top: 215px;
      min-height: 100vh;
      justify-content: flex-start;
      padding-left: 95px;
      
      @media (max-width: 1360px) {
          padding-top: 174px;
      }

      @media (max-width: 1024px) {
          width: 100%;
          min-height: initial;
          margin-top: 89px;
          padding-top: 30px;
          padding-left: 0;
          flex-basis: 100%;     
      } 

      @media (max-width: 1360px) {
        justify-content: center;
      }

    .error-msg {
        top: -15px;    
    }
    
   .red-border {
      .react-tel-input,
      .form-control {
         border: 1px solid red;
         border-radius: 5px;
         height: 40px;
      }
    }
    
    .form__subtitle {
        max-width: 500px;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 20px;
        
        @media (max-width: 1360px) {
            max-width: 350px;
        }

        @media (max-width: 1024px) {
            margin-bottom: 60px;
        } 
    }
    
    .form-group {
        margin-bottom: 23px;

        @media (max-width: 1360px) {
            margin-bottom: 20px;
        } 
    }
    
    .form-control {
        width: 440px;
        height: 40px;
        font-size: 13px;
        line-height: 28px;
        margin-bottom: 23px;

        @media (max-width: 1024px) {
            width: 100%;
            margin-bottom: 24px;
        }
    }
    
    .text {
        font-size: 16px;
        line-height: 20px;
        color: #293749;
        margin-bottom: 6px;
        
         @media (max-width: 1024px) {
             font-size: 16px;
             line-height: 22px;
             margin-bottom: 14px;
        }
    }
    
    .phone-number {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: #293749;
        margin-bottom: 11px;
    }

    .resend-btn {
        text-decoration: underline;
    }

    .text-problem {
        color: #293749;
        font-size: 18px;
        line-height: 28px;
        height: 28px;
        margin-bottom: 16px;

        @media (max-width: 1360px) {
            font-size: 18px;
            line-height: 28px;
        } 
        
        .resend-btn {
            padding-left: 10px;
            
            @media (max-width: 1024px) {
                font-size: 16px;
                line-height: 22px;
                padding-left: 0;
                margin-top: 10px;
            } 
        }
    }
    
   .resend-btn {
        height: 28px;
        font-size: 18px;
        line-height: 28px;
        border: none;
        background: transparent;
        padding: 0;
        display: inline-block;
        font-weight: 600;
        color: #20D5C4;
        transition: opacity 0.3s ease-in-out;
        margin-bottom: 19px;
        
        &:hover {
          color: #589A94;
        }
                    
        &:active,
        &:focus {
            outline: none;
        }
        
        @media (max-width: 360px) {
            padding-left: 0;
        }
    }
`;