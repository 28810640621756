import React from 'react';
import { SpeakersHero } from '../HomePage/Redisign/HomeHero'
import styled from "styled-components";

export const FAQHero = ({ title, subHeader }) => {
  return (
    <FAQHeroStyles>
      <div className="bg" />
      <section className={`hero faq`}>
        <div className="container-fluid">
          <h1 className="title">{title}</h1>
          {subHeader}
        </div>
      </section>
    </FAQHeroStyles>
  )
}

export const FAQHeroStyles = styled(SpeakersHero)`
  .faq {
       padding-top: 204px;
       padding-bottom: 127px;
       
       @media (max-width: 1280px) {
          padding: 192px 0 139px 0;
       }
       @media (max-width: 768px) {
          padding: 120px 0 40px;
       }
      
      & .title {
        font-weight: 600;
        font-size: 72px;
        line-height: 72px;
        text-align: center;
        color: #fff;

        @media (max-width: 768px) {
          font-size: 36px;
          line-height: 42px;
          margin-bottom: 0;
        }
      }
    }

    .container-fluid {
      height: 138px;
    }
`;
