import { POST_ENQUIRY_FAIL, REGISTER_FAILURE, LOGIN_FAILURE } from '../actions';
import { ActionTypes } from '../constants/createEnquiryConstants';
const RESET_ERRORS = ActionTypes.RESET_ERRORS
const initialState = {};

const errors = (state = initialState, action) => {
    let obj = { ...state };
    if (action.type === POST_ENQUIRY_FAIL) {
        let finalError = action.payload.errors
            ? action.payload.errors
            : action.payload.error;
        obj = { ...obj, errorEnquiry: finalError };
    }
    if (action.type === REGISTER_FAILURE) {
        let finalError = !action.payload.error
            ? action.payload.errors
            : action.payload.error;
        let errorRegister;
        if (action.payload.errors) {
            errorRegister = finalError ;
        } else {
            errorRegister = action.payload.message;
        }
        obj = { ...obj, errorRegister: errorRegister };
    }
    if (action.type === RESET_ERRORS && !action.payload) {
        obj = { ...obj, errorRegister: {}, errorLogin: {} };
    }
    if (action.type === RESET_ERRORS && action.payload) {
        obj = { ...obj, errorRegister: {
                ...obj.errorRegister,
                [action.payload] : null,
            } };
    }
    if (action.type === LOGIN_FAILURE) {
        // Error handling
        let errorLogin = {};
        if (action.payload.errors) {
            errorLogin.errors = action.payload.errors;
        } if (action.payload.message) {
            errorLogin.message = action.payload.message;
        }
        obj = { ...obj, errorLogin };
    }
    return obj;
};

export default errors;
