import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Player from '@vimeo/player';
import qs from 'qs';
import heroVideoPoster from '../../assets/hero-video-poster.webp';
import heroVideoPosterSmall from '../../assets/hero-video-poster-small.webp';
// import { SearchForm as SearchComponent } from './SearchForm';
import { GreenFill } from '../../_components/GreenFill/GreenFill';

import styles from './styles.module.scss';

/*
const SearchForm = () => {
    const history = useHistory();

    const changeQueryHandler = value => {
        history.push({
            pathname: '/find-speakers',
            search: value.trim() ? new URLSearchParams({ search: value }).toString() : undefined
        });
    };

    return (
        <SearchComponent onSearch={changeQueryHandler} />
    );
}
*/

export const Header = ({ winSize, searchTopicId, subtitle }) => {
    const ref = useRef();
    const videoContainerRef = useRef();
    const playerRef = useRef();

    useEffect(() => {
        if (winSize > 768) {
            const options = {
                url: 'https://player.vimeo.com/video/740709736?h=33c6c10394',
                responsive: true,
                background: true,
                muted: true
            };

            playerRef.current = new Player(videoContainerRef.current, options);
        }

    }, [winSize > 768]);

    return (
        <HeaderStyles
            className={`s-home__header`}
            backgroundImage={heroVideoPoster}
            backgroundImageMobile={heroVideoPosterSmall}
        >
            <div className="background-wrapper">
                {winSize > 768 && (
                    <div className="background-video-container" ref={videoContainerRef} />
                )}
            </div>

            <div className="s-home__container" ref={ref}>
                    <h2 className="s-home__header-title">
                        <div>
                            <GreenFill
                                imageStyle={{ top: '-3%', left: '-3%', width: '109%', height: '109%' }}
                                reference={ref}
                                delay={2000}
                            >
                                <span> Speaker</span>
                            </GreenFill>
                            <span> bookings </span>
                        </div>
                        <span>made easy</span>
                    </h2>

                    <p className="s-home__header-subtitle">
                        {subtitle}
                    </p>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div className={styles.linkContainer}>
                        <Link
                            to={{
                                pathname: '/find-speakers',
                                search: qs.stringify({ topics: [searchTopicId] })
                            }}
                        >
                            <span className={styles.link}>
                                search<span className={styles.desktopHide}> talent</span>
                            </span>
                        </Link>

                        <Link to="/contact-us">
                            <span className={`${styles.link} ${styles.mobileHide}`}>enquire</span>
                        </Link>
                    </div>
                    {/* {
                        winSize > 768 && <div className="s-home__tags">
                            <h3 className="s-home__tags-title">What’s trending</h3>
                            <p className="s-home__tags-item">#diversity</p>
                            <p className="s-home__tags-item">#futuristic</p>
                            <p className="s-home__tags-item">#social media</p>
                            <p className="s-home__tags-item">#virtual events</p>
                        </div>
                    } */}
                </div>
            </div>
        </HeaderStyles>
    )
}

const HeaderStyles = styled.header`
  position:relative;
  overflow: hidden;

    .background-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100vw;
        height: 100%;
        background-image: url("${props => props.backgroundImage}");
        background-position: center;

        @media(max-width: 768px) {
            background-image: url("${props => props.backgroundImageMobile}");
            background-size: 1000px;
            background-color: #171F28;
        }
      }

      .background-video-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1920px;
      }
`;
