import {ActionTypes} from '../constants/createEnquiryConstants';

const {
    SET_SPEAKER_LIST_ADMIN_INIT,
    SET_SPEAKER_LIST_ADMIN_SUCCESS,
    SET_SPEAKER_LIST_ADMIN_ERROR,
        } = ActionTypes;

const initialState = {
    loading: false,
    loaded: false,
    errors: false,
    data: [],
};

function admin(state = initialState, {type, payload}) {
    switch (type) {
        case SET_SPEAKER_LIST_ADMIN_INIT:
            return {...state, loading: true, loaded: false, errors: false};
        case SET_SPEAKER_LIST_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                errors: false,
                data:  payload,
            };
        case SET_SPEAKER_LIST_ADMIN_ERROR:
            return {...state, loading: false, loaded: false, errors: true};
        default:
            return state;
    }
}

export default admin;
