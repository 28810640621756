import React from 'react';
import {ReactComponent as DownloadIcon} from '../../../assets/download-arrow.svg';
import styles from './styles.module.scss';

const DownloadButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className={styles.main}>
      Download CSV
      <DownloadIcon/>
    </button>
  );
};


export default DownloadButton;
