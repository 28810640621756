import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import CallIcon from '@material-ui/icons/Call';
import { TOPICS } from '../../constants';
import logo from '../../assets/logo_light_new.svg';
import { GreenLineOnHover } from "../../HomePage/GreenLineOnHover";
import { withRouter } from 'react-router-dom'
import SideBar from "../../_components/Sidebar";
import constants from '../../constants';
import { logout } from '../../store/actions/authenticationActions';
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import { useDispatch, useSelector } from "react-redux";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import Dropdown from './Dropdown';

import styles from './Header.module.scss';


// Header for Main page - logged out view
function Header({ onClickLogin, ...props }) {
  const isLightHeader = props.location.pathname.includes('register')
    || props.location.pathname.includes('builder')
    || props.location.pathname.includes('payment')
    || props.location.pathname.includes('register');
  const windowSize = useWindowDimensions();
  let avatar = useSelector(state => state.user.avatar);

  const headerRef = useRef();

  const dispatch = useDispatch();

  const handlerLogout = () => {
    dispatch(logout());
    dispatch({ type: 'SET_IS_LOGIN', payload: false });
    dispatch({ type: 'SET_AVATAR', payload: '' });
  };

  if (
    avatar === `${process.env.REACT_APP_DO_SPACES_URL}/null` ||
    avatar === `${process.env.REACT_APP_DO_SPACES_URL}/`
  ) {
    avatar = '';
  }

  useEffect(() => {
    const changeHeader = () => {
      if (window.pageYOffset > 0) {
        headerRef.current.style.backgroundColor = '#293749';
        headerRef.current.style.boxShadow = '0 0 50px 10px rgba(0,0,0,.75)';
      } else {
        headerRef.current.style.backgroundColor = 'initial';
        headerRef.current.style.boxShadow = 'none';
      }
    };

    window.addEventListener('scroll', changeHeader);


    return () => {
      window.removeEventListener('scroll', changeHeader);
    };
  }, []);

  return (
    <header ref={headerRef} className={`${styles.header} ${isLightHeader ? styles.headerLight : ''}`}>
      {
        windowSize.width < 1024
          ? <SideBar onClickLogin={onClickLogin} isVerified={false} onLogOut={handlerLogout} theme={'dark'} />
          :
            <div className={styles.header_wrapper}>
              <div className={styles.groupOne} style={{ minWidth: 'calc(48% - 130px)' }}>
                <ul className={styles.headerNav}>
                  <li style={{fontSize: '14px'}}>
                    <Link to={`/about`}>
                      <GreenLineOnHover
                        target={'Our Story'}
                        bgImg={() => require('../../assets/home-page/green_line_why.png')}
                        moveBottom="-6px"
                        lineHeight="15px"
                        whiteSpace="nowrap"
                      />
                    </Link>
                  </li>

                  <li style={{fontSize: '14px'}}>
                    <Link to={`/`}>
                      <Dropdown
                        menu={
                          <ul className={styles.menu} style={{ position: 'static' }}>
                            {TOPICS.map(([topic, path]) => (
                              <li className={styles.dropdownMenuItem}>
                                <a
                                  target="_blank"
                                  href={path}
                                  onClick={e => e.stopPropagation()}
                                >{topic}</a>
                              </li>
                            ))}
                          </ul>
                        }
                      >
                        <GreenLineOnHover
                          target={'Trending Topics'}
                          bgImg={() => require('../../assets/home-page/green_line_process_2.png')}
                          moveBottom="-6px"
                          lineHeight="15px"
                          whiteSpace="nowrap"
                        />
                      </Dropdown>
                    </Link>
                  </li>

                  <li style={{fontSize: '14px'}}>
                    <Link to="/find-speakers">
                      <GreenLineOnHover
                        target={'Browse Speakers'}
                        bgImg={() => require('../../assets/home-page/green_line_process_1.png')}
                        moveBottom="-6px"
                        lineHeight="10px"
                        whiteSpace="nowrap"
                      />
                    </Link>
                  </li>

                  {
                    /* 
                    <li style={{fontSize: '14px'}}>
                      <Link to="/how-it-works">
                        <GreenLineOnHover
                          target={'How It Works'}
                          bgImg={() => require('../../assets/home-page/green_line_process_32.svg')}
                          moveBottom="-6px"
                          lineHeight="10px"
                          whiteSpace="nowrap"
                        />
                      </Link>
                    </li>
                    */
                  }

                  <li style={{fontSize: '14px'}}>
                    <a href="/blog/" target="_blank">
                      <GreenLineOnHover
                        target="Latest News"
                        bgImg={() => require('../../assets/greenline_about_finders.png')}
                        moveBottom="-6px"
                        lineHeight="10px"
                        whiteSpace="nowrap"
                      />
                    </a>
                  </li>

                  {/*
                    <li style={{fontSize: '14px'}}>
                      <Link to="/speaker-page">
                        <GreenLineOnHover
                          target={'I’m a Speaker'}
                          bgImg={() => require('../../assets/home-page/green_line_process_2.svg')}
                          moveBottom="-6px"
                          lineHeight="10px"
                          whiteSpace="nowrap"
                        />
                      </Link>
                    </li>
                  */}
                </ul>

                <Link to="/" className={styles.logo}>
                  <img
                    src={logo}
                    alt="finder speakers logo"
                    style={{width: '210px'}}
                  />
                </Link>
              </div>
              <ul className={styles.groupTwo}>
                <li>
                  <button
                    className={styles.btnMenu}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={onClickLogin}
                  >
                    Login
                    </button>
                </li>
                <li>
                  <a
                    href={`tel:${constants.phone}`}
                    style={{
                      fontSize: '14px',
                      fontWeight: 600
                    }}
                  ><CallIcon /> <span style={{ verticalAlign: 'middle' }}>{constants.phone}</span></a>
                </li>
                <li>
                  <Link to="/contact-us">
                    <span className={styles.getStart}>
                      <span>contact us</span>
                    </span>
                  </Link>
                  {/*
                    <Link to="/get-started">
                      <span className={styles.enquire}>
                        Get Started
                      </span>
                    </Link>
                  */}
                </li>
              </ul>
            </div>
      }
    </header>
  );
}

export default withRouter(Header);
