import React from 'react';
import {Link} from 'react-router-dom';
import bg from '../../assets/get-start-page/curve-mobile.png';
import logo from '../../assets/findersspeakers-logo-white.png';
import styled from "styled-components";
import Sidebar from '../../_components/Sidebar'
import useWindowDimensions from "../../helpers/useWindowDimentsions";

export const EasyHeader = ({onClickLogin}) => {

    const windowSize = useWindowDimensions();

    if(windowSize.width > 1024) {
        return (
            <HeaderStyle>
                <div className="header_wrapper">
                    <Link to="/">
                        <img
                            className="logo"
                            src={logo}
                            alt="logo"
                        />
                    </Link>
                    <div className="login-link">
                        <span>Already have an account?</span>
                        <a href="#" className="aqua" onClick={onClickLogin}>Log in</a>
                    </div>
                </div>
            </HeaderStyle>
        );
    }  else  {
        return (
            <HeaderStyle>
                <div className="header_wrapper">
                    <Sidebar onClickLogin={onClickLogin} theme="dark" />
                </div>
            </HeaderStyle>
        );
    }

};
export const HeaderStyle = styled.header`
    .header_wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 68px 0;
        
        @media (max-width: 1300px) {
          padding: 20px 72px 0 85px;
        }
        @media (max-width: 1024px) {
          background-image: url(${bg});
          max-height: 89px;
          background-color: #161E27;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          padding: 0;

        }
         .logo {
            max-height: 52px;
            
            @media (max-width: 1024px) {
              max-height: 29px;
            }
        }
    }
    
    .login-link {
        font-size: 18px;
        line-height: 100px;
        color: #293749;
        @media (max-width: 1270px) {
            margin-top: 16px;
            font-size: 16px;
            line-height: 20px;
        } 
    }
    
    .aqua {
      color: #20D5C4;
      display: inline-block;
      margin-left: 10px;
       @media (max-width: 1024px) {
           font-size: 16px;
        } 
    }
`;