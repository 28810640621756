import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { fetchTopics } from '../../store/actions/topicsActions';
import getFileUrl from '../../helpers/getFileUrl';
import ApiDataProvider from '../../api/apiClient';
import { HomeSlider } from '../../HomeStage2Screens/home_slider/HomeSlider';
import { Header } from '../../HomeStage2Screens/header/Header';
import SpeakerCard from './SpeakerCard';

import s from './TopicsPageBase.module.scss';
import SecondPanel from '../../_components/SecondPanel';


const TopicPageBase = ({
  heading,
  why,
  topicAlias,
  formHeading,
  headerSubtitle,
  topListItems,
  trendingSpeakersHeading,
  speakerTopicText,
  searchButtonIgnoreTopic
}) => {
  const [speakers, setSpeakers] = useState([]);

  const dispatch = useDispatch();

  const topics = useSelector(store => store.topics.data);

  const [topicId, setTopicId] = useState();

  useEffect(() => {
    ApiDataProvider.getTrendingSpeakersByTopic(topicAlias).then(result => {
      setSpeakers(result.data);
    }).catch(() => {});
  }, []);

  useEffect(() => {
    if (topics.length === 0) {
      dispatch(fetchTopics());
    } else {
      const topic = topics.find(({ alias }) => alias === topicAlias);

      if (topic) {
        setTopicId(topic.id);
      }
    }
  }, [topics.length]);

  return (
    <>
      <Header
        searchTopicId={searchButtonIgnoreTopic ? undefined: topicId}
        subtitle={headerSubtitle}
      />

      <div className={s.content}>

        <div className={s.description}>
          <SecondPanel
            heading={heading}
            formHeading={formHeading}
            topListItems={topListItems}
          />
        </div>
        
        <div className={s.speakersBlockWrapper}>
          <div className={s.speakersBlock}>
            <div className={s.header}>
              <h2 className={s.title}>{trendingSpeakersHeading}</h2>
              <p className={s.subtitle}>
                Get access to a curated collective of the most
                diverse and exciting global speaking talent
              </p>
            </div>

            <div className={s.speakersList}>
              {speakers.map(({
                id,
                first_name,
                last_name,
                photo,
                professional_title,
                tagline,
                expertise
              }) => (
                <SpeakerCard
                  id={id}
                  name={`${first_name} ${last_name}`}
                  avatarPath={photo}
                  professionalTitle={professional_title}
                  tagline={tagline}
                  speakerTypes={expertise.speaker_type}
                  speakerTopics={expertise.speaker_topic}
                />
              ))}
            </div>

            <div className={s.buttons}>
              <Link
                to={{
                  pathname: '/find-speakers',
                  search: searchButtonIgnoreTopic? undefined : qs.stringify({ topics: [topicId] })
                }}
                className={s.button}
              >view more speakers</Link>
            </div>
          </div>
        </div>

        <div className={s.callToActionBlock}>
          <h2 className={s.top}>Need help<br /><span>finding a speaker?</span></h2>
          <div className={s.bottom}>
            <h3>Get in touch</h3>
            <p>Let our team do it for you</p>
            <Link to="/contact-us" className={s.button}>Send us your brief</Link>
          </div>
        </div>

        <div className={s.howWeHelpBlock}>
          <h2 className={s.subheading}>How we help you book talent</h2>

          <p className={s.paragraph}>
            At <Link to="/" className={s.link}>Finders Speakers Co</Link>,
            we strive to provide our clients with direct access
            to the best {speakerTopicText} speakers in Australia and internationally.
            We partner with our clients to offer the following benefits:
          </p>

          <p className={s.paragraph}>
            <ol className={s.list}>
              <li>
                <strong>Personalized Approach:</strong> We work closely with our clients to understand
                their specific needs and goals, and provide tailored solutions to meet
                their unique requirements.
              </li>

              <li>
                <strong>Extensive Speaker Selection:</strong> We offer hundreds of
                famous {speakerTopicText} and celebrity {speakerTopicText} speakers
                who have achieved remarkable success
                in their fields and have a compelling story to share.
              </li>

              <li>
                <strong>Industry Expertise:</strong> Created by event experts our team has extensive
                experience and knowledge of the speaking and events industry, ensuring that
                we only recommend the best and most suitable speakers for your event.
              </li>

              <li>
                <strong>Easy Search and Booking:</strong> We make the speaker booking process easy,
                flexible and
                hassle-free to help you easily find the perfect speaker for your next events.
                Our service is 100% free.
              </li>

              <li>
                <strong>Exceptional Customer Service:</strong> <a
                    className={s.link} href="/about" target="_blank"
                  >Our team</a> is dedicated to providing exceptional
                customer service and support throughout the entire process, from initial consultation
                to post-event follow-up.
              </li>
            </ol>
          </p>

          <div className={s.buttons}>
            <Link className={s.button} to="/find-speakers">search talent</Link>
            <Link className={s.button} to="/contact-us">enquire now</Link>
          </div>
        </div>

        <div>
          <h2 className={s.subheading}>What people say</h2>

          <HomeSlider />
        </div>

        <div className={s.whyBlock}>
          {why}
        </div>

        <div className={s.aboutBlock}>
          <h2 className={s.subheading}>About Finders Speakers</h2>
          <div className={s.aboutContainer}>
            <p>
              Finders Speakers is a next-generation talent booking marketplace helping
              businesses <Link to="/find-speakers" className={s.link}>
              find and book</Link> speakers for events. Representing hundreds of leading
              global keynote and celebrity speakers, the platform makes the process of finding talent
              easier, faster and more cost effective for modern-day professionals, all briefs and budgets.
            </p>

            <p>
              Powered by AI and smart tech, Finders Speakers offers a game-changing booking solution
              helping clients to work faster and smarter to drive more value from their speaker search.
            </p>

            <p>
              <Link to="/" className={s.link}>Finders Speakers</Link> was created in response to
              the increasing demands placed on teams
              to get events right and do more with less. We remove the complexity, costs and
              bureaucracy that surrounds the traditional speaker search process. The powerful platform
              offers a dual-operating model that empowers event organisers to choose how they want
              to find talent.
            </p>

            <p>
              Clients can collaborate with talent directly from search to pay, or partner with in-house
              experts who will fully manage and curate the speaker search.
            </p>

            <p>
              Finders Speakers is the innovative speaker booking solution putting clients back
              in the control seat driving more flexibility, speed and choice for better speaker
              matches and exceptional event experiences.
            </p>
          </div>
        </div>
      </div>
    </>
  )
};

export default TopicPageBase;
