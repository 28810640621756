import React from 'react';
import styled from "styled-components";
// import Slider from "react-slick";
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import getFileUrl from "../../helpers/getFileUrl";
import addWhitespaces from '../addWhitespaces';
// import HorizontalScroll from '../HorizontalScroll';


export const SpeakersTypes = ({ data, onSelectType }) => {
    const { width } = useWindowDimensions();
    const speakerTypes = [
        { title: 'Keynote', img: require('../../assets/keynote.svg'), id: 1 },
        { title: 'Expert guest', img: require('../../assets/mc.svg'), id: 2 },
        { title: 'Virtual presenter', img: require('../../assets/virtual-presenter.svg'), id: 3 },
        { title: 'MC / Host', img: require('../../assets/panellist.svg'), id: 4 },
        { title: 'Coach / Trainer', img: require('../../assets/trainer.svg'), id: 5 },
        { title: 'Entertainer / Celebrity', img: require('../../assets/entertainer.svg'), id: 6 },
    ];

    const sliderOptions = {
        variableWidth: true,
        arrows: false,
        infinite: true,
        autoplaySpeed: 5000,
        swipeToSlide: false,
        dots: true,
    };

    return (
        <section className={'s-home__types'}>
            <div className="s-home__types-wrapper">
                <div className="s-home__types-title">
                    <span>It is easy, fast and free to&nbsp;start searching</span>
                </div>
                <div className="s-home__types-container">
                    {addWhitespaces(data).map(elem => {
                        return (
                            <div
                                className="s-home__types-elem-container"
                                key={elem.id}
                                onClick={() => onSelectType(elem.id)}
                            >
                                <img src={getFileUrl(elem.image)} />
                                <div>{elem.name}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section >
    )
}

const StyledTypesSlider = styled.div`
    .slick-dots {
        display: flex;
        margin-left: -20px;
        height: 6px;
        width: 100%;
        position: absolute;
        bottom: -25px;
        border-radius: 3px;
        padding: 0;
        list-style: none;
        text-align: center;
        justify-content: stretch;
        transform: translateX(20px);

        li {
            height: 6px;
            list-style: none;
            width: auto;
            width: ${props => 320 / props.speakerTypes.length}px;
            margin: 0;
            background-color: #ABB0BE;
            
            button {
                opacity: 0;
                width: 100%;
            }
        }

        .slick-active {
            background-color: #ffffff;
            border-radius: 3px;
        }
    }

    .slick-list {
        margin-left: 15px;
    }
`