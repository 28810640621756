import { connect } from 'react-redux';
import HomeStage2Screen from './HomeStage2Screen';
import {setPortalStatus} from '../store/actions/createEnquiryActions';

const mapDispatchToProps = {
  setPortalStatus,
}

const mapStateToProps = state => ({
  user: state.user,
  portal: state.portal
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeStage2Screen);
