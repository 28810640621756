import {
  GET_ALL_TOPICS_INIT,
  GET_ALL_TOPICS_SUCCESS,
  GET_ALL_TOPICS_FAILURE,
} from '../actions';
import ApiDataProvider from '../../api/apiClient';

export const fetchTopics = () => {
  return async dispatch => {
    try {
      dispatch({ type: GET_ALL_TOPICS_INIT });

      const topics = await ApiDataProvider.getTopics();

      dispatch({
        type: GET_ALL_TOPICS_SUCCESS,
        payload: topics.data
      });
    } catch (e) {
      dispatch({
        type: GET_ALL_TOPICS_FAILURE,
        payload: e
      });
    }
  };
};
