import React, { useEffect, useRef, useState, forwardRef } from 'react';
import styled from "styled-components";
import PrimaryButton from '../_components/buttons/PrimaryButtonLarge';
import chevron from '../assets/icons/arrow-light.svg';
import { ReactComponent as Chevron } from '../assets/icons/arrow-light.svg';
import getGoogleLocationDetails from '../api/GoogleMapsProvider';
import { Card } from 'react-bootstrap';
import { FilterListInner } from './FilterListInner';
import { Collapse } from 'reactstrap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { SearchFormSmall } from './SearchFormSmall';
import useWindowDimensions from "../helpers/useWindowDimentsions";
import styles from './FilterList.module.scss';

const getLabels = (options, values) => {
    return options.filter(option => values.some(value => value === option.value)).map(({ label }) => label);
};

const Input = props => <input {...props} className={styles.locationInput} />;

const Label = ({ children }) => {
    return <div className={styles.dropdownSelectedLabels}>{children}</div>;
};

export const FilterList = ({ values, onChange, onSearch, onReset, filterOptions }) => {
    const [filtersStatus, setFiltersStatus] = useState(1);
    const { width } = useWindowDimensions();

    const [keywordsSearchString, setKeywordsSearchString] = useState('');

    const typeLabels = getLabels(filterOptions['types'], values['types']);
    const topicLabels = getLabels(filterOptions['topics'], values['topics']);
    const formatLabels = getLabels(filterOptions['event_types'], values['event_types']);
    const budgetLabels = getLabels(filterOptions['budgets'], values['budgets']);
    const genderLabels = getLabels(filterOptions['genders'], values['genders']);

    useEffect(() => {
        if (width < 800) {
            setFiltersStatus(1);
        } else setFiltersStatus(2);
    }, [width]);

    const autocompleteSelect = object => {
        getGoogleLocationDetails(object.place_id).then(result => {
            onChange({
                location: result || ''
            });
        });
    };

    const [isOpen, setIsOpen] = useState({
        0: false, // topic
        1: false, // type
        2: false, // event types
        3: false, // budget
        4: false, // gender
    });

    const toggle = (id) => {
        setIsOpen({
            ...isOpen,
            [id]: !isOpen[id],
        })
    };

    const onClickDropdown = id => {
        setIsOpen({
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            [id]: !isOpen[id],
        });
    };

    const getFilterOptions = (filterName, filterValueList) => {
        onChange({
            [filterName]: filterValueList,
        });
    }

    const handleClearFilter = () => {
        setKeywordsSearchString('');
        onReset();
    }

    const topicRef = useRef();
    const typeRef = useRef();
    const formatRef = useRef();
    const priceRef = useRef();
    const genderRef = useRef();

    const listener = event => {
        if (!topicRef.current || !typeRef.current || !formatRef.current || !priceRef.current || !genderRef.current) {
            return
        } else if (
            topicRef.current.contains(event.target) ||
            typeRef.current.contains(event.target) ||
            formatRef.current.contains(event.target) ||
            priceRef.current.contains(event.target) ||
            genderRef.current.contains(event.target)
        ) return;

        const newState = { 0: false, 1: false, 2: false, 3: false, 4: false }
        setIsOpen(newState);
    };

    useEffect(() => {
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    });

    useEffect(() => {
        setKeywordsSearchString(values.search);
    }, [values.search]);

    return (
        <FilterStyles filtersStatus={filtersStatus}>
            {filtersStatus !== 0 && (
                <SearchFormSmall
                    value={keywordsSearchString}
                    onChange={value => {
                        setKeywordsSearchString(value);
                    }}
                    onSearch={value => {
                        onChange({
                            search: value
                        });
                    }}
                />
            )}

            {filtersStatus !== 0 && (
                <div
                    className={isOpen['1'] ? 'visible filter-container' : 'filter-container'}
                    ref={typeRef}
                    style={{ position: 'relative' }}
                >
                    <Card.Header
                        onClick={() => {
                            onClickDropdown(1);
                        }}>
                        {
                            typeLabels.length > 0
                              ? typeLabels.map(label => <Label>{label}</Label>)
                              : <div className={styles.dropdownPlaceholders}>Type</div>
                        }
                        <Chevron />
                    </Card.Header>
                    <div className="collapse-container">
                        <Collapse isOpen={isOpen[1]}>
                            <FilterListInner
                                selectedOptions={values['types']}
                                onChangeFilter={newSelectedOptions => onChange({
                                    types: newSelectedOptions
                                })}
                                isScrolling={true}
                                data={filterOptions['types']}
                            />
                        </Collapse>
                    </div>
                </div>
            )}

            {filtersStatus > 1 &&
                <div className="location filter-container">
                    <Card.Body>
                        <GooglePlacesAutocomplete
                          // force re-mounting to drop the inner state when values.location === ''
                            key={!!values.location}
                            placeholder="Location search"
                            initialValue={values.location}
                            onSelect={(e) => autocompleteSelect(e)}
                            autocompletionRequest={{
                                bounds: [
                                    {
                                        lat: 99,
                                        lng: 99,
                                    },
                                    {
                                        lat: 100,
                                        lng: 100,
                                    },
                                ],
                            }}
                            renderInput={props => {
                                return <Input
                                  {...props}
                                  onChange={(...args) => {
                                      if (props.onChange) {
                                          if (!args[0].target.value) {
                                              onChange({
                                                  location: ''
                                              });
                                          }
                                          return props.onChange(...args);
                                      }
                                  }}
                                />
                            }}
                            suggestionsStyles={{
                                suggestion: {
                                    cursor: 'pointer'
                                }
                            }}
                        />
                    </Card.Body>
                </div>
            }

            {filtersStatus !== 0 && (
                <div
                    className={isOpen['0'] ? 'visible filter-container' : 'filter-container'}
                    ref={topicRef}
                    style={{ position: 'relative' }}
                >
                    <Card.Header onClick={() => {
                        onClickDropdown(0);
                    }}>
                        {
                            topicLabels.length > 0
                              ? topicLabels.map(label => <Label>{label}</Label>)
                              : <div className={styles.dropdownPlaceholders}>Topic</div>
                        }
                        <Chevron />
                    </Card.Header>
                    <div className="collapse-container">
                        <Collapse isOpen={isOpen[0]}>
                            <FilterListInner
                                selectedOptions={values.topics}
                                onChangeFilter={(filterValueList) => getFilterOptions('topics', filterValueList)}
                                isScrolling={true}
                                data={filterOptions.topics}
                            />
                        </Collapse>
                    </div>
                </div>
            )}

            {filtersStatus > 1 &&
                <div
                    className={isOpen['2'] ? 'visible filter-container' : 'filter-container'}
                    ref={formatRef}
                    style={{ position: 'relative' }}
                >
                    <Card.Header onClick={() => {
                        onClickDropdown(2);
                    }}>
                        {
                            formatLabels.length > 0
                              ? formatLabels.map(label => <Label>{label}</Label>)
                              : <div className={styles.dropdownPlaceholders}>Format</div>
                        }
                        <Chevron />
                    </Card.Header>
                    <div className="collapse-container">
                        <Collapse isOpen={isOpen[2]}>
                            <FilterListInner
                                selectedOptions={values.event_types}
                                onChangeFilter={(filterValueList) => {
                                    getFilterOptions('event_types', filterValueList)
                                }}
                                isScrolling={true}
                                data={filterOptions.event_types}
                            />
                        </Collapse>
                    </div>
                </div>
            }

            {filtersStatus > 1
                ? (
                    <div className="budget-gender-filters">
                        <div
                            className={isOpen['3'] ? 'visible filter-container' : 'filter-container'}
                            style={width > 800
                                ? { width: '144px', marginRight: '-10px', position: 'relative' }
                                : { width: '320px', position: 'relative' }
                            }
                            ref={priceRef}
                        >
                            <Card.Header onClick={() => {
                                onClickDropdown(3);
                            }}>
                                {
                                    budgetLabels.length > 0
                                      ? budgetLabels.map(label => <Label>{label}</Label>)
                                      : <div className={styles.dropdownPlaceholders}>Price</div>
                                }
                            <Chevron />
                            </Card.Header>
                            <div className="collapse-container">
                                <Collapse isOpen={isOpen[3]}>
                                    <FilterListInner
                                        data={filterOptions.budgets}
                                        selectedOptions={values.budgets}
                                        onChangeFilter={(filterValueList) => {
                                            getFilterOptions('budgets', filterValueList)
                                        }}
                                    />
                                </Collapse>
                            </div>
                        </div>

                        <div
                            className={isOpen['4'] ? 'visible filter-container' : 'filter-container'}
                            style={width > 800
                                ? { width: '144px', position: 'relative' }
                                : { width: '320px', position: 'relative' }
                            }
                            ref={genderRef}
                        >
                            <Card.Header onClick={() => {
                                onClickDropdown(4);
                            }}>
                                {
                                    genderLabels.length > 0
                                      ? genderLabels.map(label => <Label>{label}</Label>)
                                      : <div className={styles.dropdownPlaceholders}>Gender</div>
                                }
                                <Chevron />
                            </Card.Header>
                            <div className="collapse-container">
                                <Collapse isOpen={isOpen[4]}>
                                    <FilterListInner
                                        selectedOptions={values.genders}
                                        onChangeFilter={(filterValueList) => getFilterOptions('genders', filterValueList)}
                                        data={filterOptions.genders} />
                                </Collapse>
                            </div>
                        </div>
                    </div>
                )
                : null
            }
            <div className="filters-controls">
                {width > 1120
                  ? (
                    <div className="filters-buttons">
                        <div
                          className="filters-buttons__elem"
                          onClick={handleClearFilter}
                        >reset filters</div>
                        <div>&nbsp;|&nbsp;</div>
                        <div
                          className="filters-buttons__elem"
                          onClick={() => setFiltersStatus(prev => {
                              if (prev === 0) {
                                  return 2;
                              } else return 0;
                          })}
                        >{filtersStatus === 2
                          ? 'hide filters'
                          : 'show filters'
                        }</div>
                    </div>
                  )
                  : (
                    <div className="filters-buttons">
                        <div
                          className="filters-buttons__elem"
                          onClick={() => setFiltersStatus(2)}
                        >MORE SEARCH FILTERS</div>
                        <div>&nbsp;|&nbsp;</div>
                        <div
                          className="filters-buttons__elem"
                          onClick={handleClearFilter}
                        >RESET</div>
                        <div>&nbsp;|&nbsp;</div>
                        <div
                          className="filters-buttons__elem"
                          onClick={() => setFiltersStatus(prev => {
                              if (prev === 0) {
                                  return 1;
                              } else return 0;
                          })}
                        >{filtersStatus > 0
                          ? 'HIDE'
                          : 'SHOW'
                        }</div>
                    </div>
                  )
                }

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <PrimaryButton onClick={() => {
                        onChange({
                            search: keywordsSearchString
                        });
                    }}>find talent</PrimaryButton>
                </div>

                <div />
            </div>
        </FilterStyles>
    )
}

const FilterStyles = styled.section`
  background: #fff;
  border-radius: 5px;
  padding: ${props => props.filtersStatus === 0 ? '10px 0' : '36px 0 26px 0'}
  width: 976px;
  display: flex;
  justify-content: space-around;
    align-items: start;
    row-gap: 20px;
  flex-wrap: wrap;

  .collapse-container {
    width: calc(100% + 2px);
    backgroundColor: green;
    position: absolute;
    right: -1px;
  }

  .collapsing {
      z-index: 2 !important;
  }

  @media (max-width: 768px) {
      row-gap: 5px;
      height: auto;
      padding: 25px 0 15px 0;
  }

  .card-body select {
      width: 100% !important;
  }

  .card { 
      background: transparent;
      border-radius: 0;
      border: none;
      box-shadow: none;
  }
    .location {
      .card-body {
          padding: 0;
      }
    }
    .title__wrapper {
      justify-content: space-between;
    }
    .google-places-autocomplete {
        input {
            height: 38px;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #DCDFE0;
            box-sizing: border-box;
            border-radius: 5px;
            box-shadow: none;
            padding: 9px 16px;
            font-weight: 600;
            font-size: 16px !important;
            line-height: 17px;

            @media (max-width: 800px) {
                height: 38px;
            }

            &::placeholder {
                color: #293749;
                font-weight: 600;
                font-size: 16px;

                @media (max-width: 800px) {
                    font-size: 16px;
                }
            }
        }
    }

    .card-header {
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
        background: transparent;
        border-radius: 0;
        border-top: 1px solid #ccc;
        font-weight: 600;
        font-size: 16px;
        color: #293749;
        position: relative;
        border-bottom: none;
        padding: 5px 13px;
        padding-right: 32px;
        line-height: 17px;
        cursor: pointer;

        @media (max-width: 800px) {
            font-size: 16px;
        }
    }
  
  svg {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%)  rotate(90deg);
    fill: #293749;
    // opacity: 0.4;
    transition: transform 0.4s linear;
      width: 20px;
      height: 20px;
    
    path {
      fill: #293749;
    }
  }
  
  .visible {
      svg {
          transform: rotate(270deg) translate(50%);
      }
  }

  .budget-gender-filters {
      width: 308px;
    display: flex;
    justify-content: space-between;
      align-items: start;

    @media(max-width: 600px) {
        flex-direction: column;
        width: unset;
    }
  }

  .filter-container {
      width: 308px;
      // height: 40px;
      border: 1px solid #293749;
      border-radius: 5px;
      box-shadow: 0px 15px 50px rgba(167, 171, 172, 0.1);

      @media (max-width: 800px) {
          margin-top: 16px;
          width: 320px;
      }
  }
  
  .add-aqua-btn {
    border: 3px solid #fff;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    color: #fff;
    line-height: 47px;
    position:absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    background: #20D5C4;
    
    &:focus {
        outline: none;
      }
  }
  
  .list {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: 35px;
    margin-bottom: 0;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #293749;
    margin-bottom: 24px;
  }
  
  .avatar {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    border: 3px solid #fff;
  }

  .btn-enquire {
      border: none;
      background-color: transparent;
      padding-right: 22px;
      background-image: url(${chevron});
      background-repeat: no-repeat;
      background-position: right 5px center;
      transition: background-position 0.3s ease-in-out;
      position:absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #20D5C4;
      
      &:focus {
        outline: none;
      }
      
      &:hover {
        background-position: right 0 center;
      } 
  }

    .filters-controls {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        flex-grow: 1;

        @media(max-width: 800px) {
            grid-template-columns: 1fr;
        }
    }
    
  .filters-buttons {
      display: flex;
      align-items: center;
      justify-content: start;
      color: #E8388A;

      @media(max-width: 800px) {
          justify-content: center;
        margin-bottom: 15px;
      }

      &__elem {
            text-transform: uppercase;
            color: #E8388A;
            cursor: pointer;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
      }
  }
    
  .ScrollbarsCustom {
      padding: 10px 0 !important;
      overflow: hidden;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      
      &::after {
          content: '';
          position:absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 18px;
          background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 46%,rgba(255,255,255,0) 100%); 
      }
  }
  
  .clear-filter {
        border: none;
        background: transparent;
        text-transform:uppercase;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #E8388A;
        margin-bottom: 22px;
        padding: 0;
        
        &:focus,
        &:active,
        &:visited {
          outline: none;
        }
  }
      
  @media (max-width: 768px) {
      .title {
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 23px;
      }
      
      .ScrollbarsCustom {
          padding: 0 !important;
      }
      
      .title__wrapper {
          justify-content: flex-start;
          
          .clear-filter {
              margin-left: 12px;
          }
      }
  }
`
