import React, { useEffect } from 'react';
import bg from '../assets/get-start-page/email_confirm_bg.png';
import 'react-phone-input-2/lib/style.css'
import styled from "styled-components";
import {Link} from "react-router-dom";
import logo from "../assets/findersspeakers-logo-white.png";

const SignUpEmail = ({ email, resendConfirmation, resetReduxState, resentBtnText }) => {
    useEffect(() => {
        return () => {
            resetReduxState && resetReduxState();
        };
    }, []);

    return (
        <FormEmail>
            <section className="get-start__info">
                <Link to="/" className="logo">
                    <img
                        src={logo}
                        alt="logo"
                    />
                </Link>
                <h1 className="get-start__title">
                    Check your inbox
                </h1>
                <p className="get-start__text">
                    Thanks for signing up with Finders Speakers. We have sent an email to
                    <span className="get-start__link"> {email} </span>
                    asking you to verify your email <span className="md-block">address.</span>
                </p>
                <p className="text">Please make sure it did not go into your <span
                    className="sm-block">spam folder</span></p>
                <ButtonSignIn>
                    <Link to={`/`}>
                        HOME
                    </Link>
                </ButtonSignIn>

                <span className="bottom-text">Didn’t get the email?
                    <button
                      type="button"
                      className="aqua"
                      onClick={() => resendConfirmation()}>{resentBtnText}</button>
                </span>
            </section>
        </FormEmail>
    );
}

export const FormEmail = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;

  .logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    height: 52px;
    width: auto;

    img {
      height: 52px;
      width: auto;
    }

    @media (max-width: 1360px) {
      height: 42px;
      img {
        height: 42px;
      }
    }
    @media (max-width: 1024px) {
      height: 29px;
      img {
        height: 29px;
      }
    }
  }

  .bottom-text {
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);

    .aqua {
      font-weight: 600;
      border: none;
      background: transparent;
      cursor: pointer;
      color: #20D5C4;
      transition: opacity 0.3s ease-in-out;
      padding-left: 10px;

      &:hover {
        color: rgb(165, 244, 239);

      }

      &:active,
      &:focus {
        outline: none;
      }
    }

    @media (max-width: 1360px) {
      bottom: 40px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 22px;
      width: 100%;
      text-align: center;
    }
  }

  .get-start {
    &__info {
      background-image: url(${bg});
      background-size: cover;
      text-align: center;
      padding-top: 310px;
      background-repeat: no-repeat;
      background-color: #293749;
      min-height: 100vh;

      @media (max-width: 1680px) {
        padding: 192px 95px 182px 85px;
        flex-basis: 560px;
        min-width: 560px;
      }
      @media (max-width: 1280px) {
        padding: 192px 40px 182px 40px;
      }

      @media (max-width: 1024px) {
        padding: 180px 16px 40px;
        min-width: 100%;

        .sm-block {
          display: block;
        }
      }

      .text {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #ABB0BE;

        @media (max-width: 1280px) {
          font-size: 16px;
          line-height: 20px;
        }

        @media (max-width: 1024px) {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 30px;
        }
      }
    }

    &__title {
      font-weight: 600;
      font-size: 62px;
      line-height: 72px;
      color: #FFFFFF;
      margin-bottom: 41px;

      @media (max-width: 1360px) {
        font-size: 48px;
        line-height: 60px;
        margin-bottom: 24px;
      }
      @media (max-width: 1024px) {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 20px;
      }
    }

    &__text {
      margin: 40px auto 24px;
      max-width: 625px;
      font-size: 24px;
      line-height: 30px;
      color: #ABB0BE;

      @media (max-width: 1360px) {
        font-size: 18px;
        line-height: 28px;
        margin: 24px auto 20px;

        .md-block {
          display: block;
        }

      }
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 16px;
      }

      .md-block {
        display: inline;
      }
    }

    &__link {
      color: #E8388A;
      cursor: pointer;
    }
  }
}

.aqua {
  color: #20D5C4;
}
`;

export const ButtonMagenta = styled.span`
  height: 75px;
  line-height: 71px;
  background: #E8388A;
  border: 2px solid #E8388A;
  color: #fff;
  border-radius: 40px;
  display: inline-block;
  padding: 0 67px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  transition: background-color .3s ease-in-out, border .3s ease-in-out, color .3s ease-in-out;
  margin-top: 40px;

  a {
    color: #fff;
  }


  &:focus,
  &:active,
  &:visited {
    outline: none;
  }

  &:hover {
    border: 2px solid #E8388A;
    background: #fff;
    color: #E8388A;

    a {
      color: #E8388A;
    }
  }

  @media (max-width: 1360px) {
    font-size: 14px;
    line-height: 46px;
    padding: 0 44px;
    height: 52px;
    margin-top: 24px;
  }
`;

export const ButtonSignIn = styled(ButtonMagenta)`
  width: 300px;
  display: block;
  margin: 60px auto 60px;

  @media (max-width: 1360px) {
    width: 220px;
    height: 48px;
    margin-bottom: 20px;
    margin-top: 38px;
  }
  @media (max-width: 1024px) {
    width: 124px;
    height: 48px;
    padding: 0 30px;
    font-size: 12px;
    line-height: 46px;
    margin-top: 30px;
    margin-bottom: 117px;
  }
`;

export default SignUpEmail;