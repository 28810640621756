import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { HomeHero } from "./HomeHero";
import { HomeHeroAnimated } from "./HomeHeroAnimated";
import { HomeSlider } from "./HomeSlider";
import { Process } from "./Process";
// import { Connect } from "./Connect";
import styled from "styled-components";
import { MainTitleStyled } from "../MainTitleStyled";
import { ReactComponent as Icon1 } from "../../assets/icons/connect_1.svg";
import connect1 from "../../assets/home-page/connect_1.jpg";
import { ReactComponent as Icon2 } from "../../assets/icons/conntent_2.svg";
import connect2 from "../../assets/home-page/connect_2.jpg";
import { ReactComponent as Icon3 } from "../../assets/icons/connect_3.svg";
import connect3 from "../../assets/home-page/connect_3.jpg";
import { ReactComponent as Icon4 } from "../../assets/icons/connect_4.svg";
import connect4 from "../../assets/home-page/connect_4.jpg";
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { Marketplace } from "./Marketplace";
import { GreenFill } from '../../_components/GreenFill/GreenFill';
import { MarketPlaceSlider } from './MarketplaceSlider/MarketPlaceSlider'
import PromoVideo from '../../PromoVideo';
import useTitle from '../../helpers/useTitle';
import useBlockCrawling from '../../helpers/useBlockCrawling';

export const SpeakerPage = () => {
    useBlockCrawling();
    useTitle('I’m a Speaker');

    const { width } = useWindowDimensions();
    const pathname = useHistory().location.pathname;

    const isLoaded = true;

    const processContent = [
        {
            title: 'an irresistible profile finders will love',
            dl: width > 1024
                ? [
                    {
                        dd: <span className="d-block-xs"> get the attention of finders.</span>,
                        dt: <>Easily build a market-leading profile <span className="fw-normal">that'll </span></>
                    },
                    { dd: ' to event decision makers.', dt: 'Get actively and regularly promoted' },
                    { dd: ' Start earning straight away.', dt: 'Sign up is easy and 100% free.' },
                ]
                : [
                    {
                        dd: <span className="d-block-xs">that'll get the attention of finders.</span>,
                        dt: <>Easily build a market-leading profile</>
                    },
                    { dd: ' to event decision makers.', dt: 'Get actively and regularly promoted' },
                    { dd: ' Start earning straight away.', dt: 'Sign up is easy and 100% free.' },
                ],
            greenTarget: 'Create'
        },
        {
            title: 'with Finders on events and broader collaborations',
            dl: [
                {
                    dd: width > 768
                        ? (width > 1360
                            ? <span> - Set your market rate and <span className="d-block-lg">terms you deserve from the outset. No more messy money conversations.</span></span>
                            : <span> - Set your market rate and terms <span className="d-block-lg"> you deserve from the outset. No more messy money conversations.</span></span>)
                        : <span> - Set your <span className="d-block-xs">market rate and terms you deserve from</span> the outset. No more messy money conversations.</span>,
                    dt: 'Business transparency'
                },
                { dd: ' - decide who with, when and how you want to collaborate.', dt: 'You’re now in control' },
                { dd: <span className="d-block-xs">instant alerts sent straight to your inbox.</span>, dt: 'Easily manage multiple bookings - ' },
            ],
            greenTarget: 'Engage direct'
        },
        {
            title: 'so you can get stage ready',
            dl: [
                {
                    dd: <span> - with <span className="d-block-xs">all enquiries, chats, bookings, collateral,</span> contracts, invoices now efficiently managed online</span>,
                    dt: 'Save time and focus on growth'
                },
                { dd: ' - all payments safely and securely managed for you.', dt: 'Get paid on time. Every time.' },
                { dd: <span> - Get rated and <span className="d-block-xs">publish your profile testimonials to boost</span> future conversions.</span>, dt: 'More client raves' },],
            greenTarget: ['Behind the scenes ', 'support ']
        },
    ];

    // const connectContent = [
    //     {
    //         icon: <Icon1 />,
    //         img: connect1,
    //         text: <span>Share your <span className="d-block">story and ideas</span></span>,
    //     },
    //     {
    //         icon: <Icon2 />,
    //         img: connect2,
    //         text: <span>Stay up to date <span className="d-block">on industry trends</span> </span>,
    //     },
    //     {
    //         icon: <Icon3 />,
    //         img: connect3,
    //         text: <span>Access supplier<span className="d-block">discounts and offers</span></span>,
    //     },
    //     {
    //         icon: <Icon4 />,
    //         img: connect4,
    //         text: <span>Pitch for new business<span className="d-block">(coming soon)</span></span>,
    //     },
    // ];

    const MainTitle = () => {
        const ref = useRef();
        return (
            <MainTitleStyled className="speaker-page__title">
                {
                    (width > 1024) ? isLoaded && <GreenFill animMob={true} imageStyle={{ top: '2%', left: '-4%', width: '105%', height: '96%' }} delay={500} reference={ref} speed={'0.6s'}>
                    <span
                        ref={ref}
                    >Amplify </span>
                </GreenFill>
                : <span>Amplify</span>}
                <span className={`speaker-page__title-span ${isLoaded ? 'speaker-page__title-span_active' : ''}`}> your <br />speaking business</span>
            </MainTitleStyled>
        )
    };
    //<!-- Start of LiveChat (www.livechatinc.com) code -->
    window.__lc = window.__lc || {};
    window.__lc.license = 12335247;
    (function () {
        const lc = document.createElement('script');
        lc.type = 'text/javascript';
        lc.async = true;
        lc.src = ('https:' == document.location.protocol
            ? 'https://'
            : 'http://')
            + 'cdn.livechatinc.com/tracking.js';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(lc, s);
    })();
    // <!-- End of LiveChat code -->

    return (
        <SpeakersPage>
            <main className="home">
                <HomeHeroAnimated
                    title={<MainTitle />}
                    text={
                        <div className="home-span-animated_active">
                            <div className={`desktop`}>
                                The stage for all speaking talent
                            <br />
                              Get promoted. Get booked. Get paid.
                            </div>
                            <div className={`mobile`}>
                                The direct marketing, booking
                                <br/>
                                and business management<br/>platform
                            </div>
                        </div>
                    }
                    button={'apply to join'}
                    customStyle={'speaker-page'}
                />
                <div
                    style={{
                        width: '100%',
                        height: width > 600 ? '536px' : '300px',
                        flexDirection: 'column',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        // backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: `${width > 801 ? '1400px' : '900px'}`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                    {isLoaded
                        ? (
                            <>
                                <div className="marketplace-slider-title"><span>Built for the new world <br/> of speaking and events</span></div>
                                <MarketPlaceSlider />
                            </>
                        )
                        : (
                            <></>
                        )}
                </div>
                <div className="video-section">
                  <PromoVideo />
                </div>
                <Process content={processContent} title={'Now see how it works...'} />
                <HomeSlider />
                {/* <Connect content={connectContent} /> */}
            </main>
            {
                pathname === '/'
                && (<>
                    <noscript>
                        <a href="https://www.livechatinc.com/chat-with/12335247/" rel="nofollow">Chat with us</a>,
                            powered by <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow"
                            target="_blank">LiveChat</a>
                    </noscript>
                </>
                )
            }
        </SpeakersPage >
    )
}


export const SpeakersPage = styled.div`
    .desktop {
        display: true;

        @media (max-width: 1024px) {
            display: none;
        }
    }

    .mobile {
        display: none;

        @media (max-width: 1024px) {
            display: block;
        }
    }

    .heading {
        font-weight: 500;
    }
    .subheading-copy {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    .speaker-page__title {
        font-weight: 500;

        @media (max-width: 1024px) {
            font-size: 40px;
            font-weight: 600;
            line-heinght: 40px;
        }
    }

    .speaker-page__title-span {
        font-weight: 500;
        
        @media (max-width: 1024px) {
            font-size: 40px;
            font-weight: 600;
            line-heinght: 40px;
        }
    }

    .animated-string {
        overflow: hidden;
    }

    .hero__text {
        margin: auto;

      @media (max-width: 768px) {
        max-width: 360px;
      }
    }

   .new-line {
        display: block;
   }

   .d-block-lg {
        display: block;
        @media (max-width: 768px) {
            display: inline;
        }
   }

   .d-block-xs {
      @media (max-width: 768px) {
        display: block;
      }
   }

   .home__btn {
        height: 45px;
        line-height: 42px;
        background: #E8388A;
        border: 2px solid #E8388A;
        color: #fff;
        border-radius: 40px;
        display: inline-block;
        padding: 0 24px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        transition: background-color .3s ease-in-out, border .3s ease-in-out, color .3s ease-in-out ;

        &:focus,
        &:active,
        &:visited {
            outline: none;
        }

        &:hover {
          border: 2px solid #E8388A;
          background: #fff;
          color: #E8388A;
        }
    }

    .marketplace-slider-title {
        width: 653px;
        font-weight: 600;
        font-size: 44px;
        line-height: 50px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #19222D;
        margin-top: 70px;
        margin-bottom: 22px;

        @media (max-width: 601px) {
            font-size: 30px;
            line-height: 34px;
            margin-top: 30px;
            margin-bottom: -20px;
        }
    }

   .container {
        max - width: 1160px;
        margin: 0 auto;
        font-family: Poppins, sans-serif;
        font-weight: normal;
    }

   .home {
        font - family: Poppins, sans-serif;
        font-weight: normal;
        .container {
            width: 1310px;

            @media (max-width: 1024px) {
                width: 100%;
            }
       }

       .text {
            font-size: 24px;
           line-height: 30px;
           color: var(--text-semidark);
           margin-bottom: 0;

           @media (max-width: 1280px) {
                font-size: 18px;
               line-height: 28px;
           }
       }
   }

   .subtitle {
        font-weight: 600;
       font-size: 62px;
       line-height: 72px;

       &--dark {
            color: #293749;
       }

       &--light {
            color: #fff;
       }

        @media (max-width: 1280px) {
            font-size: 40px;
            line-height: 40px;
        }
   }

   .aqua {
        color: var(--grass);
   }

   .sm-hidden {
        @media (max-width: 1024px) {
            display: none;
        }
   }

   .home-span-animated {
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 32px;
        white-space: nowrap;
        text-align: center;

       &_active {
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            transition: 1s;
       }

       @media (max-width: 601px) {
            font-size: 20px;
            line-height: 28px;

           &_active {
                font-size: 18px;
                line-height: 24px;
           }
       }
   }

   .video-section {
      max-width: 1105px;
      margin: 0 auto;
      
      @media (max-width: 1000px) {
        margin: 50px auto;
      }
   }
`;