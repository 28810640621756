import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import BaseInput from '../../../BaseInput';
import styles from './styles.module.scss';

const options = [
  {
    text: '100% payment upfront',
    value: 100
  },
  {
    text: '75/25 instalment',
    value: 75
  },
  {
    text: '50/50 instalment',
    value: 50
  },
  {
    text: '25/75 instalment',
    value: 25
  }
];

const Placeholder = () => (
  <span className={styles.placeholder}>Select payment terms</span>
);

export default ({ value, onChange, error, disabled }) => {

  return (
    <BaseInput
      select
      value={value}
      onChange={onChange}
      disabled={disabled}
      label={value ? null : <Placeholder />}
      error={error}
      SelectProps={{
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            horizontal: 'center'
          }
        }
      }}
    >
      {
        options.map(({text, value}) => (
          <MenuItem
            className={styles.menuItemContainer}
            value={value}
            classes={{
              root: styles.menuItem
            }}
          >
            {text}
          </MenuItem>
        ))
      }
    </BaseInput>
  );
};