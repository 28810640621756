import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export default ({ theme, children }) => {
  return <div className={classNames(styles.container, { [styles.dark]: theme === 'dark' })}>
    <div className={styles.content}>
      {children.map(elem => <div className={styles.item}>
        {elem}
      </div>)}
    </div>
  </div>;
};
