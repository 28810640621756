class ChatProvider {
  getClientAsync() {
    if (this._loadingPromise) {
      return this._loadingPromise;
    }

    return this._loadingPromise = import('sendbird').then(Sendbird => {
      this.client = new Sendbird.default({ appId: process.env.REACT_APP_SENDBIRD_APP_ID });
      return this.client;
    });
  }

  init(userId, accessToken) {
    return this.getClientAsync().then(client => {
      const connectionState = this.client.getConnectionState();
      if (connectionState === 'CLOSED') {
        this.connection = new Promise((resolve, reject) => {
          this.client.connect(userId, accessToken).then(user => {
            resolve(this);
          }).catch(e => {
            reject(e);
          });
        });

        return this.connection;
      }

      if (connectionState === 'CONNECTING') {
        return this.connection;
      }

      return Promise.resolve(this);
    });
  }

  async drop() {
    if (this.client.getConnectionState() === 'OPEN') {
      await this.client.disconnect();
    }
  }

  getTotalUnreadCount() {
    return this.client.getTotalUnreadMessageCount([]);
  }

  getChatListProvider(search) {
    const listQuery = this.client.GroupChannel.createMyGroupChannelListQuery();
    listQuery.order = 'latest_last_message';
    listQuery.includeEmpty = true;
    listQuery.limit = 10;
    if (search) {
      listQuery.setSearchFilter(['member_nickname', 'channel_name'], search);
    }


    return {
      hasNext: () => listQuery.hasNext,
      fetch: () => {
        if (listQuery.hasNext) {
          return listQuery.next();
        } else {
          return [];
        }
      }
    };
  }

  async getChannel(idOrChannel) {
    return await this.client.GroupChannel.getChannel(idOrChannel);
  }

  async getMessagesProvider(idOrChannel) {
    let channel = idOrChannel;

    if (typeof idOrChannel === 'string') {
      channel = await this.getChannel(idOrChannel);
    }

    let channelData;

    try {
      channelData = JSON.parse(channel.data);
    } catch (e) {}

    const listQuery = channel.createPreviousMessageListQuery();
    listQuery.limit = 10;

    return {
      load: async () => {
        const result = await listQuery.load();

        return {
          items: result,
          isFinished: result.length < 10
        };
      },
      send: payload => {
        const params = new this.client.UserMessageParams();
        params.message = payload.message || ' ';
        params.data = JSON.stringify({ files: payload.files });

        return new Promise((resolve, reject) => {
          channel.sendUserMessage(params, (msg, err) => {
            if (err) {
              reject(err);
            } else {
              resolve(msg);
            }
          });
        });
      },
      read: () => {
        return channel.markAsRead();
      },
      channelName: channel.name,
      channelUrl: channel.url,
      channelData,
      members: channel.members
    };
  }

  addListeners(receiveHandler) {
    const channelHandler = new this.client.ChannelHandler();
    channelHandler.onMessageReceived = function(channel, message) {
      receiveHandler(channel, message);
    };

    const id = Math.random() + '';

    this.client.addChannelHandler(id, channelHandler);

    return () => {
      this.client.removeChannelHandler(id);
    };
  }

  getUnreadMessages(channelUrls) {
    const listQuery = this.client.GroupChannel.createMyGroupChannelListQuery();
    listQuery.channelUrlsFilter = channelUrls;

    return listQuery.next();
  }
}

export default new ChatProvider();
