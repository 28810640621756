export default class {
  constructor(mapping) {
    this._mapping = mapping;
  }

  /*
  { fieldName: 1, ... } -> { field_name: 1 }
   */
  pack(obj) {
    if (!obj) {
      throw new Error('Parameter is required for pack()');
    }

    return Object.entries(this._mapping)
    .map(val => [val[1], obj[val[0]]])
    .reduce((acc, val) => ({ ...acc, [val[0]]: val[1]}), {});
  }

  /*
  { field_name: 1, ... } -> { fieldName: 1 }
   */
  unpack(obj) {
    if (!obj) {
      throw new Error('Parameter is required for unpack()');
    }


    return Object.entries(this._mapping)
    .map(val => [val[0], obj[val[1]]])
    .reduce((acc, val) => ({ ...acc, [val[0]]: val[1]}), {});
  }
};