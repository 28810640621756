import React from 'react';
import * as R from "ramda";

export const Expertise = ({ credentials, parent }) => (
    <div className="content">
        <div className="custom-container">
            <h3 className={`custom-container__label ${parent}`}>Expertise</h3>

            <div className="notop">
                <p className="sub__sublabel">Topics</p>
                <ul className="sub__topic">
                    {credentials['expertise'] && credentials['expertise']['speaker_topic'].map(
                        (value, index) => {
                            return (
                                <li
                                    className="sub__topic-elem"
                                    key={index}
                                >
                                    {parent === 'speakerProfile' ? value : value.name}
                                </li>
                            );
                        }
                    )
                    }
                </ul>
                <p className="sub__topic-desc">
                    {!R.isNil(credentials.expertise) && credentials.expertise.additional_speaker_topic_details}
                </p>
            </div>

            <p className="sub__sublabel">Type</p>
            <ul className="sub__type">
                {!R.isNil(credentials['expertise'])
                    ? credentials['expertise']['speaker_type'].map(
                        (value, index) => {
                            return (
                                <li
                                    className="sub__topic-elem"
                                    key={index}
                                >
                                    {parent === 'speakerProfile' ? value : value.name}
                                </li>
                            );
                        }
                    )
                    : []
                }
            </ul>
            <p className="sub__sublabel">Format</p>
            <ul className="sub__topic format">
                {!R.isNil(credentials['expertise'])
                    ? credentials['expertise']['speaker_event_types'].sort((a, b) => a.id - b.id)
                        .map(
                            (item, index) => {
                                return (
                                    <li
                                        className={` sub__topic-elem ${(parent === 'speakerProfile' ? item : item.name === 'In-person'
                                            || parent === 'speakerProfile' ? item : item.name === 'Virtual'
                                                || parent === 'speakerProfile' ? item : item.name === 'Live'
                                                    || parent === 'speakerProfile' ? item : item.name === 'Hybrid') && parent !== 'speakerProfile' && 'aqua'
                                            }`}
                                        key={index}
                                    >
                                        <span
                                            className="sub__topic-elem-link"
                                        >
                                            {parent === 'speakerProfile' ? item : item.name}
                                        </span>
                                    </li>
                                );
                            }
                        )
                    : []
                }
            </ul>
        </div>
    </div>
)
