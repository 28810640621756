import React, { useState, useEffect, useRef } from 'react';
import styles from '../BookingForm.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import {ReactComponent as Mark} from '../../../assets/question_mark.svg';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ApiDataProvider from '../../../api/apiClient';
import BookingFormContract from '../BookingFormContract/BookingFormContract';
import CalculationErrorModal from '../components/CalculationErrorModal';
import Checkbox from '../components/Checkbox';
import DatePicker from '../components/DatePicker';
import Dropdown from '../components/Dropdown';
import MoneyFormat from '../components/MoneyInput';
import BookingFormSpeakerIncrementals
  from "../BookingFormContainer/BookingFormSpeakerIncrementals/BookingFormSpeakerIncrementals";
import ConfirmationUserModal from '../../modals/ConfirmationUserModal';
import BookingFormOfferSpeakerList from './BookingFormOfferSpeaker/BookingFormOfferSpeakerList';
import BookingFormInstalmentsFirst
  from '../BookingFormInstalments/BookingFormInstalmentsFirst';
import BookingFormInstalmentsSecond
  from '../BookingFormInstalments/BookingFormInstalmentsSecond';

const LOREM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit,` +
  ` sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`;

const THROTTLE_TIME = 1000;

const paidTooltip = LOREM;


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#02B5AA'
  },
  paidLabel: {
    paddingTop: '10px',
    color: '#E8388A',
    fontWeight: 500
  },
  accord: {
    color: 'green'
  },
  accordions: {
    marginBottom: '5px',
    boxShadow: 'none'
  }
}));

const getFormControls = (role, status) => {
  let text;
  let buttons;

  if (role === 'speaker') {
    if (!status) {
      buttons = ['create-offer', 'create-draft'];
    } else if (status === 'SENT') {
      buttons = ['delete', 'edit-offer'];
      text = 'You booking offer has been sent and is waiting to be accepted by the client';
    } else if (status === 'DRAFT') {
      buttons = ['send-offer', 'save-draft', 'delete'];
    } else if (status === 'CANCELED') {
      text = 'Booking is canceled';
    } else if (status === 'DECLINED') {
      buttons = ['resubmit-offer', 'delete'];
      text = 'Please edit and send booking';
    } else if (status === 'ACCEPTED') {
      text = 'Accepted';
    } else if (status === 'PAID_PARTIALLY') {
      // text = 'First payment made';
    } else if (status === 'PAID_FULLY') {
      text = 'Paid fully';
    } else if (status === 'DONE') {
      text = 'Finished';
    } else if (status === 'REFUNDED') {
      text = 'Refunded';
    }
  } else if (role === 'business') {
    if (status === 'SENT') {
      buttons = ['decline', 'accept'];
    } else if (status === 'CANCELED') {
      text = 'The booking has been cancelled';
    } else if (status === 'DECLINED') {
      text = 'Booking is sent back to speaker';
    } else if (status === 'ACCEPTED') {
      text = 'Accepted';
      buttons = ['book'];
    } else if (status === 'PAID_PARTIALLY') {
      // text = 'First payment made';
    } else if (status === 'PAID_FULLY') {
      text = 'Paid fully';
    } else if (status === 'DONE') {
      text = 'Finished';
    } else if (status === 'REFUNDED') {
      text = 'Refunded';
    }
  }

  return {
    text,
    buttons
  };
};

const confirmationModalText = {
  'delete': {
    text: 'Are you sure you want to delete the offer?',
    subtext: () => 'When you delete the offer it will be permanently removed'
  },

  accept: {
    text: 'Accept booking?'
  },

  decline: {
    text: 'Are you sure you want to decline this offer?',
    subtext: () => 'If you wish to change or decline this offer the speaker will be notified. We recommend discussing any changes directly with the speaker in the chat function.'
  },

  cancel: {
    text: 'Are you sure you want to cancel the booking?',
    subtext: role => 'If you wish to cancel this engagement the ' +
      `${role} will be notified by the chat function. Any refunds or rescheduled dates must` +
      ' be managed direct between both parties. Once you press cancel this booking will be moved to the completed bookings tab'
  }
};

export default function BookingFormOffer(
  {
    view,
    speakerFee,
    changeSpeakerFee,
    speakerFeeInvalid,
    breakdown,
    gst,
    taxVisible,
    incrementals,
    addIncremental,
    deleteIncremental,
    agreement,
    changeAgreement,
    agreementInvalid,
    contract,
    changeContract,
    contractInvalid,
    secondPaymentDate,
    setSecondPaymentDate,
    secondPaymentDateInvalid,
    setSecondPaymentDateInvalid,
    paymentPercentage,
    setPaymentPercentage,
    paymentPercentageError,
    onCreateBooking,
    onCancelEnquiry,
    onDeleteBooking,
    onEditBooking,
    onSendBooking,
    onDeclineBooking,
    onAcceptBooking,
    onCreateBookingDraft,
    onSaveBookingDraft,
    onSimulateBooking,
    onBack,
    bookingStatus,
    standardContractPath,
    isSending
  }) {
  const classes = useStyles();
  const [inputEnabled, setInputEnabled] = useState(true);
  const [submitEnabled, setSubmitEnabled] = useState(true);

  const timeoutRef = useRef();
  const calculationEnabledRef = useRef();

  const [calcErrorModalOpen, setCalcErrorModalOpen] = useState(false);
  const [calcErrorMessage, setCalcErrorMessage] = useState('');

  const [confirmationModalAction, setConfirmationModalAction] = useState('');

  const [gstVat, setGstVat] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  const [netSpeakerFee, setNetSpeakerFee] = useState(0);
  const [total, setTotal] = useState(0);

  const [instalment1SpeakerFee, setInstalment1SpeakerFee] = useState(0);
  const [instalment1PlatformFee, setInstalment1PlatformFee] = useState(0);
  const [instalment1GstVat, setInstalment1GstVat] = useState(0);
  const [instalment1Incrementals, setInstalment1Incrementals] = useState(0);
  const [instalment1Total, setInstalment1Total] = useState(0);

  const [instalment2SpeakerFee, setInstalment2SpeakerFee] = useState(0);
  const [instalment2GstVat, setInstalment2GstVat] = useState(0);
  const [instalment2Incrementals, setInstalment2Incrementals] = useState(0);
  const [instalment2Total, setInstalment2Total] = useState(0);

  const [isSecondInstalmentVisible, setIsSecondInstalmentVisible] = useState(true);

  const isFormFrozen = view !== 'speaker' || bookingStatus && bookingStatus !== 'DECLINED' && bookingStatus !== 'DRAFT';

  const formControls =  getFormControls(view, bookingStatus);

  const agreementCheckboxHandler = e => {
    const checkbox = e.target;
    if (checkbox.checked) {
      changeAgreement(checkbox.name);
    } else {
      changeAgreement(null);
    }
  };

  const calculate = () => {
    // block any input
    setInputEnabled(false);

    return ApiDataProvider.calculateBookingBill({
      speakerFee,
      incrementals,
      paymentPercentage,
      gstVat: gst,
      }).then(response => {
      const { data } = response;

      // set bill data

      setGstVat(data.netSpeakerFee.items.gstVat);
      setPlatformFee(data.netSpeakerFee.items.platformFee);
      setNetSpeakerFee(data.netSpeakerFee.amount);
      setTotal(data.total.amount);

      setInstalment1GstVat(data.firstInstalment.items.gstVat);
      setInstalment1SpeakerFee(data.firstInstalment.items.speakerFeeToClient);
      setInstalment1PlatformFee(data.firstInstalment.items.platformFee);
      setInstalment1Incrementals(data.firstInstalment.items.incrementals);
      setInstalment1Total(data.firstInstalment.amount);

      setInstalment2Total(data.secondInstalment.amount);

      if (data.secondInstalment.amount) {
        setIsSecondInstalmentVisible(true);
        setInstalment2GstVat(data.secondInstalment.items.gstVat);
        setInstalment2SpeakerFee(data.secondInstalment.items.speakerFeeToClient);
        setInstalment2Incrementals(data.secondInstalment.items.incrementals);
      } else {
        setIsSecondInstalmentVisible(false);
      }

      setCalcErrorMessage('');
      return true;
    }).catch(e => {
      setCalcErrorMessage('Error while calculating the bill');
    }).finally(() => {
      setInputEnabled(true);
    });
  };

  useEffect(() => {
    if (breakdown) {
      setGstVat(breakdown.gstVat);
      setPlatformFee(breakdown.platformFee);
      setNetSpeakerFee(breakdown.netSpeakerFee);
      setTotal(breakdown.total);

      setInstalment1GstVat(breakdown.firstInstalment.gstVat);
      setInstalment1SpeakerFee(breakdown.firstInstalment.speakerFeeToClient);
      setInstalment1PlatformFee(breakdown.firstInstalment.platformFee);
      setInstalment1Total(breakdown.firstInstalment.total);

      if (breakdown.firstInstalment.incrementals) {
        setInstalment1Incrementals(breakdown.firstInstalment.incrementals);
      }

      if (breakdown.secondInstalment) {
        setIsSecondInstalmentVisible(true);
        setInstalment2GstVat(breakdown.secondInstalment.gstVat);
        setInstalment2SpeakerFee(breakdown.secondInstalment.speakerFeeToClient);
        setInstalment2Incrementals(breakdown.secondInstalment.incrementals);
        setInstalment2Total(breakdown.secondInstalment.total);
      } else if (breakdown.total > 0) {
        setIsSecondInstalmentVisible(false);
      }
    }
  }, []);

  useEffect(() => {
    // calculate breakdown when input value is changed by user
    if (!calculationEnabledRef.current) {
      return;
    }

    clearTimeout(timeoutRef.current);

    if (typeof speakerFee !== 'number' && !speakerFee) {
      setSubmitEnabled(true);
      return;
    }

    setSubmitEnabled(false);

    timeoutRef.current = setTimeout(() => {
      calculate().then(success => {
        if (success) {
          setSubmitEnabled(true);
        } else {
          setCalcErrorModalOpen(true);
        }
      });

    }, THROTTLE_TIME);
  }, [speakerFee]);

  useEffect(() => {
    // calculate breakdown when input value is changed by user
    if (!calculationEnabledRef.current) {
      return;
    }

    if (typeof speakerFee !== 'number' && !speakerFee) {
      return;
    }

    setSubmitEnabled(false);

    calculate().then(success => {
      if (success) {
        setSubmitEnabled(true);
      } else {
        setCalcErrorModalOpen(true);
      }
    });
  }, [incrementals, paymentPercentage]);

  const changeSpeakerFeeHandler = value => {
    if (!calculationEnabledRef.current) {
      calculationEnabledRef.current = true;
    }

    changeSpeakerFee(value);
  };

  const addIncrelemtalHandler = (description, amount) => {
    if (!calculationEnabledRef.current) {
      calculationEnabledRef.current = true;
    }

    addIncremental(description, amount);
  };

  const deleteIncrementalHandler = idx => {
    if (!calculationEnabledRef.current) {
      calculationEnabledRef.current = true;
    }

    deleteIncremental(idx);
  };

  const changePercentageHandler = e => {
    if (!calculationEnabledRef.current) {
      calculationEnabledRef.current = true;
    }

    setPaymentPercentage(e.target.value);
  };

  const onTryAgainHandler = () => {
    return calculate().then(success => {
      if (success) {
        setCalcErrorModalOpen(false);
        setSubmitEnabled(true);
      }
    });
  };

  const cancelBookingHandler = () => {
    setConfirmationModalAction('cancel');
  };

  const deleteBookingHandler = () => {
    setConfirmationModalAction('delete');
  };

  const declineBookingHandler = () => {
    setConfirmationModalAction('decline');
  };

  const acceptBookingHandler = () => {
    setConfirmationModalAction('accept');
  };

  const confirmationModalHandler = () => {
    switch (confirmationModalAction) {
      case 'cancel':
        return onCancelEnquiry().finally(() => {
          setConfirmationModalAction('');
        });
      case 'delete':
        return onDeleteBooking().finally(() => {
          setConfirmationModalAction('');
        });
      case 'decline':
        return onDeclineBooking().finally(() => {
          setConfirmationModalAction('');
        });
      case 'accept':
        return onAcceptBooking().finally(() => {
          setConfirmationModalAction('');
        });
      default:
    }
  };

  return (
    <div className={styles.your_offer}>
      <div className={styles.container_form}>
        <div className={styles.your_offer_headers}>
          <h3>Your offer</h3>
          <h4>Amount</h4>
        </div>
        <div>
          <div className={styles.offer_list_roll}>
            <BookingFormOfferSpeakerList
              view={view}
              speakerFee={speakerFee}
              changeSpeakerFee={changeSpeakerFeeHandler}
              speakerFeeInvalid={speakerFeeInvalid}
              taxVisible={taxVisible}
              gstVat={gstVat}
              disabledSpeakerFee={!inputEnabled || isFormFrozen}
              platformFee={Math.abs(platformFee)}
              netSpeakerFee={netSpeakerFee}
            />
          </div>
        </div>

        {(view === 'speaker' || incrementals.length > 0) && (
          <div className={styles.incrementalsContainer}>
            <BookingFormSpeakerIncrementals
              data={incrementals}
              onAdd={addIncrelemtalHandler}
              onDelete={deleteIncrementalHandler}
              disabled={isFormFrozen}
            />
          </div>
        )}

        {
          !isNaN(platformFee) && (
            <div className={styles.asteriskNote}>*Platform fee inc GST</div>
          )
        }

        <div className={styles.offer_list_roll_receive}>
          <div className={styles.offer_list_roll_receive_textArea}>
            <div>
              <span style={{
                color: '#FFFFFF',
                fontSize: '16px',
                fontWeight: '600'
              }}
              >
                {
                  view === 'speaker'
                    ?
                    <span>You will receive</span>
                    :
                    <span>You will pay</span>
                }
              </span>
              <span
                className={styles.aud}
                style={{
                  color: '#FFFFFF',
                  fontSize: '16px',
                }}
              >
                (AUD)
              </span>
            </div>
            <div>
              <span
                style={{
                  color: '#FFFFFF',
                  fontSize: '22px'
                }}
                className={styles.offer_list_roll_sum}
              >
                ${(total).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.offer_list_roll_paid}>

          { view === 'speaker' && (<>
            <div style={{display: 'flex'}}>
            <span
              className={styles.offer_list_roll_elems_head}
              style={{paddingRight: '3px'}}
            >When you are paid</span>

              <Tooltip title={paidTooltip}>
                <Mark className={styles.markIcon} />
              </Tooltip>
            </div>

            <div className={styles.instalmentPercentageContainer}>
              <Dropdown
                value={paymentPercentage}
                onChange={changePercentageHandler}
                error={paymentPercentageError}
                disabled={isFormFrozen}
              />
            </div>
          </>)}

          <div className={styles.offer_list_roll_accords}>
            <Accordion
              className={classes.accordions}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              ><div>
                <Typography className={classes.heading}>
                  Instalment 1 - paid at time of booking (AUD)
                </Typography>
                {(bookingStatus === 'PAID_PARTIALLY' || bookingStatus === 'PAID_FULLY') && (
                  <div className={classes.paidLabel}>Payment complete</div>
                )}
              </div>
              </AccordionSummary>
              <AccordionDetails>
                <BookingFormInstalmentsFirst
                  speakerFee={instalment1SpeakerFee}
                  platformFee={instalment1PlatformFee}
                  taxVisible={taxVisible}
                  gstVat={instalment1GstVat}
                  incrementals={instalment1Incrementals}
                  total={instalment1Total}
                  role={view}
                />
              </AccordionDetails>
            </Accordion>

            { isSecondInstalmentVisible && (
              <Accordion
                className={classes.accordions}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div>
                    <div className={styles.secondInstalmentHeading}>
                      <Typography className={classes.heading}>
                        Instalment 2 - paid on selected/agreed date
                      </Typography>
                      <DatePicker
                        value={secondPaymentDate}
                        onChange={setSecondPaymentDate}
                        error={secondPaymentDateInvalid}
                        onError={setSecondPaymentDateInvalid}
                        disabled={isFormFrozen}
                      />
                    </div>
                    {bookingStatus === 'PAID_FULLY' && (
                      <div className={classes.paidLabel}>Payment complete</div>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <BookingFormInstalmentsSecond
                    speakerFee={instalment2SpeakerFee}
                    incrementals={instalment2Incrementals}
                    taxVisible={taxVisible}
                    gstVat={instalment2GstVat}
                    total={instalment2Total}
                    role={view}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
        <div className={styles.agreement}>
          <div>
            <div className={styles.offer_list_roll_title}>
              <span>Confirm booking agreement</span>
            </div>
            {
              view === 'speaker'
              ?
                <div className={styles.offer_list_roll_checkboxes}>
                  <div>
                    <div>
                      <div className={styles.offer_list_roll_checkboxes_elements}>
                        <Checkbox
                          name="standard"
                          type="checkbox"
                          checked={agreement === 'standard'}
                          onChange={agreementCheckboxHandler}
                          error={agreementInvalid}
                          disabled={isFormFrozen}
                        />
                        <label
                          className={styles.offer_list_roll_checkboxes_label}
                          htmlFor="first"
                        >
                          Use
                          <a style={{ color: 'unset', textDecoration: 'underline', padding: '0 5px' }}
                            target="_blank"
                            href={`${process.env.REACT_APP_DO_SPACES_URL}/${standardContractPath}`}
                          >
                            Finders Speakers Standard Booking Agreement
                          </a>
                          between you and your client.
                        </label>
                      </div>
                      <div style={{
                        paddingBottom: '5px',
                        fontWeight: '600',
                        fontSize: '13px',
                        lineHeight: '19px',
                        color: '#000000'
                      }}>
                    <span style={{
                      cursor: 'default'
                    }}>Or</span>
                      </div>
                      <div
                        className={styles.offer_list_roll_checkboxes_elements}
                        style={{paddingBottom: '10px'}}
                      >
                        <Checkbox
                          name="custom"
                          type="checkbox"
                          checked={agreement === 'custom'}
                          onChange={agreementCheckboxHandler}
                          error={agreementInvalid}
                          disabled={isFormFrozen}
                        />
                        <label
                          className={styles.offer_list_roll_checkboxes_label}
                          htmlFor="second"
                        >
                          Upload your own contract between you and your client
                        </label>
                      </div>
                    </div>
                    {
                      agreement === 'custom' && <div className={styles.contractWrapper}>
                        <BookingFormContract
                          file={contract}
                          onChange={value => changeContract(value)}
                          error={contractInvalid ? 'Please upload your contract' : undefined}
                          disabled={isFormFrozen}
                        />
                      </div>
                    }
                  </div>
                  <div className={styles.checkboxError}>{agreementInvalid && 'Required field'}</div>
                </div>
                :
                <div className={styles.readyDocumentArea}>
                  {
                    contract ? (
                      <a
                        className={styles.readyDocument}
                        href={`${process.env.REACT_APP_DO_SPACES_URL}/${contract.path}`}
                        target="_blank"
                      >
                        {contract.name}
                      </a>
                    ) : (
                      <a
                        className={styles.readyDocument}
                        href={`${process.env.REACT_APP_DO_SPACES_URL}/${standardContractPath}`}
                        target="_blank"
                      >
                        Feature link agreement here
                      </a>
                    )
                  }

                </div>
            }
            {
              formControls.text && (
                <div className={styles.bookingFrozenMessage}>
                  {formControls.text}
                </div>
              )
            }
            {
              formControls.buttons && (
                <div className={styles.buttonsGroup}>
                  {
                    formControls.buttons.map(buttonType => {
                      switch (buttonType) {
                        case 'back':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColoredGrey}
                              onClick={onBack}
                            >
                              Back
                            </button>
                          );
                          case 'cancel':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColoredGrey}
                              onClick={cancelBookingHandler}
                            >
                              cancel booking
                            </button>
                          );
                        case 'delete':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColoredGrey}
                              onClick={deleteBookingHandler}
                            >
                              Delete offer
                            </button>
                          );
                        case 'create-offer':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled || isSending}
                              onClick={onCreateBooking}
                            >
                              Send to client
                            </button>
                          );
                        case 'send-offer':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled || isSending}
                              onClick={onSendBooking}
                            >
                              Send to client
                            </button>
                          );
                        case 'resubmit-offer':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled || isSending}
                              onClick={onSendBooking}
                            >
                              Re-Submit
                            </button>
                          );
                        case 'create-draft':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled || isSending}
                              onClick={onCreateBookingDraft}
                            >
                              Save & Exit
                            </button>
                          );
                        case 'save-draft':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled || isSending}
                              onClick={onSaveBookingDraft}
                            >
                              Save & Exit
                            </button>
                          );
                        case 'accept':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled}
                              onClick={acceptBookingHandler}
                            >
                              Accept Offer
                            </button>
                          );
                        case 'decline':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled}
                              onClick={declineBookingHandler}
                            >
                              Decline Offer
                            </button>
                          );
                        case 'edit-offer':
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled || isSending}
                              onClick={onEditBooking}
                            >
                              edit
                            </button>
                          );
                        case 'book': // test only
                          return (
                            <button
                              key={buttonType}
                              className={styles.buttonColored}
                              disabled={!submitEnabled}
                              onClick={onSimulateBooking}
                            >
                              mock payment (test)
                            </button>
                          );
                      }
                    })
                  }
                </div>
              )
            }
            <div className={styles.errorMessage}>
              {
                calcErrorMessage && calcErrorMessage + ' Try again later'
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles.zigzag} />

      <CalculationErrorModal
        isOpen={calcErrorModalOpen}
        text={'Oops!'}
        errorMessage={calcErrorMessage}
        onClose={() => setCalcErrorModalOpen(false)}
        onTryAgain={onTryAgainHandler}
      />

      <ConfirmationUserModal
        isOpen={confirmationModalAction}
        text={confirmationModalAction && confirmationModalText[confirmationModalAction].text}
        subtext={
          confirmationModalAction && confirmationModalText[confirmationModalAction].subtext &&
          confirmationModalText[confirmationModalAction].subtext(view === 'speaker' ? 'finder' : 'speaker')
        }
        onCancel={() => setConfirmationModalAction('')}
        onConfirm={confirmationModalHandler}
      />
    </div>
  );
}