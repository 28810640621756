import React, { useState } from 'react';
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import getFileUrl from '../../helpers/getFileUrl';
import { GreenLineOnHover } from "../../HomePage/GreenLineOnHover";
import './formats.scss';
import addWhitespaces from '../addWhitespaces';

const defaultData = [
    {
        title: <>Panel/ <br />
            Roundtable</>,
        img: require('../../assets/s-home_formats_conference.jpg'),
        isHover: true,
        order: '1',
    },
    {
        title: 'Conference / Seminar',
        img: require('../../assets/s-home_format_masterclass.jpg'),
        isHover: true,
        order: '3',
    },
    {
        title: <>Radio/ <br />
            Podcast/TV</>,
        img: require('../../assets/s-home_formats_podcast.jpg'),
        isHover: true,
        order: '5',
    },
    {
        title: <>Training/ <br />
            Workshop</>,
        img: require('../../assets/s-home_formats_seminar.jpg'),
        isHover: true,
        order: '2',
    },
    {
        title: 'Webinar / Livestream',
        img: require('../../assets/s-home_formats_webinar.jpg'),
        isHover: true,
        order: '4',
    },
    {
        title: <>After dinner/ <br />
            Gala event</>,
        img: require('../../assets/s-home_formats_meeting.jpg'),
        isHover: true,
        order: '6',
    },
];

const SingleFormat = ({ format, isHover, onSelect }) => {
    const [isCover, setIsCover] = useState(true);

    return (
        <div
            className="s-home__format"
            onMouseOver={() => setIsCover(false)}
            onMouseLeave={() => setIsCover(true)}
            onClick={() => onSelect(format.id)}
        >
            <div
                className="s-home__formats-item"
                style={
                    {
                        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%), url(${format.img}) no-repeat center`,
                        backgroundSize: 'cover',
                        filter: isCover ? 'grayscale(80%)' : '',
                    }
                }>
                {isCover && <div className="s-home__formats-cover"></div>}
                <h4 className="s-home__formats-name">
                    {isHover
                        ? (
                            <GreenLineOnHover
                                target={format.title}
                                bgImg={require('../../assets/trending-tags_underline.png')}
                                moveBottom={'-15px'}
                            />
                        )
                        : <>{format.title}</>
                    }
                </h4>
            </div>
        </div>
    )
}

export const Formats = ({ data, onSelectEventType, winSize }) => {
    const { width } = useWindowDimensions();

    const formats = addWhitespaces(data).map((item, i) => ({
        title: item.name,
        img: getFileUrl(item.image),
        order: i + 1,
        id: item.id
    }));

    const formatsItems = formats.map((f) =>
      <SingleFormat
        key={f.order}
        format={f}
        winSize={winSize}
        isHover={f.isHover}
        onSelect={onSelectEventType}
        id={f.id}
      />
    );

    return (
        <section className={'s-home__formats s-home__container'}>
            <div
                style={{ width: '100%' }}
            >
                <h2 className="s-home__formats-title">
                    Choose by format... Live,Virtual or Hybrid?
                </h2>
            </div>

            <div className="s-home__formats-wrapper">
                {
                    winSize > 768
                        ? formatsItems.map((f, k) => (
                            <div key={k}>
                                {f}
                            </div>
                        ))
                        : (
                            <div className="s-home__formats-container">
                                {formats.map(elem => {
                                    return (
                                      <div
                                        className="s-home__formats-elem" key={elem.id}
                                        style={{
                                            position: 'relative',
                                            order: width < 600 ? elem.key : ''
                                        }}
                                        onClick={() => onSelectEventType(elem.id)}
                                      >
                                        <div
                                          style={{
                                              position: 'absolute',
                                              top: 0,
                                              bottom: 0,
                                              left: 0,
                                              right: 0,
                                              backgroundImage: `url(${elem.img})`,
                                              backgroundSize: 'cover',
                                              filter: 'brightness(0.4) grayscale(80%)'
                                          }}
                                        />
                                        <div className="s-home__formats-name">
                                          {elem.title}
                                        </div>
                                      </div>
                                    );
                                })}
                            </div>
                        )
                }
            </div>
        </section>
    )
}