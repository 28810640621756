import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import LoadingBar from 'react-top-loading-bar'
import { Spinner } from 'react-bootstrap'
import Enquiries from './Enquiries'
import Booking from './Booking'
import Complete from './Complete'
import ArrowDropDown from '@material-ui/icons/ExpandMore';
import ArrowDropUp from '@material-ui/icons/ExpandLess';
import Search from '@material-ui/icons/Search';
import Load from '../assets/reload.svg';

import { fetchDataGet } from '../api/helperFunctions';

const Dashboard = props => {
    const user = useSelector(state => state.user);

    const [data, setData] = useState([])
    const [state, setState] = useState({})

    const [page, setPage] = useState('Enquiries') //Enquiries
    const [sort, setSort] = useState('asc')
    const [search, setSearch] = useState('')
    const [sortVis, setSortVis] = useState(false)
    const [status, setStatus] = useState(true)
    const [statusVis, setStatusVis] = useState(false)
    const [visible, setVisible] = useState(1)
    const [visLoader, setVisLoader] = useState(false)
    const [loadBar, setLoadBar] = useState(0)

    const fetchData = (plus) => {
        return fetchDataGet(
            `business/enquiries?`+
                `search=${search}&`+
                `page=${visible + (plus ? plus : 0)}&`+
                `sortDirection=${sort}&`+
                `active=${status}&`+
                `type=${page === 'Booking' ? 'booked' : page == 'Complete' ? 'completed' : ''}`,
            'get',
            user.token.token)
    }

    useEffect(() => {
        refreshData()
    }, [page, sort, status])

    const refreshData = async () => {
        setVisLoader(true)
        setLoadBar(30)
        await setVisible(1)
        await setData([])
        fetchData(0 - visible + 1)
            .then(res => {
                setData(res[1].data)
                setState(res[1])
                setVisLoader(false)
                setLoadBar(100)
            })
            .catch(res => {
                setVisLoader(false)
                setLoadBar(100)
            })
    }

    const loadData = () => {
        setVisLoader(true)
        setLoadBar(30)
        fetchData(1)
            .then(res => {
                setData([...data, ...res[1].data])
                setState(res[1])
                setVisible(visible + 1)
                setVisLoader(false)
                setLoadBar(100)
            }).catch(res => {
                setVisLoader(false)
                setLoadBar(100)
            })
    }

    const editCurrentPage = (currentPage) => {
        if (currentPage === page) return;
        setVisLoader(true)
        setPage(currentPage);
    }

    return (
        <StyledDashboard>
            <LoadingBar
              progress={loadBar}
              height={3}
              color='#38daae'
              onLoaderFinished={() => { setLoadBar(0) }}
            />
            <div className="table-header-wrap">
            <div className="table-header" onMouseLeave={() => {setSortVis(false)}}>
                <div className="table-header__nav">
                    <div className="table-header__title">
                    {
                        page === 'Enquiries'
                            ? 'My Enquiries'
                            : page === 'Booking'
                                ? 'My Bookings'
                                : page === 'Complete'
                                    ? 'Completed Jobs'
                                    : 'My Enquiries'
                    }
                    </div>
                    <div className="table-header__search-wrap">
                        <input className="table-header__search" placeholder="Search" value={search}
                               onChange={e => setSearch(e.target.value)}
                               /*onBlur={() => { refreshData() }}*/
                               onKeyUp={e => { e.key === 'Enter' && refreshData() }}/>
                        <Search onClick={e => refreshData() }/>
                    </div>
                    <div className="table-header__sort table-header__select" onClick={() => setSortVis(!sortVis)}>
                        <div className="table-header__select-input">
                            Sort By: {sort === 'asc' ? 'Date - Most Recent to Oldest' : sort === 'desc' ? 'Date - Oldest to Most Recent' : 'Data'}
                        </div>
                        {sortVis ? <ArrowDropUp /> : <ArrowDropDown />}
                        { sortVis &&
                            <ul className="table-header__select-dropdown">
                                <li className={`table-header__select-dropdown__elem ${sort === 'asc' ? 'active' : ''}`} onClick={() => setSort('asc')}>Date - Most Recent to Oldest</li>
                                <li className={`table-header__select-dropdown__elem ${sort === 'desc' ? 'active' : ''}`} onClick={() => setSort('desc')}>Date - Oldest to Most Recent</li>
                            </ul>
                        }
                    </div>
                    {
                      page === 'Enquiries'
                        ?
                        <div className="table-header__status table-header__select" onClick={() => setStatusVis(!statusVis)}>
                          <div className="table-header__select-input">
                            {status === true ? 'Active' : status === false ? 'Declined' : 'Status'}
                          </div>
                          {statusVis ? <ArrowDropUp /> : <ArrowDropDown />}
                          { statusVis &&
                          <ul className="table-header__select-dropdown">
                            <li className={`table-header__select-dropdown__elem ${status ? 'active' : ''}`} onClick={() => setStatus(true)}>Active</li>
                            <li className={`table-header__select-dropdown__elem ${status === false ? 'active' : ''}`} onClick={() => setStatus(false)}>Declined</li>
                          </ul>
                          }
                        </div>
                        : null
                    }
                </div>
                <ul className="table-header__link">
                    <li className={`table-header__link-elem ${page === 'Enquiries' && 'active'}`} onClick={() => editCurrentPage('Enquiries')}>Enquiries</li>
                    <li className={`table-header__link-elem ${page === 'Booking' && 'active'}`}  onClick={() => editCurrentPage('Booking')}>Bookings</li>
                    <li className={`table-header__link-elem ${page === 'Complete' && 'active'}`}  onClick={() => editCurrentPage('Complete')}>Complete</li>
                </ul>
            </div>
            </div>
            <div className="content enquiry-content">
                <div className="table-wrap">
                    {
                        data && data.length !== 0 && page === 'Enquiries' &&
                        <div className="table-headers">
                            <div className="table-headers__col col1">
                              Event Name
                            </div>
                            <div className="table-headers__col col2">
                              Posted Date
                            </div>
                            <div className="table-headers__col col3">
                              Messages
                            </div>
                        </div>
                    }

                    {! visLoader && (data.length !== 0 &&
                        <div className="table">
                            {
                                page === 'Enquiries'
                                    ?
                                    data.map((item, index) => {
                                        return (
                                            <Enquiries
                                                data={item}
                                                index={index}
                                                key={`enquiries-${index}`}
                                            />
                                        )
                                    })
                                    : page === 'Booking'
                                        ?
                                        data.map((item, index) => {
                                            return (
                                                <Booking
                                                    data={item}
                                                    index={index}
                                                    key={`booking-${index}`}
                                                />
                                            )
                                        })
                                        : page === 'Complete'
                                            ?
                                            data.map((item, index) => {
                                                return (
                                                    <Complete
                                                        data={item}
                                                        index={index}
                                                        key={`complete-${index}`}
                                                    />
                                                )
                                            })
                                            : null
                            }
                        </div>
                    )}
                    {
                        state.total >= visible * 10 && !visLoader
                        ?
                            <div className="load">
                                <img src={Load} onClick={() => loadData()}/>
                            </div>
                        : null
                    }

                    {
                        visLoader
                            ? <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><Spinner animation="border" variant="primary" /></div>
                            : null
                    }
                    {
                        data.length === 0 && !visLoader && (
                            <div className="clear-result">
                                Sorry, we couldn't find any results
                            </div>
                        )
                    }
                </div>
                {/*{
                    data.length !== 0 &&
                        <div className="content-description">
                            <div className="content-description__text">
                                <img src={Article} /><br/>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                            <div className="content-description__help">Need help? <span>CONTACT US</span></div>
                        </div>
                }*/}
            </div>
        </StyledDashboard>
    )
}

const StyledDashboard = styled.div`
    .content {
        display: flex;

        &-description {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 300px;
            vertical-align: middle;
            background-color: #F4F6F7;
            padding: 200px 35px 20px;
            font-size: 14px;

            &__text {
                text-align: center;

                img {
                    margin-bottom: 20px;
                }

            }

            &__help {

            }
        }
    }

    .clear-result {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #707070;
    }

    .table {
        border-radius: 5px;
        /* overflow: hidden; */
        border: 1px solid #D1D1D1;

        div:nth-child(even) .table-row {
            background: white;
        }

        &-headers {
            display: flex;
            padding: 10px 30px;

            &__col {
                font-size: 14px;
                color: #707070;

                &.col1 {
                    width: 28%;
                }
                &.col2 {
                    margin-left: 30px;
                    width: 17.5%;
                }
                &.col3 {
                    margin-left: 30px;
                    width: 140px;
                }
            }
        }

        &-wrap {
            margin: 0 auto;
            max-width: 1240px;
            width: 100%;
            padding: 15px 30px 40px;
        }

        &-header {
            height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 30px;
            margin: 0 auto;
            max-width: 1240px;
           
            &-wrap {
              border-bottom: 1px solid #d1d1d1;
            }

            &__title {
                font-size: 28px;
                font-weight: 400;
                color: #10151B;
                margin-right: 33px;
            }

            &__nav {
                display: flex;
                height: 100%;
            }

            &__search {
                margin-left: 34px;
                border: none;
                width: 100%;
                margin-right: 10px;

                &:focus {
                    outline: none;
                }

                &-wrap {
                    width: 248px;
                    border-radius: 5px;
                    border: 1px solid #A4A4A4;
                    margin-right: 19px;
                    position: relative;
                    display: flex;
                    align-items: center;

                    svg {
                        position: absolute;
                        left: 10px;
                    }
                }
            }

            &__sort {
                width: 179px;
            }
            
            &__status {
              width: 100px
            }

            &__select {
                height: 100%;
                position: relative;
                border-radius: 5px;
                border: 1px solid #A4A4A4;
                margin-right: 19px;
                padding: 15px 35px 15px 10px;
                font-size: 12px;
                cursor: pointer;

                &-input {
                    display: block;
                    width: 135px;
                    height: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                &-dropdown {
                    position: absolute;
                    width: 100%;
                    border: 1px solid #A4A4A4;
                    border-radius: 5px;
                    top: calc(100% + 5px);
                    left: 0;
                    list-style: none;
                    padding: 5px 0;
                    background-color: #ffffff;
                    z-index: 999;

                    &__elem {
                        margin: 10px;
                        color: #707070;
                        font-size: 10px;

                        &.active { 
                            color: #333;
                            font-weight: bold;
                        }
                    }
                }

                svg {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                }

            }

            &__link {
                display: flex;
                padding: 0;
                margin: 0;
                list-style: none;

                &-elem {
                    font-size: 18px;
                    font-weight: 450;
                    color: #707070;
                    margin-left: 30px;
                    cursor: pointer;

                    &.active {
                        color: var(--grass);
                    }

                }
            }

        }
    }

    .load {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
`

export default Dashboard
