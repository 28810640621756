import React, { useState, useRef, useEffect } from 'react';
import './style.scss'
import { HomeSlider } from "../HomePage/Redisign/HomeSlider";
import { Link } from "react-router-dom";
import { LineContainer } from "../HomePage/GreenLine";
import useWindowDimensions from "../helpers/useWindowDimentsions";
import { HomeHero } from "../HomePage/Redisign/HomeHero";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { MainTitleStyled } from "../HomePage/MainTitleStyled";
import { GreenFill } from '../_components/GreenFill/GreenFill';
import useTitle from '../helpers/useTitle';
import useDescription from '../helpers/useDescription';


const Explanation = () => (
  <section className="about-text" >
    <div className="container">
      <h2 className="about-text__tx">
        We’re the platform helping event experts and top talent connect, collaborate and create exceptional events
      </h2>
    </div>
  </section>
);

const RegisterBusinessLink = () => {
  return <Link to='/register-business' onClick={() => window.scrollTo({ top: 0 })} className='about-getstarted__link-btn about-getstarted__link-btn--coral'>

  </Link>;
};

const WhoWeAre = () => (
  <section className="about-purpose">
    <h2 className="about-purpose__tl">
      Who we are
    </h2>
    <p className="about-purpose__tx">
      Finders Speakers was formed out of frustrations experienced firsthand when sourcing talent. A clear lack of choice, diversity, accessibility and control dominated the market. Too many events were too similar, offering up stale content and recycled talent, failing to maximise impact and failing to deliver.
    </p>
    <p className="about-purpose__tx">
    The fragmented and disjointed nature of the market also meant that some of the most promising talent was overlooked simply because they didn’t have a platform to get marketed and found.
    </p>
    <p className="about-purpose__tx">
    Speaking and events has weathered a perfect storm in recent years, forcing the industry to adapt. Tighter budgets, smaller teams and increased focus on returns has meant that the pressure on event teams to deliver has never been greater.
    </p>
    <p className="about-purpose__tx">
    We built Finders Speakers, in response to these challenges.
    </p>
    
  </section>
);

const WhatWeAre = () => (
  <div className="about-story">
    <h2 className="about-story__title">What we are</h2>
    <h3 className="about-story__subtitle">A next generation talent booking marketplace</h3>
    <p className="about-story__text">
      A professional community for event organisers and speaking talent to connect and collaborate.
    </p>

    <h3 className="about-story__subtitle">A stage for the most exciting global talent</h3>
    <p className="about-story__text">
      Curated talent for all occasions, topics, budgets. Established speakers, rising stars, unearthed
      gems, they all have one thing in common, they are subject matter experts with an engaging story to share.
    </p>

    <h3 className="about-story__subtitle">We believe best outcomes come from direct engagement and strong partnership</h3>
    <p className="about-story__text">
      We don’t limit searches, restrict access to talent, or moderate conversations.
      We empower you to control the narrative and own relationships.
    </p>

    <h3 className="about-story__subtitle">We’re about helping you find the right match</h3>
    <p className="about-story__text">
      Our market leading profiles bring speaker stories to life, helping event planners find the  best match.
    </p>

    <h3 className="about-story__subtitle">More than just a booking platform</h3>
    <p className="about-story__text">
      An innovative end-to-end solution for bookings, contracts, invoicing and payments, all managed on the one platform.
    </p>
    
    <h3 className="about-story__subtitle">A new fresh alternative</h3>
    <p className="about-story__text">
      Our mission is to increase industry connectivity and power more exceptional event experiences.
    </p>
  </div>
)

const Founder = () => (
  <div className="about-founder">
    <img src={require('../assets/founder-avatar.jpg')} alt="founder avatar" />
    <div className="about-founder__info">
      <h4 className="about-founder__name">Sophie O’Neill</h4>
      <p className="about-founder__position">Founder</p>
      <p className="about-founder__text">
        With 20+ years corporate experience leading on global events for some of the biggest brands, Sophie
        knows a
        thing or two about the speaking and events industry. Sophie believes in the power of direct
        collaboration to
        drive new ideas and opportunities. So, she built Finders Speakers, a platform where event experts and
        speaking
        talent can come together to deliver best in class events.
      </p>
      <a target='_blank' href="https://www.linkedin.com/in/sophie-o-neill-4829a317/"
        className="about-founder__link">Connect
        with Sophie
        on LinkedIin</a>
    </div>
  </div>
)

const FounderScott = () => (
    <div className="about-founder">
        <div className="about-founder__info">
            <h4 className="about-founder__name">Scott </h4>
            <p className="about-founder__position">Founder</p>
            <p className="about-founder__text">
                With 20+ years corporate experience leading on global events for some of the biggest brands, Sophie
                knows a
                thing or two about the speaking and events industry. Sophie believes in the power of direct
                collaboration to
                drive new ideas and opportunities. So, she built Finders Speakers, a platform where event experts and
                speaking
                talent can come together to deliver best in class events.
            </p>
            <a target='_blank' href="https://www.linkedin.com/in/sophie-o-neill-4829a317/"
               className="about-founder__link">Connect
                with Scott
                on LinkedIin</a>
        </div>
        <img src={require('../assets/founder-avatar.jpg')} alt="founder avatar" />
    </div>
)

const Purpose = ({ bgStyles }) => (
  <section className="about-benefites"
    style={{ ...bgStyles }}>
    <h2 className="about-benefites__title">Our purpose</h2>
    <p className="about-benefites__text">
    “To create a speaking economy that drives positive opportunities for Finders and Speakers to engage, inspire and thrive”
      </p>
  </section>
)

const Together = () => (
  <section className="about-together">
    <h2 className="about-together__title">We are in this together</h2>
    <p className="about-together__text">
      When you join Finders Speakers you’ll become part of a supportive and interactive professional network of
      <span className="d-block--xs"> like-minded industry leaders sharing</span> stories, lessons, opportunities, techniques, technologies and much
      more.
    </p>
  </section>
)

export const GetStarted = ({
  addonClass,
  btnFindersText,
  btnSpeakersText,
  theme
}) => {
  const [findersHovered, toggleFindersHovered] = useState(false),
    [speakersHovered, toggleSpeakersHovered] = useState(false);

  return (
    <section className={`about-getstarted ${addonClass || ''}`}>
      <MainTitleStyled className={`about_footer_title font-70 ${theme || ''}`}>
        Need help <span className="accent">finding a speaker?</span> <br />
      </MainTitleStyled>

      <div className="about-getstarted__links">
        <div className='about-getstarted__link'
          onMouseEnter={() => toggleFindersHovered(true)}
          onMouseLeave={() => toggleFindersHovered(false)}>
          <h3 className="about-getstarted__link-name">
            <LineContainer isVisible={findersHovered}
              img={() => require('../assets/greenline_about_finders.png')}
              position={'-6px'}>
              Get in touch
              <span className={`green-line ${findersHovered ? 'visible' : ''}`} />
            </LineContainer>
          </h3>
          <p className="about-getstarted__link-desc">Let our team do it for you</p>
          <Link to='/contact-us' className='about-getstarted__link-btn about-getstarted__link-btn--coral'>
            {btnFindersText ? btnFindersText : <span>
              Send us your brief
              </span>
            }
          </Link>
        </div>

        {/*<RegisterBusinessLink />*/}

        <div className='about-getstarted__link' style={{ display: 'none' }}
          onMouseEnter={() => toggleSpeakersHovered(true)}
          onMouseLeave={() => toggleSpeakersHovered(false)}>
          <h3 className="about-getstarted__link-name">
            <LineContainer isVisible={findersHovered}
              img={() => require('../assets/greenline_about_finders.png')}
              position={'-6px'}>
              Speakers
              <span className={`green-line ${speakersHovered ? 'visible' : ''}`} />
            </LineContainer>
          </h3>
          <p className="about-getstarted__link-desc">Serious about growing your brand and business?</p>
          <Link to='/register' className='about-getstarted__link-btn about-getstarted__link-btn--grass'>
            {btnSpeakersText ? btnSpeakersText : 'apply to join'}

          </Link>
        </div>
      </div>
    </section>
  )
}
const MainTitle = () => {
  const { width } = useWindowDimensions();
  const ref = useRef();
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };
    if (document.readyState === 'complete') {
      handleLoad();
    };
    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  return (
    <MainTitleStyled className="about_main_title" style={{ maxWidth: '870px' }}>
      {isLoaded && width > 769
        ? <GreenFill imageStyle={{ top: '2%', left: '-4%', width: '110%', height: '96%' }} delay={2000} reference={ref} animMob={true}>
          <span
            style={{ fontWeight: '500' }}
            ref={ref}
          >Redefining the way </span>
        </GreenFill>
        // : <><span className="accent">Redefining <br className='sm-visible' /> the way </span> <br className='sm-hidden' /></>
        : <GreenFill imageStyle={{ top: '-14%', left: '-4%', width: '166%', height: '118%' }} delay={2000} reference={ref} animMob={true}>
          <span
            style={{ fontWeight: '500' }}
            // className="sm-visible"
            ref={ref}
          >Redefining the way </span><br />
        </GreenFill>
      }
      <span className="new-line" style={{ paddingLeft: width > 1024 ? 19 : 0 }}> Finders and Speakers</span>
      <span className="new-line" style={{ paddingLeft: width > 1024 ? 16 : 0 }}> connect</span>
    </MainTitleStyled>
  )
};

const AboutScreen = () => {
  const { width } = useWindowDimensions();

  useTitle('OUR STORY');

  useDescription('Created by event professionals, we are a speaker marketplace' +
    ' connecting bookers with leading speakers for events.'
  );

  return (
    <>
      <HomeHero
        title={<MainTitle />}
        customStyle="about__hero"
      />

      {
        // deleted for phase 1
        // <Explanation />
      }
      <WhatWeAre />
      <WhoWeAre />
      {/*<FounderScott />*/}
      <Purpose />
      <Together />
      <HomeSlider bgColor="#fff" />
    </>
  );
};

export default AboutScreen;