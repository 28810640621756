import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import { Form } from 'react-bootstrap';
import styled from "styled-components";
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as EyeClose } from "../../../assets/icons/eye-close.svg";
import { ReactComponent as Checkmark } from "../../../assets/get-start-page/checkmark.svg";
import ReCAPTCHA from "react-google-recaptcha";
import useWindowDimensions from "../../../helpers/useWindowDimentsions";
import { signUp } from '../../../store/actions/authenticationActions';

export const SignUpFormBusiness = ({ postRegisterRequest, errorsData, toggleLoginModal, resetErrors }) => {
    const [first_name, set_first_name] = useState('');
    const [last_name, set_last_name] = useState('');
    const [business_name, set_business_name] = useState('');
    const [email, set_email] = useState('');
    const [phone, set_phone] = useState({ code: 'au', phone: '' });
    const [password, set_password] = useState('');
    const [passwordTypeInput, setPasswordTypeInput] = useState('password');
    const [agree, setAgree] = useState(false);
    const [communicationAgree, setCommunicationAgree] = useState(true);
    const [captcha, setCaptcha] = useState(false);

    const windowSize = useWindowDimensions();

    const token = useSelector(state => state.user.token);

    const history = useHistory();

    const dispatch = useDispatch();

    const {
        generalError,
        first_name: firstNameError,
        last_name: lastNameError,
        business_name: businessNameError,
        email: emailError,
        number: phoneError,
        agree: agreeError,
        password: passwordError,
        captcha: captchaError
    } = useSelector(state => state.auth.wrongData);

    const [passwordRequirement, setPasswordRequirement] = useState([
        { label: 'One lowercase character', isValid: false },
        { label: '8 characters minimum', isValid: false },
        { label: 'One special character', isValid: false },
        { label: 'One uppercase character', isValid: false },
        { label: 'One number', isValid: false },
    ]);

    useEffect(() => {
        resetErrors();
    }, []);

    const handleChangePassword = value => {
        set_password(value);
        setPasswordRequirement(
            [
                { label: 'One lowercase character', isValid: /[a-z]/.test(value) },
                { label: '8 characters minimum', isValid: value.length >= 8 },
                { label: 'One special character', isValid: /[@#$%^&*()_+!]/.test(value) },
                { label: 'One uppercase character', isValid: /[A-Z]/.test(value) },
                { label: 'One number', isValid: /[0-9]/.test(value) },
            ]
        )
    };

    const onChangeRecaptcha = value => {
        setCaptcha(value);
    };

    const submitForm = async () => {
            dispatch(signUp(token, {
                first_name,
                last_name,
                business_name,
                email,
                number: phone,
                password,
                password_confirmation: password,
                role: 'business',
                agree,
                captcha: !!captcha,
                receive_emails: communicationAgree,
            }, () => {}, history));


        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    const handleEyeButton = () => {
        setPasswordTypeInput(passwordTypeInput === 'password' ? 'text' : 'password')
    };

    const agreeHandler = () => {
        setAgree(!agree);
        resetErrors('agree');
    };

    const communicationAgreeHandler = () => {
        setCommunicationAgree(!communicationAgree);
    };

    const changePhoneHandler = (phone, countryData) => {
        set_phone({
            phone,
            code: countryData.countryCode,
        })
    };

    const getElemOrder = (index) => {
        if (windowSize.width <= 600) {
            switch (index) {
                case 0:
                    return '1';
                case 3:
                    return '2';
                case 1:
                    return '3';
                case 4:
                    return '4';
                case 2:
                    return '5';
                default:
                    return '';
            }
        } else return '';
    }

    return (
        <FormStyles style={{ justifyContent: 'center' }} >
            <div className="form__wrapper">
                <h2 className="form__title">Just fill out the form below to get started!</h2>
                <FormWrapperStyle>
                    <Form.Group controlId="signUpFormFistName"
                        className={firstNameError && 'red-border'}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="fistName"
                            autoComplete="off"
                            onChange={e => e.target.value.length <= 50 && set_first_name(e.target.value)}
                            value={first_name}
                        />
                        {firstNameError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg'}
                            >
                                {firstNameError}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group
                        controlId="signUpFormLastName"
                        className={lastNameError && 'red-border'}
                    >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="lastName"
                            autoComplete="off"
                            onChange={e => e.target.value.length <= 50 && set_last_name(e.target.value)}
                            value={last_name}
                            placeholder=""
                        />
                        {lastNameError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg'}
                            >
                                {lastNameError}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group
                        controlId="signUpFormBusinessName"
                        className={businessNameError && 'red-border'}
                    >
                        <Form.Label>Business Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="BusinessName"
                            autoComplete="off"
                            onChange={e => e.target.value.length <= 50 && set_business_name(e.target.value)}
                            value={business_name}
                            placeholder=""
                        />
                        {businessNameError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg'}
                            >
                                {businessNameError}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group
                        controlId="signUpFormEmail"
                        style={{ position: 'relative', zIndex: 0 }}
                        className={emailError && 'red-border'}
                    >
                        <Form.Label>Business email</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="email"
                            autoComplete="off"
                            value={email}
                            onChange={e => set_email(e.target.value)}
                        />
                        {emailError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg'}
                            >
                                {emailError}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group
                        controlId="signUpFormPhone"
                        style={{ position: 'relative', zIndex: 1 }}
                        className={phoneError && 'red-border'}
                    >
                        <Form.Label>Phone number</Form.Label>
                        <PhoneInput
                            // inputClass={emailError && 'red-border'}
                            countryCodeEditable={false}
                            masks={{
                                'au': '.. .... .... ..',
                                'north-america': '... ... ... ... ...',
                                'south-america': '... ... ... ... ...',
                                'us': '... ... ... ... ...',
                                'cn': '... ... ... ... ...',
                                'cu': '... ... ... ... ...',
                                'eg': '... ... ... ... ...',
                                'gb': '... ... ... ... ...',
                                'io': '... ... ... ... ...',
                                'br': '... ... ... ... ...',
                                'be': '... ... ... ... ...',
                                'bs': '... ... ... ... ...',
                                'ua': '.. ... .. .. .... ..',
                                'es': '... ... ... ... ...',
                                'de': '... ... ... ... ...',
                                'ru': '... ... ... ... ...',
                                'fr': '... ... ... ... ...',
                                'jp': '... ... ... ... ...',
                                'pt': '... ... ... ... ...',
                                'it': '... ... ... ... ...',
                                'ir': '... ... ... ... ...',
                                'ar': '... ... ... ... ...',
                                'tr': '... ... ... ... ...',
                                'id': '... ... ... ... ...',
                                'ai': '... ... ... ... ...',
                            }}
                            placeholder=""
                            country={phone.code}
                            value={phone.phone}
                            onChange={(number, countryData) => changePhoneHandler(number, countryData)}
                        />
                        {phoneError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg'}
                            >
                                {phoneError.phone}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>


                    <Form.Group
                        controlId="signUpFormPassword"
                        style={{ position: 'relative', zIndex: 0 }}
                        className={`full-size full-size--extra-space ${passwordError ? 'red-border' : ''}`}
                    >
                        <Form.Label>Enter a password</Form.Label>
                        <Form.Control
                            className="form-control-password"
                            required
                            type={passwordTypeInput}
                            name="password"
                            autoComplete="off"
                            value={password}
                            onChange={e => handleChangePassword(e.target.value)}
                        />
                        <button className="eye-button" type="button" onClick={handleEyeButton}>
                            {passwordTypeInput === 'password'
                                ? <Eye />
                                : <EyeClose />
                            }
                        </button>
                        {passwordError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg error-msg--pass'}
                            >
                                {passwordError}
                            </Form.Control.Feedback>
                        )}

                        <ul className="required-list">
                            {
                                passwordRequirement.map((el, idx) => (
                                    <li key={idx}
                                        className={`required-item
                                           ${idx === 2 ? 'text-right' : ''}
                                           ${(idx === 1 || idx === 4) ? 'pl20' : ''}
                                           ${(idx === 3 || idx === 4) ? 'mb0' : ''}
                                        `}
                                        style={{ order: getElemOrder(idx) }}
                                    >
                                        {el.isValid ? <Checkmark /> : <span className="dot" />}
                                        {el.label}
                                    </li>
                                ))
                            }
                        </ul>
                    </Form.Group>

                    <Form.Group className="full-size full-size--less-space">
                        <Form.Check type="checkbox" id="signUpFormAgreeCheck">
                            <Form.Check.Input
                                required
                                type="checkbox"
                                checked={agree}
                                onChange={() => agreeHandler()}
                            />

                            <Form.Check.Label>
                                I agree to the{' '}
                                <Link to={`/tc`} target="_blank" className="color-promo">
                                    terms and conditions
                                </Link>{' '}
                                and{' '}
                                <Link to={`/privacy`} target="_blank" className="color-promo">
                                    privacy policy
                                </Link>
                            </Form.Check.Label>
                            {agreeError && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className={'d-block error-msg error-msg--agree'}
                                >
                                    {/*{errorsData.errorRegister.agree}*/}
                                    Please tick this box
                                </Form.Control.Feedback>
                            )}
                        </Form.Check>
                    </Form.Group>

                    <Form.Group className="full-size mb-24">
                        <Form.Check type="checkbox" id="signUpFormCommunicationAgreeCheck">
                            <Form.Check.Input
                                required
                                type="checkbox"
                                checked={communicationAgree}
                                onChange={() => communicationAgreeHandler()}
                            />

                            <Form.Check.Label>
                                I would like to receive regular communications and special offers
                            </Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                    <Form.Group>
                        <ReCAPTCHA
                            width={253}
                            sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                            // onExpired={() => {}}
                            onChange={onChangeRecaptcha}
                        />
                        {captchaError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg error-msg--captcha'}
                            >
                                {captchaError}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </FormWrapperStyle>

                <ButtonMagenta
                    className="apply"
                    type="submit"
                    onClick={submitForm}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    Apply to join. it’s free
                </ButtonMagenta>

                {windowSize.width < 768 && (
                    <div className="login-link">
                        <span>Already have an account?</span>
                        <a href="#" className="aqua" onClick={toggleLoginModal}>Log in</a>
                    </div>
                )}
            </div>
        </FormStyles>
    );
}

export const ButtonBlock = styled.div`
    display: flex;
    justify-content:space-between;
    align-items: center;
    max-width: 625px;
    position:relative;
    padding-bottom: 50px;
    margin-bottom: 48px;

    @media (max-width: 1380px) {
        padding-bottom: 38px;
        margin-bottom: 32px;
   }

    &::before {
        content: '';
        position:absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: rgba(171,176,190,0.6);
    }

    &::after {
        content: 'or';
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background: #EAEBED;
        font-size: 14px;
        color: #767887;
        position:absolute;
        top: calc(100% - 20px);
        text-align: center;
        line-height: 40px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const ButtonWithIcon = styled.button`
    height: 52px;
    border: none;
    border-radius: 40px;
    padding: 0 55px;
    background: rgba(171,176,190,0.6);
    font-size: 14px;
    color: #293749;
    font-weight: 600;
    text-transform: uppercase;
    transition: background-color .3s ease-in-out;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1380px) {
        max-width: 255px;
        font-size: 14px;
        line-height: 20px;
        padding: 0 35px;
        text-align: center;
        height: 48px;
   }

    @media (max-width: 1260px) {
                max-width: initial;
                padding: 0 25px;
    }

    svg {
      display:inline-block;
      margin-right: 12px;

      @media (max-width: 1360px) {
                min-width: 20px;
            }
    }

    &:focus,
    &:active,
    &:visited {
        outline: none;
    }

    &:hover {
      background: #20D5C4;

    }
`;

export const ButtonMagenta = styled.button`
    height: 52px;
    width: 259px;
    line-height: 71px;
    background: #E8388A;
    border: 2px solid #E8388A;
    color: #fff;
    border-radius: 40px;
    display: inline-block;
    padding: 0 67px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    transition: background-color .3s ease-in-out, border .3s ease-in-out, color .3s ease-in-out ;
    margin-top: 40px;

    &:focus,
    &:active,
    &:visited {
        outline: none;
    }

    &:hover {
      border: 2px solid #E8388A;
      background: #fff;
      color: #E8388A;
    }

    @media (max-width: 1360px) {
      font-size: 14px;
      line-height: 46px;
      padding: 0 44px;
      margin-top: 24px;
   }

   @media (max-width: 600px) {
        // font-size: 14px;
        // line-height: 46px;
        // padding: 0 44px;
        // margin-top: 24px;
        width: 224px;
    }
`;

export const FormWrapperStyle = styled.div`
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-wrap: wrap;

    .red-border {
      .react-tel-input,
      .form-control {
         border: 1px solid red;
         border-radius: 5px;
      }
    }

    .dot {
        position:relative;
        width: 17px;
        height: 17px;

        &::before {
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #797979;
            position:absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
        }

        @media (max-width: 1024px) {
            width: 5px;
            height: 17px;
            margin-right: 3px;

            &::before {
              left: -2px;
            }
        }

        @media (max-width: 1024px) {
            margin-right: 4px;
        }
    }

    .mb0 {
        margin-bottom: 0 !important;
    }

    .pl20 {
        padding-left: 20px;

         @media (max-width: 1024px) {
            padding-left: 0;
        }
    }

    .required-list {
        margin-top: 20px;

        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-column-gap: 3px;

        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0;

        //  grid-template-areas:
        //       "lower eight   special"
        //       "upper number .";

        @media (max-width: 1360px) {
            // grid-column-gap: 5px;
            margin-bottom: 0;
        }

        @media (max-width: 1024px) {
        margin-top: 14px;

        // grid-template-columns: repeat(2, 1fr);
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;
        //      grid-template-areas:
        //       "lower eight"
        //       "lower eight"
        //       "lower number"
        //       "upper number"
        //       "upper special"
        //       "upper special";
        }

        @media (max-width: 1024px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            justify-content: space-around;
            height: 76px;
        }
    }

    .required-item {
        white-space: nowrap;
        flex-basis: 33%;
        font-size: 14px;
        line-height: 18px;
        color: #293749;
        margin-bottom: 14px;
        position: relative;
        display: flex;

        @media (max-width: 1024px) {
            margin-bottom: 0;
            padding-left: 0;
            &.text-right {
                text-align: left !important;
            }
        }

        @media (max-width: 600px) {
            width: 143px;
            white-space: normal;
            font-size: 12px;
            line-height: 16px;
            width: 143px;

            &:nth-child(1) {
            //  grid-area: lower;
            }

            &:nth-child(2) {
                height: 16px;
                padding-top: 4px;
                white-space: nowrap;

                .dot {
                    margin-right: 9px;
                }
            }

            &:nth-child(3) {
                padding-top: 4px;
                white-space: nowrap;
            }

            &:nth-child(4) {
            //  grid-area: upper;
            }

            &:nth-child(5) {
                padding-top: 4px;
                white-space: nowrap;
            }
        }

        svg {
            margin-top: 4px;
            margin-right: 10px;

            @media (max-width: 1360px) {
                  margin-right: 5px;
            }
        }
    }

    .form-group {
        flex-basis: 298px;
        margin-bottom: 18px;
        position: relative;
        z-index: 0;

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 1260px) {
            flex-basis: 47%;
        }

        @media (max-width: 1200px) {
            flex-basis: 100%;
            margin-bottom: 14px;

            &.mb-24 {
                margin-bottom: 24px;
            }
        }

        @media (max-width: 769px) {
            width: 95%;
        }
    }

    .eye-button:hover path {
            fill: #20D5C4;
        }

    .eye-button {
        position: absolute;
        height: 40px;
        top: 31px;
        right: 12px;
        border: none;
        background: transparent;

        &:focus,
        &:active {
            outline: none;
        }

        @media (max-width: 600px) {
            top: 29px;
            height: 48px;
            right: 5px;
        }
    }

    .full-size {
        flex-basis: 100%;

        @media (max-width: 1280px) {
          &--less-space {
            margin-bottom: 16px;
          }
        }

        &--extra-space {
          margin-bottom: 32px;

          @media (max-width: 1280px) {
              margin-bottom: 24px;
          }
        }
    }

    .react-tel-input .flag-dropdown.open,
    .react-tel-input .flag-dropdown {
        height: 100%;
        background: transparent;
        border-radius: 5px 0 0 5px;
        border: none;
        border-right: 1px solid #ccc;
    }

    .react-tel-input .selected-flag {
        border: none;
        background: transparent;
        width: 55px;
    }

    .form-control:focus{
        border: 1px solid #DCDFE0;
       box-shadow: 0 15px 50px rgba(167,171,172,0.1);
    }
    .react-tel-input .form-control,
    .form-control {
        background: #FFFFFF;
        height: 40px;
        border: 1px solid #DCDFE0;
        box-sizing: border-box;
        box-shadow: 0 15px 50px rgba(167, 171, 172, 0.1);
        border-radius: 5px;
        padding: 12px 16px;
        font-size: 13px;
        line-height: 28px;
        color: #293749;

        @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 18px;
        }

        @media (max-width: 600px) {
            height: 48px;
            font-size: 14px;
        }
    }

    .react-tel-input .form-control {
        padding-left: 60px;
        width: 300px;

          @media (max-width: 1360px) {
            // width: 255px;
        }

        @media (max-width: 1280px) {
            width: 100%;
          }
    }

    .form-control {
        font-weight: 400;

        &-password {
            @media (max-width: 1201px) {
                width: 540px;
            }

            @media (max-width: 600px) {
                max-width: 328px;
            }
        }
    }

    .form-label {
        height: 28px;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        color: #293749;
        margin-bottom: 3px;

         @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 10px;
        }

        @media (max-width: 600px) {
            height: 18px;
        }
    }

    .form-check {
        padding-bottom: 0;

        input:checked + label:before {
            border: 1px solid #20D5C4;

        }
    }

    .form-check-label {
        font-size: 13px;
        line-height: 28px;
        color: #293749;
        padding-left: 20px;

        @media (max-width: 1360px) {
            padding-left: 10px;
        }
        @media (max-width: 1024px) {
            padding-left: 18px;
            line-height: 22px;
        }
    }

    .form-check label:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        background: transparent;
        border: 1px solid #797979;

        @media (max-width: 1360px) {
            width: 20px;
            height: 20px;
        }
        @media (max-width: 1024px) {
            width: 24px;
            height: 24px;
        }
    }
`

export const ErrorMsgStyle = styled.div`
    .error_msg {
        color: #dc3545;
        font-size: 10px;
        width: 65%;
        margin-left: auto;
        text-align: right;
        position: relative;
        top: 20px;

         @media (max-width: 1360px) {
            font-size: 12px;
            line-height: 16px;
        }
    }
`;

export const FormStyles = styled.section`
    display: flex;
    padding: 154px 0 30px 38px;
    flex-basis: calc((100% / 2) + 14px);
    background: #EAEBED;
    margin-left: calc((100% / 2) - 14px);
    height: 100%;
    min-height: 100vh;
    max-width: 100vw;

    @media (max-width: 1280px) {
        justify-content: center;
    }

    @media (max-width: 600px) {
        overflow-y: hidden;
    }

    .form__wrapper {
        max-width: 625px;

        @media (max-width: 1200px) {
            width: auto;
            max-width: 540px;
        }

        @media (max-width: 600px) {
            max-width: 328px;
        }

        & button.apply {
          margin-top: 30px;

          @media (max-width: 1280px) {
            margin-top: 24px;
          }

          @media (max-width: 1024px) {
            width: 224px;
            margin: 30px auto 0;
            padding: 0 30px;
            font-size: 12px;
            height: 48px;
            display: block;
          }

          @media (max-width: 1024px) {
              width: 224px;
          }
        }
    }

    .login-link {
        text-align: center;
        margin: 40px 0 0;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 1700px) {
            margin: 30px 0 0 0;
        }

        a {
            padding-left: 0.5em;
        }
    }

        @media (max-width: 1280px) {
            flex-basis: calc(100% - 540px);
          padding: 154px 38px 56px 58px;
          margin-left: 540px;
        }

        @media (max-width: 1279px) {
          flex-basis: 58%;
          margin-left: 42%;
        }

        @media (max-width: 1024px) {
          margin-top: 89px;
          padding: 30px 16px 40px;
          flex-basis: 100%;
          margin-left: 0;
        }

        .error-msg {
            color: red;
            font-size: 10px;
            position: absolute;
            right: 2px;
            top: initial;
            bottom: -17px;
            line-height: 1;
            text-align: right;
            max-height: 14px;

             @media (max-width: 1260px) {
                    bottom: -12px;
                    font-size: 8px;
                }

            &--pass {
                top: 74px;
                max-width: fit-content;

                // @media (max-width: 1360px) {
                //    top: 72px;
                // }

                @media (max-width: 1200px) {
                   right: 40px;
                }

                 @media (max-width: 600px) {
                     right: 0;
                   top: 80px;
                }
            }

            &--agree {
              right: auto;
              bottom: -11px;
              left: 40px;
              text-align: left;
              max-width: 328px;

              @media (max-width: 1360px) {
                 left: 30px;
              }
              @media (max-width: 1024px) {
                 left: 38px;
              }
            }

            &--captcha {
              max-height: 14px;
              text-align: left;
              bottom: initial;
              left: 4px;
              top: 76px;
            }

            &--bottom {
              top: initial;
              bottom: -32px;

              @media (max-width: 1024px) {
                bottom: -10px;
              }
            }
        }

    .form__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        color: #000000;
        margin-bottom: 20px;

        @media (max-width: 1280px) {
            line-height: 32px;
            // margin-bottom: 24px;
        }

        @media (max-width: 1024px) {
            line-height: 30px;
            margin-bottom: 20px;
        }

        @media (max-width: 600px) {
            font-size: 24px;
        }
    }

    .form__subtitle {
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        color: #293749;
        margin-bottom: 40px;

        @media (max-width: 1360px) {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 24px;
        }
    }
`;
