//
export default function getFileUrl(path) {
  if (!path) {
    return '';
  }

  const baseUrlFile = process.env.REACT_APP_DO_SPACES_URL || '';

  return baseUrlFile.replace(/\/+$/, '') + '/' + path.replace(/^\/+/, '');
}
