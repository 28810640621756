import { connect } from 'react-redux';
import { GetStart } from './GetStart';
import {
    postRegisterAction,
    toggleAuthSteps,
} from '../store/actions';


const mapDispatchToProps = {
    postRegisterRequest: postRegisterAction,
    toggleAuthSteps,
};

const mapStateToProps = state => ({
    errors: state.errors,
    userData: state.verification.userData,
    isRegistrationInit: state.verification.isRegistrationInit,
    step: state.authStep,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetStart);
