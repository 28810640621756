import React from 'react';
import {FAQNav} from './FAQNav'
import {FAQItem} from './FAQItem';
import styled from "styled-components";

export const FAQ = ({currentTab, setCurrentTab }) => {
    return (
        <FQATabs>
            <div className="container">
                <FAQNav currentTab={currentTab} setCurrentTab={setCurrentTab}/>
                <FAQItem currentTab={currentTab} />
            </div>
        </FQATabs>
    )
}

const FQATabs = styled.div`
    padding: 110px 0;
    
    @media (max-width: 1280px) {
        padding: 80px 0;
    }
    @media (max-width: 768px) {
        padding: 30px 0 40px;
    }
    
    & > .container {
      @media (max-width: 768px) {
        width: 100%!important;
        max-width: 100%!important;
        min-width: 100%!important;
        padding: 0 16px 0 20px;
      }
    }
`;
