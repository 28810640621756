import React from 'react';

export const ClearLayout = (props) => {

    return (
        <>
            {props.children}
        </>
    );
}
