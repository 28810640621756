import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import footerBg from "../../assets/footer-bg.webp";

export const HomeHero = ({ title, text, button, buttonLink, customStyle, animation = true}) => {
  const [moveBg, setMoveBg] = useState('')
  useEffect(() => {
    setTimeout(() => {
      setMoveBg('move-bg')
    }, 200)
  }, [])

  return (
    <SpeakersHero className={`${moveBg}`}>
      <div className="bg" />
      <section className={`hero ${customStyle}`}>
        <div className="container-fluid">
          <div className="title">{title}</div>
          {animation
            ? <>
              <p className="hero__text">{text}</p>

              <div className="home__link">
                {
                  buttonLink ?
                    <a href={buttonLink}>
                      <span className="home__btn">{button}</span>
                    </a>
                    :
                    <Link to={`/get-started`}><span className="home__btn">{button}</span></Link>
                }
              </div>
            </>
            : <>
                <p className="hero__text">{text}</p>
                <div className="home__link">
                  <Link to={`/register`}><span className="home__btn">{button}</span></Link>
                </div>
            </>
          }
        </div>
      </section>
    </SpeakersHero>
  )
}

export const SpeakersHero = styled.div`
  position:relative;
  overflow: hidden;
    .bg {
        background: #161E27 url(${footerBg}) no-repeat center;
        background-size: cover;
        width: 100%;
        height: 100%;
        position:absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        transform: scaleX(1.5);
        transition: transform 2.5s linear 0.2s;
        
        @media (max-width: 768px) {
          background:  #161E27 url(${footerBg}) center bottom no-repeat;
          background-size: cover;
          }
    }
    .hero.about__hero {
      padding: 160px 0 73px;
    }
    &.move-bg {
      .bg {
          transform: scaleX(1);
      }; 
    }
    .home__link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hero {
        padding: 190px 0 136px;
        text-align: center;
        position: relative;
        overflow: hidden;
        
       
        @media (max-width: 1360px) {
           padding: 184px 0 136px;
        }
        
        @media (max-width: 1024px) {
           padding: 110px 0 80px;
           
           &.about__hero {
              padding: 130px 0 0;
            }
        }
        
        .title {
          font-size: 72px;
          font-weight: bold;
          line-height: 80px;

          @media (max-width: 1024px) {
            font-size: 36px;
            line-height: 36px;
            text-align: center;
            letter-spacing: -0.03em;
            
            & .new-line {
              display: inline;
            }
            
            & .new-line-xs.new-line {
              display: block;
            }
          }
        }

        .home__btn {
            font-weight: 600;
            font-size: 14px;
            
             @media (max-width: 1024px) {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                height: 38px;
                width: 200px;
                white-space: nowrap;
                padding: 6px 0;
                height: 48px;
                width: 222px;
                display: flex;
                justify-content: center;
                align-items: center;
             }
        }
    
        .hero__text {
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #fff;
            margin-bottom: 25px;
          
            @media (max-width: 1024px) {
              font-size: 16px;
              line-height: 21px;
              margin-bottom: 20px;
              padding: 0 15px;
            }
        }
        
        .container-fluid {
          @media (max-width: 768px) {
            padding: 0 16px;
            max-width: 360px;
            margin: 0 auto;
          }
        }
    }
`;
