import React, { useState } from 'react';
import cn from 'classnames';
import arrow from '../../../assets/down-arrow.svg';

import s from './styles.module.scss';

const ExpandablList = ({ ListItemComponent, children, menu }) => {
  const [open, setOpen] = useState(false);

  return (<>
      <ListItemComponent>
        <span
          onClick={() => setOpen(val => !val)}
          className={cn(s.container, { [s.open]: open })}
        >{children} <img src={arrow} /></span>
      </ListItemComponent>
      {open && menu}
  </>);
};

export default ExpandablList;
