import React, {useEffect, useState, useRef} from 'react';
import ApiDataProvider from '../api/apiClient';
import useTitle from '../helpers/useTitle';
import useDescription from '../helpers/useDescription';
import useSocialMeta from '../helpers/useSocialMeta';
import '../HomeStage2Screens/style.scss'
import {MyShortList} from "./MyShortList";
import {Advertising} from "./Advertising";
import {FilterList} from "./FilterList";
import {SortBySelect} from "./SortBySelect/SortBySelect";
import {SpeakersList} from "./SpeakersList";
import {Hero} from "./Hero";
import {PopUpFilter} from './PopUpFilter'
import useWindowDimensions from "../helpers/useWindowDimentsions";
import {useBodyStopScroll} from "../helpers/useBodyStopScroll";
import {Portal} from 'react-portal';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import Loader from "react-spinners/ClipLoader";
import styled from "styled-components";
import './styles.scss';
import {PopUpShortList} from "./PopUpShortList";
import {GreenFill} from '../_components/GreenFill/GreenFill';
import SignUpBusinessModal from '../AuthScreens/SignUpBusinessModal';
import { setPortalStatus } from '../store/actions/createEnquiryActions';
import { toggleSignUpBusinessModal } from '../store/actions.js';
import {
  getSearchValuesFromQueryString,
  getQueryStringFromSearchValues
} from './queryHelpers';
import { filterBudgetOptions, filterGenderOptions } from './filterOptions';


const Title = () => {
  const ref = useRef();

  return (
    <span className="header-title" ref={ref}>
            <div className="header-title__first-elem">Easily find&nbsp;</div>
            <GreenFill
              imageStyle={{top: '-22%', left: '-5%', width: '107%', height: '145%'}}
              reference={ref}
              type={1}
              delay={2000}
            >
                <span>top talent&nbsp;</span>
            </GreenFill>
            <div className="header-title__first-elem">fast</div>
        </span>
  );
};

const FindSpeakersScreens = ({ filterOptions, loadingFilterOptions }) => {
  const history = useHistory();
  const shortList = useSelector(state => state.speakers.shortList.data);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [shownData, setShownData] = useState([]);
  const [page, setPage] = useState(0);
  const [isFinished, setIsFinished] = useState(false);


  const queryParams = useLocation().search;

  const allFilterOptions = {
    ...Object.fromEntries(Object.entries(filterOptions).map(([key, value]) => [key, value.map(el => ({ value: el.id, label: el.name}))])),
    budgets: filterBudgetOptions,
    genders: filterGenderOptions
  };

  const [searchValues, setSearchValues] = useState(
    getSearchValuesFromQueryString(queryParams, allFilterOptions)
  );

  const portal = useSelector(state => state.portal.shortListPortal);
  const portalFilter = useSelector(state => state.portal.filterPortal);
  const isSignUpBusinessModalOpen = useSelector(state => state.modal.isSignUpBusinessModalOpen);


  const loadSpeakers = (searchValues, dropPage) => {
    const queryString = getQueryStringFromSearchValues({
      ...searchValues,
      page: dropPage ? 1 : page + 1
    });

    setLoading(true);

    ApiDataProvider.getSpeakers(queryString)
      .then(resp => {
        try {
          if (dropPage) {
            setShownData(resp.data);
          } else {
            setShownData(prev => [...prev, ...resp.data]);
          }

          setPage(resp.meta.current_page);

          setIsFinished(resp.meta.current_page === resp.meta.last_page);
        } catch (e) {
          throw e;
        }
      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
    });
  };

  const searchButtonHandler = () => {
    if (loading) {
      return;
    }

    const queryString = getQueryStringFromSearchValues(searchValues);

    history.push({
      search: queryString
    });

    loadSpeakers(searchValues, true);
  };

  useBodyStopScroll();

  const handlerOpenPortal = type => {
    dispatch(setPortalStatus({ portalType: type, portalStatus: true }));
  };

  const handlerClosePortal = () => {
    dispatch({type: 'RESET_PORTAL_STATUS'});
  };

  const changeFilterParams = params => {
    setSearchValues(values => ({
      ...values,
      ...params
    }));
  };

  const resetFilters = () => {
    if (loading) {
      return;
    }

    const initialParams = getSearchValuesFromQueryString('', allFilterOptions);

    changeFilterParams(initialParams);
  };

  useEffect(() => {
    const queryString = getQueryStringFromSearchValues(searchValues);

    history.push({
      search: queryString
    });

    loadSpeakers(searchValues, true);
  }, [
    searchValues.search,
    searchValues.types,
    searchValues.topics,
    searchValues.event_types,
    searchValues.genders,
    searchValues.budgets,
    searchValues.location,
    searchValues.sort,
    searchValues.order
  ]);


  return (
    <FindSpeakersStyles>
      <Hero
        title={<Title/>}
      />

      <div className="sidebar">
        {shortList.length > 0 &&
          <MyShortList
            onClick={() => handlerOpenPortal('shortListPortal')}
            data={shortList}
          />
        }
        { !loadingFilterOptions ? (
          <FilterList
            filterOptions={allFilterOptions}
            onChange={changeFilterParams}
            onSearch={searchButtonHandler}
            onReset={resetFilters}
            values={searchValues}
          />) : <Loader color="#20d5c4" size={40} loading={true} />
        }
      </div>

      <div className="container">
        <div className="d-flex justify-content-between main">
          <div className="content">
            <header>
              <div className="row sort-wrapper">
                <SortBySelect sort={searchValues.sort} order={searchValues.order} onChange={changeFilterParams} />
              </div>
            </header>
            {
              shownData.length ? (
                <SpeakersList
                  data={shownData}
                />
              ) : isFinished && !loading && (
                <p style={{
                  fontSize: '18px',
                  color: 'white',
                  margin: '1em 0'
                }} className="text-center">No results match</p>
              )
            }

            { loading
              ? <Loader color="#20d5c4" size={40} loading={loading} />
              : !isFinished && (
                <button
                  className="btn-load-more"
                  type="button"
                  onClick={() => loadSpeakers(searchValues)}
                >load more results</button>
              )
            }
          </div>
        </div>
      </div>

      <SignUpBusinessModal show={isSignUpBusinessModalOpen} onClose={() => dispatch(toggleSignUpBusinessModal())} />

    </FindSpeakersStyles>
  );
};

export default () => {
  useTitle('FIND TALENT');

  useDescription('Browse our speakers. View market-leading profiles. ' +
    'Search by topic, budget, format, location and more.'
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [speakerTypes, setSpeakerTypes] = useState([]);
  const [eventFormats, setEventFormats] = useState([]);
  const [topics, setTopics] = useState([]);


  useEffect(() => {
    setLoading(true);

    Promise.all([
      ApiDataProvider.getSpeakerTypes(),
      ApiDataProvider.getEventFormats(),
      ApiDataProvider.getTopics()
    ]).then(results => {
      setSpeakerTypes(results[0].data);
      setEventFormats(results[1].data);
      setTopics(results[2].data.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0));
    }).catch(e => {
      setError(e.message);
    }).finally(() => {
      setLoading(false);
    });
  }, []);


  return <FindSpeakersScreens
    filterOptions={{types: speakerTypes, event_types: eventFormats, topics}}
    loadingFilterOptions={loading}
  />;
};

const FindSpeakersStyles = styled.div`
  background: #293749;
  padding-bottom: 25px;

  .text {
    margin-top: 17px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #E8388A;
    text-transform: uppercase;

    @media (max-width: 800px) {
      margin: 0;
    }
  }

  .hero {
    padding-top: 130px;
    padding-bottom: 52px;

    .hero__text {
      max-width: 800px;
      margin-bottom: 0;
    }

    .hero__text + .container {
      padding-top: 0;
      position: relative;
    }
  }

  .header-title {
    font-family: Poppins;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 44px;
    line-height: 44px;
    font-weight: normal;
    letter-spacing: -0.03em;

    @media (max-width: 861px) {
      font-size: 36px;
      line-height: 36px;
      flex-direction: column;
    }

    &__first-elem {
      white-space: nowrap;

      @media (max-width: 376px) {
        letter-spacing: -0.03em;
      }
    }

    &__second-elem {
      color: #00D5C4;
    }
  }

  .main {
    flex-direction: column;
  }

  .sidebar {
    flex-basis: 298px;
    display: flex;
    background: #ffffff;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .container {
    padding-top: 23px;

    @media (max-width: 800px) {
      width: 100%;
      padding: 17px 20px 0 20px;
    }
  }

  .content {
    width: 100%;
    flex-basis: calc(100% - 30px - 298px);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    margin: 0;
    margin-bottom: 22px;
    height: 48px;
    width: 976px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 800px) {
      margin-bottom: 19px;
      height: 26px;
      width: 320px;
    }
  }

  .loader__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sort__label {
    font-size: 18px;
    line-height: 56px;
    text-align: right;
    color: #293749;
    height: 48px;
    display: flex;
    align-items: center;
    margin-right: 20px;

    @media (max-width: 800px) {
      margin: 0;
      display: block;
      margin-right: -202px;
      margin-top: 32px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .counter {
    padding: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #293749;
    height: 48px;
    display: flex;
    align-items: center;

    @media (max-width: 861px) {
      font-size: 20px;
      line-height: 26px;
      padding: 0;
      height: 100%;
    }
  }

  .sort-dropdown {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
    height: 48px;
    padding: 0;

    @media (max-width: 768px) {
      height: 26px;
      margin: 0;
      padding: 0;
    }

    .input-elem {
      border: none;
      background: #fff;
      height: 56px;
      line-height: 56px;
    }

    .input-elem .fa {
      color: #797979;
      opacity: 0.4;
      font-size: 18px !important;
    }

    .dropmenu {
      top: calc(100% + 12px);
      background: #FFFFFF;
      border: 1px solid #DCDFE0;
      box-sizing: border-box;
      box-shadow: 0 15px 50px rgba(167, 171, 172, 0.2);
      border-radius: 5px;
      padding: 16px;

      .circle {
        display: none;
      }

      .dropmenu-elem {
        margin-bottom: 14px;
        font-size: 18px;
        line-height: 28px;
        color: #293749;
      }

      .dropmenu-elem:last-child {
        margin-bottom: 0;
      }

      .label {
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #293749;
        margin: 0;
        padding: 0;
      }
    }
  }

  .dropdown {
    width: 276px !important;
    height: 56px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #293749;
  }

  .btn-load-more {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #E8388A;
    border: none;
    background: transparent;
    margin: 30px auto 0;
    width: 230px;
    display: block;

    :active,
    :focus {
      outline: none;
    }
  }

  .speaker-screen {
    width: 255px;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    padding-bottom: 46px;

    .sort-dropdown {
      margin-bottom: 0;
    }

    .content {
      width: 100%;
    }

    .main {
      display: block !important;
    }

    .content {
      flex-basis: 100%;
      display: block;
    }

    .hero {
      padding-top: 120px;
      padding-bottom: 40px;

      .container {
        padding: 0;
      }

      .title {
        font-weight: 600;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 30px;
      }

      .hero__text {
        display: none;
      }
    }

    .btn-load-more {
      font-size: 14px;
      line-height: 20px;
      margin: 18px auto 0;
    }
  }

  .flip-card__front .categories-wrap {

    .flip-card__back .card__footer {
      height: 142px;
      width: 200px;

      .text {
        margin-bottom: 0;
      }

      .rating {
        margin-bottom: 18px;
        justify-content: flex-start;
        min-height: 24px;

        svg {
          display: inline-block;
          margin-right: 12px;
        }
      }
    }

    @media (max-width: 768px) {
      .speaker-screen:nth-child(n) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      .speaker-screen .flip-card__front {
        width: 100%;
        height: 330px;

        .front {
          width: 100%;
          height: 326px;
          min-height: initial;
        }

        .add-button {
          width: 15px;
        }

        .position {
          height: 16px;
        }

        .categories-wrap {
          height: 30px;
        }

        .bottom {
          height: 46px;
        }
      }

      .speaker-screen .flip-card__back {
        width: 100%;
        height: 330px;

        & > div {
          width: 100%;
          height: 326px;
          min-height: initial;
        }

        .add-button {
          width: 15px;
        }

        .position {
          height: 16px;
        }

        .categories-wrap {
          height: 30px;
        }

        .bottom {
          height: 46px;
        }

      }

      .speaker-screen .flip-card__back {
        border-radius: 0;

        & > div {

          .add-btn {
            margin: 17px 0 0;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
`