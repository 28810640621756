import React, { useState } from 'react';
import styled from 'styled-components';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import heart from "../../assets/icons/heart_light.png";
import plus from '../../assets/icons/+.png';
import SmartThumbnail from '../../_components/SmartThumbnail';
import {useSelector} from 'react-redux';
import './speaker-card.scss';

export const SliderSpeakerCard = (props) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const shortList = useSelector(state => state.speakers.shortList.data);

    const {
        photo,
        first_name,
        last_name,
        expertise,
        isAnimationDone,
        delay = 1,
        professional_title,
        backComponent,
        addSpeakerInShortList,
        id,
        index,
        width
    } = props;

    let added = shortList.some(el => el.id === id);

    const mouseEnterHandler = () => {
        if (width > 800) {
            setIsFlipped(true);
        }
    };

    const mouseLeaveHandler = () => {
        if (width > 800) {
            setIsFlipped(false);
        }
    };

    const onClick = () => {
        if (width <= 800) {
            setIsFlipped(true);
        }
    };

    const onClickFlipped = e => {
        e.stopPropagation();
        if (width <= 800) {
            setIsFlipped(false);
        }
    };

    const topicArr = expertise.speaker_topic.join(", ");

    const item = (
        <div className="slide-speaker-card-wrapper">
            <div
                className="slide-speaker-card-container"
                style={{position: 'relative'}}
            >
                <div
                    className="speaker-card-shortlist-button"
                    onClick={expertise ? () => addSpeakerInShortList(id) : () => {
                    }}
                    style={{backgroundColor: !added ? '#20D5C4' : '#E8388A'}}
                >
                    {added
                        ? <img src={heart}/>
                        : <img src={plus}/>
                    }
                </div>

                <div className="slide-speaker-card-image-container">
                    <SmartThumbnail
                        path={photo}
                        alt={`${first_name}'s photo`}
                    />
                </div>

                <div
                    className="speaker-stage2-card-flip card-flip"
                    onClick={onClick}
                >
                    <div className="slide-speaker-card-name card-flip">
                        {`${first_name} ${last_name}`}
                    </div>
                    <div className="slide-speaker-card-professional-title card-flip">
                        {professional_title}
                    </div>
                    <div className="slide-speaker-card-topics card-flip">
                        {expertise.speaker_topic && expertise.speaker_topic.length
                            ? expertise.speaker_topic.map((elem, index) => {
                                if (index === expertise.speaker_topic.length - 1 || expertise.speaker_topic.length === 1) {
                                   return <div>{topicArr}</div>
                                }
                            })
                            : ''
                        }
                    </div>

                        <div
                          className={`flip-card__back ${isFlipped && 'active'}`}
                          onClick={onClickFlipped}
                        >
                            {backComponent}
                        </div>
                </div>
            </div>
        </div>
    )

    return (
        <StyledDiv
            animationIn="fadeInUp"
            animationInDelay={delay}
            offset={200}
            className={isAnimationDone ? 'no-animation' : 'buuu'}
            style={{position: 'relative', pointerEvents: 'unset'}}
        >
            {item}
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
  .speaker-card-shortlist-button {
    top: 4px;
    right: 4px;
  }
`

const FlipCardStyle = styled.div`
  .flip-card__back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    pointer-events: none;
    appearance: none;
  }

  .flip-card__back.active {
    opacity: 1;
    pointer-events: initial;
    appearance: initial;
  }
`;
