import React from 'react';
import styled from "styled-components";

export const Advertising = () => {
    return (
        <AdvertisingStyles />
    )
}

const AdvertisingStyles = styled.section`
    background: #fff;
    width: 727px;
    height: 184px;
    margin-bottom: 30px;
    position: relative;
    
    &:before {
        content: 'Advertising';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        color: #ABB0BE;
    }
`