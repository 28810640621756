import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Headroom from 'react-headroom';
import styles from './HeaderDashboard.module.scss'
import logo from '../../assets/logo-header_new.svg';
import logoLight from '../../assets/logo_light.png';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../api/helperFunctions';
import LogoutMenu from './LogoutMenu';
import { logout } from '../../store/actions/authenticationActions';
import { GreenLineOnHover } from "../../HomePage/GreenLineOnHover";
import { useBodyStopScroll } from "../../helpers/useBodyStopScroll";
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import Sidebar from "../../_components/Sidebar";
import { AvatarSmall } from '../../_components/avatars';
import greenLine from '../../assets/home-page/green_line_why.svg';
import whiteLine from '../../assets/home-page/white_line_why.svg';
import { useHistory } from 'react-router';

function useOutsideAlerter(ref, toggleMenu) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleMenu(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const DashboardHeader = (props) => {
  let avatar = useSelector(state => state.user.avatar);
  const token = useSelector(state => state.user.token);
  const userName = `${useSelector(state => state.user.firstName)} ${useSelector(state => state.user.lastName)}`;
  const chatTotalUnreadCount = useSelector(state => state.chat.totalUnreadCount);
  const [isMenuOpen, toggleMenu] = useState(false);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [currentLink, setCurrentLink] = useState(0);
  const { width } = useWindowDimensions();
  const history = useHistory();
  useBodyStopScroll();

  const linkStyle = props.theme === 'dark' ? styles.d_head__link + ' ' +  styles.d_head__link_dark : styles.d_head__link;
  const underlineImage = props.theme === 'dark' ? greenLine : whiteLine;

  const logoRendered = props.lightLogo || props.theme === 'dark' ? logoLight : logo;

  useOutsideAlerter(wrapperRef, toggleMenu);

  const handlerLogout = () => {
    dispatch(logout(() => {
      history.push('/');
    }));
    dispatch({ type: 'SET_AVATAR', payload: '' });
  };

  if (
    avatar === `${process.env.REACT_APP_DO_SPACES_URL}/null` ||
    avatar === `${process.env.REACT_APP_DO_SPACES_URL}/`
  ) {
    avatar = '';
  }
  return (
    <>
      {
        width > 1024
          ? (
            <Headroom
              style={{
                height: '90px',
                transition: 'all .5s ease-in-out'
              }}
              className="absolute"
              disable={true}
            >
              <header>
                <div className={styles.d_head} style={{background: 'transparent', borderBottom: 'none'}}>
                  <div className={styles.d_head__menu_wrap}>
                    <Link to="/">
                      <img
                        className={styles.d_head__logo}
                        src={logoRendered}
                        alt="logo"
                      />
                    </Link>
                    <ul className={styles.d_head__nav}>
                    <NavLink to="/dashboard"
                        className={`${linkStyle} ${currentLink === 0 ? styles.d_head__link_active : ''}`}
                        onClick={() => setCurrentLink(0)}
                        style={{...props.styleText}}
                        activeClassName={styles.navLinkActive}
                      >
                        <GreenLineOnHover
                          target={'My Jobs'}
                          bgImg={underlineImage}
                          moveBottom="-6px"
                          lineHeight="15px"
                        />
                      </NavLink>

                      <NavLink to="/chat"
                        className={`${linkStyle} ${currentLink === 1 ? styles.d_head__link_active : ''}`}
                        onClick={() => setCurrentLink(1)}
                        style={{...props.styleText}}
                        activeClassName={styles.navLinkActive}
                      >
                        <GreenLineOnHover
                          target={'Messages'}
                          bgImg={underlineImage}
                          moveBottom="-6px"
                          lineHeight="15px"
                        />
                        {chatTotalUnreadCount !== 0 &&
                          <span title="Unread count" className={styles.messageCountBadge}>{
                            chatTotalUnreadCount > 99 ? '99+' : chatTotalUnreadCount
                          }</span>
                        }
                      </NavLink>
                      <NavLink to="/my-stats"
                        className={`${linkStyle} ${currentLink === 2 ? styles.d_head__link_active : ''}`}
                        onClick={() => setCurrentLink(2)}
                        style={{
                          ...props.styleText,
                          display: 'none' // hide for phase 1
                        }}
                      >
                        <GreenLineOnHover
                          target={'My stats'}
                          bgImg={underlineImage}
                          moveBottom="-6px"
                          lineHeight="15px"
                        />
                      </NavLink>
                      <NavLink to="/payments-invoices"
                        className={`${linkStyle} ${currentLink === 3 ? styles.d_head__link_active : ''}`}
                        onClick={() => setCurrentLink(3)}
                        style={{...props.styleText}}
                        activeClassName={styles.navLinkActive}
                      >
                        <GreenLineOnHover
                          target={'Payments'}
                          bgImg={underlineImage}
                          moveBottom="-6px"
                          lineHeight="15px"
                        />
                      </NavLink>
                      <NavLink to="/testimonials"
                        className={`${linkStyle} ${currentLink === 4 ? styles.d_head__link_active : ''}`}
                        onClick={() => setCurrentLink(4)}
                        style={{...props.styleText}}
                      >
                        {
                          /*
                          <GreenLineOnHover
                          target={'Feedback'}
                          bgImg={underlineImage}
                          moveBottom="-6px"
                          lineHeight="15px"
                        />
                           */
                        }
                      </NavLink>
                    </ul>
                  </div>

                  <div>
                    <div className={`${styles.d_head__avatar} ${isMenuOpen ? styles.d_head__avatar_active : ''}`}>
                      <div className={styles.d_head__avatar_outline}>
                        <div className={styles.d_head__avatar_img} style={{ width: '50px', height: '50px' }}>
                          <span style={{ cursor: 'pointer' }} onClick={() => toggleMenu(!isMenuOpen)}>
                            <AvatarSmall imageUrl={avatar} role={''} />
                          </span>
                        </div>
                      </div>
                      {isMenuOpen ? (
                        <div
                          ref={wrapperRef}
                          style={{ display: 'inline-block' }}
                        >
                          <LogoutMenu
                            toggleMenu={toggleMenu}
                            isMenuOpen={isMenuOpen}
                            handlerLogout={handlerLogout}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </header>
            </Headroom>
          ) : (
            <header className={styles.MobileHeader}>
              <Sidebar
                onClickLogin={() => {}}
                isVerified={token}
                userName={userName}
                onLogout={handlerLogout}
                theme={props.theme}
                lightLogo={props.lightLogo}
              />
            </header>
          )
      }
    </>
  );
};

const mapStateToProps = storeState => ({
  user: storeState.user.token,
});

export default connect(mapStateToProps)(DashboardHeader);
