import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {ReactComponent as Cross} from '../assets/icons/cross.svg';
import RSC from "react-scrollbars-custom";
import useWindowDimensions from "../helpers/useWindowDimentsions";
import {useOutsideClick} from "../helpers/useOutsideClick";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {fetchDataGet} from "../api/helperFunctions";
import * as R from 'ramda';
import {isNil} from 'ramda';
import {Tooltip} from "antd";
import {RSCStyles} from "./FilterListInner";
import {ReactComponent as Hat} from "../assets/home-page/hat.svg";
import defaultAvatar from '../assets/default-avatar.jfif';

export const PopUpShortList = ({onPortalClose, visible}) => {
    const {width, height} = useWindowDimensions();
    const innerRef = useRef(null)
    const shortListRef = useRef(null)
    const data = useSelector(state => state.speakers.shortList.data);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [clearWasClicked, setClearWasClicked] = useState(false)
    useOutsideClick(innerRef, () => onPortalClose());

    const history = useHistory();

    const handleClearData = () => {
        fetchRemoveSpeaker(data.map(el => el.id));
        onPortalClose();
    }
    const handleClearCancel = () => {
        setClearWasClicked(false)
    }
    const handleConfirmDelete = () => {
        setClearWasClicked(true);
    }

    const handleRemoveSpeaker = (id) => {
        fetchRemoveSpeaker([id]);
    }

    const fetchRemoveSpeaker = (idArr) => {
        dispatch({type: 'REMOVE_SHORT_LIST_INIT'});
        fetchDataGet(`finder/short-list`, 'delete', user.token, {speakers: idArr})
            .then((response) => {
                if (response[0] === 200) {
                    dispatch({type: 'REMOVE_SHORT_LIST_SUCCESS', payload: response[1]});
                }
            })
            .catch(() => {
                dispatch({type: 'REMOVE_SHORT_LIST_ERROR', payload: false});
            });
    }

    return (
        <PopUpShortListStyles className={`${visible ? 'visible' : ''}`}
                              height={((width > 1360 ? 222 : (width > 768 ? 155 : 146)) * data.length) + (width > 1360 ? 400 : (width > 768 ? 300 : 326))}>
            <div className={`body ${visible ? 'visible' : ''}`} ref={innerRef}>
                <button onClick={onPortalClose} className="btn-close"><Cross/></button>
                <div className="d-flex justify-content-between header">
                    <h3 className="title">My shortlist ({!R.isEmpty(data) ? data.length : [].length})</h3>
                    <button
                        className="clear-list"
                        onClick={handleConfirmDelete}
                    >
                        Clear shortlist
                    </button>
                    {clearWasClicked
                        ? <p className="delete-confirmation">
                            Please confirm:
                            <button type="button" onClick={handleClearData}>Clear shortlist</button> /
                            <button type="button" onClick={handleClearCancel}>Cancel</button>
                        </p>
                        : <></>
                    }
                </div>
                <p className="text"> Add favourite speakers to your shortlist. Share profiles
                    <span className="d-block">or send a direct enquiry
                    to one or more speakers at once!</span></p>
                <RSCStyles>
                    <RSC
                        style={{
                            width: "100%",
                            height: `${height - 360}px`
                        }}
                        trackXProps={{styles: {display: 'none'}}}
                    >
                        <ul className="short-list" ref={shortListRef}>
                            {
                                !R.isEmpty(data)
                                    ? data.map((el, idx) => {

                                        const firstName = el.first_name.toLowerCase().replace(/\s/g, '');
                                        const lastName = el.last_name.toLowerCase().replace(/\s/g, '');

                                        return (
                                            <li key={idx} className="d-flex justify-content-between short-item">
                                                <div className="photo">
                                                    <a href={`/speakers/${el.id}`} target="_blank">
                                                        {isNil(el.photo)
                                                            ? <div className="no-img"/>
                                                            : <img
                                                                src={`${process.env.REACT_APP_DO_SPACES_URL}/${el.photo}`}
                                                                alt={`${el.first_name} ${el.last_name}`}
                                                                onError={event => {
                                                                    event.target.src = defaultAvatar
                                                                }}
                                                            />
                                                        }
                                                    </a>
                                                </div>
                                                <div className="info">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="name">
                                                            {console.log(el, 'POPUP ELEMENT')}
                                                            <a style={{color: 'unset'}} href={`/speakers/${el.id}`}
                                                               target="_blank">
                                                                {`${el.first_name} ${el.last_name}`}
                                                            </a>
                                                        </div>
                                                        <button
                                                            className="btn-remove"
                                                            onClick={() => handleRemoveSpeaker(el.id)}
                                                        ><Cross/>Remove
                                                        </button>
                                                    </div>
                                                    <ul className="categories">
                                                        {/* {el.expertise.speaker_topic.map((category, idx) => {
                              return (
                                <li key={idx}>{category}</li>
                              )
                            })} */}
                                                        {el.professional_title}
                                                    </ul>
                                                    <span className="salary">{`$${el.fee.from}-$${el.fee.to}`}</span>
                                                    {/* <span className="location">{el.location}</span> */}
                                                </div>
                                            </li>
                                        )
                                    })
                                    : <></>
                            }
                        </ul>
                    </RSC>
                </RSCStyles>
                <div className="d-flex justify-content-between buttons_wrapper">
                    <button  className={"my-shortlist-btn s-home__search-submit " + (R.isEmpty(data) ? "btn-disabled" : "")}
                           onClick={() => {
                               dispatch({
                                   type: 'RESET_PORTAL_STATUS',
                                   payload: {portalType: 'shortListPortal', portalStatus: false}
                               });
                               history.push('/create-enquiry');
                           }} disabled={R.isEmpty(data)}>ENQUIRE NOW!</button>
                    {/*<input type="button" className={'my-shortlist-btn footer__button btn btn-outline-secondary'}*/}
                    {/*       value={'Email me my shortlist'}/>*/}
                </div>
            </div>
        </PopUpShortListStyles>
    )
}

const PopUpShortListStyles = styled.section`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transition: background-color 0.4s ease-in-out;

  .buttons_wrapper {
    margin-top: 24px;
    @media (max-width: 1360px) {
      margin-top: 60px;
    }
    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  .no-img {
    width: 100%;
    height: 100%;
    background: #ccc;
    opacity: 0.6;
  }

  &.visible {
    background: rgba(0, 0, 0, 0.4);
  }

  .btn-remove,
  .btn-close {
    width: 30px;
    height: 30px;
    border: none;
    background: transparent;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:hover {
      path {
        fill: #20D5C4;
      }
    }
  }

  .btn-close {
    position: absolute;
    right: 45px;
    top: 45px;
    z-index: 1;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;

  }

  .btn-remove {
    width: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 30px;
    color: #797979;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }

    path {
      fill: #797979;
    }

  }

  .d-flex {
    align-items: flex-start;
  }

  .body {
    background: #fff;
    padding: 100px 100px 100px 107px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 727px;
    height: ${props => props.height}px;
    min-height: 100vh;
    margin-right: -727px;
    transition: margin-right 0.4s ease-in-out;

    &.visible {
      margin-right: 0;
    }
  }

  .ScrollbarsCustom-TrackY {
    right: -20px !important;
  }

  .title {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    color: #293749;
  }

  .clear-list {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #E8388A;
    border: none;
    background: transparent;
    padding: 0;
    transform: translateX(15px);

    &:active,
    &:visited,
    &:focus {
      outline: none;
    }
  }

  .text {
    font-size: 18px;
    line-height: 28px;
    color: #797979;
    padding-bottom: 40px;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 0;
    margin-top: 24px;
  }

  .short-list {
    margin: 0 0 30px;
    padding: 0;
  }

  .short-item {
    padding: 40px 0;
    border-bottom: 1px solid #E9E9E9;
  }

  .photo {
    flex-basis: 140px;
    width: 140px;
    height: 140px;
    border-radius: 5px;
    overflow: hidden;

    img {
      width: 100%;
      height: 140px;
      object-fit: cover;
    }
  }

  .info {
    flex-basis: calc(100% - 154px);
    padding-right: 10px;
  }

  .name {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #293749;
    margin-bottom: 8px;
  }

  .location,
  .salary {
    font-size: 14px;
    line-height: 18px;
    color: #797979;
    margin-bottom: 12px;
    display: inline-block;
    padding-right: 8px;
    margin-right: 8px;
  }

  .salary {
    border-right: 1px solid #797979;
  }

  .categories {
    font-size: 16px;
    line-height: 18px;
    color: #20D5C4;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 13px;

    li {
      padding-right: 8px;
      margin-right: 8px;
      border-right: 2px solid #20D5C4;
      margin-bottom: 1px;
    }
  }

  .my-shortlist-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 52px;
    width: 255px;
    text-transform: uppercase;
    padding: 0;
    background-color: #E8388A;
    border: 3px solid #E8388A;
    color: #ffffff;
    border-radius: 40px;
    transition: 0.5s;
    outline: none;

    &.btn-disabled {
      user-select: none;
      background: gray;
      color: #293749;
      border: gray;
      opacity: 0.8;
      
      &:hover {
        user-select: none;
        background: gray;
        color: #293749;
        border: gray;
        opacity: 0.8;
      }
    }

    &:hover {
      background-color: #ffffff;
      color: #E8388A;
      border: 3px solid #E8388A;
      transition: 0.5s;
    }

    &.btn-outline-secondary {
      color: #293749;
      background: #ABB0BE;
      border: none;
      transform: translateX(15px);

      &:hover {
        background: #20D5C4;
      }

      @media (max-width: 768px) {
        transform: translateX(0);
      }
    }
  }

  @media (max-width: 1360px) {
    .body {
      width: 625px;
      padding: 94px 85px 40px 94px;
    }

    .btn-close {
      right: 62px;
      top: 38px;
    }

    .title {
      font-size: 24px;
      line-height: 28px;
    }

    .text {
      font-size: 14px;
      line-height: 18px;
      margin-top: 16px;
      padding-bottom: 22px;
    }

    .photo {
      width: 120px;
      height: 120px;
      flex-basis: 120px;

      img {
        width: 120px;
        height: 120px;
      }
    }

    .info {
      padding-top: 8px;
      flex-basis: calc(100% - 132px);
    }

    .location,
    .salary {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 10px;
    }

    .categories {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }

    .short-list {
      margin: 0 0 20px;
    }

    .short-item {
      padding: 17px 0;
    }

    .name {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0;
    }

    .btn-remove {
      font-size: 14px;
      line-height: 18px;
    }

    .my-shortlist-btn {
      width: 214px;
    }
  }

  @media (max-width: 768px) {
    .body.visible {
      width: 100%;
      padding: 34px 16px;

      & > .d-flex {
        display: block !important;
      }
    }

    .btn-close {
      right: 16px;
      top: 32px;
    }

    .clear-list {
      margin-top: 10px;
    }

    .text {
      padding-bottom: 28px;

      .d-block {
        display: inline !important;
        padding-left: 4px;
      }
    }

    .photo {
      width: 50px;
      height: 50px;
      flex-basis: 50px;

      img {
        width: 100%;
        height: 50px;
      }
    }

    .info {
      flex-basis: calc(100% - 65px);
      padding-top: 3px;
    }

    .short-list {
      margin-right: 10px;
    }

    .short-item {
      align-items: flex-start;
    }

    .name {
      font-size: 14px;
      line-height: 18px;
    }

    .categories li {
      padding-right: 4px;
      margin-right: 4px;
    }

    .btn-remove {
      line-height: 18px;
      font-size: 12px;
      height: 18px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .rating__item {
      width: 19px;
      height: 19px;
      margin-right: 8px;
      background-size: contain;

    }

    .my-shortlist-btn {
      border-radius: 40px;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .header {
    position: relative;
  }

  .delete-confirmation {
    position: absolute;
    bottom: -8px;
    right: 0;
    font-size: 11px;
    font-weight: 600;
    opacity: 0.6;

    @media (max-width: 1360px) {
      bottom: -20px;
    }

    @media (max-width: 768px) {
      bottom: -28px;
      right: initial;
      left: 0;
    }

    button {
      background: transparent;
      border: none;
      padding: 0 0 0 6px;
      font-size: 11px;
      font-weight: 600;
      opacity: 0.6;
      transition: opacity 0.3s ease-in-out;

      &:focus,
      &:active {
        outline: none;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
`