import React from 'react';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>The Benefits of Booking a Diversity & Inclusion Speaker</h2>

      <p className={s.paragraph}>
        At <a className={s.link} href="/" target="_blank">Finders Speakers Co</a> we
        believe that diversity and inclusion are fundamental for building thriving,
        innovative, and inclusive organizations. By engaging with these speakers,
        you can drive positive change, enhance employee engagement, and position
        your organization at the forefront of diversity and inclusion.
      </p>

      <p className={s.paragraph}>
        Our curated selection of speakers brings a wealth of expertise and thought
        leadership to help you navigate the complexities of diversity and inclusion,
        and create a more inclusive and equitable workplace environment. These
        speakers can help to tap into the power of diverse perspectives, unlock
        creativity, and build stronger, more resilient teams.
      </p>

      <h3 className={s.greenHeadingList}>Enhancing Awareness and Understanding</h3>
      <p className={s.paragraph}>
        A diversity and inclusion speaker can help raise awareness about various
        aspects of diversity, such as race, gender, ethnicity, sexual orientation,
        disability, and more. They can provide valuable insights, personal experiences,
        and historical perspectives, helping the audience develop a deeper
        understanding of these issues.
      </p>

      <h3 className={s.greenHeadingList}>Promoting Inclusive Mindsets</h3>
      <p className={s.paragraph}>
        These <a className={s.link} href="/find-speakers" target="_blank">speakers</a> can challenge
        biases, stereotypes, and unconscious prejudices that may exist within an
        organization or a community. By sharing personal stories and thought-provoking
        ideas, they can inspire individuals to adopt more inclusive mindsets and behaviors.
      </p>

      <h3 className={s.greenHeadingList}>Fostering Empathy and Connection</h3>
      <p className={s.paragraph}>
        A diversity and inclusion speaker in australia often shares personal narratives
        that can create a sense of empathy among the audience. Hearing real-life experiences
        can help people connect emotionally and develop a greater understanding of the
        challenges faced by marginalized individuals or groups.
      </p>

      <h3 className={s.greenHeadingList}>Encouraging Diversity of Thought</h3>
      <p className={s.paragraph}>
        Embracing diversity goes beyond visible differences; it also encompasses diverse
        perspectives and ideas. A speaker can emphasize the importance of diverse thought
        and how it can lead to more innovative solutions, better decision-making, and
        improved overall performance within organizations.
      </p>

      <h3 className={s.greenHeadingList}>Strengthening Workplace Culture</h3>
      <p className={s.paragraph}>
        By addressing diversity and inclusion topics, a speaker can help foster a more
        inclusive and supportive workplace culture. This, in turn, can lead to increased
        employee satisfaction, productivity, and retention rates. A diverse and
        inclusive environment can also attract top talent and enhance an organization's reputation.
      </p>

      <h3 className={s.greenHeadingList}>Providing Practical Strategies</h3>
      <p className={s.paragraph}>
        The top Diversity and inclusion speakers often offer practical strategies
        and actionable steps that individuals and organizations can implement. These
        may include fostering inclusive leadership, implementing diversity and inclusion
        initiatives, promoting allyship, and creating safe spaces for dialogue.
      </p>

      <h3 className={s.greenHeadingList}>Facilitating Difficult Conversations</h3>
      <p className={s.paragraph}>
        Discussing diversity in the workplace Australia and inclusion can sometimes be
        challenging or uncomfortable. A speaker can create a safe and open environment
        where participants feel encouraged to engage in meaningful conversations, ask
        questions, and address sensitive topics constructively.
      </p>

      <h3 className={s.greenHeadingList}>Meeting Legal and Compliance Requirements</h3>
      <p className={s.paragraph}>
        Depending on the jurisdiction and industry, organizations may have legal obligations
        to ensure diversity and inclusion in the workplace. Booking a diversity and inclusion
        speaker can help organizations stay informed about relevant laws, regulations,
        and best practices.
      </p>

      <p className={s.paragraph}>
        Overall, <a className={s.link} href="/about" target="_blank">booking</a> a
        diversity and inclusion speaker can contribute to building
        a more inclusive, equitable, and harmonious environment, both within organizations
        and society as a whole.
      </p>
    </>
  );
};

const TopicPageDiversity = () => {
  return (
    <TopicPageBase
      topicAlias="diversity-and-inclusion"
      headerSubtitle={<>
        Book leading diversity & inclusion speakers.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a diversity & inclusion speaker?"
      trendingSpeakersHeading="Trending Diversity & Inclusion Speakers"
      why={<Why />}
      speakerTopicText="diversity and inclusion"
    ></TopicPageBase>
  )
};

export default TopicPageDiversity;
