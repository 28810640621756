import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import profile from '../../assets/profile.svg';
import list from '../../assets/list.svg';
import coin from '../../assets/coin-black.svg';
import logout from '../../assets/logout.svg';
import preview_profile from '../../assets/preview_profile.svg';
import dashboard from '../../assets/admin/icons/dashboard.svg';
import bell from '../../assets/admin/icons/bell.svg';
import search from '../../assets/search-home-stage-2.svg';

const menuItems = {
  speaker: ['myAccount', 'previewProfile', 'editProfile',  'latestNews', 'logout'],
  business: ['findSpeakers', 'myAccount', 'latestNews', 'logout'],
  admin: ['logout']
};


const getData = item => {
  switch (item) {
    case 'myAccount':
      return {
        title: 'Account',
        logoSrc: list,
        url: '/account-details'
      };
    case 'editProfile':
      return {
        title: 'Edit Profile',
        logoSrc: profile,
        url: '/builder-step1'
      };
    case 'previewProfile':
      return {
        title: 'Preview Profile',
        logoSrc: preview_profile,
        url: '/preview-profile'
      };
    case 'payment':
      return {
        title: 'Payments',
        logoSrc: coin,
        url: '/payments-invoices'
      };
    case 'logout':
      return {
        title: 'Logout',
        logoSrc: logout,
        url: '/'
      };
    case 'testEnquiry':
      return {
        title: '-- Enquiry test --',
        url: '/create-enquiry'
      };
    case 'findSpeakers':
      return {
        title: 'Find a Speaker',
        url: '/find-speakers',
        logoSrc: search
      };
    case 'dashboard':
      return {
        title: 'Dashboard',
        logoSrc: dashboard,
        url: '/dashboard'
      };
      case 'latestNews':
      return {
        title: 'Latest News',
        logoSrc: bell,
        url: '/blog/',
        isDirect: true
      };
    default:
      return null;
  }
};

const LogoutMenu = props => {
  const user = useSelector(store => store.user);
  const userRole = user.role;

  return (
    <div
      className={styles.menu}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {
        menuItems[userRole].map(item => {
          const data = getData(item);
          let line = null;
          let onClickHandler = () => props.toggleMenu(!props.isMenuOpen);

          if (item === 'logout') {
            line = (<div className={styles.line}/>);
            onClickHandler = () => props.handlerLogout();
          }

          if (item === 'test-enquiry' && !process.env.REACT_APP_SHOW_ENQUIRY_TEST) {
            return null;
          }

          if (data.isDirect) {
            return (
              <>
                {line}
                <a href={data.url} className={styles.el}>
                  {
                    data.logoSrc && (
                      <div className={styles.img_wrapper}>
                        <img src={data.logoSrc} alt="img"/>
                      </div>
                    )
                  }
                  {data.title}
                </a>
              </>
            );
          }

          return (
            <>
              {line}
              <Link
                className={styles.el}
                to={data.url}
                onClick={onClickHandler}
              >
                {
                  data.logoSrc && (
                    <div className={styles.img_wrapper}>
                      <img src={data.logoSrc} alt="img"/>
                    </div>
                  )
                }
                {data.title}
              </Link>
            </>
          );
        })
      }
    </div>
  );
};

export default LogoutMenu;
