import React from 'react';
import styles from './styles.module.scss';

const BaseButton = ({ onClick, onClickAsync, disabled, children, buttonStyle }) => {
  const clickHandler = () => {
    if (onClickAsync) {
      onClickAsync()
      .catch(e => console.error(e));
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <button
      disabled={disabled}
      className={buttonStyle}
      onClick={clickHandler}
    >
      {children}
    </button>
  );
};

export const PrimaryButton = props => {
  return <BaseButton buttonStyle={styles.primaryButton} {...props} />;
};

export const PrimaryButtonInverted = props => {
  return <BaseButton buttonStyle={styles.primaryButtonInverted} {...props} />;
};

export const SecondaryButton = props => {
  return <BaseButton buttonStyle={styles.secondaryButton} {...props} />;
};
