import React from 'react';

import PreviewProfileContent from '../preview_profile_content/PreviewProfileContent';

export const Testimonials = ({ credentials }) => {
  const replacer = (data) => {
    return (data.map(elem => {
      return { ...elem, text: elem.text.replace(/\n/ig, '</br>') }
    }))
  };

  return (
    <div className="content">
      <div className="custom-container testimonials">
        <h2 className="custom-container__label">
          Testimonials from other Finders{' '}
          <span>({(credentials['testimonial'] && credentials['testimonial'].length) || '0'})</span>
        </h2>
        <PreviewProfileContent
          content={[{ title: 'testimonials', content: replacer(credentials['testimonial']) }]}
          lazyload={5}
        />
      </div>
    </div>
  )
}
