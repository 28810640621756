import React from 'react';
import styled from 'styled-components';

const PopupVerify = ({ name = 'Sophia', email = 'sophia@gmail.com', onLogIn, onResend }) => {
    return (
        <StyledPopup>
            <div className='wrapper'>
            <h5 id='title'>Hello {name}, please verify your email to proceed</h5>
            <p id="text">
                Thanks for signing up with Finders Speakers. We have sent an
                email to <span id="email">{email}</span> asking you to
                verify your email address.
            </p>
            <p id="half-inviz">
                Please make sure it did not go into your spam folder.
            </p>
            <div>
                <p id="text">
                    Didn't get the email? <span onClick={()=>onResend()}>Resend</span>
                </p>
                <p id="text">
                    Click to <span onClick={()=>onLogIn()}>Log in</span>
                </p>
            </div>
            </div>
        </StyledPopup>
    );
};

const StyledPopup = styled.div`
    @import url(./../../styles/variables.scss);

    position: fixed;
    background-color: rgba(0, 0, 0, .6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 111;

    .wrapper {
        width: 650px;
        background-color: #fff;
        border-radius: 15px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        /* bottom: 0; */
        /* margin: auto; */
        text-align: center;
        padding: 60px 60px 20px;
        box-shadow: 1px 1px 10px #5a5858;
    }

    #title {
        font-size: 28px;
        font-weight: 400;
        line-height: 50px;
        padding: 0 50px 60px;
    }

    #text {
        font-size: 18px;
        margin: 0;
        line-height: 35px;
    }
    span {
        color: var(--green-primary);
        cursor: pointer;
    }
    div {
        border-top: 1px  rgba(0, 0, 0, .4) solid;
        width: 80%;
        margin: 30px auto 0;
        padding-top: 20px;
    }

    #half-inviz {
        opacity: 0.3;
        padding-bottom: 30px;
        font-size: 14px;

    }
    #email {
        color: var(--secondary);
        cursor: auto;
    }
`;

export default PopupVerify;
