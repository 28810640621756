import React, { useEffect } from 'react';
import useBlockCrawling from '../../helpers/useBlockCrawling';
import GetStart from '../../AuthScreens';
import GetStartedForBusiness from '../../AuthScreens/GetStartedForBusiness/GetStartedForBusiness';
import { EasyHeader } from '../../_layout/Header/EasyHeader'
import LoginModal from "../../AuthScreens/Old/LoginModal";
import { useLocation } from 'react-router';

export default function GetStartLayout({ toggleLoginModal, showModal }) {
    const location = useLocation();
    useBlockCrawling();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []);

    return (
        <>
            <LoginModal
                onClick={() => toggleLoginModal()}
                setShow={() => toggleLoginModal()}
                show={showModal}
            />
            <EasyHeader onClickLogin={toggleLoginModal} />
            {location.pathname === '/register' ? <GetStart /> : <GetStartedForBusiness />}
        </>
    );
}
