import React from 'react';
import styled from "styled-components";
import { HomeTitle } from "../HomeTitle";
import { Picture } from "./Picture";
import { Link } from 'react-router-dom';

export const Marketplace = () => {
  return (
    <MarketplaceStyled>
      <HomeTitle
        text={'Join the marketplace for top speaking talent'}
        maxWidth={{ desktop: '653px', mobile: '100%' }}
        marginBottom={{ desktop: '54px', mobile: '20px' }}
        fontSize={{ desktop: '44px', mobile: '30px' }}
        parentName={'marketplace'}
      />
      <Picture />
      <ul className="marketplace_list">
        <li className="marketplace_item marketplace_item_1">
          <h3 className="marketplace_title">
            Showcase your expertise
                  </h3>
          <p className="marketplace_text">
            Access an engaged audience who want to hear your story!
                  </p>
        </li>
        <li className="marketplace_item marketplace_item_2">
          <h3 className="marketplace_title">
            More business wins
                  </h3>
          <p className="marketplace_text">
            Get marketed and connect direct. We don’t play favourites!
                  </p>
        </li>
        <li className="marketplace_item marketplace_item_3">
          <h3 className="marketplace_title">
            No upfront fees
                  </h3>
          <p className="marketplace_text">
            Our success driven model means we are 100% motivated to get you booked!
                  </p>
        </li>
        <li className="marketplace_item marketplace_item_4">
          <h3 className="marketplace_title">
            Created by event organisers
                  </h3>
          <p className="marketplace_text">
            We know your client. <span className="d-block">We’ve been your client!</span>
          </p>
        </li>
        <li className="marketplace_item marketplace_item_5">
          <h3 className="marketplace_title">
            Expand your reach
                  </h3>
          <p className="marketplace_text">
            Build a brand to thrive in a new world of speaking and events.
                  </p>
        </li>
      </ul>
      <HomeBtnVideo to={`/get-started`} className="home__link" width={{ desktop: '204px', mobile: '160px' }}>
        Play video
      </HomeBtnVideo>
    </MarketplaceStyled>
  )
}

export const HomeBtnVideo = styled(Link)`
    padding: 20px 24px 20px 70px;
    height: 64px;
    line-height: 20px;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-17%, 102%);
    z-index: 20;
    background: #E8388A;
    border: 2px solid #E8388A;
    color: #fff;
    border-radius: 40px;
    display: inline;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    transition: background-color .3s ease-in-out, border .3s ease-in-out, color .3s ease-in-out ;

    &::after {
      content: '';
      width: 38px;
      height: 38px;
      border: 2px solid #fff;
      border-radius: 50%;
      position:absolute;  
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      transition: border-color 0.3s linear;
      
    }
  
    &::before {
      content: '';
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-right: 0 transparent;
      border-left: 12px solid #fff; 
      position:absolute;  
      top: 50%;
      left: 35px;
      transform: translateY(-50%);
      transition: border-left-color 0.3s linear;
      }
      
        
    &:focus,
    &:active, 
    &:visited {
        outline: none;
    }
    
    &:hover {
      border: 2px solid #E8388A;
      background: #fff;
      color: #E8388A;
      
      &::before {
        border-left: 12px solid #E8388A; 
      }
      &::after {
        border: 2px solid #E8388A;
      }
    }
    
      @media (max-width: 1024px) {
        top: 319px;
        left: 60%;
        width: 180px;
        transform: translateX(-50%);
     }

     @media (max-width: 600px) {
       top: 285px;
     }
`;
export const MarketplaceStyled = styled.div`
    position:relative;
    overflow: hidden;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #19222D;
    padding-top: 85px;
    padding-bottom: 95px;
    min-height: 896px;
    
     @media (max-width: 1024px) { 
        font-size: 16px;
        line-height: 22px;
        padding-top: 35px;
        padding-bottom: 29px;
     }
     
     .marketplace_list {
         display: flex;
         align-items: center;
         justify-content: center;
         list-style-type: none;
         padding: 0;
         height: 506px;
         margin-bottom: 0;
         
        @media (max-width: 1024px) { 
          height: auto;
          max-width: 360px;
          margin: 0 auto;
        }

        @media (max-width: 600px) {
            margin-top: 10px;
        }
     }
     
     .marketplace_title {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.02em;
        margin-bottom: 10px;
     }
     
     .marketplace_item {
        width: 255px;
        text-align: center;
        margin-top: 80px;

        @media (max-width: 600px) {
          margin-top: 0;
        }
        
        p {
           font-size: 18px;
        }
     }
     
     .marketplace_item_1 {
        transform: translate(87px, 14px);
       
     }
     .marketplace_item_2 {
        transform: translate(-34px,170px);
     }
     .marketplace_item_3 {
        transform: translate(0, 244px);
     }
     .marketplace_item_4 {
        transform: translate(24px,170px);
     }
     .marketplace_item_5 {
        transform: translate(-87px, 14px);
     }
     
    @media (max-width: 1024px) {  
        .marketplace_list {
            display: block;
        }
        .marketplace_item {
          position: static;
          width: 100%;
          padding: 0 20px; 
          margin-bottom: 23px;
            p {
              font-size: 16px;
            }
          
            &.marketplace_item_1,
            &.marketplace_item_2,
            &.marketplace_item_3,
            &.marketplace_item_4,
            &.marketplace_item_5 {
                transform: none;
                p {
                  margin-bottom: 0;
                }
            }
            
             &.marketplace_item_5 {
                margin-bottom: 0;
             }
        }
    }
`;
