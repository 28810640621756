import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import greenFill from '../../assets/greenfill.png';

export const GreenFill = ({ children, imageStyle, reference, delay, speed, type, minus, animMob = false }) => {
    const [position, setPosition] = useState();
    const [isVisible, setIsVisible] = useState(false);
    let minusPosition = minus || 500;

    useEffect(() => {
        if (reference && reference.current) {
            const pos = document.documentElement.scrollTop + reference.current.getBoundingClientRect().top
            setPosition(pos);
        };
    }, [reference]);

    useEffect(() => {
        if (position && position - minusPosition < window.pageYOffset) {
            setTimeout(() => setIsVisible(true), delay || 200);
        } else {
            document.addEventListener('scroll', setVisibleElement);
        };
        return () => document.removeEventListener('scroll', setVisibleElement);
    });

    const setVisibleElement = () => {
        // if (type === 1) {
        //     console.log('position', position);
        //     console.log('window.pageYOffset', window.pageYOffset);
        // };

        if (position && position - minusPosition < window.pageYOffset) {
            setTimeout(() => setIsVisible(true), delay || 200);
        };
    };

    return (
        <GreenFillStyled speed={speed} imageStyle={imageStyle} animMob={animMob}>
            <img
                className={isVisible ? 'img img-visible' : 'img'}
                src={greenFill}
            />
            <span className={`greenfill-text ${isVisible ? 'greenfill-text_visible' : ''}`}>
                {children}
            </span>
        </GreenFillStyled>
    );
}

const GreenFillStyled = styled.span`
    position: relative;
    z-index: 1;

    .img {
        width: 0px;
        position: absolute;
        z-index: -1;
        top: ${props => props.imageStyle && props.imageStyle.top ? props.imageStyle.top : '-5%'};
        left: ${props => props.imageStyle && props.imageStyle.left ? props.imageStyle.left : '-5%'};
    }

    .img-visible {
        display: block;
        width: ${props => props.imageStyle && props.imageStyle.width ? props.imageStyle.width : '110%'};
        height: ${props => props.imageStyle && props.imageStyle.height ? props.imageStyle.height : '110%'};
        transition: width ${props => props.speed ? props.speed : '0.6s'} ease-in-out;

        @media (max-width: 1024px) {
            transition: ${props => props.animMob ? '' : 'none'} ;
        }
    }

    .greenfill-text {
        color: #ffffff;

        &_visible {
            color: #171F28;
            transition: ${props => props.speed ? props.speed.toString() : '1s'};

            @media (max-width: 1024px) {
                transition: none;
            }
        }
    }
`