export default {
  maxFileSizeMB: 5,
  maxProfileViews: 3,
  copyright: '© Finders Speakers 2020-2023',
  phone: '1300 112 499'
};

export const TOPICS = [
  ['Inspiration & Motivation', '/inspiration-and-motivation-speakers'],
  ['Leadership', '/leadership-speakers'],
  ['Performance & Productivity', '/performance-and-productivity-speakers'],
  ['Mental Health & Resilience', '/mental-health-and-resilience-speakers'],
  ['Diversity & Inclusion', '/diversity-and-inclusion-speakers'],
  ['Business', '/business-speakers'],
  ['Communication', '/communication-speakers'],
  ['Innovation', '/innovation-speakers'],
  ['Sports', '/sports-speakers']
];
