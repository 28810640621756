import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { GreenLineOnHover } from "../../HomePage/GreenLineOnHover";
import bgImage from '../../assets/trending-tags_underline.png';

export const TopicSlider = ({ rtl, tags, onSelectItem }) => {
    const sliderOptions = {
        arrows: false,
        variableWidth: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        swipe: false,
        autoplaySpeed: 2000,
        speed: 500,
        dots: false,
        rtl: rtl,
        pauseOnHover: false,
    }

    return (
        <StyledSlider {...sliderOptions}>
            {tags.map((item) => {
                return (
                    <div
                      key={item.title}
                      className="slide__wrapper"
                      style={{ width: '445px' }}
                      onClick={() => onSelectItem(item.id)}
                    >
                        <h2 className="slide__title">
                            <GreenLineOnHover
                                target={item.title}
                                bgImg={bgImage}
                                moveBottom={'-9px'}
                            />
                        </h2>
                        <img src={item.img} alt="img" style={{ width: '100%', height: 211, objectFit: 'cover' }} />
                    </div>
                )
            })}

            {tags.map((item) => {
                return (
                    <div key={item.title} className="slide__wrapper" onClick={() => onSelectItem(item.id)}>
                        <h2 className="slide__title">
                            <GreenLineOnHover
                                target={item.title}
                                bgImg={bgImage}
                                moveBottom={'-9px'}
                            />
                        </h2>
                        <img src={item.img} alt="img" style={{ width: '100%', height: 211, objectFit: 'cover' }} />
                    </div>
                )
            })}
        </StyledSlider>
    )
}

const StyledSlider = styled(Slider)`
    width: calc(418px * 6);
    margin-bottom: 20px;

    &:nth-child(odd) {
        margin-right: initial;
        margin-left: -328px;
    }

    &:nth-child(even) {
        margin-right: initial;
        margin-left: -245px;
    }

  .slide__wrapper {
      width: 391px !important;
      height: 211px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      
      img {
          position: relative;
          z-index: -1;
          filter: brightness(0.75);
      }
  }
  
  .slide__title {
      color: #fff;
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      font-size: 21px;
      line-height: 25px;
      white-space: nowrap;
  }

  .slick-slide {
      width: 418px !important;
  }
  
  svg, path {
      fill: #fff;
  }

  img {
    width: 391px;
    height: 211px;
      border-radius: 4px;
  }
`;