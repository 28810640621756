
import axios from 'axios';
import { PATH } from './constants';

export const fetchResendConfirmation = (url, email) => {
    return fetch(PATH + url, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ email })
    })
};

export const requestPasswordReset = email => {
    return fetch(PATH + 'forgot-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ email })
    }).then(async response => {
        const result = await response.json();
        if (response.status < 300 && result.status < 300) {
            return result;
        } else {
            throw new Error(result.message);
        }
    });
};

export const resetPassword = (email, password, confirmPassword, passwordResetToken) => {
    const requestData = {
        email,
        password,
        password_confirmation: confirmPassword,
        token: passwordResetToken
    };

    const queryString = Object.entries(requestData).map(([name, value]) => `${name}=${encodeURIComponent(value)}`).join('&');
    
    
    return fetch(PATH + 'reset-password?' + queryString, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        method: 'POST'
    }).then(response => response.json()).catch(() => ({ message: 'Unknown error' }));
};

export const fetchDataNotAuth = (url, apiMethod, formData) => {
    return fetch(PATH + url, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        method: apiMethod,
        body: JSON.stringify(formData),
    })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(responseJson => responseJson);
};

// formData - {email, password, speakerType}
export const fetchData = (url, apiMethod, formData, token) => {
    return fetch(PATH + url, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: apiMethod,
        body: JSON.stringify(formData),
    })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(responseJson => responseJson);
};
export const fetchFormData = (url, apiMethod, formData, token) => {
    return fetch(PATH + url, {
        headers: {
            // 'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: apiMethod,
        body: formData,
    })
        .then(response => {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        })
        .then(responseJson => responseJson);
};
export const fetchDataGet = (url, apiMethod, token, data) => {
    return fetch(PATH + url, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: apiMethod,
        body: JSON.stringify(data),
    })
        .then(response => {
            const statusCode = response.status;
            let data = {};
            if (statusCode !== 204) {
                data = response.json();
            }
            return Promise.all([statusCode, data]);
        })
        .then(responseJson => responseJson);
};

export const fetchPostCSV = (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    };

    return fetch(PATH + "admin/speakers/export/csv", requestOptions)
        .then(response => {
            const statusCode = response.status;
            const data = response.text();
            return Promise.all([statusCode, data]);
        })
};


export const fetchYouTubeName = (keyVideo) => {
    const YouTubeKey = 'AIzaSyDaZNmZrDWsuNaq9p5l-17OSpS0hzNj-JI'

    return fetch(`https://www.googleapis.com/youtube/v3/videos?part=id%2C+snippet&id=${keyVideo}&key=${YouTubeKey}`, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        method: 'get',
    })
        .then(res => res.json())
};

export const fetchGoogleGeocoding = async (address, apiKey) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
    try {
        let response = await fetch(url);
        let data = await response.json();
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const fetchSpeakersShortlist = token => fetch(PATH + "finder/short-list", {
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }}).then(response => response.json());
    


export const sendEnquiryForm = async (token, formData) => {
    const response = await axios.post(`${PATH}business/enquiry/create`, formData, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }
    });

    return response;
};

export const signUp = async (token, signupData) => {
   return axios.post(`${PATH}register`, signupData, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }
    });
};
