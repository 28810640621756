import React from 'react';
import { useHistory } from 'react-router-dom';
import { GreenLine } from "../../HomePage/GreenLine";
// import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { TopicSlider } from "./TopicSlider";
// import { TopicsSliderMobile } from './TopicsSliderMobile';
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import getFileUrl from '../../helpers/getFileUrl';
import bgImage from '../../assets/green-line_s-home_trending.svg';
import PrimaryButton from '../../_components/buttons/PrimaryButtonLarge';

import styles from './TrendingTopics.module.scss';

const MOBILE_TOPICS_TITLES = [
    'Inspiration and Motivation',
    'Health and wellbeing',
    'Innovation',
    'Leadership',
    'Diversity and inclusion',
    'Future and technology'
];

export const TrendingTopics = ({ data, onSelectTopic, winSize }) => {
    const { width } = useWindowDimensions();
    const history = useHistory();
    const topics = data.map(({ id, name, image }) => ({
        id,
        title: name,
        img: getFileUrl(image)
    }));

    const centerIndex = topics.length >> 1; // divide by 2

    const topicsTop = topics.slice(0, centerIndex);
    const topicsBottom = topics.slice(centerIndex);

    return (
        <div className="s-home__trending">
            <div className="s-home__container">
                <h2 className="s-home__trending-title">
                    Search<br />
                    trending
                    {
                        winSize > 768
                            ? ' topics'
                            : <GreenLine target={' topics'}
                                bgImg={bgImage}
                                scrollPosition={200} moveBottom={'-6px'} customStyles={{ height: '22px' }} />
                    }
                </h2>

                <p className="s-home__trending-text">
                    Explore the most fascinating, relevant and hot topics for discussion around the world and in
                    your location.
                </p>
            </div>

            {width > 768
                ? <>
                    <TopicSlider rtl={false} tags={topicsTop} onSelectItem={onSelectTopic} />
                    <TopicSlider rtl={true} tags={topicsBottom} onSelectItem={onSelectTopic} />
                </>
                :<div className={styles.container}>
                    {topics
                    .filter(({ title }) => MOBILE_TOPICS_TITLES.includes(title))
                    .sort((a, b) => MOBILE_TOPICS_TITLES.indexOf(a.title) - MOBILE_TOPICS_TITLES.indexOf(b.title))
                    .map(item => (
                        <div key={item.title} className={styles.item} onClick={() => onSelectTopic(item.id)}>
                            <h2 className={styles.title}>{item.title}</h2>
                            <img src={item.img} alt="img" className={styles.image} />
                        </div>
                    ))}
                </div>
            }
            <div className="s-home__trending-search-btn-wrapper">
                <PrimaryButton onClick={() => {
                    history.push('/find-speakers');
                }}>search all topics</PrimaryButton>
            </div>

        </div>
    )
};
