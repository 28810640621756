import {useSelector} from "react-redux";
import {useEffect} from "react";

export const useBodyStopScroll = () =>  {
    const body = document.querySelector('body');
    const portal = useSelector(store => store.portal);

    useEffect(() => {
        Object.values(portal).some(el => el === true)
            ? body.classList.add('stop-scroll')
            : body.classList.remove('stop-scroll');
    }, [portal]);
};
