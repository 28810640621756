import { useEffect } from 'react';

const useDescription = content => {
  useEffect(() => {
    if (!content) {
      return;
    }

    const descriptionElement = document.querySelector('meta[name="description"]');

    const previousContent = descriptionElement.content;
  
    descriptionElement.content = content;
  
    return () => {
      descriptionElement.content = previousContent;
    };
  }, [content]);
};

export default useDescription;
