import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import ApiDataProvider from '../api/apiClient';
import { setToken, setUserData } from '../store/actions/profileActions';
import { SET_AUTHENTICATED } from '../store/constants/profileConstants';
import { setChatEmpty } from '../store/actions';

const VerificationEmail = () => {
    const dispatch = useDispatch();
    const { token } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);


  useEffect(() => {
    ApiDataProvider.loginByToken(token)
      .then(result => {
        const { user, token } = result;

        dispatch(setUserData(user));
        dispatch(setToken(token));
        dispatch({
          type: SET_AUTHENTICATED,
          payload: true
        });

        dispatch({
          type: 'RESET_ERRORS'
        });

        dispatch({
          type: 'SET_IS_LOGIN',
          payload: true
        });

        dispatch(setChatEmpty());

        NotificationManager.success(
          'Verification completed',
          'COMPLETED'
        );

        setIsSuccess(true);
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        setIsLoading(false);
      });
    }, []);

    if (isLoading) {
      return <h1 style={{ 'text-align': 'center' }}>Loading...</h1>;
    }

    if (isSuccess) {
      return <Redirect to="/builder-step1" />;
    }

    return <Redirect to="/" />;
};

export default VerificationEmail;
