import React from 'react';
import ContactForm from '../ContactForm';

import s from './styles.module.scss';

const SecondPanel = ({ heading, formHeading }) => {
  return (
    <div className={s.container}>
      <div className={s.content}>
        <h2>
          {heading}
        </h2>

        <p className={s.firstParagraph}>
          Finders Speakers is the trusted speaker booking destination making it easier,
          faster&nbsp;&&nbsp;more cost effective to find & book talent for events.
        </p>

        { /*<ul className={s.bullets}> */}
        <ul className={s.icons}>
          <li>Keynote speakers, MCs & trainers</li>

          <li>Hundreds of stars to choose from</li>

          <li>Instant speaker recommendations</li>

          <li>Support from our event experts</li>

          <li>Easy & fast booking process</li>

          <li>No obligation, admin or service fees</li>
        </ul>

        <p className={s.pink}>Ready to find your next speaker?</p>

        <p>
          To get started send us your brief or call our friendly team on 1300 112 499.
        </p>
      </div>

      <div className={s.formWrapper}>
        <ContactForm heading={formHeading} />
      </div>
    </div>
  );
};

export default SecondPanel;
