import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import {
    CDataTable, CPagination,
} from "@coreui/react";
import qs from 'qs';
import {Tag} from "antd";

import {DropdownMultiSelect} from './DropdownMultiSelect'
import {fetchDataGet, fetchPostCSV} from "../../api/helperFunctions";
import {useDispatch, useSelector} from "react-redux";
import {initialFilterOptions} from "../../helpers/constants";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DoneIcon from '@material-ui/icons/Done';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ApiDataProvider from '../../api/apiClient';
import {AgreeModal} from '../../_components/AgreeModal/AdminAgreeModal';
import DownloadButton from '../components/DownloadButton';
import ConfirmationModal from '../components/ConfirmationModal';
import {useBodyStopScroll} from "../../helpers/useBodyStopScroll";
import { usePageParam } from '../hooks';

const verifySpeaker = (token, id) => {
    return fetchDataGet(
      `admin/speakers/${id}/verify`,
      'post',
      token
    ).then((res) => {
        if (res[0] === 200) {
            return true;
        } else if (res[0] === 404) {
            throw new Error('Failed to verify speaker: Speaker not found');
        } else if (res[0] === 401) {
            throw new Error('Failed to verify speaker: Authentication required');
        } else {
            throw new Error('Failed to verify speaker');
        }
    });
};

const deleteSpeaker = (token, id) => {
    return fetchDataGet(
      `admin/speakers/${id}`,
      'delete',
      token
    ).then((res) => {
        if (res[0] === 204) {
            return true;
        } else if (res[0] === 404) {
            throw new Error('Failed to delete speaker: Speaker not found');
        } else if (res[0] === 401) {
            throw new Error('Failed to delete speaker: Authentication required');
        } else {
            throw new Error('Failed to delete speaker');
        }
    });
};

const fetchSpeakers = (token, page, sortField, sortOrder) => {
    const queryParams = { page, sort: sortField, order: sortOrder };
    return fetchDataGet(`admin/speakers?${qs.stringify(queryParams)}`, 'get', token);
};

const formatDate = date => {
    return date ? `${moment.unix(date).format('MM/DD/YYYY HH:mm')}` : '';
};


const useConfirmationModal = token => {
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [action, setAction] = useState(() => {});

    const startVerifySpeaker = (speakerId, speakerName) => {
        setText(`Verify ${speakerName}'s email?`);
        setIsOpen(true);
        setErrorMessage('');
        setAction(() => () => verifySpeaker(token, speakerId).then(() => {
            setIsOpen(false);
            return true;
        }).catch(error => setErrorMessage(error.message)));
    };

    const startDeleteSpeaker = (speakerId, speakerName) => {
        setText(`Delete speaker ${speakerName}?`);
        setIsOpen(true);
        setErrorMessage('');
        setAction(() => () => deleteSpeaker(token, speakerId).then(() => {
            setIsOpen(false);
            return true;
        }).catch(error => setErrorMessage(error.message)));
    };

    const close = () => setIsOpen(false);

    return [isOpen, close, text, errorMessage, action, startVerifySpeaker, startDeleteSpeaker];
};

export const AdminPanelSpeakers = () => {
    const [page, setPage] = usePageParam();
    const [status, setStatus] = useState(null);
    const [messageValue, setMessageValue] = useState('');
    const user = useSelector(state => state.user);
    const token = user.token;
    const [speakerList, setSpeakerList] = useState([]);
    const [lastPage, setLastPage] = useState();
    const [total, setTotal] = useState();

    const [sortField, setSortField] = useState('full_name');
    const [sortOrder, setSortOrder] = useState('desc');


    const [
        isConfirmationModalOpen,
        closeConfirmationModal,
        confirmationModalText,
        confirmationModalError,
        confirmationHandler,
        onVerifySpeaker,
        onDeleteSpeaker
    ] = useConfirmationModal(token);

    const updateSpeakerList = () => {
        fetchSpeakers(token, page, sortField, sortOrder).then(response => {
            if (response[0] === 200) {
                setSpeakerList(response[1].data);
                setLastPage(response[1].meta.last_page);
                setTotal(response[1].meta.total);
            }
        }).catch(e => console.error(e));
    };

    useEffect(() => {
        updateSpeakerList();
    }, [page, sortField, sortOrder]);

    const history = useHistory();
    const dispatch = useDispatch();
    const isAgreeOpen = useSelector(state => state.portal.agreeModal);
    const [modalError, setModalError] = useState('')
    const [modalContent, setModalContent] = useState({title: '', button: '', placeholder: '', hideTextArea: false, hideText: false})
    useBodyStopScroll();

    const searchInput = document.querySelector('[aria-label="table filter input"]');
    searchInput && searchInput.setAttribute('placeholder', 'Search');

    const fields = [
        {key: 'full_name', label: 'Speaker Full Name', _style: {width: '20%'}},
        {key: 'last_login_at', label: 'Date Time Last Login', _style: {width: 'auto'}},
        {key: 'submitted_at', label: 'Submit Date', _style: {width: 'auto'}},
        {
            key: 'id',
            label: 'Speaker Profile',
            _style: {width: '10%'},
            sorter: false,
            filter: false,
        },

      /*
        {
            key: 'bookings_amount',
            label: 'Bookings Amount',
            _style: {width: '10%'},
        },
       */
        {key: 'status', label: 'Status', _style: {width: '10%'}},
        {key: 'trending', label: 'Trending', _style: {width: '10%'}, sorter: false},
        {key: 'actions', label: 'Actions', _style: {width: '180px'}},
        {key: 'verify', label: '', _style: { width: '40px' }},
        {key: 'edit', label: '', _style: { width: '40px' }},
        {key: 'account', label: '', _style: { width: '40px' }},
        {key: 'delete', label: '', _style: { width: '40px' }}
    ];

    const getBadge = (status) => {
        switch (status) {
            case 2:
                return {color: '#3B86FF80', value: 'Active'};
            case 3:
                return {color: '#F2597380', value: 'Rejected'};
            case 1:
                return {color: '#FFF70080', value: 'Pending'};
            case 4:
                return {color: '#4D4F5C', value: 'Incomplete'};
        }
    }

    const dropdownData = [
        {id: 2, value: 2, label: 'Approve'},
        {id: 3, value: 3, label: 'Decline'},
        {id: 1, value: 1, label: 'Pending'},
    ];

    const changeSortParams = value => {
        if (value.column) {
            setSortField(value.column);
        }

        setSortOrder(value.asc === true ? 'asc': 'desc');
    };

    const handlerDownloadCSV = () => {
        return fetchPostCSV(user.token)
            .then(res => {
                const blob = new Blob([res[1]], {type: 'application/csv'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "result.csv";
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
            })
            .catch(error => {

                    return Promise.reject(error);
                }
            )
    }
    const handleChange = () => {
        return fetchDataGet(
            `admin/speakers/${status.id}/status`,
            'post',
            user.token,
            {status: status.value, message: messageValue}
        ).then((res) => {
            if (res[0] === 200) {
                updateSpeakerList();
                return true;
            }
            setModalError(res[1].errors.status);
            return false;
        }).catch(() => {
            return false;
        }).finally(() => {
            setMessageValue('')
        })
    };

    const setSelectedStatus = (id, value) => {
        setStatus({id, value});
        const setText = (status) => {
            switch (status) {
                case 2: return ({
                    title: 'Are you sure want to approve this speaker?',
                    button: 'Approve',
                    placeholder: 'Add optional message with reason for approving the speaker',
                    hideTextArea: true
                });
                case 3: return ({
                    title: 'Are you sure want to reject this speaker?',
                    button: 'Decline',
                    placeholder: 'Add optional message with reason for declining the speaker',
                });
                case 1: return ({
                    title: 'Are you sure want to make the speaker pending?',
                    button: 'Make Pending',
                    placeholder: 'Add optional message with reason for pending the speaker',
                    hideTextArea: true,
                    hideText: true
                });
            }
        };
        setModalContent(setText(value));
    };

    const toggleTrending = (isChecked, speakerId) => {
        ApiDataProvider.changeSpeakerTrendingAdmin(speakerId, isChecked).then(() => {
            setSpeakerList(list => list.map(speaker => speaker.id === speakerId ? ({
                ...speaker,
                trending: isChecked
            }) : speaker));
        }).catch(e => {
            console.error(e);
        });
    };

    return (
        <SpeakerStyles>
            <h1 className="title">Speakers Management</h1>
            <div className="table__wrapper">
                <div style={{
                    textAlign: 'right',
                    paddingTop: '29px',
                    paddingRight: '273px'
                }}>
                    <DownloadButton onClick={handlerDownloadCSV} />
                </div>
                <CDataTable
                    items={speakerList}
                    fields={fields}
                    onSorterValueChange={value => changeSortParams(value)}
                    tableFilter
                    hover
                    sorter
                    scopedSlots={{
                        'last_login_at':
                            (item) => (
                                <td>
                                    {formatDate(item['last_login_at'])}
                                </td>
                            ),
                        'submitted_at': item => (
                            <td>
                                {formatDate(item['submitted_at'])}
                            </td>
                        ),
                        'status':
                            (item) => (
                                <td className={item.status === 4 ? "td__white" : ""}>
                                    <Tag color={getBadge(item.status).color}>{getBadge(item.status).value}</Tag>
                                </td>
                            ),
                        'trending': item => (<td>
                            <Switch
                              checked={item.trending}
                              onChange={e => toggleTrending(e.target.checked, item.id)}
                            />
                        </td>),
                        'actions':
                            (item) => {
                                return (
                                    <td className="py-2">
                                        {item.status !== 4 &&
                                        <DropdownMultiSelect
                                            itemStatus={item.status}
                                            data={dropdownData}
                                            id={item.id}
                                            setOption={setSelectedStatus}
                                        />
                                        }
                                    </td>
                                )
                            },
                        'id':
                            (item) => {

                                const fullName = item.full_name.toLowerCase().replace(/\s/g, '');

                                return (
                                    <td className="py-2">
                                        <Link
                                            type="primary"
                                            target="_blank"
                                            to={`/speakers/${item.id}/${fullName}`}
                                            className="ant-btn ant-btn-primary"
                                        >
                                            View profile
                                        </Link>
                                    </td>
                                )
                            },
                        'verify': item => (
                          <td>{ item.is_verified ?
                            <span title={'Verified'}><DoneIcon /></span> :
                            <span
                              style={{
                                  cursor: 'pointer'
                              }}
                              title={'Verify email'}
                              onClick={() => onVerifySpeaker(item.id, item.full_name)}
                            >
                              <ThumbUpIcon/>
                            </span>
                          }</td>
                        ),
                        'edit': item => (<td>
                            <a
                              target="_blank"
                              href={`/admin/builder-step1/${item.id}`}
                              title="Edit profile"
                            ><EditIcon /></a>
                        </td>),
                        'account': item => (<td>
                          <Link to={`/admin-panel/speakers/${item.id}`}>
                              <span title="Edit account details">
                                  <AccountBoxIcon />
                              </span>
                          </Link>
                        </td>),
                        'delete': item => (
                          <td>
                              <span
                                style={{
                                    cursor: 'pointer'
                                }}
                                title={'Delete'}
                                onClick={() => onDeleteSpeaker(item.id, item.full_name)}
                              >
                                  <ClearIcon />
                              </span>
                          </td>
                        )
                    }}
                />

            </div>
            {total > 10 &&
            <CPagination
                activePage={page}
                pages={lastPage}
                onActivePageChange={i => setPage(i)}
            />
            }
            {isAgreeOpen &&
            <AgreeModal
                className={'status__confirmation'}
                isAgree={handleChange}
                clearErrors={() => setModalError('')}
                cancelButtonText="Back to speakers list"
                agreeButtonText={modalContent.button}
            >
                <p className="title">
                    {modalContent.title}
                </p>
                {!modalContent.hideText && <p className="text">
                    If you proceed, then auto generated message will be emailed directly to the speaker.
                </p>}
                {!modalContent.hideTextArea && <textarea
                    className="message"
                    placeholder="Add optional message with reason for approving the speaker"
                    placeholder="Add optional message with reason for declining the speaker"
                    placeholder={modalContent.placeholder}
                    name="message"
                    onChange={(e) => setMessageValue(e.target.value)}
                    value={messageValue}
                    id="id"
                />}
                {modalError && <span className="text error-text">{modalError}</span>}
            </AgreeModal>
            }
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              text={confirmationModalText}
              errorMessage={confirmationModalError}
              onConfirmClick={() => confirmationHandler().then(result => {
                  if (result) {
                      updateSpeakerList();
                  }
              })
              }
              onCancelClick={closeConfirmationModal}
            />

        </SpeakerStyles>
    )
}

const SpeakerStyles = styled.div`
  text-align: center;
    
  position: relative;
    .title {
        text-align: left;
        font-size: 40px;
        color: #43425D;
        opacity: 1;
    }

    .table-responsive {
        padding: 35px 30px 30px;
        background: #fff;
        margin-bottom: 30px;
    }


  & > div > .row {
      position: absolute;
      top: 90px;
      right: 0;
      display: block;
      width: 260px;
      z-index: 1;

      .col-sm-6 {
          width: 100% !important;
          margin: 0 !important;
          flex-basis: 100%;
          max-width: 100%;

      }
  }
  .td__white .ant-tag {
    color: #fff;
  }
  .ant-tag  {
      color: #212529;
      display: inline-block;
      width: 100px;
  }
  .table__wrapper {
      position:relative;
      background: #fff;

      .form-inline {
          width: 230px;
          position:absolute;
          top: 30px;
          right: 30px;
          z-index: 2;

          &.p-0 {
            label.mr-2 {
              display: none;
            }
          }
      }


  }
  .csv__btn {
      position: absolute;
      top: 30px;
      right: 280px;
      z-index: 2;
      border-radius: 4px;
      border: none;
      background: #43425D;
      color: #fff;
      height: 46px;
      padding: 0 20px 0 20px;

      &:active,
      &:focus {
        outline: none;
      }

      svg {
        display:inline-block;
        margin-left: 10px;
        path {
          fill: #fff;
          transition: opacity 0.3s ease-in-out;
        }
      }

      &:hover {
        path {
          opacity: 0.5;
        }
      }
  }
`