import React, {useState} from "react";
import styles from "../../BookingForm.module.scss";
import Tooltip from "@material-ui/core/Tooltip";
import {ReactComponent as Mark} from "../../../../assets/question_mark.svg";
import {ReactComponent as Add} from "../../../../assets/add.svg";
import TextInput from '../../components/TextInput';
import MoneyInput from '../../components/MoneyInput';
import MoneyFormat from '../../components/MoneyFormat';

export default function BookingFormSpeakerIncrementals({ data, onAdd, onDelete, disabled }) {
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState();
  const [inputsVisible, setInputsVisible] = useState(false);
  const [descriptionError, setDescriptionError]= useState('');
  const [amountError, setAmountError]= useState('');

  const changeDescription = value => {
    setDescription(value);

    setDescriptionError('');
  };

  const changeAmount = value => {
    setAmount(value);

    setAmountError('');
  };

  const addIncremental = () => {
    if (!description.trim() || !amount) {
      if (!description.trim()) {
        setDescriptionError('Field must not be empty');
      }

      if (!amount) {
        setAmountError('Must be non-zero');
      }

      return;
    }

    onAdd(description, amount);
    setDescription('');
    setAmount(null);
    setInputsVisible(false);
  };

  const cancelIncremental = () => {
    setDescription('');
    setAmount(null);
    setInputsVisible(false);
  };

  return (
    <div>
      <div className={styles.incrementalsArea}>
        <div>
          <span>Incrementals</span>
          <span className={styles.aud}>(AUD)</span>
          <Tooltip title="Add agreed add on expenses such as travel and accomodation" aria-label="add">
            <Mark className={styles.markIcon}/>
          </Tooltip>
        </div>
        <div>
          {
            data.length === 0 && !inputsVisible && !disabled &&
            <div onClick={() => setInputsVisible(true)}>
              <Add style={{cursor: 'pointer'}} className={styles.addIcon} />
              <span
                className={styles.offer_list_roll_incremental_add}
              >
                Add incrementals
              </span>
            </div>
          }
        </div>
      </div>
      <div>
        {data.map((v, i) => (
          <div
            key={i}
            style={{
              paddingTop: '10px'
            }}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '10px'
              }}>
                <div >
                  <span>{v.description}</span>
                </div>
                <div>
                  <span style={{fontWeight: '600', color: 'black'}}><MoneyFormat value={v.amount} /></span>
                </div>
              </div>
              {
                !disabled && (
                  <button
                    className={styles.deleteButton}
                    onClick={() => onDelete(i)}
                  >
                    Delete
                  </button>
                )
              }
            </div>
          </div>
        ))}
        {
          inputsVisible && <div>
            <div className={styles.incrementalsInputs}>
              <div className={styles.incrementalsInputDescription}>
                <TextInput
                  value={description}
                  onChange={changeDescription}
                  error={descriptionError}
                />
              </div>
              <div className={styles.incrementalsInputAmount}>
                <MoneyInput value={amount} onChange={changeAmount} error={amountError} />
              </div>
            </div>
            <div className={styles.incrementalsButtonsGroup}>
              <button
                className={styles.checkButton}
                onClick={addIncremental}>
                Add
              </button>
              <button
                className={styles.cancelButton}
                onClick={cancelIncremental}
              >
                Cancel
              </button>
            </div>
          </div>
        }
        {
          (data.length > 0 && !inputsVisible) && !disabled &&
          <div style={{
            marginTop: '10px'
          }}>
            <Add style={{cursor: 'pointer'}} className={styles.addIcon} />
            <span
              className={styles.offer_list_roll_incremental_add}
              onClick={() => setInputsVisible(true)}
            >
            Add another
          </span>
          </div>
        }
      </div>
    </div>
  );
};