import React from 'react';
import { FAQItem } from "../FAQPage/FAQTabs/FAQItem";
import styled from 'styled-components';
import { FAQHero } from "../FAQPage/FAQHero";
import { AnimatedOnScroll } from 'react-animated-css-onscroll'

const PrivacyPolicy = () => {

  const policyText = [
    {
      id: 1,
      question: 'When do we collect your personal information?',
      answer: (<Item>
        <p>At this site, we only collect personal information that is necessary for us to conduct our business as a
        professional services marketplace directly contacting speaking talent with businesses in furtherance of our
        legitimate interests. Personal information is any information that could be reasonably used to identify you
        personally. The amount and type of information that Finders Speakers collects depends on the nature of the
          interaction.</p>
        <p>We collect personal information in a number of ways, including: </p>
        <ul>
          <li>When you visit and interact with the site.</li>
          <li>When businesses or speakers sign up for a client account, submit a contact form, download content from our
          site, request a demo or sign up for a newsletter
          </li>
          <li>When you make or receive a payment via our global third-party payment provider</li>
          <li>When you submit your application to join the site as a speaker</li>
          <li>When you chat with speakers via the online chat functionality</li>
          <li>When online contracts are formed to confirm the booking</li>
          <li>When speakers are rated and reviewed.</li>
          <li>Through cookies and other technologies that allow us to give you a better experience on the site</li>
          <li>When you contact us independently of the website, such as by email, phone or in person</li>
        </ul>
        <p>Finders Speakers does not disclose your personal information other than as described below. Visitors can
        always refuse to supply personal information although it may prevent you from engaging in certain
        website-rated activities and we may not be able to provide you with access to Finders Speakers and our
          services or do business with you or your organisation</p>
      </Item>)
    },
    {
      id: 2,
      question: 'What personal information do we collect from you?',
      answer: (<Item>
        <h4 style={{ fontSize: '18px' }}>Overview</h4>
        <p>We try only to collect personal information about you that we need to set up your account and for the
        operation of the Finders Speakers site and to market and promote Finders Speakers and our services. The kind
        of personal information we collect and hold about you may depend on when the information is collected. For
          example:</p>
        <p>We may collect personal information that you provide to us when you:</p>
        <p>Use this site, including (without limitation) when you:</p>
        <ul>
          <li>Complete an online contact form to contact us.</li>
          <li>Register for an account including basic account information such as your name, email address, and username
          in order to use this site, so that we can create your account, contact you about your account and so that
          you can access your data from any device. For example:
            <ul>
              <li>If you are registered on Finders Speakers as a SPEAKER, then we collect information about you that
              will include your name, contact details, email, password, qualifications and experience, and other
              information that you may wish to complete as part of Finders Speakers functionality and profile builder
              tool. Profile information might include a photo, biographical information, descriptions of speaking
              topics, videos, multimedia recommendations by others,
              </li>
              <li>If you are registered on Finders Speakers as a BUSINESS (or Finder), then we collect information about
              you that will include your name, company, contact details, password and other information you may wish
              to complete using the functionality available via Finders Speakers.
              </li>
            </ul>
          </li>
          <li>Add reviews, forum or chat room messages or comments in any elements of this site that permit
          user-generated content. Any information that you submit or post to these blogs or other forums can be viewed
          by other viewers or may otherwise be publicly available. We are not responsible for the personal information
          you choose to submit in these blogs and other forums, so please be careful about what you disclose and do
          not post any information that you expect to keep private. If you would like to request removal of your
          information from our blogs or other forums, please contact us at [ ]
          </li>
          <li>For billing purposes we require you to provide your full name and billing address. We do not store credit
          card or bank information. Payment is made through our global third party provider Stripe who use Payment
          Card industry compliant third – party payments services. Stripes privacy policy at
          http://stripe.com/en-be-privacy applies to your disclosure of personal information to them;
          </li>
          <li>
            Other site users may share information with you using your contact information (such as email or phone
            number), and that information will be stored in the course of using this site.
          </li>
        </ul>
        <p>When you contact us directly:</p>
        <ul>
          <li>Provide information to us by telephone; or</li>
          <li>send us an email or other communication.</li>
        </ul>
        <p>In order for us to successfully do business with you it will not, in most circumstances, be possible for us
        to deal with you without you providing relevant personal information to us. However, where it is lawful and
          practicable to do so, you may deal with us anonymously or by using a pseudonym.</p>
        <h4 style={{ fontSize: '18px' }}>IP addresses</h4>
        <p>This site may also collect Internet Protocol (IP) addresses. We collect and manage IP addresses as part of
        the service of providing internet session management and for security purposes. We may also collect and use
        web log, computer, and connection information for security purposes and to help prevent and detect any misuse
          of, or fraudulent activities involving, this site.</p>
      </Item>)
    },
    {
      id: 3,
      question: 'How do we use the information we collect?',
      answer: (<Item>
        <p>We use your personal information to collect and receive information to operate our site, provide
          our</p> services and fulfill our obligations pursuant to our Website Terms of Use and Speaker Terms of
        Service.
        <h4 style={{ fontSize: '18px' }}>To establish, maintain and administer your account</h4>
        <p>We use information to provide our services to you including to process transactions with you,
          authenticate</p> when you log in, provide customer support and maintain the Finders Speakers platform
        <h4 style={{ fontSize: '18px' }}>To deliver services to you</h4>
        <p>Your personal information will be used by us to:</p>
        <ul>
          <li>Enable Finders and Speakers to create a user profile and use the platform</li>
          <li>Conduct verifications of profiles, checking credentials, monitoring, and performing as permitted in the
          Website Terms of Use or under any applicable laws
          </li>
          <li>Respond to any queries or feedback that you may have;</li>
          <li>To communicate via email or phone calls to share job alerts, provide customer support and to send you
          technical notices, platform updates, security alerts and administrative messages. These communications are
          part of our services.
          </li>
          <li>Conduct research and development in respect of our services;</li>
          <li>Share your speaker profile information publicly by displaying your marketing profile on this site;</li>
          <li>Allow Finders and Speakers to contact each other</li>

          <li>Gain an understanding of your information and communication needs or obtain your feedback or views about
          our services in order for us to improve them; and/or
          </li>
          <li>Prevent and detect any misuse of, or fraudulent activities involving, this site.</li>
        </ul>
        <h4 style={{ fontSize: '18px' }}>To promote and marketing Finders Speakers</h4>
        <ul>
          <li>we may email you with news, information and offers relating to our own services or those of selected
          industry partners. Your personal information may also be collected so that we can promote and market
          products and services to you. This is to keep you informed of products, services, and special offers we
          believe you will find valuable and may continue after you cease acquiring services from us. If you would
          prefer not to receive promotional or other material from us, please let us know and we will respect your
          request. You can unsubscribe from such communications at any time if you choose.
          </li>
          <li>You will receive direct marketing communications from us if you have not opted out from receiving
          marketing communications. We may contact you to let you know about new speakers, opportunities, features,
          how they may benefit you and we may ask for your feedback or just keep you up to date with Finders Speakers
          </li>
          <li>We may send you information via email, SMS or other electronic means with your explicit consent when
          signing up to the platform
          </li>
          <li>You may opt out from receiving communications at any time in the account settings or using the unsubscribe
          button on emails
          </li>
        </ul>
        <h4 style={{ fontSize: '18px' }}>To maintain and improve our business systems and infrastructure, including testing and upgrading of these
          systems,</h4>
        <p>We use data, analytics and feedback provided directly to enable us to trouble shoot, identify trends and
        usage patterns to identify way which we can improve our website, products and services, marketing, client
          relationships and experiences</p>
        <p>For any other purpose reasonably considered necessary or desirable to pursue our legitimate interests in
          relation to the operation of our business.</p>

      </Item>)
    },
    {
      id: 4,
      question: 'How do we protect the information we collect?',
      answer: (<Item>
        <p>We are committed to maintaining the confidentiality and security of the information that you provide us, and
        we will take all reasonable precautions to protect the storage and disclosure of personal information from
        unauthorised use or alteration.
        The data you provide to us is protected using SSL (Secure Socket Layer) technology. SSL is the industry
        standard method of encrypting personal information and credit card details so they can be securely transferred
        over the Internet
        In our business, personal information will be stored electronically (on our computer systems and with our
        website hosting provider). Firewalls, anti-virus software and email filters, as well as passwords, protect all
        of our electronic information.
        While Finders Speakers continues to work hard to protect your personal information, access to the site is
        through the internet and the internet is inherently insecure, therefore we cannot provide any guarantees
        regarding the security of transmission of personal information when you communicate to us online. Any
          information sent to us online is transmitted at your own risk.</p>
      </Item>)
    },
    {
      id: 5,
      question: 'How do we access your personal information?',
      answer: (<Item>
        <p>You may review, edit or correct or delete any personal information you submit to us any time. You just need
          to log into your account to do this.</p>
        <p>You may also access personal information that we hold about you. In some cases, we may refuse to give you
        access to personal information that we hold about you and we will provide you with reasons for our refusal.
          This may include circumstances where giving you access would:</p>
        <ul>
          <li>be unlawful;</li>
          <li>have an unreasonable impact on another person’s privacy;</li>
          <li>or prejudice an investigation of unlawful activity.</li>
        </ul>
        <p>All requests for access to your personal information other than that provided on your account must be
        directed to us by email using the email address [ ]. We will deal with all requests for access to personal
        information as quickly as possible. Requests for a large amount of information, or information not in use, may
        require further time before we can respond. We may charge you a fee for access if excessive cost is incurred
        by us in order to retrieve your information, but in no case will we charge you a fee for your application for
          access. </p>
      </Item>)
    },
    {
      id: 6,
      question: 'When we may disclose your personal information?',
      answer: (<Item>
        <p>Your personal information may be disclosed to third parties who provide services to us such a supporting and
        improving this site, processing payment, or contract management. These organisations will only have
        information necessary to perform these limited functions on your behalf. We take reasonable steps to ensure
        that these organisations are bound by confidentiality and privacy obligations in relation to the protection of
          your personal information.</p>
        <p>In addition, we may disclose your personal information to:</p>
        <ul>
          <li>your authorised representatives or legal advisers (when requested by you to do so);</li>
          <li>our professional advisers, including our accountants, auditors and lawyers;</li>
          <li>government and regulatory authorities and other organisations, as required or authorised by law;</li>
          <li>organisations who manage our business strategies, including those involved in a transfer/sale of all or
          part of our assets or business and those involved in managing our business risk and funding functions;
          </li>
          <li>and the police or other appropriate persons where your communication suggests possible illegal activity or
          harm to others.
          </li>
        </ul>
      </Item>)
    },
    {
      id: 7,
      question: 'Third party websites',
      answer: (<Item>
        <p>You may click-through to third party websites from this site, in which case we recommend that you refer to
        the privacy policy of the websites you visit. This Privacy Policy applies to this site only and we assume no
          responsibility for the content of any third-party websites.</p>
        <h4 style={{ fontSize: '18px' }}>Re-marketing</h4>
        <p>We may use the Google AdWords and/or Facebook re-marketing services to advertise on third party websites to
        previous visitors to this site based upon their activity on this site. This allows us to tailor our marketing
        to better suit your needs and to only display advertisements that are relevant to you. Such advertising may be
        displayed on a Google search results page or a website in the Google Display Network or inside Facebook.
        Google and Facebook may use cookies and/or pixel tags to achieve this. Any data so collected by Google and/or
        Facebook will be used in accordance with their own respective privacy policies. None of your personal Google
        and/or Facebook information is reported to us.
        You can set preferences for how Google advertises to you using the Google Ads Settings page
          (<a href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a>). Facebook has enabled
          an AdChoices link that enables you to opt out of
          targeted advertising.</p>
        <h4 style={{ fontSize: '18px' }}>Storage and processing by third parties</h4>
        <p>Data that we collect about you may be stored or otherwise processed by third party services with data centres
        based outside Australia, such as Digital Ocean.com, Stripe. Google Analytics, Microsoft Azure, Amazon Web
        Services, Apple, etc and online relationship management tools. We consider that the collection and such
        processing of this information is necessary to pursue our legitimate interests in a way that might reasonably
        be expected (e.g., to analyse how our clients use our services, develop our services and grow our business)
          and which does not materially impact your rights, freedom or interests.</p>
        <p>We require that all third parties that act as “data processors” for us provide sufficient guarantees and
        implement appropriate technical and organisational measures to secure your data, only process personal data
        for specified purposes and have committed themselves to confidentiality and if applicable the Standard
          Contractual Clauses if not in the EU.</p>
      </Item>)
    },
    {
      id: 8,
      question: 'What is the Cookies Policy?',
      answer: (<Item>
        <p>Like most sites, Finders Speakers uses "cookies" to help personalise your online experience. Cookies are
        identifiers that we may transfer to your computer through your browser to enable us to recognize your browser.
        Cookies tell us how and when pages on our site are visited and by how many people. Many of these cookies are
        removed or cleared when you log out but some may remain so that your preferences are remembered for future
          sessions. </p>
        <p>These cookies do not collect personal information. We do not combine the information collected through
        cookies with other personal information to further identify you. You can configure your internet browser to
        accept all cookies, reject all cookies or notify you when a cookie is sent. However, if you choose to decline
        cookies, you may not be able to fully experience the interactive features of this site (including it
        remembering details of data you choose to submit to us) and allow us to understand your preferences which
          features you prefer.</p>
        <h4 style={{ fontSize: '18px' }}>Third party cookies</h4>
        <p>In some cases, third parties may place cookies through this site. For example:</p>
        <ul>
          <li>Google Analytics, one of the most widespread and trusted website analytics solutions, may use cookies
          de-identified data about how long users spend on this site and the pages that they visit;
          </li>
          <li>Google AdSense, one of the most widespread and trusted website advertising solutions, may use cookies to
          serve more relevant advertisements across the web and limit the number of times that a particular
          advertisement is shown to you;
          </li>
          <li>And third party social media applications (e.g., Facebook, Twitter, LinkedIn, Pinterest, YouTube,
          Instagram, etc) may use cookies in order to facilitate various social media buttons and/or plugins in this
          site.
          </li>
        </ul>
      </Item>)
    },
    {
      id: 9,
      question: 'What are the requirements of GDPR?',
      answer: (<Item>
        <p>The requirements of the GDPR are broadly similar to those set out in the Privacy Act and include the
          following rights for EU residents:</p>
        <ul>
          <li>you are entitled to request details of the information that we hold about you and how we process it. For
          EU residents, we will provide this information for no fee;
          </li>
          <li>you may also have a right to:
            <ul>
              <li>have that information rectified or deleted;</li>
              <li>restrict our processing of that information;</li>
              <li>stop unauthorised transfers of your personal information to a third party;</li>
              <li>in some circumstances, have that information transferred to another organisation;</li>
              <li>and lodge a complaint in relation to our processing of your personal information with a local
              supervisory authority;
              </li>
            </ul>
          </li>
          <li>and where we rely upon your consent as our legal basis for collecting and processing your data, you may
          withdraw that consent at any time.
          </li>
          <li>where we rely upon your consent as our legal basis for collecting and processing your data, you may
          withdraw that consent at any time.
          </li>
        </ul>
        <p>If you object to the processing of your personal information, or if you have provided your consent to
        processing and you later choose to withdraw it, we will respect that choice in accordance with our legal
          obligations. However, please be aware that:</p>
        <ul>
          <li>such objection or withdrawal of consent could mean that we are unable to provide our services to you, and
          could unduly prevent us from legitimately providing our services to other clients subject to appropriate
          confidentiality protections;
          </li>
          <li>and even after you have chosen to withdraw your consent, we may be able to continue to keep and process
          your personal information to the extent required or otherwise permitted by law, in particular:
            <ul>
              <li>to pursue our legitimate interests in a way that might reasonably be expected as part of running our
              business and which does not materially impact on your rights, freedoms or interests; and
              </li>
              <li>in exercising and defending our legal rights and meeting our legal and regulatory obligations.</li>
            </ul>
          </li>
        </ul>
      </Item>)
    },
    {
      id: 10,
      question: 'What is the retention duration of your data',
      answer: (<Item>
        <p>We will only keep your data for as long as is necessary for the purpose for which it was collected, subject
        to satisfying any legal, accounting or reporting obligations. If you are not active on our site and are not
        opening communications from us for 2 years, your data will either be deleted completely or anonymised (for
        example, by aggregation with other data so that it can be used in a non-identifiable way for statistical
        analysis and business planning). In some circumstances, you can ask us to delete your data.
        </p>
      </Item>)
    },
    {
      id: 11,
      question: 'Changes to this Privacy Policy',
      answer: (<Item>
        From time to time, it may be necessary for us to revise this Privacy Policy. Any changes will be in accordance
        with any applicable requirements under the Privacy Act and the Australian Privacy Principles or other applicable
        data protections laws. We may notify you about changes to this Privacy Policy by posting an updated version on
        this site. If you object to any of the changes to this Privacy Policy, you should stop using this site and
        delete your account.

      </Item>)
    },
    {
      id: 12,
      question: 'How to contact us?',
      answer: (<Item>
        <p>Please contact us at our offices via the contact details provided if:</p>
        <ul>
          <li>You want to obtain more information about the way we manage personal information that we hold about you or
          you wish to raise a concern or complaint regarding how we collect or handle your personal information or are
          concerned that we may have breached your privacy, please contact us by email to hello@findersspeakersco.com.
          </li>
          <li>If you believe that any personal information held by us is inaccurate, incomplete or out of date. You can
          go in and manage your account and we will amend any personal information about you that is held by us if you
          request us to do so. If we disagree with your view about the accuracy, completeness or currency of a record
          of your personal information that is held by us, and you ask us to associate with that record a statement
          that you have a contrary view, we will take reasonable steps to do so.
          </li>
          <li>unsubscribe from any Finders Speakers mailing list or have any questions or complaints regarding
          unsolicited electronic communications that you may have received or are concerned about.
          </li>
        </ul>
      </Item>)
    }
  ]

  return (<>
    <FAQHero title={'Privacy Policy'} subHeader={<Text color={'#fff'}>Last updated 11.01.2021</Text>} />
    <PrivacyContainer className={'container'}>
        <h4>INTRODUCTION</h4>
        <Text>
          At Finders Speakers Pty Ltd (ACN 630 096 730) ("we" or "us") we understand the importance of keeping your
          personal information private and secure. This privacy policy ("Privacy Policy") demonstrates our commitment to
          your privacy protection when you are using our online services and platform and all associated domains,
          databases,
          related mobile applications, services, software and backend systems at <a
            href="http://findersspeakersco.com">http://findersspeakersco.com</a> ("this
          site")
          and sets out our practices with the respect to the handling of your personal information and safeguarding your
          privacy when you interact with us.
        </Text>

      <Text>By signing up to Finders Speakers, using our site or any of our
        services or otherwise providing us with your
        personal information (or authorising it to be used by someone else), you agree to your personal information
        being
        handled as set out in this Privacy Policy which forms part of, and is subject to the provisions of, our
        Website
          Terms of Use (<a href="https://findersspeakersco.com/terms-conditions" target="_blank">https://findersspeakersco.com/terms-conditions</a>).
        </Text>



          <h4>The Australian Privacy Principles</h4>
          <Text>We are based in Sydney, Australia and will treat all personal information in accordance with all
          obligations
          that are binding upon us under the Privacy Act 1988 (Cth) (“Privacy Act”). The Privacy Act lays down 13
          key
          principles in relation to the collection and treatment of personal information, which are called the
                “Australian Privacy Principles”.</Text>
          <Text>If you are resident in the EU we are the controller of your personal data for the purposes of the data
          handling regime laid out in the General Data Protection Regulation (“GDPR”) which applies to any
          personal
                information of data subjects in the European Union (“EU”)</Text>
          <Text>This site is designed for an adult audience and is not aimed at children under the age of 18. We do not
          knowingly collect personally identifiable information from children under 18. If a parent or guardian
          becomes
          aware that their child has provided us with personal information without their consent, they should
          contact
                us. </Text>

        <FAQItem
          tabsData={policyText}
        />

        <Text>If you require any further information about the Privacy Act and the
          Australian Privacy Principles, you
        can
            visit the Australian Information Commissioner’s website (see <a
            href="https://www.oaic.gov.au/">https://www.oaic.gov.au/</a>).</Text>
    </PrivacyContainer>
  </>
  )
    ;
};

export default PrivacyPolicy;

export const PrivacyContainer = styled.div`
padding: 110px 15px;
@media (max-width: 1280px) {
  padding: 80px 15px;
}
@media (max-width: 768px) {
  padding: 30px 15px;
}
`

export const Text = styled.p`
font-size: 18px;
line-height: 24px;
margin: 20px 0;
${(props) => props.color && `color: ${props.color}`};

&:last-of-type {
  margin-top: 40px;
}

@media (max-width: 1280px) {
  font-size: 16px;
  line-height: 20px;
}
`

export const Item = styled.div`
margin: 0;
padding: 0;

p {
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0;
}
ul {
  list-style: inside;
  margin: 10px 0;
  & > li {
    font-size: 16px;
    line-height: 20px;
    margin: 10px 0;
  }
}
`;