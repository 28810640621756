import React, { useEffect, useRef, useState } from 'react';
import constants from '../constants';
import locationSVG from '../assets/contacts_location.png'
import phoneSVG from '../assets/contacts_phone.png'
import mailSVG from '../assets/contacts_mail.png'
import './style.scss'
import { fetchData } from "../api/helperFunctions";
import useTitle from '../helpers/useTitle';
import useDescription from '../helpers/useDescription';
import {useHistory} from 'react-router';

const headerBG = {
  background: `#161E27 url(${require('../assets/footer-bg.webp')})`,
  backgroundSize: 'cover'
}

// const getLine = () => {
//   if (window.outerWidth > 768) {
//     return require('../assets/greenline_contact_help.svg')
//   } else {
//     return require('../assets/greenline_contact_help--mobile.svg')
//   }
// }

const Header = () => (
  <header className="contacts" style={headerBG}>
    <h2 className="contacts__title">
      Let's <span className="contucts__title-span">find your&nbsp;next&nbsp;speaker!</span>
      {/* <GreenLine
        bgImg={getLine}
        target={' we can help'}
        scrollPosition={0}
        moveBottom={() => window.outerWidth > 760 ? '-8px' : '-6px'}
      /> */}
    </h2>
    <p className="contacts__subtitle">
    Send us your enquiry and our expert team will be in touch within 24 hours to discuss your brief.
    </p>
  </header>
);

const ContactForm = ({ token }) => {
  const blankData = {
    name: null,
    email: null,
    phone: null,
    message: null
  };
  const history = useHistory();
  
  const [errors, setErrors] = useState(blankData);
  const [formFields, setFormFields] = useState(blankData);
  const formRef = useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  
  // const phonePattern = '^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?$'; //in case if you will need the checking phone numbers
  const handleSubmit = (e, token) => {
    fetchData('contact-us', 'POST', formFields, token)
      .then(r => {
        const [status, data] = r;

        if (status !== 200) {
          setShowSuccess(false);
          setErrors({ ...errors, ...data.errors });
          window.scrollTo({ top: formRef.current.offsetTop - 60, behavior: 'smooth' });
        } else {
          setShowSuccess(true);
          formRef.current.reset();
          setFormFields(blankData);
          history.push('/thankyou');
          return errors
        }
      })
  };
  const handleInputChange = (fieldName, val) => {
    setShowSuccess(false);
    setErrors({
      ...errors,
      [fieldName]: null
    });
    setFormFields({
      ...formFields,
      [fieldName]: val
    })
  }

  return (
    <form ref={formRef}
      action=""
      className={'contacts-form'}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(e, token)
      }}>
      <h2 className="contacts-form__title">Send us your enquiry</h2>
      <label className={`contacts-form__field ${errors && errors.name && "error__box"}`}>
        <div style={{ height: '28px', lineHeight: '28px' }}>Full name</div>
        {errors && errors.name && <span className={'contacts-form__field-error'}>{errors.name}</span>}
        <input type="text" name={'name'} minLength={3} className={'contacts-form__field-input'}
          onChange={e => handleInputChange('name', e.target.value)} />
      </label>
      <label className={`contacts-form__field ${errors && errors.email && "error__box"}`}>
        <div style={{ height: '28px', lineHeight: '28px' }}>Work email</div>
        {errors && errors.email && <span className={'contacts-form__field-error'}>{errors.email}</span>}
        <input type="email" name={'email'} className={'contacts-form__field-input'}
          onChange={e => handleInputChange('email', e.target.value)} />
      </label>
      <label className={`contacts-form__field ${errors && errors.phone && 'error__box'}`}>
        <div style={{ height: '28px', lineHeight: '28px' }}>Phone number</div>
        {errors && errors.phone && <span className={'contacts-form__field-error'}>{errors.phone}</span>}
        <input type={'number'} name={'phone'} minLength={7} className={'contacts-form__field-input'}
          onChange={e => handleInputChange('phone', e.target.value)} />
      </label>
      <label className={`contacts-form__field ${errors && errors.message && "error__box"}`}>
        <div style={{ height: '28px', lineHeight: '28px' }}>What's the brief?</div>
        {errors && errors.message && <span className={'contacts-form__field-error'}>{errors.message}</span>}
        <textarea className={'contacts-form__field-input contacts-form__field-input--textarea'} name={'message'}
          onChange={e => handleInputChange('message', e.target.value)} />
      </label>
      <input
        type="submit"
        className={'contacts-form__submit'}
        value={showSuccess ? 'ENQUIRY SENT' : 'SEND ENQUIRY'}
      // style={{ height: '34px', lineHeight: '0px', }}
      />
      {
        showSuccess && <p className="contacts-form__field--success">Thanks for your message. We will be in touch shortly!</p>
      }
    </form>
  )
}

const Contacts = () => {
  return (
    < div className="contacts-details" >
      <h2 className="contacts-form__second-title">Want to chat?</h2>
      <h3 className="contacts-form__title--smaller">Contact our friendly team today</h3>
      <div className="contacts-details__item">
        <img src={locationSVG} alt="location" />
        <div>
          <p className="contacts-details__item-text">PO Box 7177, Bondi Beach NSW 2026</p>
          {/* <p className="contacts-details__item-text">Bondi Beach NSW 2026</p>
        <p className="contacts-details__item-text">Australia</p> */}
        </div>
      </div>

      <div className="contacts-details__item">
        <img src={phoneSVG} alt="location" />
        <a href={`tel:${constants.phone}`} className="contacts-details__item-text">{constants.phone}</a>
      </div>
      <div className="contacts-details__item">
        <img src={mailSVG} alt="location" />
        <a href={'mailto:hello@findersspeakersco.com'}
          className="contacts-details__item-text">hello@findersspeakersco.com</a>
      </div>
      <h3 className="contacts-form__title contacts-form__title--smaller">Looking for support?</h3>
      <p className="contacts-details__text">If you’re having trouble accessing our platform, please email <a
        href="mailto:hello@findersspeakersco.com"> hello@findersspeakersco.com</a></p>
    </div >

  )
};

const ContactUsScreen = (user) => {
  useTitle('CONTACT US');

  useDescription('Let us help you find your next speaker. Enquire today!');

  useEffect(() => {
    window.window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Header />
      <div className="contacts-wrapper">
        <div>
          <ContactForm token={user.token} />
          <Contacts />
        </div>
      </div>
    </>
  );
};

export default ContactUsScreen;