import React, { useEffect, useState } from 'react';
import qs from 'qs';
import './style.scss';
import './media.scss';
import { HomeSlider } from "./home_slider/HomeSlider";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import structuredData from '../structuredData';
import ApiDataProvider from '../api/apiClient';
import useTitle from '../helpers/useTitle';
import useDescription from '../helpers/useDescription';
import SecondPanel from '../_components/SecondPanel';
import { toggleSignUpBusinessModal } from '../store/actions';
import PromoVideo from '../PromoVideo';
import SignUpBusinessModal from '../AuthScreens/SignUpBusinessModal';
import { GetStarted } from '../AboutScreens/AboutScreen';
import { Formats } from './formats/Formats';
import { Header } from './header/Header';
import { TrendingTopics } from './trending_topics/TrendingTopics';
// import { WhoWeAre } from './who_we_are/WhoWeAre';
import { SpeakersList } from './speakers_list/SpeakersList';
import { SpeakersTypes } from './speaker_types/SpeakerTypes';


const HomeStage2Screen = () => {
    useTitle('FINDERS SPEAKERS – Find Your Perfect Speaker Match', true);

    useDescription('Easily book inspirational and motivational keynote and celebrity' +
        ' speakers. Access hundreds of leading global speakers.' +
        ' All topics, budgets, locations. Enquire today.'
    );

    const [winSize, setWinSize] = useState(window.screen.width);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const history = useHistory();

    const isSignUpBusinessModalOpen = useSelector(state => state.modal.isSignUpBusinessModalOpen);

    const [trendingSpeakers, setTrendingSpeakers] = useState([]);
    const [speakerTypes, setSpeakerTypes] = useState([]);
    const [eventFormats, setEventFormats] = useState([]);
    const [topics, setTopics] = useState([]);

    const onSelectSpeakerType = id => {
        history.push({
            pathname: '/find-speakers',
            search: qs.stringify({ types: [id] })
        });
    };

    const onSelectEventFormat = id => {
        history.push({
            pathname: '/find-speakers',
            search: qs.stringify({ event_types: [id] })
        });
    };

    const onSelectTopic = id => {
        history.push({
            pathname: '/find-speakers',
            search: qs.stringify({ topics: [id] })
        });
    };

    useEffect(() => {
        Promise.all([
            ApiDataProvider.getSpeakerTypes(),
            ApiDataProvider.getEventFormats(),
            ApiDataProvider.getTopics(),
            ApiDataProvider.getSpeakers({ trending: 1 }),
        ]).then(results => {
            setSpeakerTypes(results[0].data);
            setEventFormats(results[1].data);
            setTopics(results[2].data);
            setTrendingSpeakers(results[3].data);
        }).catch(e => console.error(e));


        let script;

        if (process.env.REACT_APP_STRUCTURED_DATA) {
            script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = JSON.stringify(structuredData);
            document.head.appendChild(script);
        }

        return () => {
            if (script) {
                script.remove();
            }
        };
    }, []);

    useEffect(() => {
        const setSize = () => setWinSize(+window.screen.width);
        window.addEventListener('resize', setSize);

        return () => window.removeEventListener('resize', setSize);
    }, []);
    
    return (
        <>
            <Header
                winSize={winSize}
                subtitle={<>
                    Book leading keynote & celebrity speakers for your next event.<br />
                    All topics, brief & budgets.
                </>}
            />

            <div style={{ marginTop: '40px' }}>
                <SecondPanel
                    heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
                    formHeading="looking for a speaker?"
                />
            </div>

            <SpeakersTypes
                winSize={winSize}
                setWinSize={setWinSize}
                data={speakerTypes}
                onSelectType={onSelectSpeakerType}
            />

            <TrendingTopics winSize={winSize} data={topics.filter(item => item.image)} onSelectTopic={onSelectTopic} />

            <SpeakersList
                data={trendingSpeakers}
                winSize={winSize}
                setWinSize={setWinSize}
            />

            <div className="s-home__get-started">
                <GetStarted
                    theme={'dark'}
                />
            </div>

            {/*
                <Formats
                winSize={winSize}
                data={eventFormats.filter(item => item.image)}
                onSelectEventType={onSelectEventFormat}
                />
            */}

            <div data-nosnippet>
                <HomeSlider
                    withImages={false}
                    title="What people say"
                />
            </div>

            <div
              style={{
                  maxWidth: '1105px',
                  margin: '70px auto'
              }}
            >
                <PromoVideo />
            </div>

            { /* <WhoWeAre winSize={winSize} /> */ }


            <SignUpBusinessModal show={isSignUpBusinessModalOpen} onClose={() => dispatch(toggleSignUpBusinessModal())} />
        </>
    );
};

export default HomeStage2Screen;
