import React from 'react';
import styled from "styled-components";
import constants from '../constants';
import { ReactComponent as Tel } from '../assets/faq-page/tel.svg';
import { ReactComponent as Email } from '../assets/faq-page/email.svg';
import { SpeakersPage } from "../HomePage/Redisign/SpeakerPage";


export const FAQFooter = () => {
  return (
    <FQAFooter>
      <section className={`text-center hero faq`}>
        <div className="container">
          <h2 className="subtitle subtitle--light">Still have questions?</h2>
          <p className="text">
            If you cannot find the answer to your question in our FAQ, you can always contact us. We will answer to your
            shortly!
          </p>
          <div className="d-flex justify-content-between">
            <div className="grey-bg-block">
              <Tel />
              <a className="footer__link" href={`tel:${constants.phone}`}>{constants.phone}</a>
              <p className="footer__text">We are always here to help</p>
            </div>
            <div className="grey-bg-block">
              <Email />
              <a className="footer__link aqua" href="mailto: hello@findersspeakersco.com">hello@findersspeakersco.com</a>
              <p className="footer__text">We are always here to help</p>
            </div>
          </div>


        </div>
      </section>
    </FQAFooter>
  )
};
const FQAFooter = styled(SpeakersPage)`
  .faq {
    @media (max-width: 768px) {
      padding: 0 0 50px!important;
    }
    
    .subtitle {
        @media (max-width: 1280px){
            font-size: 48px;
            line-height: 60px;
            margin-bottom: 10px;
        }
        @media (max-width: 768px){
            font-size: 28px;
            line-height: 34px;
        }
    }
    
    .text {
        @media (max-width: 1280px){
          margin: 10px auto 40px;
          max-width: 600px;
          font-size: 18px;
          line-height: 28px;
        }
        @media (max-width: 768px){
          font-size: 16px;
          line-height: 22px;
          margin: 0 0 30px 0;
        }
    }
    
    & .container {
      @media (max-width: 768px) {
        width: 100%!important;
        max-width: 100%!important;
        min-width: 100%!important;
        padding: 0 16px;
      }
      
      & .d-flex {
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
    }
  }


  .grey-bg-block {
      border-radius: 5px;
      flex-basis: calc(50% - 16px);
      background: #374658;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 100px 0 80px;
      flex-direction: column;
      
       @media (max-width: 1280px){
             padding: 80px 0 70px;
             
             svg {
              width: 24px;
              height: 24px;
             }
        }
       @media (max-width: 768px){
           padding: 24px 0;
           margin-bottom: 10px;
       }
  }
  
  .text {
     font-size: 24px;
     line-height: 30px;
     text-align: center;
     color: #ABB0BE;
     margin: 24px auto 60px;
     max-width: 850px;
  }
  
  .footer__link {
      font-size: 28px;
      line-height: 36px;
      color: #fff;
      padding: 24px 0 16px;
      
      &:hover {
        opacity: 0.7;
      }
      
      @media (max-width: 1280px){
          font-size: 24px;
          line-height: 30px;
      }
      @media (max-width: 768px){
        padding: 0;
        margin: 16px 0 10px;
        font-size: 16px;
        line-height: 22px;
      }
  }
  
  .footer__text {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #ABB0BE;
      
      @media (max-width: 1280px){
          font-size: 16px;
          line-height: 20px;
      }
      
      @media (max-width: 768px){
          font-size: 14px;
          line-height: 18px;
      }
  }
  
  .aqua {
      color: #20D5C4;
  }
`