import React, { useState, useRef } from 'react';
import  ConfirmationUserModal  from '../../../_components/modals/ConfirmationUserModal';
import TextInput from '../TextInput';
import styles from './styles.module.scss';

export default (props) => {
  const { onCancel, onConfirm, ...rest } = props;
  const [firstEmailValue, setFirstEmailValue] = useState('');
  const [secondEmailValue, setSecondEmailValue] = useState('');
  const [firstEmailError, setFirstEmailError] = useState('');
  const [secondEmailError, setSecondEmailError] = useState('');
  const [equalityError, setEqualityError] = useState('');

  const firstEmailRef = useRef(null);
  const secondEmailRef = useRef(null);

  const onFirstEmailChange = e => {
    setFirstEmailError('');
    setEqualityError('');
    setFirstEmailValue(e.target.value);
  };

  const onSecondEmailChange = e => {
    setSecondEmailError('');
    setEqualityError('');
    setSecondEmailValue(e.target.value);
  };

  const onCancelModal = () => {
    setFirstEmailValue('');
    setSecondEmailValue('');
    setFirstEmailError('');
    setSecondEmailError('');
    setEqualityError('');
    onCancel();
  };

  const onConfirmHandler = () => {
    const isFirstEmailValid = firstEmailRef.current.reportValidity();
    const isSecondEmailValid = secondEmailRef.current.reportValidity();

    if (firstEmailValue && (firstEmailValue === secondEmailValue) && isFirstEmailValid && isSecondEmailValid) {
      const result = firstEmailValue;
      setEqualityError('');
      return onConfirm(result).finally(() => {
        setFirstEmailValue('');
        setSecondEmailValue('');
      });
    }
    if (!firstEmailValue) {
      setFirstEmailError('Required');
    }

    if (!secondEmailValue) {
      setSecondEmailError('Required');
    }

    if (firstEmailValue && secondEmailValue && (firstEmailValue !== secondEmailValue)) {
      setEqualityError('Emails must be equal');
    } else {
      setEqualityError('');
    }
  };

  return <ConfirmationUserModal
    {...rest}
    onCancel={onCancelModal}
    cancelButtonText="Cancel"
    confirmButtonText="Send link"
    text="Enter new email address"
    subtext="You will be emailed with a change link"
    onConfirm={onConfirmHandler}
    errorMessage={equalityError}
  >
    <div className={styles.form}>
      <TextInput
        value={firstEmailValue}
        onChange={onFirstEmailChange}
        placeholder="Email"
        error={firstEmailError}
        type="email"
        inputRef={firstEmailRef}
      />
      <TextInput
        value={secondEmailValue}
        onChange={onSecondEmailChange}
        placeholder="Repeat email"
        error={secondEmailError}
        type="email"
        inputRef={secondEmailRef}
      />
    </div>
  </ConfirmationUserModal>;
};
