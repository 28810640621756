import React, { useState } from 'react';
import classNames from 'classnames';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ApiDataProvider from '../../../api/apiClient';
import constants from '../../../constants';
import ErrorMessageModal from '../../modals/MessageBoxUserModal';
import s from './BookingFormContract.module.scss';

const MAX_FILENAME_LENGTH = 25;

const Contract = ({
  fileName,
  isLoading,
  onSelect,
  onDelete,
  error,
  disabled
}) => {
  const onLabelClick = e => {
    if (isLoading || disabled) {
      e.preventDefault();
    }
  };

  const onEmptyClick = e => {
    if (disabled) {
      return;
    }
    onDelete(e);
  };

  const fileSelectHandler = e => {
    if (e.target.files.length > 0) {
      onSelect(e.target.files[0], e);
    } else {
      onSelect(null, e);
    }
  };

  return (
    <div className={s.wrapper}>
      <div className={classNames(s.container, { [s.error]: error, [s.disabled]: disabled })}>
        {
          isLoading ?
            <CircularProgress color={'#000000'} />
            : fileName ?
              <div className={s.filled} onClick={onEmptyClick}>
                <div className={s.filledContent}>
                  <div className={s.text}>{fileName}</div>
                  <DeleteIcon className={s.icon}/>
                </div>
              </div>
              : <label
                  className={s.empty}
                  onClick={onLabelClick}
                >
                <AttachFileIcon />
                <span className={s.emptyText}>UPLOAD DOCUMENT</span>
                <input type="file" onChange={fileSelectHandler} />
              </label>
        }
      </div>
      <div className={s.errorText}>{error}</div>
    </div>
  );
};

const upload = file => {
  return ApiDataProvider.uploadFile(file, 'booking_contract');
};

const BookingFormContract = ({ file, onChange, error, disabled }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const deleteFile = () => {
    onChange(null);
  };

  const uploadFile = (fileObject, e) => {
    const evTarget = e.target;
    if (fileObject) {
      if (fileObject.size > constants.maxFileSizeMB << 20) {
        setUploadError(`Max file size: ${constants.maxFileSizeMB} MB`);

        evTarget.value = null;
        return;
      }

      setIsLoading(true);
      upload(fileObject).then(response => {
        onChange(response);
      }).catch(e =>{
        setUploadError(e.message);
        evTarget.value = null;
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };



  const fullFilename = file && `${
    file.name.length > MAX_FILENAME_LENGTH
      ? file.name.slice(0, MAX_FILENAME_LENGTH - 3) + '...'
      : file.name}.${file.path.slice(file.path.lastIndexOf('.') + 1)}`;

  return (
    <>
      <ErrorMessageModal
        isOpen={uploadError}
        title={'Upload error'}
        message={uploadError}
        onOk={() => setUploadError('')}
      />
      <Contract
        fileName={fullFilename}
        isLoading={isLoading}
        onSelect={uploadFile}
        onDelete={deleteFile}
        error={error}
        disabled={disabled}
      />
    </>
  );
};

export default BookingFormContract;
