import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as Add } from '../../assets/add.svg';
import { ReactComponent as Mark } from '../../assets/question_mark.svg';
import ApiDataProvider from '../../api/apiClient';
import { ValidationError } from '../../errors';
import getFileUrl from '../../helpers/getFileUrl';
import PhoneInput from '../../_components/PhoneInput';
import PictureInput from '../../_components/PictureInput';
import MessageBoxUserModal from '../../_components/modals/MessageBoxUserModal';
import { PrimaryButton, PrimaryButtonInverted, SecondaryButton } from '../../_components/buttons';
import Spinner from '../../_components/Spinner';
import Paper from '../components/Paper';
import TextInput from '../components/TextInput';
import PasswordPromptModal from '../components/PasswordPromptModal';
import EmailPromptModal from '../components/EmailPromptModal';
import accountScreensStyles from '../accountScreensStyles.module.scss';
import styles from './styles.module.scss';
import { setAccountDetails } from '../../store/actions/profileActions';
import Toggle from '../components/Toggle';


export default props => {
  const {
    hasBusinessNameField,
    hasAgentEmailField,
    hasAvatarField,
    fetcher,
    sender,
    emailChangeRequester,
    avatarText,
    shouldEmailChangeConfirm,
    onBack
  } = props;

  const history = useHistory();

  const role = useSelector(state => state.user.role);
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [agentEmail, setAgentEmail] = useState('');
  const [emailNotificationsDisabled, setEmailNotificationsDisabled] = useState(false);
  const [agentEmailOpen, setAgentEmailOpen] = useState(false);

  const emailRef = useRef(null);
  const agentEmailRef = useRef(null);


  const [phoneNumber, setPhoneNumber] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [businessName, setBusinessName] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');
  const [agentEmailError, setAgentEmailError] = useState('');

  const [modalTitle, setModalTitle] = useState('');
  const [modelMessage, setModalMessage] = useState('');

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [passwordModalValue, setPasswordModalValue] = useState('');

  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const changeFirstName = e => {
    setFirstNameError('');
    setFirstName(e.target.value);
  };

  const changeLastName = e => {
    setLastNameError('');
    setLastName(e.target.value);
  };

  const changePhoneNumber = val => {
    setPhoneNumberError('');
    setPhoneNumber(val);
  };

  const avatarUploadErrorHandler = e => {
    setModalTitle('Error');
    setModalMessage(e.message);
  };

  const changeEmail = e => {
    setEmailError('');
    setEmail(e.target.value);
  };

  const changeAgentEmail = e => {
    setAgentEmailError('');
    setAgentEmail(e.target.value);
  };

  const changeBusinessName = e => {
    setBusinessNameError('');
    setBusinessName(e.target.value);
  };

  const changeEmailVerificationsEnabled = e => {
    const isChecked = e.target.checked;
    setEmailNotificationsDisabled(isChecked);

    if (!isChecked && !agentEmail?.trim()) {
      setAgentEmailError('');
    }
  };

  const getBack = () => {
    onBack();
  };

  const saveDetails = async () => {
    let isValid = true;

    const firstNameTrimmed = firstName.trim();
    const lastNameTrimmed = lastName.trim();
    const emailTrimmed = email.trim();
    const businessNameTrimmed = businessName?.trim();
    const agentEmailTrimmed = agentEmail?.trim();

    if (!firstNameTrimmed) {
      setFirstNameError('Required');
      isValid = false;
    }

    if (!lastNameTrimmed) {
      setLastNameError('Required');
      isValid = false;
    }

    if (!phoneNumber) {
      setPhoneNumberError('Required');
      isValid = false;
    }

    if (hasBusinessNameField && !businessNameTrimmed) {
      setBusinessNameError('Required');
      isValid = false;
    }

    if (!emailTrimmed) {
      setEmailError('Required');
      isValid = false;
    }

    if (!emailRef.current.reportValidity()) {
      isValid = false;
    }

    if (agentEmailRef.current && !agentEmailRef.current.reportValidity()) {
      console.log('agent email validity error');
      isValid = false;
    }

    if (emailNotificationsDisabled && !agentEmailTrimmed) {
      console.log('emailNotificationsDisabled && !agentEmailTrimmed');
      isValid = false;
      setAgentEmailError('Cannot be empty if agent notifications enabled');
    }

    if (isValid) {
      try {
        setSending(true);

        await sender({
          firstName: firstNameTrimmed,
          lastName: lastNameTrimmed,
          phoneNumber,
          email: emailTrimmed,
          avatar,
          businessName,
          agentEmail,
          emailNotificationsDisabled
      });
      } catch (e) {
        if (e instanceof ValidationError) {
          Object.entries(e.getErrors()).forEach(err => {
            const value = err[1];
            switch (err[0]) {
              case 'first_name':
                setFirstNameError(value);
                break;

              case 'last_name':
                setLastNameError(value);
                break;

              case 'contact_number':
                setPhoneNumber(value);
                break;

              case 'email':
                setEmailError(value);
                break;

              case 'business_name':
                setBusinessNameError(value);
                break;

              case 'agent_email':
                setAgentEmailError(value);
                break;

              default:
            }
          });
        } else {
          setModalTitle('Error');
          setModalMessage(e.message);
        }
      } finally {
        setSending(false);
      }
    }
  };

  const closeMessageBoxModal = () => {
    setModalTitle('');
    setModalMessage('');
  };

  const closePasswordModal = () => {
    setPasswordModalOpen(false);
    setPasswordModalValue('');
  };

  const onPasswordModalNext = (password) => {
    setPasswordModalValue(password);
    setPasswordModalOpen(false);
    setEmailModalOpen(true);
  };

  const closeEmailModal = () => {
    setEmailModalOpen(false);
    setPasswordModalValue('');
  };

  const confirmEmailChange = email => {
    return emailChangeRequester(passwordModalValue, email).then(() => {
      setModalTitle('Email sent');
      setModalMessage('');
    }).catch(e => {
      setModalTitle('Error');
      setModalMessage(e.message);
    }).finally(() => {
      setEmailModalOpen(false);
      setPasswordModalValue('');
    });
  };

  useEffect(() => {
    if (agentEmail || emailNotificationsDisabled) {
      setAgentEmailOpen(true);
    }
  }, [agentEmail, emailNotificationsDisabled]);

  useEffect(() => {
    setLoading(true);
    fetcher().then(result => {
      const {
        first_name,
        last_name,
        contact_number,
        email,
        photo,
        business_name,
        agent_email,
        receive_emails
      } = result;

      setFirstName(first_name);
      setLastName(last_name);
      setPhoneNumber(contact_number);
      setEmail(email);
      setAvatar(photo);
      setBusinessName(business_name);
      if (hasAgentEmailField) {
        setAgentEmail(agent_email || '');
        setEmailNotificationsDisabled(!receive_emails);
      }
    }).catch(() => {}).finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Paper title="My Account">
      <div className={classNames(accountScreensStyles.paperContent, styles.container)}>

        <div className={accountScreensStyles.heading}>
          <div className={accountScreensStyles.text}>Account Details</div></div>

        <p className={accountScreensStyles.subheading}>Set your personal details and mobile number:</p>

        <div className={styles.formContainer}>
          <div className={accountScreensStyles.formItem}>
            <div className={accountScreensStyles.label}>First Name*</div>
            <div className={accountScreensStyles.input}>
              <TextInput
                value={firstName}
                onChange={changeFirstName}
                error={firstNameError}
                placeholder="First Name"
              />
            </div>
          </div>

          <div className={accountScreensStyles.formItem}>
            <div className={accountScreensStyles.label}>Last Name*</div>
            <div className={accountScreensStyles.input}>
              <TextInput
                value={lastName}
                onChange={changeLastName}
                error={lastNameError}
                placeholder="Last Name"
              />
            </div>
          </div>

          <div className={accountScreensStyles.formItem}>
            <div className={accountScreensStyles.label}>Mobile Number*</div>
            <div className={accountScreensStyles.input}>
              <PhoneInput
                value={phoneNumber}
                onChange={changePhoneNumber}
                containerClass={styles.phoneContainer}
                inputClass={classNames(styles.phoneInput, { [styles.error]: !!phoneNumberError })}
              />
            </div>
          </div>

          {hasBusinessNameField && (
            <div className={accountScreensStyles.formItem}>
              <div className={accountScreensStyles.label}>Company Name*</div>
              <div className={accountScreensStyles.input}>
                <TextInput
                  value={businessName}
                  onChange={changeBusinessName}
                  placeholder="Company Name"
                  error={businessNameError}
                />
              </div>
            </div>
          )}

          <div className={classNames({ [styles.emailFieldSpeaker]: true })}>
            <div className={accountScreensStyles.formItem}>
              <div className={accountScreensStyles.label}>Email*</div>
              <div className={accountScreensStyles.input}>
                <TextInput
                  type="email"
                  value={email}
                  onChange={changeEmail}
                  error={emailError}
                  placeholder="Email"
                  disabled={shouldEmailChangeConfirm}
                  inputRef={emailRef}
                />
              </div>
            </div>
          </div>

          <div className={styles.buttonWrapper}>
            {shouldEmailChangeConfirm && (
              <PrimaryButtonInverted
                onClick={() => setPasswordModalOpen(true)}
              >Change email</PrimaryButtonInverted>
            )}
          </div>


          {hasAgentEmailField && (agentEmailOpen ? (<>
            <div className={accountScreensStyles.formItem}>
              <div className={accountScreensStyles.label}>Agent Email</div>
              <div className={accountScreensStyles.input}>
                <TextInput
                  type="email"
                  value={agentEmail}
                  onChange={changeAgentEmail}
                  error={agentEmailError && <div style={{ textAlign: 'right' }}>{agentEmailError}</div>}
                  errorPosition="down"
                  placeholder="Agent Email"
                  inputRef={agentEmailRef}
                />
              </div>
            </div>
            <div className={accountScreensStyles.formItem}>
              <div className={accountScreensStyles.label}>
                <div className={styles.tooltipLabelWrapper}>
                  <span>Agent notifications</span>
                  <span className={styles.tooltipWrapper}>
                    <Tooltip title="Enable all email notifications to be sent to your speaker agent only">
                      <Mark />
                    </Tooltip>
                  </span>
                </div>
              </div>
              <div className={accountScreensStyles.input}>
                <Toggle
                  checked={emailNotificationsDisabled}
                  onChange={e => changeEmailVerificationsEnabled(e)}
                />
              </div>
            </div>
          </>) : (<>
            <div
              className={styles.addAgentEmail}
              onClick={() => setAgentEmailOpen(true)}><Add />
              <span className={styles.text}>Add Agent Email</span>
            </div>
            <div />
          </>))}


          {hasAvatarField && (
            <div className={accountScreensStyles.formItem}>
              <div className={accountScreensStyles.label}>
                {avatarText}
              </div>
              <PictureInput
                path={avatar}
                onChange={value => setAvatar(value)}
                uploader={file => ApiDataProvider.uploadFile(file, 'upload_media')}
                onUploadError={avatarUploadErrorHandler}
              />
            </div>
          )}

          <div className={classNames(accountScreensStyles.formItem, styles.controls)}>
            <SecondaryButton onClick={getBack}>back</SecondaryButton>

            <PrimaryButton
              onClickAsync={saveDetails}
              disabled={sending || firstNameError || lastNameError || emailError || phoneNumberError}
            >Save details</PrimaryButton>
          </div>
        </div>
      </div>

      <MessageBoxUserModal
        isOpen={modalTitle}
        title={modalTitle}
        message={modelMessage}
        onOk={closeMessageBoxModal}
      />

      <PasswordPromptModal
        isOpen={passwordModalOpen}
        onCancel={closePasswordModal}
        onConfirm={onPasswordModalNext}
      />

      <EmailPromptModal
        isOpen={emailModalOpen}
        onCancel={closeEmailModal}
        onConfirm={confirmEmailChange}
      />
    </Paper>
  );
};
