import React from 'react';
import s from '../EnquirySummary.module.scss';
import ReadMore from '../ReadMore/ReadMore';
import BudgetFormatted from '../../BudgetFormatted';


const Description = ({
  eventName,
  personalMessage,
  budget
}) => {
  return (
    <>
      <h2 className={s.subHeader}>Event name</h2>
      <h1 className={s.eventName}>{ eventName }</h1>
      <h2 className={s.subHeader}>Personal message</h2>

      <div className={s.personalMessage}>
        <ReadMore>
          { personalMessage }
        </ReadMore>
      </div>

      <h2 className={s.subHeader}>Proposed budget</h2>
      <div className={s.budget}>
        <BudgetFormatted
          from={budget?.from}
          to={budget?.to}
        />
      </div>
    </>
  );
};

export default Description;
