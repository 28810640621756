import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import bg from '../assets/header-curves-bg.png';
// import { SearchForm } from "./SearchForm";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import footerBg from "../assets/footer-bg.webp";


export const Hero = ({ title, text }) => {
  const [moveBg, setMoveBg] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setMoveBg('move-bg')
    }, 200)
  }, [])

  return (
    <SpeakersHero>
      <section className={`hero ${moveBg}`}>
        <div className="bg" />
        <div className="container-fluid">
          <AnimatedOnScroll animationIn="fadeInUp" animationInDelay={1300} animateOnMount={true}>
            <h1 className="title">{title}</h1>
          </AnimatedOnScroll>
          <AnimatedOnScroll animationIn="fadeInUp" animationInDelay={1700} animateOnMount={true}>
            <p className="hero__text">{text}</p>
          </AnimatedOnScroll>
          {/* <AnimatedOnScroll animationIn="fadeInUp" animationInDelay={2100} animateOnMount={true}>
            <div className="container">
              <SearchForm onSearchPress={onSearchPress}/>
            </div>
          </AnimatedOnScroll> */}
        </div>
      </section>
    </SpeakersHero>
  )
}

export const SpeakersHero = styled.div`
    .s-home__search-form {
      margin-bottom: 0;
    }

    .hero {
        text-align: center;
        position: relative;
        overflow: hidden;
        position:relative;

        @media (max-width: 768px) {
           padding: 89px 0 40px;
        }

        .bg {
            background: #161E27 url(${footerBg}) no-repeat center;
            background-size: cover;
            width: 100%;
            height: 100%;
            position:absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            transform: scaleX(1.5);
            transition: transform 2.5s linear 0.2s;
        }
        &.move-bg {
          .bg {
              transform: scaleX(1);
          }; 
        }
    }
        
        & .title {
        font-weight: 600;
        font-size: 62px;
        line-height: 72px;
        color: #fff;
        margin-bottom: 24px;
        
          @media (max-width: 768px) {
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 10px;
            
            & .new-line {
              display: inline;
            }
          }
        }
    
        .hero__text {
            font-size: 28px;
            line-height: 36px;
            max-width: 890px;
            margin: 0 auto 60px;
            text-align: center;
            color: #ABB0BE;
            
            @media (max-width: 1280px) {
                font-size: 18px;
                line-height: 28px;
                max-width: 566px;
                margin: 0 auto 40px;
            }
            @media (max-width: 768px) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 30px;
            }
        }
        
        & .container-fluid {
          @media (max-width: 768px) {
            padding: 0 16px;
          }
        }
        
        & .home__btn {
          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 16px;
            padding: 14px 38px;
            height: auto;
          }
        }
    }
`;

