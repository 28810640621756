import { call, put } from 'redux-saga/effects';
import { fetchDataNotAuth, fetchDataGet } from '../../api/helperFunctions';
import { setUserData, setToken, initSendbird } from '../actions/profileActions';
import {setChatEmpty, setChatLoadFail, setChatLoading, setChatLoadSuccess} from '../actions';
import sendbird from '../../sendbird';

export function* postRegisterAsync({ data, params }) {
    try {
        const response = yield call(fetchDataNotAuth, `register${params}`, 'POST', data);

        yield put({
            type: 'REGISTER_INIT',
        });

        if (response[0] === 200) {
            yield put({
                type: 'REGISTER_SUCCESS',
                payload: data,
            });

            yield put({
                type: 'REGISTER_DONE',
                payload: !!params,
            });
            yield put({
                type: 'SET_CURRENT_AUTH_STEP',
                payload: !!params ? 1 : 2,
            });

            yield put({
                type: 'RESET_ERRORS',
            });

            return response[0];
        } else {
            yield put({
                type: 'REGISTER_FAILURE',
                payload: response[1],
            });
            yield put({
                type: 'SET_CURRENT_AUTH_STEP',
                payload: !!params ? 0 : 1,
            });

            return response
        }
    } catch (err) {
        yield put({
            type: 'REGISTER_FAILURE',
            payload: err,
        });
    }
}

export function* postLoginAsync(action) {
    try {
        const { email, password, history, closeModal } = action.payload;

        const data = {
            email,
            password,
        };
        const response = yield call(fetchDataNotAuth, 'login', 'POST', data);

        if (response[0] === 200) {
            const { user, token } = response[1];
            yield put(setUserData(user));
            yield put(setToken(token));
            localStorage.setItem('token', token);
            if (user.sendbird_id && user.sendbird_token) {
                yield put(setChatLoading());
            } else {
                yield put(setChatEmpty());
            }
            yield put({ type: 'SET_IS_LOGIN', payload: true });
            yield call(closeModal);
            let photoData = yield call(fetchDataGet, 'profile/about', 'get', response[1].token);
            yield put({
                type: 'SET_AVATAR',
                payload: `${process.env.REACT_APP_DO_SPACES_URL}/${photoData[1].data.photo}`,
            });
            yield call(() => history.push('/preview-profile'));
            // yield call(() => toggleVerificationModal(false));
        } else {
            yield put({
                type: 'LOGIN_FAILURE',
                payload: response[1],
            });
        }
    } catch (err) {
        console.log('Register Error = ', err);
        // yield put(postRegisterFailure());
    }
}
