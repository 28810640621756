import React, { useState } from 'react';
import styled from "styled-components";
import classNames from 'classnames';
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import './speaker-card.scss';
import { useSelector } from "react-redux";
import heart from "../../assets/icons/heart_light.png";
import plus from '../../assets/icons/+.png';
import defaultAvatar from '../../assets/default-avatar.jfif';
import formatSpeakerFee from '../../helpers/formatSpeakerFee';
import SmartThumbnail from '../../_components/SmartThumbnail';


export const SpeakerCard = ({ elem, backComponent, toggleShortlist }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const { width } = useWindowDimensions();
    const shortList = useSelector(state => state.speakers.shortList.data);

    const {
        photo,
        first_name,
        last_name,
        expertise,
        location,
        fee,
        id,
        professional_title,
    } = elem;

    const handleClick = e => {
        setIsFlipped(true);
    };

    const handleClickFlipped = e => {
        e.stopPropagation();
        if (width < 768) {
            setIsFlipped(false);
        }
    };

    let added = shortList.some(el => el.id === id);
    const topicArr = expertise.speaker_topic.join(", ");

    return (
        <FlipCardStyle>
            <div className="speaker-card-wrapper">
                <div
                  className="speaker-card-container"
                >
                    <div
                        className="speaker-card-image-container"
                        style={{
                            backgroundImage: `url(${defaultAvatar})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        {photo && (
                            <SmartThumbnail
                                path={photo}
                                alt=""
                            />
                        )}

                        <div
                            className="speaker-card-shortlist-button"
                            onClick={expertise ? () => toggleShortlist(id) : () => {}}
                            style={{ backgroundColor: !added ? '#20D5C4' : '#E8388A' }}
                        >
                            {added
                                ? <img src={heart} />
                                : <img src={plus} />
                            }
                        </div>
                    </div>

                    <div
                      className="speaker-card-flip"
                      onClick={handleClick}
                    >
                        <div className="speaker-card-name">
                            {first_name} {last_name}
                        </div>
                        <div className="speaker-card-professional-title">
                            {professional_title}
                        </div>
                        <div className="speaker-card-topics">
                            {expertise.speaker_topic && expertise.speaker_topic.length
                                ? expertise.speaker_topic.map((elem, index) => {
                                    if (index === expertise.speaker_topic.length - 1 || expertise.speaker_topic.length === 1) {
                                        
                                        
                                        return <div>{topicArr}</div>
                                    }
                                })
                                : ''
                            }
                        </div>

                        <div className="speaker-card-footer">
                            <div className="speaker-card-footer__elem speaker-card-footer__elem_first">{location}</div>
                            <div className="speaker-card-footer__elem speaker-card-footer__elem_second">
                                {
                                    formatSpeakerFee(fee.from, fee.to)
                                }
                            </div>
                        </div>

                        <div
                          className={classNames("speaker-card-back", { "speaker-card-back__active": isFlipped })}
                          onClick={handleClickFlipped}
                        >
                            {backComponent}
                        </div>
                    </div>

                </div>
            </div>

        </FlipCardStyle>
    )
}

const FlipCardStyle = styled.div`
    
`;
