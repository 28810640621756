import React from 'react';
import NumberFormat from 'react-number-format';

export default ({ from, to, className }) => {
  if (!from && !to) {
    return <span className={className}>To be discussed</span>;
  }

  if (!to || from === to) {
    return <span className={className}>
      <NumberFormat
        value={from}
        displayType="text"
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix="$"
      />
    </span>;
  }

  return <span className={className}>
    <NumberFormat
      value={from}
      displayType="text"
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix="$"
    />
      <span> - </span>
    <NumberFormat
      value={to}
      displayType="text"
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix="$"
    />
  </span>;
};
