import styled from "styled-components";

export const MainTitleStyled = styled.h1`
    font-size: 80px;
    line-height: 75px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.03em;
    margin-bottom: 18px;
    color: #fff;
    
    .accent {
      color: #20D5C4;
    }

    .new-line {
      display: block;
    }

    &.about_main_title {
      max-width: 750px;
      margin: 0 auto;
    }
    
    &.speaker-page__title {
        @media (max-width: 1024px) {
            margin-bottom: 19px;
        }
    }

    &.about_footer_title {
        margin-top: 20px;
        margin-bottom: 48px;

        &.dark {
          color: #293749;
        }

        &.font-70 {
          @media (min-width: 1024px) {
            font-size: 70px;          
          }
        }
    }
    
    .sm-visible {
        display: none;
    }

    @media (max-width: 1024px) {
        font-size: 36px;
        line-height: 36px;
        text-align: center; 
        
        .sm-hidden {
          display: none;
        }
        .sm-visible {
          display: block;
        }
        
        &.about_main_title {
            max-width: 360px;
            width: 82%;
        }
    }

    .speaker-page__title-span {
      opacity: 0;
      font-weight: normal;
      font-size: 120px;
      line-height: 120px;

      &_active {
           opacity: 1;
           font-size: 80px;
           line-height: 75px;
           transition: 1s;
      }
    
      @media (max-width: 1024px) {
        opacity: 0;
        font-size: 63px;
        line-height: 63px;

        &_active {
          opacity: 1;
          font-size: 36px;
          line-height: 36px;
          transition: 1s;
        }
      }
  }

  .vis-desktop {

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .vis-mob {
    display: none;
    color: #ffffff;
    font-size: 36px;
    line-height: 36px;

    & span {
      color: #16d4c2;
    }

    @media (max-width: 1024px) {
      display: block;
    }
  }
`;
