import s from './Process.module.scss';
import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { HomeTitle } from "../HomeTitle";
import tablet1 from "../../assets/redesign/speaker-page-row-1.png";
import tablet2 from "../../assets/redesign/speaker-page-row-2.png";
import tablet3 from "../../assets/redesign/speaker-page-row-3.png";
import tablet1Mobile from "../../assets/redesign/phone/1.png";
import tablet2Mobile from "../../assets/redesign/phone/2.png";
import tablet3Mobile from "../../assets/redesign/phone/3.png";
import speakerDemoProfile from "../../assets/speaker-demo-profile.jpg";
import { GreenFill } from '../../_components/GreenFill/GreenFill';
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import FindSpeakersLink from './FindSpeakersLink';

export const Process = () => {
  const row1Ref = useRef();
  const row2Ref = useRef();
  const row3Ref = useRef();
  const { width } = useWindowDimensions();

  return (
    <SpeakersProcess>
      <section className="process">
        <div className="container">
          <HomeTitle
            text={'How we help'}
            maxWidth={{ desktop: '320px', mobile: '100%' }}
            marginBottom={{ desktop: '54px', mobile: '20px' }}
            marginTop={{ desktop: '17px', mobile: '10px' }}
            fontSize={{ desktop: '40px', mobile: '36px' }}
            parentName={'process'}
          />
          <div className={`process__row process__row_1`} ref={row1Ref}>
            <div className="process__img">
              {/* <span className="number" style={{zIndex: 2, fontSize: '210px', top: '-30px', left: '55%'}}>1</span> */}
              {/* <div className="process__img--small" style={{backgroundImage: `url(${mobile1})`, zIndex: 3, width: '150px', top: '-25px', left: '62%'}}/> */}
              <img className="process__img--big" src={tablet1} style={{width: '720px'}}/>
            </div>
            <div className="process__info">
              <h3
                className="process__info-title"
              >
                <GreenFill
                  imageStyle={{ top: '-13%', left: '-9%', width: '118%', height: '127%' }}
                  reference={row1Ref}
                  type={1}
                >
                  <span style={{ color: 'black' }}>1. Get Promoted</span>
                </GreenFill>
              </h3>
              <div className="process__info-text">

                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                  <b>Get marketed</b> to the biggest brands and event planners
                  </div>
                </div>

                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                    <b>Create an expert profile</b> that grabs <br/> the attention of event organisers
                  </div>
                </div>

                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                    <b>Showcase your full service offering</b>; expertise, media, reviews and more
                  </div>
                </div>

                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                    <a target="_blank" href={speakerDemoProfile}>
                      <span className={s.demoProfileLink}>View profile demo here</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`process__row process__row_2`} ref={row2Ref}>
            <div className="process__info">
              <h3
                className="process__info-title"
              >
                <GreenFill
                  imageStyle={{ top: '-12%', left: '-7%', width: '118%', height: '117%' }}
                  reference={row2Ref}
                >
                  <span style={{ color: 'black' }}>2. Get Booked</span>
                </GreenFill>
              </h3>
              <div className="process__info-text">
                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                  <b>Engage direct with clients</b> for better collaborations, stronger relationships
                  </div>
                </div>

                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                    <b>Easily manage</b> all enquiries, bookings, contracts and payments online
                  </div>
                </div>

                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                    <b>Outsource the admin</b>, focus on <br/> powering your speaking business
                  </div>
                </div>

              </div>
            </div>
            <div className="process__img">
              {/* <span className="number" style={{ fontSize: '210px', top: '0', left: '25%'}}>2</span> */}
              {/* <div className="process__img--small" style={{backgroundImage: `url(${mobile2})`, width: '150px', top: '-60px', left: '38%'}}/> */}
              <img className="process__img--big" src={tablet2} style={{position: 'relative', width: '720px'}}/>
            </div>
          </div>

          <div className={`process__row process__row_3`} style={{alignItems: 'flex-start'}} ref={row3Ref}>
            <div className="process__img">
              {/* <span className="number" style={{ fontSize: '210px', zIndex: 1, left: '72%'}}>3</span> */}
              {/* <div className="process__img--small" style={{backgroundImage: `url(${mobile3})`, zIndex: 3, width: '150px', left: '55%'}}/> */}
              <img className="process__img--big" src={tablet3}  style={{width: '735px'}}/>
            </div>
            <div className="process__info" style={{ paddingLeft: `${width > 600 ? '8px' : '0px'}` }}>
              <h3
                className="process__info-title"
              >
                <GreenFill
                  imageStyle={{ top: '-17%', left: '-12%', width: '130%', height: '125%' }}
                  reference={row3Ref}
                >
                  <span style={{ color: 'black' }}>3. Get paid</span>
                </GreenFill>
              </h3>
              <div className="process__info-text">
                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                    {/* Outsource the hassle with {width < 601 && <br />}enquiries and bookings efficiently {width < 601 && <br />} managed online. */}
                     <b>Set the rate and terms you deserve</b>. <br/> No more messy money conversations
                  </div>
                </div>

                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                    {/* Get paid on time with payments {width < 601 && <br />} safely and securely {width < 601 && <br />} managed for you. */}
                    <b>Get paid on time, every time</b> with automated and secure payments 
                  </div>
                </div>

                <div className="process__info-text-container">
                  <div className="process__info-text-wrapper">
                    {/* Let us coordinate your {width < 601 && <br />} testimonials to boost your {width < 601 && <br />} brand and future bookings. */}
                      <b>Receive reviews</b> and publish <br/> testimonials to boost future bookings 
                  </div>
                </div>
              </div>

            </div>
          </div>
        
          <div className={`process__row-mobile`}>
            <img src={tablet1Mobile} className="process__row-mobile-img" style={{marginBottom: '35px', paddingLeft: '20px'}} />
            <div className="process__row-mobile-title"><span>Get Promoted</span></div>
            <div className="process__row-mobile-text"><b>Get marketed</b> to the biggest brands and event planners</div>
            <div className="process__row-mobile-text"><b>Create an expert profile</b> that grabs the attention of event organisers</div>
            <div className="process__row-mobile-text"><b>Showcase your full service offering;</b> expertise, media, reviews and more</div>
          </div>

          <div className={`process__row-mobile`}>
            <img src={tablet2Mobile} className="process__row-mobile-img"/>
            <div className="process__row-mobile-title"><span>Get Booked</span></div>
            <div className="process__row-mobile-text"><b>Engage direct with clients</b> for better collaborations, stronger relationships</div>
            <div className="process__row-mobile-text"><b>Easily manage</b> all enquiries, bookings, contracts and payments online</div>
            <div className="process__row-mobile-text"><b>Outsource the admin</b>, focus on powering your speaking business</div>
          </div> 

          <div className={`process__row-mobile`}>
            <img src={tablet3Mobile} className="process__row-mobile-img"/>
            <div className="process__row-mobile-title"><span>Get paid</span></div>
            <div className="process__row-mobile-text"><b>Set the rate and terms you deserve</b>. No more messy money conversations</div>
            <div className="process__row-mobile-text"><b>Get paid on time, every time</b> with automated and secure payments </div>
            <div className="process__row-mobile-text"><b>Receive reviews</b> and publish testimonials to boost future bookings</div>
          </div>

        </div>
      </section>

   {/*   <FindSpeakersLink />*/}
    </SpeakersProcess>
  )
};

const SpeakersProcess = styled.section`
  .container {
    padding-top: 17px;
  }

  .desktop-image {
    display: inline;
    @media (max-width: 620px) {
      display: none;
    }
  }

  .mobile-image {
    display: none;
    @media (max-width: 620px) {
      display: inline;
    }
  }

    @media (max-width: 1024px) {
      /* background: #EAEBED; */
    }

    .process__img--big,
    .process__img--small {
      max-height: 100%;
    }

    .process {
        padding: 85px 0 13px;
    }

    .accent {
      color: #1DB8AF;
    }

    .number {
        font-style: normal;
        font-weight: 600;
        font-size: 300px;
        line-height: 1;
        color: #20D5C4;
        position: absolute;
    }

    .sm-visible {
      display: none;
    }

    .process__row-mobile {
      display: none;

      @media (max-width: 1024px) {
        display: block!important;
      }

      margin-bottom: 20px;

      &-img {
        width: 100%;
      }

      &-title {
        font-size: 26px;
        line-height: 28px;
        font-weight: 500;
        color: #19222D;
        margin-bottom: 15px;

        span {
          color: #02B5AA;
        }
      }

      &-text {
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        color: #19222D;
        margin-bottom: 10px;
      }
    }

    .process__row {
      display: flex;
      align-items: start;
      /* padding-bottom: 111px; */

      @media (max-width: 1024px) {
        display: none!important;
      }

      @media (max-width: 601px) {
        padding-bottom: 79px;

        .process__img {
          display: flex;
          justify-content: center;
        }
      }
    }

    .process__row_1 {
        .process__info {
          text-align: left;
          margin-top: 75px;
        }

        .process__info-text {
          margin-right: 40px;
        }

        .number {
        }

        .process__img {
          position: relative;
          left: -32px;
          &--big {
          }
          
          &--small {
          }
        }
      }

    .process__row_2 {
        .process__info {
          margin-top: 106px;
          margin-left: 55px;
          wifth
        }

        .number {
          position: absolute;
        }

        .process__img {
          position: relative;
          top: -20px;
          left: -44px;
          margin-top: 62px;
          &--big {
          }
        }
    }

    .process__row_3 {
        padding-top: 30px;
        padding-bottom: 0;

        .process__info {
          text-align: left;
          padding-top: 40px;
        }

        .process__info-title {
          color: #19222D;
          margin-left: 14px;
          margin-bottom: 26px;
          margin-top: -2px;
        }

        .process__info-text {
          margin-right: 0;
        }

        .number {
          position: absolute;
        }

      .process__img {
          margin-left: -20px;
          top: -23px;
          left: -20px;
          &--big {
              top: -23px;
              left: 46px;
              z-index: 1;
          }
          
          &--small {
            top: -86px;
            right: 22px;
              z-index: 0;
          }
        }
      }

    .process__info-text{
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        color: #19222D;

        b {
          font-weight: 600;
        }

        @media (max-width: 601px) {
          font-size: 16px;
        }
        
        p {
            color: #19222D;
            margin-bottom: 10px;
            font-weight: 300;
            font-size: 15px;
            line-height: 21px;
        }
    }

    .process__info{

      h3 {
        margin-left: 25px;
      }
        
        &-title {
          margin-bottom: 22px;
          font-size: 30px;
          line-height: 36px;
          font-weight: 600;
          letter-spacing: -0.03em;
          text-transform: uppercase;
        }
    }

    .process__info,
    .process__img {
        flex-basis: 50%;
        position: relative;
    }

    .process__img--big {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center; 
    }
    
    @media (max-width: 1024px) {
      .sm-visible {
        display: block;
      }
      .process {
        padding: 40px 0 20px;
        
          .container {
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 20px;
              padding-right: 20px;
          }
      }
      
      .process__info-text p {
          font-size: 16px;
          line-height: 24px;
      }

      .process__row {
        max-width: 369px;
        margin: 0 auto;
        display: block;
      }
      
      .process__img {
          width: 320px;
          margin: auto;
          height: 214px;
      }
      .process__info {
        width: 320px;
        padding: 0;
        margin: 0;
      }
      .process__info-title {
        font-size: 26px;
        line-height: 28px;
      }
      
      .number {
        font-size: 190px;
      }
      .process__row_1 {
        .process__info-text {
          margin-right: 0;
        }
        .number {
            top: -28px;
            right: 66px;
            z-index: 2;
          }
          
        .process__img {
          &::after {
            width: 30px;
            height: 30px;
            bottom: 75px;
            left: 7px;
          }
          &::before {
            width: 12px;
            height: 12px;
            left: 0;
            top: 82px;
          }
      }
        .process__img--big {
            top: 3px;
            left: 14px;
            width: 228px;
            height: 193px;
        }
        .process__img--small {
            width: 114px;
            height: 203px;
            top: -23px;
            right: 5px;
        }
      }
      .process__row_2 {
          display: flex;
          flex-direction: column;
        .process__img {
          order: 1;
          flex-basis: 186px;
          z-index: 2;
          margin-top: 32px;
        }
        .process__info {
          order: 2;
          margin-left: 0;
          text-align: left;
          max-width: initial;
        }
          .number {
            font-size: 175px;
            top: -37px;
            right: initial;
            left: 0;
            z-index: 1;
          }
 
        .process__img {
          &::after {
            width: 30px;
            height: 30px;
            bottom: inherit;
            top: -20px;
            left: initial;
            right: 30px;
                      }
          &::before {
            width: 12px;
            height: 12px;
            left: initial;
            top: -32px;
            right: 16px;
          }
      }
        .process__img--big {
            top: -24px;
            left: inherit;
            width: 228px;
            height: 193px;
            right: 10px;
        }
        .process__img--small {
            width: 114px;
            height: 203px;
            top: -77px;
            right: inherit;
            left: 50px;
        }
          
      }
      .process__row_3 {
          .number {
            font-size: 175px;
            top: -33px;
            right: -5px;
            z-index: 3;
          }
          .process__img {
          height: 181px;
          &::after {
            width: 30px;
            height: 30px;
            bottom: initial;
            left: 62px;
            top: -29px;
          }
          &::before {
            width: 12px;
            height: 12px;
            left: 90px;
            top: -43px;
          }
      }
        .process__img--big {
            top: -29px;
            left: -8px;
        }
        .process__img--small {
            top: -87px;
            right: 24px;
        }   
      }
    }

    .process__info-text-container {
      padding: 11px 0;
      display: flex;
      justify-content: space-between;
      width: 420px;
      /* margin-bottom: 10px; */
    }

    .process__info-text-image-wrapper {
      width: 22px;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      margin-right: 26px;
    }

    .process__info-text-circle {
      border-radius: 50%;
      background-color: #20D5C4;
      display: block;
    }

    .process__info-text-wrapper {
      width: 380px;
    }

    .process__row-mobile-text b {
      font-weight: 500;
    }
`;