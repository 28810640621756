import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './LoginModal.module.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import { fetchDataNotAuth, requestPasswordReset } from "../../../api/helperFunctions";
import PopupVerify from '../../../_components/PopupVerify';
import {login} from '../../../store/actions/authenticationActions';

const Info = props => {
    const [isResentSet, setIsResentSet] = useState(false);

    return (
        <>
            <p>
                Forget Your Password?{' '}
                <a
                    to="#" className={'color-secondary'}
                    onClick={() => {
                        props.onForgotPassword(props.email).then(result => {
                            setIsResentSet(true);
                        }).catch(err => console.error(err));
                    }}
                >
                    {isResentSet
                        ? 'Password reset sent'
                        : 'Reset Password'
                    }
                </a>
            </p>
            <p>
                Want to signup?{' '}
                <NavLink to="/get-started" onClick={() => props.close()} className={'color-secondary'}>
                    Sign Up Here
                </NavLink>
            </p>
        </>
    );
};

export const LoginModal = ({ isVeryficationModalOpen, ...props }) => {
    const { show, setShow, onClick, loginUser, clearLoginErrors, history, errors } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [ emptyEmailError, setEmptyEmailError ] = useState('');

    const generalErrorMessage = errors?.errorLogin?.message;
    const emailErrorMessage = errors?.errorLogin?.errors?.email || emptyEmailError;
    const passwordErrorMessage = errors?.errorLogin?.errors?.password;


    const handleClose = () => {
        setEmail('');
        setPassword('');
        clearLoginErrors('');
        return setShow(false);
    };

    const onResend = (email) => {
        const form = {
            email,
        };

        fetchDataNotAuth('confirmation/re-send', 'POST', form)
            .then(res => {
                // props.toggleVerificationModal();
                // props.toggleLoginModal();
            }
            );
    };

    const onForgotPassword = async email => {
        if (!email) {
            setEmptyEmailError('Email required');
            throw new Error();
        }
        setEmptyEmailError('');
        try {
            await requestPasswordReset(email);
        } catch (e) {
            setEmptyEmailError(e.message);
            throw new Error(e);
        }
    };

    const onLogIn = () => {
        props.toggleVerificationModal();
        props.toggleLoginModal();
    };

    return (
        isVeryficationModalOpen ? (
            <PopupVerify
                onLogIn={onLogIn}
                onResend={onResend}
                name={''}
                email={email}
            />
        ) : (
                <Modal size="sm" show={show} onHide={handleClose}>
                    <Modal.Header>
                        <span className={styles.close_btn} onClick={() => handleClose()}>+</span>
                        <Modal.Title className={styles.title}>
                            Welcome Back!
                        </Modal.Title>
                        <p>Let's find you some great Connections</p>
                    </Modal.Header>
                    <Modal.Body className={styles.body}>
                        {generalErrorMessage && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block'}
                                style={{
                                    marginBottom: 10,
                                }}
                            >
                                {generalErrorMessage}
                            </Form.Control.Feedback>
                        )}

                        <Form.Group controlId="formEmail" style={{ position: 'relative' }}>
                            {(emailErrorMessage) && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className={`d-block ${styles.errorMsg}`}
                                >
                                    {emailErrorMessage}
                                </Form.Control.Feedback>
                            )}
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                className={
                                    ` ${emailErrorMessage
                                        ? styles.errorBorder
                                        : ''}`
                                }
                                type="email"
                                autoComplete="off"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" style={{ position: 'relative' }}>
                            {passwordErrorMessage && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className={`d-block ${styles.errorMsg}`}
                                >
                                    {passwordErrorMessage}
                                </Form.Control.Feedback>
                            )}
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                className={
                                    `${passwordErrorMessage
                                        ? styles.errorBorder
                                        : ''}`
                                }
                                name="password"
                                autoComplete="off"
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                                type="password"
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formRecaptcha"
                            style={{
                                marginTop: 20,
                            }}
                        ></Form.Group>

                        <div className={styles.submit}>
                            <Button
                                block
                                variant="outline-secondary"
                                type="submit"
                                onClick={() => loginUser(email, password, history, onClick)}
                            >
                                Log In
                            </Button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={styles.info}>
                        <Info
                            close={handleClose}
                            email={email}
                            toggleVerificationModal={props.toggleVerificationModal}
                            onForgotPassword={onForgotPassword}
                        />
                    </Modal.Footer>
                </Modal>
            )
    );
}
