import React, {useState} from 'react';
import logo from '../../assets/logo_light.png';
import styled from "styled-components";
import {nav} from './nav'
import {AdminHeader} from './AdminHeader'
import {ReactComponent as Dashboard} from "../../assets/admin/icons/dashboard.svg";
import {ReactComponent as Speakers} from "../../assets/admin/icons/speakers.svg";
import {ReactComponent as Businesses} from "../../assets/admin/icons/businesses.svg";
import {ReactComponent as Billing} from "../../assets/admin/icons/billing.svg";
import {NavLink} from "react-router-dom";
import './styles.scss'

export const AdminPanelLayout = (props) => {
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    const handleSidebarOpen = () => {
        setSidebarIsOpen(!sidebarIsOpen)
    }

    const handleSidebarMouseOver = () => {
        setSidebarIsOpen(true);
    }

    const handleSidebarMouseLive = () => {
        setSidebarIsOpen(false);
    }

    return (
        <AdminLayoutStyles>
            <nav className={sidebarIsOpen ? 'admin__sidebar active' : 'admin__sidebar'} onMouseLeave={handleSidebarMouseLive}>
                <div className="sidebar-header">
                    <div className="logo-holder"><img src={logo} alt="logo"/></div>
                </div>
                <div className="sidebar-container">
                    <div className="sidebar-static" onMouseOver={handleSidebarMouseOver}>
                        <div className="sidebar-header">
                            <button className="menu-btn" type="button" onClick={handleSidebarOpen}/>
                        </div>
                        <NavLink className="nav-item" to={'/admin-panel/dashboard'}><Dashboard/></NavLink>
                        <NavLink className="nav-item" to={'/admin-panel/speakers'}><Speakers/></NavLink>
                        <NavLink className="nav-item" to={'/admin-panel/businesses'}><Businesses/></NavLink>
                        <NavLink className="nav-item" to={'/admin-panel/billing'}><Billing/></NavLink>
                    </div>
                    <ul className="nav">
                        {
                            nav.map(({id, title, link}) => {
                                return (
                                    <li key={id} className={props.location.pathname.includes(link)
                                        ? 'nav-item active'
                                        : 'nav-item'}
                                    >
                                        <NavLink className="nav-link" to={link}>
                                            {title}
                                        </NavLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </nav>
            <main className="admin__main">
                <AdminHeader/>
                <div className="content">
                    {props.children}
                </div>
            </main>
        </AdminLayoutStyles>
    );
}


const AdminLayoutStyles = styled.div`
    .menu-btn {
        background: transparent;
        position: absolute;
        top: 27px;
        left: 20px;
        padding: 10px;
        width: 44px;
        height: 35px;
        border: none;
        display: block;
        
        &:focus {
            outline: none;
        }
        
        &::before {
          content: '';
          position: absolute; 
          top: 0;
          left: 0;
          width: 24px;
          height: 3px;
          background: #fff;
          box-shadow: 0 6px 0 0 #fff, 0 12px 0 0 #fff;
          transition: background-color 0.3s, box-shadow 0.3s;
        }
        
        &:hover::before {
          background: rgba(255,255,255,0.3);
          box-shadow: 0 6px 0 0 rgba(255,255,255,0.3), 0 12px 0 0 rgba(255,255,255,0.3);
        }
    }
    .admin__main {
        margin-left: 66px;
    }
    .content {
        padding: 70px;
        background: #F0F0F7;
        min-height: calc(100vh - 70px);
    }
    .admin__sidebar {
        margin-left: -194px;
        position: fixed;
        z-index: 1019;
        height: 100vh;    
        width: 260px;
        top: 0;
        bottom: 0;
        left: 0;
        transition: margin-left .4s ease-in-out;
        display: flex;
        flex-direction: column;
        padding: 0;
        color: #fff;
        background: #43425D;
        
        &.active {
            margin-left: 0;
            .logo-holder{
                display: block;
            }
            .menu-btn {
                display: none;
            }
            .sidebar-static .sidebar-header {
                background: transparent;
            }
        }
    }
    .sidebar-header {
        height: 70px;
        padding-top: 14px;
        position: relative;
        flex: 0 0 auto;
        text-align: center;
        background: #35344C;
    }
    
    .sidebar-static {
        padding: 0;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        width: 66px;
        height: 100vh;
        
        .sidebar-header {
            display: block;
            height: 70px;
            width: 50px;
            padding: 15px 0 0 22px;
            background: #35344C;
        }

        svg {
        width: 16px;
        height: 16px;
        display: block;
        
        fill: #fff;
        opacity: 0.8;
        transition: fill 0.3s ease-in-out;
        }
        
        .path {
          fill: #fff;
        }
        
        .nav-item {
            display: block;
            border-left: 4px solid transparent;
            width: 66px;
            height: 53px;
            padding: 20px 11px 16px 21px;
            background: #43425D;
        }
        
      
        .nav-item.active {
          border-left: 4px solid #A3A0FB;
            path,
            svg {
               fill: #A3A0FB;
            }
        }
        
        .nav-item:hover {
            path,
            svg {
               fill: #A3A0FB;
            }
        }
    }
    .nav {
        width: 260px;
        flex-direction: column;
        min-height: 100%;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        list-style: none;
    }  
    .nav-item {
        width: 260px;
        padding-left: 66px;
        position: relative;  
    }
    .nav-link {
        color: #fff;
        font-size: 15px;
        white-space: nowrap;
        line-height: 53px;
        padding: 0;
        
    }
    .admin__sidebar:hover .nav-item {
        width: 66px;   
    }  
    .logo-holder {
      height: 34px;
      width: 200px;
      position: relative;
      overflow: hidden;
      display: none;  
      margin-left: 30px;    

      img{
          width: 200px;
          height: auto;
      }
    }
`
