import React from 'react';
import styles from './BookingFormDetails.module.scss';

const BookingFormDetails = ({
  datePosted,
  companyName,
  contactName,
  eventDates,
  eventLocation
}) => {
  // if (!!!eventLocation) {
  //   eventLocation = 'Sydney Opera House Bennelong Point, Sydney NSW 2000';
  // }

  return (
    <div className={styles.bookingFormDetails}>
      <div className={styles.bookingFormDetailsItem}>
        <h2 className={styles.bookingFormDetailsItemLabel}>Date posted</h2>
        <div className={styles.bookingFormDetailsItemData}>{ datePosted }</div>
      </div>

      <div className={styles.bookingFormDetailsItem}>
        <h2 className={styles.bookingFormDetailsItemLabel}>Company name</h2>
        <div className={styles.bookingFormDetailsItemData}>{ companyName }</div>
      </div>

      <div className={styles.bookingFormDetailsItem}>
        <h2 className={styles.bookingFormDetailsItemLabel}>Contact name</h2>
        <div className={styles.bookingFormDetailsItemData}>{ contactName }</div>
      </div>

      <div className={styles.bookingFormDetailsItem}>
        <h2 className={styles.bookingFormDetailsItemLabel}>Event dates</h2>
        <div className={styles.bookingFormDetailsItemData}>{
          eventDates.map((date, index) => (
            <div key={index}>{ date }</div>)
          )
        }</div>
      </div>

      {/*<div className={styles.bookingFormDetailsItem}>*/}
      {/*  <h2 className={styles.bookingFormDetailsItemLabel}>Event location</h2>*/}
      {/*  <div className={styles.bookingFormDetailsItemData}>*/}
      {/*    {*/}
      {/*      eventLocation*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default BookingFormDetails;
