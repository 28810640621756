import { useEffect } from 'react';

const useBlockCrawling = ({ noindex, nofollow } = { noindex: true, nofollow: true }) => {
  useEffect(() => {
    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = [...(noindex ? ['noindex'] : []), ...(nofollow ? ['nofollow'] : [])].join(', ');
    document.head.appendChild(metaRobots);

    return () => {
      metaRobots.remove();
    };
  }, []);
};

export default useBlockCrawling;
