import React, { useEffect, useState } from "react";
import { Select } from "antd";
import styled from 'styled-components'
import { ReactComponent as Chevron } from '../../assets/icons/arrow-light.svg';

const options = [
    { id: 1, name: 'Recently added' },
    { id: 2, name: 'Oldest to recent ' },
    { id: 3, name: 'Fee (high to low)' },
    { id: 4, name: 'Fee (low to high)' },
];

const getOptionId = (sort, order) => {
    if (sort === 'recent') {
        if (order === 'asc') {
            return 2;
        } else if (order === 'desc') {
            return 1;
        }
    } else if (sort === 'fee') {
        if (order === 'asc') {
            return 4;
        } else if (order === 'desc') {
            return 3;
        }
    }
};

export const SortBySelect = ({ sort, order, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handlerSelect = (value) => {
        switch (value) {
            case 1:
                return onChange({ order: 'desc', sort: 'recent' })
            case 2:
                return onChange({ order: 'asc', sort: 'recent' });
            case 3:
                return onChange({ sort: 'fee', order: 'desc' });
            case 4:
                return onChange({ sort: 'fee', order: 'asc' });
        }
    }

    return (
        <SelectStyle
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
        >
            <Select
                open={isOpen}
                showArrow={false}
                value={getOptionId(sort, order)}
                optionFilterProp="children"
                onSelect={handlerSelect}
                dropdownClassName={'sort-by-select'}
            >
                {options.map(el => (
                    <Select.Option
                        key={el.id}
                        value={el.id}
                    >
                        {el.name}
                    </Select.Option>)
                )}
            </Select>
            <div className={`svg__wrapper`}>
                <Chevron />
            </div>
        </SelectStyle>
    )
}

const SelectStyle = styled.div`
  position:relative;
  height: 30px;

  .ant-select {
      height: 30px;
  }

  .svg__wrapper {
    width: 56px;
    height: 30px;
    position:absolute;
    right: 0;
    top: 0;
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
    z-index: 0;
    
    svg {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        display: inline-block;
    }
    
    path {
        fill: #293749;
    }
  }
    .ant-select-selection--single {
        box-shadow: 0 15px 50px rgba(167, 171, 172, 0.1);
        border-radius: 5px;
        border: none;
        width: 199px !important;
        height: 30px !important;   
    }
    
    .ant-select-selection__rendered {
        margin-right: 36px;
        margin-left: 16px;
        line-height: 56px;
        font-size: 12px;
        color: #293749;
        font-weight: 500;
        height: 30px;
        
        &:focus {
          outline: none;
        }
    }

    .ant-select-selection-selected-value {
        height: 30px;
        font-size: 12px;
        line-height: 30px;
    }
    
    .ant-select-focused .ant-select-selection, 
    .ant-select-selection:focus, .ant-select-selection:active {
        border: none;
        box-shadow: none;
    }
`;