const initialState = {
    step1: {
        photo: '',
        contact_number: '',
        gender: '',
        tagline: '',
        address: ['', ''],
        cover_picture: '',
        professional_title: '',
        price_from: '',
        price_to: '',
        first_name: '',
        last_name: '',
    }
};

const steps = (state = initialState, action) => {
    switch (action.type) {
        case 'STEP_1_SAVE':
            return { ...state, step1: action.payload };
        case 'STEP_2_SAVE':
            return { ...state, step2: action.payload };
        case 'STEP_3_SAVE':
            return { ...state, step3: action.payload };
        default:
            return state;
    }
};

export default steps;
