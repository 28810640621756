import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import styled from "styled-components";
import useBlockCrawling from '../../helpers/useBlockCrawling';
import bg from '../../assets/get-start-page/get_start_bg.png';
import footerBg from '../../assets/footer-bg.webp';
import { ReactComponent as Chevron } from "../../assets/icons/chevron.svg";
import { Link } from "react-router-dom";
import { GreenLine } from '../../HomePage/GreenLine';
// import SignUpFormCode from '../SignUpFormCode'
import  SignUpEmail  from '../SignUpEmail'
import SignUpFormBusiness from './SignUpFormBusiness'
import { fetchResendConfirmation } from '../../api/helperFunctions'
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import logo from "../../assets/findersspeakers-logo-white.png";

import {
    postRegisterAction,
    toggleAuthSteps,
} from '../../store/actions';

const GetStartedForBusiness = ({
    userData,
    errors,
    postRegisterRequest,
    step,
    toggleAuthSteps,
}) => {
    useBlockCrawling();

    const [resentBtnText, setResentBtnText] = useState('Resend email');
    const windowSize = useWindowDimensions();
const test = 'resend email'
    useEffect(() => {
        toggleAuthSteps(0);
    }, [])

    const resendConfirmation = async () => {
        const res = await fetchResendConfirmation('confirmation/re-send');
        (res.ok || !!res.message) && setResentBtnText('Re-sent successfully');
        setTimeout(() => {
            setResentBtnText('Resend email');
        }, 3000)
        toggleAuthSteps(2);
    }

    const handleSubmitSuccess = useCallback(() => async () => {
        const data = {
            first_name: '',
            last_name: '',
            business_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            role: 'speaker',
            // number: { code: '', phone: '' },
            agree: false,
            captcha: false,
            code: '000000',
            receive_emails: false,
        }

        await postRegisterRequest(data);

    }, [step === 1]);


    return (
        step !== 1
            ? (
                <SignUpFormStyles>
                    <section className="get-start__info get-start-block" style={{ display: 'flex' }}>
                        <div className="section-wrapper">
                            <Link to="/" className="logo">
                                <img
                                    src={logo}
                                    alt="logo"
                                />
                            </Link>
                            {
                                windowSize.width > 1024
                                    ? <h1 className="get-start__title">
                                        {`Find the `}
                                        <GreenLine
                                            bgImg={() => require('../../assets/get-start-page/green_line_get_start.svg')}
                                            target={'perfect '}
                                            scrollPosition={0}
                                            moveBottom={'-2px'}
                                            lineHeight={'20px'}
                                        />speaker match<br /> for your next event!
                                    </h1>
                                    : <h1 className="get-start__title">
                                        {`Find the `}
                                        <GreenLine
                                            bgImg={() => require('../../assets/get-start-page/get-started-mobile.svg')}
                                            target={'perfect '}
                                            scrollPosition={600}
                                            moveBottom={'-2px'}
                                            lineHeight={'20px'}
                                        />
                                        <br />
                                        {`speaker match`}
                                        <br />
                                        {`for your next event!`}
                                    </h1>
                            }

                            <p className="get-start__text">
                                <span className="d-block">
                                    Search. Connect, Book. Get direct access to top talent, any topic, type, budget. Finders Speakers is your full-service speaker booking platform.
                        </span>
                            </p>
                            <ul className="get-start__list">
                                <li className="get-start__item">
                                    <span className="aqua">1. </span>
                            Sign up
                        </li>
                                <li className="get-start__item">
                                    <span className="aqua">2. </span>
                            Start searching….
                        </li>
                                <li className="get-start__item">
                                    <span className="aqua">3. </span>
                            Book and nail the event brief
                        </li>
                            </ul>
                            <Link to={`/how-it-works`} className='get-start__link'>
                                Watch to see how it works
                                <Chevron />
                            </Link>
                        </div>
                    </section>

                    <SignUpFormBusiness
                        data={userData}
                        errorsData={errors}
                    />
                </SignUpFormStyles>
            )
            : (
                <SignUpEmail
                    data={userData}
                    resendConfirmation={resendConfirmation}
                    resetReduxState={handleSubmitSuccess}
                    resentBtnText={'test'}
                />
            )
    );
}

export const SignUpFormStyles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .get-start-block {
      justify-content: center;

      @media (max-width: 769px) {
        justify-content: center;
        padding-bottom: 50px;
      }
    }
    
    .section-wrapper {
        width: 500px;

        @media (max-width: 1260px) {
          width: 420px;
        }
    }
    
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
    
    .aqua {
        color: #20D5C4;
    }
    
    .logo {
        display: block;
        margin-top: 40px;
        
        img{
          max-height: 52px;
        }

        @media (max-width: 1024px) {
          display: none; 
        }
    }
    
    .get-start{
        &__info {
          position: fixed;
          top: 0;
          left: 0;
          width: calc((100% / 2) - 14px);
          background-image: url(${footerBg});
          background-repeat: no-repeat;
          background-size: cover;
          // background-color: #293749;
          padding: 40px 109px 120px 120px;
          min-height: 100vh;
          height: 100%;
          padding: 0;
          
          @media (max-width: 1280px) {
              width: 560px;
              min-width: 560px;
          }

          @media (max-width: 1260px) {
              width: 42%;
              min-width: 42%;
              flex-basis: 42%;
          }

          @media (max-width: 1024px) {
              position: static;
              min-width: 100%;
              min-height: auto;
              padding: 40px 16px;
              width: 100%;
              order: 2;
          } 

          @media (max-width: 600px) {
              padding-top: 30px;
          }
      }  

        &__title {
            margin-top: 60px;
            font-weight: 400; 
            font-size: 44px;
            line-height: 48px;
            color: #FFFFFF;
            margin-bottom: 30px;

            @media (max-width: 600px) {
              width: 100%;
              font-size: 36px;
              line-height: 42px;
              margin-bottom: 20px;
              font-weight: 600;
              margin-top: 15px;
          }
        }
        
        &__text {
            font-size: 18px;
            line-height: 24px;
            color: #ABB0BE;
            margin-bottom: 28px;
            width: 420px;

            @media (max-width: 768px) {
              width: 100%;
            }

            @media (max-width: 600px) {
              height: 112px;
            } 
        }
        
        &__list {
            padding: 0;
            margin: 0 0 40px 0;
            list-style-type: none;
            width: 717px;
            
            @media (max-width: 1600px) {
              width: 520px;
            }
            
            @media (max-width: 1280px) {
              width: 390px;
            }
            
            @media (max-width: 768px) {
              width: 100%;
            }

            @media (max-width: 768px) {
              margin-bottom: 24px;
            } 
        }
        
        &__item {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #FFFFFF;
            margin-bottom: 24px;
            width: 717px;
            
            @media (max-width: 768px) {
              width: 100%;
            }

            @media (max-width: 768px) {
              margin-bottom: 12px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
        
        &__link {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-transform: uppercase;
            color: #E8388A;
            cursor: pointer;
            width: 717px;
            
            @media (max-width: 1600px) {
                width: 520px;
            }
            
            @media (max-width: 1280px) {
                width: 390px;
            }

            @media (max-width: 1280px) {
              font-size: 14px;
              line-height: 20px;
            }
            
            svg {
                display:inline-block;
                margin-left: 17px;
                fill: #E8388A;
                width: 6px;
                
                path {
                  fill: #E8388A;
                }
            }
        }
    }
`;

const mapDispatchToProps = {
    postRegisterRequest: postRegisterAction,
    toggleAuthSteps,
};

const mapStateToProps = state => ({
    errors: state.errors,
    userData: state.verification.userData,
    isRegistrationInit: state.verification.isRegistrationInit,
    step: state.authStep,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetStartedForBusiness);
