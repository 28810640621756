import { ActionTypes } from '../constants/createEnquiryConstants';

const {
    SET_ENQUIRY_FIELD,
    CLEAR_ENQUIRY_STORE,
    SET_ENQUIRY_DATES,
    SET_ENQUIRY_BUDGET,
    DELETE_ENQUIRY_FILE,
    SAVE_LOCAL_DATES_VALUES,
} = ActionTypes;

const sendEnquiryInitialState = {
    data: {
        event_name: '',
        dates: [
            {
                start_date: '',
                end_date: '',
            },
        ],
        budget: '',
        personal_message: '',
        files: [],
        // HARDCODE
        company_name: '',
        // optional - for mvp
        // contact_number: 'temp',
        email: '',
        event_contact: '',
        /* optional - for mvp
        event_location: {
            latitude: '50.406822',
            longitude: '30.543808',
        },
        */
        message: '',

        users: [],
    },
    localDatesValues: [
        {
            year: '',
            month: '',
            day: '',
        },
    ],
    sending: false,
    send: false,
    error: false,
    errorData: {},
};

const sendEnquiryReducer = (
    state = sendEnquiryInitialState,
    { type, payload }
) => {
    switch (type) {
        case SET_ENQUIRY_FIELD: {
            const { name, value } = payload;
            if (name === 'files') {
                const copyData = { ...state.data };
                copyData.files = [...copyData.files, ...value];

                return { ...state, data: copyData };
            }
            const copyData = { ...state.data };
            copyData[name] = value;

            return { ...state, data: copyData };
        }
        case SET_ENQUIRY_DATES: {
            const currentDatesArray = payload;
            const copyData = { ...state.data };
            copyData.dates = currentDatesArray;

            return { ...state, data: copyData };
        }
        case SAVE_LOCAL_DATES_VALUES: {
            return { ...state, localDatesValues: payload };
        }
        case SET_ENQUIRY_BUDGET: {
            const value = payload;
            const copyData = { ...state.data };
            copyData.budget = value;
            return { ...state, data: copyData };
        }
        case DELETE_ENQUIRY_FILE: {
            const idx = payload;
            const copyData = { ...state.data };
            const filteredFiles = state.data.files.filter(
                (el, elemIdx) => elemIdx !== idx
            );
            const filteredCopyDaya = { ...copyData, files: filteredFiles };
            return { ...state, data: filteredCopyDaya };
        }
        case CLEAR_ENQUIRY_STORE: {
            const initialDates = [
                {
                    start_date: '',
                    end_date: '',
                },
            ];
            const copyInitialState = { ...sendEnquiryInitialState };
            copyInitialState.data = {
                ...copyInitialState.data,
                dates: initialDates
            };
            copyInitialState.localDatesValues = [
                { year: '', month: '', day: '' },
            ];
            return { ...state, ...copyInitialState };
        }
        default:
            return state;
    }
};

export default sendEnquiryReducer;
