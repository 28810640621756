import axios from 'axios';
import { PATH } from './constants';
import EnquiryModel from '../_models/EnquiryModel';
import BookingModel from '../_models/BookingModel';
import { ValidationError, AccessDeniedError } from '../errors';

const loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci animi aperiam autem consequuntur cupiditate dignissimos distinctio est eum eveniet, hic inventore iste itaque iure laudantium libero magni modi mollitia nisi omnis perferendis provident quam quis quisquam quo, ratione reiciendis saepe similique vitae voluptate voluptatibus? Hic nemo optio quasi quidem rem sunt veniam voluptas. Accusantium aliquam cupiditate, eos esse incidunt, laborum laudantium libero magnam optio pariatur perspiciatis quam quos repellat sapiente sint totam vero! Accusamus aliquam animi aperiam at atque, beatae cumque deserunt dignissimos eligendi enim esse est excepturi illum ipsam itaque iusto labore molestiae nulla quis quod repudiandae sit velit?;';

const API = axios.create({
  baseURL: PATH,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

class AuthError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthError';
  }
}

class ApiClient {
  constructor(axiosClient) {
    let token = localStorage.getItem('token');

    if (!token) {
      try {
        token = JSON.parse(localStorage.getItem('state')).user.token.token;
      } catch (e) {}
    }

    if (token) {
      this.token = token;
      axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
    } else {
      axiosClient.defaults.headers.common['Authorization'] = '';
    }

    axiosClient.interceptors.response.use(
      response => response,
      error => {
        let newError = error;
        if (error.response.status === 401 && error.response.config.url !== '/login') {
          newError = new AuthError('Session dropped. Please sign out and sign in again');
          this.drop();
        }

        return Promise.reject(newError);
      }
    );

    this.getAxiosClient = () => axiosClient;
  }

  isAuthenticated() {
    return !!this.token;
  }

  drop() {
    this.token = '';
    localStorage.removeItem('token');
    localStorage.removeItem('state');
  }

  async login(email, password) {
    try {
      const result = await this.getAxiosClient().post('/login', { email, password });

      if (result.data.token) {
        this.token = result.data.token;
        localStorage.setItem('token', this.token);
        this.getAxiosClient().defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
      }
      return result.data;
    } catch (e) {
      if (e.response.status === 401) {
        throw new Error(e.response.data.message);
      }

      if (e.response.status === 422) {
        const error = new Error(e.response.data.message);
        error.errors = e.response.data.errors;
        throw error;
      }

      throw new Error('Unknown error');
    }
  }

  async loginByToken(token) {
    try {
      const result = await this.getAxiosClient().post('/login-by-token', { token });

      if (result.data.token) {
        this.token = result.data.token;
        localStorage.setItem('token', this.token);
        this.getAxiosClient().defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
      }
      return result.data;
    } catch (e) {
      if (e.response.status === 401) {
        throw new Error(e.response.data.message);
      }

      if (e.response.status === 422) {
        const error = new Error(e.response.data.message);
        error.errors = e.response.data.errors;
        throw error;
      }

      throw new Error('Unknown error');
    }
  }

  async requestEmailChange(password, newEmail) {
    try {
      await this.getAxiosClient().put('/profiles/update-email', {
        email: newEmail,
        password
      });
    } catch (e) {
      if (e.response) {
        if (e.response.status === 422) {
          if (e.response.data) {
            if (e.response.data.errors) {
              if (e.response.data.errors.password) {
                throw new Error(e.response.data.errors.password);
              } else if (e.response.data.errors.email) {
                throw new Error(e.response.data.errors.email);
              }
            }
          }
        }
      }

      throw new Error('Your request to change email is failed');
    }
  }

  async verifyNewEmail(token) {
    try {
      const result = await this.getAxiosClient().post('/verify-new-email', { token });

      this.token = result.data.token;
      localStorage.setItem('token', this.token);
      this.getAxiosClient().defaults.headers.common['Authorization'] = 'Bearer ' + this.token;

      return result.data;
    } catch (e) {
      const tokenErrorMessage = e?.response?.data?.errors?.token;

      if (tokenErrorMessage) {
        throw new Error(tokenErrorMessage);
      }

      throw new Error('Failed to confirm email');
    }
  }

  async confirmEmailChange(token) {
    try {
      const result = await this.getAxiosClient().post('/verify-token', { token });

      return result.data.user.email;
    } catch (e) {
      const tokenErrorMessage = e?.response?.data?.errors?.token;

      if (tokenErrorMessage) {
        throw new Error(tokenErrorMessage);
      }

      throw new Error('Failed to confirm email change');
    }
  }

  async cancelEmailChange(token) {
    try {
      await this.getAxiosClient().post('/profiles/delete-token', { token });
    } catch (e) {
      const tokenErrorMessage = e?.response?.data?.errors?.token;

      if (tokenErrorMessage) {
        throw new Error(tokenErrorMessage);
      }

      throw new Error('Failed to revert email change');
    }
  }

  async me() {
    const result = await this.getAxiosClient().get('/me');

    if (result.data.status === 'success') {
      return result.data.response.user;
    } else {
      throw new Error('Failed to load user');
    }
  }

  async about() {
    const result = await this.getAxiosClient().get('/profile/about');

    return result.data;
  }

  async getAccountDetails() {
    const result = await this.getAxiosClient().get('/profiles');

    return result.data.response;
  }

  async setAccountDetails(data) {
    try {
      const result = await this.getAxiosClient().put('/profiles', data);

      return result.data;
    } catch (e) {
      console.error(e);

      if (e.response) {
        const { status, data } = e.response;
        if (status === 422) {
          throw new ValidationError(data.message, data.errors);
        }

        throw new Error(data.message);
      }

      throw new Error('Unknown error');
    }
  }

  async logout() {
    try {
      await this.getAxiosClient().post(`/logout`);
    } catch (e) {}
    this.drop();
  }


  async uploadFile(file, targetInfo) {
    const formData = new FormData();

    formData.append('media', file);
    formData.append('target', targetInfo);

    try {
      const response = await this.getAxiosClient().post(`/profile/upload-media`, formData);

      return response.data.data;
    } catch (e) {
      console.error(e);
      const { response } = e;
      if (response && response.status === 422) {
        throw new Error(response.data.errors.media);
      } else {
        throw new Error('Unknown error');
      }
    }
  }

  async getShortlist() {
    const result = await this.getAxiosClient().get(`/finder/short-list`);
    return result.data;
  }

  async addUsersToShortlist(speakerIds) {
    const result = await this.getAxiosClient().post(`/finder/short-list`, { speakers: speakerIds });
    return result.data;
  }

  async removeUserFromShortlist(speakerIds) {
    const result = await this.getAxiosClient().delete(`/finder/short-list`, { data: { speakers: speakerIds }});
    return result.data;
  }

  async getSpeaker (id) {
    const result = await this.getAxiosClient().get(`/finder/speakers/${id}`);
    return result.data;
  }

  async getSpeakers(value) {
    if (typeof value === 'string') {
      const result = await this.getAxiosClient().get(`/finder/speakers?${value}`);

      return result.data;
    } else if (typeof value === 'object') {
      const {
        keywords,
        topics,
        types,
        event_types,
        location,
        budgets,
        genders,
        trending
      } = value;

      const result = await this.getAxiosClient().get(`/finder/speakers`, {
        params: {
          keywords,
          topics,
          types,
          event_types,
          location,
          budgets,
          genders,
          trending
        }
      });

      return result.data;
    } else throw new TypeError('Wrong query parameter');
  }

  async getTrendingSpeakersByTopic(topicAlias) {
    const result = await this.getAxiosClient().get(`/get/topic-trending-speakers?topic=${topicAlias}`);

    return result.data;
  }

  async getSpeakerTypes() {
    const result = await this.getAxiosClient().get(`/get/types`);
    return result.data;
  }

  async getEventFormats() {
    const result = await this.getAxiosClient().get(`/get/event-types`);
    return result.data;
  }

  async getTopics() {
    const result = await this.getAxiosClient().get(`/get/topics`);
    return result.data;
  }

  async sendContactUsRequest(data) {
    return this.getAxiosClient().post(`/contact-us`, data);
  }

  fakeCreateEnquiry (enquiryData) {
    return this.getAxiosClient().post(`business/enquiry/fake-create`, enquiryData);
  }

  async getEnquiryByIdSpeaker(id) {
    const response = await this.getAxiosClient().get(`/speaker/enquiries/${id}`);

    if (response.status === 200) {
      return new EnquiryModel(response.data);
    } else {
      throw new Error('Unknown error');
    }
  }

  async getEnquiryByIdBusiness(id) {
    const response = await this.getAxiosClient().get(`/business/enquiries/${id}`);

    if (response.status === 200) {
      return new EnquiryModel(response.data);
    } else {
      throw new Error('Unknown error');
    }
  }

  async getEnquiriesSpeaker(page, search, order, tab) {
    const statuses = tab === 'enquiries' ? 'accepted,canceled' : tab === 'complete' ? 'completed,refunded' : null;
    const response = await this.getAxiosClient().get(`/speaker/enquiries`, {
        params: {
          page,
          statuses,
          search: search || null,
          sortDirection: order || null
        }
    });

    if (response.status === 200) {
      try {
        const data = response.data.data.map(item => ({
          id: item.id,
          enquiry: new EnquiryModel(item),
          status: item.status,
          chat: item.chat,
          bookings: item.bookings
        }));
        const next = !!response.data.next_page_url;
        return {
          data,
          next
        };
      } catch (e) {
        throw new Error('Unknown error');
      }
    }

    throw new Error('Wrong status');
  }

  async getInboundEnquiries(page) { // test only
    const response = await this.getAxiosClient().get(`/speaker/enquiries`, {
      params: { page, statuses: 'pending' }
    });

    if (response.status === 200) {
      try {
        const data = response.data.data.map(item => ({
          id: item.id,
          enquiry: new EnquiryModel(item)
        }));
        const next = !!response.data.next_page_url;
        return {
          data,
          next
        };
      } catch (e) {
        throw new Error('Unknown error');
      }
    }

    throw new Error('Wrong status');
  }

  async getEnquiriesBusiness(page, search, order, tab) {
    const statuses = tab === 'enquiries' ? 'pending,accepted,declined,canceled' : tab === 'complete' ? 'completed,refunded' : null;
    const response = await this.getAxiosClient().get(`/business/enquiries`, {
      params: {
        page,
        statuses,
        search: search || null,
        sortDirection: order || null
      }
    });

    if (response.status === 200) {
      try {
        const data = response.data.data.map(item => ({
          id: item.id,
          enquiry: new EnquiryModel(item.enquiry),
          speaker: item.user,
          status: item.status,
          bookings: item.enquiry.bookings.filter(booking => booking.user_id === item.user.id),
          chat: item.chat
        }));
        const next = !!response.data.next_page_url;
        return {
          data,
          next
        };
      } catch (e) {
        console.log(e);
        throw new Error('Unknown error');
      }
    }

    throw new Error('Wrong status');
  }

  async acceptEnquirySpeaker(id) {
    try {
      await this.getAxiosClient().post(`/speaker/enquiries/${id}/accept`);
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Failed to accept enquiry');
      }
    }
  }

  async reactivateEnquirySpeaker(id) {
    try {
      await this.getAxiosClient().post(`/speaker/enquiries/${id}/reactivate`);
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Failed to reactivate enquiry');
      }
    }
  }

  async reactivateEnquiryBusiness(enquiryId, speakerId) {
    try {
      await this.getAxiosClient().post(`/business/enquiries/${enquiryId}/speakers/${speakerId}/reactivate`);
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Failed to reactivate enquiry');
      }
    }
  }

  async declineEnquirySpeaker(id) {
    try {
      await this.getAxiosClient().post(`/speaker/enquiries/${id}/decline`);
    } catch (e) {
      if (e.response) {
        throw new Error(e.response.data.message || e.response.status);
      } else {
        throw e;
      }
    }
  }

  async cancelEnquirySpeaker(id) {
    try {
      await this.getAxiosClient().post(`/speaker/enquiries/${id}/cancel`);
    } catch (e) {
      if (e.response) {
        throw new Error(e.response.data.message || e.response.status);
      } else {
        throw e;
      }
    }
  }

  async cancelEnquiryBusiness(enquiryId, speakerId) {
    try {
      await this.getAxiosClient().post(`/business/enquiries/${enquiryId}/speakers/${speakerId}/cancel`);
    } catch (e) {
      if (e.response) {
        throw new Error(e.response.data.message || e.response.status);
      } else {
        throw e;
      }
    }
  }

  async getBookings(page) {
    const response = await this.getAxiosClient().get(`/bookings`, { params: { page } });

    if (response.status === 200) {
      try {
        const data = response.data.data.map(booking => new BookingModel(booking));
        const next = !!response.data.next_page_url;
        return {
          data,
          next
        };
      } catch (e) {
        console.error(e);
        throw new Error('Unknown error');
      }
    }

    throw new Error('Wrong status');
  }

  async getBookedEnquiriesSpeaker(page, search, order) {
    const response = await this.getAxiosClient().get(`/speaker/enquiries`, {
      params: {
        page,
        statuses: 'booked',
        search: search || null,
        sortDirection: order || null
      }
    });

    if (response.status === 200) {
      try {
        const data = response.data.data.map(item => ({
          id: item.id,
          enquiry: new EnquiryModel(item),
          status: item.status,
          chat: item.chat
        }));
        const next = !!response.data.next_page_url;
        return {
          data,
          next
        };
      } catch (e) {
        throw new Error('Unknown error');
      }
    }

    throw new Error('Wrong status');
  }

  async getBookedEnquiriesBusiness(page, search, order) {
    const response = await this.getAxiosClient().get(`/business/enquiries`, {
      params: {
        page,
        statuses: 'booked',
        search: search || null,
        sortDirection: order || null
      }
    });

    if (response.status === 200) {
      try {
        const data = response.data.data.map(item => ({
          id: item.id,
          enquiry: new EnquiryModel(item.enquiry),
          speaker: item.user,
          status: item.status,
          bookings: item.enquiry.bookings.filter(booking => booking.user_id === item.user.id),
          chat: item.chat
        }));
        const next = !!response.data.next_page_url;
        return {
          data,
          next
        };
      } catch (e) {
        console.log(e);
        throw new Error('Unknown error');
      }
    }

    throw new Error('Wrong status');
  }

  async getBooking(id) {
    try {
      const response = await this.getAxiosClient().get(`/bookings/${id}`);
      return new BookingModel(response.data);
    } catch (e) {
      if (e.response) {
        if (e.response.status === 404) {
          throw new Error('Booking not found.');
        } else {
          throw new Error(`Failed to get the booking`);
        }
      } else {
        throw new Error(`Failed to get the booking`);
      }
    }
  }

  calculateBookingBill(bookingData) {
    const {
      speakerFee,
      incrementals = [],
      gstVat,
      paymentPercentage
    } = bookingData;
    return this.getAxiosClient().post(`/bookings/calculate`, {
      budget: speakerFee,
      incrementals,
      gst_vat_percent: gstVat !== null ? gstVat : undefined,
      split_percentage: paymentPercentage
    });
  }

  getBillingDetails() {
    return this.getAxiosClient().get(`/profile/billing-details`).then(response => {
      return response.data.response;
    });
  }

  createBillingDetails(entityDto) {
    return this.getAxiosClient().post(`/profile/billing-details`, entityDto).then(response => {
      return response.data.response;
    });
  }

  async updateBillingDetails(entityDto) {
    return this.getAxiosClient().put(`/profile/billing-details`, entityDto).then(response => {
      return response.data.response;
    });
  }

  // for speaker only
  async createBooking(enquiryId, bookingData) {
    const {
      speakerFee,
      agreement,
      contract,
      incrementals,
      eventName,
      brief,
      additionalNote,
      paymentPercentage,
      secondPaymentDate
    } = bookingData;

    console.log(paymentPercentage);
    console.log(secondPaymentDate);

    try {
      await this.getAxiosClient().post(`/bookings`, {
        enquiry_id: enquiryId,
        budget: speakerFee,
        agreement,
        contract,
        incrementals,
        event_name: eventName,
        brief,
        notes: additionalNote,
        split_percentage: paymentPercentage,
        second_payment_date: secondPaymentDate
      });
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to create booking');
      }
    }
  }

  // for speaker only
  async sendBooking(bookingId, bookingData) {
    const {
      speakerFee,
      agreement,
      contract,
      incrementals,
      eventName,
      brief,
      additionalNote,
      paymentPercentage,
      secondPaymentDate
    } = bookingData;

    try {
      await this.getAxiosClient().put(`/bookings/${bookingId}`, {
        budget: speakerFee,
        agreement,
        contract,
        incrementals,
        event_name: eventName,
        brief,
        notes: additionalNote,
        split_percentage: paymentPercentage,
        second_payment_date: secondPaymentDate
      });
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to send booking');
      }
    }
  }

  async createBookingDraft(enquiryId, bookingData) {
    const {
      speakerFee,
      agreement,
      contract,
      incrementals,
      eventName,
      brief,
      additionalNote,
      paymentPercentage,
      secondPaymentDate
    } = bookingData;

    try {
      await this.getAxiosClient().post(`/bookings/draft`, {
        enquiry_id: enquiryId,
        budget: speakerFee,
        agreement,
        contract,
        incrementals,
        event_name: eventName,
        brief,
        notes: additionalNote,
        split_percentage: paymentPercentage,
        second_payment_date: secondPaymentDate
      });
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to create draft');
      }
    }
  }

  async updateBookingDraft(bookingId, bookingData) {
    const {
      speakerFee,
      agreement,
      contract,
      incrementals,
      eventName,
      brief,
      additionalNote,
      paymentPercentage,
      secondPaymentDate
    } = bookingData;

    try {
      await this.getAxiosClient().put(`/bookings/${bookingId}/draft`, {
        budget: speakerFee,
        agreement,
        contract,
        incrementals,
        event_name: eventName,
        brief,
        notes: additionalNote,
        split_percentage: paymentPercentage,
        second_payment_date: secondPaymentDate
      });
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to update draft');
      }
    }
  }

  // for speaker only
  async deleteBooking(bookingId) {
    try {
      await this.getAxiosClient().delete(`/bookings/${bookingId}`);
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to decline booking');
      }
    }
  }

  // test only
  simulateBooking(enquiryId, speakerId) {
    return this.getAxiosClient().patch(`/enquiries/${enquiryId}/speakers/${speakerId}/status`, { status: 4 });
  }

  async declineBooking(bookingId) {
    try {
      await this.getAxiosClient().post(`/bookings/${bookingId}/decline`);
    } catch (e) {
      if (e.response.status < 500) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to decline booking');
      }
    }
  }

  // for business only
  async acceptBooking(bookingId, updatedAt) {
    try {
      const result = await this.getAxiosClient().post(`/bookings/${bookingId}/accept`, {
        updated_at: updatedAt || undefined
      });

      return {
        linkOnPayment: result.data.response.link_on_payment
      };
    } catch (e) {
      if (e.response.status < 500) {
        if (e.response.status === 403) {
          throw new AccessDeniedError(e.response.data.message, e.response.data.description);
        }
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to accept booking');
      }
    }
  }

  async hideEnquirySpeaker(id) {
    try {
      await this.getAxiosClient().put(`speaker/enquiries/${id}/change-visibility`);
    } catch (e) {
      if (e.response.status < 500 && e.response?.data?.message) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to delete');
      }
    }
  }

  async hideEnquiryBusiness(enquiryId, speakerId) {
    try {
      await this.getAxiosClient().put(`business/enquiries/${enquiryId}/speakers/${speakerId}/change-visibility`);
    } catch (e) {
      console.log(e);
      if (e.response.status < 500 && e.response?.data?.message) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error('Unable to delete');
      }
    }
  }

  async getInvoices(page) {
    try {
      const response = await this.getAxiosClient().get(`/invoices`, {
        params: {
          page,
          order: 'desc'
        }
      });

      return {
        list: response.data.data,
        hasNext: !!response.data.next_page_url
      };
    } catch (e) {
      throw new Error('Failed to fetch invoices');
    }
  }

  async fetchBusinessesAdmin(page, sort, order) {
    try {
      const response = await this.getAxiosClient().get(`/admin/businesses`, {
        params: {
          page,
          sort,
          order
        }
      });

      return response.data;
    } catch (e) {
      throw new Error(e);
    }
  }

  async verifyFinderAdmin(businessId) {
    try {
      await this.getAxiosClient().post(`/admin/businesses/${businessId}/verify`);
    } catch (e) {
      if (e.response.status === 404) {
        throw new Error('Business not found');
      } else {
        throw new Error('Failed to verify business');
      }
    }
  }

  async deleteFinderAdmin(businessId) {
    try {
      await this.getAxiosClient().delete(`/admin/businesses/${businessId}`);
    } catch (e) {
      if (e instanceof AuthError) {
        throw e;
      }

      if (e.response.status === 404) {
        throw new Error('Business not found');
      } else {
        throw new Error('Failed to delete business');
      }
    }
  }

  async getSpeakerAccountDetailsAdmin(speakerId) {
    try {
      const { data } = await this.getAxiosClient().get(`/admin/speakers/${speakerId}`);

      return data.data;
    } catch (e) {
      if (e.response.status === 404) {
        throw new Error('Speaker not found');
      } else {
        throw new Error('Failed to fetch speaker');
      }
    }
  }

  async saveSpeakerAccountDetailsAdmin(speakerId, data) {
    try {
      await this.getAxiosClient().put(`/admin/speakers/${speakerId}/update-profile`, data);
    } catch (e) {
      console.error(e);

      if (e.response) {
        const { status, data } = e.response;
        if (status === 422) {
          throw new ValidationError(data.message, data.errors);
        }

        throw new Error(data.message);
      }

      throw new Error('Unknown error');
    }
  }

  changeSpeakerTrendingAdmin(speakerId, isTrending) {
    return this.getAxiosClient().put(`/admin/speakers/${speakerId}/trending`, {
      trending: isTrending
    });
  }

  async getFindersCsvAdmin() {
    try {
      const result  = await this.getAxiosClient().post(`/admin/businesses/export/csv`);
      return result.data;
    } catch (e) {
      if (e instanceof AuthError) {
        throw e;
      }

      throw new Error('Failed to fetch csv');
    }
  }


  async getStripeConnectAccountLink() {
    const result = await this.getAxiosClient().post(`/stripe-connect`);

    return result.data.response.onboard_link_of_user;
  }

  async disconnectStripe() {
    await this.getAxiosClient().post(`/stripe-disconnect`);
  }
}

const ApiDataProvider = new ApiClient(API);

export default ApiDataProvider;
