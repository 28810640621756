import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { toggleSignUpBusinessModal } from '../../store/actions.js';
import { ReactComponent as Chevron } from '../../assets/icons/arrow-light.svg';
import { Link, useHistory } from "react-router-dom";
import constants from './../../constants';
import lightHeart from "../../assets/icons/lightHeart.svg";
import heart from "../../assets/icons/heart_light.png";
import plus from '../../assets/icons/+.png';
import keynote from '../../assets/icons/keynote.png';

export const SpeakerBackSideCard = ({ id, expertise, tagline, addSpeakerInShortList, first_name, last_name}) => {
  const shortList = useSelector(state => state.speakers.shortList.data);
  const isLoggedIn = !!useSelector(state => state.user.token);
  const history = useHistory();
  const dispatch = useDispatch();
  let added = shortList.some(el => el.id === id);

  const firstName = first_name.toLowerCase().replace(/\s/g, '');
  const lastName = last_name.toLowerCase().replace(/\s/g, '');


  return (
    <Speaker
      heart={shortList.some(el => el.id === id)
        ? lightHeart
        : heart
      }
    >
      <div
        className="speaker-card-shortlist-button"
        onClick={expertise ? () => addSpeakerInShortList(id) : () => { }}
        style={{ backgroundColor: !added ? '#20D5C4' : '#E8388A' }}
      >
        {added
          ? <img src={heart} />
          : <img src={plus} />
        }
      </div>

      <p className="card-text">
        {tagline}
      </p>

      <div className="card-keynotes">
        {expertise && expertise.speaker_type.map(elem => {
          if (elem && elem.includes('/')) {
            elem = elem.split('/').join(' ');
          };
          return (
            <div className="card-keynotes__elem" key={elem}>
              <img src={keynote} />
              <div className="card-keynotes__text">{elem}</div>
            </div>
          )
        })}
      </div>

      <div
        className="card__footer"
        style={{ cursor: 'pointer' }}
        onClick={e => {
          window.open(`speakers/${id}`, "_blank");

          /*
          if (localStorage.speakerProfileViews) {
            localStorage.speakerProfileViews = +localStorage.speakerProfileViews + 1;
          } else {
            localStorage.speakerProfileViews = 1;
          }

          if (localStorage.speakerProfileViews > constants.maxProfileViews) {
            e.preventDefault();
            dispatch(toggleSignUpBusinessModal());
          } else {
            window.open(`preview-speaker/${id}`, "_blank");
          }
           */
        }}
      >
        <span
          className="btn-enquire"
        >View profile <Chevron /></span>
      </div>
    </Speaker>
  );
};

const Speaker = styled.div`
    box-sizing: border-box;
    background-color: #E0619D;
    border-radius: 5px;
    box-shadow: 0 15px 50px rgba(167, 171, 172, 0.1);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0px 5px 0px 5px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .card-text {
      height: 35%;
      margin-top: 25px;
      margin-bottom: 20px;
      color: #fff;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }

    .speaker-card-shortlist-button {
      top: 4px;
      right: 4px;
    }

    .btn-enquire {
      border: none;
      background-color: transparent;
      padding-right: 22px;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      text-transform: uppercase;
      color: #fff;
      position: relative;
      white-space: nowrap;
      transition: font-size 0.3s linear;
      text-decoration: underline;
      
      svg {
         fill: #fff;
         position: absolute;
         right: 5px;
         top: 50%;
         transform: translateY(-50%);
         transition: right 0.3s ease-in-out;
         
         path {
            fill: #fff;
         }
      }
    
      &:focus {
        outline: none;
      }
      
      &:hover {
        background-position: right 0 center;
        text-decoration: none;
        font-size: 18px;
      }
    }

    .card-keynotes {
        align-items: flex-start;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-shadow: 0px 13.2px 44px rgba(167, 171, 172, 0.1);
        border-radius: 4.4px;
        margin-bottom: 10px;

        &__elem {
          width: 93px;
          height: 38px;
          background-color: #EF4C98;
          display: flex;
          justify-content: flex-start;
          padding: 7px;
          align-items: center;
          font-weight: normal;
          font-size: 8px;
          line-height: 10px;
          color: #ffffff;
          border-radius: 5px;
          margin-bottom: 10px;
        }

        &__text {
          padding-left: 8px;
          font-weight: normal;
          font-size: 8px;
          line-height: 10px;
        }
    }

    .card__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
  }

    .add-btn {
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        margin: 25px 0 32px;
        background-color: transparent;
        transform: translate(0, -10px);
        padding-left: 22px;
        background-image: url(${props => props.heart});
        background-repeat: no-repeat;
        background-position: ${props => props.heart === lightHeart ? '0 3px' : '0 4px'};
        background-size: ${props => props.heart === lightHeart ? '16px' : '18px'};
        
    @media screen and (min-width: 1280px) {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }

    @media screen and (max-width: 1280px) {
        transform: none;
    }

    & img {
        width: 18px;
        height: 18px;
        margin-right: 11px;
    }
    
    svg {
      width: 16px;
      margin-right: 10px;
      display: inline-block;
      position:relative;
      z-index: 0;
    }

    path {
      fill: #fff;
    }
  }

    .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 66px;
        border-top: 1px solid #E9E9E9;
        font-size: 14px;
        line-height: 18px;
        color: #797979;
    
    @media screen and (min-width: 1280px) {
        font-size: 12px;
        line-height: 16px;
    }
    
    @media screen and (min-width: 1280px) {
      height: 52px;
    }
    
    &__location {
        width: 50%; 
        display: flex;
        justify-content: center; 
        align-items: center;
    }
    
    &__salary {
        width: 50%;
        display: flex;
        justify-content: center;    
        align-items: center;
        border-left: 1px solid #E9E9E9;
    }
}

    @media (max-width: 768px) {
       background-position: center top -3px;
   }
`;