import React from 'react';

import s from './styles.module.scss';

export default ({ children, menu }) => {
  return (
    <div className={s.container}>
      {children}
      <div className={s.menu}>
        {menu}
      </div>
    </div>
  );
};
