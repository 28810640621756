import { AdminPanelDashboard } from './AdminPanelDashboard';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
const mapStateToProps = state => {
    return {
        user: state.user,
        isVerified: state.user.isVerified,
    };
};
export default withRouter(connect(
    mapStateToProps,
    {}
)(AdminPanelDashboard));