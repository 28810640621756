import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ApiDataProvider from '../api/apiClient';
import { setUserEmail } from '../store/actions/profileActions'
import MessageBoxUserModal from '../_components/modals/MessageBoxUserModal';
import Spinner from '../_components/Spinner';

const VerificationEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newEmail, setNewEmail] = useState('');


  useEffect(() => {
    ApiDataProvider.confirmEmailChange(token)
      .then(updatedEmail => {
        setNewEmail(updatedEmail);
        setIsSuccess(true);
      }).catch(e => {
        console.error(e);
        setErrorMessage(e.message);
      }).finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return <MessageBoxUserModal
    isOpen={true}
    title={isSuccess ? 'Your new email is confirmed' : 'Failed to confirm your email'}
    message={errorMessage}
    onOk={() => {
      dispatch(setUserEmail(newEmail));
      history.push('/account-details');
    }}
  />;
};

export default VerificationEmail;
