import EventAbstract from './EventAbstract';

class BookingModel extends EventAbstract {
  constructor(apiDataItem) {
    const { created_at, enquiry: { dates, budget_from, budget_to } } = apiDataItem;
    super(created_at, dates, budget_from, budget_to);
    this.data = apiDataItem;
  }
}

export default BookingModel;
