import React from 'react';
import { FAQHero } from "./FAQHero";
import FAQ from "./FAQTabs";
import { GreenLine } from "../HomePage/GreenLine";
import { SpeakersPage } from "../HomePage/Redisign/SpeakerPage";

export const FAQPage = () => {
  const mainTitle = (
        <span style={{ fontWeight: 'normal' }}>
            <span className="new-line new-line-xs">
                Hello! How can
            </span>
            <GreenLine
                bgImg={() => require('../assets/faq-page/green_line_faq.svg')}
                target={' we help?'}
                lineImgIdx={9}
                scrollPosition={0}
                moveBottom={window.outerWidth < 768 ? '-4px' : null}
            />
        </span>
    );

    return (
        <SpeakersPage>
            <main className="home">
                <FAQHero
                    title={mainTitle}
                    text={<span>You bring the expertise. We&rsquo;ll help you grow and future-proof your speaking business.</span>}
                    button={'Apply to join. it’s free'}
                />
                <FAQ />
            </main>
        </SpeakersPage>
    )
}
