import React from 'react';
import PhoneInput from 'react-phone-input-2';

const masks = {
  'au': '.. .... .... ..',
  'north-america': '... ... ... ... ...',
  'south-america': '... ... ... ... ...',
  'us': '... ... ... ... ...',
  'cn': '... ... ... ... ...',
  'cu': '... ... ... ... ...',
  'eg': '... ... ... ... ...',
  'gb': '... ... ... ... ...',
  'io': '... ... ... ... ...',
  'br': '... ... ... ... ...',
  'be': '... ... ... ... ...',
  'bs': '... ... ... ... ...',
  'ua': '.. .... .... ..',
  'de': '... ... ... ... ...',
  'ru': '... ... ... ... ...',
  'fr': '... ... ... ... ...',
  'jp': '... ... ... ... ...',
  'pt': '... ... ... ... ...',
  'it': '... ... ... ... ...',
  'ir': '... ... ... ... ...',
  'ar': '... ... ... ... ...',
  'tr': '... ... ... ... ...',
  'id': '... ... ... ... ...',
  'ai': '... ... ... ... ...',
};

export default function ({ value, onChange, className, ...props }) {
  return (
    <PhoneInput
      {...props}
      countryCodeEditable={true}
      masks={masks}
      placeholder="XXXX-XXX-XXX-XXX"
      className={className}
      value={value}
      onChange={onChange}
    />
  );
};
