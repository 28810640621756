import React from 'react';
import ApiDataProvider from '../../api/apiClient';
import { ActionTypes } from '../constants/createEnquiryConstants';

const {
  GET_SHORT_LIST_INIT,
  GET_SHORT_LIST_SUCCESS,
  GET_SHORT_LIST_ERROR,

  ADD_SHORT_LIST_INIT,
  ADD_SHORT_LIST_SUCCESS,
  ADD_SHORT_LIST_ERROR,

  REMOVE_SHORT_LIST_INIT,
  REMOVE_SHORT_LIST_SUCCESS,
  REMOVE_SHORT_LIST_ERROR
} = ActionTypes;

export const getShortList = () => {
  return async dispatch => {
    dispatch({ type: GET_SHORT_LIST_INIT });
    ApiDataProvider.getShortlist().then(result => {
      dispatch({ type: GET_SHORT_LIST_SUCCESS, payload: result });
    }).catch(() => {
      dispatch({ type: GET_SHORT_LIST_ERROR });
    });
  };
};

export const addUsersToShortlist = userIds => {
  return async (dispatch, getState) => {
    const state = getState();

    const role = state.user.role;

    if (role !== 'business') {
      return dispatch({ type: ADD_SHORT_LIST_ERROR });
    }

    dispatch({ type: ADD_SHORT_LIST_INIT });
    ApiDataProvider.addUsersToShortlist(userIds).then(result => {
      dispatch({ type: ADD_SHORT_LIST_SUCCESS, payload: result });
    }).catch(() => {
      dispatch({ type: ADD_SHORT_LIST_ERROR });
    });
  };
};

export const removeUsersFromShortlist = userIds => {
  return async dispatch => {
    dispatch({ type: REMOVE_SHORT_LIST_INIT });
    ApiDataProvider.removeUserFromShortlist(userIds).then(result => {
      dispatch({ type: REMOVE_SHORT_LIST_SUCCESS, payload: result });
    }).catch(() => {
      dispatch({ type: REMOVE_SHORT_LIST_ERROR });
    });
  };
};
