import React from 'react';
import bookIcon from '../../../assets/book-no-cover.png'
import pressIcon from '../../../assets/press-no-cover.png'
import podcastIcon from '../../../assets/podcast-no-cover.png'
import './style.scss';

const images = [bookIcon, pressIcon, podcastIcon];

class PreviewProfileBook extends React.Component {
    getLink(item) {
        return item.substr(0, 4) === 'http' ? item : `${process.env.REACT_APP_DO_SPACES_URL}/${item}`
    }

    render() {
        // get path to file: 'image_path' for podcasts and 'path' for the other types
        const pathProperty = this.props.type === 2 ? 'image_path' : 'path';
        const path = this.props.content[pathProperty];

        const urlProperty = this.props.type === 2 ? 'path' : 'url';
        const url = this.props.content[urlProperty];

      const fileExe = path
        ? path.split('.')[path.split('.').length - 1].toLowerCase()
        : '';

        return (
            <div className="publish">
                <div className="publish-img__wrap">
                  {path && fileExe !== 'pdf' ? (
                    <img
                      src={`${process.env.REACT_APP_DO_SPACES_URL}/${path}`}
                      alt="content"
                      className="publish-cover"
                    />
                  ) : (
                    <img
                      src={images[this.props.type]}
                      alt="content"
                      className="publish-img"
                    />
                  )}

                </div>
                <div className="publish-wrap">
                    <h3 className="publish-img__label">
                        {this.props.content.title}
                    </h3>

                    {url && (
                      <div>
                        <a href={this.getLink(url)}
                            target="_blank" className="publish-btn">VIEW here</a>
                      </div>
                    )}

                  {path && fileExe === 'pdf' && (
                    <div>
                      <a href={this.getLink(path)}
                         target="_blank" className="publish-btn">VIEW PDF</a>
                    </div>
                  )}
                </div>
            </div>
        );
    }
}

export default class PreviewProfilePublish extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            maxItem: 2,
            clicked: false
        };
    }

    editIndex(type) {
        this.setState({ active: type, clicked: true });
    }

    editMaxItem() {
        this.setState({ maxItem: Infinity });
    }

    render() {
      let activeTabIndex;

      if (this.state.clicked) {
        activeTabIndex = this.state.active;
      } else {
        const firstPopulated = this.props.content.findIndex(({ content }) => content.length);
        if (firstPopulated === -1) {
          activeTabIndex = 0;
        } else {
          activeTabIndex = firstPopulated;
        }
      }

        return (
            <div className="preview-profile-publish">
                <ul className="post__list">
                    {this.props.content.map((value, index) => {
                        return (
                            <React.Fragment key={value.title + index}>
                                <li
                                    className={[
                                        'post__list-elem',
                                      activeTabIndex !== index || 'check',
                                    ].join(' ')}
                                    onClick={() => this.editIndex(index)}
                                >
                                    {value.title}
                                </li>
                            </React.Fragment>
                        );
                    })}
                </ul>

                <div className="post__wrap">
                    {this.props.content[activeTabIndex].content.slice().reverse().map(
                        (value, index) => {
                            return index < this.state.maxItem ? (
                                <PreviewProfileBook
                                    key={index}
                                    content={value}
                                    type={activeTabIndex}
                                />
                            ) : null;
                        }
                    )}

                    {this.props.content[activeTabIndex].content.length ===
                        0 ? (
                        <div className="clear-span">
                            This speaker hasn't uploaded any content yet
                        </div>
                    ) : null}

                    {this.props.content[activeTabIndex].content.length >
                        this.state.maxItem ? (
                        <div
                            className="post__btn post__btn--load"
                            onClick={() => this.editMaxItem()}
                        >
                            {`view
                        ${this.props.content[activeTabIndex].content.length - this.state.maxItem}
                        more
                        ${this.props.content[activeTabIndex].title.substring(
                                0,
                                this.props.content[activeTabIndex].title.indexOf(' (')
                            )}`}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}