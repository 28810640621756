import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useLocation
} from 'react-router-dom';
import SpeakerPage from './HomePage/Redisign/';
import FAQ from './FAQPage';
import Enquiry from './CreateProfileScreens/CurrentEnquiryScreen/loadable';
import { SendOfferScreen } from './CreateProfileScreens/Offer/SendOfferScreen/loadable';
import { ViewOfferScreen } from './CreateProfileScreens/Offer/ViewOfferScreen/loadable';
import MainLayout from './_layout/MainLayout';
import PreviewProfileLayout from './_layout/PreviewProfileLayout';
import DashboardLayout from './_layout/DashboardLayout';
import DashboardProfileBuilderLayout from './_layout/DashboardProfileBuilderLayout';
import VerificationEmail from './_layout/VerificationEmail';
import InvalidVerify from './_layout/InvalidVerify';
import Payment from './_layout/Payment';
import Success from './test/Success';
import ThanksPage from './CreateProfileScreens/ProfileBuilder/ThanksPage';
import ThanksPageHide from './CreateProfileScreens/ProfileBuilder/ThanksPageHide';
import PreviewProfileContainer from './CreateProfileScreens/PreviewProfile/PreviewProfileContainer';
import SpeakerProfile from './CreateProfileScreens/PreviewProfile/SpeakerProfile';
import Chat from './ChatScreens/';
import { ComingSoonPage } from './ComingSoon/ComingSoonPage';
import ComingSoonPaymentPage from './ComingSoonPaymentPage';
import ComingSoonChatPage from './ComingSoonChatPage';
import ComingSoonMyStatsPage from './ComingSoonMyStatsPage';
import ComingSoonDashboardPage from './ComingSoonDashboardPage';
import ComingSoonTestimonialsPage from './ComingSoonTestimonialsPage'
import ComingSoonSearchPage from './ComingSoonSearchPage'
import { GetStartedFirstScreen } from './AuthScreens/GetStartedFirstScreen/GetStartedFirstScreen';
import Dashboard from './Dashboard/Dashboard';
import Business from './BusinessScreens/Dashboard';
import CreateEnquiresScreen from './CreateProfileScreens/CreateEnquiresScreen/loadable';
import About from "./AboutScreens";
import ContactUs from "./ContactUsScreens";
import GetStartLayout from "./_layout/GetStartLayout";
import HowItWorks from "./HowItWorks";
import HomeStage2Screen from "./HomeStage2Screens";
import FindSpeakersScreens from "./FindSpeakersScreens";
import PrivacyPolicy from "./PrivacyPolicyPage/PrivacyPolicy";
import TermsConditions from "./PrivacyPolicyPage/TermsConditions";
import TermsConditionsGetStarted from "./PrivacyPolicyPage/TermsConditionsGetStarted";
import ClearLayout from "./_layout/ClearLayout";
import AdminPanelLayout from "./AdminPanelScreens/AdminPanelLayout";
import AdminPanelDashboard from "./AdminPanelScreens/AdminPanelDashboard";
import AdminPanelSpeakers from "./AdminPanelScreens/AdminPanelSpeakers";
import AdminPanelBusinesses from "./AdminPanelScreens/AdminPanelBusinesses";
import AdminPanelBilling from "./AdminPanelScreens/AdminPanelBilling";
import GetStartedForBusiness from './AuthScreens/GetStartedForBusiness/GetStartedForBusiness';
import { AdminPanelLogin } from "./AdminPanelScreens/AdminPanelLogin/AdminPanelLogin";
import ResetPasswordPage from './ResetPasswordPage';
import  SignUpEmail  from './AuthScreens/SignUpEmailWrapper';
import EnquirySummary from './_components/EnquirySummary';
import BookingForm from './_components/BookingForm';
import { loadUser } from './store/actions/profileActions';
import ApiDataProvider from './api/apiClient';
import PaymentsAndInvoices from './AccountScreens/PaymentsAndInvoices';
import Payments from './AccountScreens/Payments';
import AccountDetailsUser from './AccountScreens/AccountDetailsUser';
import AccountDetailsAdmin from './AccountScreens/AccountDetailsAdmin';
import BillingDetails from './AccountScreens/BillingDetails';
import Loader from 'react-spinners/FadeLoader';
import NotFound from './NotFound';
import VerifyEmail from './VerifyEmail';
import ChangeEmail from './ChangeEmail';
import ChangeEmailCancel from './ChangeEmailCancel';
import InvoicesList from './AccountScreens/InvoicesList';
import ThankYouPage from './ThankYouPage';

import TopicPageInspiration from './TopicScreens/TopicPageInspiration';
import TopicPageMentalHealth from './TopicScreens/TopicPageMentalHealth';
import TopicPageLeadership from './TopicScreens/TopicPageLeadership';
import TopicPageCommunication from './TopicScreens/TopicPageCommunication';
import TopicPageDiversity from './TopicScreens/TopicPageDiversity';
import TopicPagePerformance from './TopicScreens/TopicPagePerformance';
import TopicPageSports from './TopicScreens/TopicPageSports';
import TopicPageBusiness from './TopicScreens/TopicPageBusiness';
import TopicPageInnovation from './TopicScreens/TopicPageInnovation';
import TopicPageKeynote from './TopicScreens/TopicPageKeynote';

const ProfileBuilderOne = lazy(() => import('./CreateProfileScreens/ProfileBuilder/ProfileBuilderOne'));
const ProfileBuilderTwo = lazy(() => import('./CreateProfileScreens/ProfileBuilder/ProfileBuilderTwo'));
const ProfileBuilderThree = lazy(() => import('./CreateProfileScreens/ProfileBuilder/ProfileBuilderThree'));
const ProfileBuilderFour = lazy(() => import('./CreateProfileScreens/ProfileBuilder/ProfileBuilderFour'));



const LoaderContainer = () => {
    return (
      <div
        style={{
            height: '100vh'
        }}
      >
          <div
            style={{
                margin: '100px auto 0',
                width: '58px'
            }}
          >
            <Loader color="#20d5c4" size={100} loading={true} />
          </div>
      </div>
    );
};

const GenericRoute = ({ redirectPath, children, ...rest }) => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const user = useSelector(state => state.user);

    if (isAuthenticated && !user.id) {
        return <LoaderContainer />;
    }

    if (redirectPath && (!isAuthenticated || !user.id)) {
        return <Redirect to={redirectPath} />;
    }

    return <Route {...rest}>{children}</Route>;
};

const NonAuthorizedRoute = props => {
    return <GenericRoute {...props} />;
};

const AuthorizedRoute = props => {
    return <GenericRoute redirectPath="/" {...props} />;
};

const AdminAuthorizedRoute = ({ children }) => {
    const user = useSelector(state => state.user);

    if (user.role !== 'admin') {
        return <Redirect to="/auth-admin" />;
    }

    return children;
};

const AdminRoute = ({ children, ...rest }) => {
    return <GenericRoute redirectPath="/auth-admin" {...rest}>
        <AdminAuthorizedRoute>{children}</AdminAuthorizedRoute>
    </GenericRoute>;
};



export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathname]);

    return null;
};

export default function Navigator() {
    const isSuperAdminLoggedIn = useSelector(store => store.user).role === 'admin';
    const isUserAuth = useSelector(store => store.user.token);
    const isVerified = useSelector(state => state.user.isVerified);
    const dispatch = useDispatch();

    useEffect(() => {
        if (ApiDataProvider.isAuthenticated()) {
            dispatch(loadUser());
        }
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <Switch>
                {/* restore 'exact'!!! */}
                <AuthorizedRoute path="/dashboard">
                    {/*
                    <DashboardLayout dashboardOffset>
                        <Dashboard />
                    </DashboardLayout>
                    */}

                    <DashboardProfileBuilderLayout
                        styleText={{color: 'white'}}
                        lightLogo={true}
                        hideFooter={true}
                        noNeedVerified={true}
                        theme="dark"
                    >
                        <Route component={ScrollToTop} />
                        <Route component={ComingSoonDashboardPage} />
                    </DashboardProfileBuilderLayout>
                </AuthorizedRoute>

                <AuthorizedRoute path="/dashboard/enquiries/:enquiryId/booking">
                    <DashboardLayout>
                        <BookingForm />
                    </DashboardLayout>
                </AuthorizedRoute>

                <AuthorizedRoute path="/dashboard/enquiries/:enquiryId/with_speaker/:speakerId">
                    <DashboardLayout>
                        <EnquirySummary />
                    </DashboardLayout>
                </AuthorizedRoute>

                <AuthorizedRoute path="/dashboard/enquiries/:enquiryId">
                    <DashboardLayout>
                        <EnquirySummary />
                    </DashboardLayout>
                </AuthorizedRoute>

                <AuthorizedRoute path="/dashboard/bookings/:bookingId">
                    <DashboardLayout>
                        <BookingForm />
                    </DashboardLayout>
                </AuthorizedRoute>

                <AuthorizedRoute path="/payments-invoices" exact>
                    {/*
                        <DashboardLayout>
                            <PaymentsAndInvoices />
                        </DashboardLayout>
                    */}
                    <DashboardProfileBuilderLayout
                        styleText={{color: 'white'}}
                        lightLogo={true}
                        hideFooter={true}
                        noNeedVerified={true}
                        theme="dark"
                    >
                      <Route component={ScrollToTop} />
                      <Route component={ComingSoonPaymentPage} />
                    </DashboardProfileBuilderLayout>
                </AuthorizedRoute>

                <AuthorizedRoute path="/payments" exact>
                    {/*
                    <DashboardLayout>
                        <Payments />
                    </DashboardLayout>
                    */}

                  <DashboardProfileBuilderLayout
                    styleText={{color: 'white'}}
                    lightLogo={true}
                    hideFooter={true}
                    noNeedVerified={true}
                    theme="dark"
                  >
                        <Route component={ScrollToTop} />
                        <Route component={ComingSoonPaymentPage} />
                    </DashboardProfileBuilderLayout>
                </AuthorizedRoute>

                <AuthorizedRoute path="/account-details" exact>
                    <DashboardLayout>
                        <AccountDetailsUser />
                    </DashboardLayout>
                </AuthorizedRoute>

                <AuthorizedRoute path="/billing-details" exact>
                    <DashboardProfileBuilderLayout
                        styleText={{color: 'white'}}
                        lightLogo={true}
                        hideFooter={true}
                        noNeedVerified={true}
                        theme="dark"
                    >
                        <Route component={ScrollToTop} />
                        <Route component={ComingSoonPaymentPage} />
                    </DashboardProfileBuilderLayout>

                    {/*
                    <DashboardLayout>
                        <BillingDetails />
                    </DashboardLayout>
                    */}
                </AuthorizedRoute>

                <Route path="/dashboard-old">
                    <Business />
                </Route>

                <Route path='/reset_password/:token'>
                    <ResetPasswordPage />
                </Route>

                {/* MainLayout */}

                <Route path='/sign-up-email'>
                    <SignUpEmail />
                </Route>

                <AuthorizedRoute path="/chat" exact>
                    {/*
                    <DashboardLayout chatOffset>
                        <Chat />
                    </DashboardLayout>
                    */}

                    <DashboardProfileBuilderLayout
                      styleText={{color: 'white'}}
                      lightLogo={true}
                      hideFooter={true}
                      noNeedVerified={true}
                      theme="dark"
                    >
                        <Route component={ScrollToTop} />
                        <Route component={ComingSoonChatPage} />
                    </DashboardProfileBuilderLayout>
                </AuthorizedRoute>

                <DashboardProfileBuilderLayout
                  path="/my-stats"
                  styleText={{color: 'white'}}
                  lightLogo={true}
                  hideFooter={true}
                  noNeedVerified={true}
                  theme="dark"
                >
                    <Route component={ScrollToTop} />
                    <Route component={ComingSoonMyStatsPage} />
                </DashboardProfileBuilderLayout>

                <MainLayout exact path="/coming-soon">
                    <Route component={ScrollToTop} />
                    <Route component={ComingSoonPage} />
                </MainLayout>

                <NonAuthorizedRoute path="/speaker-page" exact>
                    <MainLayout>
                        <SpeakerPage />
                    </MainLayout>
                </NonAuthorizedRoute>

                <NonAuthorizedRoute path="/" exact>
                    <MainLayout>
                        <HomeStage2Screen />
                    </MainLayout>
                </NonAuthorizedRoute>

                <MainLayout exact path="/find-speakers">
                    {/* <Route component={ScrollToTop} /> */}
                    <Route component={FindSpeakersScreens} />
                </MainLayout>

                <MainLayout exact path="/faq">
                    <Route component={ScrollToTop} />
                    <Route component={FAQ} />

                </MainLayout>

                <MainLayout exact path="/about">
                    <Route component={ScrollToTop} />
                    <Route component={About} />
                </MainLayout>

                <MainLayout exact path="/privacy">
                    <Route component={ScrollToTop} />
                    <Route component={PrivacyPolicy} />
                </MainLayout>

                <MainLayout exact path="/terms-conditions">
                    <Route component={ScrollToTop} />
                    <Route component={TermsConditions} />
                </MainLayout>

                <MainLayout exact path="/tc">
                    <Route component={ScrollToTop} />
                    <Route component={TermsConditionsGetStarted} />
                </MainLayout>

                <MainLayout exact path="/contact-us">
                    <Route component={ScrollToTop} />
                    <Route component={ContactUs} />
                </MainLayout>

                <MainLayout exact path="/how-it-works">
                    <Route component={ScrollToTop} />
                    <Route component={HowItWorks} />
                </MainLayout>

                <MainLayout exact path="/inspiration-and-motivation-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageInspiration} />
                </MainLayout>

                <MainLayout exact path="/mental-health-and-resilience-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageMentalHealth} />
                </MainLayout>

                <MainLayout exact path="/leadership-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageLeadership} />
                </MainLayout>

                <MainLayout exact path="/communication-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageCommunication} />
                </MainLayout>

                <MainLayout exact path="/diversity-and-inclusion-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageDiversity} />
                </MainLayout>

                <MainLayout exact path="/performance-and-productivity-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPagePerformance} />
                </MainLayout>

                <MainLayout exact path="/sports-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageSports} />
                </MainLayout>

                <MainLayout exact path="/business-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageBusiness} />
                </MainLayout>

                <MainLayout exact path="/innovation-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageInnovation} />
                </MainLayout>

                <MainLayout exact path="/keynote-speakers">
                    <Route component={ScrollToTop} />
                    <Route component={TopicPageKeynote} />
                </MainLayout>

                {/*
                <AuthorizedRoute path="/enquiry/:id" exact>
                    <DashboardLayout>
                        <Route component={ScrollToTop} />
                        <Route path="/enquiry/:id" component={Enquiry} />
                    </DashboardLayout>
                </AuthorizedRoute>
                */}

                <AuthorizedRoute path="/invoices" exact>
                    <DashboardProfileBuilderLayout styleText={{color: 'white'}} lightLogo={true}  hideFooter={true} noNeedVerified={true}>
                        <Route component={ScrollToTop} />
                        <Route component={ComingSoonPaymentPage} />
                    </DashboardProfileBuilderLayout>
                    {/*
                    <DashboardLayout>
                        <InvoicesList />
                    </DashboardLayout>
                    */}
                </AuthorizedRoute>

                <MainLayout exact path="/get-started">
                    <Route component={ScrollToTop} />
                    <Route component={GetStartedFirstScreen} />
                </MainLayout>

                <GetStartLayout path="/register">
                    <Route component={ScrollToTop} />
                    <Route component={GetStartLayout} />
                </GetStartLayout>

                <GetStartLayout exact path="/register-business">
                    <Route component={GetStartedForBusiness} />
                </GetStartLayout>

                <MainLayout exact path="/finders">
                    <Redirect to='/'/>
                </MainLayout>

                
                <AuthorizedRoute path="/builder-step1" exact>
                    <Suspense fallback={<LoaderContainer />}>
                        <DashboardProfileBuilderLayout>
                            <Route component={ScrollToTop} />
                            <Route component={ProfileBuilderOne} />
                        </DashboardProfileBuilderLayout>
                    </Suspense>
                </AuthorizedRoute>

                <AuthorizedRoute path="/builder-step2" exact>
                    <Suspense fallback={<LoaderContainer />}>
                        <DashboardProfileBuilderLayout>
                            <Route component={ScrollToTop} />
                            <Route component={ProfileBuilderTwo} />
                        </DashboardProfileBuilderLayout>
                    </Suspense>
                </AuthorizedRoute>

                <AuthorizedRoute path="/builder-step3" exact>
                    <Suspense fallback={<LoaderContainer />}>
                        <DashboardProfileBuilderLayout>
                            <Route component={ScrollToTop} />
                            <Route component={ProfileBuilderThree} />
                    </DashboardProfileBuilderLayout>
                    </Suspense>
                </AuthorizedRoute>

                <AuthorizedRoute path="/builder-step4" exact>
                    <Suspense fallback={<LoaderContainer />}>
                        <DashboardProfileBuilderLayout>
                            <Route component={ScrollToTop} />
                            <Route component={ProfileBuilderFour} />
                        </DashboardProfileBuilderLayout>
                    </Suspense>
                </AuthorizedRoute>

                <AuthorizedRoute path="/preview-profile">
                    <PreviewProfileLayout>
                        <PreviewProfileContainer />
                    </PreviewProfileLayout>
                </AuthorizedRoute>

                <NonAuthorizedRoute path="/verify-new-email/:token" exact>
                    <DashboardLayout>
                        <VerifyEmail />
                    </DashboardLayout>
                </NonAuthorizedRoute>

                <NonAuthorizedRoute path="/change-email/:token" exact>
                        <DashboardLayout>
                            <ChangeEmail />
                        </DashboardLayout>
                </NonAuthorizedRoute>

                <NonAuthorizedRoute path="/change-email-cancel/:token" exact>
                    <DashboardLayout>
                        <ChangeEmailCancel />
                    </DashboardLayout>
                </NonAuthorizedRoute>

                <DashboardProfileBuilderLayout path="/thanks-page/submitted" hideFooter={true}>
                    <Route component={ScrollToTop} />
                    <Route component={ThanksPage} />
                </DashboardProfileBuilderLayout>

                <DashboardProfileBuilderLayout path="/thanks-page/hide" hideFooter={true}>
                    <Route component={ScrollToTop} />
                    <Route component={ThanksPageHide} />
                </DashboardProfileBuilderLayout>
    

                <Route exact path="/thankyou">
                    <MainLayout>
                        <ThankYouPage />
                    </MainLayout>
                </Route>
                
                {/* MainLayout */}
                <DashboardProfileBuilderLayout
                  path="/testimonials"
                  styleText={{color: 'white'}}
                  lightLogo={true}
                  hideFooter={true}
                  noNeedVerified={true}
                  theme="dark"
                >
                    <Route component={ScrollToTop} />
                    <Route component={ComingSoonTestimonialsPage} />
                </DashboardProfileBuilderLayout>

                {/* MainLayout */}
                <MainLayout path="/search" styleText={{color: 'white'}} lightLogo={true}  hideFooter={true} noNeedVerified={true}>
                    <Route component={ScrollToTop} />
                    <Redirect to='/how-it-works' />
                </MainLayout>

                <Route path="/varification/email/:token">
                    <MainLayout>
                        <VerificationEmail />
                    </MainLayout>
                </Route>

                <Route path="/speakers/:id/:slug" exact>
                    <MainLayout>
                        <SpeakerProfile />
                    </MainLayout>
                </Route>

                <Route path="/speakers/:id" exact>
                    <MainLayout>
                        <SpeakerProfile />
                    </MainLayout>
                </Route>

                <MainLayout path="/verify/invalid">
                    {
                        isVerified ? <Route component={InvalidVerify} /> : <Redirect to="/" />
                    }
                </MainLayout>

                <MainLayout path="/success">
                    <Route component={ScrollToTop} />
                    <Route component={Success} />
                </MainLayout>

                <AuthorizedRoute path="/create-enquiry" exact>
                    <DashboardLayout>
                        <Route component={ScrollToTop} />
                        <Route component={CreateEnquiresScreen} />
                    </DashboardLayout>
                </AuthorizedRoute>


                <ClearLayout path="/auth-admin">
                    <Route component={ScrollToTop} />
                    <Route component={AdminPanelLogin} />
                </ClearLayout>

                <AdminRoute path="/admin/builder-step1/:speakerId">
                    <Suspense fallback={<LoaderContainer />}>
                        <AdminPanelLayout>
                            <Route component={ScrollToTop} />
                            <Route component={ProfileBuilderOne} />
                        </AdminPanelLayout>
                    </Suspense>
                </AdminRoute>

                <AdminRoute path="/admin/builder-step2/:speakerId">
                    <Suspense fallback={<LoaderContainer />}>
                        <AdminPanelLayout>
                            <Route component={ScrollToTop} />
                            <Route component={ProfileBuilderTwo} />
                        </AdminPanelLayout>
                    </Suspense>
                </AdminRoute>

                <AdminRoute path="/admin/builder-step3/:speakerId">
                    <Suspense fallback={<LoaderContainer />}>
                        <AdminPanelLayout>
                            <Route component={ScrollToTop} />
                            <Route component={ProfileBuilderThree} />
                        </AdminPanelLayout>
                    </Suspense>
                </AdminRoute>

                <AdminRoute path="/admin/builder-step4/:speakerId">
                    <Suspense fallback={<LoaderContainer />}>
                        <AdminPanelLayout>
                            <Route component={ScrollToTop} />
                            <Route component={ProfileBuilderFour} />
                        </AdminPanelLayout>
                    </Suspense>
                </AdminRoute>

                <AdminRoute path="/admin-panel/dashboard">
                    <AdminPanelLayout>
                        <AdminPanelDashboard />
                    </AdminPanelLayout>
                </AdminRoute>

                <AdminRoute path="/admin-panel/speakers" exact>
                    <AdminPanelLayout>
                        <AdminPanelSpeakers />
                    </AdminPanelLayout>
                </AdminRoute>

                <AdminRoute path="/admin-panel/businesses">
                    <AdminPanelLayout>
                        <AdminPanelBusinesses />
                    </AdminPanelLayout>
                </AdminRoute>


                <AdminRoute path="/admin-panel/billing">
                    <AdminPanelLayout>
                        <AdminPanelBilling />
                    </AdminPanelLayout>
                </AdminRoute>

                <AdminRoute path="/admin-panel/speakers/:speakerId">
                    <AdminPanelLayout>
                        <Route component={AccountDetailsAdmin} />
                    </AdminPanelLayout>
                </AdminRoute>

                <MainLayout>
                    <NotFound />
                </MainLayout>
            </Switch>
        </Router>
    );
}
