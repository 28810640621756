const statusesSpeaker = {
  PENDING: () => ({
    buttons: ['decline', 'accept', 'back']
  }),

  DECLINED: () => ({
    text: 'Declined',
    buttons: ['back']
  }),

  ACCEPTED: () => ({
    text: 'Accepted',
    buttons: ['back']
  }),

  BOOKED: () => ({
    text: 'Booked',
    buttons: ['booking', 'back']
  }),

  COMPLETED: allowReactivate => ({
    text: 'Completed',
    buttons: [...(allowReactivate ? ['reactivate'] : []), 'back']
  }),

  CANCELED: allowReactivate => ({
    text: 'Canceled',
    buttons: [...(allowReactivate ? ['reactivate'] : []), 'back']
  }),

  REFUNDED: () => ({
    text: 'Refunded',
    buttons: ['reactivate', 'back']
  }),
};

const statusesBusiness = {
  PENDING: () => ({
    buttons: ['cancel', 'back']
  }),

  DECLINED: () => ({
    text: 'Declined',
    buttons: ['back']
  }),

  ACCEPTED: () => ({
    buttons: ['back']
  }),

  BOOKED: () => ({
    text: 'Booked',
    buttons: ['back']
  }),

  COMPLETED: allowReactivate => ({
    text: 'Completed',
    buttons: [...(allowReactivate ? ['reactivate'] : []), 'back']
  }),

  CANCELED: allowReactivate => ({
    text: 'Canceled',
    buttons: [...(allowReactivate ? ['reactivate'] : []), 'back']
  }),

  REFUNDED: () => ({
    text: 'Refunded',
    buttons: ['reactivate', 'back']
  }),
};

export default (role, status, booking, bookingStatus = '') => {
  let statuses;
  if (role === 'speaker') {
    statuses = statusesSpeaker;
  } else if (role === 'business') {
    statuses = statusesBusiness;
  } else {
    return {};
  }

  const data = { ...statuses[status.toUpperCase()](bookingStatus.toUpperCase() === 'REFUNDED' || !bookingStatus) };

  if (status === 'ACCEPTED') {
    let action;
    if (bookingStatus && !['CANCELED', 'REFUNDED'].includes(bookingStatus.toUpperCase())) {
      action = 'booking';
    } else if (role === 'speaker') {
      action = 'book';
    }

    if (data.buttons) {
      data.buttons = [...data.buttons, action];
    } else {
      data.buttons = [action];
    }
  }

  return data;
};
