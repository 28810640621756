import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {Form} from "react-bootstrap";
import { login } from '../../store/actions/authenticationActions';


export const AdminPanelLogin = () => {
    const [emailError, setEmailError] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const errors = useSelector(state => state.errors);

    console.log(errors);

    const [formFields, setFormFields] = useState({
        email: '',
        password: '',
    });

    const handleChangeFields = (e) => {
        const {name, value} = e.target;
        setFormFields({
            ...formFields,
            [name]: value,
        })
    }
    const handleCheckEmail = () => {
        const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        setEmailError(!pattern.test(formFields.email));
    }

    const handleSubmit = () => {
        dispatch(login(
          formFields.email,
          formFields.password,
          () => {
              history.push('/admin-panel/dashboard');
          }
        ));
    };

    return(
        <LoginStyles className="d-flex">
            <div className="img-wrapper"/>
            <div className="login-form">
                <h2 className="title">FindersSpeakers</h2>
                <h4 className="subtitle">Welcome back Sophie!</h4>
                <Form className="form-wrapper">
                    <Form.Group controlId="signUpFormPhone" style={{position: 'relative', zIndex: 1}}>
                        <Form.Control
                            className="input"
                            type="email"
                            placeholder="Email"
                            onBlur={handleCheckEmail}
                            value={formFields.email}
                            name="email"
                            onChange={handleChangeFields}
                        />
                        {emailError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg'}
                            >
                                Email is not valid
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="signUpFormPhone" style={{position: 'relative', zIndex: 1}}>
                        <Form.Control
                            className="input"
                            type="password"
                            placeholder="Password"
                            value={formFields.password}
                            name="password"
                            onChange={handleChangeFields}
                        />
                        {emailError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg'}
                            >
                                This field is required
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    {errors.errorLogin && <p className="error-message">{errors.errorLogin.message}</p>}
                    <button
                        type="button"
                        className="btn-submit"
                        onClick={handleSubmit}
                    >
                        Login
                    </button>
                </Form>

            </div>
        </LoginStyles>
    )
}

const LoginStyles = styled.div`
.img-wrapper {
  flex-basis: 50%;
  background: #5A55AA;
  min-height: 100vh;
}

.login-form {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.title {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 7px;
    color: #43425D;
    margin-bottom: 12px;
}

.subtitle {
    text-align: center;
    font-size: 18px;
    font-weight:normal;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 0.5;
    margin-bottom: 50px;
}

.form-wrapper {
    width: 400px;
    text-align: right;
}

.btn-submit {
    width: 185px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    background: #43425D;
    border: 1px solid #43425D;
    border-radius: 4px;
    color: #fff;
    font-weight:normal;
    font-size: 18px;
    margin-top: 75px;
    transition: opacity 0.3s ease-in-out;
    
    &:hover {
        opacity: 0.8;
    }
    
    &:focus,
    &:active {
        outline: none;
    }
}

.input {
    display: block;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #E9E9F0;
    padding: 12px 6px ;
    width: 100%;
    background: transparent;
    margin-bottom: 34px;
    
    &:placeholder-shown {
        color: #43425D;
        font-size: 15px;
    }
    
    &:focus,
    &:active {
      outline: none;
    }  
}
.error-message {
  color: red;
}
.error-msg {
    position: absolute !important;
    top: 100% !important;
}
`