import s from '../EnquirySummary.module.scss';
import React from 'react';

const Details = ({ datePosted, companyName, contactName, eventDates }) => {
  return (
    <>
      <div className={s.enquiryDetailsItem}>
        <h2 className={s.enquiryDetailsItemLabel}>Date posted</h2>
        <div className={s.enquiryDetailsItemData}>{ datePosted }</div>
      </div>

      <div className={s.enquiryDetailsItem}>
        <h2 className={s.enquiryDetailsItemLabel}>Company name</h2>
        <div className={s.enquiryDetailsItemData}>{ companyName }</div>
      </div>

      <div className={s.enquiryDetailsItem}>
        <h2 className={s.enquiryDetailsItemLabel}>Contact name</h2>
        <div className={s.enquiryDetailsItemData}>{ contactName }</div>
      </div>

      <div className={s.enquiryDetailsItem}>
        <h2 className={s.enquiryDetailsItemLabel}>Event dates</h2>
        {
          eventDates.map((dateItem, index) => (
            <div className={s.enquiryDetailsItemData} key={index}>
              { dateItem }
            </div>
          ))
        }
      </div>
    </>
  );
};

export default Details;
