import { SignUpFormBusiness } from './SignUpFormBusiness';
import { connect } from 'react-redux';
import {
    postRegisterAction,
    toggleAuthSteps,
    toggleLoginModal,
} from '../../../store/actions';
import { resetErrors } from '../../../store/actions/createEnquiryActions';
const mapDispatchToProps = {
    toggleLoginModal,
    postRegisterRequest: postRegisterAction,
    toggleAuthSteps,
    resetErrors,
};

const mapStateToProps = storeState => ({
    errorsData: storeState.errors,
    token: storeState.user.token,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpFormBusiness);
