import React from 'react'
import styled from 'styled-components'
import constants from '../../../constants';
import check from '../../../assets/elips-check.svg'
import { Link } from 'react-router-dom';

export default () => {
    return <TPStyled>
        <div className="tp-content">
            <img src={check} className="tp-img" alt="check" />
            <h2 className="tp-title">Profile submitted!</h2>
            <p className="tp-text">Thanks for submitting your market-leading profile. Our team is currently reviewing and will come back to you in the next 3 working days with approval. You are just one step away from your profile going live.</p>
            <div style={{display: 'flex'}}>
                <Link to="/preview-profile"><div className="tp-btn">
                    VIEW PROFILE
                </div></Link>
                <Link to="/builder-step1"><div className="tp-btn">
                    EDIT PROFILE
                </div></Link>
            </div>
            
        </div>

        <div className="tp-footer">
            All Rights Reserved     {constants.copyright}
        </div>
    </TPStyled>
}

const TPStyled = styled.div`
    background: #20D5C4;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .tp {

        &-content {
            max-width: 384px;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &-img {
        }

        &-title {
            font-size: 28px;
            font-weight: 500;
            color: #293749;
            margin: 15px 0;
        }

        &-text {
            font-size: 13px;
            font-weight: 300;
            color: #293749;
            text-align: center;
        }

        &-btn {
            height: 35px;
            width: 125px;
            background: #E8388A;
            border-radius: 40px;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 5px 0;
        }

        &-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: 300;
            color: #293749;
        }
    }
`