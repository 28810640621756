import React from 'react';
import styles from './BookingForm.module.scss';
import BookingFormHeader from './BookingFormHeader';
import BookingFormDetails from './BookingFormDetails/BookingFormDetails';

export default function BookingForm({
  children,
  eventName,
  setEventName,
  brief,
  changeBrief,
  budget,
  datePosted,
  companyName,
  contactName,
  eventDates,
  eventLocation,
  additionalNote,
  setAdditionalNote,
  view,
  bookingStatus
}) {
  // editable only by speaker and only if form is new or declined by finder
  const isEditable = view === 'speaker' && (bookingStatus === 'DECLINED' || !bookingStatus);

  return (
    <div className={styles.container}>
      <h2 className={styles.mainHeading}>Booking offer form</h2>
      <div className={styles.content}>
        <div className={styles.first_column}>
          <BookingFormHeader
            eventName={eventName}
            setEventName={setEventName}
            brief={brief}
            changeBrief={changeBrief}
            additionalNote={additionalNote}
            setAdditionalNote={setAdditionalNote}
            budget={budget}
            view={view}
            bookingStatus={bookingStatus}
            isEditable={isEditable}
          />
          {
            children
          }
        </div>
        <div className={styles.second_column}>
          <div>
            <BookingFormDetails
              datePosted={datePosted}
              companyName={companyName}
              contactName={contactName}
              eventDates={eventDates}
              eventLocation={eventLocation}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
