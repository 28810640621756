const formatDecimal = value => {
  const decade = Math.floor(value * 10);
  if (decade % 10) {
    return (decade / 10).toFixed(1);
  }

  return Math.floor(value);
};

const shortenSpeakerFee = price => {
  if (price >= 1000000) {
    return formatDecimal(price / 1000000) + 'M';
  } else if (price >= 1000) {
    return formatDecimal(price / 1000) + 'k';
  } else return price;
};

const getThousands = price => {
  if (price < 1000) {
    return (price / 1000).toFixed(1) + 'k';
  }

  return Math.floor(price / 1000) + 'k';
};


export default (from, to) => {
  if (to && to !== from) {
    return `$${shortenSpeakerFee(from)} - $${shortenSpeakerFee(to)}`;
  }

  if (from > 0) {
    return `$${shortenSpeakerFee(from)}`;
  }

  return '';
};
