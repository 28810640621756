import {ActionTypes} from '../constants/createEnquiryConstants';
import {combineReducers} from 'redux';
const {
    SET_SPEAKER_LIST_INIT,
    SET_SPEAKER_LIST_SUCCESS,
    SET_SPEAKER_LIST_ERROR,

    GET_SPEAKER_INIT,
    GET_SPEAKER_SUCCESS,
    GET_SPEAKER_ERROR,

    GET_SEARCH_AUTOCOMPLETE_INIT,
    GET_SEARCH_AUTOCOMPLETE_SUCCESS,
    GET_SEARCH_AUTOCOMPLETE_ERROR,

    GET_SHORT_LIST_INIT,
    GET_SHORT_LIST_SUCCESS,
    GET_SHORT_LIST_ERROR,

    ADD_SHORT_LIST_INIT,
    ADD_SHORT_LIST_SUCCESS,
    ADD_SHORT_LIST_ERROR,

    REMOVE_SHORT_LIST_INIT,
    REMOVE_SHORT_LIST_SUCCESS,
    REMOVE_SHORT_LIST_ERROR,
        } = ActionTypes;

const initialState = {
    loading: false,
    loaded: false,
    errors: false,
    data: [],
    speaker: {},
};

function speakers(state = initialState, {type, payload}) {
    switch (type) {
        case SET_SPEAKER_LIST_INIT:
            return {...state, loading: true, loaded: false, errors: false};
        case SET_SPEAKER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                errors: false,
                data:  payload,
            };
        case SET_SPEAKER_LIST_ERROR:
            return {...state, loading: false, loaded: false, errors: true};

        case GET_SPEAKER_INIT:
            return {...state, loading: true, loaded: false, errors: false};
        case GET_SPEAKER_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                errors: false,
                speaker:  payload,
            };
        case GET_SPEAKER_ERROR:
            return {...state, loading: false, loaded: false, errors: true, speaker:  {},};
        default:
            return state;
    }
}

function searchAutocomplete(state = initialState, {type, payload}) {
    switch (type) {
        case GET_SEARCH_AUTOCOMPLETE_INIT:
            return {...state, loading: true, loaded: false, errors: false};
        case GET_SEARCH_AUTOCOMPLETE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                errors: false,
                data:  payload,
            };
        case GET_SEARCH_AUTOCOMPLETE_ERROR:
            return {...state, loading: false, loaded: false, errors: true};
        default:
            return state;
    }
}

function shortList(state = initialState, {type, payload}) {

    switch (type) {
        case GET_SHORT_LIST_INIT:
            return {...state, loading: true, loaded: false, errors: false};
        case GET_SHORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                errors: false,
                data:  payload,
            };
        case GET_SHORT_LIST_ERROR:
            return {...state, loading: false, loaded: false, errors: payload};

        case ADD_SHORT_LIST_INIT:
            return {...state, loading: true, loaded: false, errors: false};
        case ADD_SHORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                errors: false,
                data:  payload,
            };
        case ADD_SHORT_LIST_ERROR:
            return {...state, loading: false, loaded: false, errors: payload};

        case REMOVE_SHORT_LIST_INIT:
            return {...state, loading: true, loaded: false, errors: false};
        case REMOVE_SHORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                errors: false,
                data:  payload,
            };
        case REMOVE_SHORT_LIST_ERROR:
            return {...state, loading: false, loaded: false, errors: payload};
        default:
            return state;
    }
}

export default combineReducers({
    speakers,
    searchAutocomplete,
    shortList,
});
