import ApiDataProvider from '../api/apiClient';
import { ValidationError, NotFoundError } from '../errors';
import Transformer from './helpers/Transformer';


const transformer = new Transformer({
  companyName: 'company_name',
  companyNumber: 'company_number',
  gstVat: 'gst_vat_percent',
  gstEnabled: 'gst_vat_percent_enabled',
  stripeConnected: 'is_connected_to_stripe',
  addressLine1: 'street_address',
  addressLine2: 'address_line_2',
  country: 'country',
  city: 'city',
  region: 'state',
  postalCode: 'postal_zip_code'
});

class BillingDetailsModel {
  constructor(data) {
    const {
      companyName,
      companyNumber,
      gstVat,
      gstEnabled,
      stripeConnected,
      addressLine1,
      addressLine2,
      country,
      city,
      region,
      postalCode
    } = data;

    this._data = {
      companyName,
      companyNumber,
      gstVat,
      gstEnabled,
      stripeConnected,
      addressLine1,
      addressLine2,
      country,
      city,
      region,
      postalCode
    };
  }

  getData() {
    return this._data;
  }

  async save({ isNew }) {
    let method;

    if (isNew) {
      method = (...args) => ApiDataProvider.createBillingDetails(...args);
    } else {
      method = (...args) => ApiDataProvider.updateBillingDetails(...args);
    }

    try {
      await method(transformer.pack(this._data));
    } catch (e) {
      if (e.response) {
        const { status, data } = e.response;
        if (status === 422) {
          throw new ValidationError(data.message, transformer.unpack(data.errors));
        }

        throw new Error(data.message);
      }

      throw new Error('Unknown error');
    }
  }

  static async load() {
    let billingDetails;

    try {
      billingDetails = await ApiDataProvider.getBillingDetails();
    } catch (e) {
      if (e.response) {
        const { status, data } = e.response;
        if (status === 400) {
          throw new NotFoundError(data.message);
        }

        throw new Error(data.message);
      }

      throw new Error('Unknown error');
    }


    const {
      companyName,
      companyNumber,
      gstVat,
      gstEnabled,
      stripeConnected,
      addressLine1,
      addressLine2,
      country,
      city,
      region,
      postalCode
    } = transformer.unpack(billingDetails);

    return new BillingDetailsModel({
      companyName,
      companyNumber,
      gstVat,
      gstEnabled,
      stripeConnected,
      addressLine1,
      addressLine2,
      country,
      city,
      region,
      postalCode
    });
  }
}

export default BillingDetailsModel;
