import {LoginModal} from './LoginModal';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    postLoginRequest,
    clearLoginErrors,
    toggleLoginModal,
    toggleVerificationModal
} from '../../../store/actions';
import { loginUser } from '../../../store/actions/authenticationActions';
// FIRST STEP
const mapDispatchToProps = {
    toggleLoginModal,
    toggleVerificationModal,
    loginUser,
    clearLoginErrors //store.dispatch(postLoginRequest(payload))
};

const mapStateToProps = storeState => ({
    user: storeState.user,
    errors: storeState.errors,
    isVeryficationModalOpen: storeState.modal.isVerificationModalOpen,
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LoginModal)
);