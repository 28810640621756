import { useHistory, useLocation } from 'react-router-dom';

export const usePageParam = () => {
  const history = useHistory();

  const page = (new URLSearchParams(useLocation().search)).get('page');

  const currentPage = +page || 1;

  const setPage = newPage => {
    history.push(`?page=${newPage}`);
  };

  return [ currentPage, setPage ];
};
