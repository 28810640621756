import { useState, useEffect } from 'react';

const useCanonical = () => {
  const [link, setLink] = useState();

  useEffect(() => {
    if (!link) {
      return;
    }

    const canonicalLink = document.createElement('link');
    canonicalLink.rel = 'canonical';
    canonicalLink.href = link;

    document.head.appendChild(canonicalLink);

    return () => {
      canonicalLink.remove();
    };
  }, [link]);

  return setLink;
};

export default useCanonical;
