import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ApiDataProvider from '../../../api/apiClient';
import { AccessDeniedError } from '../../../errors';
import BillingDetailsModel from '../../../_models/BillingDetailsModel';
import Spinner from '../../../_components/Spinner';
import ConfirmationUserModal from '../../modals/ConfirmationUserModal';
import MessageBoxUserModal from '../../modals/MessageBoxUserModal';
import InvoiceLink from '../components/InvoiceLink';
import BookingForm from '../BookingForm';
import BookingFormOffer from '../BookingFormOffer/BookingFormOffer';
import poweredByStripe from '../../../assets/Powered_by_Stripe_-_blurple.svg';

const fetchStandardContractMock = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        name: 'standard contract',
        path: 'multimedia/6NttzBoXmz8swBajytzKzuzG4hZpYdMuYQjCd3kP.pdf'
      });
    }, 100);
  });
};

const BookingFormContainer = ({ enquiryId, bookingId, userRole }) => {
  const offerElement = useRef(null);

  const history = useHistory();

  const isConnectedToStripe = useSelector(state => state.user.isConnectedToStripe);

  const [standardAgreement, setStandardAgreement] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [noBillingDetailsError, setNoBillingDetailsError] = useState(null);

  const [isSending, setIsSending] = useState(false);
  const [errorLoadingMessage, setErrorLoadingMessage] = useState();
  const [actionSuccessMessage, setActionSuccessMessage] = useState('');
  const [actionErrorMessage, setActionErrorMessage] = useState('');
  const [status, setStatus] = useState();
  const [eventName, setEventName] = useState();
  const [brief, setBrief] = useState();
  const [budget, setBudget] = useState(null);
  const [datePosted, setDatePosted] = useState();
  const [companyName, setCompanyName] = useState();
  const [contactName, setContactName] = useState();
  const [eventDates, setEventDates] = useState([]);
  const [speakerFee, setSpeakerFee] = useState();
  const [gst, setGst] = useState(null);
  const [taxVisible, setTaxVisible] = useState(false);
  const [breakdown, setBreakdown] = useState();

  const [incrementals, setIncrementals] = useState([]);
  const [additionalNote, setAdditionalNote] = useState('');
  const [agreement, setAgreement] = useState(null);
  const [contract, setContract] = useState();

  const [secondPaymentDate, setSecondPaymentDate] = useState(null);
  const [paymentPercentage, setPaymentPercentage] = useState(50);
  const [paymentPercentageError, setPaymentPercentageError] = useState(null);

  const [updatedAt, setUpdatedAt] = useState(null);

  const [speakerFeeInvalid, setSpeakerFeeInvalid] = useState(false);
  const [agreementInvalid, setAgreementInvalid] = useState(false);
  const [contractInvalid, setContractInvalid] = useState(false);
  const [secondPaymentDateInvalid, setSecondPaymentDateInvalid] = useState(null);

  const [enquiryIdDelete, setEnquiryIdDelete] = useState(enquiryId);
  const [speakerIdDelete, setSpeakerIdDelete] = useState();

  const [paymentLink, setPaymentLink] = useState(null);

  useEffect(() => {
    (async () => {
      if (userRole === 'speaker') {
        setIsLoading(true);

        try {
          const billingDetails = await BillingDetailsModel.load();

          setTaxVisible(billingDetails.getData().gstEnabled);
        } catch (e) {
          setNoBillingDetailsError({
            role: 'speaker',
            message: e.message
          });

          throw e;
        } finally {
          setIsLoading(false);
        }
      }

      if (enquiryId) {
        setIsLoading(true);

        try {
          const enquiry = await ApiDataProvider.getEnquiryByIdSpeaker(enquiryId);

          setEventName(enquiry.data.event_name);
          setBrief(enquiry.data.personal_message);
          setBudget(enquiry.getBudget());
          setDatePosted(enquiry.getEventPostedDateFormatted());
          setCompanyName(enquiry.data.company_name);
          setContactName(enquiry.data.event_contact);
          setEventDates(enquiry.getEventDatesFormatted());
        } catch (e) {
          setErrorLoadingMessage(e.message);
        } finally {
          setIsLoading(false);
        }

        fetchStandardContractMock().then(result => {
          setStandardAgreement(result);
        });
      } else if (bookingId) {
        setIsLoading(true);

        try {
          const booking = await ApiDataProvider.getBooking(bookingId);

          setEventName(booking.data.event_name);
          setBrief(booking.data.brief);
          setBudget(booking.getBudget());
          setDatePosted(booking.getEventPostedDateFormatted());
          setCompanyName(booking.data.company_name);
          setContactName(booking.data.event_contact);
          setEventDates(booking.getEventDatesFormatted());
          setSpeakerFee(booking.data.budget);
          setAdditionalNote(booking.data.notes || '');

          setGst(booking.data.gst_vat_percent);

          setUpdatedAt(booking.data.updated_at);

          if (booking.data.gst_vat_percent) {
            setTaxVisible(!!booking.data.gst_vat_percent);
          }

          setEnquiryIdDelete(booking.data.enquiry_id);
          setSpeakerIdDelete(booking.data.user_id);

          setPaymentPercentage(booking.data.split_percentage);
          setSecondPaymentDate(booking.data.second_payment_date);

          let details;

          if (userRole === 'speaker') {
            details = booking.data.fee.details;
          } else if (userRole === 'business') {
            details = booking.data.fee.details_for_business;
          }

          const newBreakdown = {
            gstVat: details.netSpeakerFee.items.gstVat,
            netSpeakerFee: details.netSpeakerFee.amount,
            total: details.total.amount,
            firstInstalment: {
              platformFee: details.firstInstalment.items.platformFee,
              speakerFeeToClient: details.firstInstalment.items.speakerFeeToClient,
              gstVat: details.firstInstalment.items.gstVat,
              total: details.firstInstalment.amount
            }
          };

          if (details.secondInstalment.amount) {
            newBreakdown.secondInstalment = {
              incrementals: details.secondInstalment.items.incrementals,
              speakerFeeToClient: details.secondInstalment.items.speakerFeeToClient,
              gstVat: details.firstInstalment.items.gstVat,
              total: details.secondInstalment.amount
            };
          } else {
            newBreakdown.secondInstalment = null;
            newBreakdown.firstInstalment.incrementals = details.firstInstalment.items.incrementals;
          }

          if (userRole === 'speaker') {
            newBreakdown.platformFee = details.total.items.platformFee;
          }

          setBreakdown(newBreakdown);

          setIncrementals(booking.data.incrementals);

          if (booking.data.agreement === false) {
            setAgreement('custom');

            if (booking.data.contract && booking.data.contract.path) {
              setContract({
                name: booking.data.contract.original_filename,
                path: booking.data.contract.path
              });
            }

            fetchStandardContractMock().then(result => {
              setStandardAgreement(result);
            });
          } else if (booking.data.agreement === true) {
            setAgreement('standard');
            setStandardAgreement({
              name: booking.data.contract.original_filename,
              path: booking.data.contract.path
            });
          } else {
            fetchStandardContractMock().then(result => {
              setStandardAgreement(result);
            });
          }

          setStatus(booking.data.status);
        } catch (e) {
          setErrorLoadingMessage(e.message);
        } finally {
          setIsLoading(false);
        }
      }
    })().catch(e => console.error(e));
  }, []);

  const changeSpeakerFee = value => {
    setSpeakerFee(value);
    setSpeakerFeeInvalid(false);
  };

  const addIncremental = (description, amount) => {
    setIncrementals([...incrementals, { description, amount }]);
  };

  const deleteIncremental = index => {
    const newIncrementals = incrementals.filter((_, i) => i !== index);
    setIncrementals([...newIncrementals]);
  };

  const changeAgreement = value => {
    setAgreement(value);
    setAgreementInvalid(false);
    setContractInvalid(false);
  };

  const changeContract = value => {
    setContract(value);
    setContractInvalid(false);
  };

  const changePaymentPercentage = value => {
    setPaymentPercentage(value);
    setPaymentPercentageError(null);

    if (value === 100) {
      setSecondPaymentDate(null);
      setSecondPaymentDateInvalid(false);
    }
  };

  const prepareData = () => {
    const bookingData = {
      speakerFee: speakerFee || null,
      agreement: agreement === 'standard' || (agreement === 'custom' ? false : null),
      contract: (agreement === 'custom' && contract)
        ? { original_filename: contract.name, path: contract.path }
        : agreement === 'standard'
          ? { original_filename: standardAgreement.name, path: standardAgreement.path }
          : { original_filename: null, path: null },
      incrementals,
      eventName: eventName || null,
      brief: brief || null,
      additionalNote: additionalNote || null,
      paymentPercentage,
      secondPaymentDate: secondPaymentDate || null
    };

    return Object.keys(bookingData).reduce(
      (acc, key) => bookingData[key] !== undefined ? ({ ...acc, [key]: bookingData[key]}) : acc,
      {}
    );
  };

  const validateAndPrepare = () => {
    let isFormValid = true;
    const validationError = new Error('Form validation failed');
    validationError.fields = {};

    if (typeof speakerFee !== 'number' || speakerFee < 0.01) {
      setSpeakerFeeInvalid(true);

      isFormValid = false;

      validationError.fields.speakerFee = true;
    }

    if (!agreement) {
      setAgreementInvalid(true);

      isFormValid = false;

      validationError.fields.agreement = true;
    }

    if (agreement === 'custom' && !contract) {
      setContractInvalid(true);
      isFormValid = false;

      validationError.fields.contract = true;
    }

    if (!paymentPercentage) {
      setPaymentPercentageError('Required');
      isFormValid = false;
    }

    if (secondPaymentDateInvalid) {
      isFormValid = false;
    } else if (!secondPaymentDate && paymentPercentage !== 100) {
      setSecondPaymentDateInvalid('Required');
      isFormValid = false;
    }

    if (!isFormValid) {
      throw validationError;
    }

    const bookingData = {
      speakerFee,
      agreement: agreement === 'standard',
      contract: agreement === 'custom'
        ? { original_filename: contract.name, path: contract.path }
        : { original_filename: standardAgreement.name, path: standardAgreement.path },
      incrementals,
      eventName,
      brief,
      additionalNote,
      paymentPercentage,
      secondPaymentDate: secondPaymentDate || undefined
    };

    return Object.keys(bookingData).reduce(
      (acc, key) => bookingData[key] !== undefined ? ({ ...acc, [key]: bookingData[key]}) : acc,
      {}
    );
  };

  const cancelEnquiry = () => {
    return (role => {
      if (role === 'speaker') {
        return ApiDataProvider.cancelEnquirySpeaker(enquiryIdDelete);
      }
      return ApiDataProvider.cancelEnquiryBusiness(enquiryIdDelete, speakerIdDelete);
    })(userRole).then(() => {
      setActionSuccessMessage('Booking canceled');
    }).catch(e => {
      setActionErrorMessage(e.message);
    });
  };

  const deleteBooking = () => {
    return ApiDataProvider.deleteBooking(bookingId).then(() => {
      setActionSuccessMessage('Booking deleted');
    }).catch(e => {
      setActionErrorMessage(e.message);
    });
  };

  const createBooking = () => {
    try {
      const bookingData = validateAndPrepare();

      setIsSending(true);

      ApiDataProvider.createBooking(enquiryId, bookingData).then(() => {
        setActionSuccessMessage('Your booking offer has been sent to the client');
      }).catch(e => {
        setActionErrorMessage(e.message);
      }).finally(() => {
        setIsSending(false);
      });
    } catch (e) {
      setIsSending(false);

      if (e.fields.speakerFee) {
        offerElement.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const sendBooking = () => {
    try {
      const bookingData = validateAndPrepare();
      setIsSending(true);
      ApiDataProvider.sendBooking(bookingId, bookingData).then(() => {
        setActionSuccessMessage('Your booking offer has been sent to the client');
      }).catch(e => {
        setActionErrorMessage(e.message);
      }).finally(() => {
        setIsSending(false);
      });
    } catch (e) {
      console.error(e);
      setIsSending(false);
      if (e.fields.speakerFee) {
        offerElement.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const createBookingDraft = () => {
    const bookingData = prepareData();

    setIsSending(true);

    ApiDataProvider.createBookingDraft(enquiryId, bookingData).then(() => {
      setActionSuccessMessage('Booking draft saved');
    }).catch(e => {
      setActionErrorMessage(e.message);
    }).finally(() => {
      setIsSending(false);
    });
  };

  const saveBookingDraft = () => {
    const bookingData = prepareData();

    setIsSending(true);

    ApiDataProvider.updateBookingDraft(bookingId, bookingData).then(() => {
      setActionSuccessMessage('Booking draft saved');
    }).catch(e => {
      setActionErrorMessage(e.message);
    }).finally(() => {
      setIsSending(false);
    });
  };

  const getBack = () => {
    history.goBack();
  };

  const declineBooking = () => {
    return ApiDataProvider.declineBooking(bookingId).then(() => {
      setActionSuccessMessage('Booking declined and sent back to speaker');
    }).catch(e => {
      setActionErrorMessage(e.message);
    });
  };

  const editBooking = () => {
    setIsSending(true);
    ApiDataProvider.declineBooking(bookingId).then(() => {
      setStatus('DECLINED');
    }).catch(e => {
      setActionErrorMessage(e.message);
    }).finally(() => {
      setIsSending(false);
    });
  };

  const acceptBooking = () => {
    return ApiDataProvider.acceptBooking(bookingId, updatedAt).then(result => {
      setPaymentLink(result.linkOnPayment);
      setActionSuccessMessage('Proceed to payments');
    }).catch(e => {
      if (e instanceof AccessDeniedError && e.code === 'no-billing-details') {
        setNoBillingDetailsError({
          role: 'business',
          message: e.message
        });
      } else {
        setActionErrorMessage(e.message);
      }
    });
  };

  const navigateToDashboard = () => {
    history.push('/dashboard');
  };

  const simulateBooking = () => {
    ApiDataProvider.simulateBooking(enquiryIdDelete, speakerIdDelete)
    .then(() => {
      navigateToDashboard();
    })
    .catch(e => console.error(e));
  };

  const onCloseMessageBox = () => {
    if (paymentLink) {
      window.location.href = paymentLink;
    } else {
      history.push('/dashboard');
    }
  };

  if (userRole === 'speaker' && !isConnectedToStripe) {
    return <ConfirmationUserModal
      isOpen={true}
      text={'No Stripe account'}
      subtext="Want to set up now?"
      onCancel={() => {
        history.push('/dashboard');
      }}
      onConfirm={() => {
        history.push('/payments');
      }}
      cancelButtonText="Later"
      confirmButtonText="Yes"
    />;
  }

  if (isLoading) {
    return (
      <div style={{
        paddingTop: '100px'
      }}>
        <Spinner />
      </div>
    );
  }

  if (noBillingDetailsError?.role === 'speaker') {
    return <ConfirmationUserModal
      isOpen={true}
      text="No billing details"
      subtext="Want to set up now?"
      onCancel={() => {
        history.push('/dashboard');
      }}
      onConfirm={() => {
        history.push('/billing-details');
      }}
      cancelButtonText="Later"
      confirmButtonText="Yes"
    />;
  }

  if (errorLoadingMessage) {
    return <MessageBoxUserModal
      isOpen={true}
      title={'Oops!'}
      message={errorLoadingMessage}
      onOk={() => {
        history.push('/dashboard');
      }}
    />;
  }

  return (
    <>
      <BookingForm
        eventName={eventName}
        setEventName={setEventName}
        brief={brief}
        changeBrief={setBrief}
        budget={budget}
        datePosted={datePosted}
        companyName={companyName}
        contactName={contactName}
        eventDates={eventDates}
        additionalNote={additionalNote}
        setAdditionalNote={setAdditionalNote}
        view={userRole}
        bookingStatus={status}
      >
        <div ref={offerElement}>
          <BookingFormOffer
            view={userRole}
            speakerFee={speakerFee}
            changeSpeakerFee={changeSpeakerFee}
            speakerFeeInvalid={speakerFeeInvalid}
            gst={gst}
            taxVisible={taxVisible}
            breakdown={breakdown}
            incrementals={incrementals}
            addIncremental={addIncremental}
            deleteIncremental={deleteIncremental}
            additionalNote={additionalNote}
            agreement={agreement}
            changeAgreement={changeAgreement}
            agreementInvalid={agreementInvalid}
            contract={contract}
            changeContract={changeContract}
            secondPaymentDate={secondPaymentDate}
            setSecondPaymentDate={setSecondPaymentDate}
            secondPaymentDateInvalid={secondPaymentDateInvalid}
            setSecondPaymentDateInvalid={setSecondPaymentDateInvalid}
            paymentPercentage={paymentPercentage}
            setPaymentPercentage={changePaymentPercentage}
            paymentPercentageError={paymentPercentageError}
            contractInvalid={contractInvalid}
            onCreateBooking={createBooking}
            onEditBooking={editBooking}
            onCancelEnquiry={cancelEnquiry}
            onDeleteBooking={deleteBooking}
            onSimulateBooking={simulateBooking}
            onBack={getBack}
            onSendBooking={sendBooking}
            onDeclineBooking={declineBooking}
            onAcceptBooking={acceptBooking}
            onCreateBookingDraft={createBookingDraft}
            onSaveBookingDraft={saveBookingDraft}
            bookingStatus={status}
            standardContractName={standardAgreement.name}
            standardContractPath={standardAgreement.path}
            isSending={isSending}
          />
        </div>
      </BookingForm>

      <MessageBoxUserModal
        isOpen={actionErrorMessage}
        title={'Oops!'}
        message={actionErrorMessage}
        onOk={() => setActionErrorMessage('')}
      />

      <MessageBoxUserModal
        isOpen={actionSuccessMessage}
        title={
        paymentLink ? <span style={{ fontWeight: 600 }}>{actionSuccessMessage}</span>
          : actionSuccessMessage
      }
        message={''}
        onOk={onCloseMessageBox}
        footer={!!paymentLink && <img
          src={poweredByStripe}
          alt="Stripe"
          style={{
            width: '150px',
            marginTop: '15px'
          }}
        />}
      />

      <ConfirmationUserModal
        isOpen={noBillingDetailsError?.role === 'business'}
        text="No billing details"
        subtext="Want to set up now?"
        onCancel={() => {
          setNoBillingDetailsError(null);
        }}
        onConfirm={() => {
          history.push('/billing-details');
        }}
        cancelButtonText="Later"
        confirmButtonText="Yes"
      />
    </>
  );
};

export default BookingFormContainer;
