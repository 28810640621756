import React from 'react';
import SmartThumbnail from '../../../_components/SmartThumbnail';
import { ReactComponent as Chevron } from '../../../assets/icons/arrow-light.svg';
import keynoteImage from '../../../assets/icons/keynote.png';

import  s from './styles.module.scss';

const SpeakerCard = ({
  id,
  name,
  avatarPath,
  professionalTitle,
  tagline,
  topics,
  speakerTypes,
  speakerTopics
}) => {

  return (
    <div className={s.container}>
      <div className={s.avatar}>
        <SmartThumbnail path={avatarPath} alt={`${name} avatar`} />
      </div>

      <div className={s.description}>
        <h4 className={s.name}>{name}</h4>

        <p className={s.professionalTitle}>{professionalTitle}</p>

        <div className={s.topics}>
            {speakerTopics.join(', ')}
        </div>

        <div className={s.backSide}>
          <div className={s.tagline}>
            <p>{tagline}</p>
          </div>
          <div className={s.keynotes}>
            {speakerTypes.map(type => (
              <div className={s.item}>
                <img src={keynoteImage} />{type.split('/').join(' ')}
              </div>
            ))}
          </div>

          <div className={s.footer}>
            <a href={`/speakers/${id}`} target="_blank" className={s.link}>view profile <Chevron /></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakerCard;
