import React, { useState, useEffect, useCallback } from 'react';


const SmartThumbnail = ({ path, alt, tryWebp }) => {
  const [imageSrc, setImageSrc] = useState(null);

  const onError = useCallback(() => {
    if (tryWebp) {
      setImageSrc(`${process.env.REACT_APP_DO_SPACES_URL}/thumbnail/${path}`);
    } else {
      setImageSrc(`${process.env.REACT_APP_DO_SPACES_URL}/${path}`);
    }
  }, [path]);

  useEffect(() => {
    if (tryWebp && path) {
      const webpPath = path.slice(0, path.lastIndexOf('.')) + '.webp';
      setImageSrc(`${process.env.REACT_APP_DO_SPACES_URL}/thumbnail/${webpPath}`);
    } else {
      setImageSrc(`${process.env.REACT_APP_DO_SPACES_URL}/thumbnail/${path}`);
    }
  }, [path]);

  return (
      <img
          src={imageSrc}
          alt={alt}
          style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover'
          }}
          onError={onError}
      />
  );
};

export default SmartThumbnail;
