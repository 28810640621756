import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import constants from '../constants';
import img from '../assets/ComingSoon/dashboard.png'

const texts = {
    speaker: {
        label: 'Once you receive your first enquiry',
        sublabel: 'You will get access to your master dashboard to help manage your speaking business',
        desc: 'Finders speakers removes the stress and hassle of running a successful speaking business. Get behind the scenes support so you can focus on getting stage ready.'
    },
    finder: {
        label: 'Once you receive a response to your first enquiry',
        sublabel: 'You will get access to your master dashboard to help manage all your speaking talent',
        desc: 'Finders speakers removes the stress and hassle of booking and managing speakers.'
    },
};

export default () => {
    const role = useSelector(state => state.user.role);
    const text = role === 'speaker' ? texts.speaker : texts.finder;

    return <SCPStyled>
        <div className="scd-content">
            <div className="scd-text">
                <h2 className="scd-text__label">{text.label}</h2>
                <p className="scd-text__sublabel">{text.sublabel}</p>
                <p className="scd-text__desc">{text.desc}</p>
                <p className="scd-text__list-title">How it works</p>
                <ul className="scd-text__list">
                    <li className="scd-text__list-item">Manage and track all enquiries in the one place</li>
                    <li className="scd-text__list-item">Access conversations, contracts, documents, invoices and payments</li>
                    <li className="scd-text__list-item">All current, future and past booking information at your fingertips</li>
                </ul>
            </div>
            <div className="scd-image">
                <img src={img} className="scd-image__big"/>
            </div>
        </div>

        <div className="scd-footer">
            All Rights Reserved     {constants.copyright}
        </div>
    </SCPStyled>
}

const SCPStyled = styled.div`
    background: #19212B;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 160px;
    padding-bottom: 100px;

    @media (max-width: 1024px) {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    .scd {

        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-image {
            position: relative;
            margin: 0 15px;

            &__big {
                width: 100%;
                max-width: 595px;
            }
        }

        &-text {
            max-width: 400px;
            margin: 0 15px;

            &__label {
                color: #ffffff;
                font-size: 28px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__sublabel {
                color: #20D5C4;
                font-size: 28px;
                line-height: 32px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__desc {
                color: #ffffff;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 30px;
            }

            &__list {
                list-style: none;
                padding-left: 20px;

                &-title {
                    color: #20D5C4;
                    font-size: 15px;
                    font-weight: 300;
                    margin-bottom: 10px;
                }

                &-item {
                    color: #ffffff;
                    margin-bottom: 10px; 

                    &:before {
                        content: "\\2022";
                        color: #20D5C4;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        &-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: 300;
            color: #ffffff;
            text-align: center;
        }
    }
`