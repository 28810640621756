import { connect } from 'react-redux';
import AboutScreen from './ContactUsScreen';

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutScreen);
