import { SAVE_ENQUIRY_STEP } from '../actions';

const initialState = {};

const enquiry = (state = initialState, action) => {
    if (action.type === SAVE_ENQUIRY_STEP) {
        return { ...state, ...action.payload };
    } else return state;
};

export default enquiry;
