import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Loader from 'react-spinners/FadeLoader';
import constants from '../../constants';
import getFileUrl from '../../helpers/getFileUrl';
import styles from './styles.module.scss';

export default props => {
  const { path, onChange, uploader, error, onUploadError } = props;

  const inputRef = useRef(null);

  const [isUploading, setIsUploading] = useState(false);

  const containerClasses = [styles.container];

  if (error) {
    containerClasses.push(styles.error);
  }

  if (path) {
    containerClasses.push(styles.filled);
  } else if (!isUploading) {
    containerClasses.push(styles.empty);
  }

  const handleFile = file => {
    if (file.size > constants.maxFileSizeMB << 20) {
      onUploadError(new Error(`Max file size is ${constants.maxFileSizeMB} MB`));
      return;
    }

    setIsUploading(true);
    uploader(file).then(result => {
      onChange(result.path);
    }).catch(err => {
      inputRef.current.value = null;
      onUploadError(err);
    }).finally(() => {
      setIsUploading(false);
    });
  };

  const selectFileHandler = event => {
    if (event.target.files.length > 0) {
      handleFile(event.target.files[0]);
    }
  };

  const deleteHandler = e => {
    inputRef.current.value = null;
    e.preventDefault();
    onChange(null);
  };

  const clickHandler = e => {
    if (isUploading) {
      e.preventDefault();
    }
  };

  const dropHandler = e => {
    e.preventDefault();

    if (!isUploading && e.dataTransfer.files) {
      if (e.dataTransfer.files[0]) {
        handleFile(e.dataTransfer.files[0]);
        inputRef.current.value = null;
      }
    }
  };

  const dragHandler = e => {
    e.preventDefault();
  };

  return <label
    className={classNames(containerClasses)}
    onDrop={dropHandler}
    onDragOver={dragHandler}>
    { path && !isUploading &&
      <div className={styles.deleteButton} onClick={deleteHandler} title="Delete">×</div>
    }

    { isUploading ? <div className={styles.loader}><Loader color="#20d5c4" /></div>
      : path && <img src={getFileUrl(path)} alt="avatar" className={styles.preview} title="Change avatar" />
    }
    <input
      type="file"
      onClick={clickHandler}
      onChange={selectFileHandler}
      ref={inputRef}
      accept=".jpg,.jpeg,.png"
    />
  </label>;
};
