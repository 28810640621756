import personAvatar_local from '../assets/PersonA.png';
import topicImg1_local from '../assets/topic-img1.png';
import topicImg2_local from '../assets/topic-img2.png';
import topicImg3_local from '../assets/topic-img3.png';
import topicImg4_local from '../assets/topic-img4.png';
import iMac_local from '../assets/iMac.png';
import categoryImg1_local from '../assets/category-img1.png';
import categoryImg2_local from '../assets/category-img2.png';
import categoryImg3_local from '../assets/category-img3.png';
import categoryImg4_local from '../assets/category-img4.png';
import promoImg_local from '../assets/image-everything-you-need-to-know.png';
import showcase_local from '../assets/showcase-profile.png';
import noMiddleMan_local from '../assets/no-middle-man.png';
import easy_booking_local from '../assets/easy-booking.png';
import diamand_local from '../assets/diamond.png';
import article_blog_img1_local from '../assets/article-blog-img1.png';
import article_blog_img2_local from '../assets/article-blog-img2.png';
import article_blog_img3_local from '../assets/article-blog-img3.png';
import question_mark from '../assets/question_mark.svg';

import search_pink_local from '../assets/search-connecting.png';
import speaker_white_local from '../assets/speaker-connecting.png';
import profilepicture_local from './../assets/profilepicture.svg';
import cover_local from './../assets/cover.svg';
import detail from './../assets/detail.svg';

import slide1 from '../assets/home-page/slide1.png'

export const slide = slide1;

export const iMac = iMac_local;
export const personAvatar = personAvatar_local;

export const topicImg1 = topicImg1_local;
export const topicImg2 = topicImg2_local;
export const topicImg3 = topicImg3_local;
export const topicImg4 = topicImg4_local;

export const categoryImg1 = categoryImg1_local;
export const categoryImg2 = categoryImg2_local;
export const categoryImg3 = categoryImg3_local;
export const categoryImg4 = categoryImg4_local;

export const promoImg = promoImg_local;

export const showcase = showcase_local;
export const noMiddleMan = noMiddleMan_local;
export const easy_booking = easy_booking_local;
export const diamand = diamand_local;

export const article_blog_img1 = article_blog_img1_local;
export const article_blog_img2 = article_blog_img2_local;
export const article_blog_img3 = article_blog_img3_local;

export const search_pink = search_pink_local;
export const speaker_white = speaker_white_local;

export const profilepicture = profilepicture_local;

export const cover = cover_local;
export const detailImg = detail;
