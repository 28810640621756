import React from 'react';
import { PreviewProfileTopics } from "../topics/PreviewProfileTopics";
import './style.scss';

class PostText extends React.Component {
    constructor(props) {
        super(props);
        this.refContent = React.createRef();

        const description = props.description || '';

        this.state = {
            description:
                description.length > 250
                    ? description.slice(0, 250) + '...'
                    : description,
            split: description.length > 250,
        };
    };

    allContent() {
        this.setState({ description: this.props.description, split: false });
    };

    componentDidUpdate() {
        const desc = this.state.description;
        this.refContent.current.innerHTML = Array.isArray(desc)
            ? desc.map(item => `<div class="post__m10">${this.replacer(item)}</div>`).join('')
            : this.replacer(desc)
    };

    componentDidMount() {
        const desc = this.state.description;
        this.refContent.current.innerHTML = Array.isArray(desc)
            ? desc.map(item => `<div class="post__m10">${this.replacer(item)}</div>`).join('')
            : this.replacer(desc)
    };

    replacer(data) {
        return data.replace(/\n/ig, '</br>')
    };

    render() {
        return (
            <>
                <div className="post__text" ref={this.refContent}></div>
                {this.state.split ? (
                    <div
                        className="post__btn"
                        onClick={() => this.allContent()}
                    >
                        Read More
                    </div>
                ) : null}
            </>
        );
    }
}

export default class PreviewProfileTalks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0, // active tab
            maxItem: 2,
        };
    };

    editLoadMore() {
        let add = this.props.lazyload || Infinity;
        this.setState({ maxItem: this.state.maxItem + add });
    };

    replacer(data) {
        return (data.map(elem => {
            return { ...elem, text: elem.text.replace(/\n/ig, '</br>') }
        }))
    };

    render() {
        const data = this.props.content[this.state.active].content.slice().reverse();

        return (
            <div className="preview-profile-talks">
                <div>
                    {data.map(
                        (value, index) => {
                            return this.state.maxItem > index ? (
                                <div className="post" key={Math.random() + index}>
                                    <div className="post__heading">
                                        {value.title !== undefined ? (
                                            <h3 className="post__label">
                                                {value.title}
                                            </h3>
                                        ) : null}
                                        {value.date !== undefined ? (
                                            <p className="post__date">{value.date}</p>
                                        ) : null}
                                    </div>

                                    <PreviewProfileTopics
                                        topics={value.speaker_topic ? value.speaker_topic : []}
                                    />

                                    {value.description ? (
                                        <PostText
                                            description={value.description}
                                        />
                                    ) : value.text ? (
                                        <PostText
                                            description={this.replacer(this.props.content[this.state.active].content[index].text)}
                                        />
                                    ) : value.render ? (
                                        value.render
                                    ) : null}
                                </div>
                            ) : null;
                        }
                    )}
                </div>

                {data.length > this.state.maxItem ? (
                    <div
                        className="post__btn post__btn--load"
                        onClick={() => this.editLoadMore()}
                    >
                        {`
                        view 
                        ${data.length - this.state.maxItem} 
                        more events
                        `}
                    </div>
                ) : data.length === 0 ? (
                    <div className="clear-span">No information yet</div>
                ) : null}
            </div>
        );
    };
};