import React from 'react';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>Why book a keynote speaker</h2>

      <p className={s.paragraph}>
        A keynote speaker has the power to captivate, inspire, and transform the atmosphere of
        any gathering. Whether you are hosting a corporate event, conference, or seminar, our
        handpicked selection of exceptional keynote speakers will provide valuable insights,
        thought-provoking ideas, and actionable strategies to drive success.
      </p>

      <p className={s.paragraph}>
        Our <a className={s.link} href="/find-speakers" target="_blank">keynote speakers</a> possess
        exceptional storytelling abilities, captivating stage presence,
        and the ability to connect with diverse audiences. They craft their presentations to
        align with your event's theme and objectives, delivering powerful messages that
        resonate with attendees long after the event concludes.
      </p>

      <h3 className={s.greenHeadingList}>Unmatched Expertise and Knowledge</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            Our keynote speakers are experts in their fields, bringing a wealth of knowledge,
            insights, and real-world experiences to your event.
          </li>
          <li>
            They stay up-to-date with the latest industry trends, research, and best practices,
            providing your audience with valuable information that can drive success.
          </li>
          <li>
            With their deep understanding of their respective domains, our speakers can offer
            fresh perspectives and thought leadership to inspire and inform your audience.
          </li>
        </ul>
      </p>

      <h3 className={s.greenHeadingList}>Inspire and Motivate Your Audience</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            A great keynote speaker has the ability to inspire and motivate individuals to
            push beyond their limits and strive for excellence.
          </li>
          <li>
            They share personal stories, anecdotes, and powerful examples to ignite passion
            and motivate attendees to take action.
          </li>
          <li>
            By tapping into the emotions and aspirations of your audience, our speakers can
            leave a lasting impact and create a sense of purpose and drive.
          </li>
        </ul>
      </p>

      <h3 className={s.greenHeadingList}>Engage and Energize</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            Our best keynote speakers are skilled at creating an engaging and interactive
            atmosphere during your event.
          </li>
          <li>
            They employ various techniques, such as audience participation, multimedia elements,
            and interactive exercises, to keep attendees fully engaged throughout their presentation.
          </li>
          <li>
            By infusing energy, humor, and interactivity, our speakers ensure that your event
            is not only informative but also enjoyable and memorable.
          </li>
        </ul>
      </p>

      <h3 className={s.greenHeadingList}>Deliver Actionable Strategies and Takeaways</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            Our <a className={s.link} href="/find-speakers" target="_blank">keynote speakers</a> go
            beyond inspirational speeches; they provide tangible, actionable
            strategies and takeaways that attendees can apply in their personal and professional lives.
          </li>
          <li>
            They offer practical tools, methodologies, and frameworks that empower individuals
            and teams to drive positive change and achieve their goals.
          </li>
          <li>
            By providing actionable insights, our speakers ensure that your audience leaves the event
            equipped with the knowledge and tools to implement meaningful change.
          </li>
        </ul>
      </p>

      <h3 className={s.greenHeadingList}>Customize and Personalize</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            We understand that every event is unique, and that's why our best keynote
            speakers customize their presentations to suit your specific needs.
          </li>
          <li>
            They work closely with you to understand your event's objectives, theme, and audience
            demographics, ensuring that their presentation aligns perfectly with your goals.
          </li>
          <li>
            By tailoring their content and delivery, our virtual keynote speakers create a
            personalized experience that resonates deeply with your attendees.
          </li>
        </ul>
      </p>

      <p className={s.paragraph}>
        Investing in a keynote speaker
        from <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a> is
        an investment in the success
        of your event. Our speakers are industry leaders, visionaries, and experts in their
        respective fields, equipped with the charisma, knowledge, and stage presence to engage
        audiences and leave a lasting impression. Contact us today to explore our lineup of
        exceptional business keynote speakers and elevate your next event to new heights.
      </p>

      <p className={s.paragraph}>
        Our handpicked keynote speakers for hire possess unmatched expertise, engaging presentation
        styles, and the ability to inspire and transform your audience. Experience the
        transformative power of our keynote speakers and make your event an extraordinary success.
      </p>
    </>
  );
};

const TopicPageKeynote = () => {
  return (
    <TopicPageBase
      topicAlias="inspiration-and-motivation"
      headerSubtitle={<>
        Book leading keynote & celebrity speakers.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a keynote speaker?"
      trendingSpeakersHeading="Trending Keynote Speakers"
      why={<Why />}
      speakerTopicText="keynote"
      searchButtonIgnoreTopic
    ></TopicPageBase>
  )
};

export default TopicPageKeynote;
