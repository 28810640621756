import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ApiDataProvider from '../api/apiClient';
import MessageBoxUserModal from '../_components/modals/MessageBoxUserModal';
import ConfirmationUserModal from '../_components/modals/ConfirmationUserModal';
import Spinner from '../_components/Spinner';

const VerificationEmail = () => {
  const history = useHistory();
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onConfirmChangeEmail = () => {
    setIsLoading(true);
    ApiDataProvider.cancelEmailChange(token)
    .then(() => {
    }).catch(e => {
      console.error(e);
      setErrorMessage(e.message);
    }).finally(() => {
      setIsLoading(false);
      setIsLoaded(true);
    });
  };

  if (!isLoading && !isLoaded) {
    return <ConfirmationUserModal
      isOpen={true}
      text={"Are you sure you want to cancel email change?"}
      onConfirm={onConfirmChangeEmail}
      onCancel={() => {
        history.push('/account-details');
      }}
    />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return <MessageBoxUserModal
    isOpen={true}
    title={!errorMessage ? 'Your email is reverted' : 'Failed to revert your email change'}
    message={errorMessage}
    onOk={() => {
      history.push('/account-details');
    }}
  />;
};

export default VerificationEmail;
