import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
// import backgroundVideo from '../../assets/video/speaker.mp4';
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import Player from '@vimeo/player';


export const HomeHeroAnimated = ({ title, text, button, customStyle }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const videoContainerRef = useRef();
  const playerRef = useRef();
  const { width } = useWindowDimensions();

  useEffect(() => {
    const options = {
      url: 'https://player.vimeo.com/video/740709736?h=33c6c10394',
      responsive: true,
      background: true,
      muted: true
    };

    playerRef.current = new Player(videoContainerRef.current, options);
  }, []);

  return (
    <SpeakersHero>
      <div className="background-wrapper">
        <div className="background-video-container" ref={videoContainerRef} />
      </div>

      <section className={`hero ${customStyle}`}>
        <div className={`container-fluid speaker-page-stamp-container speaker-page-stamp-container__active`}>
          <div className={`title`}>{title}</div>
          <p className={`hero__text`}>{text}</p>
          <div className="home__link">
            <Link to={`/register`} ><span className={`home__btn home__btn_active`}>{button}</span></Link>
          </div>
        </div>
      </section>
    </SpeakersHero>
  )
}

export const SpeakersHero = styled.div`
  position:relative;
  overflow: hidden;

   .background-wrapper {
     position: absolute;
     top: 0;
     left: 0;
     z-index: 0;
     width: 100vw;
     height: 100%;

     @media(max-width: 600px) {
       background-color: #171F28;;
     }
   }

  .background-video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1920px;

    @media (max-width: 600px) {
      width: 1000px;
    }
  }

   .speaker-page-stamp-container {
      opacity: 0;

      &__active {
        opacity: 1;
        // transition: 0.5s;
      }
   }

    .hero.about__hero {
      padding: 160px 0 73px;
    }
    &.move-bg {
      .bg {
          transform: scaleX(1);
      }; 
    }
    .home__link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hero {
        height: 670px;
        padding: 220px 0 136px;
        text-align: center;
        position: relative;
        overflow: hidden;
        
        @media (max-width: 1360px) {
           padding: 184px 0 136px;
        }
        
        @media (max-width: 1024px) {
           padding: 110px 0 80px;
           
           &.about__hero {
              padding: 90px 0 42px;
            }
        }

        @media (max-width: 601px) {
          height: 415px;
        }
        
        .title {
          font-size: 72px;
          font-weight: bold;
          line-height: 80px;

          @media (max-width: 1024px) {
            font-size: 36px;
            line-height: 36px;
            text-align: center;
            letter-spacing: -0.03em;
            
            & .new-line {
              display: inline;
            }
            
            & .new-line-xs.new-line {
              display: block;
            }
          }
        }

        .home__btn {
            font-weight: 600;
            font-size: 16px;
            width: 300px;
            height: 100px;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            opacity: 0;

            &_active {
              width: 210px;
              height: 47px;
              font-weight: 600;
              font-size: 14px;
              border-radius: 40px;
              opacity: 1;
              transition: 1s;
            }
            
             @media (max-width: 1024px) {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                height: 38px;
                width: 200px;
                white-space: nowrap;
                padding: 6px 0;
                height: 48px;
                width: 222px;
                display: flex;
                justify-content: center;
                align-items: center;
             }
        }
    
        .hero__text {
            text-align: center;
            color: #fff;
            margin-bottom: 16px;
            font-size: 18px;
            line-height: 20px;
            font-weight: 300;
            opacity: 1;
          
            @media (max-width: 1024px) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 20px;
              padding: 0 15px;
            }
        }
        
        .container-fluid {
          @media (max-width: 768px) {
            padding: 0 16px;
            max-width: 360px;
            margin: 0 auto;
          }
        }
    }
`;
