import React, { useState } from 'react';
import  ConfirmationUserModal  from '../../../_components/modals/ConfirmationUserModal';
import TextInput from '../TextInput';
import styles from './styles.module.scss';

export default (props) => {
  const { onCancel, onConfirm, ...rest } = props;
  const [passwordValue, setPasswordValue] = useState('');
  const [error, setError] = useState('');

  const changeHandler = e => {
    setPasswordValue(e.target.value);
    setError('');
  };

  const onCancelModal = () => {
    setPasswordValue('');
    onCancel();
    setError('');
  };

  const onNext = () => {
    if (!passwordValue) {
      setError('Password must not be empty');
    } else {
      onConfirm(passwordValue);
      setPasswordValue('');
    }
  };

  return <ConfirmationUserModal
    {...rest}
    onCancel={onCancelModal}
    cancelButtonText="Cancel"
    confirmButtonText="Next"
    text="Enter your current password"
    onConfirm={onNext}
  >
    <div className={styles.form}>
      <TextInput
        value={passwordValue}
        onChange={changeHandler}
        type="password"
        error={error}
        placeholder="Password"
      />
    </div>
  </ConfirmationUserModal>;
};
