import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styles from '../../BookingForm.module.scss';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import NotesBody from "./ModalBody/NotesBody";
import EventNameBody from "./ModalBody/EventNameBody";
import BriefBody from "./ModalBody/BriefBody";

const useStyles = makeStyles((theme) => ({
  textArea: {
    fontSize: '20px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '500px',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const CustomTextarea = ({ initialValue, onChange }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(initialValue);
  }, []);


  const changeHandler = e => {
    const newValue = e.target.value;

    onChange(e);

    setValue(newValue);
  };

  return (
    <textarea
      value={value}
      onChange={changeHandler}
      style={{
        width: '100%',
        height: '200px',
        resize: 'none',
        fontSize: '14px'
      }}
    />
  );
};

export default function BookingFormModal(
  {
    brief,
    submitBriefModalHandler,
    eventName,
    submitEventNameModalHandler,
    notes,
    changeValueHandler,
    value,
    submitModalHandler,
    deleteNotesHandler,
    view,
    mode,
    setAdditionalNote,
    disabled
  }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const editNotesHandler = () => {
    setOpen(true);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitNote = (value) => {
    submitModalHandler(value);
    setAdditionalNote(value);
    setOpen(false);
  }

  const submitName = (value) => {
    submitEventNameModalHandler(value);
    setOpen(false);
  }

  const submitBrief = (value) => {
    submitBriefModalHandler(value);
    setOpen(false);
  }

  if (mode === 'notes') {
    return (
      <div>
        <NotesBody
          view={view}
          handleOpen={handleOpen}
          notes={notes}
          deleteNotesHandler={deleteNotesHandler}
          editNotesHandler={editNotesHandler}
          disabled={disabled}
        />
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <h2
              id="simple-modal-title"
              style={{
                color: '#20D5C4',
                fontSize: '24px'
              }}
            >
              Enter your notes
            </h2>
            <p id="simple-modal-description">
              <CustomTextarea
                initialValue={notes}
                onChange={changeValueHandler}
              />
              <div>
                <div className={styles.modalForm_buttonsGroup}>
                  <button
                    className={styles.buttonColoredGrey}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => submitNote(value)}
                    className={styles.buttonColored}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </p>
          </div>
        </Modal>
      </div>
    )
  }

  if (mode === 'eventName') {
    return (
      <div>
        <EventNameBody
          handleOpen={handleOpen}
          eventName={eventName}
          disabled={disabled}
        />
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <h2
              id="simple-modal-title"
              style={{
                color: '#20D5C4',
                fontSize: '24px'
              }}
            >
              Enter your event name
            </h2>
            <p id="simple-modal-description">
              <CustomTextarea
                initialValue={eventName}
                onChange={changeValueHandler}
              />
              <div>
                <div className={styles.modalForm_buttonsGroup}>
                  <button
                    className={styles.buttonColoredGrey}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => submitName(value)}
                    className={styles.buttonColored}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </p>
          </div>
        </Modal>
      </div>
    );
  }

  if (mode === 'brief') {
    return (
      <div>
        <BriefBody
          handleOpen={handleOpen}
          brief={brief}
          disabled={disabled}
        />
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <h2
              id="simple-modal-title"
              style={{
                color: '#20D5C4',
                fontSize: '24px'
              }}
            >
              Enter your Brief
            </h2>
            <p id="simple-modal-description">
              <CustomTextarea
                initialValue={brief}
                onChange={changeValueHandler}
              />
              <div>
                <div className={styles.modalForm_buttonsGroup}>
                  <button
                    className={styles.buttonColoredGrey}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => submitBrief(value)}
                    className={styles.buttonColored}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </p>
          </div>
        </Modal>
      </div>
    );
  }

}
