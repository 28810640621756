import React from 'react';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>The benefits of booking an innovation speaker</h2>

      <p className={s.paragraph}>
        Welcome to <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a>, your
        trusted source for discovering exceptional innovation speakers who can spark creativity,
        foster a culture of innovation, and propel your organization to new heights. Our
        handpicked roster of innovation speakers brings a wealth of expertise and insights
        to inspire, educate, and motivate your audience.
      </p>

      <p className={s.paragraph}>
        Whether you're seeking strategies for disruptive innovation, design thinking, or
        technological advancements, our platform connects you with renowned speakers who can
        help you navigate the ever-evolving landscape of innovation and unlock the true
        potential of your organization, foster a spirit of innovation among your teams,
        and seize opportunities for growth and market disruption.
      </p>

      <h3 className={s.greenHeadingList}>Expertise and Knowledge</h3>
      <p className={s.paragraph}>
        Innovation speakers in Australia are typically experts in their fields with extensive
        knowledge and experience. They can provide valuable insights and practical strategies
        to foster innovation within organizations. Their expertise can help inspire and guide
        attendees to think creatively and embrace innovation in their work.
      </p>

      <h3 className={s.greenHeadingList}>Inspirational and Motivational</h3>
      <p className={s.paragraph}>
        Innovation speakers often have compelling personal stories of overcoming challenges
        and achieving success through innovative thinking. By sharing their experiences,
        they can inspire and motivate audiences to embrace a mindset of innovation and push
        the boundaries of what is possible.
      </p>

      <h3 className={s.greenHeadingList}>Fresh Perspectives</h3>
      <p className={s.paragraph}>
        Innovation speakers bring fresh perspectives and ideas to the table. They can challenge
        conventional thinking, encourage attendees to question the status quo, and explore new
        possibilities. Their unique insights can help stimulate creativity and innovation within
        teams or organizations.
      </p>

      <h3 className={s.greenHeadingList}>Practical Tools and Techniques</h3>
      <p className={s.paragraph}>
        <a className={s.link} href="/about" target="_blank">Innovation speakers</a> can
        provide practical tools, techniques, and frameworks that attendees can apply in their
        own work. They can share proven methodologies for generating ideas, problem-solving,
        and implementing innovation strategies. These actionable takeaways can be immediately
        implemented and can drive real change.
      </p>

      <h3 className={s.greenHeadingList}>Networking Opportunities</h3>
      <p className={s.paragraph}>
        <a className={s.link} href="/about" target="_blank">Booking</a> an
        innovation speaker in Australia can attract a diverse audience interested
        in innovation and creativity. This can create networking opportunities for
        attendees to connect with like-minded individuals, exchange ideas, and
        collaborate on innovative projects. Networking can lead to new partnerships,
        opportunities, and shared learning.
      </p>

      <h3 className={s.greenHeadingList}>Enhanced Event Experience</h3>
      <p className={s.paragraph}>
        An innovation speaker can add excitement and intrigue to an event. Their dynamic
        presentations, engaging storytelling, and interactive sessions can captivate the
        audience and create a memorable experience. This can boost attendee satisfaction,
        generate positive word-of-mouth, and enhance the overall reputation of the event.
      </p>

      <h3 className={s.greenHeadingList}>Competitive Advantage</h3>
      <p className={s.paragraph}>
        In today's fast-paced business landscape, staying ahead of the competition requires
        continuous innovation. By booking an innovation speaker, organizations can equip
        their teams with the knowledge and inspiration to innovate and adapt to changing
        market dynamics. This can help gain a competitive edge and drive business growth.
      </p>

      <p className={s.paragraph}>
        At <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a>, we
        believe that innovation is the key to long-term success and growth. By connecting
        you with exceptional innovation speakers, we empower your organization to embrace
        change, foster a culture of creativity, and drive transformative outcomes. Our
        carefully curated roster of speakers brings a wealth of knowledge and experience
        from various industries, ensuring that you find the perfect match for your event
        or conference. 
      </p>

      <p className={s.paragraph}>
        Innovation speakers possess a unique ability to challenge the status quo, disrupt
        traditional thinking, and ignite a spark of creativity within individuals and teams.
        Their expertise lies in unraveling the mysteries of innovation, sharing practical
        strategies, and inspiring audiences to embrace change and embrace new possibilities.
        By tapping into their experiences and knowledge, these speakers empower organizations
        to foster a culture of innovation, embrace risk-taking, and drive groundbreaking solutions.
      </p>
    </>
  );
};

const TopicPageInnovation = () => {
  return (
    <TopicPageBase
      topicAlias="innovation"
      headerSubtitle={<>
        Book leading innovation speakers.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a innovation speaker?"
      trendingSpeakersHeading="Trending Innovation Speakers"
      why={<Why />}
      speakerTopicText="innovation"
    ></TopicPageBase>
  )
};

export default TopicPageInnovation;
