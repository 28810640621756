import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Headroom from 'react-headroom';
import styles from './Header.module.scss';
import styled from "styled-components";
import logo from '../../assets/logo-header.png';
import logoWhite from '../../assets/logo_light_new.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchDataGet } from '../../api/helperFunctions';
import LogoutMenu from './LogoutMenu';
import SideBar from "../../_components/Sidebar";
import { GreenLineOnHover } from '../../HomePage/GreenLineOnHover';
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import { ReactComponent as Heart } from "../../assets/icons/darkHeart.svg";
import { PopUpShortList } from "../../FindSpeakersScreens/PopUpShortList";
import { Portal } from "react-portal";
import { useBodyStopScroll } from "../../helpers/useBodyStopScroll";
import { logout } from '../../store/actions/authenticationActions';
import * as R from "ramda";
import { useHistory } from 'react-router';
import { AvatarSmall } from '../../_components/avatars';

function useOutsideAlerter(ref, toggleMenu) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            toggleMenu(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}

const RegisteredHeader = props => {
    let avatar = useSelector(state => state.user.avatar);
    const user = useSelector(state => state.user);
    const role = user.role;
    const history = useHistory();

    const [isMenuOpen, toggleMenu] = useState(false);
    const dispatch = useDispatch();
    const wrapperRef = useRef(null)
    const isLightHeader = props.location.pathname.includes('register')
        || props.location.pathname.includes('builder')
        || props.location.pathname.includes('payment')
        || props.location.pathname.includes('verify');
    const windowSize = useWindowDimensions();
    const shortList = useSelector(state => state.speakers.shortList.data);
    const portal = useSelector(state => state.portal.shortListPortal);

    useOutsideAlerter(wrapperRef, toggleMenu);
    useBodyStopScroll();

    // fetch user first name and last name from store instead
    /*
    useEffect(() => {
        fetchDataGet('profile/about', 'get', user.token.token)
            .then((response) => {
                if (response[1].data) {
                    setFirstName(response[1].data.first_name);
                    setLastName(response[1].data.last_name);
                }
            })
    }, []);
    */

    useEffect(() => {
        if (!R.isNil(user.token)) {
            dispatch({ type: 'GET_SHORT_LIST_INIT' });
            fetchDataGet(`finder/short-list`, 'get', user.token)
                .then((response) => {
                    try {
                        if (response[0] === 200) {
                            dispatch({ type: 'GET_SHORT_LIST_SUCCESS', payload: response[1] });
                        }
                    } catch (e) {
                        throw e;
                    }

                })
                .catch(() => {
                    dispatch({ type: 'GET_SHORT_LIST_ERROR', payload: false });
                });
        }
    }, [user]);

    const handlerLogout = () => {
        dispatch(logout(() => {
            toggleMenu(!isMenuOpen);
            history.push('/');
        }));
    };

    if (
        avatar === `${process.env.REACT_APP_DO_SPACES_URL}/null` ||
        avatar === `${process.env.REACT_APP_DO_SPACES_URL}/`
    ) {
        avatar = '';
    }

    const handlerClosePortal = () => {
        dispatch({ type: 'RESET_PORTAL_STATUS' });
    };

    return (
        <div style={{
            position: 'absolute',
            width: '100%',
            zIndex: 2
        }}>
            {
                windowSize.width < 1024
                    ?
                    <SideBar isVerified={true} onLogout={handlerLogout} theme={'dark'} />
                    : <Headroom
                        wrapperStyle={props.location.pathname === '/preview-profile' ? {
                            position: 'absolute',
                            top: 0,
                            width: '100vw'
                        } : {}}
                        style={{
                            position: 'static',
                            transition: 'all .5s ease-in-out',
                        }}
                    >
                        <header
                            className={`${[styles.header, props.location.pathname === '/preview-profile' ? styles['header-profile'] : styles['header-regular']].join(' ')} ${isLightHeader && styles['header-light']}`}>
                            <div className={styles.header_wrapper} style={{ width: '100vw' }}>

                                <div className={styles.groupOne}>
                                    {role === 'business' && (
                                      <ul className={styles.headerNav}>
                                          <li style={{fontSize: '14px'}}>
                                              <Link to={`/about`}>
                                                  <GreenLineOnHover
                                                    target={'Our Story'}
                                                    bgImg={() => require('../../assets/home-page/green_line_why.svg')}
                                                    moveBottom="-6px"
                                                    lineHeight="15px"
                                                    whiteSpace="nowrap"
                                                  />
                                              </Link>
                                          </li>

                                          <li style={{fontSize: '14px'}}>
                                              <Link to="/find-speakers">
                                                  <GreenLineOnHover
                                                    target={'Find Talent'}
                                                    bgImg={() => require('../../assets/home-page/green_line_process_1.png')}
                                                    moveBottom="-6px"
                                                    lineHeight="10px"
                                                    whiteSpace="nowrap"
                                                  />
                                              </Link>
                                          </li>

                                          <li style={{fontSize: '14px'}}>
                                              <Link to="/how-it-works">
                                                  <GreenLineOnHover
                                                    target={'How It Works'}
                                                    bgImg={() => require('../../assets/home-page/green_line_process_32.svg')}
                                                    moveBottom="-6px"
                                                    lineHeight="10px"
                                                    whiteSpace="nowrap"
                                                  />
                                              </Link>
                                          </li>
                                      </ul>
                                    )}

                                    <Link to="/" className={styles.logo}>
                                        <img
                                            src={isLightHeader ? logo : logoWhite}
                                            alt="finder speakers logo"
                                        />
                                    </Link>
                                    <div className={styles.headerNav}>
                                    </div>
                                </div>

                                {
                                    (user.token && shortList.length > 0)
                                    && (
                                        <ShortListBtn onClick={() => {
                                            dispatch({
                                                type: 'SET_PORTAL_STATUS',
                                                payload: {portalType: 'shortListPortal', portalStatus: 'true'},
                                            });
                                        }}>
                                            <Heart />
                                            ({shortList.length})
                                        </ShortListBtn>
                                    )
                                }

                                {
                                    portal && (
                                        <Portal node={document && document.getElementById('portal')}>
                                            <PopUpShortList
                                                onPortalClose={handlerClosePortal}
                                                visible={true}
                                            />
                                        </Portal>
                                    )
                                }

                                <UserMenu>
                                    <ul
                                        className={`${styles.groupTwo} ${styles.nameArrow} ${props.location.pathname !== '/preview-profile' ? styles['nameArrow--black'] : ''}`}
                                        onClick={() => toggleMenu(!isMenuOpen)}
                                    >
                                        <li
                                            className={styles.menu_userName}
                                            style={{ fontSize: '14px' }}
                                        >
                                            {user.firstName}
                                        </li>
                                        <li
                                            style={{ fontSize: '14px' }}
                                        >
                                            {user.lastName}
                                        </li>
                                    </ul>

                                    {isMenuOpen ? (
                                        <div ref={wrapperRef} style={{ display: 'inline-block' }}>
                                            <LogoutMenu
                                                toggleMenu={toggleMenu}
                                                isMenuOpen={isMenuOpen}
                                                handlerLogout={handlerLogout}
                                            />
                                        </div>
                                    ) : null}
                                    <AvatarSmall imageUrl={avatar} role={role} />
                                </UserMenu>

                            </div>
                        </header>
                    </Headroom>
            }
        </div>
    );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(RegisteredHeader);

export const UserMenu = styled.div`
    display: flex;
    align-items: center;
    justify-self: flex-end;

  @media (max-width: 1360px) {
      margin-top: 5px;
  }
`
export const ShortListBtn = styled.div`
  position: absolute;
  top: 36px;
  right: 330px;
  color: #E8388A;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;

  &:hover {
    cursor: pointer;
    color: white;
      
      svg {
          fill: white;
      }
  }

  svg {
    fill: #E8388A;
    width: 21px;
    height: 21px;
    display:inline-block;
    margin-right: 7px;
  }
`;
