import { takeLatest } from 'redux-saga/effects';
import { postRegisterAsync, postLoginAsync} from './auth.saga';
import { postEnquiryAsync } from './enquiry.saga';
import {
    LOGIN_REQUEST,
    POST_REGISTER_REQUEST,
    POST_ENQUIRY_REQUEST,
} from '../actions';

function* watch() {
    // takeLatest возьмет последний запрос
    // takeEvery воз все
    // THIRD STEP
    yield takeLatest(POST_REGISTER_REQUEST, postRegisterAsync);
    yield takeLatest(POST_ENQUIRY_REQUEST, postEnquiryAsync);
}

export default watch;
