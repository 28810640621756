import { connect } from 'react-redux';
import { FAQ } from './FAQ';
import { setCurrentTab } from '../../store/actions/faqActions';

const mapDispatchToProps = {
    setCurrentTab,
};

const mapStateToProps = state => ({
    currentTab: state.utils.faqCurrentTab,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FAQ);
