import React from 'react';
import styles from "../../../BookingForm.module.scss";

export default function EventNameBody(
  {
    eventName,
    handleOpen,
    disabled
  }) {

  return (
    <div>
      <div>
        {eventName}
      </div>
      {
        !disabled && (
          <span
            onClick={() => handleOpen()}
            className={styles.read_button}
          >
            Edit
          </span>
        )
      }
    </div>
  );
}