import styled from 'styled-components';

export const MarketSliderStyled = styled.div`
    margin-bottom: 101px;
    position: relative;

    @media (max-width: 601px) {
        margin-bottom: 0px;
        padding-bottom: 40px;
    }

    .wrapper {
        width: calc(250px * 5);
        position: relative;

        @media(max-width: 600px) {
            top: -20px;
        }
    }

    .disable-click-wrapper {
        width: calc(250px * 5);
        height: 100%;
        position: absolute;
        background: transparent;
        z-index: 1;
    }

    .slider-point {
        position: absolute;
        border-radius: 50%;

        &__red {
            background-color: #E8388A;
        }

        &__green {
            background-color: #20D5C4;
        }
    }

    .card-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
    }

    .card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #19222D;
        width: 100%;
        height: 280px;
        position: relative;
    }

    .slide-card {
        position: absolute;
    }

    .first-slide {
        .slide-card {
            top: 80px;
            opacity: 1;
            width: 312px;
            animation-name: firstSlideAnimation;
            animation-duration: 1.5s;
        }
    }

    @keyframes firstSlideAnimation {
        0% {
            top: 50px;
            right: 15px;
            width: 201px;
            opacity: 0.7;
        }

        100% {
            top: 80px;
            width: 312px;
            opacity: 1;
        }
    }

    .second-slide {
        .slide-card.slide-card-left {
            opacity: 0.7;
            top: 50px;
            left: 33px;
            width: 201px;
            animation-name: secondSlideLeftAnimation;
            animation-duration: 1.5s;
        }

        .slide-card.slide-card-right {
            opacity: 0.7;
            top: 50px;
            right: 15px;
            width: 201px;
            animation-name: secondSlideRightAnimation;
            animation-duration: 1.5s;

            @media(max-width: 600px) {
                animation-name: secondSlideRightAnimationMobile;
                opacity: 0;
            }
        }
    }

    @keyframes secondSlideLeftAnimation {
        0% {
            top: 80px;
            left: auto;
            opacity: 1;
            width: 312px;
        }

        100% {
            top: 50px;
            left: 33px;
            width: 201px;
            opacity: 0.7;
        }
    }

    @keyframes secondSlideRightAnimation {
        0% {
            top: 0px;
            right: 132px;
            width: 161px;
            opacity: 0.4;
        }

        100% {
            top: 50px;
            width: 201px;
            opacity: 0.7;
        }
    }

    @keyframes secondSlideRightAnimationMobile {
        0% {
            top: 0px;
            right: 132px;
            width: 161px;
            opacity: 0.4;
        }

        100% {
            top: 50px;
            width: 201px;
            opacity: 0;
        }
    }

    .third-slide {
        .slide-card.slide-card-left {
            opacity: 0.4;
            top: 0px;
            left: 130px;
            width: 161px;
            animation-name: thirdSlideLeftAnimation;
            animation-duration: 1.5s;
        }

        .slide-card.slide-card-right {
            opacity: 0.4;
            top: 0px;
            right: 132px;
            width: 161px;
            animation-name: thirdSlideRightAnimation;
            animation-duration: 1.5s;
        }
    }

    @keyframes thirdSlideLeftAnimation {
        0% {
            opacity: 0.7;
            top: 50px;
            left: 33px;
            width: 201px;
        }

        100% {
            opacity: 0.4;
            top: 0px;
            left: 130px;
            width: 161px;
        }
    }

    @keyframes thirdSlideRightAnimation {
        0% {
            opacity: 0;
            right: 300px;
            top: -30px;
            width: 100px;
        }

        100% {
            opacity: 0.4;
            top: 0px;
            right: 132px;
            width: 161px;
        }
    }

    .fourth-slide {
        .slide-card.slide-card-left {
            opacity: 0;
            top: -60px;
            left: 300px;
            width: 100px;
            animation-name: fourthSlideLeftAnimation;
            animation-duration: 1.5s;
        }

        .slide-card.slide-card-right {
            opacity: 0;
            top: -60px;
            right: 132px;
            width: 161px;
            animation-name: fourthSlideRightAnimation;
            animation-duration: 1.5s;
        }
    }

    @keyframes fourthSlideLeftAnimation {
        0% {
            opacity: 0.4;
            top: 0px;
            left: 130px;
            width: 161px;
        }

        100% {
            opacity: 0;
            top: -60px;
            left: 300px;
            width: 100px;
        }
    }

    @keyframes fourthSlideRightAnimation {
        0% {
            top: -30px;
            right: 300px;
            opacity: 0;
            width: 100px;
        }

        100% {
            top: 0px;
            right: 132px;
            opacity: 0;
            width: 161px;
        }
    }
`