import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './Sidebar.scss';
import styled from "styled-components"
import CallIcon from '@material-ui/icons/Call';
import { SidebarMenu } from "./SidebarMenu";
import { GetStarted } from '../../AboutScreens/AboutScreen';
import { GreenLineOnHover } from "../../HomePage/GreenLineOnHover";
import constants from '../../constants';
import { ReactComponent as Heart } from "../../assets/icons/darkHeart.svg";
import { PopUpShortList } from "../../FindSpeakersScreens/PopUpShortList";
import { useDispatch, useSelector } from "react-redux";
import { ShortListBtn } from "../../_layout/Header/RegistredHeader";
import { Portal } from "react-portal";
import { useMediaQuery } from 'react-responsive'
import BurgerIcon from '../../_layout/Header/BurgerIcon';

const Title = ({ isGreenLineVisible }) => {
    return (
        <h2 className="about-getstarted__title">
            <GreenLineOnHover target={'Let’s get started'}
                scrollPosition={0}
                bgImg={() => require('../../assets/get-start-page/green-line-menu.svg')}
                moveBottom={'-6px'}
                manualVisibility={isGreenLineVisible}
            />
        </h2>
    )
}

const Sidebar = ({
    menuItems,
    onClickLogin,
    isVerified,
    onLogout,
    location,
    theme,
    lightLogo
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const portal = useSelector(state => state.portal.shortListPortal);
    const token = useSelector(state => state.user.token);
    const shortList = useSelector(state => state.speakers.shortList.data);
    const path = location.pathname;
    const dispatch = useDispatch();

    const isMobile = useMediaQuery({ query: '(max-device-width: 600px)' });

    const handlerClosePortal = () => {
        dispatch({
            type: 'SET_PORTAL_STATUS',
            payload: { portalType: 'shortListPortal', portalStatus: false },
        })
    };

    const handlerLogout = () => {
        onLogout();
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="mobile-head">
            <Link
                to={'/'} className="mobile-head__logo-link"
                onClick={() => setIsMenuOpen(false)}
            >
                {
                    (theme === 'dark' || lightLogo)
                        ? (
                        <img
                          src={require('../../assets/logo-header-mobile.png')}
                          alt="finders speakers"
                          onClick={() => setIsMenuOpen(false)}
                          className="logo-image"
                        />
                        ) : (
                        <img
                          src={require('../../assets/logo-header_new.png')}
                          alt="finders speakers"
                          onClick={() => setIsMenuOpen(false)}
                          className="logo-image"
                        />
                        )
                }
            </Link>
            
            <div style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                columnGap: '10px'
                }}>
                <Link to="/contact-us">
                    <span className="mobile-head__btn--pink">
                        enquire
                    </span>
                </Link>

                <a
                    href={`tel:${constants.phone}`}
                    style={{
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 600
                    }}
                ><span className="mobile-head__btn--aqua" style={{ padding: '10px' }}><CallIcon /></span></a>
                <input
                    type="checkbox"
                    className="mobile-head__checkbox"
                    id="navCheck"
                    onChange={() => {
                        console.log('changed to ', !isMenuOpen);
                        setIsMenuOpen(!isMenuOpen);
                    }}
                    checked={isMenuOpen}
                />

                <label htmlFor="navCheck" className="mobile-head__icon" style={{ margin: '0 10px' }}>
                    <BurgerIcon theme={theme} />
                </label>

                <div className="mobile-head__menu">
                    <div className={'mobile-head__menu-top'}>
                        <Link to={'/'} className="mobile-head__logo-link" onClick={() => setIsMenuOpen(false)}>
                            <img src={require('../../assets/logo_light_new.svg')} alt="finders speakers" />
                        </Link>
                        <label htmlFor="navCheck" className="mobile-head__icon">
                            <div className="mobile-head__line mobile-head__line--top" />
                            <div className="mobile-head__line mobile-head__line--bottom" />
                        </label>
                    </div>
                    {
                        (token && shortList.length > 0)
                        && (
                            <ShortListBtnMobile onClick={() => dispatch({
                                type: 'SET_PORTAL_STATUS',
                                payload: { portalType: 'shortListPortal', portalStatus: true },
                            })}>
                                <Heart />
                                ({shortList.length})
                            </ShortListBtnMobile>
                        )
                    }
                    {
                        portal && (
                            <Portal node={document && document.getElementById('portal')}>
                                <PopUpShortList
                                    onPortalClose={handlerClosePortal}
                                    visible={true}
                                />
                            </Portal>
                        )
                    }
                    {
                        // location.pathname.includes('register')
                        //     ? <>
                        //         <GetStarted
                        //             addonClass="menu__getstarted"
                        //             title={<Title isGreenLineVisible={isMenuOpen} />}
                        //             btnFindersText="Find talent"
                        //             btnSpeakersText="join. It’s free"
                        //         />
                        //         <div className="login-link">
                        //             <span>Already have an account?</span>
                        //             <a href="#" className="aqua" onClick={onClickLogin}>Log in</a>
                        //         </div>
                        //     </>
                        //     : 
                        <SidebarMenu
                            menuItems={menuItems}
                            onClickLogin={onClickLogin}
                            onLogout={handlerLogout}
                            isVerified={isVerified}
                            setIsMenuOpen={setIsMenuOpen}
                        />
                    }
                </div>
            </div>
        </div>
    )
};


export default withRouter(Sidebar);
const ShortListBtnMobile = styled(ShortListBtn)`
  position: static;
`;

const UserNameLabel = styled.label`
  font-weight: 600;
  font-size: 16px;
  max-width: 260px;
  white-space: pre-wrap;
  margin-bottom: 0;
  color: #10151B;
  padding-right: 16px;
  padding-top: 7px;
  position: relative;
  text-align: right;
  
    .arrow {
        display: inline-block;
        width: 8px;
        height: 8px;
        transform: rotate(45deg) translateY(-50%);
        position: absolute;
        border: 2px #0004 solid;
        border-top: none;
        border-left: none;
        right: 3px;
        top: 50%;
    }
`;