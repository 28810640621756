import React, { useEffect } from 'react';
import Header from '../Header';
import RegistredHeader from '../Header/RegistredHeader';
import Footer from '../Footer';
import styles from './MainLayout.module.scss';
import LoginModal from '../../AuthScreens/Old/LoginModal';

export default function MainLayout(props) {
  let showModal = props.showModal;
  let toggleLoginModal = props.toggleLoginModal;

  useEffect(() => {
    props.isVerificationModalOpen === true && props.toggleVerificationModal()
  }, []);

  const onClickRegister = () => {
    props.history.push('/get-started');
  };

  return (
    <>
      <LoginModal
        onClick={() => toggleLoginModal()}
        setShow={() => toggleLoginModal()}
        show={showModal}
      />
      <div
        onClick={() => showModal && toggleLoginModal()}
        className={styles.app + ' ' + (showModal ? 'modal-open' : '')}
        id="main-content"
      >
        {
          props.isVerified
            ? <RegistredHeader {...props} name={props.user && props.user.first_name}
              last_name={props.user && props.user.last_name}
            />
            : <Header
              onClickRegister={onClickRegister}
              onClickLogin={() => toggleLoginModal()}
            />
        }
        <div className="content" style={{ flexGrow: 1 }}>{props.children}</div>
        {!props.hideFooter && <Footer
          user={props.user}
        />}
      </div>
    </>
  );
}
