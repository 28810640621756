import React, {useEffect, useState} from 'react'
import detail from '../assets/detail.svg';
import user from '../assets/user.svg';
import file from '../assets/file_dashboard.svg'
import star from '../assets/star_dashboard.svg'
import iconCalendar from '../assets/calendar.svg'
import iconCoin from '../assets/coin.svg'
import iconLocation from '../assets/location.svg'

import moment from 'moment'

const InfoBlock = props => {
    return (
      <div className="info-item">
          <img src={props.icon} alt=""/>
          {props.label}
      </div>
    )
}

const Booking = props => {
    const {data} = props;
    const enquiry = { ...data.enquiry }
    const dates = { ...enquiry.dates }
    const userLogo = data.user.personal_details.media[0] ?? {}
    const logoUrl = userLogo.path ? `${process.env.REACT_APP_DO_SPACES_URL}/${userLogo.path})` : 'https://greendestinations.org/wp-content/uploads/2019/05/avatar-exemple.jpg'
    const [detailVisible, setDetailVisible] = useState(false);

    //temp
    const [paidStatus, setPaidStatus] = useState(false)
    useEffect(() => { setPaidStatus(Boolean(Date.now() % 2)) }, [])

    // let [location, setLocation] = useState('')
    // Geocode.fromLatLng(enquiry.event_location.latitude, enquiry.event_location.longitude)
    //   .then(res => setLocation(res.results[0].formatted_address));

    return (
      <div className="enquiry-booked" onMouseLeave={() => setDetailVisible(false)}>
          <div className="enquiry-booked__col col1">
              <div className="user-logo" style={{ backgroundImage: `url(${logoUrl})` }} />
          </div>
          <div className="enquiry-booked__col col2">
              <div className="name">{data.user.first_name} {data.user.last_name}</div>
              <div className="company_name">{enquiry.company_name}</div>
              <div className="info_blocks">
                  {dates[0] && <InfoBlock label={moment(dates[0].start_date).format('DD MMM YYYY')} icon={iconCalendar} />}
                  <InfoBlock label="Payment Status" icon={iconCoin} />
                  <InfoBlock label={data.user.personal_details.location} icon={iconLocation} />
                  <div className={`paid-status ${paidStatus ? '' : 'not'}`}>{paidStatus ? 'PAID' : 'NOT PAID'}</div>
              </div>
          </div>
          <div className="enquiry-booked__col col3">
              <div className="btn messages">message</div>
              <div className="btn view">VIEW CONTRACT</div>
          </div>
          <div className="enquiry-booked__col col4 enquiry-col-dd">
              <img src={detail} onClick={() => setDetailVisible(!detailVisible)} alt="detail"/>
              {detailVisible && <div className="enquiry-action-dd">
                  <div>
                      <img src={user} alt="user"/>
                      <span>View Speaker Profile</span>
                  </div>
                  <div>
                      <img src={file} alt="file"/>
                      <span>View Invoices</span>
                  </div>
                  <div>
                      <img src={star} alt="star"/>
                      <span>Give Feedback</span>
                  </div>
              </div>}
          </div>
      </div>
    );
}

export default Booking
