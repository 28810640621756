import React from 'react';
import NumberFormat from 'react-number-format';

export default ({ value }) => {
  return <NumberFormat
    value={value}
    thousandSeparator={true}
    decimalScale={2}
    fixedDecimalScale={true}
    displayType={'text'}
    prefix="$"
  />;
};
