import { SignUpFormCode } from './SignUpFormCode';
import { connect } from 'react-redux';
import {
    postRegisterAction,
} from '../../store/actions';

const mapDispatchToProps = {
    postRegisterRequest: postRegisterAction,
};

const mapStateToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpFormCode);
