import styles from './styles.module.scss';

import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2';

import { signUp } from '../../store/actions/authenticationActions';

const Info = props => {
    return (<>
        <p>
            Already have an account?{' '}
            <NavLink to="/register-business" onClick={() => props.close()} className={'color-secondary'}>
                Log in here
            </NavLink>
        </p>
    </>);
};



export default (props) => {
    const { show, onSignUpSuccess, onClose } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [phone, setPhone] = useState({ code: 'au', phone: '' });
    const [password, setPassword] = useState('');
    const [agree, setAgree] = useState(false);
    const [email, setEmail] = useState('');
    const [captcha, setCaptcha] = useState(false);

    const {
        generalError,
        first_name: firstNameError,
        last_name: lastNameError,
        business_name: businessNameError,
        email: emailError,
        number: phoneError,
        agree: agreeError,
        password: passwordError,
        captcha: captchaError
    } = useSelector(state => state.auth.wrongData);


    const token = useSelector(state => state.user.token);

    const changePhoneHandler = (phone, countryData) => {
      setPhone({
        phone,
        code: countryData.countryCode,
      })
    };

    return (
      <Modal size="sm" show={show} onHide={onClose}>
          <Modal.Header>
              <span className={styles.close_btn} onClick={onClose}>+</span>
              <Modal.Title className={styles.title}>
                  Get Full Access to talent
              </Modal.Title>
              <p>Start looking for great connections</p>
          </Modal.Header>
          <Modal.Body className={styles.body}>
              {generalError && (
                  <Form.Control.Feedback
                      type="invalid"
                      className={'d-block'}
                      style={{
                          marginBottom: 10,
                      }}
                  >
                      {generalError}
                  </Form.Control.Feedback>
              )}

              <Form.Group style={{ position: 'relative' }}>
                <div className={styles.labelErrorGroup}>
                  <Form.Label className={styles.label}>First Name</Form.Label>

                  <Form.Control.Feedback
                    type="invalid"
                    className={`d-block ${styles.errorMsg}`}
                  >
                    {firstNameError}
                  </Form.Control.Feedback>
                </div>

                  <Form.Control
                      className={firstNameError ? styles.errorBorder : ''}
                      type="text"
                      autoComplete="off"
                      onChange={e => setFirstName(e.target.value)}
                      value={firstName}
                  />
              </Form.Group>

              <Form.Group style={{ position: 'relative' }}>
                <div className={styles.labelErrorGroup}>
                  <Form.Label className={styles.label}>Last Name</Form.Label>
                  <Form.Control.Feedback
                    type="invalid"
                    className={`d-block ${styles.errorMsg}`}
                  >
                    {lastNameError}
                  </Form.Control.Feedback>
                </div>

                <Form.Control
                    className={lastNameError ? styles.errorBorder : ''}
                    type="text"
                    autoComplete="off"
                    onChange={e => setLastName(e.target.value)}
                    value={lastName}
                />
              </Form.Group>

              <Form.Group style={{ position: 'relative' }}>
                <div className={styles.labelErrorGroup}>
                  <Form.Label className={styles.label}>Email address</Form.Label>
                  <Form.Control.Feedback
                    type="invalid"
                    className={`d-block ${styles.errorMsg}`}
                  >
                    {emailError}
                  </Form.Control.Feedback>
                </div>
                  <Form.Control
                      className={emailError ? styles.errorBorder : ''}
                      type="text"
                      autoComplete="off"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                  />
              </Form.Group>

              <Form.Group style={{ position: 'relative' }}>
                <div className={styles.labelErrorGroup}>
                  <Form.Label className={styles.label}>Phone number</Form.Label>
                  <Form.Control.Feedback
                    type="invalid"
                    className={`d-block ${styles.errorMsg}`}
                  >
                    {phoneError?.phone}
                  </Form.Control.Feedback>
                </div>
                <PhoneInput
                  inputClass={phoneError ? styles.errorBorder : styles.phoneField}
                  inputStyle={{
                    width: '100%',
                    height: '40px',
                  }}
                  countryCodeEditable={false}
                  masks={{
                      'au': '.. .... .... ..',
                      'north-america': '... ... ... ... ...',
                      'south-america': '... ... ... ... ...',
                      'us': '... ... ... ... ...',
                      'cn': '... ... ... ... ...',
                      'cu': '... ... ... ... ...',
                      'eg': '... ... ... ... ...',
                      'gb': '... ... ... ... ...',
                      'io': '... ... ... ... ...',
                      'br': '... ... ... ... ...',
                      'be': '... ... ... ... ...',
                      'bs': '... ... ... ... ...',
                      'ua': '.. ... .. .. .... ..',
                      'es': '... ... ... ... ...',
                      'de': '... ... ... ... ...',
                      'ru': '... ... ... ... ...',
                      'fr': '... ... ... ... ...',
                      'jp': '... ... ... ... ...',
                      'pt': '... ... ... ... ...',
                      'it': '... ... ... ... ...',
                      'ir': '... ... ... ... ...',
                      'ar': '... ... ... ... ...',
                      'tr': '... ... ... ... ...',
                      'id': '... ... ... ... ...',
                      'ai': '... ... ... ... ...',
                  }}
                  placeholder=""
                  country={phone.code}
                  value={phone.phone}
                  onChange={(number, countryData) => changePhoneHandler(number, countryData)}
                />
              </Form.Group>

              <Form.Group style={{ position: 'relative' }}>
                <div className={styles.labelErrorGroup}>
                  <Form.Label className={styles.label}>Business Name</Form.Label>

                  <Form.Control.Feedback
                    type="invalid"
                    className={`d-block ${styles.errorMsg}`}
                  >
                    {businessNameError}
                  </Form.Control.Feedback>
                </div>
                  <Form.Control
                      className={businessNameError ? styles.errorBorder : ''}
                      type="text"
                      autoComplete="off"
                      onChange={e => setBusinessName(e.target.value)}
                      value={businessName}
                  />
              </Form.Group>

              <Form.Group style={{ position: 'relative' }}>
                <div className={styles.labelErrorGroup}>
                  <Form.Label className={styles.label}>Password</Form.Label>
                  <Form.Control.Feedback
                    type="invalid"
                    className={`d-block ${styles.errorMsg}`}
                  >
                    {passwordError}
                  </Form.Control.Feedback>
                </div>
                  <Form.Control
                      className={passwordError ? styles.errorBorder : ''}
                      type="password"
                      autoComplete="off"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                  />
              </Form.Group>


                <Form.Group className="full-size full-size--less-space">
                        <Form.Check type="checkbox" id="signUpFormAgreeCheck">
                            <Form.Check.Input
                                required
                                type="checkbox"
                                checked={agree}
                                onChange={() => setAgree(!agree)}
                            />

                            <Form.Check.Label className={styles.label}>
                                I agree to the{' '}
                                <Link to={`/tc`} target="_blank" className="color-promo">
                                    terms and conditions
                                </Link>{' '}
                                and{' '}
                                <Link to={`/privacy`} target="_blank" className="color-promo">
                                    privacy policy
                                </Link>
                            </Form.Check.Label>
                            {agreeError && (
                                <Form.Control.Feedback
                                    type="invalid"
                                    className={'d-block error-msg error-msg--agree'}
                                >
                                    {/*{errorsData.errorRegister.agree}*/}
                                    Please tick this box
                                </Form.Control.Feedback>
                            )}
                        </Form.Check>
                    </Form.Group>


            <Form.Group
                controlId="formRecaptcha"
                style={{
                    marginTop: 20,
                }}
            >
                    <ReCAPTCHA
                        width={253}
                        sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                        onChange={e => setCaptcha(!!e)}
                    />
                    {captchaError && (
                            <Form.Control.Feedback
                                type="invalid"
                                className={'d-block error-msg error-msg--captcha'}
                            >
                                {captchaError}
                            </Form.Control.Feedback>
                        )}

                </Form.Group>

              <div className={styles.submit}>
                  <Button
                      block
                      variant="outline-secondary"
                      type="submit"
                      onClick={() => {
                         dispatch(signUp(token, {
                             first_name: firstName,
                             last_name: lastName,
                             business_name: businessName,
                             email,
                             number: phone,
                             password,
                             password_confirmation: password,
                            role: 'business',
                            captcha,
                            agree
                         }, () => onClose(), history));
                      }}
                  >
                      Continue
                  </Button>
              </div>
          </Modal.Body>
          <Modal.Footer className={styles.info}>
              <Info close={() => onClose()}/>
          </Modal.Footer>
      </Modal>
    );
}
