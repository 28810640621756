import React from 'react';
import defaultAvatar from '../../assets/default-avatar.jfif';
import s from './styles.module.scss';

const BaseImage = ({ imageUrl, className }) => {
  const onError = event => {
    event.target.src = defaultAvatar;
  };

  return (
    <img
      className={className || s.imageElement}
      src={imageUrl || defaultAvatar}
      onError={onError}
      alt={'pic'}
    />
    );
};

export const AvatarSmall = ({ imageUrl }) => {
  return (
    <div className={s.small}>
      <BaseImage imageUrl={imageUrl} />
    </div>
  );
};

export const AvatarLarge = ({ imageUrl }) => {
  return (
    <div className={s.wrapper}>
      <BaseImage imageUrl={imageUrl} />
    </div>
  );
};

export const AvatarGeneric = ({ imageUrl, className }) => {
  return (
    <BaseImage imageUrl={imageUrl} className={className} />
  );
};
