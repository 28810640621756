import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

export const PreviewProfileTopics = ({ topics }) => {

    const topicsList = useSelector(state => state.topics.data);

    return !R.isEmpty(topicsList) && !R.isEmpty(topics)
        ? (
            <StyledTopics>
                <ul className="topics-list">
                    {
                        topics.map(topic => {
                            return (
                                <li className="topics-item" key={topic.value}>
                                    <span>
                                        {topicsList.find(el => el.id === topic.value)
                                            ? topicsList.find(el => el.id === topic.value).name
                                            : ''
                                        }
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            </StyledTopics>
        ) : (
            <></>
        );
};

const StyledTopics = styled.div`
    .topics-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0;
        margin: 10px 0 15px;
        
        @media (max-width: 768px) {
          margin-bottom: 12px;
        }
    }
    
    .topics-item {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #20D5C4;
        list-style-type: none;

        @media (max-width: 1024px) {
          font-size: 12px;
          line-height: 16px;
        }
     
        &:not(:last-child){
            span {
                border-right: 1px solid #20D5C4;;
            }
        }
        
       &:first-child {
          span {
            padding-left: 0;
          }
        }
    }
    
    span {
        padding: 0 6px 0 6px;
        line-height: 16px;
    }
`;