import React, { useEffect, useState, lazy, Suspense } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PreviewProfileContent from './preview_profile_content/PreviewProfileContent';
import PreviewProfilePublish from './preview_profile_publish/PreviewProfilePublish';
import PreviewProfileOffer from "./preview_profile_content/PreviewProfileOffer";
import PreviewProfileTalks from "./recent_talks/PreviewProfileTalks";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataGet } from '../../api/helperFunctions';
import { ReactComponent as CvPreview } from "../../assets/redesign/cv.svg";
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import instagram from '../../assets/instagram__circle-coral.png';
import twitter from '../../assets/twitter__circle-coral.png';
import facebook from '../../assets/facebook__circle-coral.png';
import linkedin from '../../assets/linkedin__circle-coral.png';
import { Expertise } from './expertise/Expertise';
import { Testimonials } from './testimonials/Testimonials';
import * as R from "ramda";
import { Link } from "react-router-dom";
import Loader from "react-spinners/FadeLoader";
import footerBg from "../../assets/footer-bg.webp";
import './style.scss';
import { ReactComponent as LightHeart } from "../../assets/icons/lightHeart.svg";
import plus from '../../assets/icons/heard_line_black.png';
import defaultAvatar from '../../assets/default-avatar.jfif';
import formatSpeakerFee from '../../helpers/formatSpeakerFee';

const PreviewProfileMultimedia = lazy(() => import('./multimedia/PreviewProfileMultimedia'));

const PreviewProfile = ({ token }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const [dynamicText, setDynamicText] = useState({
    professionalTitle: '',
    tagline: '',
  });

  const [showExperience, setShowExperience] = useState(false);
  const [dataIsLoading, setDtaIsLoading] = useState(false);

  const [about, setAbout] = useState({
    fee: {
      from: '',
      to: '',
    },
  });

  const shortList = useSelector(state => state.speakers.shortList.data);
  const added = shortList.some(el => el.id === about.id);


  const addSpeakerInShortList = () => {
    const id = about.id
    if (shortList.some(speaker => speaker.id === id)) {
        dispatch({ type: 'REMOVE_SHORT_LIST_INIT' });
        fetchDataGet(`finder/short-list`, 'delete', user.token, { speakers: [id] })
            .then((response) => {
                if (response[0] === 200) {
                    dispatch({ type: 'REMOVE_SHORT_LIST_SUCCESS', payload: response[1] });
                }
            })
            .catch(() => {
                dispatch({ type: 'REMOVE_SHORT_LIST_ERROR', payload: false });
            });
    } else {
        if (shortList.length < 50) {
            dispatch({ type: 'ADD_SHORT_LIST_INIT' });
            fetchDataGet(`finder/short-list`, 'post', user.token, { speakers: [id] })
                .then((response) => {
                    if (response[0] === 200) {
                        dispatch({ type: 'ADD_SHORT_LIST_SUCCESS', payload: response[1] });
                    }
                })
                .catch(() => {
                    dispatch({ type: 'ADD_SHORT_LIST_ERROR', payload: false });
                });
        }
    }
  };

  useEffect(() => {
    dispatch({
      type: 'GET_ALL_TOPICS_INIT',
    });
    setDtaIsLoading(true);
    fetchDataGet('get/topics', 'get', token)
      .then((response) => {
        dispatch({
          type: 'GET_ALL_TOPICS_SUCCESS',
          payload: response[1].data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'GET_ALL_TOPICS_FAILURE',
          payload: error,
        });
      })
      .finally(() => {
        setDtaIsLoading(false);
      })
  }, []);

  const [credentials, setCredentials] = useState({
    expertise: {
      collaboration: '',
      additional_speaker_topic_details: '',
      speaker_event_types: [],
      speaker_type: [],
      speaker_topic: [],
    },
    testimonial: [],
    certificates: [],
  });

  const [portfolio, setPortfolio] = useState({
    recent_events: [],
    presentations: [],
    programs: [],
    multimedia: {
      videos: [],
      photos: [],
    },
    published: {
      books: [],
      press: [],
      podcasts: [],
    },
    social_reach: [],
  });

  useEffect(() => {
    dispatch({ type: 'TOGGLE_AGREE_MODAL', payload: false });
    setDtaIsLoading(true);
    fetchDataGet(
      'profile/about',
      'get',
      user.token
    ).then(res => {
      setAbout(res[1].data);
      setDynamicText({
        professionalTitle: res[1].data['professional_title'],
        tagline: res[1].data.tagline,
      })
      setDtaIsLoading(false);
    });

    fetchDataGet(
      'speaker/profile/credentials',
      'get',
      user.token
    ).then(res => {
      setCredentials(res[1].data)
      setDtaIsLoading(false);
    }
    );

    fetchDataGet(
      'speaker/profile/portfolio',
      'get',
      user.token
    ).then(res => {
      setPortfolio(res[1].data);
      setDtaIsLoading(false);
    });
  }, [dispatch]);

  const getTravel = () => {
    return (about.travel_details && about.travel_details.length) ? about.travel_details[0].name : ''
  }

  const greenBoxText = ['New to Finders Speakers. ', <br />, 'No events booked yet'];

  return (
    <div className="preview-profile-page">
      <div>
        <div className="grey-bg" style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: -1}}/>
        <div className="banner__clear" style={{ backgroundColor: '#161E27' }}>
          <img
            src={footerBg}
            alt="banner"
            className="banner__img"
          />
        </div>
        {!dataIsLoading
          ? (
            <section
              className={`${about['cover_picture'] !== 'null' && typeof about['cover_picture'] !== 'object' ? "wrap-banner" : ''} wrap`}>
              {
                about['cover_picture'] !== 'null' && typeof about['cover_picture'] !== 'object'
                  ?
                  (<div className="personal-banner">
                    <img
                      src={`${process.env.REACT_APP_DO_SPACES_URL}/${about['cover_picture']}`}
                      alt="banner"
                      className="banner__img"
                    />
                  </div>)
                  : null
              }
              <div
                className={`info ${!R.isNil(about['cover_picture']) && typeof about['cover_picture'] !== 'object' ? 'info-cover' : ''}`}
              >
                <div className="info__photo">
                  {about['photo'] &&
                    <img
                      src={`${process.env.REACT_APP_DO_SPACES_URL}/${about['photo']}`}
                      alt="avatar"
                      className="info__photo-img"
                      onError={event => { event.target.src=defaultAvatar; }}
                    />
                  }
                </div>
                <div className="info-text-wrap">
                  <div className="info__personal">
                    <div className="info__personal-name">
                      {about['first_name']} {about['last_name']}
                    </div>
                    <div className="info__personal-special">{dynamicText.professionalTitle}</div>
                    <div className="info__personal-desc" >{dynamicText.tagline}</div>
                  </div>
                  <div className="summary">
                    <div className="summary__line summary__line--location">
                      <div className="summary__line-label">Location</div>
                      <div className="summary__line-info">{about.location}</div>
                    </div>
                    <div className="summary__line">
                      <div className="summary__line-label">Travel to</div>
                      <div className="summary__line-info">{getTravel()}</div>
                    </div>
                    <div className="summary__line summary__line--fee">
                      <div className="summary__line-label">
                        Fee
                          <sup className="summary__fee-info">
                            <Tooltip placement="top" title={'Speaker fee is in $AUD'}><InfoIcon /></Tooltip>
                          </sup>


                      </div>
                      <div className="summary__line-info">
                        {
                          formatSpeakerFee(about['fee']['from'], about['fee']['to'])
                        }
                      </div>
                    </div>
                  </div>
                </div>


                <div className="btns_wrapper">
                  <button disabled={true} className="enquire__btn disabled">enquire now</button>
                  <button disabled={true} className={`enquire ${added ? '' : 'enquire_active'} like`} onClick={() => addSpeakerInShortList()}>
                      {added
                          ? <LightHeart />
                          : <div className="enquire__plus"><img alt="plus" src={plus} /></div>
                      }
                      <span className="info__add-btn" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                          {added ? 'Shortlisted' : 'Add to Shortlist'}
                      </span>
                  </button>
                </div>
              </div>
              <div className="main">
                <div className="content__sub">

                  {window.outerWidth > 1024 &&
                    <Expertise
                      credentials={credentials}
                      parent={'previewProfile'}
                    />
                  }

                  <div className="content">
                    <div className="custom-container">
                      <h2 className="custom-container__label social">Social Reach</h2>
                      <ul className="social-container__link">
                        {portfolio['social_reach'][0] && (<>
                          {portfolio['social_reach'][0]['instagram_followers'] > 0 &&
                            (
                              <li className="social-container__link-elem">
                                <img src={instagram} alt={'instagram icon'} />
                                <a
                                  target="_blank"
                                  href={portfolio['social_reach'][0]['instagram_link'] || '#'}
                                >
                                  <span className="social-container__bold">
                                    {(portfolio['social_reach'][0]['instagram_followers']).toLocaleString()}
                                  </span>{' '}
                                  <span className="grey-text">followers</span>
                                </a>
                              </li>
                            )}

                          {portfolio['social_reach'][0]['facebook_followers'] > 0 &&
                            (<li className="social-container__link-elem">
                              <img src={facebook} alt={'facebook icon'} />
                              <a
                                target="_blank"
                                href={portfolio['social_reach'][0]['facebook_link'] || '#'}
                              >
                                <span className="social-container__bold">
                                  {(portfolio['social_reach'][0]['facebook_followers']).toLocaleString()}
                                </span>{' '}
                                <span className="grey-text">followers</span>
                              </a>
                            </li>
                            )}

                          {portfolio['social_reach'][0]['linkedin_followers'] > 0 &&
                            (<li className="social-container__link-elem">
                              <img src={linkedin} alt={'linkedin icon'} />
                              <a
                                target="_blank"
                                href={portfolio['social_reach'][0]['linkedin_link']}
                              >
                                <span className="social-container__bold">
                                  {(portfolio['social_reach'][0]['linkedin_followers']).toLocaleString()}
                                </span>{' '}
                                <span className="grey-text">connections</span>
                              </a>
                            </li>
                            )}

                          {portfolio['social_reach'][0]['twitter_followers'] > 0 &&
                            (<li className="social-container__link-elem">
                              <img src={twitter} alt={'twitter icon'} />
                              <a
                                target="_blank"
                                href={portfolio['social_reach'][0]['twitter_link']}
                              >
                                <span className="social-container__bold">
                                  {(portfolio['social_reach'][0]['twitter_followers']).toLocaleString()}
                                </span>{' '}
                                <span className="grey-text">followers</span>
                              </a>
                            </li>
                            )}
                        </>)}
                      </ul>
                    </div>

                    <div className="custom-container social-container__event-elem event-elem">
                      <h2 className="event-elem__number">{0}</h2>
                      <p className="event-elem__text">
                        {greenBoxText}
                      </p>
                    </div>

                    <div className="custom-container collaboration">
                      <h3 className="custom-container__label custom-container__label--less-space">Collaboration
                              opportunities</h3>
                      <p className="sub__topic-desc">
                        {credentials.expertise.collaboration}
                      </p>
                      <Link to={'/send-offer'} target="_blank">
                        <button disabled={true} className="enquire__btn disabled">enquire now</button>
                      </Link>
                    </div>
                  </div>
                  {
                    window.outerWidth < 1024 && <Testimonials credentials={credentials} />
                  }
                </div>
                <div className="content-right">
                  <div className="custom-container about">
                    <h2 className="custom-container__label custom-container__label--less-space">Meet {about.first_name}</h2>
                    <PreviewProfileContent
                      content={[
                        {
                          title: "Speaker's Story",
                          content: [
                            {
                              description:
                                credentials['story'],
                              render: (
                                <div
                                  style={window.outerWidth > 1024 ? { marginTop: '20px' } : { marginTop: '34px' }}>
                                  <button className="cv"
                                    onClick={() => setShowExperience(!showExperience)}>
                                    <CvPreview />
                                              DOWNLOAD {about['first_name']}'s CV
                                            </button>
                                  {
                                    showExperience
                                    && credentials['experience'] && credentials['experience'].map((item, index) => (
                                      <p className={'cv__item'} key={index}>
                                        <span
                                          className={'cv__job-name'}>{item.title}</span> - {item.company}
                                      </p>
                                    ))
                                  }
                                </div>
                              )
                            }
                          ],
                        },
                        {
                          title: `Achievements & Certifications (${credentials['certificates'].length})`,
                          content: credentials['certificates'] ? credentials['certificates'].map(i => ({
                            text: i.title,
                            achievements: true,
                            img: i.path && `${process.env.REACT_APP_DO_SPACES_URL}/${i.path}`
                          }))
                            : []
                        },

                        {
                          title: 'Value Delivered',
                          content: [
                            {
                              description:
                                credentials['key_takeaways'],
                            },
                          ],
                        },
                      ]}
                      nav={true}
                    />
                  </div>

                  {portfolio['multimedia']['videos'].length > 0 ||
                    portfolio['multimedia']['photos'].length > 0 ? (
                    <div className="custom-container multimedia">
                      <h2 className="custom-container__label">
                        Watch {about['first_name']} in action
                      </h2>
                      <Suspense fallback={<div>Loading...</div>}>
                        <PreviewProfileMultimedia
                          content={[
                            {
                              title: 'Videos',
                              content:
                                portfolio['multimedia'][
                                'videos'
                                ],
                            },
                            {
                              title: 'Photos',
                              content:
                                portfolio['multimedia'][
                                'photos'
                                ],
                            },
                          ]}
                        />
                      </Suspense>
                    </div>
                  ) : null}

                  {window.outerWidth < 1024 &&
                    <Expertise
                      credentials={credentials}
                      parent={'about'}
                    />
                  }

                  <div className="custom-container portfolio">
                    <h2 className="custom-container__label">
                      What {about.first_name} Offers
                    </h2>
                    <PreviewProfileOffer
                      content={[
                        {
                          title: `Presentations (${portfolio['presentations'].length})`,
                          content: portfolio['presentations']
                        },
                        {
                          title: `Courses/Programs (${portfolio['programs'].length})`,
                          content: portfolio['programs'].map(p => ({ ...p, date: p['created_at'] })),
                        },
                      ]}
                      nav={true}
                      loadmore={'Offers'}
                    />
                  </div>

                  <div className="custom-container">
                    <h2 className="custom-container__label"
                      style={window.outerWidth > 1024 ? {} : { marginBottom: '14px' }}>
                      Recent talks ({portfolio['recent_events'].length})
                    </h2>
                    <PreviewProfileTalks
                      content={[{ content: portfolio['recent_events'] }]}
                    />
                  </div>

                  <div className="custom-container publish">
                    <h2 className="custom-container__label">
                      In the media
                    </h2>
                    <PreviewProfilePublish
                      content={[
                        {
                          title: `Books (${portfolio['published']['books'].length})`,
                          content:
                            portfolio['published']['books'],
                        },
                        {
                          title: `Press (${portfolio['published']['press'].length})`,
                          content:
                            portfolio['published']['press'],
                        },
                        {
                          title: `Podcast/Blogs (${portfolio['published']['podcasts'].length})`,
                          content:
                            portfolio['published']['podcasts'],
                        },
                      ]}
                    />
                  </div>

                  {
                    window.outerWidth > 1024 && <Testimonials credentials={credentials} />
                  }
                </div>
              </div>
            </section>
          ) : (
            <div className="custom-container preloader">
              <Loader color="#20d5c4" size={100} loading={true} />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default PreviewProfile;
