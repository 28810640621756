import React from 'react';
import useBlockCrawling from '../../helpers/useBlockCrawling';
import { GetStartedHero } from "./GetStartedHero";
import styled from "styled-components";

export const GetStartedFirstScreen = () => {
  useBlockCrawling();

  return (
    <GetStartedStyles>
      <main className="home hiw">
        <GetStartedHero
          style={{
            fontWeight: '300',
            fontSize: '18px',
            lineHeight: '24px',
          }}
        >
        </GetStartedHero>
      </main>
    </GetStartedStyles>
  )
}

export const GetStartedStyles = styled.div`
.hiw {
  .d-block-lg {
    display: block;
    
    @media (max-width: 1360px) {
      display: inline;
    }
  }
  
  @media (max-width: 768px) {
      .d-block-xs {
          display: block;
      }
  }
  
   & .hero {
    @media (max-width: 768px) {
      padding: 89px 0 40px;
    }
    
    &__text {
      max-width: 870px !important;
      
      @media screen and (max-width: 1280px) {
        max-width: 563px!important;
      }
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }  
    }
   }
   
   & .title {
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 10px;
    }
   }

   .home__btn {
      width: 199px;
      padding: 0 76px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
        
       @media (width: 1280px) {
          padding: 0 63px;
       }
       @media (max-width: 768px) {
          height: auto;
          padding: 14px 38px;
          font-size: 12px;
          line-height: 16px;
       }
    }
    
    .connect {
      @media (max-width: 768px) {
        padding: 40px 0 20px;
      }
      
      & .text-center {
        font-size: 26px;
        line-height: 32px;
      }
      
      &__wrap {
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
    }
    
    .connect__card-text {
        bottom: -80px;
        height: 164px;
        width: 490px;
        padding: 40px 38px;
        
        @media (max-width: 1280px) {
            bottom: -66px;
            height: 124px;
            padding: 19px 40px;
            width: 410px;
        }
        
        @media (max-width: 1024px) {
            height: auto;
            width: 350px;
            padding: 24px 16px;
        }
        
        @media (max-width: 768px) {
          position: static;
          transform: translateX(0) translateY(-30px);
          width: 300px;
          height: auto;
          padding: 24px 16px;
          bottom: -38px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
      }
    }
   
    .connect__item {
      @media (max-width: 768px) {
        margin: 0 0 -10px!important;
      }
    }
    
    .connect__item:nth-child(1) {
        margin-bottom: 120px;
        @media (max-width: 1280px) {
           margin-bottom: 100px;
       }
    }
    
    .connect__item:nth-child(2) {
        margin-bottom: 120px;
        @media (max-width: 1280px) {
           margin-bottom: 100px;
       }
    }
    
    .connect .subtitle {
        font-size: 62px;
        line-height: 72px;
        max-width: 1073px;  
        margin-bottom: 80px;  
    
        @media (max-width: 1360px) {
               font-size: 32px;
               line-height: 44px;
               max-width: 550px;    
               margin-bottom: 60px;
           }
       @media (max-width: 1280px) {
           margin: 0 auto 60px;
       }
       
       @media (max-width: 1024px) {
           max-width: 100%;    
           font-size: 26px;
           line-height: 32px;
           margin-bottom: 30px;
       }
    }
} 
`