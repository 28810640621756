import qs from 'qs';
import Joi from 'joi';
import pick from 'lodash/pick';
import { filterBudgetOptions } from './filterOptions';

export const getSearchValuesFromQueryString = (queryString, filterOptions) => {
  const values = qs.parse(queryString, { ignoreQueryPrefix: true });

  const normalize = param => {
    const { value, error } = Joi.array().items(Joi.valid(...filterOptions[param].map(el => el.value + '')))
    .validate(values[param], { presence: 'required' });

    if (error) {
      return [];
    }

    return value.map(a => +a);
  };

  let budgets = [];

  if (!Joi.array().items(Joi.array().items(Joi.number())).validate(values.budgets, { presence: 'required' }).error) {
    budgets = values.budgets.map(
      ([from, to]) => filterOptions.budgets.find(el => el.from === +from && el.to === +to)
    ).filter(_ => _).map(item => item.value);
  }

  return {
    types: normalize('types'),
    event_types: normalize('event_types'),
    topics: normalize('topics'),
    budgets,
    genders: normalize('genders'),

    search: values.search || '',
    location: values.location || '',
    order: values.order || 'desc',
    sort: values.sort || 'recent',
  };
};

export const getQueryStringFromSearchValues = (
  searchValues
) => qs.stringify(pick(searchValues, [
  'event_types',
  'genders',
  'location',
  'order',
  'search',
  'sort',
  'topics',
  'types',
  'budgets',
  'page'
]), {
  filter: (prefix, value) => {
    switch (prefix) {
      case 'event_types':
      case 'genders':
      case 'topics':
      case 'types':
        if (value.length === 0) {
          return;
        }

        return value;

      case 'budgets':
        if (value.length === 0) {
          return;
        }

        return value.map(val => filterBudgetOptions.find(el => el.value === val)).map(({ from, to }) => [from, to])

      case 'location':
      case 'search':
      case 'order':
      case 'sort':
        if (value.trim() === '') {
          return;
        }
        return value;
      case 'page':
        if (value > 1) {
          return value;
        }
        return;
      default:
        return value;
    }
  }
});
