import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import check from '../../../assets/elips-check.svg'
import constants from '../../../constants';

export default () => {
    return <TPStyled>
        <div className="tp-content">
            <img src={check} className="tp-img" alt="check" />
            <h2 className="tp-title">You have made a start on your profile!</h2>
            <p className="tp-text">We’re excited to see you have made a start on your profile. Don’t forget to come back later and publish. Your profile won’t be seen by event planners until you do. If need any further help submitting your profile contact our team at <a href="mail:help@findersspeakersco.com">help@findersspeakersco.com</a></p>
            <div style={{display: 'flex'}}>
                <Link to="/preview-profile"><div className="tp-btn">
                    VIEW PROFILE
                </div></Link>
                <Link to="/builder-step1"><div className="tp-btn">
                    EDIT PROFILE
                </div></Link>
            </div>
            
        </div>

        <div className="tp-footer">
            All Rights Reserved     {constants.copyright}
        </div>
    </TPStyled>
}

const TPStyled = styled.div`
    background: #20D5C4;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .tp {

        &-content {
            max-width: 543px;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &-img {
        }

        &-title {
            font-size: 28px;
            font-weight: 500;
            color: #293749;
            margin: 15px 0;
            text-align: center;
        }

        &-text {
            font-size: 13px;
            font-weight: 300;
            color: #293749;
            text-align: center;
            max-width: 434px;

            a {
                color: #293749;
                text-decoration: underline;
            }
        }

        &-btn {
            height: 35px;
            width: 125px;
            background: #E8388A;
            border-radius: 40px;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px 5px 0;
        }

        &-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: 300;
            color: #293749;
        }
    }
`