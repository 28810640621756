import React, { lazy, useEffect, useState, Suspense } from 'react';
import PreviewProfileContent from './preview_profile_content/PreviewProfileContent';
import PreviewProfilePublish from './preview_profile_publish/PreviewProfilePublish';
import PreviewProfileOffer from "./preview_profile_content/PreviewProfileOffer";
import PreviewProfileTalks from "./recent_talks/PreviewProfileTalks";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataGet } from '../../api/helperFunctions';
import useTitle from '../../helpers/useTitle';
import useDescription from '../../helpers/useDescription';
import useSocialMeta from '../../helpers/useSocialMeta';
import useCanonical from '../../helpers/useCanonical';
import getFileUrl from '../../helpers/getFileUrl';
import SignUpBusinessModal from '../../AuthScreens/SignUpBusinessModal';
import { toggleSignUpBusinessModal } from '../../store/actions.js';
import { ReactComponent as Keynote } from '../../assets/keynote.svg';
import { ReactComponent as Expert } from '../../assets/expert.svg';
import { ReactComponent as Panellist } from '../../assets/panellist.svg';
import { ReactComponent as Seminar } from '../../assets/seminar.svg';
import { ReactComponent as MC } from '../../assets/mc.svg';
import { ReactComponent as Entertainer } from '../../assets/entertainer.svg';
import { ReactComponent as Trainer } from '../../assets/trainer.svg';
import { ReactComponent as Coach } from '../../assets/coach.svg';
import instagram from '../../assets/instagram__circle-coral.png';
import twitter from '../../assets/twitter__circle-coral.png';
import facebook from '../../assets/facebook__circle-coral.png';
import linkedin from '../../assets/linkedin__circle-coral.png';
import { Expertise } from './expertise/Expertise';
import { Testimonials } from './testimonials/Testimonials';
import NotFound from '../../NotFound';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import * as R from "ramda";
import Loader from "react-spinners/FadeLoader";
import { ReactComponent as LightHeart } from "../../assets/icons/lightHeart.svg";
import { ReactComponent as CvPreview } from "../../assets/redesign/cv.svg";
import footerBg from "../../assets/footer-bg.webp";
import plus from '../../assets/icons/heard_line_black.png';
import './style.scss';
import formatSpeakerFee from '../../helpers/formatSpeakerFee';

const PreviewProfileMultimedia = lazy(() => import('./multimedia/PreviewProfileMultimedia'));

const SpeakerProfile = () => {
    const history = useHistory();
    const location = useLocation();
    const [notFound, setNotFound] = useState(false);
    const { id: pageId, slug } = useParams();

    const setCanonicalLink = useCanonical();

    const userInfo = useSelector(state => state.speakers.speakers.speaker);

    const speakerFullName = `${userInfo.first_name || ''} ${userInfo.last_name || ''}`.trim();
    const speakerProfessionalTitle = userInfo.personal_details
        && (userInfo.personal_details.professional_title || 'Speaker');

    useTitle(speakerFullName);

    useDescription(speakerProfessionalTitle);

    useSocialMeta({
        title: speakerFullName,
        description: speakerProfessionalTitle,
        image: getFileUrl(userInfo.personal_details?.photo),
        type: 'profile'
    });

    const user = useSelector(state => state.user);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const shortList = useSelector(state => state.speakers.shortList.data);
    const isSignUpBusinessModalOpen = useSelector(state => state.modal.isSignUpBusinessModalOpen);

    const dispatch = useDispatch();
    const [showExperience, setShowExperience] = useState(false);
    const [credentials, setCredentials] = useState({
        expertise: {
            collaboration: '',
            additional_speaker_topic_details: '',
            speaker_event_types: [],
            speaker_type: [],
            speaker_topic: [],
        },
        testimonial: [],
        certificates: [],
    });

    useEffect(() => {
        dispatch({ type: 'GET_SPEAKER_INIT' });
        fetchDataGet(`finder/speakers/${pageId}`, 'get', user.token)
            .then((response) => {
                if (response[0] === 200) {
                    dispatch({ type: 'GET_SPEAKER_SUCCESS', payload: response[1] });
                } else if (response[0] === 404) {
                    setNotFound(true);
                }

                return response[1];
            }).then(res => {
                setCredentials({
                    testimonial: res.testimonial,
                    certificates: res.certificates,
                    expertise: res.expertise,
                    story: res.story,
                    key_takeaways: res.key_takeaways,
                    experience: res.experience
                });

                const correctSlug = `${res.first_name}${res.last_name}`
                    .replace(/\P{L}+/ug, '')
                    .toLowerCase();

                const sluggedPath = `/speakers/${res.id}/${correctSlug}`;

                if (slug !== correctSlug) {
                    history.push(sluggedPath);
                }

                setCanonicalLink(window.location.origin + sluggedPath);

            })
            .catch((e) => {
                dispatch({ type: 'GET_SPEAKER_ERROR', payload: false });
                return e;
            });
    }, [pageId]);

    const editNum = num => {
        if (num > 1000000) {
            return Math.round(num / 1000000) + 'M';
        } else if (num > 1000) {
            return Math.round(num / 1000) + 'k';
        } else return num;
    };

    const spType = id => {
        switch (id) {
            case 1:
                return <Keynote fill="#293749" />;
            case 2:
                return <MC fill="#293749" />;
            case 3:
                return <Panellist fill="#293749" />;
            case 4:
                return <Coach fill="#293749" />;
            case 5:
                return <Entertainer fill="#293749" />;
            case 6:
                return <Trainer fill="#293749" />;
            case 7:
                return <Seminar fill="#293749" />;
            case 8:
                return <Expert fill="#293749" />;
            default:
                return null;
        }
    };

    const getTravel = () => {
        return userInfo['personal_details'].travel_details[0]
    }

    const addSpeakerInShortList = (id) => {
        if (shortList.some(speaker => speaker.id === id)) {
            dispatch({ type: 'REMOVE_SHORT_LIST_INIT' });
            fetchDataGet(`finder/short-list`, 'delete', user.token, { speakers: [id] })
                .then((response) => {
                    if (response[0] === 200) {
                        dispatch({ type: 'REMOVE_SHORT_LIST_SUCCESS', payload: response[1] });
                    }
                })
                .catch(() => {
                    dispatch({ type: 'REMOVE_SHORT_LIST_ERROR', payload: false });
                });
        } else {
            if (shortList.length < 50) {
                dispatch({ type: 'ADD_SHORT_LIST_INIT' });
                fetchDataGet(`finder/short-list`, 'post', user.token, { speakers: [id] })
                    .then((response) => {
                        if (response[0] === 200) {
                            dispatch({ type: 'ADD_SHORT_LIST_SUCCESS', payload: response[1] });
                        }
                    })
                    .catch(() => {
                        dispatch({ type: 'ADD_SHORT_LIST_ERROR', payload: false });
                    });
            }
        }
    };

    const replacer = (data) => {
        if (data) {
            return data.replace(/\n/ig, '</br>');
        }
        return null;
    };

    const added = shortList.some(el => el.id === userInfo.id);

    const enquireNowClickHandler = () => {
        if (user.role === 'business') {
            history.push(`/create-enquiry?speaker_id=${pageId}`);
        } else {
            history.push('/contact-us');
            // dispatch(toggleSignUpBusinessModal());
        }
    };

    const shortlistClickHandler = () => {
        if (user.role === 'business') {
            addSpeakerInShortList(userInfo.id);
        } else {
            history.push('/contact-us');
            // dispatch(toggleSignUpBusinessModal());
        }
    };

    // RENDER
    if (R.isEmpty(userInfo) && !notFound) {
        return (
          <div className="preview-profile-page">
              <div className="custom-container preloader">
                  <Loader color="#20d5c4" size={100} loading={true} />
              </div>
          </div>
        );
    }

    if (notFound) {
        return <NotFound />;
    }

    return (
        <div className="preview-profile-page"
        // isBanner={!R.isNil(userInfo['personal_details'])
        // && typeof userInfo['personal_details']['cover_picture'] !== 'object'}
        >
            <div>
                <div className="grey-bg" style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: -1}}></div>
                <div className="banner__clear" style={{ backgroundColor: '#161E27' }}>
                    <img
                        src={footerBg}
                        alt="banner"
                        className="banner__img"
                    />
                </div>
                <section
                    className={`${!R.isNil(userInfo['personal_details'])
                        && typeof userInfo['personal_details']['cover_picture'] !== 'object'
                        ? "wrap-banner" : ''
                        }
                        wrap`
                    }
                >
                    {
                        !R.isNil(userInfo['personal_details']) && typeof userInfo['personal_details']['cover_picture'] !== 'object'
                            ?
                            (<div className="personal-banner">
                                <img
                                    src={`${process.env.REACT_APP_DO_SPACES_URL}/${userInfo['personal_details']['cover_picture']}`}
                                    alt="banner"
                                    className="banner__img"
                                />
                            </div>)
                            : null
                    }

                    <div

                        className={`info ${!R.isNil(userInfo['personal_details']) && typeof userInfo['personal_details']['cover_picture'] !== 'object' ? 'info-cover' : ''}`}
                    >
                        <div className="info__photo">
                            {userInfo['personal_details'] && userInfo['personal_details']['photo']
                                ? <img
                                    src={`${process.env.REACT_APP_DO_SPACES_URL}/${userInfo['personal_details'] && userInfo['personal_details']['photo']}`}
                                    alt="avatar"
                                    className="info__photo-img"
                                />

                                : <div className="no-photo" />
                            }
                        </div>
                        <div className="info-text-wrap">
                            <div className="info__personal">
                                <div className="info__personal-name">
                                    {userInfo['first_name']} {userInfo['last_name']}
                                </div>
                                <div
                                    className="info__personal-special">{userInfo['personal_details'] && userInfo['personal_details']['professional_title']}</div>
                                <div
                                    className="info__personal-desc">{userInfo['personal_details'] && userInfo['personal_details']['tagline']}</div>
                            </div>

                            <div className="btns_wrapper">
                                <button
                                  disabled={user.role !== 'business' && isAuthenticated}
                                  className="enquire__btn"
                                  onClick={enquireNowClickHandler}>
                                    enquire now
                                </button>
                                <button
                                  className={`enquire ${added ? '' : 'enquire_active'}`}
                                  style={{ cursor: 'pointer' }}
                                  onClick={shortlistClickHandler}
                                  disabled={user.role !== 'business' && isAuthenticated}
                                >
                                    {added
                                        ? <LightHeart />
                                        : <div className="enquire__plus"><img alt="plus" src={plus} /></div>
                                    }
                                    <span className="info__add-btn">
                                        {added ? 'Shortlisted' : 'Add to Shortlist'}
                                    </span>
                                </button>
                            </div>

                            <div className="summary">
                                <div className="summary__line summary__line--location">
                                    <div className="summary__line-label">Location</div>
                                    <div
                                        className="summary__line-info">{userInfo['personal_details'] && userInfo['personal_details'].location}</div>
                                </div>
                                <div className="summary__line">
                                    <div className="summary__line-label">Travel to</div>
                                    <div className="summary__line-info">{getTravel()}</div>
                                </div>
                                <div className="summary__line summary__line--fee">
                                    <div className="summary__line-label">Fee</div>
                                    <div className="summary__line-info">
                                        {
                                            formatSpeakerFee(
                                              userInfo['fee']['from'],
                                              userInfo['fee']['to']
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main">
                        <div className="content__sub">
                            <Expertise
                                credentials={credentials}
                                parent={'speakerProfile'}
                                spType={spType}
                            />

                            <div className="custom-container">
                                <h2 className="custom-container__label">Social Reach</h2>
                                {!R.isEmpty(userInfo['social_reach']) && (
                                    <ul className="social-container__link">
                                        {userInfo['social_reach'][0]['instagram_followers'] > 0 &&
                                            (<li className="social-container__link-elem">
                                                <img src={instagram} alt="img" />
                                                <a
                                                    target="_blank"
                                                    href={userInfo['social_reach'][0]['instagram_link'] || '#'}
                                                >
                                                    <span className="social-container__bold">
                                                        {(userInfo['social_reach'][0]['instagram_followers']).toLocaleString()}
                                                    </span>{' '}
                                                    <span className="grey-text">followers</span>
                                                </a>
                                            </li>)
                                        }

                                        {userInfo['social_reach'][0]['facebook_followers'] > 0 &&
                                            (<li className="social-container__link-elem">
                                                <img src={facebook} alt="img" />
                                                <a
                                                    target="_blank"
                                                    href={userInfo['social_reach'][0]['facebook_link'] || '#'}
                                                >
                                                    <span className="social-container__bold">
                                                        {(userInfo['social_reach'][0]['facebook_followers']).toLocaleString()}
                                                    </span>{' '}
                                                    <span className="grey-text">followers</span>
                                                </a>
                                            </li>)
                                        }

                                        {userInfo['social_reach'][0]['linkedin_followers'] > 0 &&
                                            (<li className="social-container__link-elem">
                                                <img src={linkedin} alt="img" />
                                                <a
                                                    target="_blank"
                                                    href={userInfo['social_reach'][0]['linkedin_link']}
                                                >
                                                    <span className="social-container__bold">
                                                        {(userInfo['social_reach'][0]['linkedin_followers']).toLocaleString()}
                                                    </span>{' '}
                                                    <span className="grey-text">connections</span>
                                                </a>
                                            </li>)
                                        }

                                        {userInfo['social_reach'][0]['twitter_followers'] > 0 &&
                                            (<li className="social-container__link-elem">
                                                <img src={twitter} alt="img" />
                                                <a
                                                    target="_blank"
                                                    href={userInfo['social_reach'][0]['twitter_link']}
                                                >
                                                    <span className="social-container__bold">
                                                        {(userInfo['social_reach'][0]['twitter_followers']).toLocaleString()}
                                                    </span>{' '}
                                                    <span className="grey-text">followers</span>
                                                </a>
                                            </li>)
                                        }
                                    </ul>
                                )}
                            </div>

                            <div className="custom-container social-container__event-elem event-elem">
                                <h2 className="event-elem__number">{0}</h2>
                                <p className="event-elem__text">
                                    New to Finders Speakers.<br />No events booked yet
                                </p>
                            </div>

                            <div className="custom-container collaboration">
                                <h3 className="custom-container__label custom-container__label--less-space">Collaboration
                                    opportunities</h3>
                                <p className="sub__topic-desc">
                                    {credentials.expertise.collaboration}
                                </p>
                                <button
                                  disabled={isAuthenticated && user.role !== 'business'}
                                  className="enquire__btn"
                                  onClick={enquireNowClickHandler}
                                >enquire now</button>
                            </div>

                            {
                                window.outerWidth < 768 && <Testimonials credentials={credentials} />
                            }
                        </div>
                        <div className="content-right">
                            <div className="custom-container about">
                                <h2 className="custom-container__label custom-container__label--less-space">Meet {userInfo.first_name}</h2>
                                <PreviewProfileContent
                                    content={[
                                        {
                                            title: "Speaker's Story",
                                            content: [
                                                {
                                                    description:
                                                        credentials['story'],
                                                    render: (
                                                        <div
                                                            style={window.outerWidth > 768 ? { marginTop: '20px' } : { marginTop: '34px' }}>
                                                            <button className="cv"
                                                                onClick={() => setShowExperience(!showExperience)}>
                                                                <CvPreview />
                                                                DOWNLOAD {userInfo['first_name']}'s CV
                                                            </button>
                                                            {
                                                                showExperience
                                                                && credentials['experience'] && credentials['experience'].map((item, index) => (
                                                                    <p className={'cv__item'} key={index}>
                                                                        <span
                                                                            className={'cv__job-name'}>{item.title}</span> - {item.company}
                                                                    </p>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }
                                            ],
                                        },

                                        {
                                            title: `Achievements & Certifications (${credentials['certificates'] && credentials['certificates'].length || 0})`,
                                            content: !R.isNil(credentials['certificates'])
                                                ? credentials['certificates'].map(i => ({
                                                    text: i.title,
                                                    achievements: true,
                                                    img: i.path && `${process.env.REACT_APP_DO_SPACES_URL}/${i.path}`
                                                }))
                                                : []
                                        },

                                        {
                                            title: 'Value Delivered',
                                            content: [
                                                {
                                                    description:
                                                        credentials['key_takeaways'],
                                                },
                                            ],
                                        },
                                    ]}
                                    nav={true}
                                />
                            </div>

                            {(userInfo['multimedia'] && userInfo['multimedia']['videos'].length > 0) ||
                                (userInfo['multimedia'] && userInfo['multimedia']['photos'].length > 0) ? (
                                <div className="custom-container multimedia">
                                    <h2 className="custom-container__label">
                                        Watch {userInfo['first_name']} in action
                                    </h2>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <PreviewProfileMultimedia
                                            content={[
                                                {
                                                    title: 'Videos',
                                                    content: userInfo['multimedia'] &&
                                                        userInfo['multimedia'][
                                                        'videos'
                                                        ],
                                                },
                                                {
                                                    title: 'Photos',
                                                    content: userInfo['multimedia'] &&
                                                        userInfo['multimedia'][
                                                        'photos'
                                                        ],
                                                },
                                            ]}
                                        />
                                    </Suspense>
                                </div>
                            ) : null}



                            <div className="custom-container portfolio">
                                <h2 className="custom-container__label">
                                    What {userInfo.first_name} Offers
                                </h2>
                                <PreviewProfileOffer
                                    content={[
                                        {
                                            title: `Presentations (${userInfo['presentations'] && userInfo['presentations'].length})`,
                                            content: userInfo['presentations'] ? userInfo['presentations'] : []
                                        },
                                        {
                                            title: `Courses/Programs (${userInfo['programs'] && userInfo['programs'] && userInfo['programs'].length})`,
                                            content: userInfo['programs'] || [],
                                        }
                                    ]}
                                    nav={true}
                                    loadmore={'Offers'}
                                />
                            </div>

                            <div className="custom-container">
                                <h2 className="custom-container__label"
                                    style={window.outerWidth > 768 ? {} : { marginBottom: '14px' }}>
                                    Recent talks
                                    ({userInfo['recent_events'] && userInfo['recent_events'].length})
                                </h2>
                                <PreviewProfileTalks
                                    content={[
                                        {
                                            content: userInfo['recent_events'] ? userInfo['recent_events'] : [],
                                        }
                                    ]}
                                />
                            </div>

                            <div className="custom-container publish">
                                <h2 className="custom-container__label">
                                    In the media
                                </h2>
                                <PreviewProfilePublish
                                    content={[
                                        {
                                            title: `Books (${userInfo['published'] && userInfo['published']['books'].length})`,
                                            content: userInfo['published'] ?
                                                userInfo['published']['books'] : [],
                                        },
                                        {
                                            title: `Press (${userInfo['published'] && userInfo['published']['press'].length})`,
                                            content: userInfo['published'] ?
                                                userInfo['published']['press'] : [],
                                        },
                                        {
                                            title: `Podcast/Blogs (${userInfo['published'] && userInfo['published']['podcasts'].length})`,
                                            content: userInfo['published'] ?
                                                userInfo['published']['podcasts'] : [],
                                        },
                                    ]}
                                />
                            </div>
                            {
                                window.outerWidth > 768 && <Testimonials credentials={credentials} />
                            }
                        </div>
                    </div>
                </section>
            </div>

            <SignUpBusinessModal
              show={isSignUpBusinessModalOpen}
              onClose={() => dispatch(toggleSignUpBusinessModal())}
            />
        </div>
    );
};

export default SpeakerProfile;
