import React from 'react';
import classNames from 'classnames';
import BaseCheckbox from '@material-ui/core/Checkbox';
import { ReactComponent as Checkmark } from '../../../../assets/checkmark.svg';
import styles from './styles.module.scss';

const Empty = ({ error, disabled, children }) => {
  return (
    <span
      className={classNames(styles.element, { [styles.error]: error, [styles.disabled]: disabled })}
    >
      {children}
    </span>
  );
};

const Checked = ({ error, disabled }) => {
  return (
    <Empty disabled={disabled}>
      <span className={styles.checkmark}>
        <Checkmark error={error} />
      </span>
    </Empty>
  );
};

const Checkbox = ({ error, disabled, ...props }) => {
  return (
    <span className={styles.container}>
      <BaseCheckbox
        {...props}
        disabled={disabled}
        icon={<Empty error={error} disabled={disabled} />}
        checkedIcon={<Checked error={error} disabled={disabled} />}
        classes={{
          root: styles.root
        }}
      />
    </span>
  );
};

export default Checkbox;
