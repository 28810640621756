import { connect } from 'react-redux';
import FindSpeakersScreens from './FindSpeakersScreens';

const mapStateToProps = state => ({
  user: state.user,
  portal: state.portal,
});

export default connect(
  mapStateToProps
)(FindSpeakersScreens);
