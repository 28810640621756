import React from 'react';
import BaseModal from '../../BaseModal';
import styles from './styles.module.scss';

const MessageBoxUserModal = ({ isOpen, title, message, onOk, children, footer }) => {

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => {}}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>{title}</div>

        <div className={styles.modalBody}>
          <p className={styles.text}>{message}</p>
          <p className={styles.text}>{children}</p>
        </div>

        <div className={styles.buttons}>
          <button className={styles.okButton} onClick={onOk}>OK</button>
        </div>

        {footer && (
          <div className={styles.footer}>{footer}</div>
        )}
      </div>
    </BaseModal>
  );
};

export default MessageBoxUserModal;
