import { ActionTypes } from '../constants/createEnquiryConstants';

const {
    RESET_ERRORS,
    SET_ENQUIRY_FIELD,
    CLEAR_ENQUIRY_STORE,
    SET_ENQUIRY_DATES,
    SET_ENQUIRY_BUDGET,
    DELETE_ENQUIRY_FILE,
    SAVE_LOCAL_DATES_VALUES,
    SET_PORTAL_STATUS,
} = ActionTypes;

export const setEnquiryField = (name, value) => ({
    type: SET_ENQUIRY_FIELD,
    payload: { name, value },
});

export const setPortalStatus = (status) => ({
    type: SET_PORTAL_STATUS,
    payload: status,
});

export const resetErrors = fieldName => {
    return fieldName
    ? ({
        type: RESET_ERRORS,
        payload: fieldName,

    }) : ({
        type: RESET_ERRORS,
    })};

export const setEnquiryDates = array => ({
    type: SET_ENQUIRY_DATES,
    payload: array,
});

export const clearEnquiryStore = () => ({
    type: CLEAR_ENQUIRY_STORE,
});

export const setEnquiryBudget = value => ({
    type: SET_ENQUIRY_BUDGET,
    payload: value,
});

export const deleteEnuiryFile = idx => ({
    type: DELETE_ENQUIRY_FILE,
    payload: idx,
});

export const saveLocalDatesValues = datesArray => ({
    type: SAVE_LOCAL_DATES_VALUES,
    payload: datesArray,
});
