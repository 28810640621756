import React from 'react';
import { PreviewProfileTopics } from '../topics/PreviewProfileTopics';
import './style.scss';

class PostText extends React.Component {
  constructor(props) {
    super(props);
    this.refContent = React.createRef();

    const description = props.description || '';

    this.state = {
      description:
        description.length > 250
          ? description.slice(0, 250) + '...'
          : description,
      split: description.length > 250,
    };
  };

  allContent() {
    this.setState({ description: this.props.description, split: false });
  };

  componentDidUpdate() {
    const desc = this.state.description;
    this.refContent.current.innerHTML = Array.isArray(desc)
      ? desc.map(item => `<div class="post__m10">${this.replacer(item)}</div>`).join('')
      : this.replacer(desc)
  };

  componentDidMount() {
    const desc = this.state.description;
    this.refContent.current.innerHTML = Array.isArray(desc)
      ? desc.map(item => `<div class="post__m10">${this.replacer(item)}</div>`).join('')
      : this.replacer(desc)
  };

  replacer(data) {
    return data.replace(/\n/ig, '</br>')
  };

  render() {
    return (
      <>
        <div className="post__text" ref={this.refContent}></div>
        {this.state.split ? (
          <div
            className="post__btn"
            onClick={() => this.allContent()}
          >
            Read More
          </div>
        ) : null}
      </>
    );
  };
};

export default class PreviewProfileContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      maxItem: 2,
      clicked: false
    };
  };

  editIndex(id) {
    this.setState({ active: id, clicked: true });
  };

  editLoadMore() {
    let add = this.props.lazyload || Infinity;
    this.setState({ maxItem: this.state.maxItem + add });
  };

  replacer(data) {
    return (data.map(elem => {
      return { ...elem, text: elem.text.replace(/\n/ig, '</br>') }
    }))
  };

  render() {
    let activeTabIndex;

    if (this.state.clicked) {
      activeTabIndex = this.state.active;
    } else {
      const firstPopulated = this.props.content.findIndex(({ content }) => content.length);
      if (firstPopulated === -1) {
        activeTabIndex = 0;
      } else {
        activeTabIndex = firstPopulated;
      }
    }

    return (
      <div className="preview-profile-content">
        {!this.props.nav || (
          <ul className="post__list">
            {this.props.content.map((value, index) => {
              return (
                <React.Fragment key={value.title + index}>
                  <li
                    className={[
                      'post__list-elem',
                      activeTabIndex !== index ||
                      'check',
                    ].join(' ')}
                    onClick={() => this.editIndex(index)}
                  >
                    {value.title}
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        )}

        <div>

          {this.props.content[activeTabIndex].content.slice().reverse().map(
            (value, index) => {
              return this.state.maxItem > index ? (
                <div className="post" key={Math.random() + index}>
                  {value.title !== undefined ? (
                    <h3 className="post__label">
                      {value.title}
                    </h3>
                  ) : null}

                  <PreviewProfileTopics
                    topics={value.speaker_topic ? value.speaker_topic : []}
                  />
                  {value.description ? (
                    <PostText
                      description={value.description}
                    />
                  ) : value.text ? (
                    <PostText
                      description={value.text}
                    />
                  ) : value.render ? (
                    value.render
                  ) : null}
                </div>
              ) : null;
            }
          )}
        </div>

        {this.props.content[activeTabIndex].content.length >
          this.state.maxItem ? (
          <div
            className="post__btn post__btn--load"
            onClick={() => this.editLoadMore()}
          >
            {`
                        view
                        ${this.props.content[activeTabIndex].content.length - this.state.maxItem} 
                        more 
                        ${this.props.content[activeTabIndex].title.substring(
              0,
              this.props.content[activeTabIndex].title.indexOf(' (')
            )
              }
                        `}
          </div>
        ) : this.props.content[activeTabIndex].content.length ===
          0 ? (
          <div className="clear-span">No information yet</div>
        ) : null}
      </div>
    );
  };
};