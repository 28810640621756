import React from 'react';

export const sliderData = [
    {
        title: <span>Maximise exposure <br /> with event organizers</span>,
        text: <span>Get marketed to audiences <br /> who want hear your story</span>,
        id: 0,
        type: '',
    },
    {
        title: <span>Showcase a <br /> market-leading profile</span>,
        text: <span>Build a bespoke marketing <br /> webpage, promote your full <br /> service offerring</span>,
        id: 1,
        type: '',
    },
    {
        title: <span>100% success<br />driven model</span>,
        text: <span>No upfront fees. We only <br /> get paid if you get paid</span>,
        id: 2,
        type: "wide",
    },
    {
        title: <span>A stage for everyone</span>,
        text: <span>Leading voices,<br />rising stars,<br />up and coming</span>,
        id: 3,
        type: '',
    },
    {
        title: <span>Get more than just <br /> introductions</span>,
        text: <span>We're a full service <br /> marketing and booking <br /> platform</span>,
        id: 4,
        type: '',
    },
    {
        title: <span>Maximise exposure <br /> with event organizers</span>,
        text: <span>Get marketed to audiences <br /> who want hear your story</span>,
        id: 5,
        type: '',
    },
    {
        title: <span>Showcase a <br /> market-leading profile</span>,
        text: <span>Build a bespoke marketing <br /> webpage, promote your full <br /> service offerring</span>,
        id: 6,
        type: '',
    },
    {
        title: <span>100% success<br />driven model</span>,
        text: <span>No upfront fees. We only <br /> get paid if you get paid</span>,
        id: 7,
        type: "wide",
    },
    {
        title: <span>A stage for everyone</span>,
        text: <span>Leading voices,<br />rising stars,<br />up and coming</span>,
        id: 8,
        type: '',
    },
    {
        title: <span>Get more than just <br /> introductions</span>,
        text: <span>We're a full service <br /> marketing and booking <br /> platform</span>,
        id: 9,
        type: '',
    },
]