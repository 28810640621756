export const nav = [
    {
        id: 1,
        title: 'Dashboard',
        link: '/admin-panel/dashboard',
    },
    {
        id: 2,
        title: 'Speakers',
        link: '/admin-panel/speakers',
    },
    {
        id: 3,
        title: 'Businesses',
        link: '/admin-panel/businesses',
    },
    {
        id: 4,
        title: 'Billing',
        link: '/admin-panel/billing',
    },
]