import React from 'react';

function Success() {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 300,
            }}
        >
            <h1>Success</h1>
        </div>
    );
}

export default Success;
