import React from 'react';
import styled from "styled-components";


export const AdminPanelBilling = () => {

    return(
        <LoginStyles>
            <h1>Billing</h1>
        </LoginStyles>
    )
}

const LoginStyles = styled.div`
`