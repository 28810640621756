import React, { useState, useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';

import store from './store';
import Navigator from './Navigator';

import 'react-notifications/lib/notifications.css';

import './styles/bootstrap-custom.scss';
import './styles/App.scss';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

function App() {
    const [load, setLoad] = useState(false);

    useEffect(() => {
        setLoad(true)
    }, []);

    return !load ? null : <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <NotificationContainer />
            <Navigator />
        </MuiPickersUtilsProvider>
    </Provider>;
}

export default App;
