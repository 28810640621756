import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import HeaderDashboard from '../Header/HeaderDashboard';
import Footer from '../Footer';
import s from './PreviewProfileLayout.module.scss';

export default function PreviewProfileLayout(props) {
  const user = useSelector(state => state.user);

  if (user.role !== 'speaker') {
    return <Redirect to={'/'} />;
  }

  return (
    <>
      <HeaderDashboard name={user.firstName} theme={'dark'} />
      <div className="content">{props.children}</div>
      <div className={s.footerWrapper}>
        <Footer
          user={user}
        />
      </div>
    </>
  );
}
