import React, { useRef, useState } from "react";
import styled from "styled-components";

export const FAQQuestion = ({ question, answer, marginBottom = 0 }) => {

  const [isShowed, setIsShowed] = useState(false);
  const [textHeight, setTextHeight] = useState(0);

  const textRef = useRef(null);
  const handleClick = () => {
    setIsShowed(!isShowed);
    setTextHeight(textRef.current.clientHeight)
  }
  return (
    <FQAItemStyle height={textHeight} marginBottom={marginBottom}>
      <button
        typeof="button"
        className="tab__button"
        onClick={handleClick}
      >
        {question}
        <span className="plus">{isShowed ? '-' : '+'}</span>
      </button>

      <div className={`tab__text ${isShowed ? 'show' : 'hide'}`}>
        <div ref={textRef}> {answer}</div>
      </div>
    </FQAItemStyle>
  )
}

export const FQAItemStyle = styled.li`
    border-bottom: 1px solid #DCDFE0;
    
    &:last-of-type {
      border: none;
      
      & > button {
        padding-bottom: 0;
      }
    }
    
    .plus {
      color: #20D5C4;
      font-weight: 300; 
      font-size: 40px;
      
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 1;
        margin-left: 24px;
      }
    }
    
    .tab__button {
        display: flex;
        justify-content:space-between;
        align-items: center;
        text-align: left;
        padding: 34px 0 30px 0;
        width: 100%;
        background: transparent;
        border: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #293749;
        cursor: pointer;
        
        &:focus, 
        &:active {
          outline: none;
        }
        
        @media (max-width: 1280px) {
            font-size: 18px;
            line-height: 28px;
            padding: 34px 0;
        }
        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 22px;
            padding: 20px 0;
            align-items: flex-start;
        }

    }

    .tab__text {
        margin: 0;
        max-width: 950px;
        font-size: 16px;
        line-height: 28px;
        transition: height 0.3s ease-in-out;
        overflow: hidden;
        color: #212523;
        
        &.show {
           height: ${props => props.height + 50}px;
           margin-bottom: ${props => props.marginBottom || 0};
        }
        
        &.hide {
          height: 0;
        }
        
        & > span {
          display: block;
        }

        & div > p > strong {
          display: block;
          margin-top: 40px;
        }
        
        @media (max-width: 1280px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
`;