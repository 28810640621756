import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import BookingFormContainer from './BookingFormContainer';

const BookingForm = () => {
  const { bookingId, enquiryId } = useParams();
  const user = useSelector(store => store.user);
  const userRole = user.role;

  if (enquiryId && userRole === 'speaker') {
    return <BookingFormContainer enquiryId={enquiryId} userRole={userRole} />;

  } else if (bookingId && (userRole === 'speaker' || userRole === 'business')) {
    return <BookingFormContainer bookingId={bookingId} userRole={userRole} />;
  }

  return <Redirect to={'/'} />;
};

export default BookingForm;
