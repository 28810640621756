import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ApiDataProvider from '../../api/apiClient';
import EnquirySummary from './EnquirySummary';


export default () => {
  const user = useSelector(store => store.user);
  const userRole = user.role;

  const { enquiryId } = useParams();
  const { speakerId } = useParams();

  let fetcher;
  let accept;
  let decline;
  let cancel;
  let reactivate;

  if (userRole === 'speaker') {
    fetcher = () => ApiDataProvider.getEnquiryByIdSpeaker(enquiryId);
    accept = () => ApiDataProvider.acceptEnquirySpeaker(enquiryId);
    reactivate = () => ApiDataProvider.reactivateEnquirySpeaker(enquiryId);
    decline =() => ApiDataProvider.declineEnquirySpeaker(enquiryId);
    cancel =() => ApiDataProvider.cancelEnquirySpeaker(enquiryId);
  } else if (userRole === 'business') {
    fetcher = () => ApiDataProvider.getEnquiryByIdBusiness(enquiryId);
    cancel =() => ApiDataProvider.cancelEnquiryBusiness(enquiryId, speakerId);
    reactivate = () => ApiDataProvider.reactivateEnquirySpeaker(enquiryId);
  } else {
    return;
  }

  return (
    <EnquirySummary
      id={enquiryId}
      role={userRole}
      fetcher={fetcher}
      accept={accept}
      decline={decline}
      cancel={cancel}
      reactivate={reactivate}
      speakerId={speakerId}
    />
  );
};
