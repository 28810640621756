import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import cn from 'classnames';
import ApiDataProvider from '../../api/apiClient';

import s from './styles.module.scss';

const Field = ({ register, fieldName, label, type, error, required, minLength, maxLength }) => {
  return (
    <div className={s.field}>
      <label className={s.fieldLabel}>{label}</label>
      <div className={s.inputWrapper}>
        {error && (
          <p className={s.fieldError}>{error.message}</p>
        )}
        {type === 'textarea' ? (
          <textarea
            className={cn(s.fieldInput, { [s.error]: error })}
            {...register(fieldName, {
              required: required && 'Required',
              minLength: minLength && {
                value: minLength,
                message: `Minimum length is ${minLength}`
              },
              maxLength: maxLength && {
                value: maxLength,
                message: `Maximum length is ${maxLength}`
              }
            })}
          />
        ) : (
          <input
          className={cn(s.fieldInput, { [s.error]: error })}
          type={type}
          {...register(fieldName, {
            required: required && 'Required',
            minLength: minLength && {
              value: minLength,
              message: `Minimum length is ${minLength}`
            },
            maxLength: maxLength && {
              value: maxLength,
              message: `Maximum length is ${maxLength}`
            }
          })}
        />
        )}
      </div>
    </div>
  );
};

const ContactForm = ({ heading }) => {
  const { register, handleSubmit, formState: { errors }, setError, reset } = useForm();
  const [isSent, setSent] = useState(false);
  const history = useHistory();
  
  const onSubmit = data => {
    if (isSent) {
      return;
    }

    ApiDataProvider.sendContactUsRequest(data).then(() => {
      setSent(true);
      reset();
      
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'contact-us-form-sent' });
      }
  
      history.push('/thankyou');
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 422) {
          const errors = error.response.data.errors;
          for (let fieldName in errors) {
            setError(fieldName, { type: 'custom', message: errors[fieldName] });
          }
        }
      }
    });
  };

  return (
    <form className={s.container} onSubmit={handleSubmit(onSubmit)}>
      <h3 className={s.heading}>{heading}</h3>

      <p className={s.subheading}>
        Send an enquiry & we will be in touch with a speaker shortlist tailored for your event.
      </p>

      <Field
        register={register}
        fieldName="name"
        label="Full name"
        type="text"
        error={errors.name}
        required
        minLength={3}
        maxLength={50}
      />

      <Field
        register={register}
        fieldName="email"
        label="Work email"
        type="email"
        error={errors.email}
        required
        minLength={3}
        maxLength={50}
      />

      <Field
        register={register}
        fieldName="phone"
        label="Phone number"
        type="number"
        error={errors.phone}
        required
        maxLength={20}
      />

      <Field
        register={register}
        fieldName="message"
        label="What's the brief?"
        type="textarea"
        error={errors.message}
        required
        maxLength={1000}
      />

      <div className={s.footer}>
        <input type="submit" value={isSent ? 'sent' : 'send request'} className={s.submitButton} />
      </div>
    </form>
  )
};

export default ContactForm;
