import React, { useEffect, useState } from 'react';
import { LineContainer } from './GreenLine';
import { useHistory } from 'react-router'


export const GreenLineOnHover = ({
    path,
    target,
    bgImg,
    moveBottom,
    lineHeight,
    manualVisibility, // boolean
    whiteSpace = 'wrap'
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const history = useHistory();

    useEffect(() => {
        manualVisibility ? setTimeout(() => setIsVisible(manualVisibility), 500) : setIsVisible(false);
    }, [manualVisibility])

    const setVisibleElement = () => {
        setIsVisible(true);
    }

    const removeVisibleElement = () => {
        setIsVisible(false);
    }

    return (
        <LineContainer
            isVisible
            img={bgImg}
            position={moveBottom || '6px'}
            height={lineHeight}
        >
            <span
                onMouseOver={setVisibleElement}
                onMouseLeave={removeVisibleElement}
                className="green-line__text"
                style={{whiteSpace: whiteSpace}}
            >{target}</span>

            <span className={isVisible ? 'green-line visible' : 'green-line'} />
        </LineContainer>
    );
};
