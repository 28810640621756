import React from 'react';
import './style.scss';

class PostText extends React.Component {
    constructor(props) {
        super(props);
        this.refContent = React.createRef();

        const description = props.description || '';

        this.state = {
            description:
              description.length > 250
                    ? description.slice(0, 250) + '...'
                    : description,
            split: description.length > 250,
        };
    };

    allContent() {
        this.setState({ description: this.props.description, split: false });
    };

    componentDidUpdate() {
        const desc = this.state.description;
        this.refContent.current.innerHTML = Array.isArray(desc)
            ? desc.map(item => `<div class="post__m10">${this.replacer(item)}</div>`).join('')
            : this.replacer(desc)
    };

    componentDidMount() {
        const desc = this.state.description;
        this.refContent.current.innerHTML = Array.isArray(desc)
            ? desc.map(item => `<div class="post__m10">${this.replacer(item)}</div>`).join('')
            : this.replacer(desc)
    };

    replacer(data) {
        return data.replace(/\n/ig, '</br>')
    };

    render() {
        return (
            <>
                <div
                    className="post__text"
                    style={
                        this.props.achievements
                            ? { color: '#000', fontWeight: 'normal', fontSize: '14px', lineHeight: '28px' }
                            : null
                    }
                    ref={this.refContent}
                />
                {this.state.split ? (
                    <div
                        className="post__btn"
                        onClick={() => this.allContent()}
                    >
                        Read More
                    </div>
                ) : null}
            </>
        );
    };
};

export default class PreviewProfileContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            maxItem: 2,
        };
    }

    editIndex(id) {
        this.setState({ active: id });
    };

    editLoadMore() {
        let add = this.props.lazyload || Infinity;
        this.setState({ maxItem: this.state.maxItem + add });
    };

    replacer(data) {
        return (data.map(elem => {
            return { ...elem, text: elem.text.replace(/\n/ig, '</br>') }
        }))
    };

    render() {
        return (
            <div className="preview-profile-content">
                {!this.props.nav || (
                    <ul className="post__list">
                        {
                            this.props.content.map(({ title }, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={[
                                            'post__list-elem',
                                            this.state.active !== index ||
                                            'check',
                                        ].join(' ')}
                                        onClick={() => this.editIndex(index)}
                                    >
                                        {title}
                                    </li>
                                );
                            })}
                    </ul>
                )}

                {
                    this.props.content[this.state.active].content

                        ? (
                            <>
                                {this.props.content[this.state.active].content.slice().reverse().map(
                                    (value, index) => {
                                        return this.state.maxItem > index ? (
                                            <div className="post" key={Math.random() + index}>
                                                {value.title !== undefined ? (
                                                    <h3 className="post__label">
                                                        {value.title}
                                                    </h3>
                                                ) : null}
                                                {value.date !== undefined ? (
                                                    <p className="post__data">{value.date}</p>
                                                ) : null}

                                                {value.description ? (
                                                    <PostText
                                                        description={
                                                            value.description
                                                        }
                                                    />
                                                ) : value.text ? (
                                                    value.achievements
                                                        ? <PostText
                                                            achievements={value.achievements}
                                                            description={
                                                                value.text
                                                            }
                                                        />
                                                        : <PostText
                                                            description={
                                                                value.text
                                                            }
                                                        />
                                                ) : null}
                                                { value.render ? (
                                                    value.render
                                                ) : null}
                                                {
                                                    value.img && (
                                                        <div className={'ac__certificat-img'}>
                                                            <img src={value.img} alt={`certificate-${index}`} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : null;
                                    }
                                )}

                                {this.props.content[this.state.active].content.length >
                                    this.state.maxItem ? (
                                    <div
                                        className="post__btn post__btn--load"
                                        onClick={() => this.editLoadMore()}
                                    >
                                        {`
                        view 
                        ${this.props.content[this.state.active].content.length - this.state.maxItem} 
                        more 
                        ${this.props.content[this.state.active].title.substring(
                                            0,
                                            this.props.content[this.state.active].title.indexOf(' (')
                                        )
                                            }
                        `}
                                    </div>
                                ) : this.props.content[this.state.active].content.length ===
                                    0 ? (
                                    <div className="clear-span">No information yet</div>
                                ) : null}
                            </>
                        ) : null
                }

            </div>
        );
    }
}