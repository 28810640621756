import styled from "styled-components";

export const SliderCardStyled = styled.div`
    color: #19222D;
    text-align: center;
    letter-spacing: -0.02em;

    .green-fill-container {
        position: relative;
    }

    .slide-card-green-fill {
        position: absolute;
        z-index: -1;
        width: 0px;

        &__active {
            width: 90%;
            height: 130%;
            top: -15%;
            left: 5%;
            transition: width 0.5s ease-in-out 1.5s;

            @media(max-width: 600px) {
                width: 77%;
                left: 13%;
                transition: width 0.5s ease-in-out 1.5s;
            }
        }
    }

    .slide-card-green-fill-title {
        position: absolute;
        z-index: -1;
        width: 0px;

        &__active {
            width: ${props => props.type === "wide" ? '210px' : '90%'};
            height: 130%;
            top: -15%;
            left: 5%;
            transition: width 0.5s ease-in-out 1.5s;
            left: ${props => props.type === "wide" ? '50px' : ''};

            @media(max-width: 600px) {
                width: ${props => props.type === "wide" ? '155px' : '75%'};
                left: 13%;
                transition: width 0.5s ease-in-out 1.5s;
                left: ${props => props.type === "wide" ? '80px' : ''};
            }
        }
    }

    .slide-card__title {
        text-transform: uppercase;
        font-style: normal;
        font-weight: 700;

        span {
            white-space: nowrap;
        }
    }

    .slide-card__desc {
        span {
            white-space: nowrap;
        }
    }

    .first-slide {
        white-space: no-wrap;

        .slide-card__title {
            font-style: normal;
            font-weight: 700;
            font-size: 21px;
            line-height: 28px;
            text-transform: uppercase;
            animation-name: firstTitleAnimation;
            animation-duration: 1.5s;
            margin-bottom: 10px;

            @media(max-width: 600px) {
                font-size: 17px;
                line-height: 24px;
                animation-name: firstTitleAnimationMobile;
                animation-duration: 1.5s;
            }

            span {
                white-space: nowrap;
            }
        }

        .slide-card__desc {
            font-size: 21px;
            line-height: 28px;
            font-weight: 300;
            animation-name: firstTextAnimation;
            animation-duration: 1.5s;

            @media(max-width: 600px) {
                font-size: 17px;
                line-height: 24px;
                animation-name: firstTextAnimationMobile;
                animation-duration: 1.5s;
            }

            span {
                white-space: nowrap;
            }
        }
    }

    @keyframes firstTitleAnimation {
        0% {
            font-size: 14px;
            line-height: 18px;
        }

        100% {
            font-size: 21px;
            line-height: 28px;
        }
    }

    @keyframes firstTextAnimation {
        0% {
            font-size: 14px;
            line-height: 18px;
        }

        100% {
            font-size: 21px;
            line-height: 28px;
        }
    }

    @keyframes firstTitleAnimationMobile {
        0% {
            font-size: 11px;
            line-height: 14px;
            opacity: 0;
        }

        100% {
            font-size: 17px;
            line-height: 24px;
            opacity: 1;
        }
    }

    @keyframes firstTextAnimationMobile {
        0% {
            font-size: 11px;
            line-height: 14px;
            opacity: 0;
        }

        100% {
            font-size: 17px;
            line-height: 24px;
            opacity: 1;
        }
    }

    .second-slide {
        letter-spacing: -0.02em;

        .slide-card-left .slide-card__text {
            font-size: 14px;
            line-height: 18px;
            animation-name: secondSlideLeftText;
            animation-duration: 1.5s;
            margin-bottom: 5px;

            @media(max-width: 600px) {
                font-size: 11px;
                line-height: 14px;
                opacity: 0;
                animation: secondSlideLeftTextMobile;
                animation-duration: 1.5s;
            }
        }

        .slide-card-right .slide-card__text {
            font-size: 14px;
            line-height: 18px;
            animation-name: secondSlideRightText;
            animation-duration: 1.5s;
            margin-bottom: 5px;
        }
    }

    @keyframes secondSlideLeftText {
        0% {
            font-size: 21px;
            line-height: 28px;
        }

        100% {
            font-size: 14px;
            line-height: 18px;
        }
    }

    @keyframes secondSlideLeftTextMobile {
        0% {
            font-size: 17px;
            line-height: 24px;
            opacity: 1;
        }

        100% {
            font-size: 11px;
            line-height: 14px;
            opacity: 0;
        }
    }

    @keyframes secondSlideRightText {
        0% {
            font-size: 11px;
            line-height: 14px;
        }

        100% {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .third-slide {
        @media(max-width: 600px) {
            opacity: 0;
        }

        .slide-card-left .slide-card__text {
            font-size: 11px;
            line-height: 14px;
            animation-name: thirdSlideLeftText;
            animation-duration: 1.5s;
            margin-bottom: 5px;
        }

        .slide-card-right .slide-card__text {
            font-size: 11px;
            line-height: 14px;
            animation-name: thirdSlideRightText;
            animation-duration: 1.5s;
            margin-bottom: 5px;
        }
    }

    @keyframes thirdSlideLeftText {
        0% {
            font-size: 14px;
            line-height: 18px;
        }

        100% {
            font-size: 11px;
            line-height: 14px;
        }
    }

    @keyframes thirdSlideRightText {
        0% {
            font-size: 11px;
            line-height: 14px;
        }

        100% {
            font-size: 11px;
            line-height: 14px;
        }
    }

    .fourth-slide {
        .slide-card-left .slide-card__text {
            font-size: 11px;
            line-height: 14px;
        }

        .slide-card-left .slide-card__text {
            font-size: 11px;
            line-height: 14px;
        }
    }
`