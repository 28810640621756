import {AdminPanelLayout} from './AdminPanelLayout';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleLoginModal } from '../../store/actions';

const mapStateToProps = state => {
    return {
        showModal: state.modal.isLoginModalOpen,
        user: state.user.token && state.user.token.user,
        isVerified: state.user.isVerified,
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        { toggleLoginModal }
    )(AdminPanelLayout)
);
