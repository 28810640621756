import React, { useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';

export default () => {
  const videoWrapperRef = useRef(null);
  const playerRef = useRef(null);

  const [videoPlaying, setVideoPlaying] = useState(false);

  useEffect(() => {
    const options = {
      url: 'https://player.vimeo.com/video/644164056?h=7f18ca6ece',
      responsive: true,
      title: false,
      color: '16d4c2'
    };

    playerRef.current = new Player(videoWrapperRef.current, options);

    // workaround for chrome bug with page scrolling to top when video full screen mode is toggled
    let fullScreenHandler;
    if (navigator.userAgent.includes('Chrome')) {
      fullScreenHandler = () => {
        const rekt = videoWrapperRef.current.getBoundingClientRect();
        const offset = window.scrollY + rekt.top;
        window.scrollTo({
          top: offset - (window.innerHeight - rekt.height) / 2
        });
      };
      videoWrapperRef.current.addEventListener('fullscreenchange', fullScreenHandler);
    }

    // add handler to scroll
    const scrollHandler = () => {
      const rekt = videoWrapperRef.current.getBoundingClientRect();
      if (rekt.top > 0 && rekt.bottom < window.innerHeight) {
        setVideoPlaying(true);
      } else {
        setVideoPlaying(false);
      }
    };
    window.addEventListener('scroll', scrollHandler);

      return () => {
        window.removeEventListener('scroll', scrollHandler);

        if (fullScreenHandler) {
          window.removeEventListener('fullscreenchange', fullScreenHandler);
        }
      };
  }, []);

  useEffect(() => {
    if (videoPlaying) {
      playerRef.current.play().catch(e => console.error(e));
    } else {
      playerRef.current.pause().catch(e => console.error(e));
    }
  }, [videoPlaying]);

  return (
    <div ref={videoWrapperRef} />
  );
};
