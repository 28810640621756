import React from 'react';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>The benefits of Booking a Sports Speaker</h2>

      <p className={s.paragraph}>
        Welcome to <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a>, your
        one-stop destination for booking professional sports speakers. Whether you're organizing
        a corporate conference, a team-building retreat, or a motivational seminar, our curated
        selection of sports speakers offers a winning combination of athletic expertise and
        motivational prowess. 
      </p>

      <p className={s.paragraph}>
        Elevate your event with the inspiring stories and experiences of sports icons,
        celebrity figures, Olympic champions, and sports presenters who can entertain,
        educate, and inspire your audience with their captivating stories of triumph
        and resilience.
      </p>

      <h3 className={s.greenHeadingList}>Expertise and Insights</h3>
      <p className={s.paragraph}>
        Sports speakers are typically individuals who have excelled in their respective sports
        or have deep knowledge and experience in the sports industry. By booking a sports speaker,
        you can provide your audience with access to firsthand expertise and valuable insights.
        They can share their strategies, techniques, and experiences, providing unique
        perspectives that can be applied to various aspects of life, such as leadership,
        teamwork, discipline, and goal-setting.
      </p>

      <h3 className={s.greenHeadingList}>Motivation and Inspiration</h3>
      <p className={s.paragraph}>
        <a className={s.link} href="/about" target="_blank">Sports speakers</a> often
        have compelling stories of triumph, resilience, and overcoming challenges. Their
        experiences and achievements can serve as a powerful source of motivation and
        inspiration for your audience. By sharing personal stories and discussing their
        journey to success, sports speakers can inspire individuals to push their limits,
        believe in themselves, and strive for greatness in their own lives.
      </p>

      <h3 className={s.greenHeadingList}>Engaging and Memorable Presentations</h3>
      <p className={s.paragraph}>
        Sports speakers are accustomed to performing in front of large crowds and engaging
        audiences. They possess excellent communication skills, charisma, and stage presence,
        allowing them to deliver captivating and memorable presentations. Their ability to
        connect with the audience on an emotional level and share impactful stories can
        leave a lasting impression and make the event more enjoyable and rewarding for attendees.
      </p>

      <h3 className={s.greenHeadingList}>Enhancing the Event Experience</h3>
      <p className={s.paragraph}>
        <a className={s.link} href="/about" target="_blank">Book</a> professional
        sports speaker which adds a unique element to your event, making it more dynamic
        and diverse. It breaks the monotony and provides a fresh perspective that can
        complement other activities or presentations during the event. The presence
        of a sports speaker can generate excitement and anticipation among attendees,
        creating a buzz around the event.
      </p>

      <h3 className={s.greenHeadingList}>Brand Association and Publicity</h3>
      <p className={s.paragraph}>
        By associating your event with a well-known and respected sports speaker,
        you can enhance the brand image and credibility of your event. Sports speakers
        often have a loyal fan base and followers who may be interested in attending
        an event where their favorite sports personality is speaking. This can increase
        the visibility and attendance of your event and attract media coverage, leading
        to additional publicity and exposure.
      </p>

      <h3 className={s.greenHeadingList}>Networking and Relationship Building</h3>
      <p className={s.paragraph}>
        Sports speakers often have extensive networks within the sports industry. By
        booking a sports speaker, you provide attendees with an opportunity to network
        and connect with influential individuals, such as athletes, coaches, sports
        executives, and enthusiasts. This can open doors for potential collaborations,
        partnerships, and business opportunities.
      </p>

      <p className={s.paragraph}>
        At <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a>, we
        believe that the power of sports extends far beyond the playing field. Our platform offers
        access to some of the most sought-after sports presenters in the country connecting you with
        professional sports speakers who can bring the spirit of athleticism, resilience, and
        teamwork to your event. From motivational speeches to panel discussions and Q&A sessions,
        our carefully curated roster offers a diverse range of speakers to suit your event's
        objectives and audience demographics.
      </p>

      <p className={s.paragraph}>
        Get started now and make your event a winning success
        with <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a>. Book
        a professional sports speaker today and witness the transformative impact they can
        have on your attendees, leaving them motivated, inspired, and ready to achieve greatness.
      </p>
    </>
  );
};

const TopicPageSports = () => {
  return (
    <TopicPageBase
      topicAlias="sports"
      headerSubtitle={<>
        Book sports speakers for your next event.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a sports speaker?"
      trendingSpeakersHeading="Trending Sports Speakers"
      why={<Why />}
      speakerTopicText="sports"
    ></TopicPageBase>
  )
};

export default TopicPageSports;
