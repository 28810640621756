export const ActionTypes = {
    SET_ENQUIRY_FIELD: 'SET_ENQUIRY_FIELD',
    SET_ENQUIRY_DATES: 'SET_ENQUIRY_DATES',
    SET_ENQUIRY_BUDGET: 'SET_ENQUIRY_BUDGET',
    CLEAR_ENQUIRY_STORE: 'CLEAR_ENQUIRY_STORE',
    DELETE_ENQUIRY_FILE: 'DELETE_ENQUIRY_FILE',
    SAVE_LOCAL_DATES_VALUES: 'SAVE_LOCAL_DATES_VALUES',
    RESET_ERRORS: 'RESET_ERRORS',
    SET_PORTAL_STATUS: 'SET_PORTAL_STATUS',

    SET_SPEAKER_LIST_INIT: 'SET_SPEAKER_LIST_INIT',
    SET_SPEAKER_LIST_SUCCESS: 'SET_SPEAKER_LIST_SUCCESS',
    SET_SPEAKER_LIST_ERROR: 'SET_SPEAKER_LIST_ERROR',

    GET_SEARCH_AUTOCOMPLETE_INIT: 'GET_SEARCH_AUTOCOMPLETE_INIT',
    GET_SEARCH_AUTOCOMPLETE_SUCCESS: 'GET_SEARCH_AUTOCOMPLETE_SUCCESS',
    GET_SEARCH_AUTOCOMPLETE_ERROR: 'GET_SEARCH_AUTOCOMPLETE_ERROR',

    GET_SHORT_LIST_INIT: 'GET_SHORT_LIST_INIT',
    GET_SHORT_LIST_SUCCESS: 'GET_SHORT_LIST_SUCCESS',
    GET_SHORT_LIST_ERROR: 'GET_SHORT_LIST_ERROR',

    ADD_SHORT_LIST_INIT: 'ADD_SHORT_LIST_INIT',
    ADD_SHORT_LIST_SUCCESS: 'ADD_SHORT_LIST_SUCCESS',
    ADD_SHORT_LIST_ERROR: 'ADD_SHORT_LIST_ERROR',

    REMOVE_SHORT_LIST_INIT: 'REMOVE_SHORT_LIST_INIT',
    REMOVE_SHORT_LIST_SUCCESS: 'REMOVE_SHORT_LIST_SUCCESS',
    REMOVE_SHORT_LIST_ERROR: 'REMOVE_SHORT_LIST_ERROR',

    SET_SPEAKER_LIST_ADMIN_INIT: 'SET_SPEAKER_LIST_ADMIN_INIT',
    SET_SPEAKER_LIST_ADMIN_SUCCESS: 'SET_SPEAKER_LIST_ADMIN_SUCCESS',
    SET_SPEAKER_LIST_ADMIN_ERROR: 'SET_SPEAKER_LIST_ADMIN_ERROR',

    GET_SPEAKER_INIT: 'GET_SPEAKER_INIT',
    GET_SPEAKER_SUCCESS: 'GET_SPEAKER_SUCCESS',
    GET_SPEAKER_ERROR: 'GET_SPEAKER_ERROR',
};