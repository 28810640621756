import React from 'react';
import Switch from '@material-ui/core/Switch';
import styles from './styles.module.scss';

export default props => {
  return (
    <span className={styles.container}>
      <Switch
        classes={{
          root: styles.root,
          track: styles.track,
          thumb: styles.thumb,
          switchBase: styles.switchBase,
          checked: styles.checked
        }}
        {...props}
      />
    </span>
    );
};
