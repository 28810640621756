import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { TOPICS } from '../../constants';
import './Sidebar.scss';
import { GreenLine } from "../../HomePage/GreenLine";
import ExpandableList from './ExpandableList';

const sidebarData = [
    { id: 2, title: "Our story", link: '/about' },
    { id: 2.5, type: 'dropdown', dropdownItems: TOPICS, title: 'Trending Topics', link: '/' },
    { id: 3, title: "Browse Speakers", link: '/find-speakers' },
    // { id: 5, title: "How It works", link: '/how-it-works' },
    // { id: 4, title: "I'm a Speaker", link: '/speaker-page' },
    { id: 6, title: "Latest News", link: '/blog/', otherPage: true },
    { id: 7, title: "FAQs", link: '/faq' }
];

const speakerSidebarItems = [
    {
        title: 'Preview profile',
        link: '/preview-profile'
    },
    {
        title: 'Edit profile',
        link: '/builder-step1'
    },
    {
        title: 'Dashboard',
        link: '/dashboard',
        isLocked: true
    },
    {
        title: 'Messages',
        link: '/chat',
        isLocked: true
    },
    {
        title: 'Account',
        link: '/account-details'
    },
    {
        title: 'Payments',
        link: '/payments-invoices',
        isLocked: true
    },
    {
        title: 'Latest News',
        link: '/blog/',
        otherPage: true
    }
];

const finderSidebarItems = [
    {
        title: 'Dashboard',
        link: '/dashboard',
        isLocked: true
    },
    {
        title: 'Find a Speaker',
        link: '/find-speakers'
    },
    {
        title: 'Messages',
        link: '/chat',
        isLocked: true
    },
    {
        title: 'Account',
        link: '/account-details'
    },
    {
        title: 'Payments',
        link: '/payments-invoices',
        isLocked: true
    },
    {
        title: 'Latest News',
        link: '/blog/',
        otherPage: true
    }
];


export const SidebarMenu = ({ menuItems, onClickLogin, onLogout, isVerified, setIsMenuOpen }) => {
    const role = useSelector(state => state.user.role);

    menuItems = menuItems || (isVerified ? (role === 'speaker' ? speakerSidebarItems: finderSidebarItems) : sidebarData);
    return (
        <>
            <ul>
                {
                    menuItems.map((item, index) => {
                        if (item.type === 'dropdown') {
                            return (
                                <ExpandableList
                                    ListItemComponent={({ children }) => (
                                        <li className="mobile-head__menu-item">{children}</li>
                                    )}
                                    menu={item.dropdownItems.map(([text, link]) => (
                                        <li
                                            className="mobile-head__menu-item"
                                            style={{ marginTop: '30px' }}
                                        >
                                            <a
                                                className="mobile-head__menu-link"
                                                href={link}
                                                target="_blank"
                                                style={{ fontSize: '18px', lineHeight: '20px', fontWeight: 400 }}
                                            >{text}
                                            </a>
                                        </li>
                                    ))}>
                                    <a
                                        className="mobile-head__menu-link"
                                        href={item.link}
                                        onClick={e => e.preventDefault()}
                                    >{item.title}
                                    </a>
                                </ExpandableList>
                            );
                        }

                        return (
                            <li
                            key={index}
                            className={classNames('mobile-head__menu-item', { 'locked': item.isLocked })}
                            onClick={() => setIsMenuOpen(false)}
                            >
                                {item.otherPage
                                    ? <a
                                        href={item.link}
                                        className="mobile-head__menu-link"
                                    >{item.title}</a>
                                    : <Link
                                        to={item.link}
                                        className="mobile-head__menu-link"
                                    >{item.title}</Link>
                                }
                            </li>
                        );
                    })
                }
            </ul>

            <nav className="mobile-head__btns">
                {
                    isVerified
                        ? <><button className="mobile-head__login" onClick={onLogout}>Logout</button></>
                        : <>
                            <button className="mobile-head__login" onClick={onClickLogin}>Login</button>
                          <Link
                            to={'/contact-us'}
                            onClick={() => setIsMenuOpen(false)}
                            className="mobile-head__btn mobile-head__btn--pink"
                          >MAKE AN ENQUIRY</Link>

                            {/*
                            <Link
                              to={'/get-started'}
                              onClick={() => setIsMenuOpen(false)}
                              className="mobile-head__btn mobile-head__btn--aqua"
                            >GET STARTED</Link>

                            */}
                        </>
                }
            </nav>
        </>
    )
};
