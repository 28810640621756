import React from 'react';
import styled from "styled-components";
import {Checkbox} from "antd";
import RSC from "react-scrollbars-custom";

export const FilterListInner = ({
  data,
  isScrolling,
  onChangeFilter,
  selectedOptions,
}) => {
  const handleChange = (e, optionValue) => {
    if (e.target.checked) {
      onChangeFilter([...selectedOptions, optionValue]);
    } else {
      onChangeFilter(selectedOptions.filter(el => el !== optionValue));
    }
  };

  return isScrolling
    ? (
      <FilterInnerStyles>
        {data.map(({ value, label }, i) => {
          return (
            <li key={i}
                className="item"
            >
              <Checkbox
                checked={selectedOptions.some(el => el === value)}
                onChange={e => handleChange(e, value)}
              >
                <span>{label}</span>
              </Checkbox>
            </li>
          )
        })}
      </FilterInnerStyles>
    )
    : (<FilterInnerStyles>
      {data.map(({value, label}) => {
        return (
          <li key={value} className="item">
            <Checkbox
              checked={selectedOptions.some(el => el === value)}
              onChange={e => handleChange(e, value)}
            >
              <span>{label}</span>
            </Checkbox>
          </li>
        )
      })}
    </FilterInnerStyles>)

}

export const RSCStyles = styled.div`
  .ScrollbarsCustom-TrackX {
    display: none !important;
  }

  .ScrollbarsCustom-TrackY {
    width: 4px !important;
    background: #EAEBED !important;
  }

  .ScrollbarsCustom-ThumbY {
    background: #797979 !important;
  }
`

export const FilterInnerStyles = styled.ul`
  max-height: 250px;
  overflow: hidden auto;
  background: #fff;
  border-radius: 5px;
  list-style-type: none;
  padding: 0;
  margin: -2px 0 0;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: #797979;
    border-radius: 3px;
  }

  .item {
    padding: 10px 10px 14px 15px;
    margin-top: -5px;
  }

  .ant-checkbox-wrapper {
    width: 100%;
    display: flex;
  }

  .ant-checkbox-inner {
    border-radius: 5px;
    width: 20px;
    height: 20px;


    &:hover {
      border-color: #16d4c2;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-checked::after {
    border-color: #16d4c2;
    border-radius: 5px;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    left: 5px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #16d4c2;
    border-color: #16d4c2;
    border-radius: 5px;
    width: 20px;
    height: 20px;

    &:hover {
      border-color: #16d4c2;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  .ant-checkbox {
    margin-right: 14px;
    border-radius: 5px;
    width: 20px;
    height: 20px;

    &:hover {
      border-color: #16d4c2;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  .ant-checkbox + span {
    font-style: normal;
    font-weight: normal;
    padding: 0;
    font-size: 11px;
    line-height: 18px;
    color: #293749;

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 18px;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #16d4c2;
  }

  .add-aqua-btn {
    border: 3px solid #fff;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    color: #fff;
    line-height: 47px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    background: #20D5C4;

    &:focus {
      outline: none;
    }
  }

  .list {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: 35px;
    margin-bottom: 0;
  }

  .item {
    list-style-type: none;
    margin-right: 0;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #293749;
  }

  .avatar {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    border: 3px solid #fff;
  }

  .btn-enquire {
    border: none;
    background-color: transparent;
    padding-right: 22px;

    background-repeat: no-repeat;
    background-position: right 5px center;
    transition: background-position 0.3s ease-in-out;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #20D5C4;

    &:focus {
      outline: none;
    }

    &:hover {
      background-position: right 0 center;
    }
  }
`;