import React from 'react'
import styled from 'styled-components'
import img from '../assets/ComingSoon/search.png'

export default (props) => {

    return <SCSStyled>
        <div className="scs-content">
            <div className="scs-text">
                <h2 className="scs-text__label">COMING SOON</h2>
                <p className="scs-text__sublabel">Easily search top talent.<br /> fast</p>
                <p className="scs-text__desc">Unrestricted access to a star pool of diverse talent. Easy and intuitive search function, dynamic, interactive profiles, to ensure the best match.</p>
                <p className="scs-text__list-title">How it works</p>
                <ul className="scs-text__list">
                    <li className="scs-text__list-item">Broadest range of talent, seasoned pro’s and rising stars, any topic, type, budget, format - live, virtual or hybrid. </li>
                    <li className="scs-text__list-item">See speakers in action, dynamic speaker profiles that bring the speaker to life.</li>
                    <li className="scs-text__list-item">View full-service offerings, expertise, showreels, published materials, social following, client testimonials and much more. </li>
                </ul>
            </div>
            <div className="scs-image">
                <img src={img} className="scs-image__big"/>
            </div>
        </div>

        {/* <div className="scs-footer">
            All Rights Reserved     © Finders Speakers 2020
        </div> */}
    </SCSStyled>
}

const SCSStyled = styled.div`
    background: #19212B;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 160px;
    padding-bottom: 100px;

    @media (max-width: 1024px) {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    .scs {

        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-image {
            position: relative;
            margin: 0 15px;

            &__big {
                width: 100%;
                max-width: 595px;
            }
        }

        &-text {
            max-width: 400px;
            margin: 0 15px;

            &__label {
                color: #ffffff;
                font-size: 28px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__sublabel {
                color: #20D5C4;
                font-size: 28px;
                line-height: 32px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__desc {
                color: #ffffff;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 30px;
            }

            &__list {
                list-style: none;
                padding-left: 20px;

                &-title {
                    color: #20D5C4;
                    font-size: 15px;
                    font-weight: 300;
                    margin-bottom: 10px;
                }

                &-item {
                    color: #ffffff;
                    margin-bottom: 10px; 

                    &:before {
                        content: "\\2022";
                        color: #20D5C4;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        &-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: 300;
            color: #ffffff;
            text-align: center;
        }
    }
`