const CHAT_LOAD_START = 'CHAT_LOAD_START';
const CHAT_LOAD_SUCCESS = 'CHAT_LOAD_SUCCESS';
const CHAT_LOAD_FAIL = 'CHAT_LOAD_FAIL';
const CHAT_EMPTY = 'CHAT_EMPTY';
const CHAT_SET_UNREAD_COUNT = 'CHAT_SET_UNREAD_COUNT';

const data = {
  status: null,
  totalUnreadCount: 0
};

const chatReducer = (state = data, action) => {
  switch (action.type) {
    case CHAT_LOAD_START:
      return {
        ...state,
        status: 'LOADING'
      }

    case CHAT_LOAD_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS'
      }

    case CHAT_LOAD_FAIL:
      return {
        ...state,
        status: 'FAIL'
      };

    case CHAT_EMPTY:
      return {
        ...state,
        status: 'EMPTY'
      };

    case CHAT_SET_UNREAD_COUNT:
      return {
        ...state,
        totalUnreadCount: action.payload
      };

    default:
      return state;
  }
};

export default chatReducer;
