import React from 'react';
import styles from './styles.module.scss';

const NotFound = () => {
  return (
    <div className={styles.container}>
      Not Found
    </div>
  );
};

export default NotFound;
