import React, { useState } from 'react';
import classNames from 'classnames';
import BaseModal from '../../../_components/BaseModal';
import styles from './styles.module.scss';

const ConfirmationModal = ({ isOpen, text, errorMessage, onCancelClick, onConfirmClick }) => {
  const [loading, setLoading] = useState(false);

  const onPressConfirm = () => {
    const result = onConfirmClick();
    if (result instanceof Promise) {
      setLoading(true);
      result.finally(() => {
        setLoading(false);
      });
    }
  };
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onCancelClick}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <p className={styles.title}>{text}</p>
        </div>
        <div className={styles.modalBody}>
          <p className={styles.errorMessage}>{!loading && errorMessage}</p>
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.cancelButton} onClick={onCancelClick}>Cancel</button>
          <button
            className={classNames(styles.confirmButton, { [styles.confirmButtonLoading]: loading })}
            onClick={onPressConfirm}
          >OK</button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmationModal;
