import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

const portalRoot = document.getElementById('portal');

const Modal = ({ onClose, children }) => {
  const overlayRef = useRef(null);

  const onOverlayClick = e => {
    if (e.target === overlayRef.current) {
      onClose();
    }
  };

  return (
    <div className={styles.modal} onClick={onOverlayClick} ref={overlayRef}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
};

const BaseModal = ({ isOpen, onClose, children }) => {
  return isOpen && ReactDOM.createPortal(
    <Modal onClose={onClose}>
      {children}
    </Modal>,
    portalRoot
  );
};

export default BaseModal;
