import React from 'react';
import slide3 from '../../assets/redesign/slide3.webp';
import slide4 from '../../assets/redesign/slide4.webp';
import slide5 from '../../assets/redesign/slide5.webp';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from "styled-components";
import chevron from '../../assets/icons/chevron_light.svg';
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { HomeTitle } from "./HomeTitle";
import { useMediaQuery } from 'react-responsive'

export const HomeSlider = ({ persons, bgColor, title }) => {
    const isMobile = useMediaQuery({ query: '(max-device-width: 600px)' })

    const sliderSettingsPeople = {
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 8000,
        dotsClass: 'slick-dots slick-thumb',
        arrows: true,
        dots: false,
        lazyLoad: true,
        initialSlide: 1,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '500px',
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    centerPadding: '300px',
                }
            },
            {
                breakpoint: 1360,
                settings: {
                    centerPadding: '240px',
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: '150px',
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 765,
                settings: {
                    centerMode: false,
                    centerPadding: 0,
                    arrows: false,
                    dots: true
                }
            }
        ],
    };

    const sliderContent = persons || [
        {
            id: 103,
            img: slide3,
            text: '“You can’t put toothpaste back in the tube! The new speakers economy is here to stay. Whether you want to start up, step up or scale up. Finders Speakers is the smart choice helping speaking talent successfully sell from all stages”',
            label: <>
                Rick Gillmore
                <br />
                Relevance-Maker Coach, Mentor and Speaker
            </>,
            className: ""
        },
        {
            id: 100,
            img: slide4,
            text: `“I'm so excited to have found this team - they not only understand the real demands of the new speaking and events industry, but they also genuinely care about my success as much as I do!”`,
            label: <>
                Kate Cox
                <br />
                Leading Australian Editor, Speaker and Commentator
            </>,
            className: "even"
        },
        {
            id: 102,
            img: slide5,
            text: `“Delivering high-end events, I want to deliver the best of the best and trust Finders Speakers and their stable of thoroughbred speakers to inspire, inform and truly “wow” my clients.”`,
            label: <>
                Fiona Parsons
                <br />
                Founder – I’m On It Events
            </>,
            className: ""
        },
        {
            id: 103,
            img: slide3,
            text: '“You can’t put toothpaste back in the tube! The new speakers economy is here to stay. Whether you want to start up, step up or scale up. Finders Speakers is the smart choice helping speaking talent successfully sell from all stages”',
            label: <>
                Rick Gillmore
                <br />
                Relevance-Maker Coach, Mentor and Speaker
            </>,
            className: "even"
        },
        {
            id: 100,
            img: slide4,
            text: `“I'm so excited to have found this team - they not only understand the real demands of the new speaking and events industry, but they also genuinely care about my success as much as I do!”`,
            label: <>
                Kate Cox
                <br />
                Leading Australian Editor, Speaker and Commentator
            </>,
            className: ""
        },
        {
            id: 102,
            img: slide5,
            text: `“Delivering high-end events, I want to deliver the best of the best and trust Finders Speakers and their stable of thoroughbred speakers to inspire, inform and truly “wow” my clients.”`,
            label: <>
                Fiona Parsons
                <br />
                Founder – I’m On It Events
            </>,
            className: "even"
        },
    ];

    return (
        <SpeakersSlider bgColor={bgColor}
            itemsLength={sliderContent.length}>
            <section className="slider">
                <div className="container-fluid">
                    {title && (
                        <HomeTitle
                        text={title}
                        maxWidth={{ desktop: '653px', mobile: '100%' }}
                        marginBottom={{ desktop: '54px', mobile: "14px" }}
                        parentName={'slider'}
                    />
                    )}

                    <div className="slider__list">
                        <Slider {...sliderSettingsPeople}>
                            {sliderContent.map((el, idx) => (
                                <div
                                    className={`slider__item slider__item-${el.className}`}
                                    key={el.id}
                                >
                                    <div className="slider__info">
                                        <p className="slider__text">
                                            {el.text}
                                        </p>
                                        <span
                                            className={`slider__label slider__label-${el.className}`}
                                            style={{ display: `${isMobile ? 'none' : ' block'}` }}
                                        >{el.label}</span>
                                    </div>
                                    <div
                                        className="slider__img"
                                        style={isMobile
                                            ? {
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                paddingRight: '20px',
                                                position: 'static',
                                                border: 'none',
                                                boxShadow: 'none',
                                                marginTop: '10px',
                                            }
                                            : {}
                                        }
                                    >
                                        <img
                                            src={el.img}
                                            loading="lazy"
                                            className="App-logo" alt="logo"
                                            style={isMobile
                                                ? {
                                                    boxShadow: '0 0 20px rgba(154,161,163,0.18)',
                                                    borderRadius: '5px',
                                                }
                                                : {}
                                            }
                                        />

                                        <span
                                            className={(idx % 2) ? 'slider__label' : 'slider__label slider__label-even'}
                                            style={{
                                                display: `${isMobile ? 'block' : ' none'}`,
                                                bottom: 'auto',
                                            }}
                                        >{el.label}</span>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        </SpeakersSlider >
    )
};

const SpeakersSlider = styled.div`
    .container-fluid {
        padding: 0;
    }

    .slider {
        margin-bottom: -1px;
            
        @media (max-width: 1024px) {
            padding-top: 0px;
        }
        
        &__list {
            box-shadow: 0 0 20px rgba(154, 161, 163, 0.18);
        }
        
        .subtitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            color: #19222D;
            margin-bottom: 43px;
            text-align: center;
            letter-spacing: -0.02em;

            @media (max-width: 600px) {
                font-size: 30px;
                line-height: 30px;
                margin-bottom: 22px;
            }
        }

            &__info {
                flex-basis: calc(100% - 210px);
                justify-content: space-between;
            }

            &__item {
                height: 379px;
                padding: 91px 68px 97px 83px;
                width: 810px;
                display: flex !important;
                align-items: center;
                justify-content: center;
                position: relative;
                box-shadow: 0 0 20px rgba(154, 161, 163, 0.18);
                color: #19222D;
                background-color: #1DB8AF;

                @media (max-width: 900px) {
                    padding: 20px;
                }

                @media (max-width: 600px) {
                    padding: 33px 22px 35px 23px;   
                    display: block !important;
                    height: 270px;
                }

                &-even {
                    background-color: #293749;
                    color: #1DB8AF;
                }
            }

            &__text {
                padding-right: 80px;
                margin-bottom: 28px;
                font-weight: 300;
                font-size: 19px;
                line-height: 25px;
                letter-spacing: -0.03em;
                text-align: left;

                @media (max-width: 975px) {
                    font-size: 20px;
                    line-height: 25px;
                    padding-right: 25px;
                }

                @media (max-width: 600px) {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 300;
                    margin-bottom: 5px;
                    padding: 0;
                }
            }

            &__label {
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: -0.02em;

                @media (max-width: 600px) {
                    position: absolute;
                    font-size: 16px;
                    bottom: 40px;
                    left: 150px;
                    line-height: 16px;
                    letter-spacing: -0.02em;
                    max-width: 162px;
                }
            }

            &__img {
                border: 2px solid #293749;
                box-sizing: border-box;
                box-shadow: 0 0 20px rgba(154, 161, 163, 0.18);
                border-radius: 5px;
                width: 185px;
                height: 185px;
                overflow: hidden;

                @media (max-width: 600px) {
                    width: 120px;
                    height: 120px;
                    margin-right: 40px;
                    border: 1px solid #293749;
                }

                @media (max-width: 441px) {
                    width: 76px;
                    height: 76px;
                    position: absolute;
                    bottom: 35px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 185px;

                    @media (max-width: 600px) {
                        width: 120px;
                        height: 120px;
                    }

                    @media (max-width: 441px) {
                        width: 76px;
                        height: 76px;
                    }
                }
            }

            & ul.slick-dots.slick-thumb {
                width: 100%;
                height: 6px;
                border-radius: 3px;
                background: #ABB0BE;
                justify-content: stretch;
                display: flex;
                max-width: calc(100% - 40px);
                transform: translateX(20px);
            
                & > li {
                    width: calc(100% / ${props => props.itemsLength});
                    margin: 0;
                    
                    &.slick-active {
                        background: #293749;
                        height: 5px;
                        top: -5px;
                        border-radius: 3px;
                    }
                    
                    & > button{
                        width: 100%;
                        padding: 0;
                        opacity: 0;
                    
                        &::before {
                            content: none;
                        }
                    }
                }
            }
        
            .slick-list .slick-track .slick-slide {
                padding: 0;
            }
    
            .slick-arrow {
                background: transparent url(${chevron}) no-repeat center center;
                width: 100px;
                height: 100px;
                background-size: 38px;
                position:absolute;
                z-index: 1;
            
                &::before {
                    content: none; 
                }
            
                &.slick-prev {
                    left: 430px;
                    transform: rotate(180deg) translateY(50px);
                }

                &.slick-next {
                    right: 430px;
                }
                
                @media (max-width: 1670px) {
                    &.slick-prev {
                        right: initial;
                        left: 250px;
                    }

                    &.slick-next {
                        left: initial;
                        right: 250px;
                    }
                }
                
                @media (max-width: 1360px) {
                    &.slick-prev {
                        left: 125px;
                    }

                    &.slick-next {
                        right:  125px;
                    }
                }
            }
        }
    }
`;