import React, { useEffect, useState } from 'react';
import styled from "styled-components";

export const GreenLine = ({
  target,
  scrollPosition,
  bgImg,
  moveBottom,
  lineHeight,
  customStyles,
  delay,
}) => {
  const [newString, setNewString] = useState(<span />);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const updatedString = (
      <LineContainer isVisible img={bgImg} position={moveBottom ? moveBottom : '6px'} height={lineHeight}>
        <span className="green-line__text">{target}</span>
        <span className={isVisible
          ? 'green-line visible'
          : 'green-line'} style={customStyles}
        />
      </LineContainer>
    )
    setNewString(updatedString);
  }, [isVisible === true]);

  useEffect(() => {
    if (scrollPosition === 0) {
      setTimeout(() => setIsVisible(true), delay || 1000);
    }
  }, []);

  const setVisibleElement = () => {
    if (window.pageYOffset >= scrollPosition) {
      setTimeout(() => setIsVisible(true), delay || 500);
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', setVisibleElement);
    return () => {
      document.removeEventListener('scroll', setVisibleElement);
    };
  }, []);

  return newString;
}
export const LineContainer = styled.span`
    position: relative;
    overflow: hidden;

    .green-line {
        height: ${props => props.height || '23px'};
        position: absolute;
        display: inline-block;
        bottom: ${props => props.position};
        left: 0;
        right: 0;
        background-image: url(${props => props.img});
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        background-clip: content-box;
        transition: padding-right 0.2s ease-in;
        padding-right: 100%;
        
        &__text {
          position: relative;
          z-index: 1;
        }
    }
 
    .green-line.visible {
        padding-right: 0;
    }
}
`;