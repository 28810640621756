import ApiDataProvider from '../../api/apiClient';
import sendbird from '../../sendbird';
import getFileUrl from '../../helpers/getFileUrl';
import {
  setChatLoading,
  setChatLoadSuccess,
  setChatLoadFail,
  setChatEmpty,
  setChatUnreadCount
} from '../actions';
import {
  SET_USER_DATA,
  SET_TOKEN,
  SET_FETCH_USER_STATUS,
  SET_AUTHENTICATED,
  SET_STRIPE_CONNECTED_STATUS,
  SET_EMAIL,
  SET_ACCOUNT_DETAILS
} from '../constants/profileConstants';


export const setUserData = userData => {
  return {
    type: SET_USER_DATA,
    payload: {
      id: userData.id,
      email: userData.email,
      firstName: userData.first_name,
      lastName: userData.last_name,
      role: (userData.role || '').toLowerCase(),
      sendbirdId: userData.sendbird_id,
      sendbirdToken: userData.sendbird_token,
      isConnectedToStripe: userData.is_connected_to_stripe,
      businessName: userData.business_name
    }
  };
};

export const setUserEmail = email => {
  return {
    type: SET_EMAIL,
    payload: email
  };
};

export const setAccountDetails = (firstName, lastName, businessName) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload: {
      firstName,
      lastName,
      businessName
    }
  };
};

export const setToken = token => {
  return {
    type: SET_TOKEN,
    payload: token
  };
};

export const updateUnreadMessages = () => {
  return dispatch => {
    sendbird.getTotalUnreadCount().then(count => {
      dispatch(setChatUnreadCount(count));
    }).catch(e => console.error(e));
  };
};

export const initSendbird = (sendbirdId, sendbirdToken) => {
  return async dispatch => {
    dispatch(setChatLoading());
    try {
      await sendbird.init(sendbirdId, sendbirdToken);
      dispatch(setChatLoadSuccess());
      dispatch(updateUnreadMessages());

      sendbird.addListeners(() => {
        setTimeout(() => { dispatch(updateUnreadMessages()); }, 1000);

      });
    } catch (e) {
      console.error(e);
      dispatch(setChatLoadFail());
    }
  };
};

export const loadUser = () => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_FETCH_USER_STATUS,
        payload: 'pending'
      });
      const user = await ApiDataProvider.me();

      dispatch({
        type: SET_FETCH_USER_STATUS,
        payload: 'success'
      });

      dispatch({
        type: SET_AUTHENTICATED,
        payload: true
      });

      dispatch(setUserData(user));

      dispatch({
        type: 'SET_IS_LOGIN',
        payload: true
      });

      if (user.sendbird_id && user.sendbird_token) {
        dispatch(initSendbird(user.sendbird_id, user.sendbird_token));
      } else {
        dispatch(setChatEmpty());
      }

      const accountDetails = await ApiDataProvider.getAccountDetails();

      dispatch({
        type: 'SET_AVATAR',
        payload: `${getFileUrl(accountDetails.data.photo)}`,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SET_FETCH_USER_STATUS,
        payload: 'error'
      });

      dispatch({
        type: SET_AUTHENTICATED,
        payload: false
      });
    }
  };
};


export const setStripeConnected = isConnected => {
  return {
    type: SET_STRIPE_CONNECTED_STATUS,
    payload: isConnected
  };
};
