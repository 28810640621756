import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiDataProvider from '../api/apiClient';
import { setAccountDetails } from '../store/actions/profileActions';
import getFileUrl from '../helpers/getFileUrl';
import AccountDetails from './AccountDetails';


export default () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const role = useSelector(state => state.user.role);

  const fetcher = () => {
    return ApiDataProvider.getAccountDetails().then(({ data }) => data);
  };

  const sender = async ({
    firstName,
    lastName,
    phoneNumber,
    avatar,
    businessName,
    agentEmail,
    emailNotificationsDisabled
  }) => {
    const result = await ApiDataProvider.setAccountDetails({
      first_name: firstName,
      last_name: lastName,
      contact_number: phoneNumber,
      photo: avatar,
      business_name: role === 'business' ? businessName : undefined,
      agent_email: role === 'speaker' ? agentEmail : undefined,
      receive_emails: role === 'speaker' ? !emailNotificationsDisabled : undefined
    });

    dispatch(setAccountDetails(
      result.response.data.first_name,
      result.response.data.last_name,
      result.response.data.business_name
    ));

    dispatch({
      type: 'SET_AVATAR',
      payload: `${getFileUrl(result.response.data.photo)}`,
    });

    history.push('/');
  };

  const emailChangeRequester = (password, email) => {
    return ApiDataProvider.requestEmailChange(password, email);
  };

  return <AccountDetails
    hasBusinessNameField={role === 'business'}
    hasAgentEmailField={role === 'speaker'}
    hasAvatarField={true}
    fetcher={fetcher}
    sender={sender}
    shouldEmailChangeConfirm={true}
    emailChangeRequester={emailChangeRequester}
    avatarText={role === 'speaker' ? 'Avatar' : 'Logo'}
    onBack={() => {
      history.push('/');
    }}
  />;
};
