import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchResendConfirmation } from '../api/helperFunctions';
import { clearVerificationData } from '../store/actions/verificationActions';
import SignUpEmail from './SignUpEmail';

export default () => {
  const dispatch = useDispatch();

  const [resentBtnText, setResentBtnText] = useState('Resend email');

  const userData = useSelector(state => state.verification.userData);

  const email = userData?.email;

  const resendConfirmation = async email => {
    const res = await fetchResendConfirmation('confirmation/re-send', email);
    (res.ok || !!res.message) && setResentBtnText('Re-sent successfully');
    setTimeout(() => {
      setResentBtnText('Resend email');
    }, 3000);
  };

  return <SignUpEmail
    email={email}
    resentBtnText={resentBtnText}
    resendConfirmation={() => resendConfirmation(email)}
    resetReduxState={() => {
      dispatch(clearVerificationData());
    }}
  />;
};
