import MainLayout from './MainLayout';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleLoginModal, toggleVerificationModal } from '../../store/actions';

const mapStateToProps = state => {
    return {
        showModal: state.modal.isLoginModalOpen,
        user: state.user,
        isVerified: state.user.isVerified,
        isVerificationModalOpen: state.modal.isVerificationModalOpen,
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        { toggleLoginModal, toggleVerificationModal }
    )(MainLayout)
);
