import {
    GET_ALL_TOPICS_INIT,
    GET_ALL_TOPICS_SUCCESS,
    GET_ALL_TOPICS_FAILURE,
} from '../actions';


const topicsState = {
    loading: false,
    loaded: false,
    error: false,
    data: [],
};

function topics(state = topicsState, { type, payload }) {
    switch (type) {
        case GET_ALL_TOPICS_INIT:
            return {...state, loading: true, loaded: false, error: false};
        case GET_ALL_TOPICS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: payload,
            };
        case GET_ALL_TOPICS_FAILURE:
            return {...state, loading: false, loaded: false, error: true};
        default:
            return state;
    }
}

export default topics;