const initialState = {
    isLoginModalOpen: false,
    isVerificationModalOpen: false,
    isSignUpBusinessModalOpen: false,
    agree_modal: false,
    del_data: [],
    budget_modal: false,
    gender_modal: false
};

const modal = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_LOGIN_MODAL':
            return { ...state, isLoginModalOpen: !state.isLoginModalOpen };
        case 'TOGGLE_VERIFICATION_MODAL':
            return { ...state, isVerificationModalOpen: !state.isVerificationModalOpen };
        case 'TOGGLE_SIGN_UP_BUSINESS_MODAL':
            return { ...state, isSignUpBusinessModalOpen: !state.isSignUpBusinessModalOpen };
        case 'SET_VERIFICATION_MODAL':
            return { ...state, isVerificationModalOpen: action.payload };
        case 'TOGGLE_AGREE_MODAL':
            return { ...state, agree_modal: action.payload };
        case 'SET_DEL_DATA':
            return { ...state, del_data: action.payload };
        case 'SET_budget_MODAL':
            return { ...state, budget_modal: action.payload };
        case 'SET_gender_MODAL':
            return { ...state, gender_modal: action.payload };
        default:
            return state;
    }
};

export default modal;
