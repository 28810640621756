import { call, put } from 'redux-saga/effects';
import { fetchData } from '../../api/helperFunctions';
import { POST_ENQUIRY_FAIL } from './../actions';

export function* postEnquiryAsync(action) {
    try {
        const {
            event_contact,
            event_name,
            personal_message,
            company_name,
            dates,
            contact_number,
            email,
            event_location,
            budget,
            users,
            files,
            history,
            token,
        } = action.payload;

        let data = {
            event_contact,
            event_name,
            dates,
            personal_message,
            company_name,
            contact_number,
            email,
            event_location,
            budget,
            users,
            files,
        };

        // JSON.stringify(data)
        const response = yield call(
            fetchData,
            'business/enquiry/create',
            'POST',
            data,
            token
        );

        if (response[0] === 200) {
            // yield put(EnquiryStepSucc(response[EnquiryFour]));
            yield put(history.push('/'));
        } else {

            // yield put(EnquiryStepFail(response[EnquiryFour]));
            yield put({
                type: 'POST_ENQUIRY_FAIL',
                payload: response[1],
            });
        }
    } catch (err) {
        console.log('Enquiry Error = ', err);
        // yield put(postRegisterFailure());
    }
}
