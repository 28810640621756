import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import LoadMoreButton from '../LoadMoreButton/LoadMoreButton';
import styles from './styles.module.scss';

const List = ({ dataProvider, ListItemComponent }, ref) => {
  const [items, setItems] = useState([]);
  const [ page, setPage ] = useState(0);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const fetchItems = (page, isFinished) => {
    if (isFinished) return;

    setLoading(true);
    dataProvider(page + 1).then(result => {
      const chunk = result.data;

      setItems(currentItems =>[...currentItems, ...chunk]);
      if (!result.next) {
        setFinished(true);
      } else {
        setPage(page + 1);
      }
    }).catch(e => console.error(e)).finally(() => {
      setLoading(false);
    });
  };

  useImperativeHandle(ref, () => ({
      reload: () => {
        setItems([]);
        setPage(0);
        setFinished(false);
        fetchItems(0, false);
      }
  }));

  if (items.length === 0 && finished) {
    return <div className={styles.noResults}>No results</div>;
  }

  return (
    <>
      {
        items.map(listItem => (
            <ListItemComponent
              key={listItem.id}
              {...listItem}
            />
          )
        )
      }
      {!finished && <LoadMoreButton isLoading={loading} onClick={() => fetchItems(page, finished)} />}
    </>
  );
};

const RefList = forwardRef(List);

export default forwardRef((props, ref) => {
  const myRef = ref || useRef(null);

  return <RefList
    {...props}
    ref={myRef}
  />;
});
