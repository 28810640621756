import React from 'react';
import styled from "styled-components";

export const HomeTitle = ({
  text,
  maxWidth,
  marginBottom,
  fontSize = { desctop: '36px', mobile: '30px' }
}) => (
  <HomeTitleStyled className={`title`} maxWidth={maxWidth} marginBottom={marginBottom} fontSize={fontSize}>
    {text}
  </HomeTitleStyled>
);

export const HomeTitleStyled = styled.h2`
  max-width: ${props => props.maxWidth.desktop};
  overflow: visible;
  font-style: normal;
  font-weight: 600;
  height: 50px;
  font-size: ${props => props.fontSize.desktop};
  line-height: 44px;
  letter-spacing: -0.03em;
  color: #19222D;
  letter-spacing: -1.3px;
  text-align: center;
  margin: 0 auto ${props => props.marginBottom.desktop};
  
  &.title__slider {
    margin-top: 43px;
    margin-bottom: 43px;

    @media (max-width: 1024px) {
      margin-top: 0;
      margin-bottom: 22px;
      padding: 22px 0;
    }
  }
  
   @media (max-width: 1024px) {
        margin: 0 auto ${props => props.marginBottom.mobile};
        margin: 0 auto ${props => props.marginBottom.mobile};
        /* font-size: 30px;
        line-height: 30px; */
        /* height: 24px; */
        text-align: center;
        letter-spacing: -0.03em;
        padding: 0 20px;  
        
        &.title {
          font-size: ${props => props.fontSize.mobile};
          line-height: 36px;
        }
      }
  `;
