import { AdminPanelBilling } from './AdminPanelBilling';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
const mapStateToProps = state => {
    return {
        user: state.user.token && state.user.token.user,
        isVerified: state.user.isVerified,
    };
};
export default withRouter(connect(
    mapStateToProps,
    {}
)(AdminPanelBilling));