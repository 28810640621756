import React from 'react';
import styled from "styled-components";

export const HomeTitle = ({
  text,
  maxWidth,
  marginBottom,
  parentName,
}) => (
  <HomeTitleStyled className={`title__${parentName}`} maxWidth={maxWidth} marginBottom={marginBottom}>
    {text}
  </HomeTitleStyled>
);

export const HomeTitleStyled = styled.h2`
  max-width: ${props => props.maxWidth.desktop};
  overflow: visible;
  font-style: normal;
  font-weight: normal;
  height: 50px;
  font-size: 50px;
  line-height: 50px;
   color: #19222D;
  letter-spacing: -1.3px;
  text-align: center;
  margin: 0 auto ${props => props.marginBottom.desktop};
  
  &.title__slider {
    padding-top: 43px;
    padding-bottom: 43px;

    @media (max-width: 1024px) {
      margin-top: 0;
      margin-bottom: 22px;
    }
  }
  
   @media (max-width: 1024px) {
        margin: 0 auto ${props => props.marginBottom.mobile};
        margin: 0 auto ${props => props.marginBottom.mobile};
        font-size: 30px;
        line-height: 30px;
        height: 24px;
        text-align: center;
        letter-spacing: -0.03em;
        padding: 0 20px;  
        
        &.title__marketplace {
          padding: 0 10px;
          max-width: 360px;
        }
        &.title__process {
          font-size: 36px;
          line-height: 36px;
        }
      }
  `;
