import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export default ({ theme }) => {
  return (
    <div className={styles.container}>
      {
        [...new Array(3)].map(() => (
          <div className={classNames(styles.line, theme === 'dark' ? styles.dark : styles.white)} />
        ))
      }
    </div>
  );
};
