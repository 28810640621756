import React from "react";
import {Select} from 'antd';
import {useDispatch} from "react-redux";

export const DropdownMultiSelect = ({data, id, setOption, itemStatus}) => {
    const {Option} = Select;
    const children = [];
    data.map((el) => {
        if (itemStatus === 2) {
           el.value === 1 && children.push(<Option key={el.id} value={el.value}>{el.label}</Option>);
        }
        if (itemStatus === 3) {
            el.value === 2 && children.push(<Option key={el.id} value={el.value}>{el.label}</Option>);
        }
        if (itemStatus === 1) {
            (el.value !== 1 ) && children.push(<Option key={el.id} value={el.value}>{el.label}</Option>);
        }
    });

    const dispatch = useDispatch();

    const handleOpenAgreeModal = (value) => {
        dispatch({
            type: 'SET_PORTAL_STATUS',
            payload: {portalType: 'agreeModal', portalStatus: true},
        });

        setOption(id, value)
    }

    return (
        <Select
            style={{width: '100%'}}
            onChange={handleOpenAgreeModal}
            placeholder="Select Action"
        >
            {children}
        </Select>
    );
}

