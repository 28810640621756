/**
 *
 * @param dateString iso date YYYY-MM-DDThh:mm:ssZ
 * @returns {Date}
 */
function parseEventPostedDate(dateString) {
  return new Date(dateString);
}

/**
 *
 * @param dateString YYYY-MM-DD format
 * @returns {Date}
 */
function parseEventDate(dateString) {
  return new Date(dateString);
}

/**
 *
 * @param date
 * @returns {string} format with respect to locale and user's timezone
 */
function formatDateLocal(date) {
  return date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
}

/**
 *
 * @param date
 * @returns {string} format with respect to locale and without respect to user's timezone
 */
function formatDateAbsolute(date) {
  return date.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'utc' });
}

export default class EventAbstract {
  constructor(eventPostedDate, eventDates, budget_from, budget_to) {
    this._eventDates = eventDates ? eventDates.map(date => {
      return {
        startDate: parseEventDate(date.start_date),
        endDate: parseEventDate(date.end_date)
      };
    }) : [];

    this._eventPostedDate = parseEventPostedDate(eventPostedDate);

    this._budget_from = budget_from;
    this._budget_to = budget_to;

  }

  getEventPostedDateFormatted() {
    return formatDateLocal(this._eventPostedDate);
  }

  getEventDatesFormatted() {
    return this._eventDates.map(dateInterval => {
      if (dateInterval.startDate.getTime() === dateInterval.endDate.getTime()) return formatDateAbsolute(dateInterval.startDate);
      return `${formatDateAbsolute(dateInterval.startDate)} - ${formatDateAbsolute(dateInterval.endDate)}`
    });
  }

  getBudget() {
    return {
      from: this._budget_from,
      to: this._budget_to
    };
  }

  getBudgetFormatted() {
    if (this._budget_to === null && this._budget_from === null) {
      return 'To be discussed';
    }

    if (this._budget_to === null || this._budget_from === this._budget_to) {
      return `$${this._budget_from}`;
    }

    return `$${this._budget_from} - $${this._budget_to}`;
  }
}
