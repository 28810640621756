import React from 'react'
import styled from 'styled-components'
import constants from '../constants';

export default (props) => {
    return <SCPStyled>
        <div className="scp-content">
            <div className="scp-text">
                <h2 className="scp-text__label">Coming soon</h2>
            </div>
        </div>

        <div className="scp-footer">
            All Rights Reserved     {constants.copyright}
        </div>
    </SCPStyled>
}

const SCPStyled = styled.div`
    background: #19212B;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 160px;
    padding-bottom: 100px;

    @media (max-width: 1024px) {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    .scp {

        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-image {
            position: relative;
            margin: 0 15px;

            &__big {
                width: 100%;
                max-width: 595px;
            }
        }

        &-text {
            max-width: 400px;
            margin: 0 15px;

            &__label {
                color: #ffffff;
                font-size: 28px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__sublabel {
                color: #20D5C4;
                font-size: 28px;
                line-height: 32px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &__desc {
                color: #ffffff;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 30px;
            }

            &__list {
                list-style: none;
                padding-left: 20px;

                &-title {
                    color: #20D5C4;
                    font-size: 15px;
                    font-weight: 300;
                    margin-bottom: 10px;
                }

                &-item {
                    color: #ffffff;
                    margin-bottom: 10px; 

                    &:before {
                        content: "\\2022";
                        color: #20D5C4;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        &-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 13px;
            font-weight: 300;
            color: #ffffff;
            text-align: center;
        }
    }
`