import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import Spinner from '../Spinner';
import ConfirmationUserModal from '../modals/ConfirmationUserModal';
import MessageBoxUserModal from '../modals/MessageBoxUserModal';
import s from './EnquirySummary.module.scss';
import Description from './Description';
import Details from './Details';
import getControlSectionData from './getControlSectionData';

const modalTexts = {
  confirmation: {
    accept: {
      text: 'Accept enquiry?'
    },
    reactivate: {
      text: 'Reactivate enquiry?'
    },
    decline: {
      text: 'Decline enquiry?'
    },
    cancel: {
      text: 'Cancel enquiry?',
      subtext: role => `If you select to cancel this enquiry the `
        + `${role === 'speaker' ? 'Finder' : 'Speaker'} will be notified`
        + ' and the enquiry will be marked as cancelled in your dashboard.'
    }
  },

  messageBox: {
    accept: {
      success: {
        text: 'Accepted'
      },
      error: {
        text: 'Failed to accept'
      }
    },
    reactivate: {
      success: {
        text: 'Reactivated'
      },
      error: {
        text: 'Failed to reactivate'
      }
    },
    decline: {
      success: {
        text: 'Declined'
      },
      error: {
        text: 'Failed to decline'
      }
    },
    cancel: {
      success: {
        text: 'Canceled'
      },
      error: {
        text: 'Failed to cancel'
      }
    }
  }
};

const Actions = ({
  id,
  status,
  role,
  bookingId,
  bookingStatus,
  acceptHandler,
  reactivateHandler,
  declineHandler,
  cancelHandler,
  backHandler
}) => {
  if (!status) {
    return null;
  }

  const controlSectionData = getControlSectionData(role, status, bookingId, bookingStatus);
  let buttonsFragment;
  let textFragment;

  if (controlSectionData.buttons) {
    buttonsFragment = controlSectionData.buttons.map((buttonName, idx) => {
      let buttonElement;

      switch (buttonName) {
        case 'decline':
          buttonElement = (
            <button
              className={s.buttonTransparent}
              onClick={declineHandler}
            >decline
            </button>
          );
          break;
        case 'cancel':
          buttonElement = (
            <button
              className={s.buttonTransparent}
              onClick={cancelHandler}
            >cancel
            </button>
          );
          break;
        case 'accept':
          buttonElement = (
            <button
              className={s.buttonColored}
              onClick={acceptHandler}>
              start chat
            </button>
          );
          break;
        case 'book':
          buttonElement = (
            <Link
              className={s.buttonColored}
              to={`/dashboard/enquiries/${id}/booking`}>
              finalise offer
            </Link>
          );
          break;
        case 'booking':
          buttonElement = (
            <Link
              className={s.buttonColored}
              to={`/dashboard/bookings/${bookingId}`}>
              view booking offer
            </Link>
          );
          break;
        case 'reactivate':
          buttonElement = (
            <button
              className={s.buttonColored}
              onClick={reactivateHandler}>
              reactivate
            </button>
          );
          break;
        case 'back':
          buttonElement = (
            <button
              className={s.buttonTransparent}
              onClick={backHandler}>
              back
            </button>
          );
        default:
      }

      return (
        <span className={s.buttonWrapper} key={idx}>
          {buttonElement}
        </span>
      );
    });
  }

  if (controlSectionData.text) {
    textFragment = (
      <span>
        {controlSectionData.text}
      </span>
    );
  }

  return (
    <div className={s.actionSection}>
      <div>{ textFragment }</div>

      {
        buttonsFragment && (
          <div>{ buttonsFragment }</div>
        )
      }
    </div>
  );
};

const getStatusClass = status => {
  switch (status) {
    case 'PENDING':
      return classNames(s.text, s.pending);
    case 'DECLINED':
      return classNames(s.text, s.declined);
    default:
      return classNames(s.text);
  }
};

const Container = ({ children }) => {
  return (
    <div className={s.container}>
      <h1 className={s.pageTitle}>New Enquiry</h1>
      <div>
        {children}
      </div>
    </div>
  );
};



const EnquirySummary = ({
  id,
  speakerId,
  role,
  fetcher,
  accept,
  reactivate,
  decline,
  cancel
}) => {
  const [ agreeCheckbox, setAgreeCheckbox ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enquiryStatus, setEnquiryStatus] = useState();
  const [bookingId, setBookingId] = useState();
  const [bookingStatus, setBookingStatus] = useState();

  const [ eventName, setEventName ] = useState('');
  const [ personalMessage, setPersonalMessage ] = useState('');
  const [ budget, setBudget ] = useState('');
  const [ eventDates, setEventDates ] = useState([]);
  const [ companyName, setCompanyName ] = useState('');
  const [ contactName, setContactName ] = useState('');
  const [ datePosted, setDatePosted ] = useState('');
  const [sendbirdChatId, setSendbirdChatId] = useState();

  const [termsErrorVisible, setTermsErrorVisible] = useState(false);

  const [speaker, setSpeaker] = useState();

  // confirmation modal data
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalText, setConfirmationModalText] = useState('');
  const [confirmationModalSubtext, setConfirmationModalSubtext] = useState('');
  const [currentAction, setCurrentAction] = useState('');

  // messagebox modal data
  const [messageBoxOpen, setMessageBoxOpen] = useState(false);
  const [messageBoxText, setMessageBoxText] = useState('');
  const [messageBoxError, setMessageBoxError] = useState('');

  const history = useHistory();

  const agreeCheckboxHandler = () => {
    if (!agreeCheckbox) {
      setTermsErrorVisible(false);
    }

    setAgreeCheckbox(!agreeCheckbox);
  };

  const acceptEnquiryHandler = useCallback(() => {
    const isNewEnquiry = enquiryStatus === 'PENDING';

    if (isNewEnquiry && !agreeCheckbox) {
      setTermsErrorVisible(true);
      return;
    }

    setCurrentAction('accept');
    setConfirmationModalText(modalTexts.confirmation.accept.text);
    setConfirmationModalOpen(true);
  }, [agreeCheckbox, enquiryStatus]);

  const reactivateEnquiryHandler = () => {
    setCurrentAction('reactivate');
    setConfirmationModalText(modalTexts.confirmation.reactivate.text);
    setConfirmationModalOpen(true);
  };

  const declineEnquiryHandler = useCallback(() => {
    setConfirmationModalText(modalTexts.confirmation.decline.text);
    setCurrentAction('decline');
    setConfirmationModalOpen(true);
  }, [decline]);

  const cancelEnquiryHandler = useCallback(() => {
    setConfirmationModalText(modalTexts.confirmation.cancel.text);
    setConfirmationModalSubtext(modalTexts.confirmation.cancel.subtext(role));
    setCurrentAction('cancel');
    setConfirmationModalOpen(true);
  }, [cancel]);
  
  const backHandler = () => {
    history.goBack();
  };


  const cancelConfirmationModalHandler = () => {
    setCurrentAction('');
    setConfirmationModalText('');
    setConfirmationModalSubtext('');
    setConfirmationModalOpen(false);
  };


  const confirmConfirmationModalHandler = useCallback(() => {
    let actionPromise;

    switch (currentAction) {
      case 'accept':
        actionPromise = accept;
        break;
      case 'reactivate':
        actionPromise = reactivate;
        break;
      case 'decline':
        actionPromise = decline;
        break;
      case 'cancel':
        actionPromise = cancel;
        break;
      default:
    }

    return actionPromise().then(() => {
      setMessageBoxText(modalTexts.messageBox[currentAction].success.text);
      setMessageBoxError('');
      setMessageBoxOpen(true);
    }).catch(e => {
      setMessageBoxText(modalTexts.messageBox[currentAction].error.text);
      setMessageBoxError(e.message);
    }).finally(() => {
      setConfirmationModalText('');
      setConfirmationModalSubtext('');
      setConfirmationModalOpen(false);
      setMessageBoxOpen(true);
    });
  }, [currentAction, accept, decline, cancel]);

  const confirmMessageBoxHandler = () => {
    if (messageBoxError) {
      setMessageBoxOpen(false);
    } else {
      history.push(`/chat#${sendbirdChatId}`);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetcher().then(enquiry => {
      const {
        event_name,
        personal_message,
        company_name,
        event_contact,
        status_label,
        speakers,
        bookings,
        chat
      } = enquiry.data;

      const speaker = speakers && speakers.find(speaker => speaker.id === +speakerId);
      const relevantBookings = [...bookings].sort((a, b) => b.id - a.id).filter(booking =>
        (speakerId ? booking.user_id === +speakerId : true)
      );

      const booking = relevantBookings[0];

      setSpeaker(speaker);

      setEnquiryStatus(status_label || speaker?.status_label);
      if (booking) {
        setBookingId(booking.id);
        setBookingStatus(booking.status);
      }
      setEventName(event_name);
      setPersonalMessage(personal_message);
      setBudget(enquiry.getBudget());
      setEventDates(enquiry.getEventDatesFormatted());
      setCompanyName(company_name);
      setContactName(event_contact);
      setDatePosted(enquiry.getEventPostedDateFormatted());
      setSendbirdChatId(chat.sendbird_id);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <div className={s.pageContent}>
        <div className={s.eventDescription}>
          <Description
            eventName={eventName}
            personalMessage={personalMessage}
            budget={budget}
          />
        </div>
        <div className={s.eventDetails}>
          <Details
            datePosted={datePosted}
            companyName={companyName}
            contactName={contactName}
            eventDates={eventDates}
          />
        </div>

        <div className={s.eventActions}>
          {
            enquiryStatus === 'PENDING' && role === 'speaker' && <>
              <h2 className={s.subHeader}>Interested in this enquiry?</h2>
              <div className={s.checkBox}>
                <Form.Group>
                  <Form.Check type="checkbox" id="signUpFormAgreeCheck">
                    <Form.Check.Input
                      required
                      type="checkbox"
                      checked={agreeCheckbox}
                      onChange={agreeCheckboxHandler}
                    />
                    <Form.Check.Label className={s.checkboxLabel}>
                      Yes, I understand and agree
                      to <a href="/terms-conditions" target="_blank">Finders Speakers
                      Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Policy</a>
                    </Form.Check.Label>
                  </Form.Check>
                  <div className={s.errorMessage}>
                    {
                      termsErrorVisible && 'You must accept Terms before accepting enquiry'
                    }
                  </div>
                </Form.Group>
              </div>
            </>
          }

          {
            speaker && (
              <div className={s.speaker}>
                <div className={s.nameHeader}>
                  <div className={s.subHeader}>Speaker name</div>
                </div>
                <div className={s.name}>
                  {speaker.full_name}
                </div>
                <div className={s.statusHeader}>
                  <div className={s.subHeader}>Status</div>
                </div>
                <div className={s.status}>
                  <span className={getStatusClass(speaker.status_label)}>{speaker.status_label}</span>
                </div>
              </div>
            )
          }

          <div>
            <Actions
              status={enquiryStatus}
              role={role}
              id={id}
              acceptHandler={acceptEnquiryHandler}
              reactivateHandler={reactivateEnquiryHandler}
              declineHandler={declineEnquiryHandler}
              cancelHandler={cancelEnquiryHandler}
              backHandler={backHandler}
              bookingId={bookingId}
              bookingStatus={bookingStatus}
            />
          </div>
        </div>

        <div className={s.contactUs}>
          Need Help?
          <Link to={'/contact-us'}  target="_blank" className={s.contactUsLink}>CONTACT US</Link>
        </div>
      </div>

      <ConfirmationUserModal
        isOpen={confirmationModalOpen}
        text={confirmationModalText}
        subtext={confirmationModalSubtext}
        onCancel={cancelConfirmationModalHandler}
        onConfirm={confirmConfirmationModalHandler}
      />

      <MessageBoxUserModal
        isOpen={messageBoxOpen}
        title={messageBoxText}
        message={messageBoxError}
        onOk={confirmMessageBoxHandler}
      />
    </Container>
  );
};

export default EnquirySummary;
