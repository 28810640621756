const authStepsState = 0

const authStep = (state = authStepsState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_AUTH_STEP':
            return action.payload;
        default:
            return state;
    }
};

export default authStep;
