import { SET_CURRENT_FAQ_TAB, SET_IS_ERROR_ON_EDIT_PROFILE } from '../constants/faq';


export const setCurrentTab = (idx) => ({
    type: SET_CURRENT_FAQ_TAB,
    payload: {idx},
});

export const setIsErrorOnEditProfile = (bool) => ({
    type: SET_IS_ERROR_ON_EDIT_PROFILE,
    payload: {bool},
});