import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import ConfirmationUserModal from '../../_components/modals/ConfirmationUserModal';
import {fetchDataGet} from "../../api/helperFunctions";
import {SpeakerBackSideCard} from './SpeakerCardBackSide';
import {SliderSpeakerCard} from './SliderSpeakerCard';
import PrimaryButton from '../../_components/buttons/PrimaryButtonLarge';
import {toggleLoginModal} from '../../store/actions';
import HorizontalScroll from '../HorizontalScroll';
import {addUsersToShortlist, removeUsersFromShortlist} from '../../store/actions/findSpeakersActions';

export const SpeakersList = ({winSize, data, children}) => {
  const {width} = useWindowDimensions();
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const history = useHistory();
  let sliderRef = useRef(null);
  const user = useSelector(state => state.user);
  const shortList = useSelector(state => state.speakers.shortList.data);
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);


  const sliderOptions = {
    arrows: false,
    variableWidth: true,
    infinite: true,
    slidesToScroll: 3,
    autoplay: false,
    autoplaySpeed: 4000,

    beforeChange: (old, next) => {
      if (next >= headAnimatedCount - 2 && isAnimationDone !== true) {
        setIsAnimationDone(true)
      }
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
          dots: true,
        }
      },
    ]
  };

  const headAnimatedCount = (winSize > 780 ? 5 : 2);

  const getSlideStyle = (index) => {
    let slide = activeSlide < 0 ? 0 : activeSlide
    if (width > 768) {
      if (slide + 5 > 8 && index === 0) {
        return '';
      } else if (
        index === slide ||
        index <= slide ||
        index > slide + 4
      ) {
        return 's-home__speakers-slide--inactive';
      } else {
        return '';
      }
    }
  }

  const addSpeakerInShortList = (id) => {
    if (!user.token) {
      setModalOpen(true);
      return;
    }

    if (shortList.some(speaker => speaker.id === id)) {
      dispatch(removeUsersFromShortlist([id]));
    } else {
      dispatch(addUsersToShortlist([id]));
    }
  };

  return (
    <section className='s-home__speakers' data-nosnippet>
      <ConfirmationUserModal
        isOpen={modalOpen}
        text="Log in or sign up to shortlist speakers"
        onConfirm={() => {
          setModalOpen(false);

          window.scrollTo({top: 0});

          dispatch(toggleLoginModal());
        }}
        onCancel={() => {
          setModalOpen(false);

          window.scrollTo({top: 0});

          history.push('/register-business');
        }}
        onClose={() => setModalOpen(false)}
        cancelButtonText="sign up"
        confirmButtonText="log in"
      />

      <div className="s-home__speakers-title-wrap">
        <div className="s-home__speakers-title-container">
          <div className="s-home__speakers-title">Trending speakers</div>
          <div className="s-home__speakers-subtitle">
            Get access to a curated collective of the most diverse and exciting global speaking talent
          </div>
        </div>
      </div>
      <div className="s-home__speakers-wrap">
        <div className={`s-home__speakers-slider ${width <= 768 ? 'drop-width' : ''}`}>

            {width > 768
              ? (
                <SliderCustomArrows itemsLength={data.length}>
                  <Slider
                    ref={ref => sliderRef = ref}
                    {...sliderOptions}
                  >
                    {data.map((d, i) => {
                      return (
                        <div
                          className={`s-home__speakers-slide`}
                          key={d.id}
                        >
                          <div style={{margin: '0 10px'}}>
                            <SliderSpeakerCard
                              {...d}
                              isAnimated={true}
                              isAnimationDone={isAnimationDone}
                              delay={i * 300}
                              index={i}
                              backComponent={<SpeakerBackSideCard {...d}
                              addSpeakerInShortList={addSpeakerInShortList}/>}
                              addSpeakerInShortList={addSpeakerInShortList}
                              width={width}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </Slider>

                    <div
                      className="s-home__slider-arrow s-home__slider-arrow-left"
                      style={{backgroundImage: `url(${'/icons/slider-arrow-left.png'})`}}
                      onClick={() => sliderRef.slickPrev()}
                    ></div>
                    <div
                      className="s-home__slider-arrow s-home__slider-arrow-right"
                      style={{backgroundImage: `url(${'/icons/slider-arrow-right.png'})`}}
                      onClick={() => sliderRef.slickNext()}
                    ></div>
                </SliderCustomArrows>
              ) : <HorizontalScroll theme="dark">
                {data.map((d, i) => {
                  return (
                    <div
                      key={i}
                      className={`s-home__speakers-slide`}
                      style={{position: 'relative'}}
                    >
                      <SliderSpeakerCard
                        {...d}
                        isAnimated={true}
                        isAnimationDone={isAnimationDone}
                        delay={i * 300}
                        addSpeakerInShortList={addSpeakerInShortList}
                        backComponent={<SpeakerBackSideCard {...d} addSpeakerInShortList={addSpeakerInShortList}/>}
                        width={width}
                      />
                    </div>
                  )
                })}
              </HorizontalScroll>
            }

          <div className="s-home__speakers-button-wrapper">
            <PrimaryButton onClick={() => history.push('/find-speakers')}>
             search all speakers
            </PrimaryButton>
          </div>
          {children}
        </div>
      </div>
    </section>
  )
}

const SliderCustomArrows = styled.div`
  .slick-track {

  }

  @media (max-width: 768px) {
    .slick-track {
      left: 0;
    }
  }

  & .slick-arrow {
    &:before {
      content: none;
    }
  }

  & .slick-prev {
    left: 25%;
    background: transparent url(/icons/slider-arrow-left.png) no-repeat center center;
    width: 100px;
    height: 100px;
    background-size: 38px;
    position: absolute;
    z-index: 1;
  }

  & .slick-next {
    right: 25%;
    background: transparent url(/icons/slider-arrow-right.png) no-repeat center center;
    width: 100px;
    height: 100px;
    background-size: 38px;
    position: absolute;
    z-index: 1;
  }

  .slick-dots {
    display: flex;
    height: 6px;
    width: 100%;
    position: absolute;
    bottom: -25px;
    border-radius: 3px;
    padding: 0;
    margin-left: -15px;
    list-style: none;
    text-align: center;
    justify-content: stretch;
    transform: translateX(20px);

    li {
      height: 6px;
      list-style: none;
      width: auto;
      width: ${props => 320 / props.itemsLength}px;
      margin: 0;
      background-color: #ABB0BE;

      button {
        opacity: 0;
        width: 100%;
      }
    }

    .slick-active {
      background-color: #ffffff;
      border-radius: 3px;
    }
  }
`;
