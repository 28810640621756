import { useEffect } from 'react';

const useInsertScript = (scriptPath, isAsync) => {
  useEffect(() => {
    if (!scriptPath) {
      return;
    }
  
    const scriptElement = document.createElement('script');
    
    scriptElement.setAttribute('src', scriptPath);
    
    if (isAsync) {
      scriptElement.setAttribute('async', '');
    }
    
    document.head.appendChild(scriptElement);
    
    return () => {
      scriptElement.remove();
    };
  }, [scriptPath]);
};

export default useInsertScript;
