import React from 'react';
import classNames from 'classnames';
import HeaderDashboard from '../Header/HeaderDashboard';
import s from './styles.module.scss';
import constants from '../../constants';

const DashboardLayout = (props) => {
  const { dashboardOffset, chatOffset } = props;

  return (
    <div className={classNames(s.container, {
      [s.dashboardOffset]: dashboardOffset,
      [s.chatOffset]: chatOffset
    })}>
      <HeaderDashboard
        {...props}
        name={props.user && props.user.first_name}
        last_name={props.user && props.user.last_name}
      />

      <div className={s.content}>
        {props.children}
      </div>

      <footer className={s.footer}>
        All Rights Reserved {constants.copyright}
      </footer>
    </div>
  );
};

export default DashboardLayout;
