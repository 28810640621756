import React from 'react';
import { Item, PrivacyContainer, Text } from './PrivacyPolicy'
import { FAQHero } from "../FAQPage/FAQHero";
import { AnimatedOnScroll } from 'react-animated-css-onscroll'
import { Link } from "react-router-dom";

const TermsConditions = () => {
    const policyText = [
        {
            id: 1,
            question: '',
            answer: (<Item>

            </Item>)
        }
    ]

    return (<>
        <FAQHero title={'Terms and Conditions'} subHeader={<Text color={'#fff'}>Last updated 11.01.2021</Text>} />
        <PrivacyContainer className={'container'}>
            <AnimatedOnScroll animationIn="fadeInLeft" animationInDelay={200} animationInDuration={1500}
                screenOffset={250}>
                <Text>Welcome to the website of FINDERS SPEAKERS PTY LTD (ABN 89 630 096 730) ("we, "us" or "Finders
                Speakers") which provides an all-in-one marketing, booking, management and collaboration
                          platform for speakers, event organisers and other industry participants.   </Text>
                <Text>This website is located on the web via the domain <Link
                    style={{
                        textDecoration: 'none',
                        fontWeight: 'bold',
                    }}
                    href="https://findersspeakersco.com"
                >
                    https://findersspeakersco.com
                      </Link> and includes all of the files located in that domain and any subdomains thereof, and any
                          other websites through which we make our services available ("this site").
                    </Text>
                <Text>
                    <h4>Agreement to these Website Terms of Use</h4>
                    <hr style={{ borderTop: '3px solid black' }} />
                By accessing this site as a user because you wish to engage a speaker for an event (“Finder”),
                or to provide speaking services (“Speaker”) or as a visitor, you agree to be bound by the
                website terms of use ("Website Terms of Use"). These Website Terms of Use are to be read in
                conjunction with the Speaker or Finder Service Terms and Conditions as applicable and our Code
                          of Conduct which together govern the obligations and behaviours of Finders and Speakers,    </Text>
                <Text>
                    <strong>
                        Please read these Website Terms of Use carefully as they contain important information
                        about your legal rights, remedies and obligations and constitute a binding agreement
                        between you and Finders Speakers and govern your use of this site.
                        Privacy Policy
                          </strong>
                </Text>
                <Text>
                    Our collection and use of personal information in connection with your access to and use of
                    this site is described in our Privacy Policy located at https://app.Finders
                    Speakers.io/privacy-policy, which is incorporated by reference into these Website Terms of
                    Use.
                         </Text>
                <h4>
                    Legal capacity to transact
                         </h4>
                <Text>
                    If you are under 16 years of age, you cannot use the services provided by this site. By using
                    this site, you represent and warrant to us that you are over the age of 16 years.
                         </Text>
                <h4>Use of this Site   </h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <h4>User information   </h4>
                <Text>
                    In order to register an account with this site as a Finder or Speaker, you must agree to these
                    Website Terms of Use, and either the Finder or Speaker Service Terms and Conditions as
                    applicable and provide us with a user name and contact information including valid email
                    address and phone number.
                         </Text>
                <Text>
                    You must promptly update this information to maintain its accuracy at all times.
                         </Text>
                <Text>
                    You represent and warrant to us that all information provided by you, including the
                    information through our account registration module or entered into your Speaker profile, is
                    true and not misleading and does not violate any applicable law or regulation or any person's
                    intellectual property or other rights.
                         </Text>
                <h4>Multiple accounts and automated account opening   </h4>
                <Text>One person may not maintain more than one account with this site. Accounts registered by "bots"
                          or other automated methods are not permitted.   </Text>
                <h4>Registration   </h4>
                <Text>
                    We reserve the right to make any parts of this site accessible only to users who have registered.
                         </Text>
                <Text>
                    Upon registration with this site as a Finder or Speaker, you will enter a username and password to access your account. You are responsible for maintaining the security of your password for this site.
                         </Text>
                <Text>
                    We will not be liable for any loss or damage arising from or in connection with your failure to comply with this security obligation. You agree that we will be entitled to assume that any person using this site with your username and password is you or your authorised representative.
                         </Text>
                <Text>
                    You must notify us immediately of any known or suspected unauthorised use of any password or any other breach of security.
                         </Text>
                <h4>Approval of registrations   </h4>
                <Text>
                    We reserve the right to accept or reject any application for registration of an account with this site at our discretion. User verification on the Internet is difficult and we do not assume any responsibility for the confirmation of any user’s identity. Notwithstanding the above, for transparency and fraud prevention purposes we require users to take a two-step verification process with phone and email and we may, but have no obligation to (i) ask you to provide a form of government identification or other information, and or (ii) undertake additional checks designed to help verify the identities or backgrounds of Speakers.
                         </Text>
                <h4>Access to site    </h4>
                <Text>
                    Notwithstanding anything else, we reserve the right at our absolute discretion refuse access to the site at any time.
                         </Text>
                <h4>
                    The role of Finders Speakers
                </h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <Text>
                    We are an intermediary platform that aims to connect Finders with Speakers to facilitate business interactions.
                         </Text>
                <Text>
                    You acknowledge and agree that Finders Speakers is a marketing and technology services provider and does not provide speaking services.
                         </Text>
                <Text>
                    The relevant Speaker, and not Finders Speakers, is:
                         </Text>
                <Text>
                    <ul>
                        <li>
                            the supplier of the services that they offer to sell; and
                                </li>
                        <li>
                            solely responsible for supplying third parties with those services and for services themselves.
                                </li>
                    </ul>
                </Text>
                <Text>
                    We do not act as agent for the Speaker and we make no representation or warranty, and provide no guarantee, that the Speaker will provide the services that they offer through this site, or that those services will meet any third party’s expectations. Finders should satisfy themselves through their own enquiries as to the quality or suitability of any Speaker listed on this site and any services supplied, offered or recommended by or on behalf of a Speaker.
                         </Text>
                <Text>
                    To promote the site and to increase the exposure of Speakers, profiles and other Speaker content may be displayed on other websites, in applications, within emails, and in online and offline advertisements.
                         </Text>
                <h4>Third party goods, services and websites   </h4>
                <Text>
                    This site includes an online portal that may allow third parties to advertise their services on this site and to upload information and other content for our users to access once they are registered. We do not recommend or endorse any third-party services that are listed, advertised or referred to in this site or the content of any third-party websites. We are not responsible for the content of linked third party websites, websites framed within this site or third-party advertisements and we do not make any representations, or provide any guarantees or warranties, regarding their content or accuracy. Your use of any third-party websites is at your own risk and subject to their respective terms and conditions of use. We do not act as agent for any such third parties and we take no responsibility, and assume no liability, for their content, information supplied (including opinions, ideas, suggestions, comments, observations, text, photographs, videos, data, music, sounds, chat messages, files or any other material) or any loss or damage that results from any dealings that you may have with such third parties.
                         </Text>
                <h4>User acknowledgements   </h4>
                <Text>
                    You acknowledge that we do not:
                         </Text>
                <Text>
                    <ul>
                        <li>
                            check the truth or currency of any of the material or information that third parties provide or make available through this site;

                                </li>
                        <li>
                            control, endorse, approve or warrant to you the merchantability or fitness for any particular purpose of any of the services of any third parties referred to in this site or whose identities become known to you through this site, including  Speaker content that is published or made available in or through this site;
                                </li>
                        <li>
                            offer professional advice on the quality or suitability of any services or information supplied by any such third parties; or
                                </li>
                    </ul>
                </Text>
                <h4>
                    Contract for Speaker’s services
                </h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <Text>
                    Acceptance of a Speaker booking offer by a Finder creates a contract between the Finder and the relevant Speaker in respect of speaking services to be provided by that Speaker (“Speaker Contract”). The fees will be agreed as between the Finder and the Speaker. We have provided a template Booking Agreement for convenience, but we are not a party to the Speaker Contract. The Contract will be subject to relevant Speakers or Finders own terms and conditions as agreed. Any terms and conditions included in the Speaker Contract must not conflict with these Website Terms of use, Speaker Terms of Service or Finders Terms of Service.
                </Text>
                <h4>Payments</h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <Text>
                    There are no upfront or subscription fees for Finders and Speakers to register on site, however upon a successful booking confirmation being made, we deduct a Platform Fee from the Speaker fees as detailed in the Finder or Speaker Service Terms and Conditions. The Platform Fee helps us with future marketing and development of the platform.
                  </Text>
                <Text>
                    If the Finder accepts the Speaker’s offer, they are taken to payments page where they set up an account with a third party gateway to make payment of the Speakers fees, and payment processing costs by credit card.
                  </Text>
                <h4>Third party payments</h4>
                <Text>
                    We use third party payment gateway Stripe to facilitate secure online payment transactions for Speakers fees. Payments made through this payment gateway are subject to Stripe’s terms and conditions and privacy policy. We do not see or have access to any personal information that you provide to Stripe, other than information that is required in order to process your order (e.g., your name, email address and billing/postal address). You receive a confirmation and payment receipt of your transaction and records of your transactions can be found on the client dashboard.
                  </Text>
                <Text>
                    While our third party payment gateway and website hosting providers employ secure technology for transactions with our users, we will not be responsible for any damages, including consequential losses (whether direct or indirect), that may be suffered by a user whose credit or debit card or bank account information is used in a fraudulent or unauthorised manner by any person other than us.
                  </Text>
                <h4>Contract modification or Cancellation</h4>
                <Text>
                    The Finder and Speaker are responsible for any modifications to a booking that they agree to make and this will be organised by the Finder and Speaker independently of the Platform. Please refer to Finder or Speaker Service Terms and Conditions as relevant for our cancellation terms.
                  </Text>
                <h4>Disputes between Finders and Speakers</h4>
                <Text>
                    You are solely responsible for your interactions with other parties listed on this site. We are solely a platform that helps Speakers connect with Finders and we will not be responsible to provide any refund to either Finders or Speakers. We reserve the right, but have no obligation, to monitor and take action regarding any disputes between Finders and Speakers. We will not, however, act on behalf, of any Finder or Speaker, in respect of any dispute between a Finder and a Speaker.
                  </Text>
                <h4>
                    Intellectual property
                  </h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <h4>Our Copyright </h4>
                <Text>
                    In these Website Terms of Use, the term <strong>"Proprietary Content"</strong> means:
                  </Text>
                <Text>
                    <ul>
                        <li>
                            this site;
                        </li>
                        <li>
                            all of its content (including all of the text, graphics, logos, designs, software, data, sound and video files and other information contained in this site, and the selection and arrangement thereof); and
                        </li>
                        <li>
                            all software, systems and other information owned or used by us in connection with the services offered through this site (whether hosted on the same server as this site or otherwise).
                        </li>
                    </ul>
                </Text>
                <Text>
                    All Proprietary Content is our property or our licensors and is protected by Australian and international copyright laws. You must not reproduce, transmit, republish or prepare derivative works from any of the Proprietary Content, except as expressly authorised by these Website Terms of Use or with our prior written consent or other copyright owner (as applicable).
                  </Text>
                <Text>
                    You may download and print out content from this site only for your non-commercial use for the purposes this site is provided for as set out in Speaker or Finder Service Terms and Conditions and provided that you do not remove or modify any copyright, trademark or other proprietary notices.
                  </Text>
                <h4>Our Trademarks</h4>
                <Text>
                    The look and feel of this site (including all button icons, scripts, custom graphics, logos and headers) are the trademarks, service marks and/or trade dress of Finders Speakers. These trademarks, service marks and trade dress may not be used, copied or imitated, in whole or in part, without our prior written consent.
                  </Text>
                <h4>User Content</h4>
                <Text>
                    In these Website Terms of Use, the term "User Content" means any and all content that is submitted, posted or otherwise added to this site by any user, such as comments, forum posts, chat room messages, reviews, ratings and feedback.
                  </Text>
                <Text>
                    This site contains some features that enable you and other users to upload User Content. We reserve the right to display, refuse to display, remove and/or amend all or any part of any User Content at its absolute discretion. In respect of any User Content that you upload, you:
                  </Text>
                <Text>
                    <ul>
                        <li>represent and warrant to us that your sharing of that User Content does not infringe any copyright or other legal right of any other person; and</li>
                        <li>
                            grant to Finders Speakers a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, sub-licensable and transferable license to use, reproduce, distribute, modify, adapt, prepare derivative works of, publicly display, publicly perform and otherwise exploit all or any part of that User Content in any way in our  absolute discretion.
                        </li>
                    </ul>
                </Text>
                <h4>Copyright claims</h4>
                <Text>
                    If you believe that our site contains any material that infringes upon any copyright that you hold or control, or that users are directed through a link on this site to a third party website that you believe is infringing upon any copyright that you hold or control, you may send a notification of the alleged infringement to us in writing. The notification should identify the works that are allegedly being infringed upon and give particulars of the alleged infringement. We will give a written notice of a claim of infringement to the provider of the allegedly infringing material. If they do not respond to us in writing denying the alleged infringement within 14 days after receipt of that notice, we will remove or block the allegedly infringing material as soon as is reasonably practicable. If they respond to us in writing denying the alleged infringement, we will, as soon as is reasonably practicable, send a copy of that response to you. If you do not, within a further 14 days, file an action seeking a court order against the provider of the allegedly infringing material, we may restore the material at our discretion. If you file a legal action, we will remove or block the allegedly infringing material pending resolution of that legal action.
                </Text>
                <h4>Restrictions on use</h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <h4>Prohibited conduct</h4>
                <Text>
                    Your use of this site is subject to the rules set out in Schedule 1.
                  </Text>
                <h4>Violations of these Website Terms of Use</h4>
                <Text>
                    Without limiting any other remedies available to us at law or in equity, we reserve the right to, without notice:
                  </Text>
                <Text>
                    <ul>
                        <li>
                            temporarily or indefinitely suspend, or terminate, your access to this site or refuse to provide services to you if:
                        </li>
                        <li>
                            you breach any provision of these Website Terms of Use;
                        </li>
                        <li>
                            we are unable to verify or authenticate any information that you provide to us; or
                        </li>
                        <li>
                            we believe that your actions may cause damage and/or legal liability to us, any Finder or Speaker or any other person; and

                        </li>
                        <li>
                            remove or block access to any information and/or materials (in whole or in part) that we, in our sole and absolute discretion, regard in any way to be offensive, objectionable or in violation of any applicable law, any person's intellectual property rights or these Website Terms of Use.
                        </li>
                    </ul>
                </Text>
                <h4>
                    Disclaimer of warranties
                </h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <Text>
                    To the maximum extent permitted by law, Finders Speakers and its officers, employees, agents, consultants, licensors, partners and affiliates expressly disclaim all conditions, representations and warranties (whether express or implied, statutory or otherwise) in relation to this site and any services purchased or obtained through this site, including any implied warranty/guarantee of merchantability, fitness for a particular purpose or non-infringement.
                  </Text>
                <Text>
                    This site is provided strictly on an "as is" and “as available” basis. To the maximum extent permitted by law,  Finders Speakers and its officers, employees, agents, consultants, licensors, partners and affiliates make no representation, warranty or guarantee as to the reliability, timeliness, quality, suitability, truth, availability, accuracy or completeness of this site or any of its content, and in particular do not represent, warrant or guarantee that:
                  </Text>
                <Text>
                    <ul>
                        <li>
                            the use of this site will be secure, timely, uninterrupted or error-free or operate in combination with any other hardware, software, system or data;

                        </li>
                        <li>
                            anything on this site, or on any third-party website referred or linked to in this site, is reliable, accurate, complete or up to date;
                        </li>
                        <li>
                            the quality of any services, information or other material purchased or obtained through this site will meet your or any particular requirements or expectations; or
                        </li>
                        <li>
                            this site or the servers that make it available are free of viruses or other harmful components.

                        </li>
                    </ul>
                </Text>
                <h4>
                    Limitation of liability
                  </h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <h4>Exclusion of liability</h4>
                <Text>
                    To the maximum extent permitted by law, Finders Speakers and its officers, employees, agents, consultants, licensors, partners and affiliates exclude all liability to you or any other person for any loss, cost, expense, claim or damage (whether arising in contract, negligence, tort, equity, statute or otherwise, and for any loss, whether it be consequential, indirect, incidental, special, punitive, exemplary or otherwise, including any loss of profits, loss or corruption of data or loss of goodwill) arising directly or indirectly out of, or in connection with, these Website Terms of Use or the use of this site by you or any other person.
                  </Text>
                <h4>Limited</h4>
                <Text>
                    To the maximum extent permitted by law, Finders Speakers and its officers, employees, agents, consultants, licensors, partners and affiliates expressly limit their liability for breach of any non-excludable condition or warranty/guarantee implied by virtue of any legislation to the following remedies (the choice of which is to be at our sole discretion):
                  </Text>
                <Text>
                    <ul>
                        <li>
                            the supply of our services again; or
                        </li>
                        <li>
                            the payment of the cost of having our services supplied again.
                        </li>
                    </ul>
                </Text>
                <h4>Indemnity</h4>
                <Text>
                    You indemnify and hold harmless Finders Speakers and its officers, employees, agents, consultants, licensors, partners and affiliates from and against any losses, liabilities, costs, expenses or damages (including actual, special, indirect and consequential losses or damages of every kind and nature, including all legal fees on a solicitor-client basis) suffered or incurred by any of them due to, arising out of, or in any way related to (directly or indirectly):
                  </Text>
                <Text>
                    <ul>
                        <li>
                            any material or information that you submit, post, transmit or otherwise make available through this site;
                        </li>
                        <li>
                            your use or connection to this site; or
                        </li>
                        <li>
                            your negligence or misconduct, breach of these Website Terms of Use or violation of any law or the rights of any person.
                        </li>
                    </ul>
                </Text>
                <h4>Force majeure</h4>
                <Text>
                    To the maximum extent permitted by law, and without limiting any other provision of these Website Terms of Use, we exclude liability for any delay in performing any of our obligations under these Website Terms of Use where such delay is caused by circumstances beyond our reasonable control, and we shall be entitled to a reasonable extension of time for the performance of such obligations.
                  </Text>
                <h4>
                    General
                  </h4>
                <hr style={{ borderTop: '3px solid black' }} />
                <h4>Notifications</h4>

                <Text>
                    We may provide any notification for the purposes of these Website Terms of Use by email.
                  </Text>
                <h4>Assignment</h4>
                <Text>
                    You may not assign, transfer or sub-contract any of your rights or obligations under these Website Terms of Use without our prior written consent.
                  </Text>
                <Text>
                    We may assign, transfer or sub-contract any of our rights or obligations under these Website Terms of Use at any time without notice to you.
                  </Text>
                <h4>No waiver</h4>
                <Text>
                    Waiver of any power or right under these Website Terms of Use must be in writing signed by the party entitled to the benefit of that power or right and is effective only to the extent set out in that written waiver. Any failure by us to act with respect to a breach by you or others does not limit our right to act with respect to that breach or any subsequent or similar breaches.
                  </Text>
                <h4>Severability</h4>
                <Text>
                    The provisions of these Website Terms of Use are severable and, if any provision of these Website Terms of Use is held to be illegal, invalid or unenforceable under any present or future law, such provision may be removed and the remaining provisions shall be enforced.
                  </Text>
                <h4>Variation</h4>
                <Text>
                    We reserve the right to amend these Website Terms of Use and any other policy on this site at any time in our sole discretion and any such changes will, unless otherwise noted, be effective immediately. Your continued usage of this site will mean you accept those amendments. We reserve the right, without notice and at our sole discretion, to change, suspend, discontinue or impose limits on any aspect or content of this site or the services offered through this site.
                  </Text>
                <Text>
                    You may only vary or amend these Website Terms of Use by written agreement with us.
                  </Text>
                <h4>Governing law and jurisdiction</h4>
                <Text>
                    These Website Terms of Use will be governed in all respects by the laws of New South Wales, Australia. The parties irrevocably submit to the non-exclusive jurisdiction of the courts of New South Wales and the courts of appeal from them.
                  </Text>
                <h4>Feedback</h4>
                <Text>We welcome and encourage you to provide feedback, comments and suggestions for improvements to the site (“Feedback “). You may submit Feedback by emailing us hello@findersspeakersco.com.  Any Feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting Feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.
                  </Text>
                <h2>Schedule 1 – Prohibited conduct</h2>
                <h4>YOU MUST NOT:</h4>
                <Text>
                    <ul>
                        <li>
                            use any device, routine or software that interferes, or attempt to interfere, with the proper working of this site;
                        </li>
                        <li>
                            use this site to decipher passwords or security encryption codes, transmit any worms, viruses or Trojan horses, transfer or otherwise violate the security of any computer network;

                        </li>
                        <li>
                            use this site to violate any applicable local, state, national or international law, to engage in any misleading or deceptive online marketing practices or for any fraudulent or malicious purposes;

                        </li>
                        <li>
                            use any spider, robot or search/retrieval application or any screen scraping, data mining or similar data gathering device, process, program or means to access, retrieve or index any portion of this site;

                        </li>
                        <li>
                            use this site to transmit junk mail, spam or chain letters or pyramid schemes or engage in other flooding techniques or mass distribution of unsolicited email;

                        </li>
                        <li>
                            access, retrieve or index any portion of this site for use in constructing or populating any database that is searchable online or for the purpose of soliciting or sharing reviews;

                        </li>
                        <li>
                            reverse engineer, decompile, disassemble, adapt, modify, translate, frame or reformat any of the material contained on this site;

                        </li>
                        <li>
                            use or exploit any of the material appearing on this site for, or in connection with, any business or enterprise (whether for profit or otherwise), including any business or enterprise that is in competition with this site, or falsely imply that any other website is associated with this site

                        </li>
                        <li>
                            use this site to transmit any information or material that is, or may reasonably be considered to be:

                        </li>
                        <li>
                            abusive, threatening, harassing, harmful, obscene, indecent, lewd, inflammatory, violent, vulgar, profane, racially, ethnically or otherwise objectionable or offensive in any way;

                        </li>
                        <li>
                            libellous, defamatory, pornographic, sexually explicit, unlawful or plagiarised;

                        </li>
                        <li>
                            infringing upon or violating any copyright, trademark, patent or other intellectual property or proprietary right;
                        </li>

                        <li>in breach of any duty of confidentiality by which you are bound, fiduciary or contractual;
                        </li>
                        <li>in breach of any person’s privacy or publicity rights;
                        </li>
                        <li>a misrepresentation of facts, including the impersonation of any person or entity or a misrepresentation of an affiliation with any person or entity (including any sponsorship or endorsement);
                        </li>
                        <li>in violation of any applicable law, statute, ordinance or regulation, or encouraging of others to do so;
                        </li>
                        <li>containing any political campaigning material, advertisements or solicitations; or</li>
                        <li>likely to bring Finders Speakers or any of its staff into disrepute.</li>
                    </ul>
                </Text>
                <Text>

                </Text>
            </AnimatedOnScroll>
        </PrivacyContainer>
    </>
    )
        ;
};

export default TermsConditions;