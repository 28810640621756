import React from 'react';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import styles from './styles.module.scss';

const errorPositions = {
  UP: 'up',
  DOWN: 'down',
  NONE: 'none'
};

const BaseInput = ({ InputProps = { classes: {} }, error, errorPosition = errorPositions.UP, ...other }) => {

  return (
    <div className={styles.inputFieldWrapper}>
      {
        errorPosition === errorPositions.UP && (
          <div className={styles.errorTextUp}>
            {error}
          </div>
        )
      }

      <TextField
        {...other}
        size={'small'}
        classes={{
          root:  classNames(styles.inputField, { [styles.inputFieldError]: error })
        }}
        variant="outlined"
        InputProps={{
          ...InputProps,
          classes: {
            root: styles.inputRoot,
            focused: styles.inputFieldFocused,
            ...InputProps.classes
          }
        }}
      />

      {
        errorPosition === errorPositions.DOWN && (
          <div className={styles.errorTextDown}>
            {error}
          </div>
        )
      }
    </div>
  );
};

export default BaseInput;
