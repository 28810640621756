export const filterBudgetOptions = [
  { value: 0, from: 0,  to: 999999, label: 'Any budget' },
  { value: 1, from: 0, to: 5000, label: 'Less than $5k' },
  { value: 2, from: 5000, to: 10000, label: '$5k - $10k' },
  { value: 3, from: 10000, to: 25000, label: '$10k - $25k' },
  { value: 4, from: 25000, to: 50000, label: '$25k - $50k' },
  { value: 5, from: 50000, to: 999999, label: '$50k +' },
];

export const filterGenderOptions = [
  { value: 0, label: 'Male' },
  { value: 1, label: 'Female' },
  { value: 2, label: 'Other' },
];
