import { createStore, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { saveState, loadState } from '../helpers/localStorage';
import ApiDataProvider from '../api/apiClient';

// save token to local storage
const persistedState = {
    auth: {
        isAuthenticated: ApiDataProvider.isAuthenticated()
    },
    user: {
        token: ApiDataProvider.token
    }
};

const sagaMiddleware = createSagaMiddleware();
const enhancer = applyMiddleware(sagaMiddleware);

const initialState = rootReducer(undefined, {});

const hydratedState = {
    ...initialState,
    auth: {
        ...initialState.auth,
        ...persistedState.auth
    },
    user: {
        ...initialState.user,
        ...persistedState.user
    }
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: hydratedState,
    enhancers: [
        enhancer
    ]
});

sagaMiddleware.run(rootSaga);

export default store;
