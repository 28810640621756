import React from 'react';
import styled from "styled-components";
import { HowItWorksTitle } from "./HowItWorksTitle";
import mobile from "../assets/redesign/mobile.png";
import tablet from "../assets/redesign/tablet.png";
import howItWorks1 from '../assets/how-it-works-1.png';
import howItWorks2 from '../assets/how-it-works-2.png';
import howItWorks3 from '../assets/how-it-works-3.png';

export const HowItWorksProcess = () => {
  return (
    <SpeakersProcess>
      <section className="process">
        <div className="container">
          <HowItWorksTitle
            text={'We’ve got your next event covered'}
            maxWidth={{ desktop: '520px', mobile: '100%' }}
            marginBottom={{ desktop: '75px', mobile: '40px' }}
            parentName={'process'}
          />
          <div className={`process__row process__row_1`}>
            
              <div className="process__img">
                <img src={howItWorks1} />
              </div>
            <div className="process__info">
              <h3
                className="process__info-title"
              >
                <span className="accent">Easily</span> search top curated talent, fast
                                </h3>
              <div className="process__info-text">
                <p>Access leading voices, purpose-led storytellers, changemakers and innovators.</p>
                <p>Our market leading profiles bring speakers stories to life, helping you find the best match.</p>
                <p>No subscriptions or upfront fees, sign up and start searching.</p>
              </div>
            </div>
            
          </div>
          <div className={`process__row process__row_2`}>
            <div className="process__info">
              <h3
                className="process__info-title"
              >
                <span className="accent">Engage</span> direct for events<span className="d-block"> and collaborations</span>
              </h3>
              <div className="process__info-text">
                <p>Engage directly for clearer communication, quicker decisions</p>
                <p>Work closely with speakers to optimise event brief and outcomes</p>
                <p>Develop long term relationships and strategic partnerships</p>
              </div>
            </div>
            <div className="process__img">
                <img src={howItWorks2} />
              </div>
          </div>
          <div className={`process__row process__row_3`}>
              <div className="process__img">
                <img src={howItWorks3} />
              </div>
            <div className="process__info">
              <h3 className="process__info-title">
                Manage and deliver <span className="accent d-inline-block">a wow event!</span>
              </h3>
              <div className="process__info-text">
                <p>Work smarter, with all enquiries, bookings, contracts, invoices and payments managed in one place.</p>
                <p>Deliver events that drive conversations, ideas and actionable insights.</p>
                <p>Boost brand recognition, engagement and customer satisfaction.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SpeakersProcess>
  )
};

const SpeakersProcess = styled.section`
    .container {
      min-width: 1240px;
    }

    .process__row {
      display: flex;
      justify-content: space-between;
    }

    background: #EAEBED;
    .process {
        padding: 70px 0 13px;
    }
    .accent {
      color: #20D5C4;
    }
    .number {
        font-style: normal;
        font-weight: 600;
        font-size: 300px;
        line-height: 1;
        color: #fff;
        position: absolute;
    }
    .sm-visible {
      display: none;
    }
    .process__row {
      display: flex;
      padding-bottom: 78px;
    }
    .process__row_1 {
        .process__info {
          text-align: left;
        }
        .process__info-text {
          margin-right: 40px;
        }
        .number {
            display: none;
            top: -58px;
            right: 117px;
            z-index: 2;
        }
        .process__img {
          padding-left: 25px;
          text-align: right;
          &::after,
           &::before {
                display: none;
                content: '';
                position: absolute;
                border-radius: 50%;
                background-color: #20D5C4;
                z-index: 0;
              }
              &::after {
                  width: 50px;
                  height: 50px;
                  bottom: 57px;
                  left: 92px;
              }
              &::before {
                width: 22px;
                height: 22px;
                top: 129px;
                left: 79px;
              }
        }
      }
    .process__row_2 {
      margin-top: 70px;

    .process__info {
      text-align: right;
      margin-left: 50px;
      margin-top: 30px;
      max-width: 420px;
    }
    .number {
      display: none;
      position: absolute;
      top: -22px;
      left: 28px;
    }

    .process__img {
      text-align: right;

      &::after,
       &::before {
            display: none;
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: #293749;
            z-index: 0;
          }
          &::after {
              width: 50px;
              height: 50px;
              top: 10px;
              right: 94px;
          }
          &::before {
            width: 22px;
            height: 22px;
            top: -14px;
            right: 67px;
          }
      }
    }
    .process__row_3 {
      margin-top: 100px;
      padding-bottom: 0;

    .process__info {
      text-align: left;
      max-width: 376px;
      margin-top: 30px;
    }
    .process__info-title {
      color: #19222D;
      margin-right: 15px;
      margin-bottom: 15px;
    }
    .process__info-text {
      margin-right: 0;
    }
    .number {
      display: none;
      position: absolute;
      top: 0;
      right: -4px;
      z-index: 1;
    }
    .process__img {
      text-align: right;
      
      &::after,
       &::before {
            display: none;
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: #E8388A;
            z-index: 0;
          }
          &::after {
              width: 50px;
              height: 50px;
              left: 177px;
              top: 5px;
            
          }
          &::before {
            width: 22px;
            height: 22px;
            top: -20px;
            left: 223px;
          }
      }
    }
    .process__info-text{
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        
        p {
            color: #293749;
            margin-bottom: 10px;
            font-weight: 300;
            font-size: 18px;
            line-height: 24px;
        }
    }
    .process__info{
        max-width: 430px;
        padding: 10px 0 15px 17px;
        
        &-title {
          margin-bottom: 10px;
          font-size: 32px;
          line-height: 36px;
          font-weight: 500;
        }
    }
    .process__img {
      flex-basis: 50%;
      position: relative;

      img {
        max-width: 100%;
      }
    }

    .process__img--big {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      position: absolute;
      border-radius: 3px;
    }
    .process__img--small {
      width: 215px;
      height: 290px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      position: absolute;
      border-radius: 3px;
    }
    
    @media (max-width: 1024px) {
      .container {
        min-width: 200px;
      }

      .sm-visible {
        display: block;
      }
      .process {
        padding: 40px 0 20px;
        
          .container {
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 20px;
              padding-right: 20px;
          }
      }
      
      .process__info-text p {
          font-size: 16px;
          line-height: 22px;
      }

      .process__row {
        max-width: 369px;
        margin: 0 auto;
        display: block;
      }
      
      .process__img {
          width: 320px;
          margin: auto;
          height: 214px;
      }
      .process__info {
        width: 320px;
        padding: 0;
        margin: 0;
      }
      .process__info-title {
        font-size: 26px;
        line-height: 28px;
      }
      
      .number {
        font-size: 190px;
      }
      .process__row_1 {
        .process__info-text {
          margin-right: 0;
        }
        .number {
            top: -28px;
            right: 66px;
            z-index: 2;
          }
          
        .process__img {
          &::after {
            width: 30px;
            height: 30px;
            bottom: 75px;
            left: 7px;
          }
          &::before {
            width: 12px;
            height: 12px;
            left: 0;
            top: 82px;
          }
      }
        .process__img--big {
            top: 3px;
            left: 14px;
            width: 228px;
            height: 193px;
        }
        .process__img--small {
            width: 114px;
            height: 203px;
            top: -10px;
            left: 201px;
        }
      }
      .process__row_2 {
          display: flex;
          flex-direction: column;
        .process__img {
          order: 1;
          flex-basis: 186px;
          z-index: 2;
        }
        .process__info {
          order: 2;
          margin-left: 0;
          text-align: left;
          max-width: initial;
        }
          .number {
            font-size: 175px;
            top: -37px;
            right: initial;
            left: 0;
            z-index: 1;
          }
 
        .process__img {
          &::after {
            width: 30px;
            height: 30px;
            bottom: inherit;
            top: -20px;
            left: initial;
            right: 30px;
                      }
          &::before {
            width: 12px;
            height: 12px;
            left: initial;
            top: -32px;
            right: 16px;
          }
      }
        .process__img--big {
            top: -24px;
            left: inherit;
            width: 228px;
            height: 193px;
        }
        .process__img--small {
            width: 114px;
            height: 203px;
            top: -17px;
            right: inherit;
            left: 180px;
        }
          
      }
      .process__row_3 {
          .number {
            font-size: 175px;
            top: -33px;
            right: -5px;
            z-index: 3;
          }
          .process__img {
          height: 181px;
          &::after {
            width: 30px;
            height: 30px;
            bottom: initial;
            left: 62px;
            top: -29px;
          }
          &::before {
            width: 12px;
            height: 12px;
            left: 90px;
            top: -43px;
          }
      }
        .process__img--big {
            top: -29px;
            left: -8px;
            width: 240px;
            height: 193px;
        }
        .process__img--small {
            width: 114px;
            height: 203px;
            top: -27px;
            left: 150px;
        }   
      }
    }
`;