import s from './styles.module.scss';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import { resetPassword } from '../api/helperFunctions';
import logoWhite from '../assets/logo_light_new.svg';

const SuccessModal = ({ open, onConfirm }) => {
  return <Modal open={open}>
    <div className={s.resultModalContent}>
      <h1 className={s.resultModalHeader}>
        Success! Your password has been reset
      </h1>
      <div className={s.resultModalAction}>
        <button className={s.resultModalActionButton} onClick={() => onConfirm()}>OK</button>
      </div>
    </div>
  </Modal>;
};

export default () => {
  const { token } = useParams();
  const history = useHistory();
  const [ inputEmail, setInputEmail ] =  useState('');
  const [ inputPassword, setInputPassword ] =  useState('');
  const [ confirmPassword, setConfirmPassword ] =  useState('');
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const inputChangeHandler = event => {
    const { name, value } = event.target;

    switch (name) {
      case 'email':
        setInputEmail(value);
        return;
      case 'password':
        setInputPassword(value);
        return;
      case 'confirm_password':
        setConfirmPassword(value);
        return;
    }

    setErrorMessage('');
  }

  const submitHandler = event => {
    event.preventDefault();

    resetPassword(inputEmail, inputPassword, confirmPassword, token)
      .then(result => {
        if (!result.status) {
          throw new Error(result.message);
        }
        setModalOpen(true);
      })
      .catch(err => {
        setErrorMessage(err.message);
      });
  };

  const redirectToMain = () => {
    history.push('/');
  };

  return <div className={s.container}>
    <SuccessModal open={modalOpen} onConfirm={redirectToMain}/>

    <div className={s.header}>
      <a href="/"><img src={logoWhite} /></a>
    </div>
    <div className={s.formWrapper}>
      <h1 className={s.formTitle}>Reset password</h1>
      <div className={s.formDescription}>
        One lowercase character |
        8 characters minimum |
        One special character |
        One uppercase character |
        One number
      </div>
      <form onSubmit={submitHandler}>
        <div className={s.errorField}>
          {errorMessage}
        </div>
        <div className={s.inputWrapper}>
          <input
            className={s.inputField}
            type="email"
            name="email"
            value={inputEmail}
            placeholder="Email"
            onChange={inputChangeHandler}
          />
        </div>
        <div className={s.inputWrapper}>
          <input
            className={s.inputField}
              type="password"
              name="password"
              value={inputPassword}
              placeholder="New password"
              onChange={inputChangeHandler}
            />
        </div>
        <div className={s.inputWrapper}>
          <input
            className={s.inputField}
            type="password"
            name="confirm_password"
            value={confirmPassword}
            placeholder="Confirm password"
            onChange={inputChangeHandler}
          />
        </div>
        <div>
          <input className={s.submitButton} type="submit" value="Submit" value="Reset" />
        </div>
      </form>
    </div>
  </div>;
};
