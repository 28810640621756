import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import BaseInput from '../../../BaseInput';
import styles from './styles.module.scss';

const TextInput = props => <BaseInput {...props} />;

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#20D5C4'
      }
    }
  },
});

/**
 value = 'yyyy-mm-dd'
 */
export default ({ value, onChange, error, onError, disabled }) => {
  const [date, setDate] = useState(value);

  return (
    <div className={styles.wrapper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            format="MM/dd/yyyy"
            value={date}
            onChange={(d, str) => {
              setDate(d);
              if (!d || d.toString() === 'Invalid Date') {
                onChange(null);
              } else {
                const [month, day, year] = str.split('/');
                onChange(`${year}-${month}-${day}`);
              }
            }}
            disabled={disabled}
            PopoverProps={{
              onClick: e => e.stopPropagation()
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              classes: {
                root: styles.button
              }
            }}
            TextFieldComponent={TextInput}
            onClick={e => e.stopPropagation()}
            minDate={disabled ? null : new Date()}
            error={error}
            errorPosition={error === 'Required' ? 'down' : 'none'}
            onError={onError}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};
