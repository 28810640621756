import React from 'react';
import styles from '../BookingForm.module.scss';

export default function BookingFormAdditionalNotes({ notes, deleteNotesHandler, editNotesHandler, disabled }) {

  return (
    <div
      className={styles.additional_notes}
      style={{
        marginTop: '20px'
      }}
    >
      <h5 className={styles.subheading}>Additional notes to the brief</h5>
      <div className={styles.additional_notes_text}>
        <div>
          {notes}
        </div>

      </div>
      {
        !disabled && (
          <div className={styles.modalButtonsGroup}>
            <span
              onClick={() => deleteNotesHandler()}
              className={styles.read_button}
              style={{
                marginRight: '10px'
              }}
            >
              Delete
            </span>
                <span
                  onClick={() => editNotesHandler()}
                  className={styles.read_button}
                >
              Edit
            </span>
          </div>
        )
      }
    </div>
  );
}