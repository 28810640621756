import React from 'react';
import useInsertScript from '../helpers/useInsertScript';
import s from './styles.module.scss';


const ThankYouPage = () => {
  useInsertScript(
    process.env.REACT_APP_GTM && 'https://www.googletagmanager.com/gtag/js?id=AW-11003135842',
    true
  );
  useInsertScript(process.env.REACT_APP_GTM && '/google-tracker.js');
  
  return (
    <div className={s.container} style={{
      background: `#161E27 url(${require('../assets/footer-bg.webp')})`,
      backgroundSize: 'cover'
    }}>
      <h1>Thank you <br /> <span className={s.green}>for your enquiry!</span></h1>
      <p>We will be in touch shortly.</p>
    </div>
  );
};

export default ThankYouPage;
