import React, { useEffect, useState, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import ClearIcon from '@material-ui/icons/Clear';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';

import ApiDataProvider from '../../api/apiClient';
import { usePageParam } from '../hooks';
import DownloadButton from '../components/DownloadButton';
import ConfirmationModal from '../components/ConfirmationModal';
import styles from './AdminPanelBusinesses.module.scss';

const tableHeadCells = [
  {
    label: 'Business Name',
    fieldName: 'business_name',
    className: styles.colWidth20,
    sortable: true
  },
  {
    label: 'First Name',
    fieldName: 'first_name',
    className: styles.colWidth10,
    sortable: true
  },
  {
    label: 'Last Name',
    fieldName: 'last_name',
    className: styles.colWidth10,
    sortable: true
  },
  {
    label: 'Email',
    fieldName: 'email',
    className: styles.colWidth20,
    sortable: true
  },
  {
    label: 'Total # of bookings',
    fieldName: 'total_bookings',
    sortable: false
  },
  {
    label: 'Total $ value of bookings',
    fieldName: 'total_amount',
    sortable: false
  }
];

const searchFilter = (search, business) => {
  if (!search) {
    return true;
  }
  const r = new RegExp(search, 'i');
  return (
    r.test(business.business_name) || r.test(business.first_name) ||
    r.test(business.last_name) || r.test(business.email)
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={styles.paginationContainer}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export const AdminPanelBusinesses = () => {
  const [page, setPage] = usePageParam();
  const debounced = useRef(null);
  const modalConfirmHandler = useRef(() => Promise.resolve());

  const [items, setItems] = useState([]);

  const [sort, setSort] = useState();
  const [order, setOrder] = useState('asc');
  const [search, setSearch] = useState('');

  const [searchStringInput, setSearchStringInput] = useState('');

  const [total, setTotal] = useState(0);

  const [modalText, setModalText] = useState('');
  const [actionError, setActionError] = useState('');

  const changePageHandler = (_, value) => {
    setPage(value + 1);
  };

  const load = useCallback(() => {
    ApiDataProvider.fetchBusinessesAdmin(page, sort, order).then(({ data, meta }) => {
      setItems(data);
      setTotal(meta.total);
      setSearch('');
      setSearchStringInput('');
    }).catch(e => console.error(e));
  }, [ page, sort, order ]);

  const changeSortParams = field => {
    const dropOrderDirection = sort !== field;

    setSort(field);

    if (order === 'asc' && !dropOrderDirection) {
      setOrder('desc');
    } else {
      setOrder('asc');
    }
  };

  const createSortHandler = field => {
    return () => changeSortParams(field);
  };

  const onSearchInputChangeHandler = e => {
    const newValue = e.target.value;
    setSearchStringInput(newValue);

    debounced.current(newValue);
  };

  const verifyHandler = (id, name) => {
    setModalText(`Verify ${name}?`);
    setActionError('');
    modalConfirmHandler.current = () => {
      return ApiDataProvider.verifyFinderAdmin(id).then(() => {
        setModalText('');
        load();
      }).catch(e => {
        setActionError(e.message);
      });
    };
  };

  const deleteHandler = (id, name) => {
    setModalText(`Delete ${name}?`);
    setActionError('');
    modalConfirmHandler.current = () => {
      return ApiDataProvider.deleteFinderAdmin(id).then(() => {
        setModalText('');
        load();
      }).catch(e => {
        setActionError(e.message);
      });
    };
  };

  useEffect(() => {
    load();
  }, [page, sort, order]);


  useEffect(() => {
    debounced.current = debounce(newValue => {
      setSearch(newValue);
      // setPage(1);
    }, 0);
  }, []);

  const downloadCsvHandler = () => {
    ApiDataProvider.getFindersCsvAdmin().then(result => {
      const blob = new Blob([result], {type: 'application/csv'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Businesses ${new Date().toLocaleString()}.csv`;
      link.click();
    }).catch(e => console.error(e));
  };

  const emptyRows = 10 - items.length;

  return (
    <div className={styles.main}>
      <h1>Businesses</h1>

      <Paper classes={{
        root: styles.paper
      }}>
        <div className={styles.searchSection}>
          <DownloadButton onClick={downloadCsvHandler} />
          <TextField
            type={'text'}
            placeholder={'Search'}
            value={searchStringInput}
            onChange={onSearchInputChangeHandler}
          />
        </div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                  tableHeadCells.map(({ fieldName, label, className, sortable }) => (
                    <TableCell
                      key={fieldName}
                      className={className}
                    >
                      {
                        sortable ? (
                          <TableSortLabel
                            active={sort === fieldName}
                            direction={order}
                            onClick={createSortHandler(fieldName)}
                          >
                            { label }
                          </TableSortLabel>
                        ) : label
                      }
                    </TableCell>
                  ))
                }
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                items.filter(business => searchFilter(search, business)).map(business => (
                  <TableRow key={business.id}>
                    <TableCell>{ business.business_name }</TableCell>
                    <TableCell>{ business.first_name }</TableCell>
                    <TableCell>{ business.last_name }</TableCell>
                    <TableCell>{ business.email }</TableCell>
                    <TableCell>{ business.total_bookings }</TableCell>
                    <TableCell>{ business.total_amount.toFixed(2) }</TableCell>
                    <TableCell>
                      <div className={styles.actionsCellContent}>
                        {
                          business.is_verified ? (
                            <span title="Verified"><DoneIcon /></span>
                          ) : (
                            <span
                              className={styles.verifyButton}
                              onClick={() => verifyHandler(business.id, business.business_name)}
                              title="Verify"
                            ><ThumbUpIcon /></span>
                          )
                        }

                        <span
                          className={styles.deleteButton}
                          onClick={() => deleteHandler(business.id, business.business_name)}
                          title="Delete"
                        ><ClearIcon /></span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              }

              {emptyRows > 0 && (
                <TableRow style={{ height: 57 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={7}
                  count={total}
                  rowsPerPage={10}
                  page={page - 1}
                  onChangePage={changePageHandler}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>

      <ConfirmationModal
        isOpen={modalText}
        text={modalText}
        errorMessage={actionError}
        onCancelClick={() => setModalText('')}
        onConfirmClick={modalConfirmHandler.current}
      />
    </div>
  );
};
