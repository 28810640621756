import React from 'react';
import styles from '../../BookingForm.module.scss';

export default function BookingFormFinderAdditionalNotes({ notes}) {
  if (notes.length === 0) {
    notes.push('Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, architecto beatae cumque, distinctio doloremque enim error expedita hic nesciunt officiis provident quae quas quis sunt totam ut vitae? Cum, doloribus.');
  }

  return (
    <div
      className={styles.additional_notes}
      style={{
        marginTop: '20px'
      }}
    >
      <h5 style={{fontWeight: '600'}}>Additional notes to the brief</h5>
      <div className={styles.additional_notes_text}>
        <div className={styles.additional_notes_textArea}>
          {[...notes]}
        </div>
      </div>
    </div>
  );
}