import React from 'react';
import { Item, PrivacyContainer } from './PrivacyPolicy'
import { FAQHero } from "../FAQPage/FAQHero";
import { AnimatedOnScroll } from 'react-animated-css-onscroll'
import { Link } from "react-router-dom";
import styles from './styles.module.scss';
import useBlockCrawling from '../helpers/useBlockCrawling';


const Text = ({ children }) => <p className={styles.block}>{children}</p>;

const TermsConditions = () => {
  useBlockCrawling();

    const policyText = [
        {
            id: 1,
            question: '',
            answer: (<Item>

            </Item>)
        }
    ]

    return (<div className={styles.speakerTerms}>
        <FAQHero title={<span>Speakers Service <br /> Terms and Conditions</span>}
        // subHeader={<Text color={'#fff'}>Last updated 9.03.2020</Text>}
        />


        <PrivacyContainer className={'container'}>
            <AnimatedOnScroll animationIn="fadeInLeft" animationInDelay={200} animationInDuration={1500}
                screenOffset={250}>

                <Text>
                  This software license and online services agreement (<strong>“Agreement”</strong>) constitutes
                  a legal agreement between you, the speaker whether an individual or an incorporated entity
                  (<strong>“Speaker, you”</strong>) and Finders Speakers Pty Ltd (ABN 89 630 096 730)
                  (<strong>"we", "us" or "Finders Speakers"</strong>)
                </Text>

                <Text>
                  Finders Speakers provides an all-in-one marketing, booking, management and collaboration
                  platform for speakers, event organisers and other industry participants. By accessing or
                  using any of the Services via the Platform, you agree to the terms and conditions
                  below and that you wish to enter into this Agreement for the purpose of accessing
                  the platform and using the Services, as a Speaker.
                </Text>

                <Text>
                  The Agreement should be read in conjunction with the Finders Speakers Website Terms of Use,
                  Code of Conduct and Privacy Policy and where they are inconsistent this Agreement will take precedence.
                </Text>
                <h2>1. Definitions</h2>
                <Text>
                    <ol>
                        <li>
                            <strong>“Booking Agreement”</strong> the template booking agreement form provided via the Platform or your own terms and conditions you have executed and attached to the Offer Form.
                        </li>
                        <li>
                            <strong>“Finders Speakers Data”</strong> means all data related to the access and use of the Services and Platform, including all data related to Finders and Speakers.
                        </li>
                        <li>
                            <strong>“Offer Form”</strong> means the offer form drafted and submitted by the Speaker in response to an enquiry from a Finder on the Platform.
                        </li>

                        <li>
                            <strong>“Platform”</strong> means (1) the website provided by us that enables Speakers and Finders to access the Services for the purposes of seeking, receiving, offering, booking, and fulfilling requests for speaking services, as updated or modified from time to time; and (2) all other related websites, mobile applications, software, or other functionality made available to you online.
                        </li>

                        <li>
                            <strong>“Services”</strong> means our lead generation and related services that enable Finders and Speakers to seek, receive, offer, book and fulfill requests for speaking services, which services include the Platform, payment services as described in Section 4 below, and related support services systems, as may be modified from time to time.
                        </li>

                        <li>
                            <strong>“Speaker(s)”</strong> means individuals, such as speakers, trainers, consultants, coaches, or similar individuals, who are qualified and accepted by us to provide speaking services to Finders to be marketed and booked via the Platform.
                        </li>

                        <li>
                            <strong>“Speaker Contract”</strong> means the contract formed between the Speaker and the Finder when the Finder accepts the Offer Form on the Platform and incorporates the terms and conditions of the attached Booking Agreement.
                        </li>

                        <li>
                            <strong>“Speakers Fees”</strong> means the amount set out in the Offer Form, which is to be paid via the Platform by the Finder to the Speaker.
                        </li>

                        <li>
                            <strong>“Speakers ID”</strong> means the email address and password key that enables you to use and access the Platform and Services.
                        </li>

                        <li>
                            <strong>“Finder”</strong> means an end user registered to use the Platform for the purpose of obtaining speaking services offered by Speakers.
                        </li>

                        <li>
                            <strong>“Finder Information”</strong> means information about a Finder made available to you in connection with their enquiry for and use of your services, which may include the Finder’s name, organisation, Speaker job brief, location, and budget.
                        </li>

                        <li>
                            <strong>"Speaker Information”</strong> means information about you on the Platform in your profile or made available to a Finder in connection with their request for and the use of your services, which may include your name, speaker story, expertise, events experience, head shot photo, show reel video, location and fees.
                        </li>
                    </ol>
                </Text>

                <h2>2. Use of the Services</h2>

                <Text>
                  <ol>
                    <li>
                      <strong>Speaker IDs.</strong> Once your account is registered, your Speaker ID will enable you to access and use the Platform in accordance with this Agreement. You are solely responsible for the use and security of your Speaker ID.
                    </li>

                    <li><strong>Provision of Speaking Services.</strong> You agree
                      to create and publish a profile. When your
                      profile is active it is made public to registered Finders who may send you
                      a direct enquiry for your speaking services. The enquiry will include
                      the Finder’s Information including job brief. We ask that you
                      access the Platform regularly to check for requests from Finders
                      and respond promptly. Should you accept the Finders enquiry
                      you will start the online chat function to have a conversation
                      or decline the enquiry. If you and the Finder agree to proceed
                      to the booking stage, you are responsible for drafting and
                      submitting the Offer Form on the Platform. The Finder will review
                      the Offer Form and approve or decline it. You can edit the Offer Form
                      until you finalise and agree the details. You are both responsible for
                      agreeing a fee, any incremental costs, and any taxes as applicable which
                      will be deducted in the manner set out in clause 7. To complete the
                      booking and form a Speaker Contract the Finder will accept the Offer
                      Form and the attached Booking Agreement on the Platform. As between us
                      and you, you agree that: (a) you shall be solely responsible for determining
                      with the Finder the Speaker Fee and the most effective, efficient and
                      safe manner to perform all speaking services; provided that you both will
                      comply with the Community Code of Conduct and (b) that you may not get a
                      substitute to provide your speaking services.</li>

                    <li><strong>Your Relationship with Finders.</strong> You agree that you will enter a direct Speaker Contract with Finders governing your relationship with them. You are solely responsible for understanding the terms of your Speaker Contract. We provide a template Booking Agreement form merely as an aid, but we are not a party to your Speaker Contract. You acknowledge and agree that you have sole responsibility for any obligations or liabilities to other parties that arise from your Speaker Contract. We are not responsible or liable for the actions or inactions of a Finder or you in relation to your activities. You acknowledge and agree that you are solely responsible for taking such precautions as may be reasonable and proper (including but not limited to maintaining adequate insurance that meets the requirements of all applicable laws) regarding your performance under a Speaker Contract and property.</li>

                    <li><strong>Your Relationship with Finders Speakers.</strong> You acknowledge and agree that our providing you the Services creates a direct business relationship between you and us as independent contractors, and no employment relationship, joint venture, partnership, or other agency relationship exists between us. We do not, and shall not be deemed to, direct or control you generally or in your performance under this Agreement, including in connection with your provision of speaking services or acts or omissions in that regard. You acknowledge and agree that you have complete discretion to provide services or otherwise engage in other business or employment activities. You retain the sole right to determine when and for how long you will utilize the Services or accept bookings, but you must comply with this Agreement, our current policies, and Community Code of Conduct. We retain the right in our sole discretion at any time, to deactivate or otherwise restrict you from accessing or using the Services in the event of a breach of this Agreement, your disparagement of Finders Speakers, or your act or omission that causes harm to our brand, reputation or business, or for any other reason in the our reasonable discretion.</li>

                    <li><strong>Feedback.</strong>
                      <ol>
                        <li>You acknowledge and agree that after successfully completing a speaking engagement, a Finder will be prompted by the Platform to provide feedback about your speaking services and that we have no liability with respect to comments or feedback left by Finders. You can choose whether any feedback is published on the Platform or not.</li>
                        <li>You acknowledge and agree that our intention is for all Finders and Speakers to have access to high-quality services via the Platform, and that should we receive repeated negative feedback we reserve the right to deactivate your access to the Platform and the Services.</li>
                        <li>Subject to clause 5.1 we reserve the right to use, share and display Finders feedback and comments in any manner in connection with our business without compensation and or attribution to the Finder or you. You acknowledge and agree that we are a distributor (without any obligation to verify) and not a publisher of Finder feedback and comments, provided that we reserve the right to edit or remove feedback and comments in the event that such reviews and comments include objectionable content, violate any privacy laws, or other applicable laws, in accordance with the Finders Speakers Website Terms of Use or our Community Code of Conduct.</li>
                      </ol>
                    </li>

                    <li><strong>You and Your Obligations</strong>
                      <ol>
                        <li><strong>Your Requirements.</strong> You acknowledge and agree that at all times, that you possess the appropriate and up to date level of training, expertise, and experience to provide speaking services in a professional manner with due skill, care and diligence. You will also conduct yourself in accordance with a high standard of professionalism, service, and courtesy in accordance with our Community Code of Conduct. You may be subject to certain background and other record checks by us from time to time to qualify to provide and remain eligible to provide, speaking services via the Platform. You acknowledge and agree that we reserve the right, at any time in our sole and absolute discretion, to deactivate or otherwise restrict you from accessing or using the Services if you fail to meet the requirements set forth in this Agreement.</li>
                        <li><strong>Documentation.</strong> Representations you make regarding your qualifications and expertise are expected to be truthful and accurate. In the event of any third-party claims, you agree at any time to corroborate your experience and if required provide proof including written copies of your qualifications, training, experience, or expertise, relevant to your area of specialization.</li>
                        <li><strong>Continuing Relationship.</strong> You acknowledge and agree that your obligation to use the Services to serve Finders introduced via the Platform, as well as any third parties they may refer to you, shall be continuing whilst you use the Platform. After a Finder’s initial use of your speaking services, then all subsequent uses of your speaking services by Finders and referrals shall be processed through the Platform, and you shall not attempt to circumvent the payment of the Platform Fees by accepting payment outside of the Platform. If we learn you are breaching this provision, we may immediately terminate this Agreement and obtain compensation for Services rendered as if you had used the Platform to obtain the speaking services, as well as any additional remedies provided by law.</li>
                      </ol>
                    </li>

                    <li><strong>Financial Terms</strong>
                      <ol>
                        <li><strong>Speaker Fee.</strong> Speakers are entitled to charge a Speaker Fee, for each instance of speaking services provided to a Finder that are obtained via the Platform. The Speaker Fee shall be agreed between Finders and you under the Speaker Contract. You shall be responsible for including all duty and taxes applicable to the Speaker Fee. Unless otherwise agreed, the Speaker Fee is to be paid via the Platform in two equal instalments using the third-party payment provider. The first 50% payment is deducted from the Finders account the day the Speaker Contract is confirmed and paid to the Speaker to secure their speaking services. This payment is non-refundable. The second 50% payment of the Speaker Fee is deducted from the Finders account on or before the day of the event. Any incremental costs and expenses or other reimbursements will be paid in the second payment.</li>
                        <li><strong>Third party Payment Provider.</strong> All Speaker Fees shall be securely managed off platform via the third-party payment provider (currently Stripe Payments Australia Pty Ltd (Stripe)). You will be responsible for any credit card or other payment processing fees applied by Stripe, currently 2.9% payment (“Payment Processing Fees”). You acknowledge and agree that when registering an account with Stripe, you enter into a separate agreement directly with Stripe and you are solely responsible and liable to Stripe in connection with that agreement. We offer Stripe only as a convenient method for the Platform users to facilitate payment, and we have no obligation to attempt to collect any Service Fees that are not paid in the normal course. You hereby assume all obligations with respect to the collection of Speakers Fees.</li>
                        <li><strong>Platform Fee.</strong> You agree to pay us a platform fee on successfully finalising a Speaker Contract for your speaking services (“Platform Fee”). The Platform Fee is for the ongoing connections, marketing, operations, maintenance, and development of the Platform and is calculated as a percentage of the Speakers Fee being currently 17% + GST. Your payment of the Platform Fee will be debited from the first instalment of Speaker Fees paid. You agree that your failure to perform speaking services for a Finder for any reason once booked does not relieve you of paying the Platform Fee. The Platform Fee is non-refundable.</li>
                        <li><strong>Payment Authorization.</strong> You hereby authorize Stripe to collect the Service Fee and deduct the Platform Fee from it before rendering the balance to you as set out above. You also authorize the charges necessary to implement the payment provisions contained this clause 4. This authorization continues until payment in full of the Speaker Fee.</li>
                      </ol>
                    </li>

                    <li><strong>Cancellations and Postponements</strong>
                      <ol>
                        <li><strong>Cancellation by Finder: Greater than 30 days’ notice</strong> - If cancellations occur more than 30 days prior to the presentation of the speaking services, the 1<sup>st</sup> Speaker Fee payment instalment will be retained by you, unless otherwise agreed with the Finder. The second 50% instalment of the Speaker Fee scheduled for payment on the day of the presentation will not be required to be paid by the Finder.</li>
                        <li><strong>Cancellation by Finder. Within 30 days’ notice</strong> - If cancellations occur less than 30 days prior to the presentation of the speaking services the entire amount of the Speaker Fee will be due to you as if the speaking services had been fully rendered, inclusive of any incremental costs, unless otherwise agreed between the Finder and you.</li>
                        <li><strong>Cancellation by You.</strong> Subject to clause 4, if you cancel for any reason other than Force Majeure irrespective of the notice period, you will not be entitled to any compensation or reimbursement of expenses, and you must refund any Speaker Fees already paid by the Finder, minus the Platform Fee and Payment Processing Fee which are non-refundable.</li>
                        <li><strong>Postponement.</strong> The Finder and you are responsible for any modifications to a booking that you agree to make, including in relation to events out of your control (“Force Majeure”) as we are not a party to the Speaker Contract, nor do we collect or process any fees. Should the Finder and you agree to defer the speaking engagement this will be organised by the Finder and you independently of the Platform and the Speaker Fees will remain as paid. Should the Finder and you wish to compensate the other in any way in relation to postponement or Force Majeure you can do so directly.</li>
                      </ol>
                    </li>

                    <li><strong>Disputes</strong>
                      <p className={styles.pMargin}>
                        You are solely responsible for your interactions with other parties on the Platform. We reserve the right, but have no obligation, to monitor and take action regarding any disputes between Finders and Speakers. We will not, however, act on behalf, of any Finder or Speaker, in respect of any dispute between a Finder and a Speaker.
                      </p>
                    </li>
                    
                    
                    <li><strong>Proprietary Rights; License</strong>
                      <ol>
                        <li><strong>License Grant.</strong> Subject to the terms and conditions of this Agreement, Finders Speakers hereby grants you a non‐exclusive, non‐transferable, non-sub-licensable, non‐assignable license, during the term of this Agreement, to use the Platform and Services solely for the purpose of arranging for speaking services and tracking resulting Service Fees. All rights not expressly granted to you are reserved by us.  You grant to us a non-exclusive and royalty-free license, during the term of this Agreement, to use your image and any other materials you may provide in connection with your profile, including any comments reviews, or other items relating to the provision of the speaking services that may come into our possession.</li>
                        <li><strong>Restrictions.</strong> You shall not, and shall not allow any other party to license, sublicense, sell, resell, transfer, assign, distribute or otherwise provide or make available to any other party the Platform and Services in any way, modify or make derivative works based upon the Platform and Services or indulge in any Prohibited Conduct as set out in Schedule 1 of the Website Terms of Use.</li>
                        <li><strong>Ownership.</strong> The Services, Platform and Finders Speakers Data, including without limitation all intellectual property rights therein, are and shall remain (as between you and us) our property. Neither this Agreement nor your use of the Services or Platform nor Finders Speakers Data conveys or grants to you any rights in or related to the Services or Finders Speakers Data, except for the limited license granted above or to use or reference in any manner Finders Speakers’ company name, logos, product and service names, trademarks, services marks or other indicia of ownership.</li>
                      </ol>
                    </li>

                    <li><strong>Confidentiality</strong>
                      <p className={styles.pMargin}>
                        <strong>Confidential Information.</strong> Each party acknowledges and agrees that it may have access to or may be exposed to, directly or indirectly, confidential information of other parties whilst being party to this Agreement. Confidential information includes Finders Speakers Data, Speaker and Finder IDs, Speaker and Finder Information and other non‐public information of each party that such party designates as being proprietary or confidential or which the other party should reasonably know should be treated as confidential. Each party agrees to hold any Confidential Information it receives during this Agreement in confidence and not to use any such information for any purpose other than to perform its obligations under this Agreement.
                      </p>
                    </li>

                    <li><strong>Privacy</strong>
                      <p className={styles.pMargin}>
                        <strong>Use of Your Information.</strong> We will collect your personal data during the course of your application for, and use of, the Platform and Services, which information may be stored, processed, accessed, and used by us for our legitimate business purposes, in accordance with our Privacy Policy which is incorporated by reference into these Terms of Service.
                      </p>
                    </li>

                    <li><strong>Finder Speakers Liability</strong>
                      <ol>
                        <li>
                          <strong>Representations and Warranties; Disclaimers by You.</strong> You hereby represent and warrant that: (a) you have full power and authority to enter into this Agreement and perform your obligations under it; (b) you have not entered into, and during the term will not enter into, any agreement that would prevent you from complying with this Agreement; and (b) you will comply with all applicable laws in your performance of this Agreement, including holding and complying with all permits, licenses, registrations and other governmental authorizations necessary to provide speaking services.
                        </li>

                        <li>
                          <strong>DISCLAIMER.</strong> Although we will use reasonable care, we do not guarantee the availability or uptime of the Services we provide and you accept, the Services on an “as is” and “as available” basis. We do not represent, warrant, or guarantee that your access to or use of the Services: (a) will be uninterrupted or error free; or (b) will result in any requests for speaking services. We function as a lead generation tool with related services only and make no representations, warranties or guarantees as to the actions or inactions of the Finder who may request or receive speaking services. By using the Services, you acknowledge and agree that you may be introduced to a third party and you are advised to take reasonable precautions with respect to interactions with third parties introduced in connection with your use of the Services. we expressly disclaim all liability for any of your acts or omissions or any other user of the Platform or Services or third party.
                        </li>

                        <li>
                          <strong>Indemnification.</strong> You shall indemnify, defend (at our option) and hold harmless Finder Speakers  and its respective officers, directors, employees, agents, successors and assigns from and against any and all liabilities, expenses (including reasonable legal fees), damages, penalties, fines, and taxes arising out of or related to: (a) your breach of your representations, warranties or obligations under this Agreement; (b) any claim by any third party (including Finders, regulators and governmental authorities) directly or indirectly related to your provision of speaking services or your use of the Platform and Services; (c) any claim you may have against a Finder arising out of or relating to this Agreement and your Speaker Contract with such Finder; and (d) your negligent acts or omissions.
                        </li>

                        <li>
                          <strong>Limits of Liability.</strong> We shall not be liable for any of the following, whether based on contract, tort or any other legal theory, even if a party has been advised of the possibility of such damages: (i) any incidental, punitive, special, exemplary, consequential, or other indirect damages of any type or kind; or (ii) your or any third party’s property damage, or loss or inaccuracy of data, or loss of business, revenue, profits, use or other economic advantage. In no event shall our liability under this agreement exceed the amount of the platform fee actually paid to us hereunder in relation to the speaking services giving rise to such claim.
                        </li>
                      </ol>
                    </li>

                    <li><strong>Term and Termination</strong>
                      <ol>
                        <li><strong>Term.</strong> This Agreement shall commence on the date accepted by you and shall continue until terminated as set forth herein.</li>
                        <li><strong>Termination.</strong> Should you not wish to remain on the Platform or to use the Services you may terminate this Agreement by deactivating your profile; (b) Either Party may terminate this Agreement immediately, without notice, for the other party’s material breach of this Agreement; or (c), in the event of the insolvency or bankruptcy of the other party. In addition, we  may terminate this Agreement or deactivate your Speaker ID immediately, without notice, in the event you no longer qualify, under applicable law or our  standards and policies or this Agreement to provide speaking services via the Platform.</li>
                        <li><strong>Effect of Termination.</strong> Upon termination of the Agreement, you shall immediately cease using the Platform and Services and making any representation that you are listed or profiled on the Platform. Outstanding payment obligations and any other provision of this Agreement that by their terms or nature are intended to survive, shall survive the termination of this Agreement.</li>
                        <li><strong>General.</strong>
                          <ol>
                            <li><strong>Entire Agreement.</strong> This Agreement constitutes the entire agreement and understanding of the parties with respect to its subject matter and replaces and supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter. In this Agreement, the words “including” and “include” mean “including, but not limited to.” Except as otherwise provided herein, this Agreement may only be amended or supplemented by written agreement signed by the parties.</li>
                            <li><strong>Notifications.</strong> We may provide any notification for the purposes of this Agreement by email or by posting on the portal available to you on the Platform.</li>
                            <li><strong>Assignment.</strong> You may not assign, transfer or sub-contract any of your rights or obligations under this Agreement without our prior written consent. We may assign, transfer or sub-contract any of our rights or obligations under this Agreement at any time without notice to you.</li>
                            <li><strong>No waiver.</strong> Waiver of any power or right under this Agreement must be in writing signed by the party entitled to the benefit of that power or right and is effective only to the extent set out in that written waiver. Any failure by us to act with respect to a breach by you or others does not our right to act with respect to that breach or any subsequent or similar breaches.</li>
                            <li><strong>Severability.</strong> The provisions of this Agreement are severable and, if any provision of this Agreement is held to be illegal, invalid or unenforceable under any present or future law, such provision may be removed, and the remaining provisions shall be enforced.</li>
                            <li><strong>Variation.</strong>We reserve the right to amend this Agreement and any other policy on this site at any time in our sole discretion and any such changes will, unless otherwise noted, be effective immediately. Your continued usage of this site will mean you accept those amendments. We reserve the right, without notice and at our sole discretion, to change, suspend, discontinue, or impose limits on any aspect or content of this Platform or the Services. You may only vary or amend this Agreement by written agreement with us.</li>
                            <li><strong>Governing law and jurisdiction.</strong>This Agreement will be governed in all respects by the laws of New South Wales, Australia. The parties irrevocably submit to the non-exclusive jurisdiction of the courts of New South Wales, Australia, and the courts of appeal from them without application of conflicts of laws provisions.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
        </Text>
            </AnimatedOnScroll>
        </PrivacyContainer>
    </div>
    )
};

export default TermsConditions;