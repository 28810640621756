import React, { useEffect, useRef, useState } from 'react';
import styles from './Footer.module.scss';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { FAQFooter } from "../../FAQPage/FAQFooter";
import { HomeHero } from "../../HomePage/Redisign/HomeHero";
import styled from "styled-components";
import footerBg from "../../assets/footer-bg.webp";
import bgFooterMobile from "../../assets/bg-footer-mobile.png";
import { Link, withRouter } from 'react-router-dom';
import logo from '../../assets/findersspeakers-logo-white.png';
import { GreenFill } from '../../_components/GreenFill/GreenFill';
import { ReactComponent as Fb } from '../../assets/icons/fb.svg';
import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg';
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import { GetStarted } from "../../AboutScreens/AboutScreen";
import { Col } from "react-bootstrap";
import useWindowDimensions from "../../helpers/useWindowDimentsions";
import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { MainTitleStyled } from "../../HomePage/MainTitleStyled"
import { useMediaQuery } from 'react-responsive'
import { toggleLoginModal } from '../../store/actions';


import greenFill from '../../assets/greenfill.png';
import constants from '../../constants';

export const cols = [
    {
        title: 'Get Started',
        rows: [
            { title: 'I am an event organiser', link: '/how-it-works' },
            { title: 'I am a speaker', link: '/speaker-page' },
        ],
    },
    {
        title: 'Learn More',
        rows: [
            { title: 'Our Story', link: '/about' },
//          { title: 'How It Works', link: '/how-it-works' },
            { title: 'FAQs', link: '/faq' },
            { title: 'Privacy', link: '/privacy' },
            { title: 'Terms of Use', link: '/terms-conditions' },
            { title: 'Contact Us', link: '/contact-us' },
        ],
    },
];

export function NavCol({ title = '', rows = [] }) {
    return (
        <div className="footer__nav">
            <h4 className="footer__nav-title">{title}</h4>
            <div className="d-flex flex-wrap ">
                {rows.map((e, index) => (
                    <Link
                        key={index}
                        className="footer__nav-item"
                        to={e.link}
                        target={e.title === 'Terms of Use' ? '_blank' : ''}
                    >
                        <span>{e.title}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export function SocialNetworks() {
    const iconWrapper = (svgElement) => {
        return <div className="social-links">{svgElement}</div>
    };

    return (
        <div className="footer__social">
            <a href="https://www.facebook.com/findersspeakersco" target="_blank">{iconWrapper(<Fb />)}</a>
            <a href="https://www.instagram.com/findersspeakersco/" target="_blank">{iconWrapper(<Instagram />)}</a>
            <a href="https://www.linkedin.com/company/19264688" target="_blank">{iconWrapper(<Linkedin />)}</a>
        </div>
    );
}

function onClickSubscribe() {
}

const Title = () => {
    const lineRef = useRef(null);
    const { width } = useWindowDimensions();
    const isMobile = useMediaQuery({ query: '(max-device-width: 600px)' })

    return (
        <MainTitleStyled ref={lineRef}>
            <span className="">Ready to {width < 1024 && <br />}
                <GreenFill
                    speed={'1000ms'}
                    imageStyle={{ top: '-3%', left: '-4%', width: '108%', height: '92%', transform: ['rotate(2deg)'], top: '2px' }}
                    reference={lineRef} delay={1000}
                    animMob={true}>
                    <span> power</span>
                </GreenFill> your <br />speaking business?
            </span>

        </MainTitleStyled>
    );
}

const HowItWorksTitle = () => {
    const [position, setPosition] = useState(0);
    const lineRef = useRef(null);
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (lineRef.current) {
            const dimensions = lineRef.current.getBoundingClientRect();
            setPosition(dimensions.y);
        }
    }, []);

    return (
        <span
            ref={lineRef}
            style={width > 800
                ? {
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '72px',
                    lineHeight: '72px',
                }
                : {
                    fontWeight: 'normal',
                }}
        >
            {/* <GreenLine
                target={'Ready '}
                bgImg={() => require('../../assets/green_line_footer.svg')}
                scrollPosition={width < 768 ? position + 200 : position - 500}
                moveBottom={width < 768 && '-6px'}
            /> */}
            <span style={{ color: '#02B5AA' }}>Ready</span> for the new world
            <span className="new-line"> of speaking and events?</span>
        </span>);
}

const Footer = (props) => {
    const { location, user, toggleLoginModal } = props;

    const mobilePaddingTop = props.location.pathname === '/preview-profile' ? 165 : 62

    const windowSize = useWindowDimensions();

    const isHomePage = location.pathname === '/';
    const isFAQPage = location.pathname === '/faq';
    const isAboutPage = location.pathname === '/about';
    const isHome2 = location.pathname === '/stage-2';
    const isHowItWorks = location.pathname === '/how-it-works';
    const isPrivacyPolicy = location.pathname === '/privacy';
    const isTc = location.pathname === '/tc' || location.pathname === '/terms-conditions';


    return (
        <SpeakersPage mobilePaddingTop={mobilePaddingTop}>
            <footer className="speakers__footer footer">
                {/* <AnimatedOnScroll animationIn="fadeIn" animationInDelay={200} animationInDuration={3000} screenOffset={200}> */}
                    {(isPrivacyPolicy || isAboutPage || isTc) && <GetStarted />}

                    {isFAQPage &&
                        (<FAQFooter
                            title={<Title />}
                            text={'It is easy, fast and free to get started.'}
                            button={'Apply to join today'}
                        />)
                    }

                    {isHowItWorks &&
                        (windowSize.width > 768
                            ? <HomeHero
                                title={<HowItWorksTitle />}
                                text={'It is easy, fast and free to get started.'}
                                button={'get access'}
                                buttonLink={'/register-business'}
                            />
                            : <HowItWorksAdaptive>
                                <HomeHero
                                    title={<HowItWorksTitle />}
                                    text={'It is easy, fast and free to get started.'}
                                    button={'get access'}
                                    buttonLink={'/register-business'}
                                />
                            </HowItWorksAdaptive>
                        )
                    }
                {/* </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay={800}> */}
                    <div className="container">
                        <div className="row">
                            <Col sm={12} md={4}>
                                <div className='footer__info'>
                                    <a href="/" className="footer__logo"><img src={logo} alt="finder speaker logo" /></a>
                                    <p className="footer__text">
                                        {!!user.id 
                                            ? <span>Your all-in-one direct speaker
                                                <span className="d-block"> booking and management platform</span>
                                            </span>

                                            : <>
                                              {
                                                // hidden for phase 1
                                                
                                                /*
                                                <span>STAY IN THE KNOW! Access trending
                                                  <span className="d-block"> news on speaking and events</span>
                                                </span> */
                                                
                                              }
                                              <span className="d-block">Follow us</span>
                                            </>
                                        }
                                    </p>
                                    <div className={styles.topBoxBtn} >
                                        {   true
                                            ? <Button
                                                variant="outline-secondary"
                                                className="footer__button"
                                                onClick={onClickSubscribe}
                                                style={{ display: 'none' }}
                                            >
                                                Subscribe
                                            </Button>
                                            : <>{
                                                // not needed for phase 1
                                                /*
                                                <a href="https://findersspeakersco.com/blog/" target="_blank">
                                                <Button
                                                    variant="outline-secondary"
                                                    className="footer__button"
                                                >
                                                    SIGN UP HERE!
                                                </Button>
                                            </a>
                                            */}</>
                                        }
                                        <SocialNetworks />
                                    </div>
                                </div>
                            </Col>

                            {/*
                            <Col sm={12} md={{ span: 2, offset: 1 }}>
                                <NavCol title={cols[0].title} rows={cols[0].rows} />
                            </Col>
                            */}

                            <Col sm={12} md={{ span: 3, offset: 1 }} >
                                <NavCol title={cols[1].title} rows={cols[1].rows} />
                            </Col>
                        </div>
                    </div>
                {/* </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay={600}> */}
                    <div className="footer__copyright">
                        <div className="container">
                            {constants.copyright}  <span>All Rights Reserved</span>
                        </div>
                    </div>
                {/* </AnimatedOnScroll> */}
            </footer>
        </SpeakersPage>
    );
}

export default withRouter(
    connect(
        () => ({}),
        { toggleLoginModal }
    )(Footer)
);

const SpeakersPage = styled.div`
    position: relative;
    background-image: url(${footerBg});
    background-size: cover;
    width: 100%;
    height: 100%;

     .footer {
       padding-top: 62px;
       
       .social-links {
        width: 60px;
        height: 60px;

        @media (max-width: 768px) {
         width: 40px;
         height: 40px;
        }
    }

       &__text {
          .d-block {
              white-space: nowrap;
          }
       }
        .bg { display: none !important; }
        @media (max-width: 768px) {
            padding: ${(props) => props.mobilePaddingTop}px 0 0;
            
            .col-5 {
                padding-right: 0;
                padding-top: 20px;
            }
        
            .col-7 {
                padding-left: 0;
                padding-top: 20px;
            }
        }

        .accent {
            color: #20D5C4;
        }
       
       .hero {
            padding: 20px 0 77px;
            background: transparent;
            
            .title {
                max-width: 1060px;
                margin: 0 auto 32px;
                @media (max-width: 1024px) {
                    width: 315px;
                    margin: 0 auto 27px;
                }   
            }
            
           &__text {
                margin-bottom: 35px;
                
                @media (max-width: 1024px) {
                    max-width: 325px;
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0 auto 14px;
                }  
           }

            @media (max-width: 1280px) {
                   padding: 20px 0 85px;
            }
            
            @media (max-width: 768px) {
                   padding: 0 0 35px;
            }
       }
       
       .new-line {
          display: block;
            @media (max-width: 768px) {
                display: inline-block;
            }
          
       }
       
       .new-line-xs {
           @media (max-width: 768px) {
                display: block;
            }
       }
        .container {
            width: 1195px;
            max-width: 1195px;
            
            @media (max-width: 1280px) {
                width: 1140px;
            }
            
            @media (max-width: 1200px) {
                width: 100%;
                max-width: initial;
                min-width: 100%;
            }
            
             @media (max-width: 768px) {
                width: 100%;
            }
        }
        
        &__logo {
            display: block;
            margin-bottom: 19px;
            img {
                max-width: 235px;
            }
              @media (max-width: 1024px) {
                width: 220px;
                margin: 0 auto 22px;

                img {
                  width: 100%;
                }
            }
        }
        
        &__info {
            margin-left: 86px;
            color: rgba(255, 255, 255, 0.55);
            
            p {
                font-size: 15px;
                line-height: 19px;
                margin-bottom: 23px;
                
                @media (max-width: 768px) {
                    line-height: 20px;
                    text-align: center;
                    margin-bottom: 26px;
                }
            }  
            
            @media (max-width: 1360px) {
              margin-left: 0;
            }     
        }
        
        &__social {
          a {
            display: inline-block;
            margin-right: 30px;
            
            path {
                transition: fill 0.3s ease;
              }
            
            &:hover {
              path {
                fill: var(--grass);
              }
            }
          }
          
           @media (max-width: 1200px) {
                    width: 177px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                   
                   a {
                      margin-right: 0;
                      &:last-child {
                          margin-right: 0;
                      }
                   }
                }
             @media (max-width: 768px) {
                    width: 177px;
                    margin: 0 auto 22px;
                    display: block;
                   
                   a {
                      margin-right: 28px;
                      &:last-child {
                          margin-right: 0;
                      }
                   }
                }
        }
        
        &__button {
            backdrop-filter: blur(40px);
            border-radius: 40px;
            font-weight: 600;
            font-size: 14px;
            line-height: 1;
            height: auto;
            min-height: initial;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            border: none;
            margin-bottom: 30px;
            padding: 12px 24px;
            background: #475668;
            
            &:hover {
              border: none;
            }
            
            @media (max-width: 768px) {
                width: 146px;
                margin: 0 auto 40px;
                height: 48px;
                padding: 12px 0;
                display: block;
            }
        }
        
        &__nav .flex-wrap{
            @media (max-width: 768px) {
                justify-content:space-between;
                }
                
        }
        
        &__nav-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            color: #FFFFFF;
            margin-bottom: 18px;
            margin-top: 16px;
            text-transform: uppercase;
            
             @media (max-width: 1360px) {
                margin-bottom: 16px;
            }
        }
        
        &__nav-item {
            font-size: 15px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.55);
            margin-bottom: 8px;
            flex-basis: 50%;
            transition: color 0.3s ease-in-out;
            
            &:hover {
              color: var(--grass);
            }
            
            @media (max-width: 768px) {
                 font-size: 14px;
                 line-height: 18px;
                 margin-bottom: 16px;
            }
        }
        
        .col-md-2 {
            .footer__nav-item {
                flex-basis: 100%;
                
                @media (max-width: 768px) {
                  flex-basis: 50%;
                  margin-bottom: 3px;
                }
          }
        }
        
        .col-4 {
            .footer__nav-item {
                flex-basis: 50%;
          }
        }
        
        &__copyright {
            height: 63px;
            border-top: 1px solid rgba(255,255,255,0.15);
            font-size: 14px;
            line-height: 63px;
            color: rgba(255, 255, 255, 0.55);
            margin-top: 34px;
            text-align: center;
            
            span {
                display: inline-block;
                padding-left: 15px;
                
            }
            
            @media (max-width: 1280px) {
                 margin-top: 40px;
            }

            @media (max-width: 1024px) {
                 padding-top: 0;
            }

            @media (max-width: 768px) {
                padding-top: 22px;
                 margin-top: 26px;
                 font-size: 14px;
                 line-height: 18px;
                 font-weight: 300;
            }
        }
     }

    .speakers__footer {
        
        
        @media (max-width: 768px) {
            background-image: url(${bgFooterMobile});
        }
    }

    .home__btn {
        font-size: 12px;
        line-height: 44px;
        padding: 0 50px;
        height: 45px;
        background: #E8388A;
        border: 2px solid #E8388A;
        color: #fff;
        border-radius: 40px;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        transition: background-color .3s ease-in-out, border .3s ease-in-out, color .3s ease-in-out ;
        
        &:focus,
        &:active, 
        &:visited {
            outline: none;
        }
        
        &:hover {
          border: 2px solid #E8388A;
          background: #fff;
          color: #E8388A;
        }
    }

    .home {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: normal;
    
        .container {
            min-width: 1280px;
            
            @media (max-width: 1400px) {
                min-width: 1180px;
            }
            
            @media (max-width: 1280px) {
                min-width: 1110px;
            }
        }
    
        p {
            font-size: 24px;
            line-height: 30px;
            color: var(--text-semidark);
            margin-bottom: 0;
            
            @media (max-width: 1280px) {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    .subtitle {
        font-weight: 600;
        font-size: 62px;
        line-height: 72px;
    
        &--dark {
            color: var(--dark-standart);
        }
    
        &--light {
            color: #fff;
        }
    }
    
    .aqua {
        color: var(--grass);
    }

    .title {
        color: #fff;
        font-weight: 600;
        font-size: 100px;
        line-height: 110px;
        text-align: center;
        margin-bottom: 40px;
        
        @media (max-width: 1280px) {
          font-size: 72px;
          line-height: 80px;
          margin-bottom: 20px;
        }
    }
`;

const HowItWorksAdaptive = styled.div`
`