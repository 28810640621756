import React from 'react';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>Why book an inspirational & motivational speaker</h2>

      <p className={s.paragraph}>
        Inspirational and motivational speakers provide a fresh perspective,
        motivating individuals to take action, boosting morale and productivity, helping
        individuals overcome obstacles, enhancing teamwork and collaboration, providing practical
        strategies and tools, and boosting confidence and self-esteem. These top inspirational
        and motivational speakers can help individuals achieve their goals and reach their full
        potential. 
      </p>

      <p className={s.paragraph}>
        If you're looking to inspire and motivate your team, consider booking an
        inspirational and motivational speaker today.
      </p>

      <h3 className={s.greenHeadingList}>A fresh perspective</h3>
      <p className={s.paragraph}>
        An inspirational and motivational speaker can provide a fresh perspective
        on life and work, helping individuals see their challenges in a new light.
        By sharing personal experiences and insights, these speakers can help individuals
        gain a new perspective on their circumstances and find new ways to approach their goals.
      </p>

      <h3 className={s.greenHeadingList}>Motivates individuals to take action</h3>
      <p className={s.paragraph}>
        An inspirational and motivational speaker can help individuals break out of their comfort
        zones and take action towards their goals. These speakers can inspire individuals to push
        past their fears and doubts and take the necessary steps towards success.
      </p>

      <h3 className={s.greenHeadingList}>Boosts morale and productivity</h3>
      <p className={s.paragraph}>
        By inspiring and motivating individuals, an inspirational and motivational speaker
        can boost morale and productivity in the workplace. Employees who are motivated and inspired
        are more likely to be engaged in their work and achieve better results.
      </p>

      <h3 className={s.greenHeadingList}>Helps individuals overcome obstacles</h3>
      <p className={s.paragraph}>
        An inspirational and motivational speaker can help individuals overcome obstacles
        and challenges in their personal and professional lives. By sharing personal stories
        of resilience and perseverance, these speakers can help individuals develop the skills
        and mindset needed to overcome adversity.
      </p>

      <h3 className={s.greenHeadingList}>Enhances teamwork and collaboration</h3>
      <p className={s.paragraph}>
        Our top inspirational speakers can help teams work together more effectively by promoting
        a culture of collaboration and cooperation. By emphasizing the importance of teamwork and
        sharing strategies for effective communication and conflict resolution, these speakers can
        help teams work more cohesively towards their goals.
      </p>

      <h3 className={s.greenHeadingList}>Provides practical strategies and tools</h3>
      <p className={s.paragraph}>
        We offer great motivational speakers who often provide practical strategies and tools
        that individuals can use to achieve their goals. Whether it's tips for effective
        time management or strategies for developing a growth mindset, these speakers can
        provide individuals with the tools they need to succeed.
      </p>

      <h3 className={s.greenHeadingList}>Boosts confidence and self-esteem</h3>
      <p className={s.paragraph}>
        By inspiring and motivating females, find inspirational female speakers in Australia that
        can help boost confidence and self-esteem. Individuals who feel confident and capable are
        more likely to take risks and pursue their goals with determination and perseverance.
      </p>
    </>
  );
};

const TopicPageInspiration = () => {
  return (
    <TopicPageBase
      topicAlias="inspiration-and-motivation"
      headerSubtitle={<>
        Book leading inspirational & motivational speakers.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a motivational speaker?"
      trendingSpeakersHeading="Trending Motivational Speakers"
      why={<Why />}
      speakerTopicText="inspirational and motivational"
    ></TopicPageBase>
  )
};

export default TopicPageInspiration;
