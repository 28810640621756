import React from 'react';
import HeaderDashboard from '../Header/HeaderDashboard';
import Footer from '../Footer/Footer';
import './styles.scss'

export const DashboardProfileBuilderLayout = (props) => {
  const { hideFooter } = props;

  return (
    <>
      <HeaderDashboard
        {...props}
        name={props.user.firstName}
        last_name={props.user.lastName}
      />
      <div className="content">{props.children}</div>
      {!hideFooter && <Footer {...props} />}
    </>
  );
};
