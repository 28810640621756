import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ApiDataProvider from '../api/apiClient';
import AccountDetails from './AccountDetails';


export default () => {
  const history = useHistory();
  const { speakerId } = useParams();

  const fetcher = () => {
    return ApiDataProvider.getSpeakerAccountDetailsAdmin(speakerId);
  };

  const sender = async ({
    firstName,
    lastName,
    phoneNumber,
    email,
    agentEmail,
    emailNotificationsDisabled
  }) => {
    await ApiDataProvider.saveSpeakerAccountDetailsAdmin(speakerId, {
      first_name: firstName,
      last_name: lastName,
      contact_number: phoneNumber,
      email,
      agent_email: agentEmail,
      receive_emails: !emailNotificationsDisabled
    });

    history.push('/admin-panel/speakers');
  };


  return <div style={{ marginTop: '-100px' }}>
    <AccountDetails
      hasBusinessNameField={false}
      hasAgentEmailField={true}
      hasAvatarField={false}
      fetcher={fetcher}
      sender={sender}
      shouldEmailChangeConfirm={false}
      avatarText={'Avatar'}
      onBack={() => {
        history.push('/admin-panel/speakers');
      }}
    />
  </div>;
};
