export class ValidationError extends Error {
  constructor(message, errors) {
    super(message);
    this.name = 'ValidationError';
    this._errors = errors;
  }

  getErrors() {
    return this._errors;
  }
}

export class NotFoundError extends Error {
  constructor(message) {
    super(message);
  }
}

export class AccessDeniedError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}
