import React from 'react';
import TopicPageBase from './components/TopicPageBase';

import s from './components/TopicsPageBase.module.scss';

const Why = () => {
  return (
    <>
      <h2 className={s.subheading}>The benefits of booking a performance & productivity speaker</h2>

      <p className={s.paragraph}>
        At <a className={s.link} href="/" target="_blank">Finders Speakers Co</a> we
        offer the best productivity speakers who have proven track records of driving
        real results. Our speakers are renowned industry experts, thought leaders, and
        experienced professionals, each equipped with unique insights and strategies to
        optimize performance across various domains
      </p>

      <p className={s.paragraph}>
        Our handpicked selection of performance and productivity speakers will inspire,
        motivate, and equip your team with the skills and strategies needed to excel in their
        roles. Discover how our expert speakers can unlock the full potential of your workforce
        and boost your company's performance to new heights.
      </p>

      <h3 className={s.greenHeading}>Enhanced Productivity and Performance</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            Productivity and performance speakers are masters at sharing proven techniques
            and strategies for optimizing individual and team performance
          </li>
          <li>
            They provide practical tools and methodologies to streamline workflows, improve
            time management, and boost efficiency, resulting in increased productivity levels
          </li>
          <li>
            With their guidance, your team can learn how to set ambitious goals, stay focused,
            and achieve exceptional results.
          </li>
        </ul>
      </p>

      <h3 className={s.greenHeading}>Improved Leadership and Communication Skills</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            Great leaders are the cornerstone of successful organizations. Our speakers
            offer valuable insights and practical guidance to develop effective leadership skills.
          </li>
          <li>
            They provide strategies for effective communication, conflict resolution, and
            team collaboration, empowering leaders to inspire and motivate their teams effectively.
          </li>
          <li>
            Investing in leadership development through our speakers can have a profound
            impact on organizational culture and overall performance.
          </li>
        </ul>
      </p>

      <h3 className={s.greenHeading}>Adaptation to Change and Innovation</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            In today's rapidly evolving business landscape, embracing change and fostering
            innovation are crucial for staying competitive.
          </li>
          <li>
            Our speakers offer fresh perspectives and strategies to navigate through change,
            adapt to new technologies, and foster a culture of innovation.
          </li>
          <li>
            They empower individuals and teams to embrace challenges, think creatively,
            and drive positive change within their organizations.
          </li>
        </ul>
      </p>

      <h3 className={s.greenHeading}>Industry-Specific Expertise</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
            We understand that each industry has unique challenges and requirements.
            That's why we have a diverse lineup of speakers with expertise in various fields.
          </li>
          <li>
            Our performance and best productivity speakers specialize in industries such as
            technology, finance, healthcare, sales, and more.
          </li>
          <li>
            By selecting a speaker with industry-specific knowledge, you can ensure that the
            insights and strategies shared are relevant and tailored to your organization's needs.
          </li>
        </ul>
      </p>

      <h3 className={s.greenHeading}>Increased Motivation and Engagement</h3>
      <p className={s.paragraph}>
        <ul className={s.ulist}>
          <li>
          Our speakers ignite passion and inspire individuals to reach their full potential.
          </li>
          <li>
            They use powerful storytelling techniques and real-life examples to connect
            with audiences on an emotional level, creating lasting motivation and drive.
          </li>
          <li>
            By fostering a positive and engaging atmosphere our speakers enhance employee
            morale and overall job satisfaction.
          </li>
        </ul>
      </p>

      <p className={s.paragraph}>
        Investing in a performance and productivity speaker
        from <a className={s.link} href="/" target="_blank">Finders Speakers Co.</a> is
        an investment in your organization's success. Our handpicked speakers will inspire,
        motivate, and equip your team with the skills and strategies needed to drive peak
        performance and productivity.
      </p>

      <p className={s.paragraph}>
        Our performance and productivity speakers possess exceptional communication
        skills and a deep understanding of human behavior, enabling them to captivate
        audiences and ignite the spark needed for personal and professional growth. They
        seamlessly blend storytelling, interactive sessions, and actionable takeaways to
        create engaging experiences that resonate long after the event.
      </p>
    </>
  );
};

const TopicPagePerformance = () => {
  return (
    <TopicPageBase
      topicAlias="performance-and-productivity"
      headerSubtitle={<>
        Book leading performance & productivity speakers.<br />
        All topics, brief & budgets.
      </>}
      heading={'Find your perfect speaker match.\nCreate exceptional event experiences.'}
      formHeading="looking for a performance & productivity speaker?"
      trendingSpeakersHeading="Trending Performance Speakers"
      why={<Why />}
      speakerTopicText="performance and productivity"
    ></TopicPageBase>
  )
};

export default TopicPagePerformance;
