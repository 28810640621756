import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import mainImg from "../../assets/redesign/marketplace1.png";
import secondImg from "../../assets/redesign/marketplace2.png";

export const Picture = () => {
    const [moveBg, setMoveBg] = useState('')
    useEffect(() => {
        setTimeout(() => {
            setMoveBg('move-bg')
        }, 200)
    }, [])
    return (
        <PictureStiled className={moveBg}>
            <div className="picture_wrap">
                <div className="shape circle1"></div>
                <div className="shape circle2"></div>
                <div className="shape circle3"></div>
                <div className="shape circle4"></div>
                <div className="shape square1"></div>
                <div className="shape square2"></div>

                <div className="img_wrapper1">
                    <img src={mainImg} alt="img"/>
                </div>
                <div className="img_wrapper2">
                    <img src={secondImg} alt="img"/>
                </div>
            </div>
        </PictureStiled>
    )
};

export const PictureStiled = styled.section`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    background-image: url(${require('../../assets/redesign/cruve.png')});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
    transform: scale(1.4);
    transition: transform 4s ease-in-out;
    
    .picture_wrap {
        max-width: 1160px;
        height: 100%;
        position: relative;
        margin: 0 auto;
    }
    
    &.move-bg {
      transform: scale(1);
    }
    
    .shape {
        border-radius: 50%;
        position: absolute;
    }
    
    .circle1 {
        background: #20D5C4;
        width: 94px;
        height: 94px;
        top: 40%;
        right: 24%;
        transform: translate(-50%, -50%);
        
    }
    .circle2 {
        background: #20D5C4;
        width: 30px;
        height: 30px;
        top: 33%;
        right: 24%;
        transform: translate(-50%, -50%);
    
    }
    .circle3 {
        background: #E8388A;
        width: 33px;
        height: 33px;
        top: 44.5%;
        left: 31%;
        transform: translate(-50%, -50%);
    }   
    .circle4 {
        background: #E8388A;
        width: 12px;
        height: 12px;
        top: 40.5%;
        left: 27%;
        transform: translate(-50%, -50%);
    }
    .square2 {
        width: 90px;
        height: 84px;
        top: 35.5%;
        left: 31%;
        transform: translate(-50%, -50%);
        background: linear-gradient(0deg, #20D5C4, #20D5C4), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 100%);
        border-radius: 4px;
        z-index: 0;
    }

    .square1 {
        width: 60px;
        height: 110px;
        top: 33%;
        left: 30%;
        background: rgba(233, 238,241,0.7);
        box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1);
        transform: rotate(-22.4deg);
        border-radius: 8px; 
        z-index: 1;
    }
    
    .img_wrapper1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%,-82%);
        z-index: 2;
    }
    .img_wrapper2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-88%,-36%);
        z-index: 2;
    }
    
    @media (max-width: 1024px) { 
       background-size: contain;
       background-attachment: initial;
       background-position: top center;
       height: 300px;
       width: 360px;
       margin: auto;
       position:static;
       
      .picture_wrap {
        max-width: initial;
        border-bottom: initial;
      }
      
        .img_wrapper1,
        .img_wrapper2 {
            img {
              width: 100%;
            }
        }
        .img_wrapper1 {
            width: 265px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-39%,-80%);
            z-index: 2;
        }
        .img_wrapper2 {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-91%,-30%);
            z-index: 2;
        }
        .circle1 {
            width: 60px;
            height: 60px;
            top: 33%;
            right: -3%;
        }
        .circle2 {
            width: 18px;
            height: 18px;
            top: 20%;
            right: -2%;
        }
        .circle3 {
            width: 20px;
            height: 20px;
            top: 39.5%;
            left: 12%;
        }
        .circle4 {
          display: none;
        }
        .square2 {
            width: 51px;
            height: 51px;
            top: 22.5%;
            left: 17%;
        }
        .square1 {
            width: 39px;
            height: 64px;
            top: 16%;
            left: 14%;
            border-radius: 6px;
        }
    }
`;
