import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components';
import moment from 'moment'
import {Button, Spinner} from 'react-bootstrap'
import './style.scss';
import { detailImg } from '../styles/Images'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import { fetchDataGet } from '../api/helperFunctions'


const Enquiries = props => {
    const {data} = props;
    const user = useSelector(state => state.user);
    const [dropdown, setDropDown] = useState(false)
    const [popup, setPopup] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const [visLoader, setVisLoader] = useState(false)

    // Temp variable, before chat creation
    const [repliesData, setRepliesData] = useState([0, 0, false])
    useEffect(() => {
      setRepliesData([
        Math.floor(Math.random() * Math.floor(10)),
        Math.floor(Math.random() * Math.floor(2)),
        Boolean(Date.now() % 2)
      ])
    }, [])

    const cancelEnquiry = () => {
        setVisLoader(true)
        fetchDataGet(`business/enquiries/${data.id}/cancel`, 'put', user.token.token)
          .then((res) => {
              setVisLoader(false)
              setPopup(false)
              if (res[1].message) setCanceled(true)
          })
          .catch(() => {
              setPopup(false)
              setVisLoader(false)
          })
    }

    const replies = repliesData[0] > 0
      ? <div>Replies <b>{repliesData[0]} {repliesData[1] > 0 && <span>&nbsp;({repliesData[1]} new)</span>}</b></div>
      : <div className="no-replies">No replies yet</div>


    return (
      <>{ canceled ? null :
        <StyledEnquiries>
            <div className="table-row" onMouseLeave={() => setDropDown(false)}>
                <div className="table-row__col col1">
                    { data.event_name }
                </div>
                <div className="table-row__col col2">
                    { moment(data.created).format('dddd, Do MMM, YYYY') }
                </div>

                <div className="table-row__col col3">
                    {replies}
                </div>
                <div className="table-row__col col4">
                    {repliesData[2] && <a href="#">VIEW OFFER</a>}
                </div>
                <div className="table-row__col col5">
                    <CustomButton type='view' text='My Enquiry' />
                </div>
                <div className="table-row__col col6 enquiry-col-dd">
                    <img src={detailImg} alt="" onClick={() => setDropDown(!dropdown)} />
                    {dropdown && <div className="enquiry-action-dd">
                        <div onClick={() => {setPopup(true); setDropDown(false)}}>
                            <RemoveCircleOutline />
                            <span>Cancel Enquiry</span>
                        </div>
                    </div>}
                </div>

                {popup && <div className="enquiry-cancel">
                    {visLoader ? <Spinner className="spinner" animation="border" variant="primary"/> :
                      <div className="ec-body">
                          <CloseIcon className="ec-close" onClick={() => setPopup(false)}/>
                          <div className="ec-title">Are you sure you want to cancel this enquiry?</div>
                          <div>If you proceed, your data will be deleted permanently.<br/>Please notify any shortlisted
                              speakers directly.
                          </div>
                          <div className="ec-btn">
                              <Button variant="outline-primary" onClick={() => setPopup(false)}>CLOSE</Button>
                              <Button variant="outline-secondary" onClick={() => cancelEnquiry()}>CONFIRM</Button>
                          </div>
                      </div>
                    }
                </div>}

            </div>
        </StyledEnquiries>}
      </>
    );
}

const CustomButton = props => {
    return (
      <StyledCustomButton>
          {
              props.click
                ? <div className={`btn ${props.type}`} onClick={() => props.click(true)}>
                    {props.text}
                </div>
                : <div className={`btn ${props.type}`}>
                    {props.text}
                </div>
          }
      </StyledCustomButton>
    )
}

const StyledCustomButton = styled.div`
    .btn {
        height: 40px;
        width: 132px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;

        &.messages {
            color: white;
            background-color: var(--grass);
        }

        &.view {
            color: white;
            background-color: #B4B6B7;
        }

        &.new {
            color: #ff00ff;
            border: 2px solid #ff00ff;
            background-color: #fff;
        }
    }
`

export default Enquiries

const StyledEnquiries = styled.div`
    
    .table-row {
        display: flex;
        width: 100%;
        background-color: #E8EAEC;
        padding: 20px 30px;
        font-size: 12px;
        font-weight: 450;
        
        &__col {
            display: flex;
            align-items: center;
            margin-left: 30px;

            &.col1 {
                display: block;
                margin: auto 0;
                max-height: 36px;
                overflow: hidden;
                width: 28%;
            }
            &.col2 {
                width: 17.5%;
            }
            &.col3 {
                margin-left: 30px;
                margin-right: auto;
                width: 140px;
                b {
                    font-size: 14px;
                    font-weight: 600;
                }
                span {
                    color: #FF00FF
                }
                 .no-replies {
                    color: rgba(27, 16, 16, .5);
                }
            }
            &.col4 {
                margin: auto
                width: 80px;
                a {
                    color: #16D4C2;
                }            
            }
            &.col5 {
              margin: auto
            }
            &.col6 {
              margin-left: auto;
              padding-left: 20px;
            }
        }
    }
`;
